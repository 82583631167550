import { AsyncPaginate } from "react-select-async-paginate";

const PaginateSelectedComponent = (props) => {
  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  console.log("props.value test: ", props.value);

  return (
    <section style={props?.customStyle}>
      <AsyncPaginate
        // debounceTimeout={100000000000}
        // key={JSON.stringify(regionName)}
        value={props.value || ""}
        loadOptions={props.loadOptions}
        getOptionValue={(option) => option.name}
        getOptionLabel={(option) => option.name}
        onChange={onChange}
        isSearchable
        placeholder={props?.placeholder}
        isDisabled={props?.disabled ? props?.disabled : false}
        isLoading={props.loading}
        additional={{
          page: 1,
        }}
        isMulti={props.isMulti || false}
        className="AsyncPaginateStyle"
      />
    </section>
  );
};

export { PaginateSelectedComponent };
