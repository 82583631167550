import React from 'react'
import { Form, Table } from 'react-bootstrap'
import style from './TableData.module.scss'

const TableData = ({ AllDataFromDB, PrimaryHeader, header, title, checkedLoan, setCheckedLoan  }) => {

    const items = AllDataFromDB && AllDataFromDB.results.map((item) => item);
    const allChecked = checkedLoan?.size === items?.length;


    const handleCheckAll = () => {
        if (allChecked) {
            setCheckedLoan(new Set());
        } else {
            setCheckedLoan(new Set(items));
        }
    };
    const handleItemCheck = (items) => {
        const newCheckedLoan = new Set([...checkedLoan, ...items]);

        items.forEach((item) => {
            if (checkedLoan.has(item)) {
                newCheckedLoan.delete(item);
            } else {
                newCheckedLoan.add(item);
            }
        });

        setCheckedLoan(newCheckedLoan);
    };
console.log(items?.length !== 0 ? true : false)
console.log(allChecked)
console.log(checkedLoan?.size === items?.length)
    return (
        <Table bordered responsive className='mt-2 users_table mb-0'>
            <thead>

                <tr >
                    {
                        PrimaryHeader?.map((head, index) => {
                            return (
                                <th
                                    className={`${head.isSelect && style.is_select} `}
                                    onClick={() => {
                                        if (head.isSelect) {
                                            handleCheckAll();
                                        }
                                    }}
                                    key={head.key} style={{ backgroundColor: "#3EBDAC", color: "white", fontSize: "13px" }}>
                                    {head.isSelect ? (
                                        <input
                                            type='checkbox'
                                            checked={checkedLoan?.size === items?.length && items?.length !== 0}
                                        />
                                    ) : (head.header_names)
                                    }
                                </th>
                            )
                        })
                    }
                    {
                        header?.map((head, index) => {
                            return (
                                <th
                                    className={`${head.isSelect && style.is_select}`}
                                    key={head.key} style={{ backgroundColor: "#3EBDAC", color: "white", fontSize: "13px" }}>
                                    {head.header_names}
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {AllDataFromDB && AllDataFromDB?.results.map((item, i) => (
                    <tr key={i}>
                        <td className='date_title'>
                            <span>

                                <input
                                    type='checkbox'
                                    // id={item.payment_subscriber.id}
                                    checked={checkedLoan?.has(item)}
                                    onChange={() => handleItemCheck([item])}
                                />

                            </span>
                        </td>
                        <td className='date_title'>
                            <span>
                                {item.error_message}
                            </span>
                        </td>
                        <td className='date_title'>
                            <span>
                                {item.Failed_timestamp}
                            </span>
                        </td>

                        {
                            title && title === 'loans' ? (
                                item.payment_subscriber !== null && (
                                    <>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.loan_application_number}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.tenor}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.created}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.modified}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.user_phone}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.user_national_id}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.brand_name}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.branch_name}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.product_label}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.principal}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.total_due_amount}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.interest_rate}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.approved}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.refunded}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                </>
                                )

                            ) : title && title === 'customers' ? (
                               item.payment_subscriber !== null && (
                                <>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.full_name}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.phone}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.national_id}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.governorate}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.area}
                                        </span>
                                    </td>

                                </>
                               )
                            ) : title && title === 'Vendor_liability_requests' ? (
                                item.payment_subscriber !== null && (
                                    <>
                                      
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.branch_name}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.brand_name}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.loan_application_number}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.created}
                                            </span>
                                        </td>
                                    </>
                                )
                            ) : title && title === 'create_installments' ? (
                                item.payment_subscriber !== null && (
                                    <>

                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.loan_application_number}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.tenor}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.created}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.modified}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.user_phone}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.user_national_id}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.brand_name}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.branch_name}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.product_label}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.principal}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.total_due_amount}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            {item.payment_subscriber.interest_rate}
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.approved}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.refunded}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                    </>
                                )

                            ) : title && title === 'installment_payment' ? (
                                item.payment_subscriber !== null && (
                                    <>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.loan}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.due_date}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.loan_application_number}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.created}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.amount}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.interest}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.principal}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.paid_amount}
                                            </span>
                                        </td>
                                    </>
                                )

                            ) : title && title === 'Early' ? (
                                item.payment_subscriber !== null && (
                                    <>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.payment_method}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.paid_datetime}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.transaction_id}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.amount}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.total_interest}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.national_id}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.user_oracle_identifier}
                                            </span>
                                        </td>

                                        <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.sent_conv_fees}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                    <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.paid}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                    </>
                                )
                            ) : title && title === 'Penalty' ? (
                                item.payment_subscriber !== null && (
                                    <>

                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.loan_application_number}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.customer_name}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.national_id}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.phone_number}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.amount}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.installment_due_date}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.installment_amount}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.created}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                            <span>
                                                {item.payment_subscriber.installment_number}
                                            </span>
                                        </td>
                                        <td className='date_title'>
                                        <span>
                                            <Form.Check
                                                type='checkbox'
                                                id='checkbox'
                                                checked={item.payment_subscriber.paid}
                                                disabled
                                            // onChange={(e) => {
                                            //     item.payment_subscriber.refunded = e.target.checked;
                                            //     // update the item or send it to the server
                                            // }}
                                            />
                                        </span>
                                    </td>
                                    </>
                                )
                            ) : (
                                <p>This Item Is Not Exist</p>
                            )
                        }
                    </tr>
                ))}
            </tbody>
        </Table >
    )
}

export default TableData