import React, { useState, useContext, useEffect } from "react";
import "./Branches.scss";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import PaginationComponent from "../pagination/PaginationComponent";
import MerchantToolHistory from "../MerchantToolHistory/MerchantToolHistory";
import { Accordion, Form } from "react-bootstrap";
import DropdownTabel from "../dropdown/dropdownTabel";
import { Label } from "@mui/icons-material";

const Branches = () => {
  const location = useLocation();
  const { brandId } = location.state;
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [brandBranches, setBrandBranches] = useState([]);

  const [newBranch, setNewBranch] = useState({
    id: null,
    title_en: "",
    title_ar: "",
    city: "",
    area: "",
    branch_optional_identifier: "",
    phone_numbers: "",
    virtual_branch: false,
    // email: "",
    lat: "",
    lng: "",
    location_link: "",
  });

  
  const [page, setPage] = useState(1);
  const [branchesDetail, setBranchesDetail] = useState();
  const { handleLoader, permissions } = useContext(UserContext);
  // const [branchesIds, setBranchesIds] = useState([]);
  // const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [actionSelected, setActionSelected] = useState();
  const [branchSelected, setBranchSelected] = useState();
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [newareasOptions, setNewAreasOptions] = useState([]);
  const [selectedCityMap, setSelectedCityMap] = useState({});

  const getKioskCities = () => {
    UserUtility.getKioskCities()
      .then((res) => {
        if (Array.isArray(res)) {
          setCitiesOptions(res);
        } else {
          console.error("Invalid response for cities:", res);
        }
      })
      .catch((err) => console.log("Error fetching cities:", err));
  };

  const getKioskCAreas = (selectCity, index) => {
    UserUtility.getKioskCAreas(selectCity)
      .then((res) => {
        if (Array.isArray(res)) {
          const updatedAreas = [...areasOptions];
          updatedAreas[index] = res;
          setAreasOptions(updatedAreas);
        } else {
          console.error("Invalid response for areas:", res);
        }
      })
      .catch((err) => console.log("err of getKioskCities: ", err));
  };

  const getNewKioskCAreas = (selectCity) => {
    UserUtility.getKioskCAreas(selectCity)
      .then((res) => {
        setNewAreasOptions(res);
      })
      .catch((err) => console.log("err of getKioskCities: ", err));
  };

  useEffect(() => {
    getKioskCities();
  }, []);

  useEffect(() => {
    if (Object.keys(selectedCityMap).length > 0) {
      Object.keys(selectedCityMap).forEach((index) => {
        const city = selectedCityMap[index];
        getKioskCAreas(city, index);
      });
    }
  }, [selectedCityMap]);

  const getBranches = () => {
    handleLoader(true);
    setBrandBranches([]);

    UserUtility.getBrandBranches(brandId, page)
      .then((res) => {
        setBrandBranches(res?.results?.length && res?.results);
        setBranchesDetail(res);
        handleLoader(false);
      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  };

  //handle pagination page
  const handleChange = (e, value) => {
    // if(isHistoryOpen){
    //   setIsHistoryOpen(false)
    // }
    setIsHistoryOpen(false);
    setPage(value);
  };

  //edit branches
  const handleBranches = (index, branchKey, e) => {
    let value =
      branchKey == "virtual_branch" ? e.target.checked : e.target.value;

    if (branchKey === "city") {
      const updatedSelectedCityMap = { ...selectedCityMap, [index]: value };
      setSelectedCityMap(updatedSelectedCityMap);
    }

    const updatedBranches = [...brandBranches];
    updatedBranches[index] = {
      ...updatedBranches[index],
      [branchKey]: value,
    };
    setBrandBranches(updatedBranches);
  };

  const handleNewBranch = (e) => {
    const { name, value, checked } = e.target || e;
    console.log("checked: ", checked);

    if (name === "city") {
      getNewKioskCAreas(value);
    }

    setNewBranch((prevState) => ({
      ...prevState,
      [name]: name == "virtual_branch" ? checked : value,
    }));
  };

  const handleAddBranch = () => {
    const {
      // id,
      title_en,
      title_ar,
      city,
      area,
      branch_optional_identifier,
      phone_numbers,
      //  email,
      lat,
      lng,
      location_link,
    } = newBranch;
    // console.log("newBranch: ", newBranch);

    if (
      (city && area && branch_optional_identifier,
      title_en && title_ar && lat && lng && location_link)
    ) {
      // const branchData = {
      //   ...newBranch,
      //   cities: [cities],
      //   areas: [areas],
      // };

      handleLoader(true);
      UserUtility.addBranches(newBranch, brandId)
        .then(() => {
          getBranches();
          setNewBranch({
            id: null,
            title_en: "",
            title_ar: "",
            city: "",
            area: "",
            branch_optional_identifier: "",

            phone_numbers: "",
            virtual_branch: false,
            // email: "",
            lat: "",
            lng: "",
            location_link: "",
          });
          handleLoader(false);
        })
        .catch((error) => {
          console.error(error);
          handleLoader(false);
          toast.error(error[0] || "something went wrong");
        });
    } else {
      toast.error("Please fill all input fields");
    }
  };

  const handleEditBranch = (branchId, arrayId) => {
    // console.log("brandBranches", brandBranches);
    handleLoader(true);
    UserUtility.editBranch(brandBranches[arrayId], branchId)
      .then((res) => {
        getBranches();
        handleLoader(false);
      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  };

  // branch actions
  const handleActiveBranch = (branchId) => {
    setBranchSelected(branchId);
  };

  const handleActionPOS = () => {
    // generate pos function
    if (actionSelected === "re_create_pos_admin") {
      handleLoader(true);
      UserUtility.recreatePOSBranch(branchSelected)
        .then((res) => {
          handleLoader(false);
          if (res.status === "error") {
            res?.data
              ? toast.error(res.message + "to brands: " + res.data.toString())
              : toast.error(res.message);
          } else {
            toast.success(res.Success);
          }
        })
        .catch((err) => {
          handleLoader(false);
          toast.error(err.message);
        });
    }
  };

  const newInputs = Object.keys(newBranch).map(function (key) {
    if (key === "city" || key === "area") {
      const options = key === "city" ? citiesOptions : newareasOptions;
      return (
        <StyledTableCell key={key}>
          <DropdownTabel
            name={key}
            value={newBranch[key]}
            options={options}
            onChange={handleNewBranch}
          />
        </StyledTableCell>
      );
    }

    return (
      <StyledTableCell key={key}>
        {key == "virtual_branch" ? (
          <input
            type={"checkbox"}
            checked={newBranch[key]}
            name={key}
            onChange={handleNewBranch}
          />
        ) : (
          <input
            size={"small"}
            className={"branchInput"}
            value={newBranch[key]}
            name={key}
            onChange={handleNewBranch}
            disabled={key == "id"}
            type={key === "branch_optional_identifier" ? "number" : "text"}
          />
        )}
      </StyledTableCell>
    );
  });

  useEffect(() => {
    getBranches();
  }, [page]);

  return (
    <article className="branches_container">
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Title (en)</StyledTableCell>
              <StyledTableCell>Title (ar)</StyledTableCell>
              <StyledTableCell>Governate</StyledTableCell>
              <StyledTableCell>Area</StyledTableCell>
              <StyledTableCell>Branch MDM</StyledTableCell>
              <StyledTableCell>Branch Phones</StyledTableCell>
              <StyledTableCell>Is Online</StyledTableCell>
              {/* <StyledTableCell>Email</StyledTableCell> */}
              <StyledTableCell>LAT</StyledTableCell>
              <StyledTableCell>LNG</StyledTableCell>
              <StyledTableCell>Location link</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {brandBranches.length
              ? brandBranches.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Form.Check
                        type="radio"
                        name="group1"
                        onChange={() => handleActiveBranch(row.id)}
                        checked={row.id === branchSelected}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.id}
                        disabled
                        // onChange={(e) => handleBranches(index, "id", e)}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.title_en}
                        onChange={(e) => handleBranches(index, "title_en", e)}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.title_ar}
                        onChange={(e) => handleBranches(index, "title_ar", e)}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <DropdownTabel
                        name="city"
                        value={row.city}
                        options={citiesOptions}
                        onChange={(e) => handleBranches(index, "city", e)}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <DropdownTabel
                        name="area"
                        value={row.area}
                        options={
                          areasOptions[index] || [
                            {
                              label: brandBranches[index]?.area,
                              value: row.area,
                            },
                          ]
                        }
                        onChange={(e) => handleBranches(index, "area", e)}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.branch_optional_identifier}
                        type="number"
                        onChange={(e) =>
                          handleBranches(index, "branch_optional_identifier", e)
                        }
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.phone_numbers}
                        onChange={(e) =>
                          handleBranches(index, "phone_numbers", e)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <input
                        style={{ width: 70 }}
                        type="checkbox"
                        checked={row.virtual_branch}
                        onChange={(e) =>
                          handleBranches(index, "virtual_branch", e)
                        }
                      />
                    </StyledTableCell>

                    {/* <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.email}
                        onChange={(e) => handleBranches(id, "email", e)}
                      />
                    </StyledTableCell> */}

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.lat}
                        onChange={(e) => handleBranches(index, "lat", e)}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.lng}
                        onChange={(e) => handleBranches(index, "lng", e)}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <input
                        className="branchInput"
                        defaultValue={row.location_link}
                        onChange={(e) =>
                          handleBranches(index, "location_link", e)
                        }
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      {permissions.includes("BD_merchant_creation_tool") && (
                        <Button
                          variant="contained"
                          type="submit"
                          className="editBtn"
                          onClick={() => handleEditBranch(row.id, index)}
                        >
                          save
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}

            {permissions.includes("BD_merchant_creation_tool") && (
              <>
                <StyledTableRow>
                  <StyledTableCell>
                    <Form.Check type="radio" checked={false} disabled />
                  </StyledTableCell>

                  {newInputs}

                  <StyledTableCell>
                    <Button
                      variant="contained"
                      onClick={handleAddBranch}
                      type="submit"
                      className="editBtn"
                    >
                      add
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* new actions wit new permission and all new action must have (Finance_merchant_creation_tool) permission */}
      {permissions.includes("action_generate_pos_users") &&
        permissions.includes("BD_merchant_creation_tool") && (
          <div
            style={{
              display: "flex",
              marginBottom: 20,
              width: "30%",
              marginTop: 40,
            }}
          >
            <Form.Select
              style={{ marginRight: 10 }}
              size="md"
              onChange={(e) => {
                setActionSelected(e.target.value);
              }}
            >
              <option value={""}>-------</option>
              <>
                <option value="re_create_pos_admin" name="Re create pos admin">
                  Re create pos admin
                </option>
              </>
            </Form.Select>
            <Button
              variant="contained"
              type="submit"
              className="px-5 editBtn"
              onClick={handleActionPOS}
            >
              Submit
            </Button>
          </div>
        )}

      <PaginationComponent
        brands={branchesDetail}
        page={page}
        handleChange={handleChange}
        total={false}
      />

      <Accordion
        activeKey={isHistoryOpen ? "0" : ""}
        className="accordClass"
        style={{ marginTop: 30, marginRight: 0, marginLeft: 0 }}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header
            onClick={() => {
              console.log("************************");
              // console.log("************************");
              setIsHistoryOpen(!isHistoryOpen);
            }}
          >
            History
          </Accordion.Header>
          <Accordion.Body>
            <MerchantToolHistory
              type="branch"
              ids={brandBranches ? brandBranches.map(({ id }) => id) : []}
              isState={isHistoryOpen}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </article>
  );
};

export default Branches;
