import React from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const Tables = (props) => {
  const { type } = props;
  const { alert } = props;
  const { session } = props;
  const { lawsuit } = props;
  const { fees } = props;
  const { user_id } = props;
  const { historyUser_id } = props;
  return (
    <div>
      <Form.Text className="fs-4 fw-bold" style={{ color: "black" }}>
        {type}
      </Form.Text>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {" "}
                {type === "الإنذارات"
                  ? "رقم الإنذار"
                  : type === "الدعاوي"
                  ? "رقم الدعوي"
                  : type === "الجلسات"
                  ? "التاريخ"
                  : "التعليق"}
              </StyledTableCell>
              <StyledTableCell>
                {" "}
                {type === "الإنذارات"
                  ? "تاريخ الإحالة"
                  : type === "الدعاوي"
                  ? "المحكمة"
                  : type === "الجلسات"
                  ? "الحالة"
                  : "القيمة"}
              </StyledTableCell>
              <StyledTableCell>
                {" "}
                {type === "الإنذارات"
                  ? "تاريخ الأرشيف"
                  : type === "الدعاوي"
                  ? "الدائرة المختصة"
                  : type === "الجلسات"
                  ? "تاريخ الجلسة الجديدة"
                  : "مدفوع"}
              </StyledTableCell>
              {type === "الإنذارات" && (
                <StyledTableCell>اسم المكتب</StyledTableCell>
              )}
              {type === "الإنذارات" && (
                <StyledTableCell>تم التصالح</StyledTableCell>
              )}
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(type === "الإنذارات"
              ? alert
              : type === "الجلسات"
              ? session
              : type === "الدعاوي"
              ? lawsuit
              : fees
            )?.map((e, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {e.alert_num}
                    {type === "الجلسات" && e.date}
                    {e.lawsuit_num}
                    {type === "المصروفات" && e.comment}
                  </StyledTableCell>
                  <StyledTableCell>
                    {e.referral_date}
                    {e.status}
                    {type === "الدعاوي" && e.court_name}
                    {type === "المصروفات" && e.legal_fees}
                  </StyledTableCell>
                  <StyledTableCell>
                    {e.received_date_from_archieve}
                    {e.next_session_date}
                    {e.department_name}
                    {type === "المصروفات" && (
                      <Form.Check type="checkbox" checked={e.paid} disabled />
                    )}
                  </StyledTableCell>
                  {alert && <StyledTableCell>{e.office_name}</StyledTableCell>}
                  {alert && (
                    <StyledTableCell>
                      <Form.Check
                        type="checkbox"
                        // onChange={() => handleActiveUser(index, ele.id)}
                        checked={e.is_reconciled}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    <Link
                      to={
                        type === "الإنذارات"
                          ? "/Alert"
                          : type === "الدعاوي"
                          ? "/Lawsuit"
                          : type === "الجلسات"
                          ? "/Session"
                          : "/Fees"
                      }
                      state={{
                        historyUser_id,
                        request_id: e.id,
                        user_id,
                        national_Id: e.customer_national_id,
                      }}
                    >
                      <Button style={{ paddingInline: 50 }} variant="success">
                        {historyUser_id ? "التفاصيل" : "تعديل"}
                      </Button>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Tables;
