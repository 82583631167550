import React, { useState, useEffect, useContext } from "react";
import "./CategoryDetails.scss";
import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Colors,
  // Colors,
  StyledTableCell,
  StyledTableRow,
} from "../../constants/Colors";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as plans from "./Plans.json";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import { Accordion } from "react-bootstrap";
import MerchantToolHistory from "../MerchantToolHistory/MerchantToolHistory";

const CategoryDetails = () => {
  const { brand: categoryId = "" } = useParams();
  const location = useLocation();
  // const { brandName = "", categoryName, is_online, published, min_percentage } = location?.state;
  const { categoryDetail, brandName = "", brandId } = location?.state;
  console.log('categoryDetail: ', categoryDetail)
  // const [searchValue, setSearchValue] = useState(categoryName);
  const [isOnline] = useState(categoryDetail?.is_online || false);
  const [isPublish] = useState(categoryDetail?.published || false);
  const [categories, setCategories] = useState([]);
  const [newInstallments, setNewInstallments] = useState(true);
  const { handleLoader, permissions } = useContext(UserContext);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)

  const defualtMinArr = [
    {
      months: 6,
      value: categoryDetail?.min_percentage_6_months
    },
    {
      months: 9,
      value: categoryDetail?.min_percentage_9_months
    },
    {
      months: 12,
      value: categoryDetail?.min_percentage_12_months
    },
    {
      months: 18,
      value: categoryDetail?.min_percentage_18_months
    },
    {
      months: 24,
      value: categoryDetail?.min_percentage_24_months
    },
    {
      months: 30,
      value: categoryDetail?.min_percentage_30_months
    },
    {
      months: 36,
      value: categoryDetail?.min_percentage_36_months
    },
    {
      months: 42,
      value: categoryDetail?.min_percentage_42_months
    },
    {
      months: 48,
      value: categoryDetail?.min_percentage_48_months
    },
    {
      months: 54,
      value: categoryDetail?.min_percentage_54_months
    },
    {
      months: 60,
      value: categoryDetail?.min_percentage_60_months
    },
    {
      months: 61,
      value: categoryDetail?.min_percentage_61_months
    }
  ]

  let navigate = useNavigate();

  useEffect(() => {
    getInstallmentPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*
  //to handle active plan check (not used)
  const handleActivePlan = (index) => {
    const newCategories = categories.map((category, id) => {
      return {
        ...category,
        published: index === id ? !category.published : category.published,
      };
    });
    setCategories(newCategories);
  };
*/
  const handlePlanNumbers = (index, planKey, e) => {
    const newCategories = categories.map((category, id) => {
      return {
        ...category,
        [planKey]:
          index === id
            ? planKey === "grace_period"
              ? e.target.value
              : parseFloat(e.target.value)
            : parseFloat(category[planKey]),
        total: calculateMax((index === id && planKey === 'interest_rate') ? parseFloat(e.target.value) : category.interest_rate, category.admin_fees.value, category.admin_fees.kick_back, category.admin_fees.forsa_processing_fees, category.admin_fees.paymob_processing_fees)
      };
    });
    setCategories(newCategories);
  };
  //to handle object of object
  const handlePlanNumbers2 = (index, planKey, keyOfPlanKey, e) => {
    const newCategories = categories.map((category, id) => {
      return {
        ...category,
        [planKey]: {
          ...category[planKey],
          [keyOfPlanKey]:
            index === id
              ? parseFloat(e.target.value)
              : parseFloat(category[planKey][keyOfPlanKey]),
        },

        total: calculateMax(category.interest_rate, (index === id && keyOfPlanKey === 'value') ? parseFloat(e.target.value) : category.admin_fees.value,
          (index === id && keyOfPlanKey === 'kick_back') ? parseFloat(e.target.value) : category.admin_fees.kick_back,
          (index === id && keyOfPlanKey === 'forsa_processing_fees') ? parseFloat(e.target.value) : category.admin_fees.forsa_processing_fees,
          (index === id && keyOfPlanKey === 'paymob_processing_fees') ? parseFloat(e.target.value) : category.admin_fees.paymob_processing_fees
        )
      };
    });


    setCategories(newCategories);
  };

  const getInstallmentPlans = () => {
    handleLoader(true);
    UserUtility.getInstallmentPlans(categoryId)
      .then((res) => {
        if (res?.results.length) {
          const newCat = res?.results.map((category, index) => {
            return {
              ...category,
              min_percentage: defualtMinArr[index]?.months === res?.results[index]?.months ? defualtMinArr[index].value : null,
              total: calculateMax(category.interest_rate, category.admin_fees.value, category.admin_fees.kick_back, category.admin_fees.forsa_processing_fees, category.admin_fees.paymob_processing_fees)
            }
          })

          setCategories(newCat);

          setNewInstallments(false);
        } else {
          getDefaultInstall()
          setNewInstallments(true);
        }
        handleLoader(false);
      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  };

  const updatePlans = () => {
    if (newInstallments) {
      const convertDataToCreate = categories.map((item) => ({
        published: item.published,
        months: item?.months,
        interest_rate: parseFloat(item.interest_rate),
        admin_fees: item.admin_fees.value,
        grace_period: `${item.grace_period}`,
        kick_back: parseFloat(item.admin_fees.kick_back),
        forsa_processing_fees: parseFloat(
          item.admin_fees.forsa_processing_fees
        ),
        paymob_processing_fees: parseFloat(
          item.admin_fees.paymob_processing_fees
        ),
      }));
      
      const installmetPlans = {
        title: categoryDetail?.title,
        is_online: isOnline,
        published: isPublish,
        // installment_plans: [...convertDataToCreate],
        installment_plans: convertDataToCreate
      };
      UserUtility.createInstallmentPlans(categoryId, installmetPlans)
        .then((res) => {
          navigate(-1);
        })
        .catch((error) => console.error(error));
    } else {
      const convertDataToUpdate = categories.map((item) => ({
        id: item.id,
        published: item.published,
        brand: item.admin_fees.brand,
        category: categoryId,
        months: item?.months,
        interest_rate: item.interest_rate,
        grace_period: item.grace_period,
        admin_fees: item.admin_fees,
      }));

      console.log('convertDataToUpdate: ', convertDataToUpdate)

      UserUtility.editInstallments(convertDataToUpdate)
        .then((res) => {
          getInstallmentPlans();
          navigate(-1);
        })
        .catch((error) => console.error(error));

    }
  };

  // to handle publish button to publish or unpublish
  const publishChange = (planId, publishState) => {
    handleLoader(true);
    UserUtility.publishInstallmentPlans(planId, publishState)
      .then(() => {
        handleLoader(false);
        getInstallmentPlans();
      })
      .catch((error) => {
        console.error("publish installment plan error", error);
        handleLoader(false);
      });
  };

  const publishSwitch = (isPublish, item) => {
    let id = item.id;
    /*
    let grace_period =
      item?.grace_period === 1
        ? item?.grace_period
        : (typeof item?.grace_period == 'string') ? parseInt(item?.grace_period.split(" ", 1)) : '';
        */
    switch (true) {
      case isPublish && !permissions.includes("BD_merchant_creation_tool"):
        return "Published";
        break;

      case isPublish && permissions.includes("BD_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.red,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, false)}
          >
            Unpublish
          </Button>
        );
        break;

      case !isPublish && permissions.includes("Finance_merchant_creation_tool"):
        return (
          <>
            <Button
              style={{
                minWidth: 100,
                backgroundColor: Colors.primary,
              }}
              type="submit"
              variant="contained"
              disabled={(item?.total < item?.min_percentage) || (item?.admin_fees?.forsa_processing_fees === 0 && item?.admin_fees?.paymob_processing_fees === 0)}
              onClick={() => publishChange(id, true)}
            >
              Publish
            </Button>
          </>
        );
        break;

      case !isPublish && !permissions.includes("Finance_merchant_creation_tool"):
        return "Not Published";
        break;

      default:
        break;
    }
  };

  const calculateMax = (interest, fees, kick, forsa_processing, paymob) => {
    return (interest ? interest : 0) + (fees ? fees : 0) + (kick ? kick : 0) + (forsa_processing ? forsa_processing : 0) + (paymob ? paymob : 0)
  }

  // to get default installment values releated to sectors , will call this function in case category return []
  const getDefaultInstall = () => {
    UserUtility.getInstallmentDefault(brandId).then(res => {
      const newArr = res?.results.map((category, index) => {
        return {
          ...category,
          min_percentage: defualtMinArr[index]?.months === plans.rows[index]?.months ? defualtMinArr[index].value : null,
          total: calculateMax(category.interest_rate, category.admin_fees.value, category.admin_fees.kick_back, category.admin_fees.forsa_processing_fees, category.admin_fees.paymob_processing_fees)
        }
      })
      setCategories(newArr)
      if (res.next) {
        getPaginateData(res.next)
      }
    }).catch(err => {
      console.log('err of getDefaultInstall: ', err)
    })
  }

  const getPaginateData = (url) => {
    UserUtility.getNextPageData(url)
      .then((res) => {
        const newArr = res?.results.map((category, index) => {
          return {
            ...category,
            min_percentage: defualtMinArr[index]?.months === plans.rows[index]?.months ? defualtMinArr[index].value : null,
            total: calculateMax(category.interest_rate, category.admin_fees.value, category.admin_fees.kick_back, category.admin_fees.forsa_processing_fees, category.admin_fees.paymob_processing_fees)
          }
        })
        setCategories((old) => [...old, ...newArr]);
        if (res.next) {
          getPaginateData(res.next)
        }
      })
      .catch((err) => console.log(err));
  };

  // to filter categories that return [] in case defualt installment not include on ids
  const filteredCategories = categories.filter((item) => item?.id);

  return (
    <main className="newCategory_container">
      <h2 className="brand_title">
        {`Brands / ${brandName} / Categories / `}
        <span>{categoryDetail?.title}</span>
      </h2>
      {/* <article>
        <TextField
          size="small"
          placeholder="ex: Big Zero"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className="text-field"
          label="Category name"
        />
      </article> */}

      {/* <article className="header_wrapper">
        <section>
          <button onClick={() => setIsOnline(!isOnline)} className="checkBtn">
            <Checkbox size="small" checked={isOnline} />
          </button>
          <span> Is Online</span>
        </section>
        <Button
          style={{
            minWidth: 100,
            backgroundColor: isPublish ? Colors.red : Colors.primary,
          }}
          type="submit"
          variant="contained"
          onClick={() => setIsPublish(!isPublish)}
        >
          {isPublish ? "Unpublish" : "Publish"}
        </Button>
      </article> */}

      {/* table container */}
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell> </StyledTableCell> */}
              <StyledTableCell>No of months</StyledTableCell>
              <StyledTableCell>Interest rate (%)</StyledTableCell>
              <StyledTableCell>Admin fees (%)</StyledTableCell>
              <StyledTableCell>Kick back (%)</StyledTableCell>
              <StyledTableCell>Forsa proccessing fees (%)</StyledTableCell>
              <StyledTableCell>Paymob processing fees (%)</StyledTableCell>
              <StyledTableCell>Grace period</StyledTableCell>
              <StyledTableCell>Min percentage (%)</StyledTableCell>
              <StyledTableCell>Publish</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {categories?.map((row, id) => (
              <StyledTableRow key={id}>
                {/*
                not used
                 <StyledTableCell style={{ borderRight: "1px dashed #707070" }}>
                  <button
                    onClick={() => handleActivePlan(id)}
                    className="checkBtn"
                  >
                    <Checkbox size="small" checked={row.published} />
                  </button>
                </StyledTableCell> */}
                <StyledTableCell>{row.months}</StyledTableCell>
                <StyledTableCell>
                  <input
                    step="any"
                    type="number"
                    className="input"
                    onChange={(e) => handlePlanNumbers(id, "interest_rate", e)}
                    defaultValue={row.interest_rate}
                    disabled={
                      permissions.includes("Finance_merchant_creation_tool") ||
                      row.published
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <input
                    step="any"
                    type="number"
                    className="input"
                    onChange={(e) =>
                      handlePlanNumbers2(id, "admin_fees", "value", e)
                    }
                    defaultValue={row.admin_fees.value}
                    disabled={
                      permissions.includes("Finance_merchant_creation_tool") ||
                      row.published
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <input
                    step="any"
                    type="number"
                    className="input"
                    onChange={(e) =>
                      handlePlanNumbers2(id, "admin_fees", "kick_back", e)
                    }
                    defaultValue={row.admin_fees.kick_back}
                    disabled={
                      permissions.includes("Finance_merchant_creation_tool") ||
                      row.published
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <input
                    step="any"
                    type="number"
                    className="input"
                    onChange={(e) =>
                      handlePlanNumbers2(
                        id,
                        "admin_fees",
                        "forsa_processing_fees",
                        e
                      )
                    }
                    defaultValue={row.admin_fees.forsa_processing_fees}
                    disabled={
                      permissions.includes("Finance_merchant_creation_tool") ||
                      row.published
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <input
                    step="any"
                    type="number"
                    className="input"
                    onChange={(e) =>
                      handlePlanNumbers2(
                        id,
                        "admin_fees",
                        "paymob_processing_fees",
                        e
                      )
                    }
                    defaultValue={row.admin_fees.paymob_processing_fees}
                    disabled={
                      permissions.includes("Finance_merchant_creation_tool") ||
                      row.published
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <input
                    // type="number"
                    className="input"
                    style={{ width: "100px" }}
                    onChange={(e) => handlePlanNumbers(id, "grace_period", e)}
                    defaultValue={row.grace_period}
                    disabled
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <span className="total_flag"
                    style={{ backgroundColor: (row?.total >= row?.min_percentage) ? "#f7a46b" : "#e74c3c" }}
                  >{row?.total}</span>
                </StyledTableCell>

                <StyledTableCell>
                  {publishSwitch(row.published, row)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* history */}
      {/* history */}
      <Accordion defaultActiveKey="-1" className="accordClass" style={{ marginTop: 30, marginRight: 0, marginLeft: 0 }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={() => {
            setIsHistoryOpen(!isHistoryOpen)
          }} >
            History
          </Accordion.Header>
          <Accordion.Body onExiting={() => {
            setIsHistoryOpen(false)
          }}>

            <MerchantToolHistory type="category" id={categoryId} secType="installment" isState={isHistoryOpen} installmentIds={filteredCategories.map(({ id }) => id)} />
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>


      {!permissions.includes("Finance_merchant_creation_tool") &&
        !permissions.includes("Marketing_merchant_creation_tool") &&
        permissions.includes("BD_merchant_creation_tool") &&
        !categoryDetail?.published ? (
        <div className="submitBtn">
          <Button
            variant="contained"
            type="submit"
            style={{ marginLeft: 0 }}
            onClick={updatePlans}
          >
            Save section
          </Button>
        </div>
      ) : (
        ""
      )}
    </main>
  );
};

export default CategoryDetails;
