/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Col, Row } from "react-bootstrap";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { feesFileds } from "./legalFields";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
const Fees = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { id, historyUser_id, customerName, national_Id, request_id, user_id } =
    location.state;
  const [data, setData] = useState([]);
  console.log("data", data);

  useEffect(() => {
    if (request_id) return get_fees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request_id]);
  const get_fees = () => {
    UserUtility.get_fees(request_id, user_id || historyUser_id)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error("get_fees", err);
      });
  };

  const update_fees = () => {
    if (data?.legal_fees) {
      delete data.updated_by;
      UserUtility.update_fees(request_id, user_id, data)
        .then(() => {
          navigate("/Main", {
            state: {
              customer_id: national_Id,
            },
          });
        })
        .catch((err) => {
          console.error("update_fees", err);
        });
    } else {
      toast.error("يجب اضافه قيمة");
    }
  };

  const create_fees = () => {
    if (data?.legal_fees) {
      data.user = id;
      UserUtility.create_fees(id, data)
        .then(() => {
          navigate("/Main", {
            state: {
              customer_id: national_Id,
            },
          });
        })
        .catch((err) => {
          console.error("create_fees", err);
        });
    } else {
      toast.error("يجب اضافه قيمة");
    }
  };
  const handleSubmit = () => {
    if (request_id) {
      update_fees();
    } else {
      create_fees();
    }
  };
  return (
    <div className="Legal" style={{ textAlign: "right" }} dir="rtl">
      {!historyUser_id && (
        <Form.Label className="fs-4 fw-bold">إضافة مصروفات جديدة</Form.Label>
      )}
      <InputGroup>
        <Row>
          {feesFileds.map((field, index) =>
            field.type === "textarea" ? (
              <Col lg={8} md={8} sm={12} className="mt-2 " key={index}>
                <Form.Label>{field.label}</Form.Label>
                <Form.Control
                  disabled={historyUser_id}
                  value={data[field.name]}
                  name="value"
                  onChange={(e) =>
                    setData({ ...data, [field.name]: e.target.value })
                  }
                  type="text"
                  as="textarea"
                  className="shadow rounded "
                  rows={5}
                  // required
                />
              </Col>
            ) : field.type === "space" ? (
              <Col
                lg={2}
                // md={6}
                sm={12}
                key={index}
              />
            ) : field.type === "date" ? (
              <Col lg={3} md={3} sm={12} key={index} className="mt-2 ">
                <Form.Group
                  className="d-flex flex-column position-relative m-0"
                  style={{ zIndex: 10, right: "5px" }}
                >
                  <Form.Label>{field.label}</Form.Label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      readOnly={true}
                      inputFormat="dd/MM/yyyy"
                      value={
                        data[field.name]
                          ? new Date(data[field.name]) || null
                          : null
                      }
                      onChange={(e) =>
                        setData({
                          ...data,
                          [field.name]: e
                            ? moment(e).format("YYYY-MM-DD")
                            : null,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Form.Group>
              </Col>
            ) : field.type === "checkbox" ? (
              <Col lg={3} md={5} sm={12} key={index} className="mt-2 ">
                <Form.Label>{field.label}</Form.Label>
                <Form.Check
                  disabled={historyUser_id ? true : field.disabled}
                  type="checkbox"
                  onChange={(e) =>
                    setData({ ...data, [field.name]: e.target.checked })
                  }
                  checked={data[field.name]}
                />
              </Col>
            ) : (
              <Col lg={3} md={5} sm={12} key={index} className="mt-2 ">
                <Form.Label>{field.label}</Form.Label>
                <Form.Control
                  disabled={historyUser_id ? true : field.disabled}
                  // value={}
                  name="value"
                  value={
                    field.name === "customer_name"
                      ? customerName || data[field.name]
                      : field.name === "customer_national_id"
                      ? national_Id || data[field.name]
                      : data[field.name]
                  }
                  onChange={(e) =>
                    setData({ ...data, [field.name]: e.target.value })
                  }
                  type="text"
                  className="shadow rounded"
                  // required
                />
              </Col>
            )
          )}
        </Row>
        {!historyUser_id && (
          <section className="w-25 mx-auto mt-5 ">
            <Button
              variant="success"
              onClick={() => handleSubmit()}
              className="w-100 mt-5"
              // disabled={loading}
            >
              حفظ
            </Button>
          </section>
        )}
      </InputGroup>
    </div>
  );
};

export default Fees;
