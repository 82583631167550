import React from "react";
import Multiselect from "multiselect-react-dropdown";
const MultipleSelection = ({
  optionsItem,
  label,
  finalSelectedList,
  selectedValues,
  hidePlaceholder,
  isTitle,
  disabled,
  isSingleSelect
}) => {
  const selectedValuesCustom =
    selectedValues && selectedValues.length > 0
      ? selectedValues.map((item) => {
        return { id: item.kiosk_user_id, email: item.email };
      })
      : [];


  const selectedValuesTags = selectedValues && selectedValues.length > 0
    ? selectedValues : [];


  const handleSelected = (selectedList, selectedItem) => {
    finalSelectedList(selectedList);
  };

  const handleRemoved = (selectedList, removedItem) => {
    finalSelectedList(selectedList);
  };
  return (
    <Multiselect
      className="customSelection"
      displayValue={isTitle ? 'title' : 'email'}
      placeholder={`select ${label}`}
      onKeyPressFn={function noRefCheck() { }}
      onRemove={handleRemoved}
      onSearch={function noRefCheck() { }}
      onSelect={handleSelected}
      options={optionsItem}
      selectedValues={isTitle ? selectedValuesTags : selectedValuesCustom}
      showCheckbox={isSingleSelect ? false : true}
      hidePlaceholder
      avoidHighlightFirstOption
      loading={optionsItem.length > 0 ? false : true}
      disable={disabled ? true : false}
      selectionLimit={isSingleSelect ? 1 : -1}
    />
  );
};

export default MultipleSelection;

