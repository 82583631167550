import React, { useEffect, useState } from "react";
import { UserUtility } from "../../apis/UserUtility";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";

const HistoryDocuments = () => {
  const [document, setdocument] = useState();

  const documents_fields = [
    {
      fieldLabel: "National Id Front_Image:",
      fieldName: "national_id_front_image",
    },
    {
      fieldLabel: "National Id Back_Image:",
      fieldName: "national_id_back_image",
    },
    { fieldLabel: "Image profile:", fieldName: "image_profile"},
    { fieldLabel: "Club Front_Image:", fieldName: "club_front_image" },
    { fieldLabel: "Club Back_Image:", fieldName: "club_back_image" },
    { fieldLabel: "Front_Licence_Image:", fieldName: "front_licence_image" },
    { fieldLabel: "Back_Licence_Image:", fieldName: "back_licence_image" },
    { fieldLabel: "Contract:", fieldName: "contract" },
    { fieldLabel: "Contract_Back:", fieldName: "contract_back" },
    { fieldLabel: "Contract_Image_4:", fieldName: "contract_image_4" },
    { fieldLabel: "Contract_Image_5:", fieldName: "contract_image_5" },
    { fieldLabel: "Contract_Image_6:", fieldName: "contract_image_6" },
    { fieldLabel: "Contract_Extra:", fieldName: "contract_extra" },
    { fieldLabel: "Additional_Document:", fieldName: "additional_document" },
  ];

  const location = useLocation();
  const { userId } = location.state;
  const user_document = () => {
    UserUtility.user_document(userId)
      .then((res) => {
        setdocument(res);
      })
      .catch((err) => console.error("user_document_err", err));
  };
  useEffect(() => {
    user_document();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="mt-5">
      <h4 className="text-center">User documents</h4>

      <Row className="mt-4" md={2} sm={1} xs={1}>
        {document &&
          documents_fields.map((res, id) => (
            <Col className="d-flex flex-column align-items-center" key={id}>
              <h4 className="text-center mt-4">{res.fieldLabel}</h4>
              {document[res.fieldName]?
              <ReactImageMagnify
              {...{
                smallImage: {
                  alt: `${res.fieldLabel}`,
                  isFluidWidth: true,
                    src: ` ${document[res.fieldName] }`,
                  },
                  largeImage: {
                    src: `${document[res.fieldName] }`,
                    width: 1800,
                    height: 1000,
                  },
                  imageStyle: {
                    borderRadius: 10,
                  },
                  
                  enlargedImagePosition: "over",
                }}
                />
              :null}
                </Col>
                ))}
                </Row>
                </article>
                );
              };
              export default HistoryDocuments;
              