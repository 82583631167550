import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import "./OneTransactionStyle.scss";
import moment from "moment";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";



export const FinanceOneTransaction = () => {
  let navigate = useNavigate();
  const { permissions } = useContext(UserContext);
  const [finance_list, setFinance_list] = useState();
  const [searcnNational, setSearchNational] = useState();
  const [totalList, setTotalList] = useState();
  const [page, setPage] = useState(1);
  const [creditSelected, setCreditSelected] = useState();
  const studingFinance = { status: "studying_finance" };


  const financeList = (searcnNational) => {
    UserUtility.financeList(page, searcnNational, creditSelected)
      .then((res) => {
        setTotalList(res);
        setFinance_list(res.results);
      })
      .catch((err) => {
        console.error("financeList:", err);
      });
  };
  const handleChange = (e, value) => {
    setPage(value);
  };
  const contractsSearch = (e) => {
    e.preventDefault();
    financeList(searcnNational);
  };

  const creatContractFun = (profileData) => {
    console.log('profileData?.onetransactionloandata?.status: ',profileData?.onetransactionloandata?.status)
    if (profileData?.onetransactionloandata?.status === "approved_operation") {
      UserUtility.sendToOperationChekcer(profileData.id, studingFinance)
        .then(() => { })
        .catch((err) => {
          console.error("creatContractFun err:", err);
          toast.error(err)
        });
    }
    navigate(`/oneTransaction/credit/creditMemo/${profileData.id}/`, {
      state: {
        loanId: profileData.id,
        showHistory: true,
        isViewOnly: false,
        fromPage: 'financeTab'
      }
    })
  };

  useEffect(() => {
    financeList();
  }, [page]);

  return (
    <main className="oneTransaction_container">
      <section>
        <form>
          <article className="searchBar_wrapper searchBar_wrapper_full">
            <section className="search_fields_wrapper">
              <TextField
                size="small"
                placeholder="National ID / Phone Number"
                value={searcnNational}
                onChange={(e) => {
                  setSearchNational(e.target.value);
                }}
                className="text-field"
                label="National ID / Phone Number"
              />
              <FormControl className="select-field">
                <InputLabel>Credit Status</InputLabel>
                <Select
                  className="select_onetrans_class"
                  size="small"
                  id="demo-simple-select"
                  // value={creditSelected}
                  label="Credit Status"
                  onChange={(e) => setCreditSelected(e.target.value)}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="approved_operation">
                    Approved Operation
                  </MenuItem>
                  <MenuItem value="studying_finance">Studying Finance</MenuItem>
                  <MenuItem value="restudying_finance">
                    Restudying Finance
                  </MenuItem>
                  <MenuItem value="checking_finance">Checking_finance</MenuItem>
                </Select>
              </FormControl>
              <Button
                className="searchButton"
                type="submit"
                variant="contained"
                onClick={(e) => contractsSearch(e)}
              >
                Search
              </Button>
            </section>
          </article>
          {/* filters container */}
        </form>
      </section>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>National ID</StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Application Status</StyledTableCell>

              <StyledTableCell>IScore Date</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finance_list?.map((ele, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {ele.userprofile.onetransactionprofile.national_id}
                  </StyledTableCell>
                  <StyledTableCell>
                    {ele.userprofile.onetransactionprofile.first_name +
                      " " +
                      ele.userprofile.onetransactionprofile.last_name}
                  </StyledTableCell>
                  <StyledTableCell>{ele.created}</StyledTableCell>
                  <StyledTableCell>
                    {ele.onetransactionloandata.status}
                  </StyledTableCell>

                  <StyledTableCell>
                    {moment(ele.onetransactionloandata.iscore_date).format(
                      "DD-MM-YYYY"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {[
                      "approved_operation",
                      "studying_finance",
                      "restudying_finance",
                    ].some((i) =>
                      ele.onetransactionloandata.status.includes(i)
                    ) &&
                      permissions.includes("one_transaction_finance_maker") && (
                        <Button
                          className="small_btn"
                          type="click"
                          variant="contained"
                          onClick={() => creatContractFun(ele)}
                        >
                          Check
                        </Button>
                      )}
                    {ele.onetransactionloandata.status === "checking_finance" &&
                      permissions.includes(
                        "one_transaction_finance_checker"
                      ) && (
                        <Button
                          className="small_btn"
                          type="click"
                          variant="contained"
                          onClick={() => (
                            creatContractFun(ele)
                          )}
                        >
                          Book
                        </Button>
                      )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={totalList}
        page={page}
        handleChange={handleChange}
      />
    </main>
  );
};
