/* eslint-disable no-sequences */
import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";

import Modal from "react-modal";

const LoginHistory = ({ deviceLogin, setDeviceLogin }) => {
  const { handleLoader, permissions } = useContext(UserContext);

  const [openLoginDateModified, setOpenLoginDateModified] = useState(false);
  const [openLoginDateCreated, setOpenLoginDateCreated] = useState(false);

  const DeviceReport = ["is_user_dashboard_user" && "is_staff"].some((i) =>
    permissions.includes(i)
  );
  const defaultDate = new Date("2022-01-01");
  const defaultDateForm = format(defaultDate, "MM/dd/yyyy");

  const [loginModifiedDate, setLoginModifiedDate] = useState([
    {
      startDate: defaultDate,
      endDate: defaultDate,
      key: "selection",
    },
  ]);
  const [loginCreatedDate, setLoginCreatedDate] = useState([
    {
      startDate: defaultDate,
      endDate: defaultDate,
      key: "selection",
    },
  ]);

  const loginModifiedStartDate = format(
    loginModifiedDate[0].startDate,
    "MM/dd/yyyy"
  );
  const loginModifiedEndDate = format(
    loginModifiedDate[0]?.endDate,
    "MM/dd/yyyy"
  );
  const loginCreatedStartDate = format(
    loginCreatedDate[0]?.startDate,
    "MM/dd/yyyy"
  );
  const loginCreatedEndDate = format(
    loginCreatedDate[0]?.endDate,
    "MM/dd/yyyy"
  );
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "85%",
      overflowY: "scroll",
      height: "85%",
    },
  };
  toast.configure();

  const device_report = () => {
    if (DeviceReport) {
      handleLoader(true);
      UserUtility.device_report(
        loginModifiedStartDate === defaultDateForm
          ? ""
          : loginModifiedStartDate,
        loginModifiedEndDate,
        loginCreatedStartDate === defaultDateForm ? "" : loginCreatedStartDate,
        loginCreatedEndDate
      )
        .then((res) => {
          toast.success(res?.detail);
          handleLoader(false);
        })
        .catch((err) => {
          console.error("user_list_err", err);
          handleLoader(false);
        });
    } else {
      toast.error("You do not have permission to perform this action.");
    }
  };

  return (
    <>
      <Modal
        isOpen={deviceLogin}
        onRequestClose={() => setDeviceLogin(false)}
        style={customStyles}
        contentLabel="login device modal"
        shouldCloseOnOverlayClick={true}
      >
        <div className="d-flex justify-content-evenly mt-5">
          <Form.Text
            size="sm"
            className=" mt-0"
            style={{
              width: "25%",
              borderRadius: "20px",
              textAlign: "center",
              borderWidth: "thin",
              borderStyle: "solid",
              paddingTop: 5,
              borderColor: "#41B6A8",
              cursor: "pointer",
              color: "black",
            }}
            // style={{ width: "25%", marginTop: 0, color: "black" }}
            onClick={() => (
              setOpenLoginDateCreated(false),
              setOpenLoginDateModified(!openLoginDateModified)
            )}
            // className="ms-3 border rounded p-2 "
          >
            {defaultDateForm === loginModifiedStartDate
              ? "Select by date modified"
              : `From ${loginModifiedStartDate} To ${loginModifiedEndDate}`}
          </Form.Text>
          {openLoginDateModified && (
            <DateRange
              className="position-absolute top-25 end-50 mt-5 pe-5 me-5"
              editableDateInputs={true}
              onChange={(item) => setLoginModifiedDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={loginModifiedDate}
              showDateDisplay={false}
            />
          )}
          <Form.Text
            size="sm"
            className="ms-3 mt-0"
            style={{
              width: "25%",
              borderRadius: "20px",
              textAlign: "center",
              borderWidth: "thin",
              borderStyle: "solid",
              paddingTop: 5,
              borderColor: "#41B6A8",
              cursor: "pointer",
              color: "black",
            }}
            // style={{ width: "25%", marginTop: 0, color: "black" }}
            onClick={() => (
              setOpenLoginDateCreated(!openLoginDateCreated),
              setOpenLoginDateModified(false)
            )}
            // className="ms-3 border rounded p-2 "
          >
            {defaultDateForm === loginCreatedStartDate
              ? "Select by date created"
              : `From ${loginCreatedStartDate} To ${loginCreatedEndDate}`}
          </Form.Text>
          {openLoginDateCreated && (
            <DateRange
              className="position-absolute top-25 start-50 ms-5 ps-5 mt-5"
              editableDateInputs={true}
              onChange={(item) => setLoginCreatedDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={loginCreatedDate}
              showDateDisplay={false}
            />
          )}
        </div>

        <section
          className="d-flex justify-content-evenly  mx-auto "
          style={{ marginTop: "12rem" }}
        >
          <Button
            style={{
              marginRight: openLoginDateCreated && "4rem",
              marginLeft: openLoginDateModified && "5rem",
            }}
            variant="success"
            className="  mt-3"
            onClick={() => {
              device_report();
            }}
          >
            Export Login Device
          </Button>
        </section>
      </Modal>
    </>
  );
};

export default LoginHistory;
