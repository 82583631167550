import { Accordion, Button } from "react-bootstrap";
import { textFieldsOnBoard } from "./OnBoardData";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../hooks/userContext";
import { AiOutlineDelete } from "react-icons/ai";
import style from "./OnBoard.module.css";
import { ENDPOINTS } from "../../apis/EndPoints";
import PopupRejecte from "./PopupRejecteResone/PopupRejecte";
import { PaginateSelectedComponent } from "./PaginateSelectedComponent/PaginateSelectedComponent";
import { UserUtility } from "../../apis/UserUtility";
import PopupComments from "./PopupComments/PopupComments";
import Documents from "./Documents";
import AuditLogList from "./AuditLogList/AuditLogList";
import PaginationComponent from "../../components/pagination/PaginationComponent";

const merchantValidation = Yup.object().shape({
  //personal data required
  commercial_register: Yup.string().required("*This field is required."),
  Value_added_tax_certificate: Yup.string(),
  Legal_representative_card: Yup.number().required("*This field is required."),
  address: Yup.string().required("*This field is required."),
  number_of_branches: Yup.number().required("*This field is required."),
  brand_name: Yup.string().required("*This field is required."),
  account_manager_mail: Yup.string()
    .email("Invalid email")
    .required("*This field is required."),
});
const OnBoardDetail = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowComments, setModalShowComments] = useState(false);

  const [item, setItem] = useState();
  const [documents, setDocuments] = useState([]);
  const [documentObj, setDocumentObj] = useState();

  const [auditLogList, setAuditLogList] = useState([]);

  const [BrandClass, setBrandClass] = useState([]);
  const [selectedBrandClass, setSelectedBrandClass] = useState([]);
  const [BrandClassForUpdate, setBrandClassForUpdate] = useState([]);
  const [BrandClassForSetInValue, setBrandClassForSetInValue] = useState([]);

  const [Location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [LocationForUpdate, setLocationForUpdate] = useState([]);
  const [LocationForSetInValue, setLocationForSetInValue] = useState([]);

  const [selectedResoneForReject, setSelectedResoneForReject] = useState([]);

  const [commentUpdated, setCommentUpdated] = useState("");
  const [departments, setDepartments] = useState("");
  const [commentsByAllCategory, setCommentsByAllCategory] = useState([]);
  const [marketingUpdated, setMarketingUpdated] = useState();
  const { handleLoader, permissions } = useContext(UserContext);
  const [page, setPage] = useState(1);
  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };
  let isAdmin = permissions.includes("on_boarding_tool_admin");
  let isLegal = permissions.includes("can_approve_legal_user");
  let isRisk = permissions.includes("can_approve_risk_user");
  let isEntry = permissions.includes("data_entry_on_boarding");
  let isMarketing = permissions.includes("marketing__on_boarding");
  let isOwner = permissions.includes("owner__on_boarding");

  // this function for checked status with who department
  const handle_Approve_Rejecte_Restudy = (
    item,
    Status,
    Approve_Rejecte_Restudy,
    currentPhase
  ) => {
    //This requirement only works on the permissions mentioned in it
    if (isLegal || isRisk || isMarketing || isAdmin) {
      let token = localStorage.getItem("tools_token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `token ${token}`);
      var formdata = new FormData();

      if (documents && Array.isArray(documents)) {
        documents.forEach((file, i) => {
          return formdata.append(`file_media[${i}]file_field`, file);
        });
      }

      //this condition for check actions from isMarketing permission
      if (isAdmin) {
        formdata.append("status", Status);
      }

      //this condition for check actions from isMarketing permission
      if (isMarketing) {
        marketingUpdated !== undefined &&
          formdata.append("rejected_markting", marketingUpdated);
        if (BrandClass && BrandClass.length > 0) {
          BrandClass.forEach((cla, i) => {
            formdata.append(`brand_class`, cla);
          });
        }
      }

      //this condition for check actions from isLegal || isRisk permission
      if (isLegal || isRisk) {
        Status !== "" && formdata.append("status", Status);
        isLegal
          ? formdata.append("approved_by_legal", Approve_Rejecte_Restudy)
          : formdata.append("approved_by_risk", Approve_Rejecte_Restudy);
        currentPhase && formdata.append("current_phase", currentPhase);
        commentUpdated && formdata.append("comment", commentUpdated);
        selectedResoneForReject &&
          selectedResoneForReject.forEach((cla) => {
            formdata.append(`reject_reason`, cla);
          });
      }
      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(ENDPOINTS.OnBoard_list.url_updated(item.id), requestOptions).then(
        async (res) => {
          handleLoader(false);
          if (!res.ok) {
            const text = await res.text();
            throw new Error(text);
          } else {
            handleLoader(false);
            toast.success("has been changed successfully");
            navigate("/MerchantOnBoard");
            return res.json();
          }
        }
      );
    }
  };

  useEffect(() => {
    location.state !== null ? setItem(location.state.item) : setItem();

    //this function for get comments all departments
    item &&
      departments !== "" &&
      UserUtility.CommentsViewListbyCategory(item.id, departments).then(
        (res) => {
          setCommentsByAllCategory(res);
        }
      );
  }, [location.state, item, departments]);

  useEffect(() => {
    //this function for get AuditLogList
    item &&
      UserUtility.AuditLogList(item.id).then((res) => {
        setAuditLogList(res);
      });
  }, [item]);

  useEffect(() => {
    setBrandClass(selectedBrandClass.map((ele) => ele.id));
    setLocation(selectedLocation.map((ele) => ele.id));
  }, [selectedBrandClass, selectedLocation]);

  useEffect(() => {
    UserUtility.getBrandClass(1).then((res) => {
      setBrandClassForUpdate(res.results);
    });
    UserUtility.getLocation(1).then((res) => {
      setLocationForUpdate(res.results);
    });
  }, [selectedBrandClass, selectedLocation]);

  //this function take a tow array for handle filter brand_class & location Lists
  const filterForShowInUpdateData = (data, dataID) => {
    return dataID?.flatMap((id) => data?.filter((item) => item.id === id));
  };

  useEffect(() => {
    if (item?.brand_class) {
      setBrandClassForSetInValue(
        filterForShowInUpdateData(BrandClassForUpdate, item.brand_class)
      );
    }
    if (item?.brand_class) {
      setLocationForSetInValue(
        filterForShowInUpdateData(LocationForUpdate, item.location)
      );
    }
  }, [
    BrandClassForUpdate,
    LocationForUpdate,
    item?.brand_class,
    item?.location,
  ]);

  useEffect(() => {
    setSelectedLocation([...LocationForSetInValue, ...selectedLocation]);
  }, [LocationForSetInValue.length]);
  useEffect(() => {
    setSelectedBrandClass([...BrandClassForSetInValue, ...selectedBrandClass]);
  }, [BrandClassForSetInValue.length]);

  const Brand_Class_URL = (page) => UserUtility.getBrandClass(page);
  const location_URL = (searchQuery, page) =>
    UserUtility.getLocation(searchQuery, page);
  const loadOptions_Class = async (searchQuery, loadedOptions, { page }) => {
    let response = await Brand_Class_URL(1);
    return {
      options: response?.results,
    };
  };
  const loadOptions_Location = async (loadedOptions, { page }) => {
    let response = await location_URL(1);
    return {
      options: response?.results,
    };
  };

  //this function for add MultiSelect document
  const handleAddDocument = (event) => {
    const file = event.target.files[0];
    setDocuments([...documents, file]);
    // Reset the file input element
    fileInputRef.current.value = null;
  };

  const handleDeleteDoc = (FileIndex) => {
    const DocAfterDelete = documents.filter((file, index) => {
      return index !== FileIndex;
    });
    setDocuments(DocAfterDelete);
  };

  const editAndPostDraft = (values, StatusFromRestudy) => {
    //this block of code is to upload pics and it is seperated from rest of data
    let token = localStorage.getItem("tools_token");
    const {
      commercial_register,
      Value_added_tax_certificate,
      Tax_card,
      Legal_representative_card,
      address,
      number_of_branches,
      facebook_link,
      current_phase,
      brand_name,
      account_manager_mail,
      sector,
      MDM_code,
      instgram_link,
      other_link,
      website_link,
      payment_link,
      rejected_markting,
      e_commerce,
      is_released,
      retail,
      // status,
      approved_by_risk,
      approved_by_legal,
    } = values;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    var formdata = new FormData();

    formdata.append("commercial_register", commercial_register);
    formdata.append("Value_added_tax_certificate", Value_added_tax_certificate);
    formdata.append("Tax_card", Tax_card);
    formdata.append("Legal_representative_card", Legal_representative_card);
    formdata.append("status", StatusFromRestudy);
    formdata.append("approved_by_legal", approved_by_legal);
    formdata.append("approved_by_risk", approved_by_risk);
    formdata.append("address", address);
    formdata.append("number_of_branches", number_of_branches);
    formdata.append("facebook_link", facebook_link);
    formdata.append("current_phase", current_phase);
    formdata.append("brand_name", brand_name);
    formdata.append("account_manager_mail", account_manager_mail);
    formdata.append("sector", sector);
    formdata.append("MDM_code", MDM_code);
    formdata.append("instgram_link", instgram_link);
    formdata.append("other_link", other_link);
    formdata.append("payment_link", payment_link);
    formdata.append("website_link", website_link);
    !isEntry && formdata.append("comment", commentUpdated);
    formdata.append("rejected_markting", rejected_markting);
    formdata.append("e_commerce", e_commerce);
    formdata.append("retail", retail);
    formdata.append("is_released", is_released);

    if (BrandClass && BrandClass.length > 0) {
      BrandClass.forEach((cla, i) => {
        formdata.append(`brand_class`, cla);
      });
    }

    if (Location) {
      Location.forEach((cla, i) => {
        return formdata.append(`location`, cla);
      });
    }

    // !isEntry && selectedResoneForReject.map((cla, i) => {
    //     return formdata.append(`reject_reason`, cla);
    // })

    if (documents && Array.isArray(documents)) {
      documents.forEach((file, i) => {
        return formdata.append(`file_media[${i}]file_field`, file);
      });
    }
    const requestOptions = {
      method: item?.id ? "PATCH" : "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    handleLoader(true);

    fetch(
      item?.id
        ? ENDPOINTS.OnBoard_list.url_updated(item.id)
        : ENDPOINTS.OnBoard_list.url_created(),
      requestOptions
    )
      .then(async (res) => {
        handleLoader(false);
        if (!res.ok) {
          const responseJson = await res.json();
          const errorMessage = responseJson.errors.commercial_register[0];
          toast.error(`Error: ${errorMessage}`);
          throw new Error(errorMessage);
        } else {
          handleLoader(false);
          toast.success("has been changed successfully");
          navigate("/MerchantOnBoard");
          return res.json();
        }
      })
      .catch((error) => {
        console.error("edit Draft error:: ", error);
        toast.error(error);
        handleLoader(false);
      });
  };
  console.log("item: ", item);

  return (
    <main className="onBoard_main mb-5">
      <section>
        <h2>Create New Application</h2>
        <Formik
          initialValues={{
            commercial_register: item?.commercial_register || "",
            Value_added_tax_certificate:
              item?.Value_added_tax_certificate || "",
            Tax_card: item?.Tax_card || "",
            Legal_representative_card: item?.Legal_representative_card || "",
            address: item?.address || "",
            brand_class: item?.brand_class || [],
            location: item?.location || [],
            reject_reason: item?.reject_reason || [],
            number_of_branches: item?.number_of_branches || 0,
            facebook_link: item?.facebook_link || "",
            current_phase: item?.current_phase || "Data entry",
            brand_name: item?.brand_name || "",
            account_manager_mail: item?.account_manager_mail || "",
            sector: item?.sector || "",
            MDM_code: item?.MDM_code || "",
            instgram_link: item?.instgram_link || "",
            website_link: item?.website_link || "",
            other_link: item?.other_link || "",
            rejected_markting: item?.rejected_markting || false,
            payment_link: item?.payment_link || false,
            e_commerce: item?.e_commerce || false,
            retail: item?.retail || false,
            is_released: item?.is_released || false,
            status: item?.status || "PENDING",
            approved_by_risk:
              item?.approved_by_risk === "RESTUDY"
                ? "PENDING"
                : item?.approved_by_risk || "PENDING",
            approved_by_legal:
              item?.approved_by_legal === "RESTUDY"
                ? "PENDING"
                : item?.approved_by_legal || "PENDING",
          }}
          validationSchema={merchantValidation}
          enableReinitialize={true}
          onSubmit={(values) => {
            // same shape as initial values
            (Location.length !== 0 && !isOwner) || isOwner
              ? editAndPostDraft(values, isOwner ? "APPROVED" : "PENDING")
              : toast.error(`Check Location Field`);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Accordion
                alwaysOpen
                defaultActiveKey="0"
                className="accordClass"
              >
                {/* data */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Main Information</Accordion.Header>
                  <Accordion.Body>
                    <div className="row row_div">
                      <div className="d-none">
                        <Field
                          className="form-control"
                          type="text"
                          name="brand_class"
                          value={BrandClass}
                        />
                        <Field
                          className="form-control"
                          type="text"
                          name="location"
                          value={Location}
                        />
                        <Field
                          className="form-control"
                          type="text"
                          name="reject_reason"
                          value={selectedResoneForReject}
                        />
                      </div>

                      <div className="d-flex">
                        <div className="w-50">
                          <PaginateSelectedComponent
                            value={selectedBrandClass}
                            loadOptions={loadOptions_Class}
                            onChange={(value) => setSelectedBrandClass(value)}
                            placeholder="Select Brand Class"
                            // customStyle={{ width: '30%' }}
                            loading={selectedBrandClass ? false : true}
                            disabled={
                              item?.status !== "REJECTED" &&
                              item?.status !== "APPROVED" &&
                              isMarketing
                                ? false
                                : true
                            }
                            item={item}
                            isMulti
                          />
                        </div>

                        <div className="w-50">
                          <PaginateSelectedComponent
                            value={selectedLocation}
                            loadOptions={loadOptions_Location}
                            onChange={(value) => setSelectedLocation(value)}
                            placeholder="Select Location"
                            // customStyle={{ width: '30%' }}
                            loading={selectedLocation ? false : true}
                            disabled={
                              item?.current_phase === "Processing"
                                ? true
                                : isEntry
                                ? false
                                : true
                            }
                            item={item}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-3 " controlid="formBasic">
                        <label className="form-label">MDM Code</label>

                        <Field
                          className="form-control"
                          type="text"
                          name="MDM_code"
                          disabled={
                            item && item?.status === "APPROVED"
                              ? true
                              : item?.status === "REJECTED"
                              ? true
                              : isEntry
                              ? false
                              : true
                          }
                        />
                      </div>

                      {textFieldsOnBoard.map((field, index) => (
                        <div
                          className="mb-3 col-md-3 "
                          controlid="formBasic"
                          key={index.toString()}
                        >
                          <label className="form-label">{field.label}</label>

                          <Field
                            className="form-control"
                            type="text"
                            name={field.name}
                            disabled={
                              item && item.current_phase === "Processing"
                                ? true
                                : isEntry
                                ? false
                                : true
                            }
                          />
                          <div className="text-danger">
                            {errors[field.name]}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className={style.checked_status}>
                      <label className="me-4">
                        <input
                          type="checkbox"
                          name="retail"
                          checked={values.retail}
                          onChange={() => {
                            isEntry &&
                              item?.current_phase !== "Processing" &&
                              setFieldValue("retail", !values.retail);
                          }}
                        />
                        <span className={style.label_checked_status}>
                          Retail
                        </span>
                      </label>
                      <label className="me-4">
                        <input
                          type="checkbox"
                          name="rejected_markting"
                          checked={values.rejected_markting}
                          onChange={() => {
                            item?.status !== "REJECTED" &&
                              item?.status !== "APPROVED" &&
                              isMarketing &&
                              setFieldValue(
                                "rejected_markting",
                                !values.rejected_markting
                              );
                            setMarketingUpdated(!values.rejected_markting);
                          }}
                        />
                        <span className={style.label_checked_status}>
                          Approved
                        </span>
                      </label>
                      <br />
                      <label className="me-4">
                        <input
                          type="checkbox"
                          name="payment_link"
                          checked={values.payment_link}
                          onChange={() => {
                            isEntry &&
                              item?.current_phase !== "Processing" &&
                              setFieldValue(
                                "payment_link",
                                !values.payment_link
                              );
                          }}
                        />
                        <span className={style.label_checked_status}>
                          Payment Link
                        </span>
                      </label>
                      <br />
                      <label className="me-4">
                        <input
                          type="checkbox"
                          name="e_commerce"
                          checked={values.e_commerce}
                          onChange={() => {
                            isEntry &&
                              item?.current_phase !== "Processing" &&
                              setFieldValue("e_commerce", !values.e_commerce);
                          }}
                        />
                        <span className={style.label_checked_status}>
                          Is E-commerce
                        </span>
                      </label>

                      <br />
                      <label className="me-4">
                        <input
                          type="checkbox"
                          name="is_released"
                          checked={values.is_released}
                          onChange={() => {
                            isOwner &&
                              item?.status == "APPROVED" &&
                              setFieldValue("is_released", !values.is_released);
                          }}
                          disabled={!isOwner || item?.status !== "APPROVED"}
                        />
                        <span className={style.label_checked_status}>
                          Is Released
                        </span>
                      </label>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* upload multiple documents */}
                {console.log("documents: ", documents)}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Upload Documents</Accordion.Header>
                  <Accordion.Body>
                    <section>
                      <div>
                        {documents.map((item, index) => (
                          <div key={index.toString()}>
                            <div controlId="formFile" className="mb-3">
                              <label className="form-label">
                                Upload Document
                              </label>
                              <div className="d-flex justify-content-start align-items-center">
                                <div className="upload_div w-100">
                                  <input
                                    type="text"
                                    className="file_input_override form-control"
                                    value={item?.name}
                                  />
                                  <div
                                    className={`${style.delete_doc_butt} ms-5`}
                                    onClick={() => {
                                      handleDeleteDoc(index);
                                    }}
                                  >
                                    <AiOutlineDelete />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        <div controlId="formFile" className="mb-3">
                          <label className="form-label">Upload Document</label>
                          <div className="upload_div">
                            <div style={{ position: "relative" }}>
                              <input
                                ref={fileInputRef}
                                type="file"
                                onChange={(e) => {
                                  handleAddDocument(e);
                                }}
                                className="file_input form-control pb-0 pt-2"
                              />

                              {item?.status !== "REJECTED" &&
                                item?.status !== "APPROVED" && (
                                  <Button
                                    className="small_btn"
                                    type="button"
                                    variant="contained"
                                    readOnly
                                  >
                                    Upload
                                  </Button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <Documents item={item} />
                  </Accordion.Body>
                </Accordion.Item>
                {/* comments */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Comments</Accordion.Header>
                  <Accordion.Body>
                    <div
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <div>
                        <label className="form-label mx-2" htmlFor="comment">
                          Comments
                        </label>
                        <label
                          onClick={() => {
                            setDepartments("legal");
                            setModalShowComments(true);
                          }}
                          className={`form-label mx-2 ${style.comments_departments}`}
                          htmlFor="comment"
                        >
                          Legal Comments
                        </label>
                        <label
                          onClick={() => {
                            setDepartments("risk");
                            setModalShowComments(true);
                          }}
                          className={`form-label mx-2 ${style.comments_departments}`}
                          htmlFor="comment"
                        >
                          Risk Comments
                        </label>
                      </div>
                      {!isEntry &&
                        !isMarketing &&
                        !isAdmin &&
                        item?.status !== "REJECTED" &&
                        item?.status !== "APPROVED" && (
                          <div>
                            <Field
                              name="comment"
                              as="textarea"
                              rows={3}
                              id="comment"
                              className="form-control"
                              value={commentUpdated}
                              onChange={(e) => {
                                setCommentUpdated(e.target.value);
                              }}
                            />

                            <div className="text-danger">{errors.comment}</div>

                            {/* <div className="mt-3">
                                                                    <Button
                                                                        className={`p-0 ${style.send_butt} `}
                                                                        type="submit"
                                                                    >Send Updates</Button>
                                                                </div> */}
                          </div>
                        )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <AuditLogList
                  auditLogList={auditLogList}
                  page={page}
                  handleChange={handleChange}
                />
              </Accordion>
              {/* btn in footer */}
              <div className="flex_div mt-5">
                {/* send to  */}
                <div className="d-flex flex-column ">
                  <div className="d-flex align-items-center me-5">
                    {isEntry
                      ? item?.status !== "APPROVED" &&
                        item?.status !== "REJECTED" && (
                          <div>
                            <Button
                              className={`${style.send_butt}`}
                              onClick={() => {
                                setFieldValue("current_phase", "Processing");
                              }}
                              type="submit"
                            >
                              Send
                            </Button>
                          </div>
                        )
                      : item?.status !== "REJECTED" &&
                        item?.status !== "APPROVED" &&
                        isMarketing && (
                          <Button
                            className={`${item ? style.send_butt : "d-none"}`}
                            onClick={() => handle_Approve_Rejecte_Restudy(item)}
                          >
                            Send
                          </Button>
                        )}

                    <div className="d-flex">
                      {isEntry
                        ? item?.current_phase !== "Processing" && (
                            <div className="d-flex align-items-center">
                              {/* <div>
                                                                <Button className={style.save_draft_butt}
                                                                    onClick={() => setFieldValue("current_phase", "Data entry")}
                                                                    type="submit"
                                                                    variant="contained">
                                                                    Save As Draft
                                                                </Button>
                                                            </div> */}
                              {!item && (
                                <span
                                  className={style.clear_inputs}
                                  onClick={() => resetForm()}
                                >
                                  Clear All Inputs
                                </span>
                              )}
                            </div>
                          )
                        : (isLegal ||
                            isRisk ||
                            (item?.approved_by_legal === "APPROVED" &&
                              item?.approved_by_risk === "APPROVED" &&
                              isAdmin)) &&
                          item?.status !== "REJECTED" &&
                          item?.status !== "APPROVED" && (
                            <div>
                              <Button
                                className={`${
                                  item ? style.Approved_butt : "d-none"
                                }`}
                                onClick={() =>
                                  isAdmin
                                    ? handle_Approve_Rejecte_Restudy(
                                        item,
                                        "APPROVED",
                                        ""
                                      )
                                    : handle_Approve_Rejecte_Restudy(
                                        item,
                                        "",
                                        "APPROVED"
                                      )
                                }
                              >
                                Approve
                              </Button>
                              <Button
                                className={`${
                                  item ? style.reject_butt : "d-none"
                                }`}
                                variant="primary"
                                onClick={() => setModalShow(true)}
                              >
                                Reject
                              </Button>
                              <Button
                                className={`${
                                  item ? style.Restudy_butt : "d-none"
                                }`}
                                onClick={() =>
                                  isAdmin
                                    ? handle_Approve_Rejecte_Restudy(
                                        item,
                                        "RESTUDY",
                                        "",
                                        ""
                                      )
                                    : handle_Approve_Rejecte_Restudy(
                                        item,
                                        "RESTUDY",
                                        "RESTUDY",
                                        "Data entry"
                                      )
                                }
                              >
                                Return
                              </Button>
                            </div>
                          )}

                      {isOwner && (
                        <Button
                          className={`${item ? style.Approved_butt : "d-none"}`}
                          type="submit"
                          //   onClick={() =>}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {item && modalShow && (
                <PopupRejecte
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  item={item}
                  selectedResoneForReject={selectedResoneForReject}
                  setSelectedResoneForReject={setSelectedResoneForReject}
                  handle_Approve_Rejecte_Restudy={
                    handle_Approve_Rejecte_Restudy
                  }
                />
              )}

              <PopupComments
                show={modalShowComments}
                onHide={() => setModalShowComments(false)}
                comments={commentsByAllCategory}
                department={departments}
              />
            </Form>
          )}
        </Formik>
      </section>
    </main>
  );
};
export default OnBoardDetail;
