import React, { useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useLocation } from "react-router-dom";
import Tables from "../../components/LegalTables/Tables";
const Main = ({ historyUser_id }) => {
  
  const location = useLocation();
  const customer_id = location?.state?.customer_id;
  const [alertPagesNumber, setAlertPagesNumber] = useState(1);
  const [sessionPagesNumber, setSessionPagesNumber] = useState(1);
  const [lawsuitPagesNumber, setLawsuitPagesNumber] = useState(1);
  const [feesPagesNumber, setFeesPagesNumber] = useState(1);
  const [total, setTotal] = useState();
  const [nationalId, setNationalId] = useState();
  const [data, setData] = useState();
  const [list, setList] = useState();
  const id = data && data[0]?.id;
  const national_Id = data && data[0]?.national_id;
  const customerName = data && data[0]?.customer_full_name;
  const total_overdues = data && data[0]?.total_overdues;
  const Buttons = [
    {
      name: "إضافة انذار",
      direction: "Alert",
    },
    {
      name: "إضافة دعوي",
      direction: "Lawsuit",
    },
    {
      name: "إضافة جلسة",
      direction: "Session",
    },
    {
      name: "إضافة مصروفات",
      direction: "Fees",
    },
    {
      name: "ملفات العميل",
      direction: "Files",
    },
  ];
  useEffect(() => {
    search_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);
  const handleClick = (e) => {
    if (e === "Enter") {
      search_list();
      setAlertPagesNumber(1);
      setSessionPagesNumber(1);
      setLawsuitPagesNumber(1);
      setFeesPagesNumber(1);
    }
  };
  useEffect(() => {
    if (id || historyUser_id) {
      alert_list();
      session_list();
      lawsuit_list();
      fees_list();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    historyUser_id,
    alertPagesNumber,
    sessionPagesNumber,
    lawsuitPagesNumber,
    feesPagesNumber,
  ]);

  const search_list = () => {
    setList([]);
    setData([]);
    setTotal();
    if (nationalId || customer_id) {
      UserUtility.search_list(nationalId || customer_id)
        .then((res) => {
          setData(res.results);
        })
        .catch((err) => {
          // toast.error(err[0])
          console.error("search_list", err);
        });
    }
  };
  const alert_list = () => {
    UserUtility.alert_list(id || historyUser_id, alertPagesNumber)
      .then((res) => {
        setList((oldList) => ({ ...oldList, Alert: res.results }));
        setTotal((oldList) => ({ ...oldList, Alert: res }));
      })
      .catch((err) => console.error("alert_list", err));
  };
  const session_list = () => {
    UserUtility.session_list(id || historyUser_id, sessionPagesNumber)
      .then((res) => {
        setList((oldList) => ({ ...oldList, Session: res.results }));
        setTotal((oldList) => ({ ...oldList, Session: res }));
      })
      .catch((err) => console.error("session_list", err));
  };
  const lawsuit_list = () => {
    UserUtility.lawsuit_list(id || historyUser_id, lawsuitPagesNumber)
      .then((res) => {
        setList((oldList) => ({ ...oldList, Lawsuit: res.results }));
        setTotal((oldList) => ({ ...oldList, Lawsuit: res }));
      })
      .catch((err) => console.error("lawsuit_list", err));
  };
  const fees_list = () => {
    UserUtility.fees_list(id || historyUser_id, feesPagesNumber)
      .then((res) => {
        setList((oldList) => ({ ...oldList, Fees: res.results }));
        setTotal((oldList) => ({ ...oldList, Fees: res }));
      })
      .catch((err) => console.error("fees_list", err));
  };
  const alertHandleChange = (e, value) => {
    setAlertPagesNumber(value);
  };
  const sessionHandleChange = (e, value) => {
    setSessionPagesNumber(value);
  };
  const lawsuitHandleChange = (e, value) => {
    setLawsuitPagesNumber(value);
  };
  const feesHandleChange = (e, value) => {
    setFeesPagesNumber(value);
  };
  const get_report = () => {
    UserUtility.legal_report(nationalId)
      .then(() => {
        toast.success("Report sent successfully");
      })
      .catch((err) => console.error("get_report", err));
  };
  const reconciliation = () => {
    UserUtility.user_reconciliation(id)
      .then(() => {
        toast.success("تم التصالح بنجاح");
        alert_list();
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div className="Legal" style={{ textAlign: "right" }} dir="rtl">
      {!historyUser_id && (
        <>
          <Stack direction="horizontal" className="mb-3" gap={4} dir="rtl">
            <Form.Control
              onKeyPress={(e) => handleClick(e.key)}
              type="text"
              placeholder="الرقم القومي"
              className="me-auto"
              size="lg"
              onChange={(e) => {
                setNationalId(e.target.value);
              }}
            />
            <Button
              variant="success"
              className="px-5"
              onClick={() => handleClick("Enter")}
            >
              بحث
            </Button>
          </Stack>

          <Button
            variant="success"
            className="px-5"
            onClick={() => get_report()}
          >
            Export
          </Button>
          {data?.length > 0 && (
            <article className="d-flex justify-content-center">
              <Form.Text className="fs-4" style={{ color: "black" }}>
                {data[0]?.customer_full_name} {data[0]?.national_id}{" "}
              </Form.Text>
              <div className="fees">
                <Form.Text className="fw-bold">
                  إجمالي المصاريف المستحقة
                </Form.Text>
                <Form.Label className="fw-bolder">
                  {data[0]?.legal_fees}{" "}
                </Form.Label>
              </div>
              <Button
                variant="success"
                className="me-2"
                disabled={list?.Alert?.length > 0 ? false : true}
                onClick={() => reconciliation()}
              >
                إيقاف الإجراءات القانونية
              </Button>
            </article>
          )}
        </>
      )}
      <Tables
        type={"الإنذارات"}
        alert={list?.Alert}
        user_id={id}
        national_Id={national_Id}
        customerName={customerName}
        historyUser_id={historyUser_id}
      />
      {total && (
        <PaginationComponent
          brands={total?.Alert}
          page={alertPagesNumber}
          handleChange={alertHandleChange}
          // eslint-disable-next-line react/style-prop-object
          style="ltr"
        />
      )}
      <Tables
        type={"الدعاوي"}
        lawsuit={list?.Lawsuit}
        user_id={id}
        national_Id={national_Id}
        customerName={customerName}
        historyUser_id={historyUser_id}
      />
      {total && (
        <PaginationComponent
          brands={total?.Lawsuit}
          page={lawsuitPagesNumber}
          handleChange={lawsuitHandleChange}
          // eslint-disable-next-line react/style-prop-object
          style="ltr"
        />
      )}
      <Tables
        type={"الجلسات"}
        session={list?.Session}
        user_id={id}
        national_Id={national_Id}
        customerName={customerName}
        historyUser_id={historyUser_id}
      />
      {total && (
        <PaginationComponent
          brands={total?.Session}
          page={sessionPagesNumber}
          handleChange={sessionHandleChange}
          // eslint-disable-next-line react/style-prop-object
          style="ltr"
        />
      )}
      <Tables
        type={"المصروفات"}
        fees={list?.Fees}
        user_id={id}
        national_Id={national_Id}
        customerName={customerName}
        historyUser_id={historyUser_id}
      />
      {total && (
        <PaginationComponent
          brands={total?.Fees}
          page={feesPagesNumber}
          handleChange={feesHandleChange}
          // eslint-disable-next-line react/style-prop-object
          style="ltr"
        />
      )}
      <section className="d-flex justify-content-evenly mt-5">
        {data &&
          data?.length !== 0 &&
          Buttons.map((ele, index) => {
            return (
              <div key={index}>
                <Link
                  to={`/${ele.direction}`}
                  state={{
                    historyUser_id,
                    id,
                    customerName,
                    national_Id,
                    total_overdues,
                  }}
                >
                  <Button
                    style={{ paddingInline: 50 }}
                    variant="success"
                    className="mb-5"
                  >
                    {ele.name}
                  </Button>
                </Link>
              </div>
            );
          })}
      </section>
    </div>
  );
};
export default Main;
