import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import moment from "moment";
import styles from "./IscoreStyle.module.scss";
import { ENDPOINTS } from "../../apis/EndPoints";
import { toast } from "react-toastify";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import axios from "axios";

export const IscoreToolScreen = () => {
  const [allInitiators, setAllInitiators] = useState();
  const [searchValue, SetSearchValue] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [iscoreState, setIscoreState] = useState();
  const [reportLanguage, setReportLanguage] = useState();
  const [initiatorSelected, setInitiatorSelected] = useState();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const from = moment(dateFrom).format("YYYY-MM-DD");
  const to = moment(dateTo).format("YYYY-MM-DD");
  const [total, setTotal] = useState();
  const [dataWithoutPagination, setDataWithoutPagination] = useState();
  const [totalFiles, setTotalFiles] = useState();

  const { handleLoader, permissions = [] } = useContext(UserContext);
  const filtersPermission = permissions?.includes(
    "can_use_iscore_tool_filters"
  );

  const [list, setList] = useState([]);
  const allIds = dataWithoutPagination?.map((ele) => {
    return ele.id;
  });
  const ids = list?.map((ele) => {
    if (ele?.active === true) {
      return ele.id;
    }
  });
  const filtered = ids?.filter((ele) => {
    return ele !== undefined;
  });

  const iscoreList = (param) => {
    handleLoader(true);
    UserUtility.getIscoreSearch(
      pagesNumber,
      searchValue,
      iscoreState,
      from,
      to,
      initiatorSelected,
      reportLanguage,
      param && total?.count
    )
      .then((res) => {
        handleLoader(false);
        if (param) {
          setDataWithoutPagination(res?.results);
          // test();
        } else {
          setTotal(res);
          setList(res?.results);
        }
      })
      .catch((err) => {
        handleLoader(false);
        console.error("err of iscoreList: ", err);
        toast.error(err || "something went wrong");
      });
  };
  const iscoreInitiators = () => {
    UserUtility.iscoreInitiators()
      .then((res) => {
        setAllInitiators(res);
      })
      .catch((err) => {
        console.error("iscoreInitiators ", err);
        toast.error(err || "something went wrong");
      });
  };

  const importReport = (e) => {
    let file = e.target.files[0];

    let token = localStorage.getItem("tools_token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);

    var formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    handleLoader(true);
    fetch(`${ENDPOINTS.importIscores.url}`, requestOptions)
      .then(async (res) => {
        handleLoader(false);
        if (!res.ok) {
          const text = await res.json();
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          toast.error(
            text?.errors?.error ||
              text?.error ||
              text?.errors?.non_field_errors[0] ||
              "something went wrong"
          );
          // throw new Error(text);
        } else {
          // navigate(-1);
          toast.success("File has been saved successfully");
          return res.json();
        }
      })
      .catch((err) => {
        console.error("err of import: ", err);
        toast.error("Oops something went wrong of upload file");
        handleLoader(false);
      })
      .finally(() => {
        // nabila comment ... add to line to empty input value to trigger when select the same file ands onChange fire after then
        e.target.value = null;
      });
  };
  const handleClick = (e) => {
    if (e === "Enter") {
      if (pagesNumber !== 1) {
        setPagesNumber(1);
      } else {
        iscoreList();
      }
    }
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  const handleActiveUser = (id) => {
    const active = list?.map((ele, index) => {
      if (ele.id === id) {
        return { ...ele, active: !ele.active };
      } else {
        return { ...ele, active: ele.active };
      }
    });
    setList(active);
  };
  const download_report = () => {
    handleLoader(true);
    UserUtility.download_report(allIds ? allIds : filtered)
      .then((res) => {
        handleLoader(false);
        toast.success(res?.detail);
      })
      .catch((err) => {
        handleLoader(false);
        console.error("download_report ", err);
        toast.error(err || "something went wrong");
      });
  };
  useEffect(() => {
    iscoreList();
    iscoreInitiators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber]);
  useEffect(() => {
    setTotalFiles(total?.count);
  }, [total]);
  useEffect(() => {
    if (allIds !== undefined) {
      download_report();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWithoutPagination]);
  return (
    <main className="main_div">
      {/* head */}
      <div>
        <div className="flex_div">
          <div className="flex_div" style={{ width: "70%" }}>
            <div
              className="input_div"
              style={{ width: "50%", marginRight: 20 }}
            >
              <Form.Control
                placeholder="Search by National ID"
                className="searchInput form_control_style"
                onChange={(e) => SetSearchValue(e.target.value)}
              />
              <BsSearch className="search_icon" />
            </div>
            <FormControl
              className="select-field"
              style={{ width: "20%", marginRight: "2%" }}
            >
              <InputLabel> Get iscore</InputLabel>
              <Select
                className="select_onetrans_class"
                size="small"
                id="demo-simple-select"
                value={iscoreState}
                label="Select Status"
                onChange={(e) => setIscoreState(e.target.value)}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
            {filtersPermission && (
              <article style={{ flexDirection: "row", display: "flex" }}>
                {/* from */}
                <section className="date_wrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="from"
                      inputFormat="dd/MM/yyyy"
                      value={dateFrom}
                      disableHighlightToday
                      onChange={(newValue) => {
                        setDateFrom(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </section>

                {/* to */}
                <section className="date_wrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="to"
                      value={dateTo}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setDateTo(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </section>
              </article>
            )}
            <section style={{ marginLeft: "2%", width: "20%" }}>
              <Form.Check
                type="radio"
                label="Arabic File"
                onChange={() => setReportLanguage("AR")}
                checked={reportLanguage === "AR"}
              />
              <Form.Check
                type="radio"
                label="English File"
                onChange={() => setReportLanguage("EN")}
                checked={reportLanguage === "EN"}
              />
            </section>
          </div>

          <div className="flex_div">
            <Button
              onClick={() => handleClick("Enter")}
              className={styles.btnStyle}
              style={{ width: 200 }}
            >
              Search
            </Button>
          </div>
        </div>

        <div className="flex_div  mt-5 ">
          {filtersPermission && (
            <FormControl
              className="select-field"
              style={{ width: "20%", marginRight: "2%" }}
            >
              <InputLabel> Initiator Email</InputLabel>
              <Select
                className="select_onetrans_class"
                size="small"
                id="demo-simple-select"
                value={initiatorSelected}
                label="Select Status"
                onChange={(e) => setInitiatorSelected(e.target.value)}
              >
                <MenuItem value="">None</MenuItem>
                {allInitiators?.map((filter, index) => {
                  return (
                    <MenuItem value={filter.id} key={index}>
                      {filter.email}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          <TextField
            label="Total Files"
            value={total?.count}
            defaultValue={totalFiles ? totalFiles : " "}
            InputProps={{
              readOnly: true,
            }}
          />
          <div className={styles.div_import}>
            <input
              onChange={(e) => importReport(e)}
              // className="d-none"
              type="file"
            />

            <Button
              type="submit"
              variant="contained"
              className={styles.btnStyle}
              // component="span"
              // onClick={() => importReport()}
            >
              Import
            </Button>
          </div>
        </div>
      </div>

      {/* table */}

      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table
          striped
          responsive
          hover
          sx={{ minWidth: 700 }}
          className="notify_table"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  width: "5%",
                }}
                onClick={() => iscoreList("all")}
              >
                Download All
              </StyledTableCell>
              <StyledTableCell>National id</StyledTableCell>
              <StyledTableCell>Initiator</StyledTableCell>
              <StyledTableCell>Created at</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>View</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((ele, index) => {
              return (
                <StyledTableRow key={index.toString()}>
                  <StyledTableCell>
                    {
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleActiveUser(ele.id)}
                        checked={ele.active}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>{ele?.user_national_id}</StyledTableCell>
                  <StyledTableCell>{ele?.initiator}</StyledTableCell>
                  <StyledTableCell>
                    {moment(ele?.created).format("lll")}{" "}
                  </StyledTableCell>
                  <StyledTableCell>{ele?.status || " - "} </StyledTableCell>
                  <StyledTableCell>
                    <a
                      href={ele?.file}
                      target="_blank"
                      className={styles.viewBtn}
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        type="submit"
        variant="contained"
        className={styles.btnStyle}
        style={{ float: "left", marginTop: 10 }}
        // component="span"
        onClick={() => download_report()}
      >
        {`Downlaod ${filtered?.length !==0 ?filtered?.length:''}`}
      </Button>
      <PaginationComponent
        brands={total}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </main>
  );
};
