import React from "react";
import { Outlet } from "react-router-dom";
import "./Statistics.scss";

const Statistics = () => {
  // const choosingBar = [
  //   {
  //     name: "Daily",
  //     tag: "Daily",
  //     selected: true,
  //   },
  //   {
  //     name: "Month to date",
  //     tag: "monthToDate",
  //     selected: false,
  //   },
  //   {
  //     name: "Year to date",
  //     tag: "yearToDate",
  //     selected: false,
  //   },
  //   {
  //     name: "Since inception",
  //     tag: "sinceInception",
  //     selected: false,
  //   },
  // ];
  // const [barTabs, setBarTabs] = useState(choosingBar);
  // const handleBar = (currentIndex) => {
  //   const newBarTabs = barTabs.map((bar, index) => {
  //     return {
  //       ...bar,
  //       selected: currentIndex === index ? true : false,
  //     };
  //   });
  //   setBarTabs(newBarTabs);
  // };
  return (
    <main className="statistics_container">
      {/* <section className="choosing_bar">
        <div className="barTabs_wrapper"> */}
      {/* {barTabs.map((bar, id) => {
            return (
              <div
                className={bar.selected ? "list_item_active" : "list_item"}
                key={id}
              >
                <Link
                  style={{
                    color: bar.selected ? "#fff" : "#000",
                    textDecoration: "none",
                    width: "100%",
                  }}
                  to={bar.tag}
                >
                  <button
                    className={
                      bar.selected
                        ? "navigate_button_active"
                        : "navigate_button"
                    }
                    onClick={() => handleBar(id)}
                  >
                    {bar.name}
                  </button>
                </Link>
              </div>
            );
          })} */}
      {/* </div>
      </section> */}

      <Outlet />
    </main>
  );
};

export default Statistics;
