// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  StyledTableCell,
  StyledTableRow,
  customStyles,
} from "../../constants/Colors";
import OneTransactionContext from "../../hooks/oneTransactionContext";
import UserContext from "../../hooks/userContext";
import "./OneTransactionStyle.scss";
import { ENDPOINTS } from "../../apis/EndPoints";
import { PaginateSelectedComponent } from "../../components/CustomSelectedComponent/PaginateSelectedComponent";
import { AiOutlinePlus } from "react-icons/ai";
import { CreateSelectComponent } from "../../components/CustomSelectedComponent/CreateSelectComponent";

export const DataEntry = () => {
  const [searchValue, setSearchValue] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [numberOfApplications, setNumberOfApplications] = useState();
  const [isCreditOpen, setIsCreditOpen] = useState({
    isOpen: false,
    user: null,
  });
  const [allApplications, setAllApplications] = useState([]);
  const [page, setPage] = useState(1);
  const [dataDetail, setDataDetail] = useState();
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [stateSelected, setStateSelected] = useState();
  const { programs, oneTransactionOtherOptionResult } = useContext(
    OneTransactionContext
  );
  const isViewOrEdit =
    (permissions.includes("view_onetransactionprofile") &&
      permissions.includes("change_onetransactionprofile")) ||
    (permissions.includes("view_onetransactionprofile") &&
      !permissions.includes("add_onetransactionprofile"))
      ? true
      : false;
  const [selectedProgram, setSelectedProgram] = useState();
  const [providerServ, setProviderServ] = useState();
  const [serviceInfo, setServiceInfo] = useState();
  const [returnedUserData, setReturnedUserData] = useState();
  const [isOpenReturned, setIsOpenReturned] = useState(false);
  const [returnedPage, setReturnedPage] = useState(1);
  const [selectedSalesMan, setSelectedSalesMan] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [salesAgentArr, setSalesAgentArr] = useState([]);
  const [salesAgentSearch, setSalesAgentSearch] = useState();

  // const isViewAddSales = permissions.includes("can_view_salesman_and_agency") && permissions.includes("can_add_salesman");
  // const isViewAddAgency = permissions.includes("can_view_salesman_and_agency")
  //  && permissions.includes("can_add_agency");
  const isViewSalseAndAgency = permissions.includes(
    "can_view_salesman_and_agency"
  );

  console.log("permissionspermissions: ", permissions);
  const getAllApplications = (fromReturned) => {
    const from = moment(dateFrom).format("YYYY-MM-DD");
    const to = moment(dateTo).format("YYYY-MM-DD");
    UserUtility.oneTransAllApplications(
      fromReturned ? returnedPage : page,
      fromReturned ? "" : searchValue,
      fromReturned ? "" : numberOfApplications,
      fromReturned ? "" : from,
      fromReturned ? "" : to,
      fromReturned
        ? "return_data_entry"
        : stateSelected === "All"
        ? ""
        : stateSelected
    )
      .then((res) => {
        if (fromReturned) {
          setReturnedUserData(res);
        } else {
          setDataDetail(res);
          setAllApplications(res.results);
        }
      })
      .catch((err) => {
        console.error("error of getAllApplications: ", err);
      });
  };

  const contractsSearch = (e) => {
    e.preventDefault();
    if (page === 1) {
      getAllApplications();
    } else {
      setPage(1);
    }
  };

  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handleCloseModal = () => {
    setIsCreditOpen({ isOpen: false, user: null });
    setSelectedAgency();
    setSelectedSalesMan();
    setSelectedProgram();
    setProviderServ();
    setServiceInfo();

  };

  const oneTransCreateCredit = () => {
    let programObj = programs.find((item) => {
      return item.id == selectedProgram;
    });
    let obj = {};
    if (programObj) {
      obj["user"] = isCreditOpen.user;
      obj["program"] = programObj.id;
      obj["product_label"] = programObj.name;
      obj["service_provider"] = providerServ;
      obj["service_info"] = serviceInfo;
      if (selectedSalesMan) {
        obj["salesman"] = selectedSalesMan?.value;
      }
      if (selectedAgency) {
        obj["agency"] = selectedAgency?.value;
      }
      // setSelectedProgram();
      // setProviderServ();
      // setServiceInfo();

      UserUtility.oneTransCreateCredit(obj)
        .then((res) => {
          handleCloseModal();
          toast.success("Credit created successfully");
        })
        .catch((err) => {
          console.error("oneTransCreateCredit err: ", err);
          if (err?.detail) {
            toast.error("Create credit " + err?.detail);
          } else if (err?.non_field_errors) {
            toast.error("Create credit " + err?.non_field_errors[0]);
          } else {
            toast.error("Create credit " + err);
          }
        });
    } else {
      toast.error("Please select program");
    }
  };

  useEffect(() => {
    getAllApplications();
  }, [page]);

  useEffect(() => {
    console.log("isOpenReturned: ", isOpenReturned);
    if (isOpenReturned) {
      getAllApplications(true);
    }
  }, [returnedPage, isOpenReturned]);
  const fileInput = useRef(null);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    selectedFile && Export_Import(ENDPOINTS.importProfiles.url, selectedFile);
    // nabila comment ... add to line to empty input value to trigger when select the same file ands onChange fire after then
    e.target.value = null;
  };

  const Export_Import = (URL, File) => {
    let token = localStorage.getItem("tools_token");
    var myHeaders = new Headers();
    var formdata = new FormData();
    myHeaders.append("Authorization", `token ${token}`);
    File && formdata.append(`file`, File);

    const requestOptions = File
      ? {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        }
      : {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };
    handleLoader(true);
    fetch(URL, requestOptions)
      .then(async (res) => {
        handleLoader(false);

        if (!res.ok) {
          const responseJson = await res.json();
          const errorMessage = responseJson.errors;
          toast.error(`Error: ${errorMessage}`);
          throw new Error(errorMessage);
        } else {
          handleLoader(false);
          const responseJson = await res.json();
          if (responseJson.errors) {
            toast.error(responseJson.errors, { autoClose: 10000 });
          } else {
            toast.success("File has been saved successfully");
          }

          !File && responseJson?.file
            ? (window.location.href = `${responseJson?.file}`)
            : console.log(`Not Have Results`);
          console.log(responseJson?.file);
          return responseJson;
        }
      })
      /*
     // nabila comment ... remove it :
     1- the refresh sceen is bad behavior and not solve the issue of import two file after each other
     2- .then is fire before worj the cenario of export and download file
     .then(()=>{
       window.location.reload()
     })
     */
      .catch((error) => {
        console.error("error:: ", error);
        toast.error("Oops something went wrong of upload file");
      });
  };

  const getSalesManAgent = () => {
    UserUtility.oneTransactionManSalese()
      .then((res) => {
        // setSalesAgentArr(res);

        const customSales = res?.salesmen.map((item, index) => {
          console.log("iiiitem: ", item);
          return { label: item.name, value: item.name };
        });

        const customAgencies = res?.agencies.map((item, index) => {
          console.log("iiiitem: ", item);
          return { label: item.name, value: item.name };
        });
        setSalesAgentArr({ salesmen: customSales, agencies: customAgencies });
      })
      .catch((err) => {
        console.log("err of oneTransactionManSalese: ", err);
      });
  };

  return (
    <main className="oneTransaction_container">
      <section>
        <form>
          <article className="searchBar_wrapper">
            <section className="search_fields_wrapper">
              <TextField
                size="small"
                placeholder="National ID / Phone Number"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="text-field"
                label="National ID / Phone Number"
              />

              <article className="date_container">
                {/* from */}
                <section className="date_wrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      label="from"
                      inputFormat="dd/MM/yyyy"
                      value={dateFrom}
                      onChange={(newValue) => {
                        setDateFrom(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </section>

                {/* to */}
                <section className="date_wrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      label="to"
                      value={dateTo}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setDateTo(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </section>

                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker />
                </LocalizationProvider> */}
              </article>

              <TextField
                placeholder="No. of Applications"
                onChange={(e) => setNumberOfApplications(e.target.value)}
                label="No. of applications"
                className="no_applications"
              />
            </section>

            <Button
              className="searchButton"
              type="submit"
              variant="contained"
              onClick={(e) => contractsSearch(e)}
            >
              Search
            </Button>
          </article>

          {/* filters container */}
        </form>

        {/* new application btn with permmission add */}

        <div>
          {permissions.includes("add_onetransactionprofile") && (
            <Link
              to="/oneTransaction/dataEntry/Application"
              state={{ history: "hidden" }}
            >
              <Button type="submit" variant="contained" className="addBtn">
                New Application
              </Button>
            </Link>
          )}

          <div className="addBtn mx-2">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  height: "50px",
                  borderRadius: "8px",
                  border: "0",
                  color: "white",
                }}
              >
                Profile
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="bg-secondary"
                  onClick={() => {
                    Export_Import(ENDPOINTS.exportProfiles.url);
                  }}
                >
                  <div className="d-flex align-items-end justify-content-center">
                    <p className="">Export</p>
                  </div>
                </Dropdown.Item>
                {permissions.includes(
                  "one_transaction_profile_import_report"
                ) && (
                  <>
                    <input
                      ref={fileInput}
                      type="file"
                      className={`form-control d-none`}
                      multiple
                      onChange={(e) => {
                        handleFileUpload(e);
                      }}
                    />
                    <Dropdown.Item className="bg-secondary">
                      <span
                        onClick={() => {
                          if (fileInput.current) {
                            fileInput.current.click();
                          }
                        }}
                      >
                        <div className="d-flex align-items-end justify-content-center">
                          <p className="">Import</p>
                        </div>
                      </span>
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
            <Button
              type="submit"
              variant="contained"
              className="addBtn"
              onClick={() => {
                getAllApplications(true);
                setIsOpenReturned(true);
              }}
            >
              View Returned Users
            </Button>
          </div>
        </div>
      </section>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>National ID</StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>No. of Appliction</StyledTableCell>
              <StyledTableCell>Sent To Drive</StyledTableCell>
              <StyledTableCell>Drive Account No.</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Creation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allApplications?.map((ele, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{ele.id}</StyledTableCell>

                  <StyledTableCell>
                    {isViewOrEdit ? (
                      <Link
                        to={`/oneTransaction/dataEntry/Application/${ele.id}/`}
                        className="linkStyle"
                        state={{
                          userData: ele,
                        }}
                      >
                        {ele.national_id}
                      </Link>
                    ) : (
                      <>{ele.national_id}</>
                    )}
                  </StyledTableCell>

                  <StyledTableCell>
                    {ele.first_name} {ele.last_name}
                  </StyledTableCell>
                  <StyledTableCell>{ele.no_of_loans}</StyledTableCell>

                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      defaultChecked={ele.sent_to_drive}
                      disabled
                    />
                  </StyledTableCell>
                  <StyledTableCell>{ele.drive_account_number}</StyledTableCell>
                  <StyledTableCell>
                    {moment(ele.created).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  {/* <StyledTableCell>{moment(ele.iscore_date).format("DD-MM-YYYY")}</StyledTableCell> */}
                  <StyledTableCell>
                    <Button
                      className="small_btn"
                      type="click"
                      variant="contained"
                      onClick={(e) => {
                        setIsCreditOpen({ isOpen: true, user: ele.user });
                        getSalesManAgent();
                      }}
                    >
                      Create Credit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={dataDetail}
        page={page}
        handleChange={handleChange}
      />

      {/* modal of create credit (select program) */}
      <Modal
        show={isCreditOpen.isOpen}
        onHide={() => handleCloseModal()}
        size="lg"
        centered
        className="modal_style"
      >
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Select Program</Form.Label>
            <Form.Select
              id="disabledSelect"
              value={selectedProgram}
              onChange={(e) => setSelectedProgram(e.target.value)}
            >
              <option disabled value="" selected>
                Select Program
              </option>
              {programs &&
                programs.map((item, index) => (
                  <option value={item.id} key={index.toString()}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Service provider</Form.Label>
            <Form.Control
              type="text"
              disabled={!selectedProgram}
              value={providerServ}
              onChange={(e) => setProviderServ(e.target.value)}
              required
              isInvalid={!providerServ}
            />

            <Form.Control.Feedback type="invalid">
              Please enter provider name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Service info</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={serviceInfo}
              onChange={(e) => setServiceInfo(e.target.value)}
              disabled={!selectedProgram}
            />
          </Form.Group>

          <section className="mb-3">
            <p className="p_label_style">Sales Man</p>

            <CreateSelectComponent
              value={selectedSalesMan}
              options={salesAgentArr?.salesmen}
              placeholder="Select Sales"
              customStyle={{ width: "100%" }}
              disabled={!isViewSalseAndAgency}
              onChange={(value) => setSelectedSalesMan(value)}
            />
            {!selectedSalesMan && isViewSalseAndAgency && selectedProgram && (
              <p className="err_msg">Please select salesman</p>
            )}
            {/* <Button
                style={{ marginLeft: 10 }}
                onClick={() => addSalesOrAgent("sales")}
              >
                <AiOutlinePlus size={20} />
              </Button> */}
            {/* <AiOutlinePlusSquare size={20}/> */}
          </section>
          <section className="mb-3">
            <p className="p_label_style">Agency</p>
            <CreateSelectComponent
              value={selectedAgency}
              options={salesAgentArr?.agencies}
              placeholder="Select Agency"
              customStyle={{ width: "100%" }}
              disabled={!isViewSalseAndAgency}
              onChange={(value) => setSelectedAgency(value)}
            />
            {!selectedAgency && isViewSalseAndAgency && selectedProgram && (
              <p className="err_msg">Please select agency</p>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal()}>
            Close
          </Button>
          <Button
            className="small_btn"
            variant="contained"
            disabled={
              !selectedProgram ||
              !providerServ ||
              (!selectedAgency && isViewSalseAndAgency) ||
              (!selectedSalesMan && isViewSalseAndAgency)
            }
            onClick={() => oneTransCreateCredit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* return history */}
      <Modal
        show={isOpenReturned}
        onHide={() => setIsOpenReturned(false)}
        size="lg"
        centered
        className="returnModalStyle"
      >
        <Modal.Body>
          <h6 className="subTitle">Returned User Data</h6>
          <TableContainer
            component={Paper}
            style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>National ID</StyledTableCell>
                  <StyledTableCell>Program Name</StyledTableCell>
                  <StyledTableCell>Return Date</StyledTableCell>
                  <StyledTableCell>Return Reason</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {returnedUserData?.results?.map((ele, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{ele.national_id}</StyledTableCell>

                      <StyledTableCell>
                        {ele?.profile_comments.length > 0
                          ? ele?.profile_comments[0]?.program_name
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ele?.profile_comments.length > 0
                          ? moment(
                              ele?.profile_comments[0]?.return_date
                            ).format("DD-MM-YYYY")
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ele?.profile_comments.length > 0
                          ? ele?.profile_comments[0]?.comment
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          className="small_btn"
                          type="click"
                          variant="contained"
                        >
                          <Link
                            to={`/oneTransaction/dataEntry/Application/${ele.id}/`}
                            style={{ color: "white", textDecoration: "none" }}
                            state={{
                              userData: ele,
                            }}
                          >
                            View Account
                          </Link>
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationComponent
            brands={returnedUserData}
            page={returnedPage}
            handleChange={(e, value) => setReturnedPage(value)}
          />
        </Modal.Body>
      </Modal>
    </main>
  );
};
