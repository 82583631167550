import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button, Form } from "react-bootstrap";
import RequestHistory from "./RequestHistory";
import { customStyles } from "../../constants/Colors";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";

const UserRequestModals = ({
  isOpen,
  setModal,
  shouldCloseOnOverlayClick,
  setReason,
  request_tool_action,
  modalName,
  requestId,
  setReload,
}) => {

  const reasonModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "35%",
      //   overflowY: "scroll",
      height: "40%",
      //   backgroundColor:"#41B6A8",
      borderRadius: 20,
    },
  };
  const [branchOptions, setBranchOptions] = useState();
  const [newBranch, setNewBranch] = useState();
  const [selectSize, setSelectSize] = useState(1);

  const branches_options = () => {
    UserUtility.branches_options()
      .then((res) => {
        setBranchOptions(res);
      })
      .catch((err) => console.error("branches_options", err));
  };
  const changeBranch = () => {
    UserUtility.changeBranch(requestId, newBranch)
      .then((res) => {
        toast.success(res.details);
        setModal(false);
        setTimeout(() => {
          setReload(true);
        }, 2000);
      })
      .catch((err) => toast.error(err));
  };
  useEffect(() => {
    branches_options();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModal(false)}
      style={modalName === "historyModal" ? customStyles : reasonModalStyle}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {modalName === "reasonModal" ? (
        <>
          <Form.Control
            className="shadow rounded mt-4"
            as="textarea"
            rows={5}
            placeholder="Add Reason"
            type="text"
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
          <div style={{ marginTop: "50px", textAlign: "center" }}>
            <Button variant="success" onClick={() => request_tool_action()}>
              Submit
            </Button>
          </div>
        </>
      ) : modalName === "historyModal" ? (
        <RequestHistory requestId={requestId} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "34vh",
            flexDirection: "column",
          }}
        >
          <Form.Select
            style={{
              width: "50%",
              height: selectSize === 1 ? "40px" : "120px",
              borderRadius: "8px",
            }}
            size="sm"
            htmlSize={selectSize}
            onChange={(e) => {
              setNewBranch(e.target.value);
            }}
            onFocus={() => setSelectSize(4)}
            // onBlur={() => setSelectSize(1)}
          >
            <option value={""}>Select new Branch</option>
            {branchOptions?.map((res, id) => (
              <option value={res.id} name={res.name_en} key={id}>
                {res.name_en}
              </option>
            ))}
          </Form.Select>
          <div style={{ marginTop: "50px" }}>
            <Button variant="success" onClick={() => changeBranch()}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
export default UserRequestModals;
