import React, { useContext, useState, useEffect } from "react";
import "./menu.scss";
import { Link, NavLink } from "react-router-dom";
import ForsaLogo from "../../assets/forsaLogo3.png";
import UserContext from "../../hooks/userContext";
import { UserUtility } from "../../apis/UserUtility";
import { AiOutlineDoubleRight } from "react-icons/ai";

export default function Menu() {
  const [usertData, setUserData] = useState(null);
  const {
    permissions = [],
    setIsLogin,
    setToolProfile,
  } = useContext(UserContext);
  const [hideNav, setHideNav] = useState(false);
  const [loanPermission, setLoanPermissing] = useState(false);
  const limit =
    permissions?.includes("is_limit_checker") ||
    permissions?.includes("is_limit_maker");
  const manual =
    permissions?.includes("is_risk_checker") ||
    permissions?.includes("is_risk_maker");
  const request =
    permissions?.includes("customer_support_dep") ||
    permissions?.includes("finance_dep") ||
    permissions?.includes("risk_dep") ||
    permissions?.includes("treasury_dep") ||
    permissions?.includes("archive_dep");

  const isOneTransaction = permissions.includes("view_onetransactionprofile");
  const isNotificationDashboard = permissions.includes(
    "notification_audience_list"
  );
  let isAdmin = permissions.includes("on_boarding_tool_admin");
  let isLegal = permissions.includes("can_approve_legal_user");
  let isRisk = permissions.includes("can_approve_risk_user");
  let isEntry = permissions.includes("data_entry_on_boarding");
  let isMarketing = permissions.includes("marketing__on_boarding");
  let isOwner = permissions.includes("owner__on_boarding");

  let isFailersystem = permissions.includes("failure_tool_tool_admin");

  let isIscoreTool = permissions.includes("can_use_iscore_tool");

  let isOfferOverview =  permissions.includes("can_view_offers_listing_tool");
  const navButtonsAr = [
    { title: "Tools", path: "/" },
    { title: "Loans", path: "/loans" },
    { title: "Users profile", path: "/users" },
    // { title: "offer overview", path: "/offerOverView" },

    // { title: "Iscore Report", path: "/IscoreTool" },
  ];
  const legal = permissions?.includes("legal_tool");

  const collection_safe = permissions?.includes(
    "can_view_users_advanced_payment_balances"
  );
  const financeReport = permissions?.includes("Can_export_payments_data");
 

  const advancedReport = permissions?.includes(
    "can_export_advanced_payments_custom_report"
  );
  const viewKiosk = permissions?.includes("can_view_kiosk");

  
  if (manual) {
    navButtonsAr.push({ title: "Manual study", path: "manualStudy" });
  }

  if (viewKiosk) {
    navButtonsAr.push({
      title: "Activation Point",
      path: "ActivationPointTool",
    });
  }

  if (limit) {
    navButtonsAr.push({ title: "Limit increase", path: "limitIncrease" });
  }
  if (financeReport || advancedReport) {
    navButtonsAr.push({ title: "Finance report", path: "/FinanceReport" });
  }
  if (legal) {
    navButtonsAr.push({ title: "الشئون القانونية", path: "/Main" });
  }

  if (isOfferOverview) {
    navButtonsAr.push({ title: "offer overview", path: "/offerOverView" });
  }
  if (isOneTransaction) {
    navButtonsAr.push({
      title: "One Transaction",
      path: "oneTransaction/DataEntry",
    });
  }

  if (isNotificationDashboard) {
    navButtonsAr.push({ title: "Notification", path: "/notification" });
  }
  if (collection_safe) {
    navButtonsAr.push({ title: "Finance Safe", path: "/FinanceSafe" });
  }
  if (request) {
    navButtonsAr.push({ title: "User request", path: "/userRequest" });
  }

  if (isNotificationDashboard) {
    navButtonsAr.push({ title: "Notification", path: "/notification" });
  }

  if (isAdmin || isLegal || isRisk || isEntry || isMarketing || isOwner) {
    navButtonsAr.push({ title: "Merchant OnBoard", path: "/MerchantOnBoard" });
  }
  if (request) {
    navButtonsAr.push({ title: "User request", path: "/userRequest" });
  }

  if (isFailersystem) {
    navButtonsAr.push({ title: "Requests Failure", path: "/requestsFailure" });
  }

  if (isIscoreTool) {
    navButtonsAr.push({ title: "Iscore Report", path: "/IscoreTool" });
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    let is_exist = permissions.includes("is_loan_dashboard_user");
    setLoanPermissing(is_exist);
  }, [permissions]);

  const logoutFunc = () => {
    localStorage.removeItem("tools_token");
    setIsLogin(false);
  };

  const getUserData = () => {
    UserUtility.userData()
      .then((res) => {
        setUserData(res);
        setToolProfile(res);
      })
      .catch((err) => {
        console.error("failed to get User data", err);
      });
  };

  const checkPermissions = permissions?.map((permission, id) => {
    switch (permission) {
      case "is_loan_dashboard_user":
        return (
          <Link to="/loans" className="tool_card" key={id}>
            <h3>Loans</h3>
          </Link>
        );

      default:
        return (
          <Link to="/" className="tool_card" key={id}>
            <h3>Tools</h3>
          </Link>
        );
    }
  });
  return (
    <ul
      onClick={() => setHideNav(!hideNav)}
      className={`menu ${hideNav && "menu--hidden"}`}
    >
      <div className="arrow-container">
        <div className="arrow">
          <AiOutlineDoubleRight />
        </div>
      </div>
      <li className="logo">
        <img alt="logo" src={ForsaLogo} />
      </li>
      <h5 className="user_name">{usertData?.username || "User name"}</h5>
      {navButtonsAr.map((res, index) => {
        return (
          <li key={index} className="nav_li">
            <NavLink
              className="nav_link"
              style={{ textAlign: res.path === "/Main" && "right" }}
              to={res.path}
            >
              {res.title}
            </NavLink>
          </li>
        );
      })}

      {/* <li className="nav_li">
        <NavLink className="nav_link" to="/">
          Tools
        </NavLink>
      </li>

      {loanPermission && (
        <li className="nav_li">
          <NavLink className="nav_link" to="/loans">
            Loans
          </NavLink>
        </li>
      )}
      <li className="nav_li">
        <NavLink className="nav_link" to="/users">
          users profile
        </NavLink>
      </li> */}

      <li className="nav_li">
        <Link to="/" onClick={logoutFunc} className="nav_link">
          Logout
        </Link>
      </li>
    </ul>
  );
}
