import React, { useContext, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import UserContext from "../../hooks/userContext";
import "../../styles/SegmentStyle.scss"
const Operation = () => {
  const choosingBar = [
    {
      name: "Makers",
      tag: "Makers",
      selected: true
    },
    {
      name: "Contracts",
      tag: "Contracts",
      selected: false
    },
    {
      name: "Summary",
      tag: "Summary",
      selected: false
    },
  ];
  //to verify if the user able to see makers component or not
  const { permissions = [] } = useContext(UserContext);
  const [isDisabled, setIsDisabled] = useState(false)

  const handleClick = (e, bar) => {

    if (bar.tag === 'Summary' && !permissions.includes("can_view_contract_tracking_summary")) {
      e.preventDefault()
      setIsDisabled(true)
    }
    else if (bar.tag === 'Contracts' && (!permissions.includes("contract_operations_risk") && !permissions.includes("contract_operations_archive") && (!permissions.includes("contract_operations")))) {
      e.preventDefault()
      setIsDisabled(true)
    }
    else if (bar.tag === 'Makers' && !permissions.includes("contract_operations")) {
      e.preventDefault()
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }
  return (
    <article className="segment_container">
      <section className="choosing_bar">
        <ul className="profile_navBar_wrapper">

          {choosingBar.map((bar, id) => {
            return (
              <li className="list_item" key={id}>
                <NavLink
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    cursor: isDisabled ? 'default' : 'pointer'
                  }}
                  className="navLink"
                  to={bar.tag}
                  end
                  onClick={(event) => handleClick(event, bar)}
                >
                  {bar.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </section>

      <Outlet />
    </article>
  );
};

export default Operation;
