import React from "react";
import { useLocation } from "react-router-dom";
import Main from "../../pages/legal/Main";
const LegalHistory = () => {
  const location = useLocation();
  const { id } = location.state;

  return <Main historyUser_id={id} />;
};

export default LegalHistory;
