/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../../apis/UserUtility";
import "./Style.scss";
import Container from "react-bootstrap/Container";
import CollectionTables from "./CollectionTables/CollectionTables";
import UserContext from "../../../hooks/userContext";

const CollectionContainer = () => {
  const location = useLocation();
  const { userId } = location.state;
  const [installment, setInstallment] = useState();
  const [products, setProducts] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [productsPage, setProductsPage] = useState(1);
  const [penalties, setPenalties] = useState();
  const [totalPenaltiesRes, setTotalPenaltiesRes] = useState();
  const [penaltiesPage, setPenaltiesPage] = useState(1);
  const [createdDateSorting, setCreatedDateSorting] = useState(false);
  const [installmentAmountSorting, setInstallmentAmountSorting] =
    useState(false);
  const [installmentDueDate, setInstallmentDueDate] = useState(false);
  const [remainPenalties, setRemainPenalties] = useState(false);
  const [holdPenalties, setHoldPenalties] = useState(false);
  const [sortingColumn, setSortingColumn] = useState();
  const { handleLoader, permissions = [] } = useContext(UserContext);

  const installmentsList = () => {
    UserUtility.monthly_installments(userId)
      .then((res) => {
        setInstallment(res);
      })
      .catch((err) => {
        console.error("installmentsList", err);
      });
  };
  const productsList = () => {
    UserUtility.collection_products(userId)
      .then((res) => {
        setProducts(res.results);
      })
      .catch((err) => {
        console.error("productsList", err);
      });
  };

  const penaltiesList = () => {
    UserUtility.collection_penalties(
      userId,
      sortingColumn === "created"
        ? createdDateSorting
          ? "created"
          : "-created"
        : "",
      sortingColumn === "amount"
        ? installmentAmountSorting
          ? "installment__amount"
          : "-installment__amount"
        : "",
      sortingColumn === "due_date"
        ? installmentDueDate
          ? "installment__due_date"
          : "-installment__due_date"
        : "",
      sortingColumn === "penalty_remaining_amount"
        ? remainPenalties
          ? "penalty_remaining_amount"
          : "-penalty_remaining_amount"
        : "",
      sortingColumn === "on_hold"
        ? holdPenalties
          ? "on_hold"
          : "-on_hold"
        : ""
    )
      .then((res) => {
        setTotalPenaltiesRes(res);
        setPenalties(res.results);
      })
      .catch((err) => {
        console.error("penaltiesList", err);
      });
  };

  useEffect(() => {
    productsList();
    installmentsList();
    penaltiesList();
  }, []);

  // useEffect(() => {
  //   if (permissions.includes("can_view_user_penalties")) {
  //   }
  // }, [
  //   penaltiesPage,
  //   permissions.length,
  //   createdDateSorting,
  //   installmentAmountSorting,
  //   installmentDueDate,
  //   remainPenalties,
  //   holdPenalties,
  // ]);
  return (
    <Container className="mt-5 w-100">
      <div className="mt-4">
        <CollectionTables
          Section="Installments"
          list={installment}
          user_id={userId}
        />
        <CollectionTables
          Section="Products"
          list={products}
          user_id={userId}
          totalProducts={totalProducts}
          setProductsPage={setProductsPage}
          productsPage={productsPage}
        />
        <CollectionTables
          Section="Penalties"
          list={penalties}
          user_id={userId}
          totalPenaltiesRes={totalPenaltiesRes}
          setPenaltiesPage={setPenaltiesPage}
          penaltiesPage={penaltiesPage}
          createdDateSorting={createdDateSorting}
          setCreatedDateSorting={setCreatedDateSorting}
          installmentAmountSorting={installmentAmountSorting}
          setInstallmentAmountSorting={setInstallmentAmountSorting}
          installmentDueDate={installmentDueDate}
          setInstallmentDueDate={setInstallmentDueDate}
          setSortingColumn={setSortingColumn}
          sortingColumn={sortingColumn}
          remainPenalties={remainPenalties}
          setRemainPenalties={setRemainPenalties}
          holdPenalties={holdPenalties}
          setHoldPenalties={setHoldPenalties}
        />
      </div>
    </Container>
  );
};
export default CollectionContainer;
