import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import OneTransactionContext from "../../hooks/oneTransactionContext";
import "../../styles/SegmentStyle.scss"
export const OneTransaction = () => {
  const [oneTransactionOptionResult, setOneTransactionOptionResult] = useState()
  const [programs, setPrograms] = useState([])

  const choosingBar = [
    {
      name: "Data Entry",
      tag: "dataEntry",
      selected: true
    },
    {
      name: "Loans",
      tag: "loans",
      selected: false
    },
    {
      name: "Operation",
      tag: "operation",
      selected: false
    },
    {
      name: "Finance",
      tag: "finance",
      selected: false
    },
    {
      name: "Collection",
      tag: "collection",
      selected: false
    },
  ];
 
  return (
      <article className="segment_container">
        <section className="choosing_bar">
          <ul className="profile_navBar_wrapper">

            {choosingBar?.map((bar, id) => {
              return (
                <li className="list_item" key={id}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      width: "100%",
                    }}
                    className="navLink"
                    to={bar.tag}
                    end
                  >
                    {bar.name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </section>

        <Outlet />
      </article>
  );

}