import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";

const ContractHistory = ({ ContractHistoryState }) => {
  const [usertContracts, setContracts] = useState([]);
  const [pagesNumber, setPagesNumber] = useState(1);
  const [results, setResults] = useState();
  const location = useLocation();
  const { userId } = location.state;
  const users_contracts = () => {
    if (ContractHistoryState) {
      UserUtility.contract_history(userId, pagesNumber)
        .then((res) => {
          setResults(res);
          setContracts(res);
        })
        .catch((err) => console.error("user history err", err));
    }
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  useEffect(() => {
    users_contracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContractHistoryState, pagesNumber]);
  return (
    <div className="mb-3">
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Action</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Courier</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
              <StyledTableCell>Employee name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usertContracts?.results?.map((contracts, id) => (
              <StyledTableRow key={id}>
                <StyledTableCell>{contracts.action_type}</StyledTableCell>
                <StyledTableCell>{contracts.created}</StyledTableCell>
                <StyledTableCell>{contracts.courier}</StyledTableCell>
                <StyledTableCell>{contracts.department}</StyledTableCell>
                <StyledTableCell>{contracts.operations_user}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ContractHistory;
