import React, { useContext } from 'react'
import UserContext from '../../../hooks/userContext';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { ENDPOINTS } from '../../../apis/EndPoints';

const ExportDraft = ({
    status,
    approved_by_legal,
    approved_by_risk,
    startDate,
    endDate,
    handleSearch
}) => {

    const { handleLoader } = useContext(UserContext);

    const handleExportDraft = () => {
        let token = localStorage.getItem("tools_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `token ${token}`);
        var formdata = new FormData();
        status && formdata.append("status", status);
        approved_by_legal && formdata.append("approved_by_legal", approved_by_legal);
        approved_by_risk && formdata.append("approved_by_risk", approved_by_risk);
        endDate && startDate && formdata.append("start_date", startDate);
        startDate && endDate && formdata.append("end_date", endDate);
        if (status === "" && approved_by_legal === "" && approved_by_risk === "" && endDate === "" && startDate === "") {
            formdata.append("all", "all");
        }
        console.log(formdata);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(ENDPOINTS.exportDraft.url, requestOptions)
            .then(async (res) => {
                handleLoader(false);
                if (!res.ok) {
                    const responseJson = await res.json();
                    const errorMessage = responseJson.errors;
                    toast.error(errorMessage?.non_field_errors[0])
                    console.log('errorMessage: ',errorMessage)

                    throw new Error(errorMessage);
                } else {
                    handleLoader(false);
                    // toast.success("Export Successfully");
                    const responseJson = await res.json();
                    responseJson?.url ? window.location.href = `${responseJson?.url}` : toast.error(`Not Have Results After Filter`);
                    return responseJson;
                }
            })
            .catch((error) => {
                // console.error("edit Draft error:: ", error);
                // toast.error(error);
                handleLoader(false);
            });
    };


    return (
        <div className="mt-3 mb-0">
            <Button
                className="small_btn"
                type="button"
                variant="contained"
                onClick={() => {
                    handleExportDraft();
                    handleSearch();
                }}
            >
                Export
            </Button>
        </div>
    )
}

export default ExportDraft
