import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
} from "@mui/material";
import { useContext, useMemo } from "react";
import { StyledTableCell, StyledTableRow } from "../../../constants/Colors";
import UserContext from "../../../hooks/userContext";
import './offerTable.scss'
const GlobalOfferTable = ({
    list,
    onBtnRemove,
    tableData
}) => {
    const tableHead = (data) => {
        return (
            <>
                {data?.map((item, index) => (
                    <StyledTableCell key={index}>{item.title}</StyledTableCell>
                ))}
            </>
        )
    }
    const tableDataRerender = useMemo(() => tableHead(tableData), [])

    return (
        <>
            <TableContainer
                component={Paper}
                style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
            >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {tableDataRerender}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((row, id) => (
                            <StyledTableRow key={id}>
                                <StyledTableCell>
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.title}
                                </StyledTableCell>
                                {/* <StyledTableCell>
                                    {row.sector}
                                </StyledTableCell> */}
                                <StyledTableCell>
                                    <button className="removeBtn" onClick={() => onBtnRemove(row.id)}>Remove</button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default GlobalOfferTable;
