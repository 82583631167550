// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { UserUtility } from "../../apis/UserUtility";
import Modal from "react-modal";
import UserContext from "../../hooks/userContext";
import { ENDPOINTS } from '../../apis/EndPoints'
import axios from "axios";
import { ChequesFormComponent } from "../../components/OneTransactionComponent/ChequesFormComponent";
import { customStyles, StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { useReactToPrint } from "react-to-print";
import OneTransactionContext from "../../hooks/oneTransactionContext";
import { LoanDocumentComponent } from "../../components/OneTransactionComponent/LoanDocumentComponent";

const dates = [
    { lable: "Contract Date", name: "contract_date" },
    { lable: "Start Date", name: "start_date" },
    { lable: "End Date", name: "end_date" },
];
export const CommonContractComponent = ({ creditData, loanId, getFrom, setToFinanceMaker, setToOperationMaker, setFinanceReturnToData, setToCreditMaker, setOperationPaymentData, setNewData }) => {
    const [contract_date, setContract_date] = useState();
    const [creditFormData, setCreditFormData] = useState(creditData);
    const [modalAR, setModalAR] = useState(false);
    const { handleLoader, permissions = [] } = useContext(UserContext);
    const [isOpenCheque, setIsOpenCheque] = useState(false);
    const [reasonModal, setReasonModal] = useState(false);
    const [reasons, setReasons] = useState();
    const [installmentsAR, setInstallmentsAR] = useState(creditFormData?.installments || [])
    const [paymentData, setPaymentData] = useState({ status: "checking_operation" })
    const [loading, setLoading] = useState(false);
    const componentRef = useRef(null);
    const onBeforeGetContentResolve = useRef(null);
    const [isApplyBtnDisabled, setIsApplyBtnDisabled] = useState(false)
    const { oneTransactionOtherOptionResult } = useContext(OneTransactionContext);
    const [paymentOptions, setPaymentOptions] = useState();
    const [isUpdateDate, setIsUpdateDate] = useState(false)
    const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false);

    const isCreditMaker = permissions.includes("one_transaction_credit_maker") && (getFrom === 'credit')
        ? true
        : false;
    const isCreditChecker = permissions.includes("one_transaction_credit_checker") && (getFrom === 'credit')
        ? true
        : false;


    const isOperationMaker = permissions.includes("one_transaction_operation_maker") && (getFrom === 'operation')
        ? true
        : false;
    const isOperationChecker = permissions.includes("one_transaction_operation_checker") && (getFrom === 'operation')
        ? true
        : false;


    const isFinancenMaker = permissions.includes("one_transaction_finance_maker") && (getFrom === 'finance')
        ? true
        : false;

    const isFinanceChecker = permissions.includes("one_transaction_finance_checker") && (getFrom === 'finance')
        ? true
        : false;


    const makerOperationType = "one_transaction_operation_maker";
    const checkerOperationType = "one_transaction_operation_checker";


    const makerFinanceType = "one_transaction_finance_maker";
    const checkerFinanceType = "one_transaction_finance_checker";

    // operation or finance
    const [makerComment, setMakerComment] = useState({
        oldComment: null,
        comment: null,
        id: null,
    });
    const [checkerComment, setCheckerComment] = useState({
        oldComment: null,
        comment: null,
        id: null,
    });

    const applyContract = () => {
        console.log('contract_date: ', contract_date)
        if (creditFormData?.iscore_valid) {
            if (contract_date) {
                setIsApplyBtnDisabled(true)
                // setCreditFormData()
                UserUtility.sendToOperationChekcer(loanId, contract_date)
                    .then((res) => {
                        setCreditFormData(res);
                        setIsUpdateDate(!isUpdateDate)
                        setNewData(res)
                        handleLoader(false)
                        toast.success("Applied Successfully");
                        setIsApplyBtnDisabled(false)
                    })
                    .catch((err) => {
                        console.error("applyContract:", err);
                        setIsApplyBtnDisabled(false)
                    });
            }
        } else {
            toast.error("iscore expired");
            setIsApplyBtnDisabled(false)
        }
    };

    // Download Contract PDF

    const printContract = () => {
        handleLoader(true)
        UserUtility.printContract(loanId, 'blob').then(res => {
            console.log('res of printContract: ', res)
            handleLoader(false)
            handleDownload(res?.contract)
        }).catch(err => {
            handleLoader(false)
            console.log('err of printContract: ', err)
            toast.error(err)
        })
    }

    const handleDownload = (ExamplePdf) => {
        const link = document.createElement('a');
        link.download = 'Report-Contract-File';
        link.href = ExamplePdf;
        link.target = "_blank"
        link.click()
    };

    const printContract2 = async () => {
        handleLoader(true);
        const token = localStorage.getItem("tools_token");
        const headers = {
            "Content-Type": "blob",
            Authorization: `token ${token}`,
        };
        const config = {
            method: "GET",
            url: `${ENDPOINTS.url}onetransaction/contract/${loanId}/`,
            responseType: "arraybuffer",
            headers,
        };

        try {
            const response = await axios(config).then(res => console.log('res of then: ', res)).catch(err => console.log('err of catch: ', err));
            const url = URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Contract.PDF`);
            document.body.appendChild(link);
            link.click();
            handleLoader(false);
        } catch (error) {
            console.log('errrror: ', Error(error))
            handleLoader(false);
            throw Error(error);
        }
    };

    const handleCheques = () => {
        setIsOpenCheque(true);
    };

    const txtReasonSwitch = () => {
        if (isOperationChecker) {
            return 'Return to operation maker reason'
        } else if (isOperationMaker) {
            return 'Return to credit maker reason'
        }
        if (isFinanceChecker) {
            return 'Return to finance maker reason'
        } else if (isFinancenMaker) {
            return 'Return reason'
        }
    }

    const handleClickReasonSwitch = (e) => {
        if (isOperationChecker) {
            setToOperationMaker({
                reason: e.target.value,
            })
        }
        if (isFinanceChecker) {
            // return to finance component to send to finance maker
            setToFinanceMaker({
                reason: e.target.value,
            })
        }

        if ((isFinancenMaker)) {
            setFinanceReturnToData({
                reason: e.target.value
            })
        }
        if ((isOperationMaker)) {
            setToCreditMaker({
                reason: e.target.value
            })
        }
    }

    // start print AR
    const handleOnBeforeGetContent = useCallback(() => {
        setLoading(true);
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                resolve();
            }, 500);
        });
    }, [setLoading]);


    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        onBeforeGetContent: handleOnBeforeGetContent,
        removeAfterPrint: true,
    });
    // end print AR


    //start comments of operation or finance
    // add comment operation (maker or checker)
    const handlAddOrEditComment = () => {
        let type, commentId, finalComment, isEditable;
        if (isOperationMaker) {
            type = makerOperationType;
            finalComment = makerComment.comment;
            commentId = makerComment.id;
            isEditable = (makerComment.comment?.trim() != makerComment.oldComment?.trim()) && true
        } else if (isOperationChecker) {
            type = checkerOperationType;
            finalComment = checkerComment.comment;
            commentId = checkerComment.id;
            isEditable = (checkerComment.comment?.trim() != checkerComment.oldComment?.trim()) && true

        } else if (isFinancenMaker) {
            type = makerFinanceType;
            finalComment = makerComment.comment;
            commentId = makerComment.id;
            isEditable = (makerComment.comment?.trim() != makerComment.oldComment?.trim()) && true

        } else if (isFinanceChecker) {
            type = checkerFinanceType;
            finalComment = checkerComment.comment;
            commentId = checkerComment.id;
            isEditable = (checkerComment.comment?.trim() != checkerComment.oldComment?.trim()) && true
        }

        let obj = {
            comment: finalComment,
            onetransactionloan: loanId,
            type: type,
        };

        console.log('makerComment isEditable: ', isEditable)

        if (isEditable === true) {
          
            UserUtility.oneTransAddComment(obj)
                .then(() => {
                    toast.success('Comment Saved')
                    getOperationOrFinanceComments()
                })
                .catch((err) => {
                    console.error("error of addComment: ", err);
                    if (err.detail) {
                        toast.error("Add comment " + err.detail);
                    } else {
                        toast.error("Add comment " + err);

                    }
                })
                .finally(() => {
                    // setOpenModal(false);
                    setMakerComment({ comment: null, id: null });
                    setCheckerComment({ comment: null, id: null});
                });
        }
    };

    const getOperationOrFinanceComments = () => {
        let type;
        if (getFrom === 'operation') {
            type = makerOperationType + "," + checkerOperationType;
        } else if (getFrom === 'finance') {
            type = makerFinanceType + "," + checkerFinanceType;
        }
        UserUtility.oneTransGetComments(loanId, type)
            .then((res) => {
                if (res.results && res.results.length > 0) {
                    let findMakerObj = res.results?.find((item) => {
                        console.log('')
                        return (item.type === makerOperationType) || (item.type === makerFinanceType) ? item : null;
                    });

                    let findCheckerObj = res.results?.find((item) => {
                        return (item.type === checkerOperationType) || (item.type === checkerFinanceType) ? item : null;
                    });
                    if (findMakerObj) {
                        setMakerComment({
                            oldComment: findMakerObj.comment,
                            comment: findMakerObj.comment,
                            id: findMakerObj.id,
                        });
                    }

                    if (findCheckerObj) {
                        setCheckerComment({
                            oldComment: findCheckerObj.comment,
                            comment: findCheckerObj.comment,
                            id: findCheckerObj.id,
                        });
                    }
                }
            })
            .catch((err) => {
                console.error("error of get comments: ", err);
            });
    };
    // end comments of operation or finance


    const uploadDocuments = (file) => {
        var token = localStorage.getItem("tools_token");
        var formdata = new FormData();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `token ${token}`);
        formdata.append("onetransactionloan", loanId);
        formdata.append("name", file.name);
        formdata.append("file", file);
        formdata.append("department", "operation")

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        handleLoader(true);

        fetch(`${ENDPOINTS.url}onetransaction/loandocuments/`, requestOptions)
            .then(async (res) => {
                handleLoader(false);

                if (!res.ok) {
                    const text = await res.json();
                    // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
                    toast.error(text.detail || text[0] || "something went wrong");
                    // throw new Error(text);
                } else {
                    // navigate(-1);
                    toast.success("File has been saved successfully");
                    return res.json();
                }
            })
            .catch((err) => {
                handleLoader(false);
                console.error("error editing on imagessss", err);
            });
    };



    useEffect(() => {
        if (getFrom === "operation") {
            setOperationPaymentData({ ...paymentData, payment_method: creditFormData?.onetransactionloandata?.payment_method })
            getOperationOrFinanceComments(loanId)
        }
        if (getFrom === 'finance') {
            getOperationOrFinanceComments(loanId)

        }
        setInstallmentsAR(creditFormData?.installments)
    }, [creditFormData])

    useEffect(() => {
        setPaymentOptions(oneTransactionOtherOptionResult?.onetransactionloandata?.children?.payment_method?.choices)

    }, [oneTransactionOtherOptionResult])

    useEffect(() => {
        setCreditFormData(creditData)
    }, [creditData?.id])

    console.log('creditFormData test: ', creditFormData)
    return (
        <main>
            <section>
                <Row >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {dates?.map((ele, index) => (
                            <>
                                <Col lg={2} md={2} sm={12} key={index}>
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        label={ele.lable}
                                        disablePast={ele.lable === "Contract Date"}
                                        readOnly={
                                            isOperationMaker ? ele.lable !== "Contract Date" : true
                                        }
                                        value={
                                            ele.lable === "Contract Date"
                                                ?
                                                contract_date?.contract_date ?
                                                    new Date(contract_date?.contract_date) :
                                                    creditFormData?.onetransactionloandata?.contract_date ? new Date(creditFormData?.onetransactionloandata?.contract_date) : null
                                                : creditFormData
                                                    ? creditFormData[ele.name] ? new Date(creditFormData[ele.name]) : null
                                                    : null
                                        }

                                        onChange={(e) => {
                                            setContract_date({
                                                ...contract_date,
                                                [ele.name]: moment(e).format("YYYY-MM-DD"),
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Col>
                            </>
                        ))}


                    </LocalizationProvider>
                    <Col lg={4} md={5} sm={12}>
                        <Button
                            className="small_btn"
                            variant="contained"
                            onClick={() => setModalAR(true)}
                        >
                            {(isOperationChecker || isFinanceChecker || isCreditChecker || isCreditMaker) ? "View AR" : "Print AR"}
                        </Button>

                        <Button
                            className="small_btn"
                            variant="contained"
                            onClick={() => printContract()}
                        >
                            {(isOperationChecker || isFinanceChecker || isCreditChecker || isCreditMaker) ? "View Contract" : "Print Contract"}
                        </Button>
                    </Col>
                </Row>

                {/* row2 */}

                <div className=" row row_div align-items-end">
                    <Form className="col-md-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns} style={{ height: 40 }}>
                            <DatePicker

                                className="picker_div"
                                inputFormat="dd/MM/yyyy"
                                label="i.Score Report Date"
                                readOnly={true}
                                value={new Date(creditFormData?.onetransactionloandata?.iscore_date)}
                                // value={new Date('2022-09-11')}
                                onChange={(e) => { }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Form>
                    <Form className="col-md-3">
                        <Form.Text>payment method</Form.Text>

                        <Form.Select
                            className="mt-1"
                            value={
                                paymentData?.payment_method ||
                                creditFormData?.onetransactionloandata?.payment_method ||
                                null
                            }
                            // style={{ width: "23%" }}
                            disabled={isOperationMaker ? false : true}
                            // size="lg"
                            onChange={(e) => {
                                setPaymentData({ ...paymentData, payment_method: e.target.value });
                                setOperationPaymentData({ ...paymentData, payment_method: e.target.value })
                            }}
                        >
                            <option value={""}>Select the payment method</option>
                            {paymentOptions?.map((res, id) => (
                                <option value={res.value} name={res.display_name} key={id}>
                                    {res.display_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form>

                    {(isOperationChecker || isOperationMaker) &&
                        <div className="col-md-3">
                            <Button
                                disabled={isApplyBtnDisabled}
                                className="small_btn"
                                variant="contained"
                                onClick={() => applyContract()}
                            >
                                Apply contract date
                            </Button>
                        </div>
                    }
                    <div className="col-md-3">
                        <Button
                            className="small_btn"
                            type="click"
                            variant="contained"
                            onClick={() => handleCheques()}
                        >
                            {isOperationMaker ? "Add cheques" : "View cheques"}
                        </Button>
                    </div>
                </div>

                {/* row3 */}
                <div className="row row_div align-items-end">
                    {((!isCreditChecker) || (!isCreditMaker)) &&
                        <div className="ms-3 col-12 col-md-4">
                            <Form.Text>
                                {txtReasonSwitch()}
                            </Form.Text>
                            <Form.Control
                                type="textArea"
                                onChange={(e) => {
                                    handleClickReasonSwitch(e)
                                }}
                            />
                        </div>
                    }
                    <div className="col-md-3">
                        <Button
                            className="small_btn"
                            style={{ marginLeft: 0 }}
                            type="click"
                            variant="contained"
                            onClick={() => (
                                setReasons(creditFormData?.onetransactionloandata?.reasons),
                                setReasonModal(true)
                            )}
                        >
                            Show reasons
                        </Button>
                    </div>
                </div>


                {/* comments for finance or operation */}
                <div className="row row_div">
                    <Form.Group className="ms-3 mb-3 col-md-3" controlId="formBasic">
                        <Form.Label>Maker Comment:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={makerComment.comment}
                            onChange={(e) =>
                                setMakerComment({
                                    ...makerComment,
                                    comment: e.target.value,
                                })
                            }
                            disabled={(isOperationMaker || isFinancenMaker) ? false : true}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-3 " controlId="formBasic">
                        <Form.Label>Checker Comment:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={checkerComment.comment}
                            onChange={(e) =>
                                setCheckerComment({
                                    ...checkerComment,
                                    comment: e.target.value,
                                })
                            }
                            disabled={(isOperationChecker || isFinanceChecker) ? false : true}
                        />
                    </Form.Group>

                    <div className="mb-3 col-md-3 align-self-end">
                        <Button
                            variant="primary"
                            type="button"
                            className="small_btn"
                            // disabled={checkBtnDisabled()}
                            onClick={() => handlAddOrEditComment()}
                        >
                            Save Comment
                        </Button>
                    </div>
                </div>

                <div className="row">
                    {/* attachment */}
                    <div className="row row_side_btn">
                        {(isOperationMaker || isOperationChecker) && (
                            <div className="col-6">
                                <Form.Group
                                    controlId="formFile"
                                    className="col-6 upload_class"
                                    style={{ width: "100%" }}
                                >
                                    <Form.Control
                                        type="file"
                                        className="upload_input"
                                        disabled={!loanId ? true : false}
                                        onChange={(e) => {
                                            uploadDocuments(e.target.files[0]);
                                        }}
                                    />

                                    {(isOperationMaker || isOperationChecker) &&
                                        <div
                                            className="upload_btn"
                                            type="upload"
                                            style={{ width: "100%", fontSize: 12 }}
                                        >
                                            Attach Document
                                        </div>
                                    }
                                </Form.Group>
                            </div>
                        )}
                        <div className="col-6">
                            <Button
                                variant="primary"
                                type="button"
                                className="outlineBtn1"
                                onClick={() => setIsOpenDocumentModal(true)}
                            >
                                View document
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            {/* modal of AR (Account Reservable) View installments  */}
            <Modal
                isOpen={modalAR}
                onRequestClose={() => setModalAR(false)}
                style={customStyles}
                size="lg"
                centered
            >

                <div className="row_div">
                    <Button
                        variant="link"
                        className="link_btn_green "
                        style={{ color: "#41B6A8", fontSize: 20, marginBottom: 30 }}
                        onClick={handlePrint}
                        disabled={loading}

                    >
                        Print
                    </Button>
                </div>
                <div ref={componentRef}>
                    <Row className="d-flex flex-row">
                        <Col lg={3} md={4} sm={12}>
                            <Form.Text>Customer name</Form.Text>
                            <Form.Control
                                type="text"
                                readOnly={true}
                                defaultValue={
                                    creditFormData?.userprofile?.onetransactionprofile?.first_name +
                                    " " +
                                    creditFormData?.userprofile?.onetransactionprofile?.last_name
                                }
                            />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                            <Form.Text>National id</Form.Text>
                            <Form.Control
                                type="text"
                                readOnly={true}
                                defaultValue={
                                    creditFormData?.userprofile?.onetransactionprofile?.national_id
                                }
                            />
                        </Col>
                    </Row>
                    <TableContainer
                        component={Paper}
                        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
                    >
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>No.</StyledTableCell>
                                    <StyledTableCell>Due Date</StyledTableCell>
                                    <StyledTableCell>Payment Due</StyledTableCell>
                                    <StyledTableCell>Principal</StyledTableCell>
                                    <StyledTableCell>Intrest</StyledTableCell>
                                    <StyledTableCell>Balance</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {installmentsAR?.map((ele, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{index + 1}</StyledTableCell>
                                            <StyledTableCell>{ele.due_date}</StyledTableCell>
                                            <StyledTableCell>{ele.amount}</StyledTableCell>
                                            <StyledTableCell>{ele.principal}</StyledTableCell>
                                            <StyledTableCell>{ele.interest}</StyledTableCell>
                                            <StyledTableCell>{ele.balance}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Modal>

            {/* Reason Modal */}
            <Modal
                isOpen={reasonModal}
                onRequestClose={() => setReasonModal(false)}
                style={customStyles}
                size="lg"
                centered
            >
                {creditFormData?.onetransactionloandata?.reasons ?
                    <Form.Text>

                        <div
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{ __html: reasons }}
                        />
                    </Form.Text>
                    :
                    <div>
                        <h6 className="permission_style">
                            No Reasons Found
                        </h6>
                    </div>
                }
            </Modal>

            {/* modal of create/view cheques */}
            <Modal
                isOpen={isOpenCheque}
                onRequestClose={() => setIsOpenCheque(false)}
                style={customStyles}
                contentLabel="Cheques"
                shouldCloseOnOverlayClick={true}
            >
                <ChequesFormComponent loanId={loanId} />
            </Modal>



            {/* view documents */}
            <Modal
                isOpen={isOpenDocumentModal}
                onRequestClose={() => setIsOpenDocumentModal(!isOpenDocumentModal)}
                style={customStyles}
                contentLabel="user history"
                shouldCloseOnOverlayClick={true}
            >
                <LoanDocumentComponent loanId={loanId} department='operation' />
            </Modal>
        </main>
    )
}