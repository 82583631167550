import React, { useContext, useEffect, useState } from "react";
import "./GeneralInfo.scss";
import Button from "@material-ui/core/Button";
import {
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  TextField,
  InputLabel,
} from "@mui/material";
import { Formik } from "formik";
import { UserUtility } from "../../apis/UserUtility";
import { ENDPOINTS } from "../../apis/EndPoints";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Accordion } from "react-bootstrap";
import { SliderComponent } from "./SliderComponent";
import MerchantToolHistory from "../MerchantToolHistory/MerchantToolHistory";
import moment from "moment";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PaginateSelectedComponent } from "../CustomSelectedComponent/PaginateSelectedComponent";

const GeneralInfo = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const { newBrand, brandId, publishedBrand } = location.state;
  const [allSectors, setAllSectors] = useState([]);
  const { handleLoader, permissions } = useContext(UserContext);
  const [brandData, setBrandData] = useState("");
  const isReadOnly = permissions.includes("BD_merchant_creation_tool");
  const isMarketing = permissions.includes("Marketing_merchant_creation_tool")
    ? true
    : false;
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  // const [isTagsOpen, setIsTagsOpen] = useState(false)
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [selectedExtraSectors, setSelectedExtraSectors] = useState([]);
  const [allCustomSectors, setAllCustomSectors] = useState([]);
  const [uniqueIdent, setUniqueIdent] = useState();
  const [providers, setProviders] = useState([]);
  const getAllSectors = async () => {
    await UserUtility.getBrandSectors()
      .then((res) => {
        setAllSectors(res.results);
      })
      .catch((error) => console.error("getting sectors error", error));
  };

  const editAndPostBrand = (values) => {
    values["othersector"] =
      selectedExtraSectors && selectedExtraSectors.length > 0
        ? selectedExtraSectors.map((item) => item.id)
        : [];
    console.log("provideeeeer: ", values.provider);
    //this block of code is to upload pics and it is seperated from rest of data
    let token = localStorage.getItem("tools_token");
    const {
      background,
      thumbnail,
      background_web,
      thumbnail_web,
      ...newValues
    } = values;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    var formdata = new FormData();
    console.log("values emaillls: ", values.merchant_manager_emails);

    if (
      values.featured ||
      values.is_online ||
      values.is_retail ||
      values.newest ||
      values.best_seller
    ) {
      if (!newBrand) {
        //to check if the url is new or not
        if (background && typeof background === "object") {
          formdata.append("background", background);
        }
        if (thumbnail && typeof thumbnail === "object") {
          formdata.append("thumbnail", thumbnail);
        }

        if (background_web && typeof background_web === "object") {
          formdata.append("background_web", background_web);
        }
        if (thumbnail_web && typeof thumbnail_web === "object") {
          formdata.append("thumbnail_web", thumbnail_web);
        }

        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        console.log("newValues exist: ", newValues);

        handleLoader(true);
        UserUtility.editBrand(brandId, newValues)
          .then(() => {
            handleLoader(false);

            // call fetch url in case image upload
            //to prevent calling the EP if nothing changed
            if (
              (background && typeof background === "object") ||
              (thumbnail && typeof thumbnail === "object") ||
              (background_web && typeof background_web === "object") ||
              (thumbnail_web && typeof thumbnail_web === "object")
            ) {
              handleLoader(true);
              fetch(
                `${ENDPOINTS.url}management_dashboard/brands/edit/${brandId}/`,
                requestOptions
              )
                .then(async (res) => {
                  if (!res.ok) {
                    const text = await res.text();
                    console.log("error of images: ", new Error(text));
                    throw new Error(text);
                  } else {
                    handleLoader(false);
                    toast.success("Images has been changed successfully");
                    navigate("/brands");
                    return res.json();
                  }
                })
                .catch((err) => {
                  toast.error("Oops something went wrong of images");
                  handleLoader(false);
                });
            } else {
              navigate("/brands");
            }
          })
          .catch((error) => {
            console.error("edit brand error:: ", error);
            toast.error(error);
            handleLoader(false);
          });
      } else {
        const { tags, categories, vendor_liability_offset_days, ...newValues } =
          values;

        for (var key in newValues) {
          formdata.append(key, newValues[key]);
        }
        formdata.append("title", values.title_en);
        formdata.append("description", values.description_en);
        formdata.append(
          "vendor_liability_offset_days",
          parseInt(values.vendor_liability_offset_days)
        );
        console.log("values.othersector test: ", values.othersector);
        if (values.othersector && values.othersector.length == 0) {
          formdata.delete("othersector");
        }

        // formdata.append(
        //   "max_percentage",
        //   parseInt(values.max_percentage)
        // );
        //to check if the url is new or not
        if (typeof values.background !== "object") {
          formdata.delete("background");
        }
        if (typeof values.thumbnail !== "object") {
          formdata.delete("thumbnail");
        }
        if (typeof values.background_web !== "object") {
          formdata.delete("background_web");
        }
        if (typeof values.thumbnail_web !== "object") {
          formdata.delete("thumbnail_web");
        }

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        handleLoader(true);

        fetch(
          `${ENDPOINTS.url}management_dashboard/brands/create/`,
          requestOptions
        )
          .then(async (res) => {
            if (!res.ok) {
              const text = await res.text();
              throw new Error(text);
            } else {
              handleLoader(false);
              toast.success("Data has been changed successfully");
              navigate("/brands");
              return res.json();
            }
          })
          .catch((err) => {
            handleLoader(false);
          });
      }
    } else {
      toast.warning(
        "Please select at least one of [Featured,Newest,Is online,Is retail,Best seller]"
      );
    }
  };

  const getSpecialBrand = async () => {
    await UserUtility.getSpecialBrand(brandId)
      .then((res) => {
        setBrandData(res);

        let customArr = res.othersector.map((item, index) => {
          return { ...item, name: item.title };
        });
        setSelectedExtraSectors(customArr);
      })
      .catch((error) => console.error("getting special brand error", error));
  };

  const validateYup = Yup.object({
    slug: Yup.string()
      .matches(
        /^[a-z0-9]+(?:[._-][a-z0-9]+)*$/g,
        "No capital or special character or space"
      )
      .required("Required*"),
    title_en: Yup.string().required("Required*"),
    title_ar: Yup.string().required("Required*"),
    description_en: Yup.string().required("Required*"),
    description_ar: Yup.string().required("Required*"),
    paymob_identifier: Yup.string(),
    drive_identifier: Yup.string(),
    vendor_liability_offset_days: Yup.number(),
    sector: Yup.string().required("Required*"),
    order: Yup.number().required("Required*"),
    merchant_manager_emails: Yup.string().required("Required*"),
    commercial_register_num: Yup.string(),
    provider: Yup.string().required("Required*"),
    // max_percentage:Yup.string().notRequired()
  });

  // extra sectors get options with page and search
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    console.log(`loading page:${page} - limit: 10`);

    let response = await UserUtility.getBrandSectors();
    console.log("responsesssss: ", response);
    let customArr = response.results.map((item, index) => {
      return { ...item, name: item.title };
    });
    setAllCustomSectors(customArr);
    return {
      options: customArr,
      // hasMore: customArr.length >= 1,
      // additional: {
      //   page: page + 1,
      // },
    };
  };

  // generate unique code for paymob identifier
  const generateCode = (setFieldValue) => {
    UserUtility.generateUnqMerchantIdentifier()
      .then((res) => {
        console.log("res of generateUnqMerchantIdentifier: ", res);
        setUniqueIdent(res.MID);
        setFieldValue("paymob_identifier", res.MID);
      })
      .catch((err) => {
        console.log("err of generateUnqMerchantIdentifier: ", err);
        setUniqueIdent();
      });
  };

  const getMerchantProviders = () => {
    UserUtility.getMerchantProviders()
      .then((res) => {
        setProviders(res.results);
      })
      .catch((err) => {
        console.log("err getMerchantProviders: ", err);
      });
  };
  useEffect(() => {
    !newBrand && getSpecialBrand();
    getAllSectors();
    getMerchantProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="generalInfo_container">
      <Formik
        initialValues={{
          slug: brandData?.slug || "",
          title_en: brandData?.title_en || "",
          title_ar: brandData?.title_ar || "",
          description_en: brandData?.description_en || "",
          description_ar: brandData?.description_ar || "",
          paymob_identifier: brandData?.paymob_identifier || "",
          drive_identifier: brandData?.drive_identifier || "",
          vendor_liability_offset_days:
            brandData?.vendor_liability_offset_days || 0,
          is_retail: brandData?.is_retail || false,
          is_online: brandData?.is_online || false,
          approved_by_marketing: brandData?.approved_by_marketing || false,
          sector: parseInt(brandData?.sector?.id) || "",
          categories: brandData?.categories || [],
          tags: brandData?.tags || [],
          order: brandData?.order || 1,
          featured: brandData?.featured || false,
          newest: brandData?.newest || false,
          best_seller: brandData?.best_seller || false,
          thumbnail: brandData?.thumbnail,
          background: brandData?.background,
          thumbnail_web: brandData?.thumbnail_web,
          background_web: brandData?.background_web,
          merchant_manager_emails: brandData?.merchant_manager_emails || "",
          modified: brandData?.modified || null,
          commercial_register_num: brandData?.commercial_register_num || "",
          phone_numbers: brandData?.phone_numbers || "",
          provider: brandData?.provider?.id || "",
          // max_percentage:brandData?.max_percentage || 0,
        }}
        validationSchema={validateYup}
        onSubmit={(values) => editAndPostBrand(values)}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <section>
              <h3>Slug (en):</h3>
              <TextField
                placeholder="forsa"
                name="slug"
                variant="filled"
                value={values.slug}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isReadOnly && !isMarketing}
              />
            </section>
            {errors.slug && touched.slug ? (
              <div className="error_message">{errors.slug}</div>
            ) : null}

            <section>
              <h3>Title (en):</h3>
              <TextField
                placeholder="Forsa"
                name="title_en"
                variant="filled"
                value={values.title_en}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isReadOnly && !isMarketing}
              />
            </section>
            {errors.title_en && touched.title_en ? (
              <div className="error_message">{errors.title_en}</div>
            ) : null}
            <section>
              <h3>Title (ar):</h3>
              <TextField
                name="title_ar"
                placeholder="فرصة"
                variant="filled"
                value={values.title_ar}
                onChange={handleChange}
                style={{ direction: "rtl" }}
                onBlur={handleBlur}
                disabled={!isReadOnly && !isMarketing}
              />
            </section>
            {errors.title_ar && touched.title_ar && (
              <div className="error_message">{errors.title_ar}</div>
            )}
            <section>
              <h3>Description (en):</h3>
              <TextField
                multiline
                rows={4}
                variant="filled"
                value={values.description_en}
                name="description_en"
                placeholder="Enter the description here ..."
                onChange={handleChange}
                className="description"
                onBlur={handleBlur}
                disabled={!isReadOnly && !isMarketing}
              />
            </section>
            {errors.description_en && touched.description_en && (
              <div className="error_message">{errors.description_en}</div>
            )}

            <section>
              <h3>Description (ar):</h3>
              <TextField
                multiline
                rows={4}
                variant="filled"
                value={values.description_ar}
                name="description_ar"
                placeholder="ادخل الوصف هنا ...."
                onChange={handleChange}
                style={{ direction: "rtl" }}
                className="description"
                onBlur={handleBlur}
                disabled={!isReadOnly && !isMarketing}
              />
            </section>
            {errors.description_ar && touched.description_ar && (
              <div className="error_message">{errors.description_ar}</div>
            )}

            {/* merchant manager emails */}

            <section>
              <h3>Merchant Manager Email:</h3>
              <TextField
                // type={"email"}
                type="text"
                placeholder="merchant manager email"
                name="merchant_manager_emails"
                variant="filled"
                value={values.merchant_manager_emails}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />
            </section>
            {errors.merchant_manager_emails &&
              touched.merchant_manager_emails && (
                <div className="error_message">
                  {errors.merchant_manager_emails}
                </div>
              )}

            {console.log("values.modified: ", values.modified)}
            <section>
              <h3>Modified date:</h3>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={
                    values.modified ? new Date(values.modified) || null : null
                  }
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                />
              </LocalizationProvider>
            </section>
            <div className="images">
              {/* mobile brand image */}
              <section>
                <h3>Thumbnail</h3>
                <div>
                  <label className="image" htmlFor="contained-button-file">
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                      name="thumbnail"
                      onChange={(e) =>
                        setFieldValue("thumbnail", e.target.files[0])
                      }
                    />
                    <div>
                      <TextField
                        placeholder="image url"
                        variant="filled"
                        onChange={handleChange}
                        value={values.thumbnail?.name || values.thumbnail}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {(isReadOnly || isMarketing) && (
                        <Button variant="contained" component="span">
                          Upload
                        </Button>
                      )}

                      <p className="image_hint">
                        *Hint: Dimension is: 155 * 152
                      </p>
                    </div>
                  </label>
                </div>
                {values.thumbnail &&
                  values.thumbnail.size &&
                  values.thumbnail.size >= 400000 && (
                    <div className="error_message">
                      File size exceeds the maximum limit (400kb).
                    </div>
                  )}
              </section>

              <section>
                <h3>Background</h3>
                <div>
                  <label className="image" htmlFor="background-file">
                    <input
                      accept="image/*"
                      id="background-file"
                      type="file"
                      style={{ display: "none" }}
                      name="background"
                      onChange={(e) =>
                        setFieldValue("background", e.target.files[0])
                      }
                    />
                    <div>
                      <TextField
                        id="outlined-basic"
                        placeholder="image url"
                        variant="filled"
                        value={values.background?.name || values.background}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {(isReadOnly || isMarketing) && (
                        <Button variant="contained" component="span">
                          Upload
                        </Button>
                      )}
                      <p className="image_hint">
                        *Hint: Dimension is: 325 * 154
                      </p>
                    </div>
                  </label>
                </div>
                {values.background &&
                  values.background.size &&
                  values.background.size > 400000 && (
                    <div className="error_message">
                      File size exceeds the maximum limit (400kb).
                    </div>
                  )}
              </section>

              {/* web brand image */}

              <section>
                <h3>Thumbnail Web</h3>
                <div>
                  <label className="image" htmlFor="contained-button-file-web">
                    <input
                      accept="image/*"
                      id="contained-button-file-web"
                      type="file"
                      style={{ display: "none" }}
                      name="thumbnail_web"
                      onChange={(e) =>
                        setFieldValue("thumbnail_web", e.target.files[0])
                      }
                    />

                    <div>
                      <TextField
                        placeholder="image url"
                        variant="filled"
                        onChange={handleChange}
                        value={
                          values.thumbnail_web?.name || values.thumbnail_web
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {(isReadOnly || isMarketing) && (
                        <Button variant="contained" component="span">
                          Upload
                        </Button>
                      )}
                      <p className="image_hint">
                        *Hint: Dimension is: 400 * 400
                      </p>
                    </div>
                  </label>
                </div>
                {values.thumbnail_web &&
                  values.thumbnail_web.size &&
                  values.thumbnail_web.size >= 400000 && (
                    <div className="error_message">
                      File size exceeds the maximum limit (400kb).
                    </div>
                  )}
              </section>
              <section>
                <h3>Background Web</h3>
                <div>
                  <label className="image" htmlFor="background-file-web">
                    <input
                      accept="image/*"
                      id="background-file-web"
                      type="file"
                      style={{ display: "none" }}
                      name="background_web"
                      onChange={(e) =>
                        setFieldValue("background_web", e.target.files[0])
                      }
                    />
                    <div>
                      <TextField
                        id="outlined-basic"
                        placeholder="image url"
                        variant="filled"
                        value={
                          values.background_web?.name || values.background_web
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />

                      {(isReadOnly || isMarketing) && (
                        <Button variant="contained" component="span">
                          Upload
                        </Button>
                      )}
                      <p className="image_hint">
                        *Hint: Dimension is: 720 * 273
                      </p>
                    </div>
                  </label>
                </div>
                {values.background_web &&
                  values.background_web.size &&
                  values.background_web.size > 400000 && (
                    <div className="error_message">
                      File size exceeds the maximum limit (400kb).
                    </div>
                  )}
              </section>
            </div>
            <section
              className="d-flex justify-center align-items-end"
              style={{ marginTop: 19 }}
            >
              <section className="identifier">
                <h3>Paymob Identifier</h3>
                <TextField
                  name="paymob_identifier"
                  variant="filled"
                  onChange={handleChange}
                  value={values.paymob_identifier}
                  onBlur={handleBlur}
                  disabled={!isReadOnly}
                />
              </section>
              <Button
                variant="contained"
                onClick={() => generateCode(setFieldValue)}
              >
                Generate Code
              </Button>
            </section>
            {errors.paymob_identifier && touched.paymob_identifier && (
              <div className="error_message">{errors.paymob_identifier}</div>
            )}
            <section className="identifier">
              <h3>Drive Identifier</h3>
              <TextField
                name="drive_identifier"
                variant="filled"
                onChange={handleChange}
                value={values.drive_identifier}
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />
            </section>
            {errors.drive_identifier && touched.drive_identifier && (
              <div className="error_message">{errors.drive_identifier}</div>
            )}

            <section className="identifier">
              <h3>Vendor Liability offset days</h3>
              <TextField
                name="vendor_liability_offset_days"
                variant="filled"
                onChange={handleChange}
                value={values.vendor_liability_offset_days}
                type="number"
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />
            </section>
            {errors.vendor_liability_offset_days &&
              touched.vendor_liability_offset_days && (
                <div className="error_message">
                  {errors.vendor_liability_offset_days}
                </div>
              )}

            <section className="identifier">
              <h3>Commercial Register Number</h3>
              <TextField
                name="commercial_register_num"
                variant="filled"
                onChange={handleChange}
                value={values.commercial_register_num}
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />
            </section>
            {errors.commercial_register_num &&
              touched.commercial_register_num && (
                <div className="error_message">
                  {errors.commercial_register_num}
                </div>
              )}

            <section>
              <h3>Provider</h3>
              <FormControl className="select-field">
                <Select
                  size="small"
                  value={values.provider}
                  onChange={handleChange}
                  name="provider"
                  onBlur={handleBlur}
                  disabled={!isReadOnly}
                  placeholder="Select Provider"
                >
                  {providers.map((item, ind) => (
                    <MenuItem value={item.id} key={ind}>
                      {item.provider_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.provider && touched.provider && (
                <div className="error_message">{errors.provider}</div>
              )}
            </section>

            <section>
              <h3>Sector</h3>
              <FormControl className="select-field">
                <Select
                  size="small"
                  value={values.sector}
                  onChange={handleChange}
                  name="sector"
                  onBlur={handleBlur}
                  disabled={!isReadOnly}
                >
                  {allSectors?.map((sectorData, id) => (
                    <MenuItem value={sectorData.id} key={id}>
                      {sectorData.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.sector && touched.sector && (
                <div className="error_message">{errors.sector}</div>
              )}
            </section>

            <section>
              <h3 style={{ marginBottom: -20 }}>Other Sectors</h3>
              <PaginateSelectedComponent
                value={selectedExtraSectors}
                loadOptions={loadOptions}
                onChange={(value) => {
                  console.log("value of multi: ", value);
                  setSelectedExtraSectors(value);
                }}
                disabled={!isReadOnly}
                placeholder="Select Sectors"
                customStyle={{ width: "30%" }}
                isMulti
              />
            </section>
            <section>
              <h3>Order</h3>
              <TextField
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                name="order"
                placeholder="order"
                variant="filled"
                value={values.order}
                onChange={handleChange}
                style={{ direction: "rtl" }}
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />

              {errors.order && touched.order && (
                <div className="error_message">{errors.order}</div>
              )}
            </section>

            {/* brand phone numbers */}

            <section>
              <h3>Brand Phone Numbers</h3>
              <TextField
                type="text"
                name="phone_numbers"
                placeholder="Ex:1222,3115"
                variant="filled"
                value={values.phone_numbers}
                onChange={handleChange}
                style={{ direction: "rtl" }}
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />
            </section>

            {/* checkbox */}
            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                size="medium"
                name="is_retail"
                onChange={handleChange}
                checked={values.is_retail}
                disabled={!isReadOnly}
              />
              <h3 style={{ marginLeft: 6 }}>Retail</h3>
            </section>

            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                size="medium"
                name="is_online"
                onChange={handleChange}
                checked={values.is_online}
                disabled={!isReadOnly}
              />
              <h3 style={{ marginLeft: 6 }}>Is online</h3>
            </section>

            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                size="medium"
                name="newest"
                onChange={handleChange}
                checked={values.newest}
                disabled={!isReadOnly}
              />
              <h3 style={{ marginLeft: 6 }}>Newest</h3>
            </section>

            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                size="medium"
                name="best_seller"
                onChange={handleChange}
                checked={values.best_seller}
                disabled={!isReadOnly}
              />
              <h3 style={{ marginLeft: 6 }}>Best seller</h3>
            </section>

            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                size="medium"
                name="featured"
                onChange={handleChange}
                checked={values.featured}
                disabled={!isReadOnly}
              />
              <h3 style={{ marginLeft: 6 }}>Featured</h3>
            </section>

            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                size="small"
                name="approved_by_marketing"
                onChange={handleChange}
                checked={values.approved_by_marketing}
                disabled={
                  permissions.includes("Marketing_merchant_creation_tool")
                    ? false
                    : true
                }
              />
              <h3 style={{ marginLeft: 6 }}>Approved by marketing</h3>
            </section>

            {/* slider and tags */}
            <Accordion alwaysOpen defaultActiveKey="-1" className="accordClass">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  onClick={() => {
                    setIsSliderOpen(true);
                  }}
                >
                  Slider
                </Accordion.Header>

                <Accordion.Body
                  onExiting={() => {
                    console.log("onExited");
                    setIsSliderOpen(false);
                  }}
                >
                  <SliderComponent
                    isBrandPublished={publishedBrand}
                    isDisabled={!isReadOnly}
                    brandId={brandData?.id}
                    sliderState={isSliderOpen}
                  />
                </Accordion.Body>
              </Accordion.Item>

              {/* history */}
              {brandData?.id && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    onClick={() => {
                      setIsHistoryOpen(true);
                    }}
                  >
                    History
                  </Accordion.Header>
                  <Accordion.Body
                    onExiting={() => {
                      setIsHistoryOpen(false);
                    }}
                  >
                    <MerchantToolHistory
                      type="brand"
                      id={brandData?.id}
                      isState={isHistoryOpen}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {/* <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => {
                  setIsTagsOpen(true)
                }} >
                  Tags
                </Accordion.Header>
                <Accordion.Body onExiting={() => {
                  console.log('onExited')
                  setIsTagsOpen(false)
                }}>
                  <TagComponent isDisabled={!isReadOnly} brandId={brandData?.id}
                    tagState={isTagsOpen} tagIds={values.tags}
                    selectedTagIds={(items) => {
                      let arr = items && items.length > 0 ?
                        items.map((element, _) => { return element.id })
                        :
                        []
                      console.log('arr with tag ids: ', arr)
                      values.tags = arr
                    }} />
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>

            {(!permissions.includes("Finance_merchant_creation_tool") &&
              permissions.includes("BD_merchant_creation_tool") &&
              !publishedBrand) ||
            (!publishedBrand &&
              permissions.includes("Marketing_merchant_creation_tool")) ? (
              <div className="submitBtn">
                <Button variant="contained" type="submit">
                  Save section
                </Button>
              </div>
            ) : (
              <></>
            )}
          </form>
        )}
      </Formik>
    </article>
  );
};

export default GeneralInfo;
