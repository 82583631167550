import React, { useState, useContext } from "react";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Container } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { UserUtility } from "../../apis/UserUtility";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ENDPOINTS } from "../../apis/EndPoints";
import axios from "axios";

const FinanceReport = () => {
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [data, setData] = useState();
  let today = new Date().toISOString().slice(0, 10);
  const finance_report = () => {
    handleLoader(true);
    UserUtility.finance_report(data?.from, data?.to)
      .then((res) => {
        handleLoader(false);
        toast.success(res.detail);
      })
      .catch((err) => console.error("finance_report", err));
    handleLoader(false);
  };
  const advanced_report = async () => {
    handleLoader(true);
    const token = localStorage.getItem("tools_token");
    const headers = {
      "Content-Type": "blob",
      Authorization: `token ${token}`,
    };
    const config = {
      method: "GET",
      url: `${ENDPOINTS.url}finance/cash_collection/advanced_payments/export/?created_at__date__gte=${data?.from}&created_at__date__lte=${data?.to}`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `advanced-payment-${today}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      throw Error(error);
    }
  };
  return (
    <Container className="mt-5" style={{ textAlign: "center" }}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="From"
          inputFormat="yyyy/MM/dd"
          value={data?.from ? new Date(data?.from) : null}
          onChange={(e) =>
            setData({
              ...data,
              from: moment(e).format("YYYY-MM-DD"),
            })
          }
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="To"
          inputFormat="yyyy/MM/dd"
          value={data?.to ? new Date(data?.to) : null}
          onChange={(e) =>
            setData({
              ...data,
              to: moment(e).format("YYYY-MM-DD"),
            })
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <section className="d-flex justify-content-evenly  mt-5 ">
        {permissions?.includes(
          "can_export_advanced_payments_custom_report"
        ) && (
          <Button
            variant="success"
            onClick={() => advanced_report()}
            // className="w-25 mt-5 me-1"
          >
            Get Advanced Report
          </Button>
        )}

        {permissions?.includes("Can_export_payments_data") && (
          <Button
            variant="success"
            onClick={() => finance_report()}
            // className="w-25 mt-5 "
          >
            Get Finance Report
          </Button>
        )}
      </section>
    </Container>
  );
};
export default FinanceReport;
