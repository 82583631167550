import React, { useState, useContext, useMemo, useEffect } from "react";
import "../generalInfo/GeneralInfo.scss"; //90% of style are the same as general info
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    Checkbox,
    TextField,
    MenuItem,
    Select,
    FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as plans from "../categoryDetails/Plans.json";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import { toast } from "react-toastify";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment";
import * as Yup from "yup";
import OfferTable from "./offerTable/offerTable";
import { Table2Data, textFields, checkboxes, globalOfferTableData } from "./Data";
import ImageCropper from "../imageCropper/imageCropper";
import { Colors } from "../../constants/Colors";
import GlobalOfferTable from "./offerTable/GlobalOfferTable";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const GlobalOfferDetails = () => {
    let navigate = useNavigate();

    const [installmentPlans, setInstallmentPlans] = useState(plans.rows);
    const [expirationDate, setExpirationDate] = useState("");
    const { handleLoader, permissions } = useContext(UserContext);

    // new params
    const [imageToCropThumbnail, setImageToCropThumbnail] = useState("");
    const [imageToCropBackground, setImageToCropBackground] = useState("");
    const [thumbnailCropped, setThumbnailCropped] = useState();
    const [backgroundCropped, setBackgroundCropped] = useState();
    // web
    const [imageToCropThumbnailWeb, setImageToCropThumbnailWeb] = useState("");
    const [imageToCropBackgroundWeb, setImageToCropBackgroundWeb] = useState("");
    const [thumbnailCroppedWeb, setThumbnailCroppedWeb] = useState();
    const [backgroundCroppedWeb, setBackgroundCroppedWeb] = useState();
    const [sectors, setSectors] = useState([]);
    const [brands, setBrands] = useState([]);

    const [selectedBrands, setSelectedBrands] = useState([]);

    // to splice total object from array of Table2Data
    let customInstallDataTable = [...Table2Data];
    let x = customInstallDataTable.findIndex(item => {
        return item.defaultValue1 === 'total'});
    customInstallDataTable.splice(x, 1)


    // get all sectors
    const getAllSectors = async () => {
        await UserUtility.getBrandSectors()
            .then((res) => {
                setSectors(res.results);
            })
            .catch((error) => console.error("getting sectors error", error));
    };

    // get all brands of sector
    const getBrandsSector = async (id) => {
        await UserUtility.getBrandsBySector(id)
            .then((res) => {
                setBrands(res.brands);
                setSelectedBrands(res.brands)
            })
            .catch((error) => console.error("getting sectors error", error));
    };

    // change sector select
    const changeSectorFun = (event) => {
        getBrandsSector(event.target.value)
    }

    // change brand select
    const changeBrandFun = (event) => {
        let arr = []
        arr.push(event.target.value)
        setSelectedBrands(arr)
    }

    //to handle remove brand from list
    const handleRemoveBrand = (id) => {
        let index = selectedBrands.findIndex(item => item.id == id)
        if (index > -1) {
            selectedBrands.splice(index, 1)
        }
        setSelectedBrands(selectedBrands);
    };

    //to handle installment plans values
    const handlePlanNumbers = (index, planKey, e) => {
        const newInstallmentPlans = installmentPlans.map((plan, id) => {
            return {
                ...plan,
                [planKey]:
                    index === id
                        ? planKey === "grace_period"
                            ? e.target.value
                            : parseFloat(e.target.value)
                        : planKey === "grace_period"
                            ? plan[planKey]
                            : parseFloat(plan[planKey]),
            };
        });
        setInstallmentPlans(newInstallmentPlans);
    };
    //to handle object of object
    const handlePlanNumbers2 = (index, planKey, keyOfPlanKey, e) => {
        const newInstallments = installmentPlans.map((eachPlan, id) => {
            return {
                ...eachPlan,
                [planKey]: {
                    ...eachPlan[planKey],
                    [keyOfPlanKey]:
                        index === id
                            ? parseFloat(e.target.value)
                            : parseFloat(eachPlan[planKey][keyOfPlanKey]),
                },
            };
        });
        setInstallmentPlans(newInstallments);
    };

    const editAndPostOffer = (values) => {
        //to remove background, thumbnail and category from the object
        const { category: valuesCategory, ...newValues } = values;
        var myHeaders = new Headers();
        var formdata = new FormData();
        var token = localStorage.getItem("tools_token");
        myHeaders.append("Authorization", `token ${token}`);
        // myHeaders.append("Content-Type","multipart/form-data")
        // myHeaders.append("Accept","application/json")

        // for two cases new offer or create one
        if (backgroundCropped && typeof backgroundCropped === "object") {
            formdata.append("background", backgroundCropped);
        }
        if (thumbnailCropped && typeof thumbnailCropped === "object") {
            formdata.append("thumbnail", thumbnailCropped);
        }

        if (backgroundCroppedWeb && typeof backgroundCroppedWeb === "object") {
            formdata.append("background_web", backgroundCroppedWeb);
        }
        if (thumbnailCroppedWeb && typeof thumbnailCroppedWeb === "object") {
            formdata.append("thumbnail_web", thumbnailCroppedWeb);
        }
        delete newValues.thumbnail;
        delete newValues.background;
        delete newValues.thumbnail_web;
        delete newValues.background_web;


        //if the offer is new
        const convertCategory = {
            title: valuesCategory,
            title_en: valuesCategory,
            title_ar: valuesCategory,
            published: false,
            is_online: false,
        };
        const convertInstallments = installmentPlans.map((eachPlan) => ({
            ...eachPlan,
            admin_fees: parseFloat(eachPlan.admin_fees.value),
            forsa_processing_fees: parseFloat(
                eachPlan.admin_fees.forsa_processing_fees
            ),
            paymob_processing_fees: parseFloat(
                eachPlan.admin_fees.paymob_processing_fees
            ),
            kick_back: parseFloat(eachPlan.admin_fees.kick_back),
            grace_period: `${eachPlan.grace_period.toString()} 00:00:00`,
        }));


        const newOfferData = {
            ...newValues,
            category: convertCategory,
            description: newValues.description_en,
            title: newValues.title_en,
            expiry_date: expirationDate,
            installment_plans: convertInstallments,
            price: newValues.price ? newValues.price : 0
        };

        for (var key in newOfferData) {
            if (key === "category" || key === "installment_plans") {
                formdata.append(key, JSON.stringify(newOfferData[key]));
            } else {
                formdata.append(key, newOfferData[key]);
            }
            // if (key === "category") {
            //     let category = newOfferData[key];
            //     for (var key2 in category[key2]) {
            //         formdata.append(key2, category[key2]);
            //     }
            // } else {
            //     formdata.append(key, newOfferData[key]);
            // }
        }

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        let brandIds = selectedBrands.map((item) => { return (item.id) })
        let brandIdsString = brandIds.toString()

        handleLoader(true);

        fetch(
            `https://forsa-staging.bit68.com/api/v1/management_dashboard/global_offer/?ids=${brandIdsString}`,
            requestOptions
        )
            .then(async (res) => {
                if (!res.ok) {
                    handleLoader(false);
                    const text = await res.text();
                    throw new Error(text);
                } else {
                    handleLoader(false);
                    navigate(-1);
                    return res.json();
                }
            })
            .catch((err) => {

                handleLoader(false);
                toast.error(err[0] || "something went wrong");
            });

        // UserUtility.createGlobalOffer(newOfferData, brandIdsString)
        //     .then((res) => {



        //         if (
        //             (backgroundCropped && typeof backgroundCropped === "object") ||
        //             (thumbnailCropped && typeof thumbnailCropped === "object") ||
        //             (backgroundCroppedWeb && typeof backgroundCroppedWeb === "object") ||
        //             (thumbnailCroppedWeb && typeof thumbnailCroppedWeb === "object")

        //         ) {

        //         }

        //         // navigate(-1);
        //         // handleLoader(false);
        //     })
        //     .catch((error) => {
        //         console.error("error posting", error);
        //         handleLoader(false);
        //         toast.error(error[0] || "something went wrong");
        //     });

    };

    const onUploadFile = (event, setState) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setState(reader.result));
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    /*
        const uploadToBE = async (values) => {
            if (
                typeof thumbnailCropped === "object" ||
                typeof backgroundCropped === "object" ||
                typeof thumbnailCroppedWeb === "object" ||
                typeof backgroundCroppedWeb === "object"
            ) {
                var objValues = new Map();
                //to check if the url is new or not
                if (thumbnailCropped && typeof thumbnailCropped === "object") {
                    objValues.set("image1", thumbnailCropped);
                }
                if (backgroundCropped && typeof backgroundCropped === "object") {
                    objValues.set("image2", backgroundCropped);
                }
    
                if (thumbnailCroppedWeb && typeof thumbnailCroppedWeb === "object") {
                    objValues.set("image3", thumbnailCroppedWeb);
                }
                if (backgroundCroppedWeb && typeof backgroundCroppedWeb === "object") {
                    objValues.set("image4", backgroundCroppedWeb);
                }
    
                CommonUtility.uploadFiles(objValues)
                    .then(async (res) => {
                        handleLoader(false);
                        toast.success("Data has been changed successfully");
                        if (res.image1) {
                            values["thumbnail"] = res.image1;
                        }
    
                        if (res.image2) {
                            values["background"] = res.image2;
                        }
    
                        if (res.image3) {
                            values["thumbnail_web"] = res.image3;
                        }
    
                        if (res.image4) {
                            values["background_web"] = res.image4;
                        }
    
                        editAndPostOffer(values);
                    })
                    .catch((err) => {
                        console.log("error", err);
                        handleLoader(false);
                    });
            } else {
                editAndPostOffer(values);
            }
        };
    */
    // to handle publish button to publish or unpublish
    const publishChange = (planId, publishState) => {
        const newPlans = installmentPlans.map((eachPlan, id) => {
            return {
                ...eachPlan,
                published: planId === eachPlan.id ? publishState : eachPlan.published,
            };
        });
        handleLoader(true);
        UserUtility.publishInstallmentPlans(planId, publishState)
            .then(() => {
                handleLoader(false);
                setInstallmentPlans(newPlans);
            })
            .catch((error) => {
                console.error("publish installment plan error", error);
                handleLoader(false);
            });
    };

    const publishSwitch = (isPublish, item) => {
        let id = item.id;
        let grace_period =
            item?.grace_period === 1
                ? item?.grace_period
                : parseInt(item?.grace_period.split(" ", 1));
        switch (true) {
            case isPublish && permissions.includes("Finance_merchant_creation_tool"):
                return "Published";
                break;

            case isPublish && permissions.includes("BD_merchant_creation_tool"):
                return (
                    <Button
                        style={{
                            minWidth: 100,
                            backgroundColor: Colors.red,
                        }}
                        // type="submit"
                        variant="contained"
                        onClick={() => publishChange(id, false)}
                    >
                        Unpublish
                    </Button>
                );
                break;

            case !isPublish && permissions.includes("Finance_merchant_creation_tool"):
                return (
                    <Button
                        style={{
                            minWidth: 100,
                            backgroundColor: Colors.primary,
                        }}
                        // type="submit"
                        disabled={grace_period === 1}
                        variant="contained"
                        onClick={() => publishChange(id, true)}
                    >
                        Publish
                    </Button>
                );
                break;

            case !isPublish && permissions.includes("BD_merchant_creation_tool"):
                return "Not Published";
                break;

            default:
                break;
        }
    };

    const validateYup = Yup.object({
        // slug: Yup.string().matches(/^[a-z0-9]+(?:[._-][a-z0-9]+)*$/g,"No capital or special character or space").required("Required*"),
        title_en: Yup.string().required("Required*"),
        title_ar: Yup.string().required("Required*"),
        description_en: Yup.string().required("Required*"),
        description_ar: Yup.string().required("Required*"),
        product_label: Yup.string().required("Required*"),
        price: Yup.string()
            .matches(/^[0-9]+$/, "invalid number "),
        category: Yup.string().required("Required*"),
        order: Yup.number().required("Required*"),
    });


    useEffect(() => {
        getAllSectors()
    }, [])
    return (
        <article className="generalInfo_container">
            <h2 className="brand_title">
                {`Brands / `}
                <span>New global offer</span>
            </h2>
            <Formik
                initialValues={{
                    // slug: "",
                    title_en: "",
                    title_ar: "",
                    description_en: "",
                    description_ar: "",
                    is_retail: false,
                    is_online: false,
                    featured: false,
                    newest: false,
                    best_seller: false,
                    otc: false,
                    product_label: "",
                    price: 0,
                    category: "",
                    // category: parseInt(category?.id) || "",
                    order: 1,
                    brand: null,
                    sector: null,
                    thumbnail: "",
                    background: "",
                    thumbnail_web: "",
                    background_web: "",
                }}
                onSubmit={(values) => {
                    // uploadMedia(values);
                    // editAndPostImgs(37, values);
                    editAndPostOffer(values);
                }}
                validationSchema={validateYup}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <section style={{ width: '20%' }}>
                                <h3>Sector</h3>
                                <FormControl className="select-field" style={{ width: '100%' }}>
                                    <Select
                                        size="small"
                                        // value={values.sector}
                                        onChange={(event) => changeSectorFun(event)}
                                        name="Sector"
                                        onBlur={handleBlur}
                                    >
                                        {sectors?.map((sector, id) => (
                                            <MenuItem value={sector.id} key={id}>
                                                {sector.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </section>

                            <section style={{ marginLeft: 20, width: '20%' }}>
                                <h3>Brand</h3>
                                <FormControl className="select-field" style={{ width: '100%' }}>
                                    <Select
                                        size="small"
                                        // value={values.brand}
                                        onChange={(event) => changeBrandFun(event)}
                                        name="Brand"
                                        onBlur={handleBlur}
                                    >
                                        {brands?.map((brand, id) => (
                                            <MenuItem value={brand} key={id}>
                                                {brand.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </section>
                        </div>

                        <div>
                            <GlobalOfferTable
                                list={selectedBrands}
                                onBtnRemove={handleRemoveBrand}
                                tableData={globalOfferTableData}
                            />
                        </div>

                        {textFields.map((field, index) => (
                            <>
                                {field.name !== 'slug' ?
                                    <div key={index}>
                                        <section>
                                            <h3>{field.title}</h3>
                                            <TextField
                                                placeholder={field.placeholder}
                                                variant="filled"
                                                onBlur={handleBlur}
                                                name={field.name}
                                                onChange={handleChange}
                                                style={field.styles ? field.styles : null}
                                                multiline={field.multiline ? true : false}
                                                rows={field.rows ? field.rows : null}
                                                className={field.className ? field.className : null}
                                                value={values[field.name]}
                                            />
                                        </section>
                                        {field.error && errors[field.name] && touched[field.name] && (
                                            <div className="error_message">{errors[field.name]}</div>
                                        )}
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        ))}

                        <section>
                            <h3>Expiry date:</h3>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disablePast
                                    value={expirationDate ? new Date(expirationDate) : null}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        setExpirationDate(moment(newValue).format("YYYY-MM-DD"));
                                    }}
                                    renderInput={(params) => <TextField onKeyDown={(e)=>e.preventDefault()} {...params} />}
                                />
                            </LocalizationProvider>
                        </section>
                        {/* Thumbnail section */}
                        <section>
                            <h3>Thumbnail</h3>
                            <ImageCropper
                                imageToCrop={imageToCropThumbnail}
                                onImageCropped={(croppedImage) =>
                                    setThumbnailCropped(croppedImage)
                                }
                                maxCrop={{ x: 0, y: 0, width: 33, height: 32, unit: "px" }}
                                name="thumbnail.jpeg"
                            />
                            <div>
                                <label htmlFor="contained-button-file">
                                    <input
                                        accept="image/*"
                                        id="contained-button-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        name="thumbnail"
                                        onChange={(e) => onUploadFile(e, setImageToCropThumbnail)}
                                    />

                                    <TextField
                                        placeholder="image url"
                                        variant="filled"
                                        onBlur={handleBlur}
                                        value={thumbnailCropped?.name || thumbnailCropped || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <Button variant="contained" component="span">
                                        Upload
                                    </Button>
                                    <p className="image_hint">*Hint: Dimension is: 155 * 116</p>
                                </label>
                            </div>
                            {thumbnailCropped &&
                                thumbnailCropped.size &&
                                thumbnailCropped.size >= 400000 && (
                                    <div className="error_message">
                                        File size exceeds the maximum limit.
                                    </div>
                                )}
                        </section>

                        {/* background section */}
                        <section>
                            <h3>Background</h3>
                            <ImageCropper
                                imageToCrop={imageToCropBackground}
                                onImageCropped={(croppedImage) =>
                                    setBackgroundCropped(croppedImage)
                                }
                                maxCrop={{ x: 0, y: 0, width: 155, height: 116, unit: "px" }}
                                name="background.jpeg"
                            />
                            <div>
                                <label htmlFor="background-file">
                                    <input
                                        accept="image/*"
                                        id="background-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        name="background"
                                        onChange={(e) => onUploadFile(e, setImageToCropBackground)}
                                    />
                                    <TextField
                                        placeholder="image url"
                                        variant="filled"
                                        onBlur={handleBlur}
                                        value={backgroundCropped?.name || backgroundCropped || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <Button variant="contained" component="span">
                                        Upload
                                    </Button>
                                    <p className="image_hint">*Hint: Dimension is: 325 * 154</p>
                                </label>
                            </div>

                            {backgroundCropped &&
                                backgroundCropped.size &&
                                backgroundCropped.size > 400000 && (
                                    <div className="error_message">
                                        File size exceeds the maximum limit.
                                    </div>
                                )}
                        </section>

                        {/*thumbnail web */}
                        <section>
                            <h3>Thumbnail Web</h3>
                            <ImageCropper
                                imageToCrop={imageToCropThumbnailWeb}
                                onImageCropped={(croppedImage) =>
                                    setThumbnailCroppedWeb(croppedImage)
                                }
                                maxCrop={{ x: 0, y: 0, width: 33, height: 32, unit: "px" }}
                                name="thumbnail.jpeg"
                            />
                            <div>
                                <label htmlFor="contained-button-file-web">
                                    <input
                                        accept="image/*"
                                        id="contained-button-file-web"
                                        type="file"
                                        style={{ display: "none" }}
                                        name="thumbnail_web"
                                        onChange={(e) =>
                                            onUploadFile(e, setImageToCropThumbnailWeb)
                                        }
                                    />
                                    <TextField
                                        placeholder="image url"
                                        variant="filled"
                                        onBlur={handleBlur}
                                        value={
                                            thumbnailCroppedWeb?.name || thumbnailCroppedWeb || ""
                                        }
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <Button variant="contained" component="span">
                                        Upload
                                    </Button>
                                </label>
                            </div>
                            {thumbnailCroppedWeb &&
                                thumbnailCroppedWeb.size &&
                                thumbnailCroppedWeb.size >= 400000 && (
                                    <div className="error_message">
                                        File size exceeds the maximum limit.
                                    </div>
                                )}
                        </section>

                        {/*background web */}
                        <section>
                            <h3>Background Web</h3>
                            <ImageCropper
                                imageToCrop={imageToCropBackgroundWeb}
                                onImageCropped={(croppedImage) => {
                                    setBackgroundCroppedWeb(croppedImage);
                                }}
                                maxCrop={{ x: 0, y: 0, width: 155, height: 116, unit: "px" }}
                                name="background.jpeg"
                            />
                            <div>
                                <label htmlFor="background-file-web">
                                    <input
                                        accept="image/*"
                                        id="background-file-web"
                                        type="file"
                                        style={{ display: "none" }}
                                        name="background_web"
                                        onChange={(e) => {
                                            onUploadFile(e, setImageToCropBackgroundWeb);
                                            // handelUploadPhoto(e);
                                        }}
                                    />
                                    <TextField
                                        placeholder="image url"
                                        variant="filled"
                                        onBlur={handleBlur}
                                        value={
                                            backgroundCroppedWeb?.name || backgroundCroppedWeb || ""
                                        }
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <Button variant="contained" component="span">
                                        Upload
                                    </Button>
                                </label>
                            </div>
                        </section>
                        {backgroundCroppedWeb &&
                            backgroundCroppedWeb.size &&
                            backgroundCroppedWeb.size > 400000 && (
                                <div className="error_message">
                                    File size exceeds the maximum limit.
                                </div>
                            )}

                        {checkboxes.map((item, index) => (
                            <section
                                key={index}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <Checkbox
                                    size="small"
                                    name={item.name}
                                    value={values[item.name]}
                                    onChange={handleChange}
                                    checked={values[item.name]}
                                />
                                <h3>{item.title}</h3>
                            </section>
                        ))}
                        <section>
                            <h3>Order</h3>
                            <FormControl className="select-field">
                                <Select
                                    size="small"
                                    name="order"
                                    value={values.order}
                                    // label="Sector"
                                    // onChange={handleChangeSector}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                </Select>
                            </FormControl>
                        </section>

                        <OfferTable
                            list={installmentPlans}
                            // onBtnClick={handleActivePlan}
                            tableData={customInstallDataTable}
                            readOnly={false}
                            inputClassName="input"
                            btnChecked="published"
                            inputType="number"
                            months={true}
                            handlePlanNumbers2={handlePlanNumbers2}
                            handlePlanNumbers={handlePlanNumbers}
                            publishSwitch={publishSwitch}
                        />

                        <div className="submitBtn">
                            <Button variant="contained" type="submit">
                                Save section
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </article>
    );
};

export default GlobalOfferDetails;