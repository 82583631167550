export let textFieldsOnBoard = [
    {
        label: "Brand Name",
        name: "brand_name",
        error: true,
    },

    {
        label: "Account Manager Mail",
        name: "account_manager_mail",
        error: true,
    },
    // {
    //     label: "Brand Sector",
    //     name: "sector",
    //     error: true,
    // },
    // {
    //     label: "MDM Code",
    //     name: "MDM_code",
    //     error: true,
    // },

    {
        label: "Commercial register",
        name: "commercial_register",
        error: true,
    },
    {
        label: "Value added tax certificate",
        name: "Value_added_tax_certificate",
        error: true,
    },
    {
        label: "Tax card",
        name: "Tax_card",
        error: true,
    },
    {
        label: "Legal representative card",
        name: "Legal_representative_card",
        error: true,
    },
    {
        label: "Address",
        name: "address",
        error: true,
    },
    // {
    //     label: "Brand Class",
    //     name: "brand_class",
    //     error: true,
    // },

    {
        label: "Number of branches",
        name: "number_of_branches",
        error: true,
    },

    {
        label: "Facebook Link",
        name: "facebook_link",
        error: true,
    },
    {
        label: "Instagram Link",
        name: "instgram_link",
        error: true,
    },
    {
        label: "Website Link",
        name: "website_link",
        error: true,
    },
    {
        label: "Other Links",
        name: "other_link",
        error: true,
    }
];