import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import "./Makers.scss";
import { useNavigate } from "react-router-dom";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { UserUtility } from "../../apis/UserUtility";
import { Form } from "react-bootstrap";
import PaginationComponent from "../pagination/PaginationComponent";
import UserContext from "../../hooks/userContext";


const Makers = () => {
  const [makersInfo, setMakersInfo] = useState([]);
  const [couriersList, setCouriersList] = useState([]);
  const [courier, setCourier] = useState("");
  const [makersId, setMakersId] = useState([]);
  const [pagination, setPagination] = useState({ next: null });
  const [count, setCount] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [branchesList, setBranchesList] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { permissions = [] } = useContext(UserContext);

  useEffect(() => {
    getMakers(selectedBranch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedBranch]);

  useEffect(() => {
    getCouriers();
    getBranches();
  }, []);

  // get branches and handle pagination

  const getBranches = () => {
    UserUtility.contractNotCollectedBranches()
      .then((res) => {
        setBranchesList(res.results);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((error) => {
        console.error("get getBranches error", error);
      });
  };

  const paginationFun = (nextUrl) => {
    UserUtility.getNextPageData(nextUrl, "")
      .then((res) => {
        setBranchesList((old) => [...old, ...res.results]);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((err) => console.log(err));
  };
  //get makers and handle pagination with scroll to the bottom
  const getMakers = (branch) => {
    UserUtility.makersList(count, `&kiosk_v2=${branch}`)
      .then((res) => {
        setMakersInfo()
        const result = res.results.map((maker) => {
          return {
            ...maker,
            active: false,
          };
        });
        if (count !== 1) {
          setMakersInfo((makersInfo) => [...makersInfo, ...result]);
        } else {
          setMakersInfo(result);
        }
        setPagination(res);
      })
      .catch((error) => {
        console.error("get makers error", error);
      });
  };
  //get couries to put them in the select list
  const getCouriers = () => {
    UserUtility.courierList()
      .then((res) => {
        setCouriersList(res.results);
      })
      .catch((error) => {
        console.error("get couriers error", error);
      });
  };

  //to handle active maker check
  const handleActivePlan = (index, makerId) => {
    //to add or remove checkbox as UI
    const newMakersInfo = makersInfo.map((makerInfo, id) => {
      return {
        ...makerInfo,
        active: index === id ? !makerInfo.active : makerInfo.active,
      };
    });
    setMakersInfo(newMakersInfo);
    //to add and remove makersId to send to BE
    const checkId = makersId.filter((maker) => maker === makerId);
    if (checkId[0]) {
      const removeId = makersId.filter((maker) => maker !== makerId);
      setMakersId(removeId);
    } else {
      setMakersId((oldMakers) => [...oldMakers, makerId]);
    }
  };

  //  handle select all makers
  const handleSelectAll = (event) => {
    setIsSelectAll(event.target.checked);
    //to add or remove checkbox as UI
    const newMakersInfo = makersInfo.map((makerInfo, id) => {
      return {
        ...makerInfo,
        active: event.target.checked,
      };
    });
    setMakersInfo(newMakersInfo);
    if (event.target.checked) {
      const AllIds = newMakersInfo.map((item, index) => item.id);
      setMakersId(AllIds);
    } else {
      setMakersId([]);
    }
  };

  //send courier and navigate to report
  const handleReport = () => {
    UserUtility.createCollectionOrder(makersId, courier, selectedBranch)
      .then((res) => {
        navigate("/contractReport", {
          state: {
            details: res,
          },
        });
      })
      .catch((error) => {
        console.error("create collection error", error);
      });
  };
  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  }
  return (
    <main className="makers_container">
      {permissions.includes('contract_operations') ?
        <>
          {/* branches */}
          <section className="branch_sec">
            <Form.Select
              className="ms-auto"
              size="lg"
              onChange={(e) => {

                setSelectedBranch(e.target.value)
                setPage(1)
                getMakers(e.target.value);

              }
              }
            >
              <option selected disabled>
                Choose a branch...
              </option>
              {branchesList?.map((branch, id) => (
                <option key={id} value={branch.id}>
                  {branch.title}
                </option>
              ))}
            </Form.Select>
          </section>

          <section className="date_wrapper">
            <h4>Date:</h4>
            <h4>{moment().format("DD.MM.YYYY")}</h4>
          </section>
          {/* branches table */}
          <TableContainer
            component={Paper}
            style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Maker</StyledTableCell>
                  <StyledTableCell># Contract to collect</StyledTableCell>
                  <StyledTableCell>Branch</StyledTableCell>
                  <StyledTableCell>Phone</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={"All"}>
                  <StyledTableCell style={{ borderRight: "1px dashed #707070" }}>
                    <button
                      onClick={(event) => handleSelectAll(event)}
                      className="checkBtn"
                    >
                      <Checkbox
                        size
                        ="small"
                        checked={makersInfo?.length > 0 ? isSelectAll : false}
                        disabled={makersInfo?.length === 0}
                      />
                      <span>All</span>
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
                {makersInfo?.length > 0 ? (
                  makersInfo?.map((row, id) => (
                    <StyledTableRow key={id}>
                      <StyledTableCell
                        style={{ borderRight: "1px dashed #707070" }}
                      >
                        <button
                          onClick={() => handleActivePlan(id, row?.contracts?.maker)}
                          className="checkBtn"
                        >
                          <Checkbox size="small" checked={row.active} />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <input
                          className="tableShowInput"
                          defaultValue={row?.contracts?.maker__email}
                          readOnly
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <input
                          className="tableShowInput"
                          defaultValue={row?.contracts?.contracts_count}
                          readOnly
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row?.contracts?.kiosk_v2__title}</StyledTableCell>
                      <StyledTableCell>
                        <input
                          className="tableShowInput"
                          defaultValue={row?.contracts?.maker__phone}
                          readOnly
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                      All contracts have been scheduled for pick-up. Good job!
                    </StyledTableCell>

                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationComponent
            brands={pagination}
            page={page}
            handleChange={handleChange}
            total={false}
          />
          <div className="submitBtn">
            <FormControl className="select-field">
              <InputLabel>Courier</InputLabel>
              <Select
                size="small"
                value={courier}
                label="Courier"
                onChange={(e) => setCourier(e.target.value)}
              >
                {couriersList?.map((eachCourier, id) => {
                  return (
                    <MenuItem value={eachCourier.id} key={id}>
                      {eachCourier.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {courier && makersId.length > 0 && selectedBranch ? (
              <Button variant="contained" type="submit" onClick={handleReport}>
                Collection order
              </Button>
            ) : (
              <Button variant="contained" type="submit" disabled>
                Collection order
              </Button>
            )}
          </div>
        </>
        :
        <div>
          <h6 className="permission_style">You don't have permission to see this page</h6>
        </div>
      }
    </main>
  );
};

export default Makers;
