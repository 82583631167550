import React from "react";
import Multiselect from "multiselect-react-dropdown";
const MultipleSelectionNotify = ({
  optionsItem,
  label,
  finalSelectedList,
  selectedValues,
  hidePlaceholder,
  isDisable
}) => {
  const customSelectedValues =
    selectedValues && selectedValues.length > 0
      ? selectedValues.map((item, index) => {
        return {label: item?.label || item, value: item?.value || item };
      })
      : [];
  // const customSelectedValues = selectedValues || []
  const handleSelected = (selectedList, selectedItem) => {
    finalSelectedList(selectedList);
  };

  const handleRemoved = (selectedList, removedItem) => {
    finalSelectedList(selectedList);
  };
  return (
    <Multiselect
      className="customSelection"
      displayValue="label"
      placeholder={`select ${label}`}
      onKeyPressFn={function noRefCheck() { }}
      onRemove={handleRemoved}
      onSearch={function noRefCheck() { }}
      onSelect={handleSelected}
      options={optionsItem}
      selectedValues={customSelectedValues}
      showCheckbox
      hidePlaceholder
      avoidHighlightFirstOption
      loading={optionsItem?.length > 0 ? false : true}
      disable={isDisable}
    />
  );
};

export default MultipleSelectionNotify;

