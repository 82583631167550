import React, { useEffect, useState,useContext } from "react";
import UserContext from "../../hooks/userContext";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import RecentCardPayments from "./RecentCardPayments";
import { ENDPOINTS } from "../../apis/EndPoints";
import axios from "axios";
const PaymentHistory = ({ paymentHistoryState }) => {
  Modal.setAppElement("#root");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "85%",
      overflowY: "scroll",
      height: "85%",
    },
  };
  const { handleLoader} = useContext(UserContext);
  const [userPayment, setPayment] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [results, setResults] = useState();
  const [cardPaymentsModal, setCardPaymentsModal] = useState(false);
  const location = useLocation();
  const { userId } = location.state;
  const payment_history = () => {
    if (paymentHistoryState) {
      UserUtility.payment_history(userId, pagesNumber)
        .then((res) => {
          setResults(res);
          setPayment(res.results);
        })
        .catch((err) => console.log("user history err", err));
    }
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  const exportPaymentsHistory = async () => {
    handleLoader(true);
    const token = localStorage.getItem("tools_token");
    const headers = {
      "Content-Type": "blob",
      Authorization: `token ${token}`,
    };
    const config = {
      method: "GET",
      url: `${ENDPOINTS.url}management_dashboard/user_dashboard/payment_export/${userId}/`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `payment-history.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      throw Error(error);
    }
  };
  useEffect(() => {
    payment_history();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentHistoryState, pagesNumber]);
  return (
    <div>
      <div className="text-end">
      <Button className="w-25 mt-3 mb-3 border border-success text-success" onClick={()=>exportPaymentsHistory()}>
        Export Payment
      </Button>
      </div>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 2000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Payment value</StyledTableCell>
              <StyledTableCell>Principle/intrest</StyledTableCell>
              <StyledTableCell>Payment date</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Modified</StyledTableCell>
              <StyledTableCell>Payment method</StyledTableCell>
              <StyledTableCell>settled</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell>Brand</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userPayment?.map((field, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{field.amount}</StyledTableCell>
                  <StyledTableCell>
                    {field.principal} / {field.interest}
                  </StyledTableCell>
                  <StyledTableCell>{`${field.date_paid}`}</StyledTableCell>
                  <StyledTableCell>{`${field.created}`}</StyledTableCell>
                  <StyledTableCell>{`${field.modified}`}</StyledTableCell>
                  <StyledTableCell>{field.payment_method}</StyledTableCell>
                  <StyledTableCell>{`${field.settled}`}</StyledTableCell>
                  <StyledTableCell>{field.category}</StyledTableCell>
                  <StyledTableCell>{field.product_label}</StyledTableCell>
                  <StyledTableCell>{field.brand}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => setCardPaymentsModal(true)}
        className="w-25 mt-5  border border-success text-success "
      >
        Recent card payments
      </Button>
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
      <Modal
        isOpen={cardPaymentsModal}
        onRequestClose={() => setCardPaymentsModal(false)}
        style={customStyles}
        contentLabel="card Payment"
        shouldCloseOnOverlayClick={true}
      >
        <RecentCardPayments />
      </Modal>
    </div>
  );
};

export default PaymentHistory;
