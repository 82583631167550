import React, { useEffect, useState } from "react";
import "./ContractHistory.scss";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { UserUtility } from "../../apis/UserUtility";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const ContractHistory = ({ contractId }) => {
  const [historyData, setHistoryData] = useState({});

  useEffect(() => {
    contractId &&
      UserUtility.contractHistory(contractId)
        .then((res) => {
          setHistoryData(res);
        })
        .catch((error) => console.error("error contract history", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#41b6a8",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      border: "1px solid #41b6a8",
      color: "#464646",
      textAlign: "center"
    },
  }));


  return (
    <main className="contractHistory_container">
      <section className="section_wrapper">
        <h4>Activated date:</h4>
        <h4>{historyData?.contract?.activated_date}</h4>
      </section>
      <section className="section_wrapper">
        <h4>Maker</h4>
        <h4>{historyData?.contract?.maker}</h4>
      </section>
      <section className="section_wrapper">
        <h4>Activation Point:</h4>
        <h4>{historyData?.contract?.activation_point}</h4>
      </section>
      <section className="section_wrapper">
        <h4>Client Name:</h4>
        <h4>{historyData?.contract?.client_name}</h4>
      </section>
      {/* table container */}
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="historyDetail">
                Action
              </StyledTableCell>
              <StyledTableCell className="historyDetail">Date</StyledTableCell>
              <StyledTableCell className="historyDetail">
                Courier
              </StyledTableCell>
              <StyledTableCell className="historyDetail">
                Department
              </StyledTableCell>
              <StyledTableCell className="historyDetail">
                Employee name
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData?.actions?.map((row, id) => (
              <StyledTableRow key={id}>
                <StyledTableCell className="historyDetail">
                  {row.action_type === "reject" ? (

                    <BootstrapTooltip
                      title={
                        <div>
                          <h4 className="tooltip_title">Rejection Reason</h4>
                          <p >{row.rejection_reason}</p>
                          <h4 className="tooltip_title">Rejection Comment</h4>
                          <p >{row.comment}</p>
                        </div>
                      }
                    >
                      <p className="reject_tooltip">{row.action_type}</p>
                    </BootstrapTooltip>

                  ) : (
                    <>{row.action_type}</>
                  )}
                </StyledTableCell>
                <StyledTableCell className="historyDetail">
                  {row.created}
                </StyledTableCell>
                <StyledTableCell className="historyDetail">
                  {row.courier}
                </StyledTableCell>
                <StyledTableCell className="historyDetail">
                  {row.department}
                </StyledTableCell>
                <StyledTableCell className="historyDetail">
                  {row.operations_user}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
};

export default ContractHistory;
