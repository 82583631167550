import { toast } from "react-toastify";

export class Network {
  constructor() {
    this.jwt = "";
  }

  static async fetch(url, init, addAuth, custom) {
    // console.log("custom: ", custom);
    this.jwt = await localStorage.getItem("tools_token");
    const response = await fetch(url, {
      mode: "cors",
      ...init,
      headers: Network.getHeaders(init.headers, addAuth, custom),
    });
    let promise;
    if (response.status !== 200 && response.status !== 201) {
      promise = Network.handleErrorsBasedOnStatus(response);
    } else {
      promise = response.json();
    }
    return promise;
  }
  static getHeaders(originalHeaders, addAuth, customHeader) {
    let headers = {
      accept: "application/json",
      "Accept-Language": "en"
    };

    if (addAuth) {
      console.log('this.jwt: ',this.jwt)
      headers.Authorization = `token ${this.jwt}`;
    }

    // to handle if content-type is json or not
    if (!customHeader) {
      headers["content-type"] = "application/json";
    } else {
      headers["content-type"] = customHeader;
    }
    headers = {
      ...headers,
      ...originalHeaders,
    };

    // console.log("headers headers: ", headers);
    return headers;
  }



  static handleErrorsArrayType(data) {
    for (let i = 0; i < data.length; i++) {
      return Promise.reject(data[i].toString());
    }
  }

  static handleErrorsObjectType(data) {
    for (const [key, value] of Object.entries(data.errors || data)) {
      return Promise.reject(value.toString());
    }
  }

  static handleErrorsBasedOnStatus(response) {
    let promise;
    switch (response.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 422:
      case 429:
      case 500:
        promise = response.json().then((data) => {
          // this will get error from any array like non_field_errors if not array will loop on Object.Enteries
          if (Array.isArray(data)) {
            return this.handleErrorsArrayType(data);
          } else if (typeof data === "object") {
            return this.handleErrorsObjectType(data);
          }

          /*
          // this will convert any error to string consist on key + value, I hide this becouse all error in this tool did not appear 
           if (data.errors) {
             for (const [key, value] of Object.entries(data.errors)) {
               let final =(key+'-').concat(value) 
               return Promise.reject(final);
             }
           } else {
             return Promise.reject(data);
           }
           */
        });
        break;

      case 204:
        promise = response;
        return promise;

      default:
      // toast("Oops something went wrong", {
      //   position: "top-center",
      //   autoClose: "2500",
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
    if (promise) {
      return promise.catch((error) => {
        return Promise.reject(error);
      });
    } else {
      return Promise.reject();
    }
  }
}
