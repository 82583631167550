/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ENDPOINTS } from "../../apis/EndPoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const AdvancedPayments = () => {
  const theme = createTheme({
    palette: {
      main: {
        main: "#41b6a8",
        contrastText: "#fff",
      },
      blue: {
        main: "#418AB6",
        contrastText: "#fff",
      },
    },
  });
  let today = new Date().toISOString().slice(0, 10);
  let navigate = useNavigate();

  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [total, setTotal] = useState();
  const [list, setList] = useState();
  const [date, setDate] = useState({ from: null, to: null });
  const [nationalId, setNationalId] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [ids, setIds] = useState([]);
  const [checkedIds, setCheckedIds] = useState();
  const [selectedArr, setSelectedArr] = useState([]);

  toast.configure();
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  useEffect(() => {
    if (permissions.includes("can_view_advanced_payments")) {
      finance_list();
    }
  }, [pagesNumber, permissions]);
  useEffect(() => {
    if (
      date.from === "Invalid date" ||
      (date?.from?.length === 10 && date?.from[0] == 0)
    )
      return setDate({ ...date, from: null });
    if (
      date.to === "Invalid date" ||
      (date?.to?.length === 10 && date?.to[0] == 0)
    )
      return setDate({ ...date, to: null });
  }, [date]);

  const finance_list = () => {
    handleLoader(true);
    UserUtility.finance_list(pagesNumber, nationalId, date)
      .then((res) => {
        handleLoader(false);
        setTotal(res);
        setList(res.results);
      })
      .catch((err) => {
        console.error("finance_list", err);
        toast.error(err);
        handleLoader(false);
      });
  };

  const checker_confirm = () => {
    handleLoader(true);
    UserUtility.checker_confirm(checkedIds)
      .then(() => {
        handleLoader(false);
        toast.success("Advanced Payment Approved Successfully");
        // finance_list()
        setCheckedIds([]);
        setTimeout(() => {
          window.location.reload();
        }, [1100]);
      })
      .catch((err) => {
        handleLoader(false);
        console.error("checker_confirm", err);
        toast.error(err);
      });
  };

  const handleClick = (e) => {
    if (nationalId?.length === 14 || date.from?.length || date.to?.length) {
      if (e === "Enter") {
        finance_list();
        setPagesNumber(1);
      }
    }
  };
  const handelUploadFile = async (e) => {
    const file = e.target.files[0];
    const token = localStorage.getItem("tools_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    const formdata = new FormData();
    formdata.append("excel_file", file);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    const fet = await fetch(
      `${ENDPOINTS.url}/finance/cash_collection/advanced_payments/import_sheet/`,
      requestOptions
    )
      .then(async (res) => {
        if (!res.ok) {
          handleLoader(false);
          const text = await res.text();
          throw new Error(text);
        } else {
          toast.success("File imported successfully");
        }
      })
      .catch((err) => {
        console.error("handelUploadFile", err);
        handleLoader(false);
        toast.error(err[0] || "something went wrong");
      });
    finance_list();
    e.target.value = null;
  };
  const exportAdvancedPayments = async () => {
    handleLoader(true);
    const token = localStorage.getItem("tools_token");
    const headers = {
      "Content-Type": "blob",
      Authorization: `token ${token}`,
    };
    const config = {
      method: "GET",
      url: `${ENDPOINTS.url}finance/cash_collection/advanced_payments/export/?${
        nationalId ? `&national_id=${nationalId}` : ""
      }${date?.from?.length ? `&created_at__date__gte=${date?.from}` : ""}${
        date?.to?.length ? `&created_at__date__lte=${date?.to}` : ""
      }`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `advanced-payment-${today}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      throw Error(error);
    }
  };

  const checkFun = () => {
    let unique = [];
    ids?.forEach((element) => {
      if (!unique.includes(element)) {
        unique.push(element);
        const compare = (a, b) => {
          return a - b;
        };
        unique?.sort(compare);
      } else {
        unique.splice(unique.indexOf(element), 1);
      }
    });
    setCheckedIds(unique);
  };
  const pageIds = list?.map((ele) => {
    return ele.id;
  });

  const pageAll = list?.map((ele) => {
    return ele;
  });
  const handleSelect = (ele) => {
    // let arr = selectedArr;
    var check = selectedArr.some((value) => {
      return value.id === ele.id ? true : false;
    });
    console.log("check: ", check);
    if (!check) {
      // arr.push(ele)
      setSelectedArr([...selectedArr, ele]);
      // console.log('arrr: ', arr)
    } else {
      selectedArr.splice(selectedArr.indexOf(ele), 1);
      setSelectedArr(selectedArr);
    }
    setIds([...ids, ele.id]);
  };

  const handleAll = () => {
    setIds(pageIds);
    setSelectedArr(pageAll);
  };
  useEffect(() => {
    checkFun();
  }, [ids]);
  return (
    <>
      {permissions?.includes("can_view_advanced_payments") ? (
        <div className="UserProfile mb-5">
          <div className="textStyle">
            <Form.Text className="fs-5" style={{ color: "white" }}>
              Advanced Payments
            </Form.Text>
          </div>
          <Row className="align-items-center pt-4 ">
            <Col
              lg={7}
              md={6}
              sm={12}
              className="d-flex justify-content-between"
            >
              <Form.Control
                onKeyPress={(e) => handleClick(e.key)}
                type="number"
                size="medium"
                placeholder="National ID "
                onChange={(e) => setNationalId(e.target.value)}
                className="text-field"
                label="National ID "
              />
              <div className="d-flex" style={{ width: "55%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="From"
                    value={
                      date.from?.length === 12 ? new Date(date.from) : null
                    }
                    onChange={(e) => {
                      setDate({
                        ...date,
                        from: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                    onKeyPress={(e) => handleClick(e.key)}
                    renderInput={(params) => (
                      <TextField
                        onKeyPress={(e) => handleClick(e.key)}
                        {...params}
                      />
                    )}
                  />
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="To"
                    value={date.to ? new Date(date.to) : null}
                    onChange={(e) => {
                      setDate({
                        ...date,
                        to: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        onKeyPress={(e) => handleClick(e.key)}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </Col>
            <Col
              lg={5}
              md={6}
              sm={5}
              className="d-flex h-75 justify-content-evenly"
              style={{ alignItems: "self-end" }}
            >
              <ThemeProvider theme={theme}>
                <Button
                  className="px-5"
                  variant="contained"
                  color="main"
                  disabled={
                    nationalId?.length !== 14 &&
                    !date.from?.length &&
                    !date.to?.length
                      ? true
                      : false
                  }
                  onClick={() => handleClick("Enter")}
                >
                  Search
                </Button>
                {permissions.includes("can_export_advanced_payments") && (
                  <Button
                    className="px-5"
                    variant="contained"
                    component="span"
                    color="main"
                    onClick={() => exportAdvancedPayments()}
                  >
                    Export
                  </Button>
                )}
              </ThemeProvider>
              <label
                htmlFor="contained-button-file"
                className="d-flex align-content-center"
              >
                <input
                  accept=".xlsx"
                  id="contained-button-file"
                  type="file"
                  style={{ display: "none" }}
                  name="thumbnail"
                  onChange={(e) => handelUploadFile(e)}
                />
                {permissions.includes("can_import_advanced_payments") && (
                  <ThemeProvider theme={theme}>
                    <Button
                      className="px-5"
                      variant="contained"
                      component="span"
                      color="main"
                    >
                      Import
                    </Button>
                  </ThemeProvider>
                )}
              </label>
            </Col>
          </Row>
          <TableContainer
            // component={Paper}
            style={{ marginRight: 20, marginTop: 30 }}
          >
            <Table striped responsive hover className="mt-5 users_table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      onChange={() =>
                        pageIds?.join() === checkedIds?.join()
                          ? setIds([])
                          : handleAll()
                      }
                      checked={
                        pageIds?.join() === checkedIds?.join() &&
                        pageIds?.length !== 0
                          ? true
                          : false
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>National ID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Payment Method</StyledTableCell>
                  <StyledTableCell>Deposit Type</StyledTableCell>
                  <StyledTableCell>Created Date</StyledTableCell>
                  <StyledTableCell>Approved</StyledTableCell>
                  <StyledTableCell style={{ maxWidth: "25%" }}>
                    Comment
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((ele, index) => (
                  <StyledTableRow key={index.toString()}>
                    <StyledTableCell>
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleSelect(ele)}
                        checked={checkedIds.includes(ele.id) ? true : false}
                      />{" "}
                    </StyledTableCell>
                    <StyledTableCell>{ele.id}</StyledTableCell>
                    <StyledTableCell>{ele.national_id}</StyledTableCell>
                    <StyledTableCell>{ele.user_name}</StyledTableCell>
                    <StyledTableCell>{ele.advanced_amount}</StyledTableCell>
                    <StyledTableCell>{ele.payment_method}</StyledTableCell>
                    <StyledTableCell>{ele.deposit_type}</StyledTableCell>
                    <StyledTableCell>{ele.created_at}</StyledTableCell>
                    <StyledTableCell>
                      <Form.Check
                        type="checkbox"
                        checked={ele.is_confirmed}
                        disabled
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Form.Control
                        as="textarea"
                        disabled
                        value={ele.comment}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <ThemeProvider theme={theme}>
            {permissions.includes("can_confirm_users_advanced_payment") ? (
              <Button
                className="px-5 mt-2"
                variant="contained"
                component="span"
                color="blue"
                onClick={() => {
                  checker_confirm();
                }}
              >
                {"Approve"}
              </Button>
            ) : (
              <Button
                className="px-5 mt-2"
                variant="contained"
                component="span"
                color="main"
                disabled={ids.length === 0 ? true : false}
                onClick={() => {
                  navigate("/Receipt", {
                    state: {
                      users: selectedArr,
                    },
                  });
                  // console.log('selectedArr: ', selectedArr)
                  // console.log('checkedIds: ', checkedIds)
                }}
              >
                {"Print Receipts"}
              </Button>
            )}
          </ThemeProvider>
          <PaginationComponent
            brands={total}
            page={pagesNumber}
            handleChange={handleChange}
          />
        </div>
      ) : (
        <div className="permission_div">
          <h6 className="permission_style">
            You don't have permission to see this page
          </h6>
        </div>
      )}
    </>
  );
};

export default AdvancedPayments;
