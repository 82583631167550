import React, { useEffect, useState } from "react";
import { Button, Col, Form, Table, InputGroup } from "react-bootstrap";
import { installmentsModal } from "./TablesHeader";
import { productsModal } from "./TablesHeader";
import { penaltiesModal } from "./TablesHeader";
import { UserUtility } from "../../../../apis/UserUtility";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { TextField } from "@mui/material";
import { ENDPOINTS } from "../../../../apis/EndPoints";

const ModalTables = ({
  Section,
  elementSelected,
  advancedPayment,
  totalDues,
  user_id,
  buttonName,
  totalEarly,
}) => {
  const [paymentValue, setPaymentValue] = useState();
  const [validation, setValidation] = useState(false);
  const [beforeWaive, setbeforeWaive] = useState();
  const [waivePercentage, setWaivePercentage] = useState();
  const [waiveExpiry, setWaiveExpiry] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  let today = new Date().toISOString().slice(0, 10);
  const earlyDates = elementSelected?.filter(
    (e) => e?.early_settlement_date > today
  );
  const dates = elementSelected?.map((e) => {
    return e?.due_date;
  });
  const beforeWaiveSum = elementSelected?.map((e) => {
    return e?.remaining_dues;
  });
  const waiveIds = elementSelected?.map((e) => {
    return e?.id;
  });
  const earlyIds = elementSelected?.map((e) => {
    return e?.id;
  });
  const selectamount = () => {
    let sum = 0;
    for (let i = 0; i < beforeWaiveSum?.length; i += 1) {
      sum += beforeWaiveSum[i];
    }
    setbeforeWaive(sum);
  };

  const partial_pay = (e) => {
    if (e.detail === 1) {
      if (
        paymentValue > advancedPayment ||
        paymentValue < 0 ||
        paymentValue > totalDues.toFixed(2)
      ) {
        setValidation(true);
      } else {
        setDisableButton(true);
        setValidation(false);
        UserUtility.cash_collection_oneTxn(user_id, dates, paymentValue)
          .then((res) => {
            toast(res.details);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch((err) => toast.error(err));
      }
    }
  };
  const totalCashCollect = () => {
    toast.error("Advanced Payment not enough for this action");
  };
  const waive_penalties = (e) => {
    if (e.detail === 1) {
      setDisableButton(true);
      UserUtility.waive_penalties_oneTxn(user_id, waiveIds, waivePercentage)
        .then(() => {
          toast("Penalty Is Waved Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => toast.error(err));
    }
  };
  const update_penalties = (e) => {
    if (e.detail === 1) {
      setDisableButton(true);
      UserUtility.update_penalties_oneTxn(
        user_id,
        waiveIds,
        waivePercentage,
        waiveExpiry
      )
        .then(() => {
          toast("Penalty Is Temporary Waved Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => toast.error(err));
    }
  };

  const early_settle = () => {
    var token = localStorage.getItem("tools_token");
    let values = {
      loan_ids: earlyIds,
    };
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    myHeaders.append("Accept", `application/json`);
    myHeaders.append("content-type", `application/json`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values),
      redirect: "follow",
    };

    fetch(
      `${ENDPOINTS.url}finance/cash_collection/one_txn/user_profile/${user_id}/early_settle/`,
      requestOptions
    )
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.json();
          // toast(text?.errors)
          if (text?.errors?.non_field_errors)
            return toast.error(text?.errors?.non_field_errors[0]);
          // console.log(text?.errors?.loan_ids.)
          if (text?.errors?.loan_ids) {
            for (const [key, value] of Object.entries(text?.errors?.loan_ids)) {
              let final = (key + "  ").concat(value);
              toast.error(final);
            }
          }
        } else {
          toast.success("Early Settled Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  useEffect(() => {
    selectamount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementSelected]);
  return (
    <div>
      <Table striped responsive hover className="users_table">
        <thead>
          <tr>
            {(Section === "Installments"
              ? installmentsModal
              : Section === "Products"
              ? productsModal
              : penaltiesModal
            )?.map((e, index) => (
              <th key={index}>{e.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {elementSelected?.map((e, index) => (
            <tr key={index}>
              <td>
                {Section === "Installments"
                  ? e.due_date
                  : Section === "Penalties"
                  ? e.id
                  : e.id}
              </td>
              <td>
                {Section === "Installments"
                  ? e.installments_amount
                  : Section === "Penalties"
                  ? e.amount
                  : e.early_settlement_date}
              </td>
              <td>
                {Section === "Installments"
                  ? e.installments_due_amount
                  : Section === "Penalties"
                  ? e.remaining_dues
                  : e.early_settlement_amount}
              </td>
              {Section !== "Products" && (
                <td>
                  {Section === "Installments"
                    ? e.last_payment_date
                    : Section === "Penalties"
                    ? e.created
                    : ""}
                </td>
              )}

              {Section === "Installments" && (
                <td>{e.penalties_total_amount}</td>
              )}
              {Section === "Penalties" && <td>{e.installment_number}</td>}
              {Section === "Installments" && <td>{e.penalties_due_amount}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="textStyle " style={{ textAlign: "start" }}>
        {Section === "Installments" || Section === "Products" ? (
          <Form.Text className="ms-5" style={{ color: "white" }}>
            Total Selected :{" "}
            {totalDues
              ? totalDues?.toFixed(2)
              : totalEarly
              ? totalEarly?.toFixed(2)
              : ""}
          </Form.Text>
        ) : (
          <>
            <Form.Text className="ms-5" style={{ color: "white" }}>
              Before Waived : {beforeWaive}
            </Form.Text>
            <Form.Text className="ms-5" style={{ color: "white" }}>
              After Waived :{" "}
              {waivePercentage > 100
                ? 0
                : waivePercentage &&
                  beforeWaive - (beforeWaive * waivePercentage) / 100}
            </Form.Text>
          </>
        )}
      </div>

      <Col className="table_bottom my-4">
        {Section !== "Penalties" && (
          <div className="advanced_payment bg-white ">
            <Form.Text className="fw-bold">Advanced Payment</Form.Text>
            <Form.Label className="fw-bolder">{advancedPayment}</Form.Label>
          </div>
        )}
        {Section === "Products" && (
          <Button
            style={{
              backgroundColor: "#41B66E",
              paddingInline: 40,
              color: "#FFFFFF",
            }}
            disabled={disableButton}
            onClick={(e) => early_settle(e)}
          >
            Pay
          </Button>
        )}
        {Section === "Penalties" && (
          <>
            <div className="advanced_payment bg-white ">
              <Form.Text className="fw-bold">Waive Percentage</Form.Text>
              <Form className="d-flex justify-content-center align-items-baseline">
                <Form.Control
                  style={{
                    borderColor: "#fff",
                    width: "25%",
                    backgroundColor: "rgb(149 131 131 / 5%)",
                  }}
                  value={waivePercentage > 100 ? "" : waivePercentage}
                  onChange={(e) => setWaivePercentage(e.target.value)}
                  name="value"
                  type="number"
                />
                <Form.Text className="fw-bold">%</Form.Text>
              </Form>
            </div>
          </>
        )}
        {buttonName === "withdrow" && Section === "Penalties" && (
          <>
            <div className="d-flex" style={{ width: "30%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={waiveExpiry ? new Date(waiveExpiry) : null}
                  onChange={(e) => {
                    setWaiveExpiry(moment(e).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ backgroundColor: "white" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </>
        )}
        {Section === "Installments" && (
          <InputGroup className="d-flex" style={{ width: "35%" }}>
            <Form.Control
              placeholder={`Max ${advancedPayment}`}
              onChange={(e) => setPaymentValue(e.target.value)}
              name="value"
              type="number"
              isInvalid={validation}
            />
            <Button
              style={{
                backgroundColor: "#41B66E",
                paddingInline: 40,
                color: "#FFFFFF",
              }}
              disabled={!paymentValue || disableButton}
              onClick={(e) => partial_pay(e)}
            >
              Pay
            </Button>
            <Form.Control.Feedback type="invalid">
              Value must be less than or equal to advanced payment and total
              selected
            </Form.Control.Feedback>
          </InputGroup>
        )}
        {buttonName === "withdrow" && (
          <Button
            style={{
              backgroundColor: "#41B66E",
              paddingInline: 40,
              color: "#FFFFFF",
            }}
            disabled={disableButton}
            onClick={(e) => update_penalties(e)}
          >
            Temporary Waive
          </Button>
        )}
        {Section === "Products" ||
          (buttonName === "waive" && (
            <Button
              style={{
                backgroundColor: "#41B66E",
                paddingInline: 40,
                color: "#FFFFFF",
              }}
              disabled={disableButton}
              onClick={(e) =>
                Section === "Products" ? totalCashCollect() : waive_penalties(e)
              }
            >
              {Section === "Products" ? "Total Cash Collect" : "Waive"}
            </Button>
          ))}
      </Col>
    </div>
  );
};
export default ModalTables;
