import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import './signUp.scss'

const SignUp = () => {
    const [spinner, setSpinner] = useState(false);

    const handleSignUp = async (values) => {
        setSpinner(true);
        const newValues = { ...values }
        newValues.email = `${newValues.email}@drive-finance.com`
        // UserUtility.userLogin(values)
        //   .then((res) => {
        //     localStorage.setItem("tools_token", res.token);
        //     setSpinner(false);
        //     setIsLogin(true);
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //     setSpinner(false);
        //     toast.error(error[0]);
        //   });
    };

    const validateYup = Yup.object({
        email: Yup.string().required("Required"),
        username: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
    });

    return (
        <div className="signup_container">
            <div className="form_container">
                <Formik
                    initialValues={{ email: "", password: "", username: "" }}
                    validationSchema={validateYup}
                    onSubmit={async (values) => {
                        handleSignUp(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} className="form">
                            <article className="inputs_wrapper">
                                <h4 className="login_header">Sign Up</h4>
                                <section className="inputs">
                                    <div className="input-container">
                                        <label className="form_label">Email</label>
                                        <div className="form__email">
                                            <input
                                                type="text"
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                className="input input--sm"
                                            />
                                            <div className="form__text">@drive-finance.com</div>
                                        </div>
                                        {errors.email && touched.email ? (
                                            <div className="error_message">{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className="input-container">
                                        <label className="form_label">Username</label>
                                        <input
                                            type="text"
                                            name="username"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.username}
                                            className="input"
                                        />
                                        {errors.username && touched.username ? (
                                            <div className="error_message">{errors.username}</div>
                                        ) : null}
                                    </div>

                                    <div className="input-container">
                                        <label className="form_label">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            className="input"
                                        />
                                        {errors.password && touched.password ? (
                                            <div className="error_message">{errors.password}</div>
                                        ) : null}
                                    </div>
                                </section>
                            </article>
                            <article className="buttonsWrapper">
                                <Button
                                    className="login_button"
                                    type="submit"
                                    variant="contained"
                                    disabled={
                                        errors.password ||
                                            errors.email ||
                                            !values.email ||
                                            !values.password
                                            ? true
                                            : false
                                    }
                                    endIcon={spinner && <CgSpinner className="spinner" />}
                                >
                                    Sign Up
                                </Button>
                            </article>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default SignUp;