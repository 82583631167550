export const installments = [
  {
    label: "Installment Date",
    sort: true,
  },
  {
    label: "Amount",
  },
  {
    label: "Paid Amount",
  },
  {
    label: "Remaining Amount",
    sort: true,
  },

  {
    label: "Late Days",
  },
  {
    label: "DPD",
  },
  {
    label: "BKT",
  },
  {
    label: "Paid Date",
    sort: true,
  },
  {
    label: "Penalties",
  },
  // {
  //   label: "Settled penalties",
  // },
  {
    label: "Paid Penalties",
  },
  {
    label: "Waived Penalties",
  },
  {
    label: "Penalties OnHold Amount",
  },
  {
    label: "Remain Penalties",
  },
  {
    label: "Settled Penalties",
  },
  {
    label: "Principal",
  },
  {
    label: "Interest",
  },
  {
    label: "Remain Principal",
  },
  {
    label: "Remain Interest",
  },
  {
    label: "Settled",
  },
];
export const products = [
  {
    label: "Loan ID",
  },
  {
    label: "Integration Reference",
  },
  {
    label: "Early Settlement Date",
  },
  {
    label: "Settled",
  },
  {
    label: "Remain Penalties",
  },
  {
    label: "Remain Principal",
  },
  {
    label: "Early Fees %",
  },
  {
    label: "Calculated Fees",
  },
  {
    label: "Upcoming Installment Amount",
  },
  {
    label: "Upcoming Installment Date",
  },
  {
    label: "Upcoming Installment Balance",
  },
  {
    label: "Refund Block",
  },
];
export const penalties = [
  {
    label: "Integration Reference",
  },
  {
    label: "Date Created",
    sort: true,
  },
  {
    label: "Amount",
  },
  {
    label: "Paid",
  },
  {
    label: "Remain",
    sort: true,
  },
  {
    label: "Installment Due Date",
    sort: true,
  },
  {
    label: "Installment Amount",
    sort: true,
  },
  {
    label: "Temporary Waived Amount",
  },
  {
    label: "Is Totaly Waived",
  },
  {
    label: "Hold",
    sort: true,
  },
];
export const installmentsModal = [
  {
    label: "Installment Date",
  },
  {
    label: "Amount",
  },
  {
    label: "Remaining Amount",
  },
  {
    label: "Paid Date",
  },
  {
    label: "Penalties",
  },
  {
    label: "Remaining Penalties",
  },
];
export const productsModal = [
  {
    label: "ID",
  },
  {
    label: "Early Settlement Date",
  },
  {
    label: "Early Settlement Amount",
  },
];
export const penaltiesModal = [
  {
    label: "Penalty ID",
  },
  {
    label: "Amount",
  },
  {
    label: "Remaining Amount",
  },
  {
    label: "Created Date",
  },
  {
    label: "Installment Number",
  },
];
