import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { UserUtility } from "../../apis/UserUtility";
import "./ActivationPointTool.scss";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { BsSearch } from "react-icons/bs";

const ActivationPointTool = () => {
  const [userSearch, setUserSearch] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectArea, setSelectArea] = useState();
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [page, setPage] = useState(1);
  const [activationPoints, setActivationPoints] = useState([]);
  const [activationCount, setActivationCount] = useState();

  const getKioskCities = () => {
    UserUtility.getKioskCities()
      .then((res) => {
        setCities(res);
      })
      .catch((err) => console.log("err of getKioskCities: ", err));
  };

  const getKioskCAreas = () => {
    console.log("selectCity", selectCity);
    UserUtility.getKioskCAreas(selectCity)
      .then((res) => {
        setAreas(res);
        console.log("resssssss", res);
      })
      .catch((err) => console.log("err of getKioskCities: ", err));
  };

  useEffect(() => {
    getKioskCities();
  }, []);

  const getActivationPointData = () => {
    // if (!selectCity) return;
    UserUtility.getActivationPoints(page, userSearch, selectCity, selectArea)
      .then((res) => {
        setActivationPoints(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getActivationPointData();
  }, [page]);

  // nabila comment... dublication with useeffect in down
  useEffect(() => {
    if (selectCity) {
      getKioskCAreas();
    } else {
      setAreas([]);
      setSelectArea();
    }
  }, [selectCity]);

  const searchHandler = () => {
    if (page === 1) {
      getActivationPointData();
    } else {
      setPage(1);
    }
  };

  // useEffect(() => {
  //   console.log("tooooooooooo", activationPoints);
  // }, [activationPoints]);

  //   useEffect(() => {
  //     console.log("aaaaaaaa", selectUserCity, userSearch);
  //   }, [selectUserCity, userSearch]);
  return (
    <div className="activationPoint_container background--white">
      <div className="top" style={{ marginTop: 30 }}>
        <div className="page__title">Activation Point</div>

        {/* filter */}
        <article>
          <Form className="flex_filter">
            <div className="input_div" style={{ width: "50%", marginRight: 4 }}>
              <Form.Control
                placeholder="Search by name"
                className="searchInput form_control_style"
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />
              <BsSearch className="search_icon" />
            </div>

            {/* cities */}
            <Form.Select
              aria-label="Default select example"
              value={selectCity}
              onChange={(e) => {
                setSelectCity(e.target.value);
              }}
            >
              <option value="">Select city</option>

              {cities.map((item, index) => (
                <option value={item.value} key={index.toString()}>
                  {item.label}
                </option>
              ))}
            </Form.Select>

            {/* areas */}
            <Form.Select
              aria-label="Default select example"
              value={selectArea}
              onChange={(e) => {
                setSelectArea(e.target.value);
              }}
            >
              <option value="">Select area</option>
              {areas.map((item, index) => (
                <option value={item.value} key={index.toString()}>
                  {item.label}
                </option>
              ))}
            </Form.Select>

            <Button
              className="searchButton"
              type="button"
              variant="contained"
              onClick={() => searchHandler()}
            >
              Search
            </Button>
          </Form>
        </article>

        {/* table container */}
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Kiosk Name</StyledTableCell>
                <StyledTableCell>Kiosk City</StyledTableCell>
                <StyledTableCell>Kiosk Area</StyledTableCell>
                <StyledTableCell>Kiosk Working Hours</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {activationPoints?.results?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.title}</StyledTableCell>
                  <StyledTableCell>{row.city}</StyledTableCell>
                  <StyledTableCell>{row.area}</StyledTableCell>
                  <StyledTableCell style={{ width: "50%" }} colSpan={5}>
                    {row.working_hours}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {activationPoints?.results?.length > 0 && (
          <PaginationComponent
            brands={activationPoints}
            page={page}
            handleChange={(e, value) => setPage(value)}
          />
        )}
      </div>
    </div>
  );
};

export default ActivationPointTool;
