/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Col, Row } from "react-bootstrap";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { lawsuitFields } from "./legalFields";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from "react-router-dom";

const Lawsuit = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const {
    id,
    historyUser_id,
    customerName,
    national_Id,
    request_id,
    user_id,
    total_overdues,
  } = location.state;
  // const { handleLoader, permissions } = useContext(UserContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (request_id) return get_lawsuit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request_id]);

  const get_lawsuit = () => {
    UserUtility.get_lawsuit(request_id, user_id || historyUser_id)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error("get_lawsuit", err);
      });
  };
  const update_lawsuit = () => {
    UserUtility.update_lawsuit(request_id, user_id, data)
      .then(() => {
        navigate("/Main", {
          state: {
            customer_id: national_Id,
          },
        });
      })
      .catch((err) => {
        console.error("update_lawsuit", err);
      });
  };
  const create_lawsuit = () => {
    UserUtility.create_lawsuit(id, data)
      .then(() => {
        navigate("/Main", {
          state: {
            customer_id: national_Id,
          },
        });
      })
      .catch((err) => {
        console.error("create_lawsuit", err);
      });
  };
  const handleSubmit = () => {
    if (request_id) {
      update_lawsuit();
    } else {
      create_lawsuit();
    }
  };
  return (
    <div className="Legal" style={{ textAlign: "right" }} dir="rtl">
      {!historyUser_id && (
        <Form.Label className="fs-4 fw-bold ">إضافة دعوي جديده</Form.Label>
      )}
      <InputGroup>
        <Row>
          {lawsuitFields.map((field, index) =>
            field.type === "date" ? (
              <Col lg={3} md={6} sm={12} key={index} className="mt-2 ">
                <Form.Group
                  className="d-flex flex-column position-relative m-0"
                  style={{ zIndex: 10 }}
                >
                  <Form.Label>{field.label}</Form.Label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      readOnly={historyUser_id}
                      inputFormat="dd/MM/yyyy"
                      value={
                        data[field.name] ? new Date(data[field.name]) : null
                      }
                      onChange={(e) =>
                        setData({
                          ...data,
                          [field.name]: e
                            ? moment(e).format("YYYY-MM-DD")
                            : null,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Form.Group>
              </Col>
            ) : field.type === "space" ? (
              <Col lg={6} sm={12} className="mt-2 " key={index} />
            ) : (
              <Col lg={3} md={6} sm={12} className="mt-2 " key={index}>
                <Form.Label>{field.label}</Form.Label>
                <Form.Control
                  disabled={historyUser_id ? true : field.disabled}
                  value={
                    field.name === "customer_name"
                      ? customerName || data[field.name]
                      : field.name === "customer_national_id"
                      ? national_Id || data[field.name]
                      : field.name === "total_overdues"
                      ? total_overdues || data[field.name]
                      : data[field.name]
                  }
                  name="value"
                  onChange={(e) =>
                    setData({ ...data, [field.name]: e.target.value })
                  }
                  type="text"
                  className="shadow rounded"
                  // required
                />
              </Col>
            )
          )}
        </Row>
      </InputGroup>
      {!historyUser_id && (
        <section className="w-25 mx-auto ">
          <Button
            variant="success"
            onClick={() => handleSubmit()}
            className="w-100 mt-5"
            // disabled={loading}
          >
            حفظ
          </Button>
        </section>
      )}
    </div>
  );
};

export default Lawsuit;
