import "./otp_card.scss";

export default function OtpCard(params) {
  const { otp = "X", otp_type = "X", date_sent = "X" } = params.otpData;
  return (
    <div className="otp_card_container">
      <span className="otp_card_span">Phone Number: {params?.phoneNumber}</span>
      <span className="otp_card_span">Otp: {otp}</span>
      <span className="otp_card_span">Otp type: {otp_type}</span>
      <span className="otp_card_span">Date sent: {date_sent}</span>
    </div>
  );
}
