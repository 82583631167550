import { useState } from "react";
import OtpCard from "../../components/otpCard/otp_card";
import "./otp.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { UserUtility } from "../../apis/UserUtility";
import {toast } from "react-toastify";

export default function Otp(params) {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const handleSubmit = async (e) => {
    let number = `${value}`;
    e.preventDefault();
    setIsLoading(true);
    UserUtility.searchOtp(number)
      .then((res) => {
        setOtpData(res.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error(error[0]);
      });
  };
  const submitOtpCard = otpData?.map((cardData) => {
    return <OtpCard otpData={cardData} phoneNumber={value} />;
  });
  return (
    <div className="otp_container">
      <div className="otp_header">
        <h1 className="otp_header_text">Otp Tool</h1>
        <form className="searchBar_wrapper" onSubmit={handleSubmit}>
          <TextField
            label="Phone Number"
            type="number"
            size="small"
            placeholder="012xxxxxx"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setOtpData(null);
            }}
            style={{ width: "60%" }}
          />
          <Button
            className="searchButton"
            type="submit"
            variant="contained"
            disabled={value.length !== 11 || isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </form>
      </div>
      {submitOtpCard}
    </div>
  );
}
