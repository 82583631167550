import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import {
  textFieldsAddress,
  textFieldsFinancial,
  textFieldsPerson,
} from "./ApplicationData";
import { ENDPOINTS } from "../../apis/EndPoints";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { DocumentsComponent } from "./DocumentsComponent";
import OneTransactionContext from "../../hooks/oneTransactionContext";
import { DataEntryApplication } from "../../pages/OnTransactionScreen/DataEntryApplication";
import { customStyles } from "../../constants/Colors";
import { GoAlertFill } from "react-icons/go";
/*
Component Notes: 
1- All fields will disabled if:
    a- user exist in forsa users 
    b- call component form  ("credit" || "Operation" || "Finance")
    c- isViewOnly => permission ("view_onetransactionprofile") not edit or add

2- Type of user
  a- user already exist in forsa
  b- new user 
  c- user already exist in one transaction profile     
*/
// modal
const userValidation = Yup.object().shape({
  //personal data
  first_name: Yup.string()
    .required("*This field is required.")
    .matches(/^[^a-zA-Z]*$/, "Arabic letters only"),
  second_name: Yup.string()
    .required("*This field is required.")
    .matches(/^[^a-zA-Z]*$/, "Arabic letters only"),
  third_name: Yup.string()
    .required("*This field is required.")
    .matches(/^[^a-zA-Z]*$/, "Arabic letters only"),
  last_name: Yup.string()
    .required("*This field is required.")
    .matches(/^[^a-zA-Z]*$/, "Arabic letters only"),

  gender: Yup.string().required("*This field is required."),
  email: Yup.string().required("*This field is required."),
  phone: Yup.number().required("*This field is required."),
  // .test('len', 'Must be exactly 11 characters', val => val?.toString().length === 10),
  additional_phone: Yup.number(),
  emergency_contact: Yup.number().required("*This field is required."),
  // .test('len', 'Must be exactly 11 characters', val => val?.toString().length === 10),
  emergency_contact_name: Yup.string().required("*This field is required."),
  age: Yup.number().required("*This field is required."),
  marital_status: Yup.string().required("*This field is required."),
  no_independent: Yup.number().required("*This field is required."),
  national_id: Yup.string()
    .required("*This field is required.")
    .matches(/^\d+$/, "Must be digit")
    .min(14, `National ID must be 14 Number`)
    .max(14, `National ID must be 14 Number`),
  // address info
  governorate: Yup.string().required("*This field is required."),
  area: Yup.string().required("*This field is required."),
  home_address: Yup.string().required("*This field is required."),
  home_address_2: Yup.string(),
  home_type: Yup.string(),
  home_address_duration: Yup.string(),

  //financial info
  occupation: Yup.string().required("*This field is required."),
  company_name: Yup.string().when("occupation", {
    is: (occupation) =>
      typeof occupation === "string" &&
      (occupation == "N/A" ||
        occupation == "Unemployed" ||
        occupation == "Housewife"),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("*This field is required.").nullable(),
  }),

  company_contact: Yup.string().when("occupation", {
    is: (occupation) =>
      typeof occupation === "string" &&
      (occupation == "N/A" ||
        occupation == "Unemployed" ||
        occupation == "Housewife"),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("*This field is required.").nullable(),
  }),

  // .test('len', 'Must be exactly 11 characters', val => val?.toString().length === 10),
  company_type: Yup.string(),
  annual_income: Yup.number().required("*This field is required."),
  net_monthly_income: Yup.number().required("*This field is required."),
  company_address: Yup.string(),
});
export const ApplicationFormComponent = ({
  fromPage,
  transactionData,
  creditFormData,
  loan,
  showHistory,
}) => {
  const location = useLocation();
  const [editReturnReason, setEditReturnReason] = useState(false);
  const history = location.state?.history;
  const userData = location.state?.userData;
  console.log("userData", userData);

  useEffect(() => {
    setData(transactionData ? transactionData : userData);
  }, [transactionData, userData]);

  const { handleLoader, permissions = [] } = useContext(UserContext);
  const { oneTransactionOptionResult } = useContext(OneTransactionContext);
  const nationalIdEdit = permissions.includes(
    "one_transaction_change_national_id"
  );
  const phoneNumberEdit = permissions.includes(
    "one_transaction_change_phone_number"
  );
  const isViewOnly =
    permissions.includes("view_onetransactionprofile") &&
    !permissions.includes("add_onetransactionprofile") &&
    !permissions.includes("change_onetransactionprofile")
      ? true
      : false;
  const isDisabled = fromPage === "credit" || isViewOnly ? true : false;
  let navigate = useNavigate();
  const [existUser, setExistUser] = useState(false);
  const [isSpecialDisabled, setIsSpecialDisable] = useState(false);
  const [isPhoneDisabled, setIsPhoneDisable] = useState(false);
  const [nationalId, setNationalId] = useState();
  const [data, setData] = useState(userData);

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [button, setButton] = useState();
  const [homeTypes, setHomeTypes] = useState([]);
  const [homeAddressDuration, setHomeAddressDuration] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [genders, setGenders] = useState([]);
  const [maritalStatusArr, setMaritalStatusArr] = useState([]);

  const [selectedGovernorate, setSelectedGovernorate] = useState();
  const [isEditableProfile, setIsEditableProfile] = useState();

  const [fileUploaded, setFileUploaded] = useState();
  const [userId, setUserId] = useState();
  const [profileId, setProfileId] = useState();

  const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false);

  const [noDepend, setNoDepend] = useState();
  const [selectedMarital, setSelectedMarital] = useState();
  // const [hasLoan, setHasLoan] = useState(false);
  const searchUser = () => {
    UserUtility.oneTransGetProfile(nationalId)
      .then((res) => {
        if (res.count > 0) {
          setData(res.results[0]);
          setIsPhoneDisable(true);
          checkCountryCode(res.results[0]);
          setNoDepend(
            res.results[0].no_independent ? res.results[0].no_independent : "0"
          );
          setSelectedGovernorate(res.results[0].governorate);
        } else {
          setData([]);
          setIsPhoneDisable(false);
        }
      })
      .catch((err) => {
        console.error("searchUser: ", err);
        setData([]);
        if (err?.national_id) {
          toast.error(err?.national_id[0]);
        } else {
          toast.error(err);
        }
      });
  };

  const profileOptions = (result) => {
    setMaritalStatusArr(result?.marital_status?.choices);
    setGenders(result?.gender?.choices);
    setHomeTypes(result?.home_type?.choices);
    setHomeAddressDuration(result?.home_address_duration?.choices);
    setCompanyTypes(result?.company_type?.choices);
    setOccupations(result?.occupation?.choices);
  };

  const getCities = () => {
    UserUtility.oneTransCities().then((res) => {
      setCities(res);
    });
  };

  const getAreas = (city) => {
    UserUtility.oneTransAreas(
      city ? city : selectedGovernorate || data?.governorate
    ).then((res) => {
      setAreas(res);
    });
  };

  // change selected city to call area api
  const handleChangeCity = (e, setFieldValue) => {
    setSelectedGovernorate(e.target.value);
    setFieldValue("area", "");
    getAreas(e.target.value);
  };

  const handleChangeMarital = (e, setFieldValue) => {
    setSelectedMarital(e.target.value);
    if (e.target.value === "Single" || e.target.value === "single") {
      setNoDepend("0");
      setFieldValue("no_independent", "0");
    } else {
      setNoDepend("");
      setFieldValue("no_independent", "");
    }
  };

  const switchSelectControl = (fieldName) => {
    switch (fieldName) {
      case "marital_status":
        return (
          <>
            <option value="" selected disabled>
              Select status
            </option>
            {maritalStatusArr &&
              maritalStatusArr.map((item, index) => {
                return (
                  <option value={item.value} key={index.toString()}>
                    {item.display_name}
                  </option>
                );
              })}
          </>
        );

      case "gender":
        return (
          <>
            <option value="" selected disabled>
              Select gender
            </option>
            {genders &&
              genders.map((item, index) => {
                return (
                  <option value={item.value} key={index.toString()}>
                    {item.display_name}
                  </option>
                );
              })}
          </>
        );

      case "governorate":
        return (
          <>
            <option value="" selected disabled>
              Select city
            </option>
            {cities.map((item, index) => {
              return (
                <option value={item.value} key={index.toString()}>
                  {item.value}
                </option>
              );
            })}
          </>
        );

      case "area":
        return (
          <>
            <option value="" selected disabled>
              Select area
            </option>
            {areas.map((item, index) => {
              return (
                <option value={item.value} key={index.toString()}>
                  {item.value}
                </option>
              );
            })}
          </>
        );

      case "home_type":
        return (
          <>
            <option value="" selected disabled>
              Select type
            </option>
            {homeTypes &&
              homeTypes.map((item, index) => {
                return (
                  <option value={item.value} key={index.toString()}>
                    {item.display_name}
                  </option>
                );
              })}
          </>
        );

      case "home_address_duration":
        return (
          <>
            <option value="" selected disabled>
              Select type
            </option>
            {homeAddressDuration &&
              homeAddressDuration.map((item, index) => {
                return (
                  <option value={item.value} key={index.toString()}>
                    {item.display_name}
                  </option>
                );
              })}
          </>
        );

      case "occupation":
        return (
          <>
            <option value="" selected disabled>
              Select occupation
            </option>
            {occupations &&
              occupations.map((item, index) => {
                return (
                  <option value={item.value} key={index.toString()}>
                    {item.display_name}
                  </option>
                );
              })}
          </>
        );

      case "company_type":
        return (
          <>
            <option value="" selected disabled>
              Company type
            </option>
            {companyTypes &&
              companyTypes.map((item, index) => {
                return (
                  <option value={item.value} key={index.toString()}>
                    {item.display_name}
                  </option>
                );
              })}
          </>
        );

      default:
        break;
    }
  };

  const sendToBE = (values) => {
    values.national_id = nationalId;
    values.governorate = selectedGovernorate;
    values.marital_status = selectedMarital;
    values.no_independent = noDepend;
    checkCountryCode(values);
    // console.log('valueees: ', values)
    // console.log('selectedGovernorate:: ',selectedGovernorate)
    createNewOrEditProfile(values);
  };

  // To create new profile or edit in case press on "Submit" btn will edit profile And press on "Save" btn will create profile or edit if userId exist
  const createNewOrEditProfile = (values) => {
    handleLoader(true);
    const method = button === "save" && !userData?.id ? "POST" : "PATCH";
    values["type"] = button;
    UserUtility.oneTransCreateOrEditProfile(
      values,
      method,
      method === "PATCH" ? profileId : ""
    )
      .then((res) => {
        handleLoader(false);
        console.log("res of createOffer: ", res);
        setUserId(res.user);
        setProfileId(res.id);
        setData(res);
        if (method === "PATCH") {
          if (button === "submit") {
            toast.success("Data has been sent successfully");
          } else {
            toast.success("Data has been changed successfully");
          }
        } else {
          toast.success("Data has been added successfully");
        }
        setTimeout(() => {
          navigate(-1);
        }, 500);
      })
      .catch((err) => {
        handleLoader(false);
        console.log("err of oneTransCreateProfile: ", err);
        if (err.includes("non_field_errors")) {
          toast.error(err);
        } else {
          toast.error(err);
        }
        /*
         for (const [key, value] of Object.entries(err)) {
           let final = (key + "-").concat(value);
           toast.error(final);
         }
         */
      });
  };

  const uploadDocuments = (event) => {
    console.log("uploadDocuments file: ", event.target.files[0]);
    var token = localStorage.getItem("tools_token");
    var formdata = new FormData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    formdata.append("user", userId);
    formdata.append("name", event.target.files[0].name);
    formdata.append("file", event.target.files[0]);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ENDPOINTS.url}onetransaction/userdocuments/`, requestOptions)
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.json();
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          toast.error(text.detail || text[0] || "something went wrong");
          // throw new Error(text);
        } else {
          // navigate(-1);
          toast.success("File has been saved successfully");
          setFileUploaded("");
          event.target.value = null;
          return res.json();
        }
      })
      .catch((err) => {
        console.error("error editing on imagessss", err);
      });
  };

  const checkCountryCode = (values) => {
    // phone
    if (values.phone) {
      let checkPhone = values.phone.includes("+2");
      if (!checkPhone) {
        values.phone = "+2" + values.phone;
      }
    }

    // additional phone
    if (values.additional_phone) {
      let checkAdditPhone = values.additional_phone.includes("+2");
      if (!checkAdditPhone) {
        values.additional_phone = "+2" + values.additional_phone;
      }
    }

    // emergency contact
    if (values.emergency_contact) {
      let checkEmergPhone = values.emergency_contact.includes("+2");
      if (!checkEmergPhone) {
        values.emergency_contact = "+2" + values.emergency_contact;
      }
    }

    // company contact
    if (values.company_contact) {
      let checkCompanyPhone = values.company_contact.includes("+2");
      if (!checkCompanyPhone) {
        values.company_contact = "+2" + values.company_contact;
      }
    }
  };

  const handleResolve = (commentID, loanId) => {
    let obj = {
      status: "profile_resolved",
      resolved_comment: commentID,
    };
    // let loanId =
    //   data?.profile_comments?.length > 0 && data?.profile_comments[0].loan;
    UserUtility.oneTransEditCredit(loanId, obj)
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
        console.error("oneTransEditCredit err: ", err);
        if (err?.detail) {
          toast.error("Change transaction " + err?.detail);
        } else if (err?.non_field_errors) {
          toast.error(err?.non_field_errors[0]);
        } else {
          toast.error(err);
        }
      });
  };

  const gender = nationalId?.substring(12, 13);
  const oddNumbers = ["1", "3", "5", "7", "9"];
  const century = nationalId?.substring(0, 1);
  const userAge = nationalId?.substring(1, 3);
  const d = new Date();
  const year = d.getFullYear();
  const current = century === "2" ? `${19 + userAge}` : `${20 + userAge}`;
  const userYear = year - current;

  useEffect(() => {
    if (userData?.id || transactionData?.id) {
      let comingData = userData || transactionData;
      setUserId(comingData?.user);
      setProfileId(comingData?.id);
      setNationalId(comingData?.national_id);
      setIsSpecialDisable(true);
      setSelectedGovernorate(comingData?.governorate);
      setSelectedMarital(comingData.marital_status);
      setNoDepend(comingData?.no_independent || "0");
      getAreas(comingData?.governorate);
      checkCountryCode(comingData);
      setIsPhoneDisable(true);

      // can edit on application in case no loans or status return_data_entry
      console.log("data.is_profile_editable: ", data);
      if (data?.no_of_loans == 0) {
        console.log("no loans");
        setIsEditableProfile(false);
      } else if (data?.return_status == "profile_resolved") {
        setIsEditableProfile(true);
      } else {
        console.log("others loans");
        setIsEditableProfile(!data?.is_profile_editable);
      }
    }

    if (fromPage === "credit") {
      setIsPhoneDisable(true);
      setData(transactionData);
      setIsEditableProfile(true);
    }
    getCities();
  }, []);

  useEffect(() => {
    if (data?.governorate) return getAreas();
    if (permissions.includes("one_transaction_change_forsa_user")) {
      setExistUser(false);
    } else if (data?.p_state?.length) {
      setExistUser(true);
    } else if (data?.p_state?.length === undefined) {
      setExistUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id, permissions]);
  // in case refresh url
  useEffect(() => {
    profileOptions(oneTransactionOptionResult);
  }, [oneTransactionOptionResult]);
  const editReturn = userData?.profile_comments?.map((ele) => {
    return ele.return_type;
  });
  // console.log("editReturn",editReturn.includes("profile_issue"))
  useEffect(() => {
    if (editReturn?.includes("missing_document")) {
      setEditReturnReason(true);
    }
  }, [editReturn]);
  console.log("EditReturnReason", editReturnReason);
  return (
    <main className="application_main">
      <Formik
        initialValues={{
          national_id: nationalId || "",
          first_name: data?.first_name || "",
          second_name: data?.second_name || "",
          third_name: data?.third_name || "",
          last_name: data?.last_name || "",
          phone: data?.phone || "",
          additional_phone: data?.additional_phone || "",
          age: data?.age || (data && userYear) || "",
          gender:
            data?.gender ||
            (data && gender
              ? oddNumbers.includes(gender)
                ? "Male"
                : "Female"
              : ""),
          marital_status: data?.marital_status || "",
          emergency_contact: data?.emergency_contact || "",
          emergency_contact_name: data?.emergency_contact_name || "",
          email: data?.email || "",
          governorate: data?.governorate || "",
          area: data?.area || "",
          home_type: data?.home_type || "",
          home_address: data?.home_address || "",
          home_address_2: data?.home_address_2 || "",
          home_address_duration: data?.home_address_duration || "",
          occupation: data?.occupation || "",
          company_name: data?.company_name || "",
          company_type: data?.company_type || "",
          company_contact: data?.company_contact || "",
          annual_income: data?.annual_income || "",
          net_monthly_income: data?.net_monthly_income || "",
          no_independent:
            data?.no_independent !== null && data?.no_independent !== ""
              ? data?.no_independent
              : "0",
          company_address: data?.company_address || "",
        }}
        validationSchema={userValidation}
        enableReinitialize={true}
        onSubmit={(values) => sendToBE(values)}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleBlur,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {userData?.profile_comments?.map((ele, index) => {
                return (
                  <div
                    className="row row_div"
                    style={{
                      backgroundColor: "#e8f2cf",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingBlock: 10,
                      width: "94%",
                    }}
                  >
                    <div style={{ width: "3%" }}>
                      <GoAlertFill color="#e2c65c" size={20} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <p
                        style={{
                          color: "#6d5c28",
                          fontWeight: "bold",
                          margin: 0,
                        }}
                      >
                        {ele?.return_type}
                      </p>
                      <p
                        style={{
                          color: "#6d5c28",
                          margin: 0,
                        }}
                      >
                        {ele?.comment}
                      </p>
                      {/* <Form.Control
                          as="input"
                          value={}
                          disabled
                          style={{
                            borderColor: "transparent",
                            backgroundColor: "transparent",
                            color: "#6d5c28",
                          }}
                        /> */}
                    </div>
                    <div style={{ width: "10%" }}>
                      <Button
                        className="small_btn"
                        type="button"
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderRadius: 5,
                          borderColor: "#e3cc6a",
                          width: "100px",
                          height: "10px",
                        }}
                        // variant="contained"
                        disabled={isDisabled}
                        onClick={() => handleResolve(ele.id, ele.loan)}
                      >
                        Resolve
                      </Button>
                    </div>
                  </div>
                );
              })}

              <h4 className="subTitle">Personal Info</h4>

              {/* national id for check if user already exist in forsa users to get information or not */}
              <div className="row row_div">
                <Form.Group className="mb-3 col-md-3" controlId="formBasic">
                  <Form.Label>National ID:</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={
                      nationalIdEdit
                        ? false
                        : isDisabled || isSpecialDisabled || editReturnReason
                    }
                    name="national_id"
                    value={nationalId || transactionData?.national_id}
                    onChange={(e) => {
                      setNationalId(e.target.value);
                      // setData()
                      setFieldValue("national_id", e.target.value);
                    }}
                    isInvalid={!!errors.national_id}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.national_id}
                  </Form.Control.Feedback>
                </Form.Group>
                {!creditFormData && (
                  <div className="mb-3 col-md-3 align-self-end">
                    <Button
                      className="small_btn"
                      type="button"
                      variant="contained"
                      onClick={() => searchUser()}
                      disabled={
                        isDisabled || editReturnReason || userData?.id
                          ? true
                          : false
                      }
                    >
                      Search
                    </Button>
                  </div>
                )}

                <div className="mb-3 col-md-3 align-self-end">
                  <h6>
                    User Type:{" "}
                    {data ? (data.p_state ? "Existing User" : "New User") : "-"}
                  </h6>
                </div>

                {data?.p_state && (
                  <div className="mb-3 col-md-3 align-self-end">
                    <h6>Forsa State: {data?.p_state}</h6>
                  </div>
                )}
              </div>

              <div className="row row_div">
                {textFieldsPerson.map((field, index) => (
                  <Form.Group
                    className="mb-3 col-md-3"
                    controlId="formBasic"
                    key={index.toString()}
                  >
                    <Form.Label>{field.label}</Form.Label>
                    {field.isDropDown ? (
                      <Form.Select
                        id="disabledSelect"
                        disabled={
                          isEditableProfile || isDisabled || editReturnReason
                        }
                        value={
                          // field.name==="gender"?data&&!data?.p_state&&gender?oddNumbers.includes(gender)?"Male":"Female":values[field.name]:
                          field.name === "marital_status"
                            ? selectedMarital || values?.marital_status
                            : values[field.name]
                        }
                        onChange={(e) => {
                          if (field.name === "marital_status") {
                            setFieldValue(field.name, e.target.value);
                            handleChangeMarital(e, setFieldValue);
                          } else {
                            setFieldValue(field.name, e.target.value);
                          }
                        }}
                        isInvalid={!!errors[field.name]}
                      >
                        {switchSelectControl(field.name)}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        type="text"
                        disabled={
                          editReturnReason ||
                          isEditableProfile ||
                          isDisabled ||
                          (field.name === "phone"
                            ? phoneNumberEdit === true
                              ? false
                              : isPhoneDisabled
                            : false)
                        }
                        maxLength={field.type === "number" ? 11 : ""}
                        minLength={field.type === "number" ? 11 : ""}
                        name={field.name}
                        value={
                          // field.name==="age"?data&&!data?.p_state&&nationalId?userYear:values[field.name]:
                          field.type === "number"
                            ? values[field.name].replace("+2", "")
                            : field.name === "no_independent"
                            ? noDepend
                            : values[field.name]
                        }
                        onChange={(e) => {
                          if (field.name === "no_independent") {
                            setNoDepend(e.target.value);
                            setFieldValue("no_independent", e.target.value);
                          } else {
                            setFieldValue(field.name, e.target.value);
                          }
                        }}
                        isInvalid={!!errors[field.name]}
                      />
                    )}

                    <Form.Control.Feedback type="invalid">
                      {errors[field.name]}
                    </Form.Control.Feedback>
                  </Form.Group>
                ))}
              </div>
              <h4 className="subTitle">Address</h4>

              <div className="row row_div">
                {textFieldsAddress.map((field, index) => (
                  <Form.Group
                    className="mb-3 col-md-3"
                    controlId="formBasic"
                    key={index.toString()}
                  >
                    <Form.Label>{field.label}</Form.Label>
                    {field.isDropDown ? (
                      <Form.Select
                        id="disabledSelect"
                        disabled={
                          isEditableProfile || isDisabled || editReturnReason
                        }
                        value={
                          field.name === "governorate"
                            ? selectedGovernorate || values?.governorate
                            : values[field.name]
                        }
                        onChange={(e) => {
                          if (field.name === "governorate") {
                            setFieldValue(field.name, e.target.value);
                            handleChangeCity(e, setFieldValue);
                          } else {
                            setFieldValue(field.name, e.target.value);
                          }
                        }}
                        isInvalid={!!errors[field.name]}
                      >
                        {switchSelectControl(field.name)}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        type="text"
                        disabled={
                          isEditableProfile || isDisabled || editReturnReason
                        }
                        name={field.name}
                        value={values[field.name]}
                        onChange={(e) =>
                          setFieldValue(field.name, e.target.value)
                        }
                        isInvalid={!!errors[field.name]}
                      />
                    )}

                    <Form.Control.Feedback
                      type="invalid"
                      className="error_message"
                    >
                      {errors[field.name]}
                    </Form.Control.Feedback>
                  </Form.Group>
                ))}
              </div>

              <h4 className="subTitle">Financial info</h4>
              <div className="row row_div">
                {textFieldsFinancial.map((field, index) => (
                  <Form.Group
                    className="mb-3 col-md-3"
                    controlId="formBasic"
                    key={index.toString()}
                  >
                    <Form.Label>{field.label}</Form.Label>
                    {field.isDropDown ? (
                      <Form.Select
                        id="disabledSelect"
                        disabled={
                          isEditableProfile || isDisabled || editReturnReason
                        }
                        value={values[field.name]}
                        onChange={(e) =>
                          setFieldValue(field.name, e.target.value)
                        }
                        isInvalid={!!errors[field.name]}
                      >
                        {switchSelectControl(field.name)}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        type="text"
                        disabled={
                          isEditableProfile || isDisabled || editReturnReason
                        }
                        name={field.name}
                        value={
                          field.type === "number"
                            ? values[field.name].replace("+2", "")
                            : values[field.name]
                        }
                        onChange={(e) =>
                          setFieldValue(field.name, e.target.value)
                        }
                        isInvalid={!!errors[field.name]}
                      />
                    )}

                    <Form.Control.Feedback
                      type="invalid"
                      className="error_message"
                    >
                      {errors[field.name]}
                    </Form.Control.Feedback>
                  </Form.Group>
                ))}
              </div>

              {/* Eighth row */}
              <div className="row row_div">
                <Form.Group controlId="formBasic" className="mb-3 col-md-3">
                  <Form.Label>Upload document:</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={
                      isEditableProfile || isDisabled || isViewOnly || !userId
                        ? true
                        : false
                    }
                    value={fileUploaded}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formFile"
                  className="mb-3 col-md-6 upload_class"
                >
                  <Form.Control
                    type="file"
                    className="upload_input"
                    disabled={
                      isEditableProfile || isDisabled || isViewOnly || !userId
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setFileUploaded(e.target.files[0].name);
                      uploadDocuments(e);
                    }}
                  />
                  {!creditFormData && (
                    <div className="upload_btn" type="upload">
                      Upload
                    </div>
                  )}
                </Form.Group>

                <div className="col-md-4 align-self-end">
                  <Button
                    variant="link"
                    className="link_btn align-self-end"
                    onClick={() => setIsOpenDocumentModal(true)}
                  >
                    {" "}
                    Show documents
                  </Button>
                </div>
              </div>
              {userData?.id ? (
                <></>
              ) : (
                <p style={{ fontSize: 12, width: "22%" }}>
                  {" "}
                  * You must save your data then upload documents if available
                </p>
              )}
              {fromPage !== "credit" && !isViewOnly ? (
                <div className="btns_div">
                  {/* only save data */}
                  <Button
                    variant="primary"
                    type="submit"
                    className="addBtn me-4"
                    onClick={() => setButton("save")}
                  >
                    Save
                  </Button>

                  {/* send to oracle */}
                  <Button
                    disabled={!userId || data?.sent_to_drive ? true : false}
                    variant="primary"
                    type="submit"
                    className="addBtn"
                    onClick={() => setButton("submit")}
                  >
                    Submit
                  </Button>

                  <Button
                    variant="primary"
                    type="button"
                    className="outlineBtn"
                    style={{ marginBottom: 20 }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </form>
          </>
        )}
      </Formik>

      {/* modal of all document */}
      <Modal
        isOpen={isOpenDocumentModal}
        onRequestClose={() => setIsOpenDocumentModal(!isOpenDocumentModal)}
        style={customStyles}
        contentLabel="user history"
        shouldCloseOnOverlayClick={true}
      >
        <DocumentsComponent id={profileId || transactionData?.id} />
      </Modal>
      <DataEntryApplication
        profileId={userData?.id}
        history={history}
        user_id={userData?.user}
        creditFormData={creditFormData}
        loan={loan}
        showHistory={showHistory}
      />
    </main>
  );
};
