import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
// import useGetHistory from "../../hooks/useGetHistory";


const getHistory = async (type, ids, pageNumber = 1) => {

    try {
        const result = await UserUtility.getHistory(type, ids, pageNumber);
        return result;
    } catch (err) {
        console.error('err of getHistoryByIds: ', err)
    }

}

const getHistoryById = async (id, type, installmentIds, pageNumber = 1) => {
    console.log('installmentIds: ', installmentIds)
    try {
        const result = await UserUtility.getHistoryById(id, type, installmentIds, pageNumber);
        return result;
    } catch (err) {
        console.error('err of getMerchantHistory: ', err)
    }

}


const MerchantToolHistory = ({
    type = "",
    id = "",
    isState,
    ids = [],
    installmentIds = [],

}) => {


    const [pageNumber, setPagesNumber] = useState(1);
    const [detail, setDetail] = useState(null);
    const [actions, setActions] = useState([]);
    console.log({ actions, detail });
    // console.log('installmentIds get: ', installmentIds)
    // if i found an id i use get history by id 
    // if not i use getHistory 

    const getResults = useCallback(async () => {
        try {
            if (id) {
                return await getHistoryById(id, type, installmentIds, pageNumber)
            }
            return await getHistory(type, ids, pageNumber)
        } catch (error) {
            console.error({ error })
        }
    }, [pageNumber, type, id, installmentIds.length, ids.length])

    const extractPaginationDetails = useCallback((historyResult) => {

        const { results, ...rest } = historyResult
        return rest

    }, [])




    const fetch = useCallback(async () => {
        const historyResults = await getResults();
        console.log({ historyResults });
        const historyDetails = extractPaginationDetails(historyResults)
        setActions(historyResults.results)
        setDetail(historyDetails)
    }, [getResults, extractPaginationDetails])

    useEffect(() => {
        if (isState === true) {
            fetch();
        } else {
            setPagesNumber(1)
            setDetail(null);
            setActions([]);

        }
    }, [isState, fetch]);

    const handleChange = (_, value) => {
        setPagesNumber(value);

    };

    return (
        <div>
            <Table striped bordered responsive hover className="tools_table" size="sm">
                <thead>
                    <tr>
                        <th style={{ whiteSpace: 'nowrap' }}>User Phone</th>
                        <th style={{ whiteSpace: 'nowrap' }}>Date</th>
                        <th style={{ whiteSpace: 'nowrap' }}>id</th>
                        <th>Action details</th>

                    </tr>
                </thead>
                <tbody>
                    {actions && actions.map((action, index) => (
                        <tr>
                            <td style={{ whiteSpace: 'nowrap' }}>{action.user_phone || ' - '} </td>
                            <td style={{ whiteSpace: 'nowrap' }}>{moment(action.timestamp).format('lll')}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{action.id}</td>
                            <td>{action.changes}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationComponent
                brands={detail}
                page={pageNumber}
                handleChange={handleChange}

            />
        </div>

    );
};

export default MerchantToolHistory;
