import React from "react";
import "./NewBrands.scss";
import { useLocation, NavLink, Outlet } from "react-router-dom";

const NewBrand = () => {
  const location = useLocation();
  const {
    newBrand,
    brandName = "New brand/Merchant",
    brandId,
  } = location?.state;
  const choosingBar = [
    {
      name: "General Info",
      tag: "GeneralInfo",
      selected: true,
    },
    {
      name: "Categories",
      tag: "Categories",
      selected: false,
    },
    {
      name: "Offers",
      tag: "Offers",
      selected: false,
    },
    {
      name: "Branches",
      tag: "Branches",
      selected: false,
    },
  ];

  const getCurrentState = (bar) => {
    const state =
      bar.tag === "GeneralInfo"
        ? {
          newBrand: newBrand,
          brandId: brandId,
          brandName: brandName,
        }
        : bar.tag === "Categories"
          ? {
            brandName: brandName,
            brandId: brandId,
          }
          : bar.tag === "Offers"
            ? {
              brandName: brandName,
              brandId: brandId,
            }
            : bar.tag === "Branches"
              ? {
                brandId: brandId,
                brandName: brandName,
              }
              : {};

    return state;
  };

  return (
    <article className="newBrands_container">
      <h2>
        Brands / <span>{brandName}</span>
      </h2>
      <section className="choosing_bar">
        <ul className="profile_navBar_wrapper">
          {choosingBar.map((bar, id) => {
            return (
              <li className="list_item" key={id}>
                {(!newBrand || bar.tag === "GeneralInfo") && (
                  <NavLink
                    defaultChecked={bar.tag === "GeneralInfo"}
                    state={getCurrentState(bar)}
                    style={{
                      textDecoration: "none",
                      width: "100%",
                    }}
                    className="navLink"
                    to={bar.tag}
                    end
                  >
                    {bar.name}
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      </section>
      <Outlet />
    </article>
  );
};

export default NewBrand;
