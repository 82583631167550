import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import { Button } from "react-bootstrap";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import moment from "moment/moment";

const LoanHistory = ({ LoanHistoryState }) => {
  let navigate = useNavigate();
  const [userLoans, setLoans] = useState([]);
  const [pagesNumber, setPagesNumber] = useState(1);
  const location = useLocation();
  const [results, setResults] = useState();

  const { userId, profileOptions, blocked, type, manual_state, LimitIncrease } =
    location.state;
  const users_loans = () => {
    if (LoanHistoryState) {
      UserUtility.loan_history(userId, pagesNumber)
        .then((res) => {
          setResults(res);
          setLoans(res);
        })
        .catch((err) => console.error("user history err", err));
    }
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };

  // useEffect(() => {
  //   console.log("userLoans", userLoans);
  // }, [userLoans]);

  useEffect(() => {
    users_loans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoanHistoryState, pagesNumber]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <div className="mb-3">
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 3500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Integration Ref.</StyledTableCell>
              <StyledTableCell>Loan ID</StyledTableCell>
              <StyledTableCell>Gateway Trx Id</StyledTableCell>
              <StyledTableCell>Gateway Order Id</StyledTableCell>
              <StyledTableCell>Refunded</StyledTableCell>
              <StyledTableCell>Refund_blocked</StyledTableCell>
              <StyledTableCell>Product Label</StyledTableCell>
              <StyledTableCell>Down Payment</StyledTableCell>
              <StyledTableCell>Principal</StyledTableCell>
              <StyledTableCell>Total Due Amount</StyledTableCell>
              <StyledTableCell>Cash Back</StyledTableCell>
              <StyledTableCell>Original Amount</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell>Branch</StyledTableCell>
              {/* <StyledTableCell>Created Date</StyledTableCell> */}
              <StyledTableCell>Early Date</StyledTableCell>
              <StyledTableCell>Sent Otp</StyledTableCell>
              <StyledTableCell>1st installment date</StyledTableCell>
              <StyledTableCell>Installment amount</StyledTableCell>
              <StyledTableCell>Approved</StyledTableCell>
              <StyledTableCell>Created date</StyledTableCell>
              <StyledTableCell>End date</StyledTableCell>
              <StyledTableCell>Refund date</StyledTableCell>
              <StyledTableCell>Fees amount</StyledTableCell>
              <StyledTableCell>Tenor</StyledTableCell>
              <StyledTableCell>Interest rate</StyledTableCell>
              <StyledTableCell>Settled</StyledTableCell>
              <StyledTableCell>Statment</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userLoans?.results?.map((loan, id) => (
              <StyledTableRow key={id}>
                <StyledTableCell>{loan.integration_reference}</StyledTableCell>
                <StyledTableCell>{loan.id}</StyledTableCell>
                <StyledTableCell>
                  {loan.transaction_request__gateway_trx_id}
                </StyledTableCell>
                <StyledTableCell>
                  {loan.transaction_request__gateway_order_id}
                </StyledTableCell>
                <StyledTableCell>{`${loan.refunded}`}</StyledTableCell>
                <StyledTableCell>{`${loan.refund_blocked}`}</StyledTableCell>
                <StyledTableCell>{loan.product_label}</StyledTableCell>
                <StyledTableCell>{loan.down_payment}</StyledTableCell>
                <StyledTableCell>{loan.principal}</StyledTableCell>
                <StyledTableCell>{loan.total_due_amount}</StyledTableCell>
                <StyledTableCell>{loan.cashback__awarded}</StyledTableCell>
                <StyledTableCell>{loan.original_amount}</StyledTableCell>
                <StyledTableCell>{loan.category}</StyledTableCell>
                <StyledTableCell>{loan.brand}</StyledTableCell>
                <StyledTableCell>{loan.branch}</StyledTableCell>
                {/* <StyledTableCell>{loan.created}</StyledTableCell> */}
                <StyledTableCell>{loan.early_settlement_date}</StyledTableCell>
                <StyledTableCell>
                  {loan.transaction_request__sent_otp}
                </StyledTableCell>
                <StyledTableCell>{loan.first_payment_date}</StyledTableCell>
                <StyledTableCell>{loan.installment_amount}</StyledTableCell>
                <StyledTableCell>{`${loan.approved}`}</StyledTableCell>
                <StyledTableCell>
                  {loan.created ? formatDate(loan.created) : ""}
                </StyledTableCell>
                <StyledTableCell>
                  {loan.last_payment_date
                    ? formatDate(loan.last_payment_date)
                    : ""}
                </StyledTableCell>
                <StyledTableCell>
                  {loan.refunded_at ? formatDate(loan.refunded_at) : ""}
                </StyledTableCell>
                <StyledTableCell>
                  {loan.transaction_request__admin_fees}
                </StyledTableCell>
                <StyledTableCell>
                  {loan.installment_plan__months}
                </StyledTableCell>
                <StyledTableCell>
                  {loan.installment_plan__interest_rate}
                </StyledTableCell>
                <StyledTableCell>{`${loan.settled}`}</StyledTableCell>
                <StyledTableCell>
                  <Link
                    state={{
                      userId: userId,
                      profileOptions: profileOptions,
                      blocked: blocked,
                      LimitIncrease: LimitIncrease,
                      type: type,
                      manual_state: manual_state,
                      loanId: loan.id,
                    }}
                    to="Statment"
                    className="text-success fw-bolder"
                  >
                    View
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default LoanHistory;
