import React from "react";
import style from "../RemainingInstallments/TableData/TableData.module.scss";
const TableFines = (props) => {
  const allData = props?.allData;
  const tableHeader = [
    { name: "توضيح" },
    { name: "نوع القسط" },
    { name: "عمولة التأخير المحصله" },
    { name: "عمولة التأخير طبقاً لإيام التأخير" },
    { name: "عدد أيام التأخير للقسط  الغير المسدد" },
    { name: "عدد أيام التأخير للقسط المسدد" },
    { name: "المتبقى من القسط" },
    { name: "المبلغ المسدد" },
    { name: "تاريخ السداد" },
    { name: "قيمة القسط" },
    { name: "تاريخ القسط" },
    { name: "رقم القسط" },
  ];

  return (
    <table
      className="table"
      style={{ fontSize: "5px", textAlign: "center", lineHeight: 1 }}
    >
      <thead className={`${style.table_header}`}>
        <tr>
          {tableHeader.map((ele, index) => {
            return (
              <th
                key={index}
                style={{ backgroundColor: "#D9D9D9" }}
                scope="col"
              >
                {ele.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {allData?.map((ele, id) => {
          return (
            <tr key={id} style={{ textAlign: "center" }}>
              {ele.index !== 2 && ele.index !== 3 && (
                <td
                  style={{ width: "15%", lineHeight: 2 }}
                  rowSpan={ele.index === 1 && 3}
                >
                  {ele.index === 1 &&
                    " يستحق عائد تاخير 2% بحد أدني 50جنيه و هذا بالاضافة الى عمولة تأخير بنسبة 1% عن كل يوم تأخير من اليوم التالى لإستحقاق القسط وحتى تمام السداد وذلك فى حال تجاوز الأيام المتأخرة 30 يوماً من تاريخ إستحقاق القسط"}
                </td>
              )}
              <td>تمويل إستهلاكي</td>
              <td>{ele.penalties_paid_amount}</td>
              <td>{ele.penalties_due_amount}</td>
              <td>{ele.dpd}</td>
              <td>{ele.late_days}</td>
              <td>{ele.installments_due_amount}</td>
              <td>{ele.installments_paid_amount}</td>
              <td>{ele.last_payment_date}</td>
              <td>{ele.installments_amount}</td>
              <td>{ele.due_date}</td>
              <td style={{ textAlign: "center" }}>{ele.index}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableFines;
