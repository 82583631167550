/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationFormComponent } from "../../components/OneTransactionComponent/ApplicationFormComponent";
import Modal from "react-modal";
import { LiabilityFormComponent } from "../../components/OneTransactionComponent/LiabilityFormComponent";
import OneTransactionContext from "../../hooks/oneTransactionContext";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { ENDPOINTS } from "../../apis/EndPoints";
import { LoanDocumentComponent } from "../../components/OneTransactionComponent/LoanDocumentComponent";
import { CommonContractComponent } from "./CommonContractComponent";
import { customStyles } from "../../constants/Colors";
import moment from "moment";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiFillInfoCircle,
} from "react-icons/ai";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

export const CreditForm = () => {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  let navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [deviationList, setDeviationList] = useState();
  const [deviationSelected, setDeviationSelected] = useState([]);
  const [deviationIds, setDeviationIds] = useState([]);

  const [isEditableProfile, setIsEditableProfile] = useState(false);
  const [profileComment, setIsProfileComment] = useState();
  const [retunDataEntry, setReturnDataEntry] = useState();
  const loanId = location?.state?.loanId;
  const showHistory = location?.state?.showHistory;
  const isViewOnly = location?.state?.isViewOnly;
  const fromPage = location?.state?.fromPage;
  const [isGetIscore, setIsGetIscore] = useState({
    isValid: false,
    data: null,
    isOpenModal: false,
  });
  const [isGetForsaIscore, setIsGetForsaIscore] = useState({
    data: null,
    isOpenModal: false,
  });
  const [isCreateLiablity, setIsCreateLiablity] = useState(false);
  const { programs, oneTransactionOtherOptionResult } = useContext(
    OneTransactionContext
  );
  const returnTypeOptions =
    oneTransactionOtherOptionResult?.onetransactionloandata?.children
      ?.return_type?.choices;
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [selectedProgram, setSelectedProgram] = useState({
    value: null,
    name: null,
  });
  const [tenors, setTenors] = useState([]);
  const [selectedTenor, setSelectedTenor] = useState({
    value: null,
    months: null,
  });
  const [downPayment, setDownPayment] = useState();
  const [income, setIncome] = useState();
  const [interestRate, setInterestRate] = useState();
  const [returnType, setReturnType] = useState();

  const [membershipAmount, setMembershipAmount] = useState();
  const [makerComment, setMakerComment] = useState({
    oldComment: null,
    comment: null,
    id: null,
  });
  const [checkerComment, setCheckerComment] = useState({
    oldComment: null,
    comment: null,
    id: null,
  });
  const [rejectReason, setRejectReason] = useState({ status: "rejected" });
  // const [fileUploaded, setFileUploaded] = useState();
  const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false);
  //start operation state
  const [paymentData, setPaymentData] = useState();
  const [reason, setReason] = useState({ status: "restudying_credit" });
  const [operationMaker, setOperationMaker] = useState({
    status: "restudying_operation",
  });

  const [financeOptions, setFinanceOptions] = useState([]);
  const [returnToData, setReturnToData] = useState();
  const [deviationReason, setDeviationReason] = useState();
  const [providerServ, setProviderServ] = useState();
  const [serviceInfo, setServiceInfo] = useState();
  const [originalLimit, setOriginalLimit] = useState();

  const isMaker = permissions.includes("one_transaction_credit_maker")
    ? true
    : false;

  const isChecker = permissions.includes("one_transaction_credit_checker")
    ? true
    : false;

  const operation =
    permissions.includes("one_transaction_operation_maker") ||
    permissions.includes("one_transaction_operation_checker")
      ? true
      : false;

  const finance =
    permissions.includes("one_transaction_finance_maker") ||
    permissions.includes("one_transaction_finance_checker")
      ? true
      : false;

  const makerType = "one_transaction_credit_maker";
  const checkerType = "one_transaction_credit_checker";

  const isCheckerOperation =
    permissions.includes("one_transaction_operation_checker") &&
    fromPage === "operationTab";

  const proceedChecker = { status: "approved_operation" };
  const date = Date();
  const todayDate = moment(date)?.format("YYYY-MM-DD");

  //end operation state

  // start finance state
  const isCheckerFinance =
    permissions.includes("one_transaction_finance_checker") &&
    fromPage === "financeTab";
  const isMakerFinance =
    permissions.includes("one_transaction_finance_maker") &&
    fromPage === "financeTab";

  const isEditService = permissions.includes(
    "can_edit_service_info_&_service_provider"
  )
    ? true
    : false;

  const is_returned_profile =
    data?.userprofile?.onetransactionprofile?.return_status ===
      "return_data_entry" &&
    data?.userprofile?.onetransactionprofile?.return_type === "profile_issue"
      ? true
      : false;

  const [MDMIdentifier, setMDMIdentifier] = useState();
  const changeProgram = (value, type) => {
    let findObj = programs?.find((item) => {
      return type === "name" ? item.name == value : item.id == value;
    });
    if (findObj) {
      setSelectedProgram({ value: findObj.id, name: findObj.name });
      setInterestRate(findObj.interest_rate);
      setTenors([]);

      // if changed program empty selected tenor
      if (type === "id") {
        setSelectedTenor({ value: null, months: null });
        setServiceInfo("");
        setProviderServ("");
      }
      getProgramTenors(findObj.id);
    }
  };

  const getLoanData = () => {
    UserUtility.getLoanData(loanId)
      .then((res) => {
        console.log("reeeeeees: ", res);
        setData(res);
      })
      .catch((err) => {
        console.log("getLoanData: ", err);
      });
  };

  // get all tenors of selected program
  const getProgramTenors = (programId) => {
    UserUtility.oneTransTenors(programId)
      .then((res) => {
        setTenors(res.results);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((err) => {
        console.error("error of get tenors: ", err);
        if (err?.detail) {
          toast.error("Tenors " + err?.detail);
        }
      });
  };

  const handleCalculateOrEdit = (status, isBack = false) => {
    let obj = {
      user: data?.userprofile?.onetransactionprofile?.user,
      program: selectedProgram.value,
      product_label: selectedProgram.name,
      tenure: selectedTenor.value,
      principal: membershipAmount,
      down_payment: downPayment,
      profile_income: income,
      return_type: returnType,
      status:
        deviationIds.length > 0 && status === "studying_credit"
          ? "restudying_credit"
          : // : data?.onetransactionloandata?.status === "created" &&
          //   !selectedTenor.value
          // ? "created"
          retunDataEntry
          ? "return_data_entry"
          : status,
      deviation: deviationIds.join(),
      profile_editable_flag: isEditableProfile,
      service_provider: providerServ,
      service_info: serviceInfo,
      original_amount: originalLimit,
    };

    if (retunDataEntry) {
      obj["profile_comment"] = profileComment;
      obj["return_type"] = returnType;
    } else {
      delete obj["profile_comment"];
      delete obj["return_type"];
    }
    if (retunDataEntry && status === "checking_credit") {
      toast.info("Please UnCheck Return To Data Entry");
    } else {
      // if exist comment from maker call endpoint maker in case click on save btn only
      if (
        (isMaker &&
          makerComment.comment &&
          makerComment.comment?.trim() != makerComment.oldComment?.trim()) ||
        (isChecker &&
          checkerComment.comment &&
          checkerComment.comment?.trim() != checkerComment.oldComment?.trim())
      ) {
        handlAddOrEditComment();
      }

      UserUtility.oneTransEditCredit(data.id, obj)
        .then((res) => {
          setData(res);
          if (isBack) {
            navigate(-1);
          }
        })
        .catch((err) => {
          console.error("oneTransEditCredit err: ", err);
          if (err?.detail) {
            toast.error("Change transaction " + err?.detail);
          } else if (err?.non_field_errors) {
            toast.error(err?.non_field_errors[0]);
          } else {
            toast.error(err);
          }
        });
    }
  };

  const handleReject = () => {
    if (retunDataEntry) {
      toast.info("Please UnCheck Return To Data Entry");
    } else {
      if (rejectReason?.reason?.length) {
        UserUtility.oneTransEditCredit(data.id, rejectReason)
          .then(() => {
            navigate(-1);
          })
          .catch((err) => {
            console.error("oneTransEditCredit err: ", err);
            if (err?.detail) {
              toast.error("Change transaction " + err?.detail);
            } else {
              toast.error(err);
            }
          })
          .finally(() => {
            // if exist comment from maker call endpoint maker
            if (
              isMaker &&
              makerComment.comment &&
              makerComment.comment.trim() != makerComment.oldComment.trim()
            ) {
              handlAddOrEditComment();
            }
          });
      } else {
        toast.error("Reject reason field is empty");
      }
    }
  };

  const handleChangeTenor = (value, type) => {
    let findObj =
      tenors?.length &&
      tenors?.find((item) => {
        return type === "months" ? item.months == value : item.id == value;
      });

    if (findObj) {
      setSelectedTenor({ value: findObj.id, months: findObj.months });
    }
  };

  // to check if all required input is fill to active getIscore btn and all button or not
  const checkBtnDisabled = () => {
    if (
      data?.onetransactionloandata?.status === "created" &&
      isEditableProfile
    ) {
      return false;
    } else {
      if (
        income &&
        selectedProgram.value &&
        selectedTenor.value &&
        membershipAmount &&
        providerServ
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleOpenIscore = (callType) => {
    if (callType === "new") {
      setIsGetIscore({
        ...isGetIscore,
        isValid: true,
      });
    }
    let params = `?loan=${data.id}`;
    params = makerType ? `${params}&type=${callType}` : params;
    window.open(
      `${ENDPOINTS.url}onetransaction/user/pdfreport/${params}`,
      "_blank"
    );
  };

  const handleOpenForsaIscore = () => {
    window.open(
      `${ENDPOINTS.url}onetransaction/user/pdfreportforsa/?loan=${data.id}`,
      "_blank"
    );
  };

  /*
  // with new requirement to open iscor in new tab can't use these functions
  
  // create iscore
  const getIscoreHandle = (callType, isViewOnly) => {
    handleLoader(true);
    var token = localStorage.getItem("tools_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let params = `?loan=${data.id}`;
    params = makerType ? `${params}&type=${callType}` : params;
    fetch(
      `${ENDPOINTS.url}onetransaction/user/pdfreport/${params}`,
      requestOptions
    )
      .then(async (res) => {
        handleLoader(false);
        if (!res.ok) {
          const text = await res.json();
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          toast.error(text?.detail || text[0] || "something went wrong");
        } else {
          const text2 = await res.text();
          toast.success("iscore got successfully");
          setIsGetIscore({
            isValid: true,
            data: text2,
            isOpenModal: callType === "old" ? true : false,
          });

          // call to update status
          if (!isViewOnly) ChangetransactionStatus("studying_credit");
          return res.text();
        }
      })
      .catch((err) => {
        console.error("error editing on imagessss", err);
        toast.error(
          err?.detail ||
            err?.non_field_errors[0] ||
            err[0] ||
            "something went wrong"
        );
      });
  };

  // to get forsa iscore if profile exist in forsa
  const getForsaIscoreHandle = () => {
    handleLoader(true);
    var token = localStorage.getItem("tools_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${ENDPOINTS.url}onetransaction/user/pdfreportforsa/?loan=${data.id}`,
      requestOptions
    )
      .then(async (res) => {
        handleLoader(false);
        if (!res.ok) {
          const text = await res.json();
          console.log("text errror: ", text);
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          toast.error(
            text?.errors?.non_field_errors[0] ||
              text?.non_field_errors[0] ||
              text[0] ||
              "something went wrong"
          );
        } else {
          const text2 = await res.text();
          toast.success("Forsa iscore got successfully");
          setIsGetForsaIscore({
            data: text2,
            isOpenModal: true,
          });

          return res.text();
        }
      })
      .catch((err) => {
        console.error("error editing on imagessss", err);
      });
  };

  */
  // change transaction status
  const ChangetransactionStatus = (status, isBack = false) => {
    let obj = {
      user: data?.userprofile?.onetransactionprofile?.user,
      status: status,
    };

    UserUtility.oneTransEditCredit(data.id, obj)
      .then((res) => {
        setData(res);
        if (isBack) {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.error("ChangetransactionStatus err: ", err);
        if (err?.detail) {
          toast.error("Change Transaction " + err?.detail);
        }
      });
  };

  // add comment operation (maker or checker)
  const handlAddOrEditComment = () => {
    let commentId = isMaker ? makerComment.id : checkerComment.id;
    let obj = {
      comment: isMaker ? makerComment.comment : checkerComment.comment,
      onetransactionloan: loanId,
      type: isMaker ? makerType : checkerType,
    };

    UserUtility.oneTransAddComment(obj)
      .then(() => {})
      .catch((err) => {
        console.error("error of addComment: ", err);
        if (err?.detail) {
          toast.error("Add comment " + err?.detail);
        }
      });
  };

  // get maker and checker comments in this transaction
  const getCreditComments = (transactionId) => {
    let type = makerType + "," + checkerType;
    UserUtility.oneTransGetComments(transactionId, type)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          let findMakerObj = res.results?.find((item) => {
            return item.type === makerType ? item : null;
          });

          let findCheckerObj = res.results?.find((item) => {
            return item.type === checkerType ? item : null;
          });

          if (findMakerObj) {
            setMakerComment({
              oldComment: findMakerObj.comment,
              comment: findMakerObj.comment,
              id: findMakerObj.id,
            });
          }

          if (findCheckerObj) {
            setCheckerComment({
              oldComment: findCheckerObj.comment,
              comment: findCheckerObj.comment,
              id: findCheckerObj.id,
            });
          }
        }
      })
      .catch((err) => {
        console.error("error of get comments: ", err);
      });
  };

  // end comment operation

  const handleLiability = () => {
    // stop it because change status when press this button
    /*
    let status = isMaker ? "studying_credit" : "checking_credit";
    ChangetransactionStatus(status);
    */
    setIsCreateLiablity(true);
  };

  const paginationFun = (nextUrl) => {
    UserUtility.getNextPageData(nextUrl, "")
      .then((res) => {
        setTenors((old) => [...old, ...res.results]);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((err) => console.error(err));
  };

  const deviation = () => {
    UserUtility.deviationList()
      .then((res) => {
        setDeviationList(res);
      })
      .catch((err) => console.error("deviationList:", err));
  };

  const unCheckDeviation = () => {
    let unique = [];
    deviationSelected.forEach((element) => {
      if (!unique.includes(element)) {
        unique.push(element);
        const compare = (a, b) => {
          return a - b;
        };
        unique?.sort(compare);
      } else {
        unique.splice(unique.indexOf(element), 1);
      }
    });
    setDeviationIds(unique);
  };

  // deviation approve or reject
  const deviationResponseAction = (response) => {
    let obj = {
      loan: data.id,
      deviations: deviationIds.join(),
      response: response,
      reason: deviationReason,
    };
    UserUtility.deviationApproval(obj)
      .then((res) => {
        toast.success(
          `Deviation ${response === 1 ? "approved" : "rejected"} successfully`
        );
        navigate(-1);
      })
      .catch((err) => {
        console.log("err of deviationResponseAction: ", err);
      });
  };

  const checkDeviationDisable = (filter) => {
    if (
      permissions.includes("one_transaction_deviation_approver") &&
      !deviationSelected.includes(filter.id)
    ) {
      return true;
    } else if (
      !permissions.includes("one_transaction_deviation_approver") &&
      (!isMaker || isViewOnly)
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log("dataaa: ", data);

  //start function for  operation screen
  const sendToOperationChecker = () => {
    if (data?.onetransactionloandata?.contract_date) {
      if (data?.expired === false) {
        UserUtility.sendToOperationChekcer(loanId, paymentData)
          .then(() => {
            setTimeout(() => {
              navigate(-1);
            }, 500);
          })
          .catch((err) => {
            console.error("sendToOperationChekcer:", err);
            if (err) return toast.error(err);
            if (err?.detail) return toast.error(err?.detail);
          });
      } else {
        toast.error("Iscore date is expired");
      }
    } else {
      toast.error("Please choose contract date and apply it");
    }
  };
  const returnToCreditMaker = () => {
    if (reason?.reason?.length) {
      UserUtility.sendToOperationChekcer(loanId, reason)
        .then(() => {
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch((err) => {
          console.error("returnToCreditMaker:", err);
        });
    } else {
      toast.error("reason field empty");
    }
  };
  const returnToOperationMaker = () => {
    if (operationMaker?.reason?.length) {
      UserUtility.sendToOperationChekcer(loanId, operationMaker)
        .then(() => {
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch((err) => {
          console.error("returnToOperationMaker:", err);
        });
    } else {
      toast.error("reason field empty");
    }
  };
  const proceedFun = () => {
    if (data?.iscore_valid === false) {
      toast.error("Iscore date must be less than today");
    } else {
      UserUtility.sendToOperationChekcer(loanId, proceedChecker)
        .then(() => {
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch((err) => {
          console.error("proceedFun:", err);
          if (err?.detail) return toast.error(err?.detail);
          if (err) return toast.error(err);
        });
    }
  };

  // end function for operation screen

  // start function for finance screen
  const financeOptionsFun = () => {
    UserUtility.financeOptions()
      .then((res) => {
        setFinanceOptions(res);
      })
      .catch((err) => {
        console.error("financeOptions: ", err);
      });
  };

  // return to department or finance maker
  const ReturnToDepartment = () => {
    if (returnToData?.reason.length && returnToData?.status) {
      returnToFun(returnToData);
    } else {
      toast.error("Reason field or return to is empty");
    }
  };
  const sendToFinanceChecker = (obj) => {
    let finalObj = { ...obj, drive_identifier: MDMIdentifier };

    // let finalObj = { drive_identifier: MDMIdentifier };
    returnToFun(finalObj);
  };

  const BookFun = (obj) => {
    if (todayDate > data?.start_date) {
      toast.error("Start date must be less than or equal today");
    } else {
      // console.log('ellllle')
      returnToFun(obj);
    }
  };
  /*
    const returnToFinanceMaker = () => {
      if (FinanceMaker?.reason?.length) {
        returnToFun(FinanceMaker)
      } else {
        toast.error("reason field empty");
      }
    };
  */
  const returnToFun = (obj) => {
    UserUtility.ReturnToDepartment(loanId, obj)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  // end function for finance screen

  const uploadDocuments = (file) => {
    var token = localStorage.getItem("tools_token");
    var formdata = new FormData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    formdata.append("onetransactionloan", loanId);
    formdata.append("name", file.name);
    formdata.append("file", file);
    formdata.append("department", "credit");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ENDPOINTS.url}onetransaction/loandocuments/`, requestOptions)
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.json();
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          toast.error(text.detail || text[0] || "something went wrong");
          // throw new Error(text);
        } else {
          // navigate(-1);
          toast.success("File has been saved successfully");
          return res.json();
        }
      })
      .catch((err) => {
        console.error("error editing on imagessss", err);
      });
  };

  useEffect(() => {
    let arr = deviationSelected;
    if (permissions.includes("one_transaction_deviation_approver")) {
      if (
        data?.deviation_approvals_group_by_user &&
        data?.deviation_approvals_group_by_user.length > 0
      ) {
        let newArr = data?.deviation_approvals_group_by_user.filter(
          (item, index) => {
            return item.approver.some(
              (userId) => userId === data.logged_in_user_id
            );
          }
        );
        arr = newArr.map((item, index) => {
          return item.id;
        });
      }

      console.log("arr arr: ", arr);
    } else {
      data?.deviation_approvals_group_by_user?.map((e) =>
        deviationSelected.includes(e.id) ? null : arr.push(e.id)
      );
    }

    setDeviationSelected(arr);
    setIsGetIscore({ ...isGetIscore, isValid: data?.iscore_valid });
  }, [data, permissions]);

  useEffect(() => {
    unCheckDeviation();
  }, [deviationSelected, data]);

  useEffect(() => {
    if (data) {
      setSelectedProgram({
        value: data?.onetransactionloandata?.program_name,
        name: data?.onetransactionloandata?.program_name,
      });
      changeProgram(data?.onetransactionloandata?.program_name, "name");
      setMembershipAmount(data?.membership_amount);
      setDownPayment(data?.down_payment);
      setIncome(data?.onetransactionloandata?.profile_income);
      getCreditComments(data?.id);
      setIsEditableProfile(
        data?.userprofile?.onetransactionprofile?.is_profile_editable
      );
      setIsProfileComment(
        data?.onetransactionloandata?.profile_comments[0]?.comment
      );
      setReturnType(data?.onetransactionloandata?.return_type);

      // provider service and service info realted to program selected
      setProviderServ(data?.onetransactionloandata?.service_provider);
      setServiceInfo(data?.onetransactionloandata?.service_info);
      setOriginalLimit(data?.onetransactionloandata?.original_amount);
      setMDMIdentifier(data?.onetransactionloandata?.program_drive_identifier);
    }
  }, [data, programs?.length]);
  // console.log("data?.userprofile?.onetransactionprofile",data?.userprofile?.onetransactionprofile)
  useEffect(() => {
    if (data) {
      handleChangeTenor(data?.onetransactionloandata?.tenure_months, "months");
    }
  }, [tenors?.length]);

  useEffect(() => {
    getLoanData();
  }, [isCreateLiablity]);

  useEffect(() => {
    deviation();
    financeOptionsFun();
  }, []);

  console.log("dataaaaa: ", data);
  return (
    <section style={{ width: "100%" }}>
      {/* user informbbation view only*/}
      <Accordion alwaysOpen defaultActiveKey="1" className="accordClass">
        {/* Contract details in view only */}
        {/* user application profile */}
        {isViewOnly && (
          <Accordion.Item eventKey="-1">
            <Accordion.Header>Contract Detail</Accordion.Header>
            <Accordion.Body>
              <section className="contract_section">
                <CommonContractComponent
                  creditData={data}
                  loanId={loanId}
                  getFrom={"credit"}
                />
              </section>
            </Accordion.Body>
          </Accordion.Item>
        )}

        {(fromPage === "operationTab" || fromPage === "financeTab") && (
          <Accordion.Item eventKey="-1">
            <Accordion.Header>Contract Detail</Accordion.Header>
            <Accordion.Body>
              <section className="contract_section">
                {fromPage === "operationTab" && (
                  <CommonContractComponent
                    creditData={data}
                    loanId={loanId}
                    getFrom={"operation"}
                    setNewData={(value) => {
                      let obj = { ...data };
                      obj.onetransactionloandata.contract_date = value;
                      setData(obj);
                    }}
                    setToOperationMaker={(value) => {
                      setOperationMaker({ ...operationMaker, ...value });
                    }}
                    setToCreditMaker={(value) => {
                      setReason({ ...reason, ...value });
                    }}
                    setOperationPaymentData={(value) => {
                      setPaymentData({ ...value });
                    }}
                  />
                )}
                {fromPage === "financeTab" && (
                  <CommonContractComponent
                    creditData={data}
                    loanId={loanId}
                    getFrom={"finance"}
                    setToFinanceMaker={(value) => {
                      setReturnToData({
                        status: "restudying_finance",
                        ...value,
                      });
                    }}
                    setFinanceReturnToData={(value) => {
                      setReturnToData({ ...returnToData, ...value });
                    }}
                  />
                )}
              </section>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {/* user application profile */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>User Application Profile</Accordion.Header>
          <Accordion.Body>
            <ApplicationFormComponent
              fromPage="credit"
              transactionData={data?.userprofile?.onetransactionprofile}
              creditFormData={data}
              loan={data}
              showHistory={showHistory}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Credit Memo</Accordion.Header>
          <Accordion.Body>
            <main className="credit_main" style={{ marginTop: 10 }}>
              <section className="row">
                <h6 className="subTitle">Credit Memo</h6>
                {/* section1 with big size */}
                <section className="col-md-7">
                  <div className="row row_div">
                    <Form.Group className="mb-3 col-md-4">
                      <Form.Label>Select Program</Form.Label>
                      <Form.Select
                        id="disabledSelect"
                        value={selectedProgram.value}
                        onChange={(e) => changeProgram(e.target.value, "id")}
                        disabled={!isMaker}
                      >
                        <option value="" selected disabled>
                          Select Program
                        </option>
                        {programs &&
                          programs?.map((item, index) => (
                            <option value={item.id} key={index.toString()}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        *This field is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-4">
                      <Form.Label>Service provider</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={!isEditService}
                        value={providerServ}
                        onChange={(e) => setProviderServ(e.target.value)}
                        required
                        isInvalid={!providerServ}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please enter provider name.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-4">
                      <Form.Label>Service info</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={serviceInfo}
                        onChange={(e) => setServiceInfo(e.target.value)}
                        // disabled={!isEditService}
                      />
                    </Form.Group>
                  </div>

                  {/* second row */}
                  <div className="row row_div">
                    <Form.Group className="mb-3 col-md-4">
                      <Form.Label>Tenure(months)</Form.Label>
                      <Form.Select
                        id="disabledSelect"
                        value={selectedTenor.value}
                        onChange={(e) =>
                          handleChangeTenor(e.target.value, "id")
                        }
                        disabled={!isMaker}
                      >
                        <option value="" selected>
                          Select Tenure
                        </option>
                        {tenors &&
                          tenors.map((item, index) => (
                            <option value={item.id} key={index.toString()}>
                              {item.months} Month
                            </option>
                          ))}
                      </Form.Select>
                      {!selectedTenor.value && (
                        <p className="error_field">*This field is required.</p>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-4" controlId="formBasic">
                      <Form.Label>Original Limit:</Form.Label>
                      <Form.Control
                        type="number"
                        value={originalLimit}
                        onChange={(e) =>
                          setOriginalLimit(parseFloat(e.target.value))
                        }
                        disabled={!isMaker}
                      />
                      {originalLimit < 0 && (
                        <p className="error_field">
                          *This field must be positive.
                        </p>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-4" controlId="formBasic">
                      <Form.Label>MDM Identifier</Form.Label>
                      <Form.Control
                        type="number"
                        value={MDMIdentifier}
                        onChange={(e) => setMDMIdentifier(e.target.value)}
                        disabled={!isMakerFinance}
                      />
                    </Form.Group>
                  </div>

                  <div className="row row_div">
                    <Form.Group className="mb-3 col-md-4" controlId="formBasic">
                      <Form.Label>Down Payment:</Form.Label>
                      <Form.Control
                        type="number"
                        value={downPayment}
                        onChange={(e) =>
                          setDownPayment(parseFloat(e.target.value))
                        }
                        disabled={!isMaker}
                      />
                      {downPayment < 0 ? (
                        <p className="error_field">
                          *This field must be positive.
                        </p>
                      ) : (
                        (downPayment > membershipAmount ||
                          downPayment == membershipAmount) && (
                          <p p className="error_field">
                            *Down payment must be less than Membership amount.
                          </p>
                        )
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-4" controlId="formBasic">
                      <Form.Label> Monthly Income:</Form.Label>
                      <Form.Control
                        type="number"
                        value={income}
                        onChange={(e) => setIncome(e.target.value)}
                        disabled={!isMaker}
                      />
                      {!income ? (
                        <p className="error_field">*This field is required.</p>
                      ) : income === 0 || income < 0 ? (
                        <p className="error_field">
                          *This field must be greater than 0.
                        </p>
                      ) : (
                        <div style={{ height: 18 }}></div>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-md-4"
                      style={{ position: "relative" }}
                      controlId="formBasic"
                    >
                      <Form.Label>Interest Rate:</Form.Label>
                      <Form.Control type="text" value={interestRate} disabled />
                      <span
                        style={{ position: "absolute", bottom: 25, right: 22 }}
                      >
                        %
                      </span>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-md-4"
                      style={{ position: "relative" }}
                      controlId="formBasic"
                    >
                      <Form.Label>Admin Fees:</Form.Label>
                      <Form.Control
                        type="text"
                        value={data?.onetransactionloandata?.program_admin_fees}
                        disabled
                      />
                      <span
                        style={{ position: "absolute", bottom: 25, right: 22 }}
                      >
                        %
                      </span>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-md-4"
                      style={{ position: "relative" }}
                      controlId="formBasic"
                    >
                      <Form.Label>Kick Back:</Form.Label>
                      <Form.Control
                        type="text"
                        value={data?.onetransactionloandata?.program_kick_back}
                        disabled
                      />
                      <span
                        style={{
                          position: "absolute",
                          bottom: "12%",
                          right: 22,
                        }}
                      >
                        %
                      </span>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-md-4"
                      style={{ position: "relative" }}
                      controlId="formBasic"
                    >
                      <Form.Label>Amount Paid To:</Form.Label>
                      <Form.Control
                        type="text"
                        value={data?.onetransactionloandata?.amount_paid_to}
                        disabled
                      />
                      {/* <span style={{ position: "absolute", bottom: '12%', right: 22 }}>
                        %
                      </span> */}
                    </Form.Group>

                    {!isViewOnly && (
                      <div
                        className="col-md-4 align-self-end"
                        style={{ marginBottom: "1rem" }}
                      >
                        <Button
                          variant="primary"
                          type="button"
                          className="small_btn"
                          // disabled={checkBtnDisabled()}
                          onClick={() => handleCalculateOrEdit()}
                        >
                          Calculate
                        </Button>
                      </div>
                    )}
                  </div>
                  {!isViewOnly && (
                    <>
                      <div className="row row_div">
                        <Form.Group
                          className="mb-3 col-md-6"
                          controlId="formBasic"
                        >
                          <Form.Label>Maker Comment:</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            value={makerComment.comment}
                            onChange={(e) => {
                              setMakerComment({
                                ...makerComment,
                                // oldComment: makerComment.comment,
                                comment: e.target.value,
                              });
                            }}
                            disabled={isMaker ? false : true}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3 col-md-6"
                          controlId="formBasic"
                        >
                          <Form.Label>Checker Comment:</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            value={checkerComment.comment}
                            onChange={(e) =>
                              setCheckerComment({
                                ...checkerComment,
                                comment: e.target.value,
                              })
                            }
                            disabled={isChecker ? false : true}
                          />
                        </Form.Group>
                      </div>

                      <div className="row row_div">
                        <Form.Group>
                          <Form.Label>Reject reason:</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            // value={makerComment.comment}
                            onChange={(e) =>
                              setRejectReason({
                                ...rejectReason,
                                reason: e.target.value,
                              })
                            }
                            disabled={isMaker ? false : true}
                          />
                        </Form.Group>
                      </div>
                    </>
                  )}
                  {/* isGetIscore.isValid && */}
                  {
                    <>
                      {/* section deviation request and response */}
                      {isGetIscore.isValid && (
                        <section style={{ marginTop: 20, marginBottom: 20 }}>
                          <div style={{ marginBottom: 30 }}>
                            <Accordion
                              defaultActiveKey="0"
                              flush
                              className="accordingClass2"
                            >
                              {data?.deviation_approvals_group_by_user &&
                                data?.deviation_approvals_group_by_user.map(
                                  (item, index) => (
                                    <>
                                      {item?.approvers?.map(
                                        (itemApprove, ind) => (
                                          <>
                                            {itemApprove?.deviation_approvals &&
                                              itemApprove?.deviation_approvals
                                                .length > 0 && (
                                                <Accordion.Item
                                                  eventKey={ind.toString()}
                                                  className={`${
                                                    itemApprove
                                                      ?.deviation_approvals
                                                      ?.length > 0 &&
                                                    itemApprove
                                                      ?.deviation_approvals[0]
                                                      .response === true
                                                      ? "item1"
                                                      : "item2"
                                                  }`}
                                                >
                                                  <Accordion.Header>
                                                    <div
                                                      style={{ width: "88%" }}
                                                    >
                                                      <div className="head_flex">
                                                        {itemApprove
                                                          ?.deviation_approvals
                                                          ?.length > 0 &&
                                                        itemApprove
                                                          ?.deviation_approvals[0]
                                                          .response ? (
                                                          <AiOutlineCheckSquare />
                                                        ) : (
                                                          <AiOutlineCloseSquare />
                                                        )}
                                                        <div
                                                          style={{
                                                            marginLeft: 10,
                                                            marginTop: -1,
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <p className="p1">
                                                            {itemApprove?.email}
                                                          </p>
                                                          {/* <br /> */}
                                                          {/* name of deviation */}
                                                          <p className="p2">
                                                            {item?.name}
                                                          </p>
                                                          <div
                                                            className="head_flex"
                                                            style={{
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            {itemApprove?.deviation_approvals &&
                                                              itemApprove
                                                                ?.deviation_approvals
                                                                .length > 0 && (
                                                                <p className="p2">
                                                                  {" "}
                                                                  Current:{" "}
                                                                  {itemApprove
                                                                    ?.deviation_approvals[0]
                                                                    .response ===
                                                                  true
                                                                    ? "Approved"
                                                                    : "Rejected"}
                                                                </p>
                                                              )}
                                                            {itemApprove?.deviation_approvals &&
                                                              itemApprove
                                                                ?.deviation_approvals
                                                                .length > 1 && (
                                                                <p className="p2">
                                                                  {" "}
                                                                  Past:{" "}
                                                                  {itemApprove
                                                                    ?.deviation_approvals[1]
                                                                    .response
                                                                    ? "Approved"
                                                                    : "Rejected"}
                                                                </p>
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    {itemApprove
                                                      ?.deviation_approvals
                                                      ?.length > 0
                                                      ? itemApprove
                                                          ?.deviation_approvals[0]
                                                          ?.reason
                                                      : ""}
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              )}
                                          </>
                                        )
                                      )}
                                    </>
                                  )
                                )}
                            </Accordion>

                            {!data.is_deviations_approved &&
                              data?.deviation_approvals?.length > 0 && (
                                <p className="deviation_hint">
                                  *All deviations requests must be approved to
                                  submit this application
                                </p>
                              )}
                          </div>
                          <div className="row">
                            <Form.Group className="mb-4 col-md-6">
                              <Form.Label>Deviation request</Form.Label>
                              {deviationList?.map((filter, index) => (
                                <>
                                  <Form className="d-flex " key={index}>
                                    <Form.Check
                                      className="me-2"
                                      type="checkbox"
                                      disabled={checkDeviationDisable(filter)}
                                      onChange={() =>
                                        setDeviationSelected([
                                          ...deviationSelected,
                                          filter.id,
                                        ])
                                      }
                                      checked={
                                        deviationIds?.includes(filter.id) &&
                                        true
                                      }
                                    />
                                    {filter.name}
                                  </Form>
                                </>
                              ))}
                            </Form.Group>
                            {permissions.includes(
                              "one_transaction_deviation_approver"
                            ) && (
                              <div className="col-md-6">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasic"
                                >
                                  <Form.Label>Responsible Comment:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={6}
                                    value={deviationReason}
                                    onChange={(e) =>
                                      setDeviationReason(e.target.value)
                                    }
                                    // disabled={isMaker ? false : true}
                                  />
                                </Form.Group>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                      {/* <div className="row div_row"> */}
                      {isGetIscore.isValid && (
                        <div className="row row_div">
                          <div className="col-md-6">
                            <Button
                              variant="link"
                              className="link_btn_green "
                              style={{ color: "#41b6a8", fontSize: 16 }}
                              onClick={() => {
                                handleOpenIscore("old");
                                /*
                                // old implement when open iscore in modal
                                if (isGetIscore.data) {
                                  setIsGetIscore({
                                    ...isGetIscore,
                                    isOpenModal: true,
                                  });
                                } else {
                                  getIscoreHandle("old", true);
                                }
                                */
                              }}
                            >
                              View Iscore Report
                            </Button>
                          </div>

                          <div className="col-md-6">
                            <Button
                              variant="link"
                              className="link_btn_green "
                              style={{ color: "#41b6a8", fontSize: 16 }}
                              onClick={() => handleOpenForsaIscore()}
                            >
                              View Iscore Forsa Report
                            </Button>
                          </div>
                        </div>
                      )}
                      {/* </div> */}
                    </>
                  }

                  {/* start deviation buttons */}
                  {permissions.includes("one_transaction_deviation_approver") &&
                    !data?.is_deviations_approved && (
                      <div className="btns_div">
                        <Button
                          variant="primary"
                          type="button"
                          className="outlineBtn"
                          style={{
                            color: "#B64140",
                            borderColor: "#B64140",
                            marginLeft: 0,
                            marginRight: 16,
                            marginBottom: 20,
                          }}
                          disabled={deviationIds.length === 0}
                          onClick={() => deviationResponseAction(0)}
                        >
                          REJECT
                        </Button>

                        <Button
                          variant="primary"
                          type="submit"
                          className="addBtn"
                          disabled={deviationIds.length === 0}
                          onClick={() => deviationResponseAction(1)}
                        >
                          Approve
                        </Button>
                      </div>
                    )}
                  {/* end deviation buttons */}
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#B3B3B3",
                      borderRadius: 5,
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <div
                      className="d-flex align-items-end mt-3"
                      style={{ backgroundColor: "white" }}
                    >
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        // label="Return to data entry"
                        disabled={(!isMaker || is_returned_profile) && true}
                        onChange={(e) => setReturnDataEntry(e.target.checked)}
                        checked={retunDataEntry}
                      />
                      <Form.Label>Return to data entry</Form.Label>
                      {is_returned_profile && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              <strong>
                                Pending to resolve another opened reason
                              </strong>
                            </Tooltip>
                          }
                        >
                          <Button style={{ marginBottom: -6 }}>
                            <AiFillInfoCircle
                              style={{ marginLeft: 10, width: 20, height: 20 }}
                            />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </div>
                    {(isChecker || retunDataEntry) && (
                      <Form.Group style={{ marginTop: 14 }}>
                        <Form.Label>Select Reason</Form.Label>
                        <Form.Select
                          disabled={!isMaker || is_returned_profile}
                          value={returnType}
                          onChange={(e) => setReturnType(e.target.value)}
                        >
                          {returnTypeOptions?.map((res, id) => (
                            <option key={id} value={res.value}>
                              {res.display_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}
                    {(isChecker || retunDataEntry) && (
                      <Form.Group style={{ marginTop: 14 }}>
                        <Form.Label>Write comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={profileComment}
                          onChange={(e) => setIsProfileComment(e.target.value)}
                          disabled={
                            !isMaker || is_returned_profile ? true : false
                          }
                        />
                      </Form.Group>
                    )}
                  </div>

                  <div className="btns_div">
                    {isMaker && !isViewOnly && (
                      <>
                        {isGetIscore.isValid ? (
                          <Button
                            variant="primary"
                            type="submit"
                            className="addBtn"
                            style={{ marginRight: 16 }}
                            disabled={
                              (!data?.is_deviations_approved &&
                                data?.deviation_approvals_group_by_user
                                  ?.length > 0) ||
                              (data?.is_deviations_approved &&
                                deviationIds?.length > 0 &&
                                data?.deviation_approvals_group_by_user
                                  ?.length < 1)
                            }
                            onClick={() =>
                              handleCalculateOrEdit("checking_credit", true)
                            }
                          >
                            Send to checker
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="primary"
                              type="submit"
                              className="addBtn"
                              style={{ marginRight: 16 }}
                              disabled={
                                retunDataEntry
                                  ? true
                                  : data?.onetransactionloandata?.status ===
                                    "created"
                                  ? true
                                  : checkBtnDisabled()
                              }
                              onClick={() => handleOpenIscore("new")}
                            >
                              Get IScore
                            </Button>
                          </>
                        )}
                        <Button
                          variant="primary"
                          type="button"
                          className="outlineBtn"
                          style={{
                            color: "#B64140",
                            borderColor: "#B64140",
                            marginLeft: 0,
                            marginRight: 16,
                            marginBottom: 20,
                          }}
                          disabled={checkBtnDisabled()}
                          onClick={() => handleReject()}
                        >
                          REJECT
                        </Button>

                        <Button
                          variant="primary"
                          type="submit"
                          className="addBtn"
                          disabled={retunDataEntry ? false : checkBtnDisabled()}
                          onClick={() =>
                            handleCalculateOrEdit("studying_credit", true)
                          }
                        >
                          Save
                        </Button>
                      </>
                    )}

                    {isChecker && !isViewOnly && (
                      <>
                        <Button
                          variant="primary"
                          type="submit"
                          className="addBtn"
                          style={{ marginRight: 16 }}
                          onClick={() =>
                            handleCalculateOrEdit("restudying_credit", true)
                          }
                        >
                          Return to maker
                        </Button>

                        <Button
                          variant="primary"
                          type="submit"
                          className="addBtn"
                          onClick={() =>
                            handleCalculateOrEdit("approved_credit", true)
                          }
                        >
                          Approve
                        </Button>
                      </>
                    )}
                  </div>

                  {/* operation btn */}
                  {fromPage === "operationTab" && (
                    <Form.Group
                      className="d-flex justify-content-evenly"
                      style={{ marginTop: 20 }}
                    >
                      <Button
                        className="small_btn"
                        variant="contained"
                        onClick={() => {
                          isCheckerOperation
                            ? returnToOperationMaker()
                            : sendToOperationChecker();
                        }}
                      >
                        {isCheckerOperation
                          ? "Return to operation maker"
                          : "Send to operation checker"}
                      </Button>
                      <Button
                        className="small_btn"
                        variant="contained"
                        onClick={() => {
                          isCheckerOperation
                            ? proceedFun()
                            : returnToCreditMaker();
                        }}
                      >
                        {isCheckerOperation
                          ? "Proceed"
                          : "Return to credit maker"}
                      </Button>
                      {!isCheckerOperation && (
                        <Button
                          className="small_btn"
                          variant="contained"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      )}
                    </Form.Group>
                  )}

                  {fromPage === "financeTab" && (
                    <Form.Group
                      className="mx-5 px-5 d-flex justify-content-evenly"
                      style={{ marginTop: 20 }}
                    >
                      <Form>
                        {isMakerFinance && (
                          <Form.Select
                            size="lg"
                            onChange={(e) => {
                              setReturnToData({
                                ...returnToData,
                                status: e.target.value,
                              });
                            }}
                          >
                            <option value={""}>Return to</option>
                            {financeOptions?.map((res, id) => (
                              <option
                                value={res.status}
                                name={res.display}
                                key={id}
                              >
                                {res.display}
                              </option>
                            ))}
                          </Form.Select>
                        )}
                      </Form>
                      <Button
                        className="small_btn"
                        variant="contained"
                        onClick={() => {
                          ReturnToDepartment();
                          // isCheckerFinance ? returnToFinanceMaker() : ReturnToDepartment();
                        }}
                      >
                        {isCheckerFinance
                          ? "Return to finance maker"
                          : "Return"}
                      </Button>
                      <Button
                        className="small_btn"
                        variant="contained"
                        onClick={() => {
                          isCheckerFinance
                            ? BookFun({ status: "approved" })
                            : sendToFinanceChecker({
                                status: "checking_finance",
                              });
                        }}
                      >
                        {isCheckerFinance ? "Book" : "Send to finance Checker"}
                      </Button>
                    </Form.Group>
                  )}
                </section>

                {/* middle section */}
                <section className="col-1 justify-self-center">
                  <div className="line"></div>
                </section>
                {/* small size */}
                <section className="col-md-4">
                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Membership Amount</Form.Label>
                    <Form.Control
                      type="number"
                      value={membershipAmount}
                      onChange={(e) =>
                        setMembershipAmount(parseFloat(e.target.value))
                      }
                      disabled={!isMaker}
                    />
                    {!membershipAmount ? (
                      <p className="error_field">*This field is required.</p>
                    ) : (
                      (membershipAmount == 0 || membershipAmount < 0) && (
                        <p className="error_field">
                          *This field must be greater than 0.
                        </p>
                      )
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Debt Service Ratio</Form.Label>
                    <div className="row_ratio">
                      <div className="col_ratio1">
                        <Form.Control type="text" disabled value={data?.dsr} />
                        <Form.Text className="txt">%</Form.Text>
                      </div>
                      <div className="col_ratio2">
                        <Form.Control
                          type="text"
                          disabled
                          value={data?.dsr_amount}
                        />
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Loan amount</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={data?.principal}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Intrest amount</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={data?.interest_amount}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Admin fees amount</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={data?.onetransactionloandata?.admin_fees_amount}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Kick back amount</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={data?.onetransactionloandata?.kick_back_amount}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Total Due Amount</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={data?.total_due_amount}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Monthly Payment</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={data?.monthly_payment}
                    />
                  </Form.Group>

                  <div className="row row_side_btn mb-3">
                    <div className="col-12">
                      <Button
                        disabled={
                          isMaker &&
                          (data?.onetransactionloandata?.status ===
                            "studying_credit" ||
                            data?.onetransactionloandata?.status ===
                              "restudying_credit" ||
                            data?.onetransactionloandata?.status ===
                              "created") &&
                          !operation &&
                          !finance &&
                          is_returned_profile
                        }
                        variant="primary"
                        type="button"
                        className="btn1"
                        onClick={() => handleLiability()}
                      >
                        {isMaker &&
                        (data?.onetransactionloandata?.status ===
                          "studying_credit" ||
                          data?.onetransactionloandata?.status ===
                            "restudying_credit" ||
                          data?.onetransactionloandata?.status === "created") &&
                        !operation &&
                        !finance
                          ? "Create Liabilities"
                          : "Show Liabilities"}
                      </Button>
                    </div>
                  </div>

                  {/* attachment */}
                  <div className="row row_side_btn">
                    {!isViewOnly && isMaker && (
                      <div className="col-6">
                        <Form.Group
                          controlId="formFile"
                          className="col-6 upload_class"
                          style={{ width: "100%" }}
                        >
                          <Form.Control
                            type="file"
                            className="upload_input"
                            readOnly={is_returned_profile}
                            disabled={
                              is_returned_profile || !loanId ? true : false
                            }
                            onChange={(e) => {
                              uploadDocuments(e.target.files[0]);
                            }}
                          />

                          <div
                            className="upload_btn"
                            type="upload"
                            style={{ width: "100%", fontSize: 12 }}
                          >
                            Attach Document
                          </div>
                        </Form.Group>
                      </div>
                    )}
                    <div className="col-6">
                      <Button
                        variant="primary"
                        type="button"
                        className="outlineBtn1"
                        onClick={() => setIsOpenDocumentModal(true)}
                      >
                        View document
                      </Button>
                    </div>
                  </div>
                </section>
              </section>
            </main>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* modal of create liability */}
      <Modal
        isOpen={isCreateLiablity}
        onRequestClose={() => setIsCreateLiablity(false)}
        style={customStyles}
        contentLabel="Liabilities"
        shouldCloseOnOverlayClick={true}
      >
        <LiabilityFormComponent
          user={data?.userprofile?.onetransactionprofile?.user}
          applicationStatus={data?.onetransactionloandata?.status}
        />
      </Modal>

      {/* modal of display iscore */}
      <Modal
        isOpen={isGetIscore.isOpenModal || isGetForsaIscore.isOpenModal}
        onRequestClose={() => {
          setIsGetIscore({ ...isGetIscore, isOpenModal: false });
          setIsGetForsaIscore({ ...isGetForsaIscore, isOpenModal: false });
        }}
        style={customStyles}
        contentLabel={
          isGetIscore.isOpenModal ? "Iscore Report" : "Forsa Iscore Report"
        }
        shouldCloseOnOverlayClick={true}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: isGetIscore.data || isGetForsaIscore.data,
          }}
        />
      </Modal>

      {/* view documents */}
      <Modal
        isOpen={isOpenDocumentModal}
        onRequestClose={() => setIsOpenDocumentModal(!isOpenDocumentModal)}
        style={customStyles}
        contentLabel="user history"
        shouldCloseOnOverlayClick={true}
      >
        <LoanDocumentComponent loanId={loanId} department="credit" />
      </Modal>
    </section>
  );
};

