import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../hooks/userContext";
import { UserUtility } from "../../apis/UserUtility";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const UserHistoryData = () => {
  const { handleLoader } = useContext(UserContext);
  const [userHistory, setUserHistory] = useState({});
  const location = useLocation();
  const { userId } = location.state;

  const history_fields = [
    { fieldLabel: "Activated time", fieldName: "date_of_activation" },
    { fieldLabel: "Maker", fieldName: "maker" },
    { fieldLabel: "Checker", fieldName: "checker" },
    { fieldLabel: "Activation point", fieldName: "kiosk" },
    { fieldLabel: "Client Name", fieldName: "client_name" },
    { fieldLabel: "Total Limit", fieldName: "profile_finance_limit" },
    { fieldLabel: "Used Amount", fieldName: "used_amount" },
    { fieldLabel: "Available Amount", fieldName: "available_amount" },
    { fieldLabel: "Watchlist", fieldName: "on_watch_date" },
    { fieldLabel: "Iscore", fieldName: "iscore_pdf_report" },
  ];

  const users_profile = () => {
    handleLoader(true);
    UserUtility.userProfile_history(userId)
      .then((res) => {
        setUserHistory(res);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("userProfile_history", err)(handleLoader(false));
      });
  };
  useEffect(() => {
    users_profile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 2000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {history_fields.map((field, id) => (
                <StyledTableCell key={id}>{field.fieldLabel}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {history_fields.map((field, id) => (
                <StyledTableCell key={id}>
                  {field.fieldLabel === "Iscore" ? (
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#418AB6",
                        cursor: "pointer",
                      }}
                      href={userHistory.iscore_pdf_report?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                     {userHistory.iscore_pdf_report?.url? "View" :''}
                    </a>
                  ) : (
                    `${userHistory[field.fieldName]} `
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserHistoryData;
