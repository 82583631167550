import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { profileFields } from "./ProfileFields";
import { TextField } from "@mui/material";
import { UserUtility } from "../../apis/UserUtility";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { Table, TableHead, TableRow, TableBody } from "@mui/material";
import { StyledTableCell } from "../../constants/Colors";
import PopupCreditLimit from "./PopupCreditLimit";
import PopupLoginHistory from "./PopupLoginHistory";

const UserProfile = () => {
  const location = useLocation();
  const { permissions } = useContext(UserContext);
  const { userId, profileOptions } = location?.state;
  const [carModel, setCarModel] = useState();
  const [editable, setEditable] = useState();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState([]);
  const [loginHistoryInfo, setLoginHistoryInfo] = useState([]);
  const [ShowPopupCredit, setShowPopupCredit] = useState(false);
  const [CreditData, setCreditData] = useState([]);
  const [userID, setUserID] = useState("");
  const [page, setPage] = useState(1);
  const [pageHistory, setPageHistory] = useState(1);
  const [ShowPopupHistory, setShowPopupHistory] = useState(false);

  const can_activate =
    permissions.includes("can_activate_login_device") &&
    permissions.includes("ud_can_view_login_devices");
  const can_deactivate =
    permissions?.includes("can_deactivate_login_device") &&
    permissions?.includes("ud_can_view_login_devices");

  const fieldEdit = () => {
    if (permissions.includes("can_edit_user_details")) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  };
  const login_info = () => {
    UserUtility.login_info(userId)
      .then((res) => {
        setLoginInfo(res.results);
      })
      .catch((err) => console.error("login_info", err));
  };

  const login_history_info = () => {
    UserUtility.login_history_info(userId)
      .then((res) => {
        setLoginHistoryInfo(res.results);
      })
      .catch((err) => console.error("login_info", err));
  };

  const users_profile = () => {
    UserUtility.users_profile(userId)
      .then((res) => {
        setUserData(res);
        car_model(res?.car_brand);
      })
      .catch((err) => console.error("user_profile_err", err));
  };

  const car_model = (brandName) => {
    UserUtility.car_model(brandName)
      .then((res) => {
        setCarModel(res.results);
      })
      .catch((err) => console.error("car_Model_err", err));
  };

  // updateUserData is a function to update our user data by passing the field name with the new value
  const updateUserData = (fieldName, newValue) => {
    setUserData({ ...userData, [fieldName]: newValue });
  };

  const change_userProfile = () => {
    let finalData = userData;
    delete finalData.is_blocked;
    setLoading(true);
    UserUtility.change_userProfile(finalData, userId)
      .then((res) => {
        setUserData(res);
        toast.success("User Data has been changed");
        setLoading(false);
      })
      .catch((errors) => {
        toast.error(errors);
        setLoading(false);
      });
  };

  const login_action = (loginId, loginAction) => {
    setLoading(true);
    UserUtility.login_action(userId, loginId, loginAction)
      .then(() => {
        login_info();
        login_history_info();
        setLoading(false);
      })
      .catch((err) => {
        console.error("login_action", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fieldEdit();
    if (permissions.includes("can_view_user_details")) {
      users_profile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    login_info();
    login_history_info();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };
  //handle pagination page
  const handleHistoryChange = (e, value) => {
    setPageHistory(value);
  };
  //this function for handle Credit Limit EndPoint
  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (userID) {
      searchParams.append("profile_id", userID);
    }
    if (page && userID) {
      searchParams.append("page", page);
    }

    if (userID !== "") {
      UserUtility.CreditLimit(searchParams.toString())
        .then((res) => {
          console.log(res);
          setCreditData(res);
        })
        .catch((err) => console.error("Credit_Limit_List_Not_Avall", err));
    }
  }, [userID, page]);

  console.log("loginHistoryInfo", loginHistoryInfo);

  return (
    <Container className="mt-4">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <PopupCreditLimit
        show={ShowPopupCredit}
        onHide={() => setShowPopupCredit(false)}
        creditData={CreditData}
        setUserID={setUserID}
        handleChange={handleChange}
        page={page}
      />

      <PopupLoginHistory
        show={ShowPopupHistory}
        onHide={() => setShowPopupHistory(false)}
        historyData={loginHistoryInfo}
        handleChange={handleHistoryChange}
        page={pageHistory}
      />

      {profileFields?.map((res, id) => (
        <Row key={id} className="">
          {res.map((field, id) =>
            field.fieldType === "header" ? (
              <div
                className="textStyle"
                // style={{ height: "50px", marginBottom: "2%" }}
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block", height: "50px", marginBottom: "2%" }
                }
              >
                <div className="d-flex " style={{ alignItems: "baseline" }}>
                  <Form.Text className="ms-3 fs-5" style={{ color: "white" }}>
                    {field.fieldLabel}
                  </Form.Text>

                  {field.fieldLabel === "Important Dates" && (
                    <>
                      <Form.Label
                        className="fw-bold ms-5 me-3 fs-5"
                        style={{ fontSize: "50px" }}
                      >
                        Modified
                      </Form.Label>

                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        key={id}
                        className="mb-md-4 mb-sm-2"
                      >
                        <Form.Control
                          type="text"
                          readOnly={true}
                          defaultValue={userData.modified}
                          onChange={(e) =>
                            updateUserData("modified", e.target.value)
                          }
                        />
                      </Col>
                    </>
                  )}

                  {field.fieldLabel === "Login Info" && (
                    <>
                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        key={id}
                        className="mb-md-4 mb-sm-2 ms-3"
                      >
                        <Button
                          variant="success"
                          className="w-100"
                          style={{
                            backgroundColor: "white",
                            color: "#41b6a8",
                            marginBottom: "12px",
                          }}
                          onClick={() => setShowPopupHistory(true)}
                        >
                          <span
                            style={{ textDecoration: "none", color: "#41b6a8" }}
                            // target="_blank"
                            // rel="noreferrer"
                          >
                            View History
                          </span>
                        </Button>
                      </Col>
                    </>
                  )}
                </div>
              </div>
            ) : // to handle text input
            field.fieldType === "text" ? (
              <Col
                lg={3}
                md={6}
                sm={12}
                key={id}
                className=" mb-md-4 mb-sm-2"
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <Form.Label className="fw-bold">{field.fieldLabel}</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    readOnly={!editable ? true : field.redOnly ? true : false}
                    defaultValue={userData[field?.fieldName]}
                    onChange={(e) =>
                      updateUserData(field.fieldName, e.target.value)
                    }
                  />
                  {field.image && (
                    <Button variant="success" className="w-25">
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        target="_blank"
                        rel="noreferrer"
                        href={userData[field?.fieldName]}
                      >
                        View
                      </a>
                    </Button>
                  )}
                </div>
              </Col>
            ) : field.fieldType === "options" ? (
              // to handle options fetched from options API
              <Col
                lg={3}
                md={6}
                sm={12}
                key={id}
                className="mb-md-4 mb-sm-2"
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <Form.Label className="fw-bold">{field.fieldLabel}</Form.Label>

                <Form.Select
                  // readOnly={!editable ? true : field.redOnly ? true : false}
                  value={userData[field.fieldName] || null}
                  // multiple={true}
                  onChange={(e) =>
                    updateUserData(field.fieldName, e.target.value)
                  }
                >
                  {/* {editable &&
                    (
                      <option value={null} name={res.display_name} key={id}>
                        All
                      </option>
                    )} */}
                  {profileOptions &&
                    profileOptions[field.fieldName]?.choices?.map((res, id) => (
                      <option
                        disabled={
                          !editable ? true : field.redOnly ? true : false
                        }
                        value={res.value}
                        name={res.display_name}
                        key={id}
                      >
                        {res.display_name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : field.fieldType === "textaria" ? (
              // to handle text aria input
              <Col
                lg={
                  field.fieldLabel === "iscore report" ||
                  field.fieldLabel === "iscore parsed report" ||
                  field.fieldLabel === "rejection reason" ||
                  field.fieldLabel === "cancel reason"
                    ? 6
                    : 3
                }
                md={6}
                sm={12}
                key={id}
                className="mb-md-4 mb-sm-2"
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <Form.Label className="fw-bold">{field.fieldLabel}</Form.Label>
                <Form.Control
                  readOnly={!editable ? true : field.redOnly ? true : false}
                  as="textarea"
                  rows={3}
                  defaultValue={userData[field.fieldName] || ""}
                  onChange={(e) =>
                    updateUserData(field.fieldName, e.target.value)
                  }
                />
              </Col>
            ) : field.fieldType === "space" ? (
              <Col lg={12} key={id} className="my-4" />
            ) : field.fieldType === "state" ? (
              <div
                className="w-25 p-3 text-center mb-3 border rounded"
                // style={{ backgroundColor: "#D5ECE6" }}
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block", backgroundColor: "#D5ECE6" }
                }
              >
                <Form.Label>{userData[field?.fieldName]}</Form.Label>
              </div>
            ) : field.fieldType === "button" ? (
              <button
                onClick={() => {
                  setShowPopupCredit(true);
                }}
                className="w-25 p-3 text-center mb-3 border rounded ms-auto"
                // style={{ backgroundColor: "#41b6a8" }}
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block", backgroundColor: "#41b6a8" }
                }
              >
                {/* <Form.Label>{userData[field?.fieldName]}</Form.Label> */}
                <span className="text-white">{field.fieldName}</span>
              </button>
            ) : field.fieldType === "date" ? (
              // to handle date picker
              <Col
                lg={3}
                md={6}
                sm={12}
                key={id}
                className="mb-md-4 mb-sm-2 d-flex align-items-end"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    readOnly={!editable ? true : field.redOnly ? true : false}
                    label={field.fieldLabel}
                    inputFormat="dd/MM/yyyy"
                    value={userData[field.fieldName] || null}
                    onChange={(e) => {
                      let modified_date = moment(e).format("YYYY-MM-DD");
                      updateUserData(field.fieldName, modified_date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>
            ) : field.fieldType === "checkbox" ? (
              <Col
                lg={3}
                md={6}
                sm={12}
                key={id}
                className="mb-md-4 mb-sm-2"
                // style={{ alignSelf: "center" }}
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block", alignSelf: "center" }
                }
              >
                <Form.Check
                  disabled={!editable ? true : field.redOnly ? true : false}
                  // readOnly={!editable ? true : field.redOnly ? true : false}
                  type="checkbox"
                  label={field.fieldLabel}
                  style={{ fontWeight: "bold" }}
                  checked={userData[field.fieldName] || false}
                  onChange={(e) =>
                    updateUserData(field.fieldName, e.target.checked)
                  }
                />
              </Col>
            ) : field.table ? (
              <Table aria-label="customized table" className="mb-5">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="deviceInfo_head">
                      {field.fieldLabel}
                    </StyledTableCell>
                    <StyledTableCell className="deviceInfo_head">
                      {field.fieldLabel1}
                    </StyledTableCell>
                    <StyledTableCell className="deviceInfo_head">
                      {field.fieldLabel2}
                    </StyledTableCell>
                    <StyledTableCell className="deviceInfo_head">
                      {field.fieldLabel3}
                    </StyledTableCell>
                    <StyledTableCell className="deviceInfo_head">
                      {field.fieldLabel4}
                    </StyledTableCell>
                    <StyledTableCell className="deviceInfo_head">
                      {field.fieldLabel5}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loginInfo?.map((ele, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell className="deviceInfo_body">
                          {ele.get_device_name}
                        </StyledTableCell>
                        <StyledTableCell className="deviceInfo_body">
                          {ele.created_at}
                        </StyledTableCell>
                        <StyledTableCell className="deviceInfo_body">
                          {ele.last_successful_login_attempt}
                        </StyledTableCell>
                        <StyledTableCell className="deviceInfo_body">
                          {ele.approver}
                        </StyledTableCell>
                        <StyledTableCell className="deviceInfo_body">
                          {ele.is_approved_to_login_through
                            ? "APPROVED"
                            : "BLOCK"}
                        </StyledTableCell>
                        <StyledTableCell className="deviceInfo_body">
                          {ele.is_approved_to_login_through &&
                          can_deactivate ? (
                            <Button
                              variant="success"
                              onClick={() => login_action(ele.id, false)}
                              // className="w-25"
                            >
                              Deactivate
                            </Button>
                          ) : (
                            !ele.is_approved_to_login_through &&
                            can_activate && (
                              <Button
                                variant="success"
                                onClick={() => login_action(ele.id, true)}
                                // className="w-25"
                              >
                                Activate
                              </Button>
                            )
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : field.fieldType === "car_brand_options" ? (
              // to handle car brand options fetched from car brand options API
              <Col lg={3} md={6} sm={12} key={id} className="mb-md-4 mb-sm-2">
                <Form.Label className="fw-bold">{field.fieldLabel}</Form.Label>
                <Form.Select
                  value={userData[field?.fieldName] || ""}
                  onChange={(e) => {
                    updateUserData(field?.fieldName, e.target.value);
                    car_model(e.target.value);
                  }}
                >
                  {profileOptions &&
                    profileOptions[field?.fieldName]?.choices?.map(
                      (res, id) => (
                        <option
                          disabled={
                            !editable ? true : field.redOnly ? true : false
                          }
                          value={res.value}
                          name={res.display_name}
                          key={id}
                        >
                          {res.display_name}
                        </option>
                      )
                    )}
                </Form.Select>
              </Col>
            ) : field.fieldName === "checker_comments" ? (
              <Col
                lg={
                  field.fieldLabel === "iscore report" ||
                  field.fieldLabel === "iscore parsed report"
                    ? 6
                    : 3
                }
                md={6}
                sm={12}
                key={id}
                className="mb-md-4 mb-sm-2"
                style={
                  field?.permissionField === true &&
                  !permissions.includes("can_view_credit_info")
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <Form.Label className="fw-bold">{field.fieldLabel}</Form.Label>
                <Form.Control
                  readOnly={!editable ? true : field.redOnly ? true : false}
                  as="textarea"
                  rows={3}
                  defaultValue={userData?.checker_comments?.map((e, index) => {
                    return e.comment;
                  })}
                  onChange={(e) =>
                    updateUserData(field.fieldName, e.target.value)
                  }
                />
              </Col>
            ) : (
              field.fieldType === "car_model_options" && (
                // to handle car brand models fetched from car brand models API
                <Col lg={3} md={6} sm={12} key={id} className="mb-md-4 mb-sm-2">
                  <Form.Label className="fw-bold">
                    {field.fieldLabel}
                  </Form.Label>

                  <Form.Select
                    value={userData[field.fieldName] || ""}
                    onChange={(e) =>
                      updateUserData(field.fieldName, e.target.value)
                    }
                  >
                    {carModel &&
                      carModel?.map((res, id) => (
                        <option
                          disabled={
                            !editable ? true : field.redOnly ? true : false
                          }
                          value={res.value}
                          name={res.value}
                          key={id}
                        >
                          {res.display_name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              )
            )
          )}
        </Row>
      ))}

      {permissions.includes("can_view_credit_info") && (
        <>
          <Form.Label className="fw-bold">i.score report</Form.Label>
          <Table aria-label="customized table" className="mb-5">
            <TableHead>
              <TableRow>
                <StyledTableCell className="iscoreFile">
                  File Name
                </StyledTableCell>
                <StyledTableCell className="iscoreFile">
                  Report Date
                </StyledTableCell>
                <StyledTableCell className="iscoreFile">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>
                  {userData?.iscore_pdf_report?.user_name_in_report}
                </StyledTableCell>
                <StyledTableCell>
                  {userData?.iscore_pdf_report?.report_date}
                </StyledTableCell>
                <StyledTableCell>
                  {userData?.iscore_pdf_report?.user_name_in_report && (
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#418AB6",
                        cursor: "pointer",
                      }}
                      href={userData?.iscore_pdf_report?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View{" "}
                    </a>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}

      <section className="w-25 mx-auto mb-5">
        <Button
          variant="success"
          onClick={change_userProfile}
          className="w-100 mt-5"
          disabled={loading}
        >
          Save
        </Button>
      </section>
    </Container>
  );
};

export default UserProfile;
