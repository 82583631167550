import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UserUtility } from "../../../apis/UserUtility";
import PaginationComponent from "../../../components/pagination/PaginationComponent";
import { StyledTableCell, StyledTableRow } from "../../../constants/Colors";
import OneTransactionContext from "../../../hooks/oneTransactionContext";
import UserContext from "../../../hooks/userContext";

const CollectionList = () => {
  const [allApplications, setAllApplications] = useState([]);
  const [Applications, setApplications] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState();
  const [statusSelected, setStatusSelected] = useState();
  const [page, setPage] = useState(1);
  const { permissions = [] } = useContext(UserContext);

  const collectionList = () => {
    UserUtility.collection_list(page, statusSelected, searchValue)
      .then((res) => {
        setApplications(res.results);
        setAllApplications(res);
      })
      .catch((err) => {
        console.error("error of collectionList: ", err);
      });
  };
  const collectionOptions = () => {
    UserUtility.collection_options()
      .then((res) => {
        setOptions(res.GET.state.choices);
      })
      .catch((err) => {
        console.error("error of collectionOptions: ", err);
      });
  };

  const contractsSearch = (e) => {
    e.preventDefault();
    if (page === 1) {
      collectionList();
    } else {
      setPage(1);
    }
  };

  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    collectionList();
  }, [page]);
  useEffect(() => {
    collectionOptions();
  }, []);

  return (
    <main className="oneTransaction_container">
      <section>
        <form>
          <article className="searchBar_wrapper">
            <section className="search_fields_wrapper">
              <TextField
                size="small"
                placeholder="National ID / Phone Number"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="text-field"
                label="National ID / Phone Number"
              />

              <Form.Select
                className="select-field"
                size="sm"
                onChange={(e) => setStatusSelected(e.target.value)}
              >
                <option value={""}>All</option>
                {options?.map((res, id) => (
                  <option value={res.value} name={res.label} key={id}>
                    {res.label}
                  </option>
                ))}
              </Form.Select>
            </section>

            <Button
              className="searchButton"
              type="submit"
              variant="contained"
              onClick={(e) => contractsSearch(e)}
            >
              Search
            </Button>
          </article>
        </form>
      </section>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>National ID</StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Sent To Drive</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Applications?.map((ele, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{ele.id}</StyledTableCell>
                  <StyledTableCell>
                    <Link
                      to={`/oneTransaction/collection/${ele.id}/`}
                      className="linkStyle"
                      state={{
                        userId: ele.id,
                      }}
                    >
                      {ele.national_id}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{ele.full_name}</StyledTableCell>
                  <StyledTableCell>{ele.user__phone}</StyledTableCell>
                  <StyledTableCell>{ele.state}</StyledTableCell>
                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      checked={ele.sent_to_drive}
                      disabled
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={allApplications}
        page={page}
        handleChange={handleChange}
      />
    </main>
  );
};
export default CollectionList;
