import { Form } from "react-bootstrap";

const DropdownTabel = ({ name, value, options, onChange }) => {
  console.log("value", options);

  return (
    <Form.Select
      value={value}
      name={name}
      onChange={(e) => onChange({ target: { name, value: e.target.value } })}
      style={{ width: "100%", minWidth: "140px" }}
    >
      <option value="">
        {`Select ${name.charAt(0).toUpperCase() + name.slice(1)}`}
      </option>

      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </Form.Select>
  );
};
export default DropdownTabel;
