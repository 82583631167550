import React, { useContext } from "react";
import { Container, Nav } from "react-bootstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import "./Style.scss";
import UserContext from "../../hooks/userContext";

const UserDetails = () => {
  const { permissions } = useContext(UserContext);
  const LimitIncrease = false;
  const location = useLocation();
  const { id, userId, profileOptions, blocked, type, manual_state } =
    location.state;

  return (
    <Container className="mt-5 w-100">
      <Nav
        variant="pills"
        fill
        defaultActiveKey="/userDetails/profile"
        className="user_nav"
        activeKey="/userDetails/profile"
      >
        {permissions.includes("can_view_user_details") && (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/userDetails/profile"
              state={{
                id: id,
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
              }}
            >
              User Profile
            </Nav.Link>
          </Nav.Item>
        )}

        {type === "limitMaker" ||
        type === "limitChecker" ||
        type === null ? null : (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/userDetails/ManualLimit"
              state={{
                id: id,
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: false,
                type: type,
                manual_state: manual_state,
              }}
            >
              Manual Limit
            </Nav.Link>
          </Nav.Item>
        )}

        {type === "riskMaker" ||
        type === "riskChecker" ||
        type === null ? null : (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              state={{
                id: id,
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: true,
                type: type,
                manual_state: manual_state,
              }}
              to="/userDetails/LimitIncrease"
            >
              Limit Increase
            </Nav.Link>
          </Nav.Item>
        )}
        {permissions.includes("can_view_user_history") && (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/userDetails/History"
              state={{
                id: id,
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
              }}
            >
              History
            </Nav.Link>
          </Nav.Item>
        )}
        {permissions.includes("can_view_user_block") && (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/userDetails/BlockHistory"
              state={{
                id: id,
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
              }}
            >
              Block History
            </Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/userDetails/FinanceDetails"
            state={{
              id: id,
              userId: userId,
              profileOptions: profileOptions,
              blocked: blocked,
              LimitIncrease: LimitIncrease,
              type: type,
              manual_state: manual_state,
            }}
          >
            Collection
          </Nav.Link>
        </Nav.Item>
        {permissions.includes("view_legal_History") && (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/userDetails/LegalHistory"
              state={{
                id: id,
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
              }}
            >
              Legal
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
      <Outlet />
    </Container>
  );
};

export default UserDetails;
