import { useState, useEffect } from "react";
import UserContext from "./hooks/userContext";
import RouteComponent from "./RouteComponent";
import "react-toastify/dist/ReactToastify.css";
import { UserUtility } from "./apis/UserUtility";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";

// to be global for all modal
Modal.setAppElement("#root");
function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [oneTransactionOptionResult, setOneTransactionOptionResult] =
    useState();
  const [toolProfile, setToolProfile] = useState();
  useEffect(() => {
    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  const handleLoader = (arg) => {
    setLoader(arg);
  };

  const getPermissions = () => {
    UserUtility.userPermissions()
      .then((res) => {
        setPermissions(res.detail);
      })
      .catch((error) => {
        console.error("failed to get permissions", error);
      });
  };
  const autoLogin = async () => {
    const token = await localStorage.getItem("tools_token");
    if (token) {
      setIsLogin(true);
      getPermissions();
    } else {
      return;
    }
  };
  return (
    <UserContext.Provider
      value={{
        isLogin,
        setIsLogin,
        permissions,
        loader,
        handleLoader,
        setOneTransactionOptionResult,
        oneTransactionOptionResult,
        toolProfile,
        setToolProfile,
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RouteComponent />
    </UserContext.Provider>
  );
}

export default App;
