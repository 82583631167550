import React from "react";
import style from "./TableData.module.scss";
const TableData = (props) => {
  const { data } = props;
  const { paymentRequestdata } = props;
  const { paid } = props;

  const tableHeader = [
    { name: "رقم القسط" },
    { name: "تاريخ القسط" },
    { name: "قيمة القسط" },
    { name: "التمويل" },
  ];
  const lastTableHeader = [
    { name: "الإيداع" },
    { name: "مصدر الإيداع" },
    { name: "تاريخ الإيداع" },
  ];
  return (
    <>
      <table className="table" style={{ fontSize: "8px" }}>
        <thead className={`${style.table_header}`}>
          <tr>
            {(data ? tableHeader : lastTableHeader)?.map((ele) => {
              // {( tableHeader)?.map((ele) => {
              return <th style={{ backgroundColor: "#D9D9D9" }}>{ele.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {paymentRequestdata
            ? paymentRequestdata?.map((ele, index) => {
                return (
                  <tr style={{ lineHeight: 1 }} key={index}>
                    <td>{ele.amount}</td>
                    <td>{`تم الإيداع ب${ele.payment_method}`}</td>
                    <td>{ele.payment_date}</td>
                  </tr>
                );
              })
            : data?.map((ele, id) => {
                return (
                  <tr style={{ lineHeight: 1 }} key={id}>
                    <td style={{ textAlign: "center" }}>{ele.index}</td>
                    <td>{ele.due_date}</td>
                    <td>{ele.installments_amount}</td>
                    <td>تمويل إستهلاكي</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
      {paid && (
        <div
          className="d-flex flex-row-reverse "
          style={{ fontSize: 10, marginRight: "5%" }}
        >
          <p style={{ marginLeft: "15%" }}>الإجمالي</p>
          <p>{paid}</p>
        </div>
      )}
    </>
  );
};

export default TableData;
