import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import UserContext from "../../hooks/userContext";
import styles from "./OverViewStyle.module.scss";

export const OfferOverViewTool = () => {
  const [searchValue, SetSearchValue] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [total, setTotal] = useState();
  const [offerList, setOfferList] = useState([]);
  const { handleLoader ,loader} = useContext(UserContext);
  /*
  const [offerList, setOfferList] = useState([
    {
      brand_name: "Tradeline",
      offer_name:
        "The awaited offer to shop more, Install up to 12 months triple zero",
      expiry: "20-10-2024",
      data: [
        {
          tenor: "3 month",
          interest_rate: "1%",
          admin_fees: "12%",
        },
        {
          tenor: "3 month",
          interest_rate: "1%",
          admin_fees: "12%",
        },
      ],
    },
    {
      brand_name: "Tradeline",
      offer_name:
        "The awaited offer to shop more, Install up to 12 months triple zero",
      expiry: "20-10-2024",
      data: [
        {
          tenor: "3 month",
          interest_rate: "1%",
          admin_fees: "12%",
        },
        {
          tenor: "3 month",
          interest_rate: "1%",
          admin_fees: "12%",
        },
      ],
    },
    {
      brand_name: "Tradeline",
      offer_name:
        "The awaited offer to shop more, Install up to 12 months triple zero",
      expiry: "20-10-2024",
      data: [
        {
          tenor: "3 month",
          interest_rate: "1%",
          admin_fees: "12%",
        },
        {
          tenor: "3 month",
          interest_rate: "1%",
          admin_fees: "12%",
        },
      ],
    },
  ]);
*/

  const getOffers = () => {
    handleLoader(true);
    UserUtility.getOfferOverView(pagesNumber, searchValue)
      .then((res) => {
        handleLoader(false);
        setTotal(res);
        setOfferList(res.results);
      })
      .catch((err) => {
        handleLoader(false);
        toast.error(err);
      });
  };

  const handleClick = (e) => {
    if (e === "Enter") {
      if (pagesNumber !== 1) {
        setPagesNumber(1);
      } else {
        getOffers();
      }
    }
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };

  useEffect(() => {
    getOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber]);

  const test = { count: 20 };
  return (
    <main className="main_div">
      {/* head */}
      <div>
        <div className="flex_div">
          <div className="flex_div" style={{ width: "70%" }}>
            <div
              className="input_div"
              style={{ width: "50%", marginRight: 20 }}
            >
              <Form.Control
                placeholder="Search by Brand Name"
                className="searchInput form_control_style"
                onChange={(e) => SetSearchValue(e.target.value)}
              />
              <BsSearch className="search_icon" />
            </div>
          </div>

          <div className="flex_div">
            <Button
              onClick={() => handleClick("Enter")}
              className={styles.btnStyle}
              style={{ width: 200 }}
              disabled={loader}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      {/* table */}

      {offerList.map((offer, index) => (
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Box className={`${styles.boxStyle}`}>
            <div className={styles.divHead} style={{ width: "25%" }}>
              <h6> Brand Name</h6>
              <p>{offer.brand_title}</p>
            </div>
            {/* <Typography
              variant="h6"
              component="div"
              style={{ textAlign: "center", fontWeight: "bold" }}
            > */}
            <div className={styles.divHead} style={{ width: "50%" }}>
              <h6>Offer Name </h6>
              <p>{offer.title}</p>
            </div>
            {/* </Typography> */}
            <div className={styles.divHead} style={{ width: "25%" }}>
              <h6> Expiry Date </h6>
              <p>
                {/* {offer?.expiry} */}
                {offer?.expiry_date
                  ? moment(offer?.expiry_date).format("lll")
                  : "-"}{" "}
              </p>
            </div>
          </Box>
          <Table
            striped
            responsive
            hover
            sx={{ minWidth: 700 }}
            className="notify_table"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Tenor </StyledTableCell>
                <StyledTableCell>Interest Rate</StyledTableCell>
                <StyledTableCell>Admin Fees</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offer?.plans.map((ele, index) => {
                return (
                  <StyledTableRow key={index.toString()}>
                    <StyledTableCell>{ele?.months} Months</StyledTableCell>
                    <StyledTableCell>{ele?.interest_rate} %</StyledTableCell>
                    <StyledTableCell>{ele?.admin_fees_value} %</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ))}

      {total && total?.count > 0 ? (
        <PaginationComponent
          defaultNum={15}
          brands={total}
          page={pagesNumber}
          handleChange={handleChange}
        />
      ) : (
        <>
        {loader ? <></> : 
        <div>
          <h6 className="permission_style">No Result Found</h6>
        </div>
        }
        </>
      )}
    </main>
  );
};
