import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import OneTransactionContext from "../../hooks/oneTransactionContext";
import UserContext from "../../hooks/userContext";
import "./OneTransactionStyle.scss";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ENDPOINTS } from "../../apis/EndPoints";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
export const Credit = () => {
  const { programs, oneTransactionOtherOptionResult } = useContext(
    OneTransactionContext
  );
  const [searchValue, setSearchValue] = useState("");
  const [allCredits, setAllCredits] = useState([]);
  // const [userProfileData, setUserProfileData] = useState()
  const [page, setPage] = useState(1);
  const [dataDetail, setDataDetail] = useState();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  console.log("statusOptionsstatusOptionsstatusOptions", statusOptions);
  const [programSelected, setProgramSelected] = useState();

  const [paymentOptions, setPaymentOptions] = useState([]);

  const [stateSelected, setStateSelected] = useState();
  const [isDeviation, setIsDeviation] = useState("0");

  const { handleLoader, permissions = [] } = useContext(UserContext);

  const [userType, setUserType] = useState();
  let navigate = useNavigate();

  const contractsSearch = (e) => {
    e.preventDefault();
    if (page === 1) {
      getAllCredits();
    } else {
      setPage(1);
    }
  };

  const getAllCredits = (deviation) => {
    const from = moment(dateFrom).format("YYYY-MM-DD");
    const to = moment(dateTo).format("YYYY-MM-DD");
    UserUtility.oneTransCredits(
      page,
      searchValue,
      stateSelected,
      from,
      to,
      programSelected,
      deviation ? deviation : isDeviation
    )
      .then((res) => {
        setDataDetail(res);
        setAllCredits(res.results);
      })
      .catch((err) => {
        console.log("error of getAllCredits: ", err);
      });
  };

  const handleSwitchBtn = (status, id, return_type) => {
    console.log("status switch: ", status);
    console.log("userType switch: ", userType);

    switch (userType) {
      case "maker":
        if (status === "studying_credit" || status === "created") {
          return (
            <Button
              className="small_btn"
              type="click"
              variant="contained"
              onClick={() => handleNavigate(id, return_type, "Study")}
            >
              Study
            </Button>
          );
        }
        if (status === "restudying_credit" || status === "profile_resolved") {
          return (
            <Button
              className="small_btn"
              type="click"
              variant="contained"
              onClick={() => {
                if (status === "profile_resolved") {
                  handleEditLoan(id, return_type);
                } else {
                  handleNavigate(id, return_type, "ReStudy");
                }
              }}
            >
              ReStudy
            </Button>
          );
        }
        break;

      case "checker":
        if (status === "checking_credit") {
          return (
            <Button
              className="small_btn"
              type="click"
              variant="contained"
              onClick={() => handleNavigate(id, return_type, "Check")}
            >
              Check
            </Button>
          );
        }
        break;

      default:
        break;
    }
  };

  const exportReport = () => {
    let obj = {
      name: "report 1",
      /*
      // will use later
      type: "one_transaction_user_installments_export_report",
      loan_settled: false,
      installments_settled: false,
      from: moment(dateFrom).format("YYYY-MM-DD"),
      to: moment(dateTo).format("YYYY-MM-DD")
      */
    };
    handleLoader(true);
    UserUtility.oneTransactionReport(obj)
      .then((res) => {
        console.log("res of oneTransactionReport: ", res);
        handleLoader(false);
        handleDownload(res);
      })
      .catch((err) => {
        handleLoader(false);
        console.log("err of oneTransactionReport: ", err);
      });
  };

  const handleDownload = (result) => {
    const link = document.createElement("a");
    link.download = result.name;
    link.href = result.file;
    link.click();
  };

  const importReport = (file) => {
    console.log("fiile: ", file);
    let token = localStorage.getItem("tools_token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);

    var formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    handleLoader(true);
    fetch(`${ENDPOINTS.url}onetransaction/payments_import/`, requestOptions)
      .then(async (res) => {
        handleLoader(false);
        if (!res.ok) {
          const text = await res.json();
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          toast.error(text.detail || text[0] || "something went wrong");
          // throw new Error(text);
        } else {
          // navigate(-1);
          toast.success("File has been saved successfully");
          return res.json();
        }
      })
      .catch((err) => {
        console.log("err of import: ", err);
        toast.error("Oops something went wrong of upload file");
        handleLoader(false);
      });
  };

  const filterWithDeviation = () => {
    isDeviation === "0" ? setIsDeviation("1") : setIsDeviation("0");
    if (page === 1) {
      getAllCredits(isDeviation === "0" ? "1" : "0");
    } else {
      setPage(1);
    }
  };
  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  // called it when status is profile_resolve to change it to restudy
  const handleEditLoan = (loanId, return_type) => {
    if (return_type === "profile_issue") {
      toast.error(
        "There is a profile issue. Cannot proceed until profile is resolved."
      );
    } else {
      UserUtility.oneTransEditCredit(loanId, { status: "restudying_credit" })
        .then((res) => {
          navigate(`/oneTransaction/credit/creditMemo/${loanId}/`, {
            state: {
              loanId: loanId,
            },
          });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleNavigate = (id, return_type, btn) => {
    if (
      (btn === "ReStudy" || btn === "Study") &&
      return_type === "profile_issue"
    ) {
      toast.error(
        "There is a profile issue. Cannot proceed until profile is resolved."
      );
    } else {
      navigate(`/oneTransaction/credit/creditMemo/${id}/`, {
        state: {
          loanId: id,
        },
      });
    }
  };
  useEffect(() => {
    getAllCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setStatusOptions(
      oneTransactionOtherOptionResult?.onetransactionloandata?.children?.status
    );
    setPaymentOptions(
      oneTransactionOtherOptionResult?.onetransactionloandata?.children
        ?.payment_method?.choices
    );
  }, [oneTransactionOtherOptionResult]);
  useEffect(() => {
    if (
      permissions.includes("one_transaction_credit_maker") &&
      permissions.includes("view_onetransactionloandata")
    ) {
      setUserType("maker");
    }
    if (
      permissions.includes("one_transaction_credit_checker") &&
      permissions.includes("view_onetransactionloandata")
    ) {
      setUserType("checker");
    }
  }, [permissions]);

  const fileInput = useRef(null);
  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    console.log("tttarget", e.target.files[0]);
    console.log("selectedFile selectedFile: ", selectedFile);
    selectedFile && Export_Import(ENDPOINTS.importLoans.url, selectedFile);
    // nabila comment ... add to line to empty input value to trigger when select the same file ands onChange fire after then
    e.target.value = null;
  };

  const Export_Import = (URL, File) => {
    console.log("Export_Import Export_Import");
    let token = localStorage.getItem("tools_token");
    var myHeaders = new Headers();
    var formdata = new FormData();
    myHeaders.append("Authorization", `token ${token}`);

    File && formdata.append(`file`, File);

    const requestOptions = File
      ? {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        }
      : {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };
    handleLoader(true);
    fetch(URL, requestOptions)
      .then(async (res) => {
        handleLoader(false);

        if (!res.ok) {
          const responseJson = await res.json();
          const errorMessage = responseJson.errors;
          toast.error(`Error: ${errorMessage}`);
          throw new Error(errorMessage);
        } else {
          handleLoader(false);

          const responseJson = await res.json();
          if (responseJson.errors) {
            toast.error(responseJson.errors, { autoClose: 10000 });
          } else {
            toast.success("File has been saved successfully");
          }

          !File && responseJson?.file
            ? (window.location.href = `${responseJson?.file}`)
            : console.log(`Not Have Results`);
          console.log(responseJson?.file);
          return responseJson;
        }
      })
      /*
      // nabila comment ... remove it :
      1- the refresh sceen is bad behavior and not solve the issue of import two file after each other
      2- .then is fire before worj the cenario of export and download file
      .then(()=>{
        window.location.reload()
      })
      */
      .catch((error) => {
        console.error("error:: ", error);
        toast.error("Oops something went wrong of upload file");
      });
  };

  return (
    <main className="oneTransaction_container">
      {permissions.includes("view_onetransactionloan") ? (
        <>
          <section>
            <form>
              <article className="searchBar_wrapper searchBar_wrapper_full">
                <section className="search_fields_wrapper">
                  <TextField
                    size="small"
                    placeholder="National ID / Phone Number"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    className="text-field"
                    label="National ID / Phone Number"
                  />
                  <article className="date_container">
                    {/* from */}
                    <section className="date_wrapper">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="from"
                          inputFormat="dd/MM/yyyy"
                          value={dateFrom}
                          onChange={(newValue) => {
                            setDateFrom(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </section>

                    {/* to */}
                    <section className="date_wrapper">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="to"
                          value={dateTo}
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            setDateTo(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </section>
                  </article>
                  <FormControl className="select-field">
                    <InputLabel> Credit Status</InputLabel>
                    <Select
                      className="select_onetrans_class"
                      size="small"
                      id="demo-simple-select"
                      value={stateSelected}
                      label="Select Status"
                      onChange={(e) => setStateSelected(e.target.value)}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {statusOptions?.choices?.map((filter, index) => {
                        return (
                          <MenuItem value={filter.value} key={index}>
                            {filter.display_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl className="select-field">
                    <InputLabel>Select Program</InputLabel>
                    <Select
                      className="select_onetrans_class"
                      size="small"
                      id="demo-simple-select"
                      //   value={stateSelected}
                      label="Select Program"
                      onChange={(e) => setProgramSelected(e.target.value)}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {programs?.map((filter, index) => {
                        return (
                          <MenuItem value={filter.name} key={index}>
                            {filter.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </section>

                <Button
                  className="searchButton"
                  type="submit"
                  variant="contained"
                  onClick={(e) => contractsSearch(e)}
                >
                  Search
                </Button>
              </article>
              {/* filters container */}
            </form>

            {/* new application btn */}
            <div className="div_btn_flex">
              {permissions.includes("one_transaction_deviation_approver") && (
                <Button
                  type="submit"
                  variant="contained"
                  className="addBtn credit_btn"
                  onClick={() => filterWithDeviation()}
                >
                  {isDeviation === "1" ? "All loans" : "Deviations Requests"}
                </Button>
              )}

              {/* report btn */}
              {permissions.includes(
                "one_transaction_user_installments_export_report"
              ) && (
                <Button
                  type="submit"
                  variant="contained"
                  className="outlineBtn credit_btn"
                  onClick={() => exportReport()}
                >
                  Export
                </Button>
              )}

              {permissions.includes(
                "one_transaction_user_installments_payment_import_report"
              ) && (
                <div className="file_div_import">
                  <input
                    onChange={(e) => importReport(e.target.files[0])}
                    // className="d-none"
                    type="file"
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    className="otherBtn credit_btn"
                    // component="span"
                    // onClick={() => importReport()}
                  >
                    Import
                  </Button>
                </div>
              )}

              <div className="addBtn " style={{ marginTop: "20px" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      height: "50px",
                      borderRadius: "8px",
                      border: "0",
                      color: "white",
                    }}
                  >
                    Loans Docs
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="bg-secondary"
                      onClick={() => {
                        Export_Import(ENDPOINTS.exportLoans.url);
                      }}
                    >
                      <div className="d-flex align-items-end justify-content-center">
                        <p className="">Export</p>
                      </div>
                    </Dropdown.Item>
                    {permissions.includes(
                      "one_transaction_loan_import_report"
                    ) && (
                      <>
                        <input
                          ref={fileInput}
                          type="file"
                          className={`form-control d-none`}
                          multiple
                          onChange={(e) => {
                            handleFileUpload(e);
                          }}
                        />
                        <Dropdown.Item className="bg-secondary">
                          <span
                            onClick={() => {
                              if (fileInput.current) {
                                fileInput.current.click();
                              }
                            }}
                          >
                            <div className="d-flex align-items-end justify-content-center">
                              <p className="">Import</p>
                            </div>
                          </span>
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </section>
          <TableContainer
            component={Paper}
            style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>National ID</StyledTableCell>
                  <StyledTableCell>Customer Name</StyledTableCell>
                  <StyledTableCell>Loan Program</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  {isDeviation === "1" && (
                    <>
                      <StyledTableCell>Deviation Name</StyledTableCell>
                      <StyledTableCell>Deviation Status</StyledTableCell>
                    </>
                  )}

                  {isDeviation === "0" && (
                    <StyledTableCell>Integration Reference</StyledTableCell>
                  )}
                  <StyledTableCell>Created Date</StyledTableCell>
                  <StyledTableCell>IScore Date</StyledTableCell>
                  {isDeviation === "0" && (
                    <StyledTableCell>Actions</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allCredits &&
                  allCredits?.map((ele, index) => {
                    return (
                      <StyledTableRow key={index.toString()}>
                        <StyledTableCell>
                          {permissions.includes("view_onetransactionloan") ? (
                            <Link
                              to={`/oneTransaction/credit/creditMemo/${ele.id}/`}
                              state={{
                                loanId: ele.id,
                                deviationTotalStatus:
                                  ele.deviation_view_approver_status,
                                showHistory: true,
                                isViewOnly: true,
                                paymentOptions: paymentOptions,
                              }}
                            >
                              <span className="nationalIdStyle">
                                {" "}
                                {
                                  ele?.userprofile?.onetransactionprofile
                                    ?.national_id
                                }
                              </span>
                            </Link>
                          ) : (
                            ele?.userprofile?.onetransactionprofile?.national_id
                          )}
                        </StyledTableCell>

                        <StyledTableCell>
                          {ele?.userprofile?.onetransactionprofile?.first_name}{" "}
                          {ele?.userprofile?.onetransactionprofile?.last_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {ele?.onetransactionloandata?.program_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {ele?.onetransactionloandata?.status}
                        </StyledTableCell>
                        {isDeviation === "1" && (
                          <>
                            <StyledTableCell>
                              {ele?.deviation_view_deviation === null
                                ? "-"
                                : ele?.deviation_view_deviation?.name}
                            </StyledTableCell>

                            <StyledTableCell>
                              {ele?.deviation_view_approver_status === null
                                ? "Pending"
                                : ele?.deviation_view_approver_status === true
                                ? "Approved"
                                : "Rejected"}
                            </StyledTableCell>
                          </>
                        )}

                        {isDeviation === "0" && (
                          <StyledTableCell>
                            {ele?.integration_reference}
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {moment(ele?.created).format("DD-MM-YYYY")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {ele.onetransactionloandata?.iscore_date &&
                            moment(
                              ele.onetransactionloandata.iscore_date
                              ).format("DD-MM-YYYY")}
                        </StyledTableCell>
                        {isDeviation === "0" && (
                          <StyledTableCell>
                            {permissions.includes(
                              "one_transaction_deviation_approver"
                            ) ? (
                              <></>
                            ) : (
                              <>
                                {/* <Link
                                to={`/oneTransaction/credit/creditMemo/${ele.id}/`}
                                state={{
                                  loanId: ele.id,
                                }}
                              > */}
                                <>
                                  {handleSwitchBtn(
                                    ele?.onetransactionloandata?.status,
                                    ele.id,
                                    ele.userprofile.onetransactionprofile
                                      .return_type
                                  )}
                                </>
                                {/* </Link> */}
                              </>
                            )}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationComponent
            brands={dataDetail}
            page={page}
            handleChange={handleChange}
          />
        </>
      ) : (
        <div>
          <h6 className="permission_style">
            You don't have permission to see this page
          </h6>
        </div>
      )}
    </main>
  );
};
