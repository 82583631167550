import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../hooks/userContext";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const BlackListHistory = ({ blacklistHistoryState }) => {
  const { permissions } = useContext(UserContext);
  const [blackListHistory, setBlackListHistory] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [results, setResults] = useState();
  const location = useLocation();
  const { userId } = location.state;
  const blackList_history = () => {
    if (blacklistHistoryState) {
      UserUtility.blackList_history(userId, pagesNumber)
        .then((res) => {
          setResults(res);
          setBlackListHistory(res.results);
        })
        .catch((err) => console.log("blackList_history err", err));
    }
  };
  const blackList_activate = (blackList_id) => {
    UserUtility.blackList_activate(userId, blackList_id)
      .then(() => {
        blackList_history();
      })
      .catch((err) => toast.error(err));
  };
  const blackList_deactivate = (blackList_id) => {
    UserUtility.blackList_deactivate(userId, blackList_id)
      .then(() => {
        blackList_history();
      })
      .catch((err) => toast.error(err));
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  useEffect(() => {
    blackList_history();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blacklistHistoryState, pagesNumber]);
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Modified</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Active</StyledTableCell>
              <StyledTableCell>Reason</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blackListHistory?.map((field, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{field.created}</StyledTableCell>
                  <StyledTableCell>{field.modified}</StyledTableCell>
                  <StyledTableCell>
                    {field.national_id
                      ? "National Id"
                      : field.phone
                      ? "Phone"
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell>{`${field.active}`}</StyledTableCell>
                  <StyledTableCell>{field.reason}</StyledTableCell>
                  <StyledTableCell style={{ width: "10%" }}>
                    {[
                      "ud_can_activate_user_blacklist_records",
                      "ud_can_deactivate_user_blacklist_records",
                    ].some((i) => permissions.includes(i)) ? (
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={() =>
                          field.active === false
                            ? blackList_activate(field.id)
                            : blackList_deactivate(field.id)
                        }
                      >
                        {field.active === true ? "Deactivate" : "Activate"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default BlackListHistory;
