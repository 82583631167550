import React, { useEffect, useState, useContext } from "react";
import { Button, Stack, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const LimitIncrease = () => {
  const { handleLoader, permissions } = useContext(UserContext);
  const [makerSearch, setMakerSearch] = useState();
  const [makerList, setMakerList] = useState([]);
  const [checkerList, setCheckerList] = useState();
  const [phone, setPhone] = useState();
  const [nationalId, setNationalId] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [results, setResults] = useState();

  const searchValue = (value) => {
    // eslint-disable-next-line no-unused-expressions
    value.length > 11
      ? (setNationalId(value), setPhone(null))
      : (setPhone(value), setNationalId(null));
  };

  const limitMaker_search = (e) => {
    if (e === "Enter") {
      handleLoader(true);
      UserUtility.limitMaker_search(phone, nationalId)
        .then((res) => {
          setMakerSearch(res.results);
          handleLoader(false);
        })
        .catch((err) => {
          console.error("limitMaker_search", err);
          handleLoader(false);
        });
    }
  };

  const limitMaker_list = () => {
    handleLoader(true);
    UserUtility.limitMaker_list(pagesNumber)
      .then((res) => {
        setResults(res);
        setMakerList(res.results);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("limitMaker_list", err);
        handleLoader(false);
      });
  };
  const limitChecker_list = () => {
    handleLoader(true);
    UserUtility.limitChecker_list(pagesNumber)
      .then((res) => {
        setResults(res);
        setCheckerList(res.results);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("limitChecker_list", err);
        handleLoader(false);
      });
  };
  const limitMaker = permissions?.includes("is_limit_maker");
  const limitChecker = permissions?.includes("is_limit_checker");
  useEffect(() => {
    if (limitMaker) {
      limitMaker_list();
    }
    if (limitChecker) {
      limitChecker_list();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber, limitMaker, limitChecker, permissions]);
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  return (
    <div className="UserProfile">
      {permissions?.includes("is_limit_maker") && (
        <Stack direction="horizontal" className="mt-5" gap={4}>
          <Form.Control
            onKeyPress={(e) => limitMaker_search(e.key)}
            type="text"
            placeholder="Client's national ID or phone number"
            className="me-auto"
            size="lg"
            onChange={(e) => searchValue(e.target.value)}
          />

          <Button
            variant="success"
            className="px-5"
            onClick={() => limitMaker_search("Enter")}
          >
            Search
          </Button>
        </Stack>
      )}
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>ID Number</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Limit state</StyledTableCell>
              <StyledTableCell>Sent to drive</StyledTableCell>
              <StyledTableCell>Passed Iscore</StyledTableCell>
              <StyledTableCell>Maker</StyledTableCell>
              <StyledTableCell>Checker</StyledTableCell>
            </TableRow>
          </TableHead>
          {permissions?.includes("is_limit_maker") ? (
            <TableBody>
              {(makerSearch?.length > 0 ? makerSearch : makerList).map(
                (e, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{e.id}</StyledTableCell>
                      <StyledTableCell>{e.national_id}</StyledTableCell>
                      <StyledTableCell>{e.p_state}</StyledTableCell>
                      <StyledTableCell>
                        {e.limit_data.limit_state
                          ? e.limit_data.limit_state
                          : "null"}
                      </StyledTableCell>
                      <StyledTableCell>{`${e.sent_to_drive}`}</StyledTableCell>
                      <StyledTableCell>{`${e.passed_iscore_test}`}</StyledTableCell>
                      <StyledTableCell>
                        {e.limit_data.added_by_phone ? (
                          <Link
                            to="/userDetails/LimitIncrease"
                            state={{
                              userId: e.id,
                              manual_state: "limitReturn_To_Maker",
                              type: "limitMaker",
                              LimitIncrease: true,
                            }}
                          >
                            <Button variant="success">
                              {e.limit_data.added_by_phone}
                            </Button>
                          </Link>
                        ) : (
                          <Link
                            to="/userDetails/LimitIncrease"
                            state={{
                              userId: e.id,
                              type: "limitMaker",
                              LimitIncrease: true,
                            }}
                          >
                            <Button variant="success" style={{ width: 100 }}>
                              Study
                            </Button>
                          </Link>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {e.limit_data.approved_by_phone
                          ? e.limit_data.approved_by_phone
                          : "null"}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          ) : null}
          <TableBody>
            {checkerList?.map((ele, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{ele.id}</StyledTableCell>
                  <StyledTableCell>{ele.national_id}</StyledTableCell>
                  <StyledTableCell>{ele.p_state}</StyledTableCell>
                  <StyledTableCell>
                    {ele.limit_data.limit_state}
                  </StyledTableCell>
                  <StyledTableCell>{`${ele.sent_to_drive}`}</StyledTableCell>
                  <StyledTableCell>{`${ele.passed_iscore_test}`}</StyledTableCell>
                  <StyledTableCell>{`${ele.limit_data.added_by_phone}`}</StyledTableCell>
                  {
                    <StyledTableCell>
                      <Link
                        to="/userDetails/LimitIncrease"
                        state={{
                          userId: ele.id,
                          type: "limitChecker",
                          LimitIncrease: true,
                        }}
                      >
                        <Button variant="success">
                          {ele.limit_data.approved_by_phone === null
                            ? "Check"
                            : ele.limit_data.approved_by_phone}
                        </Button>
                      </Link>
                    </StyledTableCell>
                  }
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default LimitIncrease;
