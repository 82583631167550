/* eslint-disable no-sequences */
import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import { toast, ToastContainer } from "react-toastify";
import UserContext from "../../hooks/userContext";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import PaginationComponent from "../../components/pagination/PaginationComponent";
import UserRequestModals from "./UserRequestModals";

const UserRequset = () => {
  const { handleLoader, permissions } = useContext(UserContext);
  const [list, setList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [department, setDepartment] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [requestValue, setRequestValue] = useState([]);
  const [requestId, setRequestId] = useState();
  const [id, setId] = useState();
  const [startDate, setStartDateByFilter] = useState("");
  const [endDate, setEndDateByFilter] = useState("");
  const [actionData, setActionData] = useState();
  const [reason, setReason] = useState();
  const [modalName, setModalName] = useState();
  const [modal, setModal] = useState(false);
  const [pagesNumber, setPagesNumber] = useState(1);
  const [state, setState] = useState();
  const [results, setResults] = useState([]);
  const [payingStatus, setPayingStatus] = useState();
  const [reload, setReload] = useState(false);
  const [commentRow, setCommentRow] = useState(false);
  const [index, setIndex] = useState();

  // const createdStartDate = format(dateCreated[0].startDate, "yyyy-MM-dd");
  // const createdEndDate = format(dateCreated[0]?.endDate, "yyyy-MM-dd");

  const user_request_list = () => {
    setList([]);
    handleLoader(true);
    UserUtility.user_request_list(
      pagesNumber,
      state,
      requestValue,
      id,
      department,
      startDate,
      endDate,
      payingStatus
    )
      .then((res) => {
        setResults(res);
        setList(res.results);
        handleLoader(false);
        setReload(false);
      })
      .catch((err) => {
        console.error("user_request_list", err);
        handleLoader(false);
        toast.error(err);
        setReload(false);
      });
  };
  const department_options = () => {
    UserUtility.department_options()
      .then((res) => {
        setDepartmentOptions(res);
      })
      .catch((err) => console.error("request_types", err));
  };

  const status_options = () => {
    UserUtility.status_options()
      .then((res) => {
        setStatusOptions(res);
      })
      .catch((err) => console.error("status_options", err));
  };
  const request_types = () => {
    UserUtility.request_types()
      .then((res) => {
        setRequestTypes(res);
      })
      .catch((err) => console.error("request_types", err));
  };

  const request_tool_action = (actions, id) => {
    handleLoader(true);
    UserUtility.request_tool_action(
      actions || actionData?.action,
      id || actionData?.reference,
      reason
    )
      .then((res) => {
        setModal(false);
        handleLoader(false);
        user_request_list();
        toast.error(res.details);
        setTimeout(() => {
          setReload(true);
        }, 2000);
        setReason()
      })
      .catch((err) => {
        handleLoader(false);
        console.error("request_tool_action", err);
        toast.error(`${err}`);
        setReload(false);
      });
  };

  const handleClick = (e) => {
    if (e === "Enter") {
      user_request_list();
      setPagesNumber(1);
    }
  };

  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  const requestFunction = (e, id) => {
    if (e?.target?.checked) {
      setRequestValue([...requestValue, id]);
    } else {
      const requestsId = requestValue?.filter((ele) => {
        return ele !== id;
      });
      setRequestValue(requestsId);
    }
  };

  const requestTypeFilter = requestTypes?.map((ele, index) => {
    return (
      <div key={index} className="d-flex mx-2 my-3 ">
        <Form.Check
          name="inlineRadioOptions"
          className="me-2"
          type="checkbox"
          onChange={(e) => {
            requestFunction(e, ele.id);
          }}
        />
        <Form.Text className="m-0 text-body fw-bold">
          {ele.request_type?.replace(/_/g, " ")}
        </Form.Text>
      </div>
    );
  });

  useEffect(() => {
    user_request_list();
    status_options();
    request_types();
    department_options();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber, permissions]);
  useEffect(() => {
    if (reload) return user_request_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  function handleStartDateChange(event) {
    setStartDateByFilter(event.target.value);
  }
  function handleEndDateChange(event) {
    setEndDateByFilter(event.target.value);
  }

  return (
    <div className="UserProfile mb-5 " style={{ width: "90%" }}>
      <ToastContainer
        position="top-center"
        // autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack direction="horizontal" className="mt-5" gap={4}>
        <Form.Control
          onKeyPress={(e) => handleClick(e.key)}
          type="number"
          placeholder="ID"
          className="me-auto w-25"
          size="sm"
          style={{ height: "40px", borderRadius: "8px" }}
          onChange={(e) => setId(e.target.value)}
        />

        <Form.Label
          style={{ width: "10%", fontSize: "12px", fontWeight: "bold" }}
        >
          Start date
        </Form.Label>
        <Form.Control
          className="w-25"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <Form.Label
          style={{ width: "10%", fontSize: "12px", fontWeight: "bold" }}
        >
          End date
        </Form.Label>
        <Form.Control
          className="w-25"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
        />

        <Form.Select
          style={{ width: "20%", height: "40px", borderRadius: "8px" }}
          size="sm"
          onChange={(e) => {
            setState(e.target.value);
          }}
        >
          <option value={""}>Select By Status</option>
          {statusOptions?.map((res, id) => (
            <option value={res.status} name={res.display} key={id}>
              {res.display}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          style={{ width: "23%", height: "40px", borderRadius: "8px" }}
          size="sm"
          onChange={(e) => {
            setDepartment(e.target.value);
          }}
        >
          <option value={""}>Select By Department</option>
          {departmentOptions?.map((res, id) => (
            <option value={res.department} name={res.display} key={id}>
              {res.display}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="success"
          className="px-5"
          onClick={() => {
            handleClick("Enter");
          }}
        >
          Search
        </Button>
      </Stack>

      <Form.Group
        className="d-flex mt-3 position-relative"
        // style={{ zIndex: 10 }}
      ></Form.Group>
      <Form.Group className="d-flex align-items-center">
        {requestTypeFilter}
        <Form.Select
          style={{
            width: "15%",
            height: "40px",
            borderRadius: "8px",
            marginLeft: "1%",
          }}
          size="sm"
          onChange={(e) => {
            setPayingStatus(e.target.value);
          }}
        >
          <option value={""}>Select By Paid</option>
          <option value="1" name="Yes">
            Paid
          </option>
          <option value="0" name="No">
            Not Paid
          </option>
        </Form.Select>
      </Form.Group>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 2400 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Request ID</StyledTableCell>
              <StyledTableCell>National ID</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Request Type</StyledTableCell>
              <StyledTableCell>Comment</StyledTableCell>
              <StyledTableCell>Reason</StyledTableCell>
              <StyledTableCell>Delivery Branch</StyledTableCell>
              <StyledTableCell>Limit Before</StyledTableCell>
              <StyledTableCell>Limit After</StyledTableCell>
              <StyledTableCell>Hold</StyledTableCell>
              <StyledTableCell>Hold Request</StyledTableCell>
              <StyledTableCell>Account Closed</StyledTableCell>
              <StyledTableCell>Require Payment</StyledTableCell>
              <StyledTableCell>Paid</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Internal Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((ele, id) => {
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell
                    onClick={() => (
                      setRequestId(ele.reference_number),
                      setModal(true),
                      setModalName("historyModal")
                    )}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {ele.reference_number}
                  </StyledTableCell>

                  <StyledTableCell>{ele.national_id}</StyledTableCell>
                  <StyledTableCell>{ele.phone}</StyledTableCell>
                  <StyledTableCell>{ele.name}</StyledTableCell>
                  <StyledTableCell>{ele.request_type}</StyledTableCell>
                  <StyledTableCell>
                    {ele.comment !== null && ele.comment?.length !== 0 && (
                      <Form.Control
                        onClick={() => (setCommentRow(true), setIndex(id))}
                        as="textarea"
                        rows={commentRow && id === index ? 3 : 1}
                        readOnly
                        // defaultValue={commentRow&&id===index&&ele.comment  || "open"}
                        value={
                          commentRow && id === index
                            ? ele.comment
                            : "Click to show comment"
                        }
                        style={{ cursor: "pointer", resize: "none",overflow:commentRow && id === index?"":"hidden" }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{ele.reason}</StyledTableCell>
                  <StyledTableCell
                    onClick={() =>
                      ele.forsa_branch !== null &&
                      (setModal(true),
                      setRequestId(ele.reference_number),
                      setModalName("branchModal"))
                    }
                    style={
                      ele.forsa_branch && { cursor: "pointer", color: "blue" }
                    }
                  >
                    {ele.forsa_branch}
                  </StyledTableCell>
                  <StyledTableCell>
                    {ele?.limit_decrease_request_data?.limit_before}
                  </StyledTableCell>
                  <StyledTableCell>
                    {ele?.limit_decrease_request_data?.limit_after}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      checked={ele.user_on_hold}
                      disabled
                    />
                  </StyledTableCell>

                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      checked={ele.user_on_hold_request}
                      disabled
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      checked={ele.account_closed_flag}
                      disabled
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      checked={ele.payment_required}
                      disabled
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Form.Check type="checkbox" checked={ele.paid} disabled />
                  </StyledTableCell>
                  <StyledTableCell>{ele.created}</StyledTableCell>
                  <StyledTableCell>{ele.status}</StyledTableCell>

                  <StyledTableCell>
                    {ele.internal_status?.includes("_")
                      ? ele.internal_status.replace(/_/g, " ")
                      : ele.internal_status}
                  </StyledTableCell>

                  <StyledTableCell>
                    {ele.tool_actions[0] && (
                      <Button
                        className="m-1 "
                        variant="success"
                        onClick={() =>
                          ele.tool_actions[0].includes("Return")
                            ? (setModal(true),
                              setModalName("reasonModal"),
                              setActionData({
                                action: ele.tool_actions[0],
                                reference: ele.reference_number,
                              }))
                            : request_tool_action(
                                ele.tool_actions[0],
                                ele.reference_number
                              )
                        }
                      >
                        {ele.tool_actions[0]?.replace(/_/g, " ")}
                      </Button>
                    )}
                    {ele.tool_actions[1] && (
                      <Button
                        variant="success"
                        onClick={() =>
                          ele.tool_actions[1].includes("Return")
                            ? (setModal(true),
                              setModalName("reasonModal"),
                              setActionData({
                                action: ele.tool_actions[1],
                                reference: ele.reference_number,
                              }))
                            : request_tool_action(
                                ele.tool_actions[1],
                                ele.reference_number
                              )
                        }
                      >
                        {ele.tool_actions[1]}
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <UserRequestModals
        isOpen={modal}
        setModal={setModal}
        shouldCloseOnOverlayClick={true}
        requestId={requestId}
        setReason={setReason}
        request_tool_action={() => request_tool_action()}
        modalName={modalName}
        setReload={setReload}
      />
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default UserRequset;
