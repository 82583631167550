import { InputLabel } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { UserUtility } from "../../apis/UserUtility";
import MultipleSelection from "../dropdown/MultipleSelection";
import './TagStyle.scss'
export const TagComponent = ({
    isDisabled,
    tagState,
    brandId,
    selectedTagIds,
    tagIds
}) => {

    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const getTags = () => {
        UserUtility.getTags().then(res => {
            console.log('res getTags: ', res)
            setTags(res.results)
            if (res.next) {
                getPaginateData(res.next)
            }
        })
    }


    const getPaginateData = (url) => {
        UserUtility.getNextPageData(url)
            .then((res) => {
                // state of assigned orunussigned maker or checker
                // let arr = res.results.map((item) => {
                //   return { id: item.id, email: item.email };
                // });
                // setState((old) => [...old, ...arr]);
                setTags((old) => [...old, res.results])
                if (res.next) {
                    getPaginateData(res.next)
                }

            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        if (tagState) {
            getTags()
        }
    }, [tagState, brandId])

    useEffect(() => {
        let arr = [];
        if (tagIds && tagIds.length > 0) {
            for (let i = 0; i < tagIds.length; i++) {
                let findObj = tags.find((item) => item.id === tagIds[i])
                console.log('findObj: ', findObj)
                if (findObj) {
                    arr.push(findObj)
                }
            }
            setSelectedTags(arr)
        }
    }, [tags])

    return (
        <div className="card_tag_dropdown">
            <InputLabel className="label">Select Tags</InputLabel>
            <MultipleSelection
                disabled={isDisabled}
                optionsItem={tags}
                type="tags"
                label="Tags"
                finalSelectedList={(values) => {
                    console.log('values values: ', values)
                    selectedTagIds(values)
                }}
                selectedValues={selectedTags}
                isTitle
            />
        </div>
    )
}