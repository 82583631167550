import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";

const CreateSelectComponent = (props) => {
  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  console.log("props.value test: ", props.value);

  const ColourOption = [
    { name: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { name: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { name: "purple", label: "Purple", color: "#5243AA" },
    { name: "red", label: "Red", color: "#FF5630", isFixed: true },
    { name: "orange", label: "Orange", color: "#FF8B00" },
    { name: "yellow", label: "Yellow", color: "#FFC400" },
    { name: "green", label: "Green", color: "#36B37E" },
    { name: "forest", label: "Forest", color: "#00875A" },
    { name: "slate", label: "Slate", color: "#253858" },
    { name: "silver", label: "Silver", color: "#666666" },
  ];

  return (
    <section style={props?.customStyle}>
      {/* <AsyncPaginate
        // debounceTimeout={100000000000}
        // key={JSON.stringify(regionName)}
        value={props.value || ""}
        loadOptions={props.loadOptions}
        getOptionValue={(option) => option.name}
        getOptionLabel={(option) => option.name}
        onChange={onChange}
        isSearchable
        placeholder={props?.placeholder}
        isDisabled={props?.disabled ? props?.disabled : false}
        isLoading={props.loading}
        additional={{
          page: 1,
        }}
        isMulti={props.isMulti || false}
        className="AsyncPaginateStyle"
      />

      <CreatableAsyncPaginate
        value={props.value || ""}
        loadOptions={props.loadOptions}
        onChange={onChange}
      /> */}

      <CreatableSelect
        placeholder={props?.placeholder || "Select ..."}
        options={props?.options}
        value={props.value || ""}
        isDisabled={props?.disabled ? props?.disabled : false}
        onChange={props?.onChange}
        isClearable
      />
    </section>
  );
};

export { CreateSelectComponent };
