/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ENDPOINTS } from "../../apis/EndPoints";
import axios from "axios";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";

const FinanceSafe = () => {
  const theme = createTheme({
    palette: {
      main: {
        main: "#41b6a8",
        contrastText: "#fff",
      },
    },
  });
  let navigate = useNavigate();
  let today = new Date().toISOString().slice(0, 10);

  const { handleLoader } = useContext(UserContext);
  const [total, setTotal] = useState();
  const [list, setList] = useState();
  const [nationalId, setNationalId] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  toast.configure();
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  useEffect(() => {
    advanced_users();
  }, [pagesNumber]);

  const advanced_users = () => {
    handleLoader(true);
    UserUtility.advanced_users(pagesNumber, nationalId)
      .then((res) => {
        handleLoader(false);
        setTotal(res);
        setList(res.results);
      })
      .catch((err) => {
        console.error("advanced_users", err);
        handleLoader(false);
      });
  };

  const handleClick = (e) => {
    if (nationalId?.length === 14) {
      if (e === "Enter") {
        advanced_users();
        setPagesNumber(1);
      }
    }
  };

  const exportFinanceSafe = async () => {
    handleLoader(true);
    const token = localStorage.getItem("tools_token");
    const headers = {
      "Content-Type": "blob",
      Authorization: `token ${token}`,
    };
    const config = {
      method: "GET",
      url: `${ENDPOINTS.url}finance/cash_collection/advanced_payments/advanced_payment_balance/export/`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `finance-safe-${today}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      throw Error(error);
    }
  };
  return (
    <div className="UserProfile">
      <div className="textStyle">
        <Form.Text className="fs-5" style={{ color: "white" }}>
          Finance Safe
        </Form.Text>
      </div>
      <Row className="align-items-center pt-4 ">
        <Col lg={5} md={4} sm={12} className="d-flex justify-content-between">
          <Form.Control
            onKeyPress={(e) => handleClick(e.key)}
            type="number"
            size="medium"
            placeholder="National ID "
            onChange={(e) => setNationalId(e.target.value)}
            label="National ID "
          />
        </Col>
        <Col
          lg={6}
          md={8}
          sm={12}
          className="d-flex h-75 justify-content-evenly"
          style={{ alignItems: "self-end" }}
        >
          <ThemeProvider theme={theme}>
            <Button
              className="px-5"
              variant="contained"
              color="main"
              disabled={nationalId?.length !== 14}
              onClick={() => handleClick("Enter")}
            >
              Search
            </Button>
            <Button
              className="px-5"
              variant="contained"
              component="span"
              color="main"
              onClick={() => exportFinanceSafe()}
            >
              Export
            </Button>
            <Button
              className="px-5"
              variant="contained"
              component="span"
              color="main"
              onClick={() => {
                navigate("/AdvancedPayments");
              }}
            >
              Add Advanced Payments
            </Button>
          </ThemeProvider>
        </Col>
      </Row>
      <TableContainer
        // component={Paper}
        style={{ marginRight: 20, marginTop: 30 }}
      >
        <Table striped responsive hover className="mt-5 users_table">
          <TableHead>
            <TableRow>
              <StyledTableCell>National ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Total Forsa Advanced Payment</StyledTableCell>
              <StyledTableCell>Forsa Remaining Amount</StyledTableCell>
              <StyledTableCell>
                Total OneTransaction Advanced Payment
              </StyledTableCell>
              <StyledTableCell>OneTransaction Remaining Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((ele, index) => (
              <StyledTableRow key={index.toString()}>
                <StyledTableCell>{ele.national_id} </StyledTableCell>
                <StyledTableCell>{ele.user_profile_name} </StyledTableCell>
                <StyledTableCell>
                  {ele.total_forsa_advanced_payment}{" "}
                </StyledTableCell>
                <StyledTableCell>{ele.forsa_remaining_amount} </StyledTableCell>
                <StyledTableCell>
                  {ele.total_one_txn_advanced_payment}{" "}
                </StyledTableCell>
                <StyledTableCell>
                  {ele.one_txn_remaining_amount}{" "}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={total}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default FinanceSafe;
