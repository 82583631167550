import Modal from "react-bootstrap/Modal";
import PaginationComponent from "../pagination/PaginationComponent";
import TableLoginHistoryData from "./TableLoginHistoryData";

function PopupLoginHistory(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="w-100 h-100"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="fs-5 mb-0">Login Info. History</p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TableLoginHistoryData historyData={props.historyData} />
      </Modal.Body>

      <Modal.Footer>
        <PaginationComponent
          brands={props.creditData}
          page={props.page}
          handleChange={props.handleChange}
          total={false}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default PopupLoginHistory;
