import React from 'react'
import Table from 'react-bootstrap/Table';

const TableCreditData = ({ creditData }) => {
    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Facility</th>
                    <th>Type</th>
                    <th>Limit</th>
                    <th>Sanc Date</th>
                </tr>
            </thead>
            <tbody>
                {
                    creditData.results?.map((credit, i) => (
                        <tr key={i}>
                            <td>{credit.facility_type}</td>
                            <td>{credit.type}</td>
                            <td>{credit.max_facility_limit}</td>
                            <td>{credit.facility_sanc_date}</td>
                        </tr>
                    ))
                }
                <tr>
                    <td></td>
                    <td colSpan={2}></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default TableCreditData
