export let textFieldsAudience = [
    {
        label: "Title",
        name: "title",
        error: false,
        type: "text",
    },
    {
        label: "Description",
        name: "description",
        error: false,
        type: "textArea",
    }

];

export let textFieldsBasicInfo = [
    {
        label: "Title",
        name: "title",
        error: true,
        type: "text",
    },
    {
        label: "Content",
        name: "message",
        error: true,
        type: "textarea",
    },
    // {
    //     label: "Content Type",
    //     name: "content",
    //     error: true,
    //     type: "text",
    // },
    // {
    //     label: "Content Object ID",
    //     name: "content_object_id",
    //     error: true,
    //     type: "number",
    // },

    // {
    //     label: "broadcast",
    //     name: "broadcast",
    //     error: true,
    //     type: "checkbox",
    // }
];

export let textFieldsTargeting = [
    {
        label: "City",
        name: "governorate",
        error: true,
        isDropDown: true,
    },
    {
        label: "Area",
        name: "area",
        error: true,
        isDropDown: true,
    },
    {
        label: "User State",
        name: "p_states",
        error: true,
        isDropDown: true,
    },
    {
        label: "Gender",
        name: "userprofile__gender",
        error: true,
        isDropDown: true,
    },
    {
        label: "Preference",
        name: "preferences",
        isDropDown: true
    },


    {
        label: "Corporate",
        name: "corporate__in",
        type: "number",
        isDropDown: true
    }

];

export let TextFieldsRangTargeting =
    [
        {
            attribute: 'Age',
            attr_type: 'number',
            nestedArray: [
                {
                    label: "From",
                    name: "age_gte",
                    type: "number",
                },
                {
                    label: "To",
                    name: "age_lte",
                    type: "number",
                },
            ]
        },
        {
            attribute: 'Remaining Amount',
            attr_type: 'number',
            nestedArray: [
                {
                    label: "From",
                    name: "remaining_amount_gte",
                    type: "number",
                },

                {
                    label: "To",
                    name: "remaining_amount_lte",
                    type: "number",
                },
            ]
        },
        {
            attribute: 'Finance Limit',
            attr_type: 'number',
            nestedArray: [
                {
                    label: "From",
                    name: "profile_finance_limit_gte",
                    type: "number"
                },
                {
                    label: "To",
                    name: "profile_finance_limit_lte",
                    type: "number"
                },
            ]
        },
        {
            attribute: 'No. of transactions',
            attr_type: 'number',
            nestedArray: [
                {
                    label: "From",
                    name: "no_of_transactions__from",
                    type: "number"
                },
                {
                    label: "To",
                    name: "no_of_transactions__to",
                    type: "number"
                },
            ]
        },
        {
            attribute: 'Activition Date',
            attr_type: 'date',
            nestedArray: [
                {
                    label: "From",
                    name: "date_of_activation__gte",
                    type: "date"
                },
                {
                    label: "To",
                    name: "date_of_activation__lte",
                    type: "date"
                },
            ]
        },
        {
            attribute: 'Register Form Date',
            attr_type: 'date',
            nestedArray: [
                {
                    label: "From",
                    name: "form_submit_date__gte",
                    type: "date"
                },
                {
                    label: "To",
                    name: "form_submit_date__lte",
                    type: "date"
                },
            ]
        }
    ]


export let textFieldsSchedulingAppointment = [
    {
        label: "Select Date",
        name: "select_date",
        error: true,
        type: 'date',
    },
    {
        label: "Select Time",
        name: "select_time",
        error: true,
        type: 'time',
    },

];