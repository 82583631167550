import { Formik } from "formik"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { UserUtility } from "../../apis/UserUtility"
import UserContext from "../../hooks/userContext"
import { ENDPOINTS } from "../../apis/EndPoints";
import "./GeneralInfo.scss";
import Button from "@material-ui/core/Button";
import {
    Checkbox,
    TextField,
} from "@mui/material";

export const SliderComponent = ({
    isBrandPublished,
    isDisabled,
    brandId,
    sliderState
}) => {
    const [sliderDetail, setSliderDetail] = useState({
        title_ar: '',
        title_en: '',
        thumbnail: null,
        thumbnail_web: null,
        background: null,
        background_web: null,
        order: 0,
        view_in_home: false
    });

    const [method, setMethod] = useState('POST')
    const { handleLoader } = useContext(UserContext);
    console.log('ENDPOINTS.url: ', ENDPOINTS.url)

    const getSlider = () => {
        UserUtility.getBtandSlider(brandId).then(res => {
            if (res && res.id) {
                console.log('res of getBtandSlider: ', res)
                setMethod('PATCH')
                setSliderDetail(res)
            } else {
                setMethod('POST')
                setSliderDetail({})
            }
        }).catch(err => {
            console.log('err of getBtandSlider: ', err)
            toast.error(err)
        })
    }

    const submitSlider = () => {
        let token = localStorage.getItem("tools_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `token ${token}`);
        var formdata = new FormData();

        let url = (method === 'POST') ? `${ENDPOINTS.url}management_dashboard/brand/slider/${brandId}/` : `${ENDPOINTS.url}management_dashboard/slider/${brandId}/`
        formdata.append("related_brand", brandId)
        formdata.append("title_en", sliderDetail.title_en)
        formdata.append("title_ar", sliderDetail.title_ar)

        formdata.append("order", sliderDetail.order)
        formdata.append("view_in_home", sliderDetail.view_in_home)

        // in case edit slider each of (thumbnail,thumbnail_web,background,background_web) will be string value this will get error ,So check this line to prevent it
        if (sliderDetail.thumbnail && typeof sliderDetail.thumbnail === "object") {
            formdata.append("thumbnail", sliderDetail.thumbnail)
        }

        if (sliderDetail.background && typeof sliderDetail.background === "object") {
            formdata.append("background", sliderDetail.background)
        }

        if (sliderDetail.background_web && typeof sliderDetail.background_web === "object") {
            formdata.append("background_web", sliderDetail.background_web)
        }

        if (sliderDetail.thumbnail_web && typeof sliderDetail.thumbnail_web === "object") {
            formdata.append("thumbnail_web", sliderDetail.thumbnail_web)
        }


        const requestOptions = {
            method: method,
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };


        if (sliderDetail.title_en && sliderDetail.title_ar && sliderDetail.thumbnail
            && sliderDetail.background && sliderDetail.thumbnail_web && sliderDetail.background_web) {
            handleLoader(true);
            fetch(
                url,
                requestOptions
            )
                .then(async (res) => {
                    if (!res.ok) {

                        // to fetch res and convert it to json object
                        // The main reason clone() exists is to allow multiple uses of Body objects
                        const txtJson = await res.clone().json()
                        for (const [key, value] of Object.entries(txtJson.errors)) {
                            let final = (key + "-").concat(value);
                            toast.error(final);
                        }
                        handleLoader(false);

                    } else {
                        handleLoader(false);
                        toast.success("Slider has been saved successfully");
                        getSlider()
                        return res.json();
                    }
                })
                .catch((err) => {
                    console.log('error of slider: ', err)
                    toast.error(err.errors)
                    // toast.error("Oops something went wrong of images");
                    handleLoader(false);
                });
        } else {
            toast.warning('Must fill all slider fields')
        }
    }

    // updateSliderData is a function to update our user data by passing the field name with the new value
    const updateSliderData = (fieldName, newValue) => {
        console.log(`fieldName: ${fieldName} and newValue: ${newValue}`)
        setSliderDetail({ ...sliderDetail, [fieldName]: newValue });
    };

    useEffect(() => {
        console.log('brandId: ', brandId)
        if (sliderState) {
            getSlider()
        }

    }, [brandId, sliderState])

    return (
        <section className="slider_container">
            <section className="">
                <div className="row">
                    <section className="col-6">
                        <h3>Title (en):</h3>
                        {console.log("sliderDetail['title_en']: ", sliderDetail['title_en'])}
                        <TextField
                            placeholder="Forsa"
                            name="title_en"
                            variant="filled"
                            value={sliderDetail['title_en'] || ""}
                            onChange={(e) => updateSliderData('title_en', e.target.value)}
                            disabled={isDisabled || !brandId}
                        />
                    </section>

                    <section className="col-6">
                        <h3>Title (ar):</h3>
                        <TextField
                            name="title_ar"
                            placeholder="فرصة"
                            variant="filled"
                            value={sliderDetail['title_ar'] || ''}
                            onChange={(e) => updateSliderData('title_ar', e.target.value)}
                            style={{ direction: "rtl" }}
                            disabled={isDisabled || !brandId}
                        />
                    </section>
                </div>

                <div className="images">
                    <div className="row">
                        <section className="col-6">
                            <h3>Thumbnail</h3>
                            <div>
                                <label className="image" htmlFor="contained-button-file-web">
                                    <div>
                                        <TextField
                                            placeholder="image url"
                                            variant="filled"
                                            onChange={() => console.log('onChange')}
                                            value={sliderDetail['thumbnail']?.name || sliderDetail['thumbnail']}
                                            InputProps={{
                                                readOnly: true,
                                            }}

                                            disabled={isDisabled || !brandId}
                                        />
                                        {(!isDisabled && brandId) && (
                                            <Button
                                                variant="contained"
                                                component="label"
                                            >
                                                Upload
                                                <input
                                                    type="file"
                                                    hidden
                                                    name="thumbnail"
                                                    onChange={(e) => {
                                                        console.log('e.target.files[0]: ', e.target.files[0])
                                                        updateSliderData("thumbnail", e.target.files[0])
                                                    }
                                                    }
                                                />
                                            </Button>
                                        )}
                                        <p className="image_hint">
                                            *Hint: Dimension is: 400 * 400
                                        </p>
                                    </div>
                                </label>
                            </div>
                            {sliderDetail?.thumbnail &&
                                sliderDetail?.thumbnail.size &&
                                sliderDetail?.thumbnail.size >= 400000 && (
                                    <div className="error_message">
                                        File size exceeds the maximum limit (400kb).
                                    </div>
                                )}
                        </section>

                        <section className="col-6">
                            <h3>Background</h3>
                            <div>
                                <label className="image" htmlFor="background-file">

                                    <div>

                                        <TextField
                                            id="outlined-basic"
                                            placeholder="image url"
                                            variant="filled"
                                            value={sliderDetail['background']?.name || sliderDetail['background']}
                                            InputProps={{
                                                readOnly: true,
                                            }}

                                            disabled={isDisabled || !brandId}
                                        />
                                        {(!isDisabled && brandId) && (
                                            <Button
                                                variant="contained"
                                                component="label"
                                            >
                                                Upload
                                                <input
                                                    type="file"
                                                    hidden
                                                    name="background"
                                                    onChange={(e) =>
                                                        updateSliderData("background", e.target.files[0])
                                                    }
                                                />
                                            </Button>
                                        )}
                                        <p className="image_hint">
                                            *Hint: Dimension is: 325 * 154
                                        </p>
                                    </div>
                                </label>
                            </div>

                        </section>
                    </div>

                    <div className="row">
                        <section className="col-6">
                            <h3>Thumbnail Web</h3>
                            <div>
                                <label className="image" htmlFor="contained-button-file-web">

                                    <div>
                                        <TextField
                                            placeholder="image url"
                                            variant="filled"
                                            value={sliderDetail['thumbnail_web']?.name || sliderDetail['thumbnail_web']}
                                            disabled={isDisabled || !brandId}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        {(!isDisabled && brandId) && (
                                            <Button
                                                variant="contained"
                                                component="label"
                                            >
                                                Upload
                                                <input
                                                    type="file"
                                                    hidden
                                                    name="thumbnail_web"
                                                    onChange={(e) =>
                                                        updateSliderData("thumbnail_web", e.target.files[0])
                                                    }
                                                />
                                            </Button>
                                        )}
                                        <p className="image_hint">
                                            *Hint: Dimension is: 400 * 400
                                        </p>
                                    </div>
                                </label>
                            </div>

                        </section>
                        <section className="col-6">
                            <h3>Background Web</h3>
                            <div>
                                <label className="image" htmlFor="background-file-web">

                                    <div>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="image url"
                                            variant="filled"

                                            value={sliderDetail['background_web']?.name || sliderDetail['background_web']}
                                            disabled={isDisabled || !brandId}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />

                                        {(!isDisabled && brandId) && (
                                            <Button
                                                variant="contained"
                                                component="label"
                                            >
                                                Upload
                                                <input
                                                    type="file"
                                                    hidden
                                                    name="background_web"
                                                    onChange={(e) =>
                                                        updateSliderData("background_web", e.target.files[0])
                                                    }
                                                />
                                            </Button>
                                        )}
                                        <p className="image_hint">
                                            *Hint: Dimension is: 720 * 273
                                        </p>
                                    </div>
                                </label>
                            </div>

                        </section>
                    </div>
                </div>

                <div className="row">
                    <section className="col-6">
                        <h3>Order</h3>
                        <TextField
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 1
                                }
                            }}
                            name="order"
                            placeholder="order"
                            variant="filled"
                            value={sliderDetail['order'] || ''}
                            onChange={(e) => updateSliderData('order', e.target.value)}
                            style={{ direction: "rtl" }}
                            disabled={isDisabled || !brandId}
                        />
                    </section>
                </div>

                {/* view_in_home  */}
                <div className="row">
                {console.log("sliderDetail['view_in_home']: ",sliderDetail['view_in_home'])}
                    <section style={{ display: "flex", alignItems: "center" }} className="col-6">
                        <Checkbox
                            size="small"
                            name="view_in_home"
                            checked={sliderDetail['view_in_home'] || false}
                            onChange={(e) => updateSliderData('view_in_home', e.target.checked)}
                            style={{ direction: "rtl" }}
                            disabled={isDisabled || !brandId}
                        />
                        <h3 style={{ marginLeft: 6 }}>View in home</h3>
                    </section>
                </div>

                {console.log("isBrandPublished: ", isBrandPublished)}
                {!isBrandPublished &&
                    <div className="submitBtn">
                        <Button variant="contained" type="button" onClick={submitSlider} disabled={isDisabled || !brandId}>
                            Save slider
                        </Button>
                    </div>
                }
            </section>
        </section>
    )
}