import { Formik } from "formik"
import { Button, Form } from "react-bootstrap"
import { textFieldsBasicInfo, textFieldsSchedulingAppointment, textFieldsTargeting, textFieldsAudience } from "../../components/NotificationDashBoardComponent/NotificationData"
import { BsCloudUpload } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { UserUtility } from "../../apis/UserUtility";
import * as Yup from "yup";
import { TargetingComponent } from "../../components/NotificationDashBoardComponent/TargetingComponent";
import {
    AiOutlinePlus,
    AiOutlineDelete
} from "react-icons/ai";

import UserContext from "../../hooks/userContext";
import { ENDPOINTS } from '../../apis/EndPoints';
import { toast } from "react-toastify";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import moment from "moment";
import { PaginateSelectedComponent } from "../../components/CustomSelectedComponent/PaginateSelectedComponent";

const notifyValidation = Yup.object().shape({
    //notification data
    title: Yup.string()
        .required("*This field is required."),
    message: Yup.string()
        .required("*This field is required."),
    badge: Yup.string()
        .required("*This field is required."),

});
export const NotifyDetail = () => {
    let location = useLocation();
    const notifyData = location?.state?.notifyData;
    const audienceData = location?.state?.audienceData;
    const navigate = useNavigate()

    const [notifyObj, setNotifyObj] = useState(notifyData?.id ? notifyData : {})
    const [audiences, setAudiences] = useState([])
    const [selectedAudience, setSelectedAudience] = useState()
    const [newSced, setNewSced] = useState({
        select_date: '',
        select_time: ''
    })
    const [scedArr, setScedArr] = useState([])
    const { handleLoader, permissions = [] } = useContext(UserContext);
    const [filterParams, setFlterParams] = useState()

    const handleAddNewScedule = () => {
        let arr = scedArr;
        if (newSced.select_date && newSced.select_time) {
            arr.push(newSced)
            setScedArr(arr)
            setNewSced({
                select_date: '',
                select_time: ''
            })
        }
    }

    // to handle changes in scedule array
    const handleScedulArrChanges = (item, index, fieldName) => {
        let arr = scedArr;
        arr[index][fieldName] = item
        setScedArr(arr)
    }

    // to handle delete scedule row
    const handleDeleteSced = async (index) => {
        const dummy = scedArr;
        let arr = await dummy.filter((item, indd) => {
            return indd !== index
        })
        setScedArr([])
        setScedArr(arr)

    }


    // audiences get options with page and search
    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        let response = await UserUtility.getAudiences(searchQuery, page)

        return {
            options: response?.results,
            hasMore: response?.results.length >= 1,
            additional: {
                page: page + 1,
            },
        };
    };

    const createOrEditAudience = (values) => {
        let token = localStorage.getItem("tools_token");
        var myHeaders = new Headers();
        var formdata = new FormData();
        // to custom date, firstly migrate date with time in one field then convert new arr to string
        let customDates = scedArr.map((item, _) => {
            return item.select_date + ' ' + item.select_time
        })
        let dateString = customDates.join(',')

        // body
        formdata.append('title', values?.title)
        formdata.append('message', values?.message)
        formdata.append('is_notification', true)
        formdata.append('dates', dateString)
        formdata.append('is_recurrent', dateString ? true : false)
        formdata.append('broadcast', values?.broadcast)
        if (values?.badge && typeof values?.badge === "object") {
            formdata.append('badge', values?.badge)
        }
        if (selectedAudience?.id) {
            formdata.append('audience', selectedAudience?.id)
        } else {
            if (notifyObj?.type === 'custom') {
                formdata.append('users_ids', notifyObj?.users_ids)
            }
        }

        myHeaders.append("Authorization", `token ${token}`);
        const requestOptions = {
            method: notifyData?.id ? "PATCH" : "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };


        handleLoader(true);
        fetch(
            notifyData?.id ?
                `${ENDPOINTS.url}accounts/triggers/${notifyData?.id}/${(selectedAudience?.id) ? '' : `?${(notifyObj?.type === 'criteria') ? `?${filterParams}` : ''}`}`
                : `${ENDPOINTS.url}accounts/triggers/${(selectedAudience?.id) ? '' : `?${(notifyObj?.type === 'criteria') ? `?${filterParams}` : ''}`}`
            ,
            requestOptions
        )
            .then(async (res) => {
                handleLoader(false);
                if (!res.ok) {
                    const text = await res.json();
                    // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
                    toast.error(text.detail || text[0] || "something went wrong");
                    // throw new Error(text);
                } else {
                    navigate(-1)

                    toast.success("Notification has been saved successfully");
                    return res.json();
                }
            })
            .catch((err) => {
                console.log('err of import: ', err)
                toast.error(err || "Oops something went wrong of upload file");
                handleLoader(false);
            });

    }


    const audienceSelectFun = (audienceObj) => {

        setSelectedAudience({ ...audienceObj, criteria: typeof audienceObj?.criteria === 'object' ? {} : JSON.parse(audienceObj?.criteria) })
    }


    // useEffect(() => {
    //     getAllAudiences()
    // }, [])

    useEffect(() => {
        setNotifyObj(notifyData)
        if (notifyData?.audience) {
            console.log('NNNNNNNNNo')
            setSelectedAudience({ ...notifyData?.audience, criteria: typeof notifyData?.audience?.criteria === 'object' ? {} : JSON.parse(notifyData?.audience?.criteria) })
        }
        if (notifyData?.scheduled_dates && notifyData?.scheduled_dates?.length) {
            let dates = notifyData?.scheduled_dates.map((item, _) => {
                return { select_date: moment(item.date).format('YYYY-MM-DD'), select_time: moment(item.date).format('HH:mm') }
            })
            setScedArr(dates)
        }
        if (audienceData?.id) {
            console.log('YYYYYYYYEs: ',audienceData)
            setSelectedAudience({ ...audienceData, criteria: typeof audienceData?.criteria === 'object' ? {} : JSON.parse(audienceData?.criteria) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifyData?.id, audiences?.length])

    return (
        <main style={{ width: '100%' }}>
            <Formik
                initialValues={{
                    title: notifyObj?.title || '',
                    message: notifyObj?.message || '',
                    badge: notifyObj?.badge || null,
                    broadcast: notifyObj?.broadcast || false
                }}
                onSubmit={createOrEditAudience}
                validationSchema={notifyValidation}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                    handleBlur,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {/* Audience info */}
                        <section className="notify_section">
                            <div className="head_banner">
                                <h4>Audience Target</h4>
                            </div>
                            <div className="section_content">
                                <div className="row row_notify_div">
                                    {/* <Form.Group
                                        className="mb-3 col-md-3"
                                        controlId="formBasic"
                                    >
                                        <Form.Label>Audience</Form.Label>
                                        <Form.Select
                                            id="disabledSelect"
                                            disabled={notifyData?.audience?.id ? true : false}
                                            value={selectedAudience?.id}
                                            onChange={(e) => {
                                                audienceSelectFun(e.target.value)
                                            }}
                                        // isInvalid={!!errors[field.name]}
                                        >
                                            <>
                                                <option value="" selected disabled>
                                                    Select Audience
                                                </option>
                                                {audiences.length > 0 && audiences.map((item, index) => (
                                                    <option value={item.id} key={index.toString()}>{item.name}</option>

                                                ))}
                                            </>
                                        </Form.Select>
                                    </Form.Group> */}

                                    <div className="col-md-3">
                                        <PaginateSelectedComponent
                                            disabled={(notifyData?.audience?.id || audienceData?.id) ? true : false}
                                            value={selectedAudience}
                                            loadOptions={loadOptions}
                                            onChange={(value) => audienceSelectFun(value)}
                                            placeholder="Select Audience"
                                        // customStyle={{ width: '30%' }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </section>

                        {/* target section */}
                        <section className="notify_section">
                            <div className="head_banner">
                                <h4>Targeting</h4>
                            </div>
                            <TargetingComponent isDisable={selectedAudience?.id ? true : false} defaultSelected={selectedAudience} setTargetFinal={(values) => {
                                setNotifyObj((prev) => ({ ...prev, ...values }))
                            }}

                                setPrepareParams={(values) => setFlterParams(values)}

                            />
                        </section>

                        {/* basic info section */}
                        <section className="notify_section">
                            <div className="head_banner">
                                <h4>Notification Info.</h4>
                            </div>
                            <div className="section_content">
                                <div className="row row_notify_div">
                                    {textFieldsBasicInfo.map((field, index) => (
                                        <Form.Group
                                            className="mb-3 col-md-3"
                                            controlId="formBasic"
                                            key={index.toString()}
                                        >
                                            <Form.Label>{field.label}</Form.Label>
                                            {field.type === 'textarea' ?
                                                <Form.Control
                                                    type="text"
                                                    as={field.type}
                                                    name={field.name}
                                                    value={
                                                        field.type === "number" ? values[field.name] :
                                                            values[field.name]
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(field.name, e.target.value)
                                                    }
                                                    isInvalid={!!errors[field.name]}
                                                />
                                                :
                                                <Form.Control
                                                    type="text"
                                                    name={field.name}
                                                    value={
                                                        field.type === "number" ? values[field.name] :
                                                            values[field.name]
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(field.name, e.target.value)
                                                    }
                                                    isInvalid={!!errors[field.name]}
                                                />
                                            }

                                            <Form.Control.Feedback
                                                type="invalid"
                                                className="error_message"
                                            >
                                                {errors[field.name]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    ))}
                                </div>

                                <div className="row row_notify_div">
                                    <Form.Group
                                        controlId="formFile"
                                    >
                                        <Form.Label>Badge Image</Form.Label>

                                        <div className="upload_notify_class">
                                            <Form.Control
                                                name='badge'
                                                type="file"
                                                className="upload_notify_input"
                                                // disabled={
                                                //   existUser|| isDisabled || isViewOnly || !userId ? true : false
                                                // }
                                                onChange={(e) => {
                                                    setFieldValue('badge', e.target.files[0]);
                                                }}
                                            />
                                            <div className={`upload_notify_btn`} style={{ borderColor: errors['badge'] ? '#cc0000' : '#384EB74D' }} type="upload">
                                                <div className="child">
                                                    {(values?.badge) ?
                                                        <img src={values?.badge || values?.badge?.name} alt={values?.badge?.name} width="400" height="200" />
                                                        :
                                                        <>
                                                            <BsCloudUpload className="upload_icon" />
                                                            <p>Drag & drop files or Browse</p>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* {values?.badge &&
                                            <p style={{ marginTop: 10 }}>File name: {values?.badge?.name}</p>
                                        } */}

                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="error_message"
                                        >
                                            {errors['badge']}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="row row_notify_div ">
                                    <h6>Additional Options</h6>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            disabled={!permissions.includes('notification_broadcast_edit') || notifyData?.broadcast}
                                            // defaultChecked={true}
                                            label={'broadcast'}
                                            value={values['broadcast']}
                                            onChange={(e) => setFieldValue('broadcast', e.target.checked)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </section>


                        {/* appointment section */}
                        <section className="notify_section">
                            <div className="head_banner">
                                <h4>Scheduling appointments</h4>
                            </div>
                            <div className="section_content">
                                {scedArr.map((item, index) => (
                                    <div className="row row_notify_div">
                                        <>
                                            {textFieldsSchedulingAppointment.map((field, ind) => (
                                                <>
                                                    <Form.Group
                                                        className="mb-3 col-md-3"
                                                        controlId="formBasic"
                                                        key={index.toString()}
                                                    >
                                                        <Form.Label>{field.label}</Form.Label>
                                                        <Form.Control
                                                            min={field.type === 'date' ? moment(new Date()).format('YYYY-MM-DD') : ''}
                                                            type={field.type}
                                                            name={field.name}
                                                            defaultValue={item[field.name]}
                                                            // value={item[field.name]}
                                                            onChange={(e) => {
                                                                handleScedulArrChanges(e.target.value, index, field.name)
                                                            }}

                                                            isInvalid={!!errors[field.name]}
                                                        />
                                                    </Form.Group>
                                                </>
                                            ))}

                                            <div className="mb-3 col-md-6"
                                                style={{ alignSelf: 'flex-end', textAlign: 'end' }}

                                            >
                                                <AiOutlineDelete
                                                    style={{ cursor: "pointer" }}

                                                    onClick={() => handleDeleteSced(index)}
                                                />
                                            </div>
                                        </>
                                    </div>
                                ))}

                                {/* new row of scedule */}
                                <div className="row row_notify_div" >
                                    {
                                        textFieldsSchedulingAppointment.map((field, index) => (
                                            <Form.Group
                                                className="mb-3 col-md-3"
                                                controlId="formBasic"
                                                key={index.toString()}
                                            >
                                                <Form.Label>{field.label}</Form.Label>
                                                <Form.Control
                                                    min={field.type === 'date' ? moment(new Date()).format('YYYY-MM-DD') : ''}
                                                    type={field.type}
                                                    name={field.name}
                                                    value={newSced[field.name]}

                                                    onChange={(e) => {
                                                        setNewSced((prevState) => ({
                                                            ...prevState,
                                                            [field.name]: e.target.value,
                                                        }))
                                                        // setNewSced((prev) => ({ ...prev, [field.name]: e.target.value }))
                                                    }}
                                                    isInvalid={!!errors[field.name]}
                                                />

                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="error_message"
                                                >
                                                    {errors[field.name]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))
                                    }

                                    {/* </div> */}
                                    <div className="col-md-6 align-self-end" style={{ textAlign: 'end' }}>
                                        <Button className="btn_small" type="button" onClick={() => handleAddNewScedule()}>
                                            <AiOutlinePlus style={{ marginRight: 10 }} />
                                            Add
                                        </Button>
                                    </div>
                                </div>

                                <div className="flex_div" style={{ marginTop: 100 }}>
                                    {notifyData?.broadcast !== true &&

                                        <div>
                                            <Button className="btn3" type="submit">Submit</Button>
                                            {/* <Button className="btn_outline" style={{ marginLeft: 20 }}>Save As Draft</Button> */}
                                        </div>
                                    }
                                    <Button className="btn_cancel" onClick={() => navigate(-1)}>Cancel</Button>
                                </div>

                            </div>
                        </section>
                    </form>
                )
                }
            </Formik>
        </main>
    )
}