import React from "react";
import { Pagination } from "@mui/material";
import "./Pagination.scss";

const PaginationComponent = ({
  brands,
  page,
  handleChange,
  total,
  style,
  section,
  defaultNum,
}) => {
  const dividNum = defaultNum || 10;
  //to get number of pages by dividing "count" on 10 and rounded it up
  const paginationCount = brands?.count ? brands.count / dividNum : 1;
  const paginationRound = Math.ceil(paginationCount);

  return (
    <div className={!section && "pagination_wrapper"}>
      <Pagination
        count={paginationRound}
        page={page}
        onChange={handleChange}
        variant="outlined"
        dir={style}
      />
      {total && <span> {brands?.count} contracts</span>}
    </div>
  );
};

export default PaginationComponent;
