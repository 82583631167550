import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsCloudUpload } from "react-icons/bs";
import { UserUtility } from "../../apis/UserUtility";
import MultipleSelectionNotify from "./MultipleSelectionNotify";
import {
  TextFieldsRangTargeting,
  textFieldsTargeting,
} from "./NotificationData";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";

export const TargetingComponent = ({
  setTargetFinal,
  defaultSelected,
  isDisable,
  setPrepareParams,
}) => {
  const [targetingObj, setTargetingObj] = useState({ type: "criteria" });
  const [options, setOptions] = useState([]);
  const [genderOpt, setGenderOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [preferencesOpt, setPreferencesOpt] = useState([]);
  const [corporatesOpt, setCorporatesOpt] = useState([]);

  const [file, setFile] = useState();
  const [audienceNum, setAudienceNum] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const prepareParams = () => {
    let filterParams = targetingObj?.age_gte
      ? `age_gte=${targetingObj?.age_gte}`
      : "";
    filterParams = targetingObj?.age_lte
      ? `${filterParams}&age_lte=${targetingObj?.age_lte}`
      : filterParams;
    filterParams = targetingObj?.governorate
      ? `${filterParams}&governorate=${targetingObj?.governorate
          ?.map((item) => item?.value || item)
          .join()}`
      : filterParams;
    filterParams = targetingObj?.cities
      ? `${filterParams}&cities=${targetingObj?.cities
          .map((item) => item?.value || item)
          .join()}`
      : filterParams;
    filterParams = targetingObj?.area
      ? `${filterParams}&area=${targetingObj?.area
          .map((item) => item?.value || item)
          .join()}`
      : filterParams;
    filterParams = targetingObj?.userprofile__gender
      ? `${filterParams}&userprofile__gender=${targetingObj?.userprofile__gender
          ?.map((item) => item?.value || item)
          .join()}`
      : filterParams;
    filterParams = targetingObj?.profile_finance_limit_gte
      ? `${filterParams}&profile_finance_limit_gte=${targetingObj?.profile_finance_limit_gte}`
      : filterParams;
    filterParams = targetingObj?.profile_finance_limit_lte
      ? `${filterParams}&profile_finance_limit_lte=${targetingObj?.profile_finance_limit_lte}`
      : filterParams;
    filterParams = targetingObj?.remaining_amount_gte
      ? `${filterParams}&remaining_amount_gte=${targetingObj?.remaining_amount_gte}`
      : filterParams;
    filterParams = targetingObj?.remaining_amount_lte
      ? `${filterParams}&remaining_amount_lte=${targetingObj?.remaining_amount_lte}`
      : filterParams;
    filterParams = targetingObj?.p_states
      ? `${filterParams}&p_states=${targetingObj?.p_states
          .map((item) => item?.value || item)
          .join()}`
      : filterParams;

    filterParams = targetingObj?.preferences
      ? `${filterParams}&preferences=${targetingObj?.preferences
          .map((item) => item?.value || item)
          .join()}`
      : filterParams;
    filterParams = targetingObj?.date_of_activation__gte
      ? `${filterParams}&date_of_activation__gte=${targetingObj?.date_of_activation__gte}`
      : filterParams;
    filterParams = targetingObj?.date_of_activation__lte
      ? `${filterParams}&date_of_activation__lte=${targetingObj?.date_of_activation__lte}`
      : filterParams;
    filterParams = targetingObj?.form_submit_date__gte
      ? `${filterParams}&form_submit_date__gte=${targetingObj?.form_submit_date__gte}`
      : filterParams;
    filterParams = targetingObj?.form_submit_date__lte
      ? `${filterParams}&form_submit_date__lte=${targetingObj?.form_submit_date__lte}`
      : filterParams;
    filterParams = targetingObj?.corporate__in
      ? `${filterParams}&corporate__in=${targetingObj?.corporate__in
          .map((item) => item?.value || item)
          .join()}`
      : filterParams;

    filterParams = targetingObj?.no_of_transactions__from
      ? `${filterParams}&no_of_transactions__from=${targetingObj?.no_of_transactions__from}`
      : filterParams;
    filterParams = targetingObj?.no_of_transactions__to
      ? `${filterParams}&no_of_transactions__to=${targetingObj?.no_of_transactions__to}`
      : filterParams;
    return filterParams;
  };

  // get all options
  const users_options = (cities) => {
    let citesString;
    if (cities) {
      let citesArr = cities.map((item, index) => {
        return item.value;
      });
      citesString = citesArr.join();
    }
    UserUtility.getUserOptions(citesString)
      .then((res) => {
        setOptions(res);
        let genderArr = res.gender_list.map((item, index) => {
          return { label: item[0], value: item[1] };
        });

        let stateArr = res.p_state_list.map((item, index) => {
          return { label: item[0], value: item[1] };
        });

        let preferencArr = res.preferences.map((item, index) => {
          return { label: item.title, value: item.id };
        });

        let corporateArr = res.corporate_list.map((item, index) => {
          return { label: item.title, value: item.id };
        });
        setGenderOpt(genderArr);
        setStateOpt(stateArr);
        setPreferencesOpt(preferencArr);
        checkExistArea(res);
        setCorporatesOpt(corporateArr);
      })
      .catch((err) => console.error("getUserOptions err: ", err));
  };

  const handleChangeObj = (fieldName, value) => {
    // setTargetingObj({ ...targetingObj, [fieldName]: value })
    setTargetingObj((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const calculateCount = () => {
    let params = prepareParams();
    setBtnLoading(true);
    UserUtility.getTargetCount(params)
      .then((res) => {
        setBtnLoading(false);

        setAudienceNum(res);
      })
      .catch((err) => {
        setBtnLoading(false);
        console.log("err of calculateCount: ", err);
        toast.error(err);
      });
  };

  // to check if selected area not exist in options.area_list remove it from targetObject
  const checkExistArea = (list) => {
    let areaObj = targetingObj?.area?.filter((e) =>
      list?.area_list.find((ele) => ele.value === e.value)
    );
    setTargetingObj((prevState) => ({
      ...prevState,
      area: areaObj,
    }));
  };

  const switchSelectControl = (fieldName) => {
    switch (fieldName) {
      case "p_states":
        return (
          <>
            <MultipleSelectionNotify
              optionsItem={stateOpt}
              selectedValues={targetingObj["p_states"]}
              label={"user state"}
              isDisable={targetingObj.type === "custom" || isDisable}
              finalSelectedList={(values) => {
                setTargetingObj((prevState) => ({
                  ...prevState,
                  p_states: values,
                }));
              }}
            />
          </>
        );

      case "userprofile__gender":
        return (
          <>
            <Form.Group controlId="formBasic">
              {console.log(
                "value of gender: ",
                targetingObj["userprofile__gender"]
              )}
              {console.log(
                "ttttttest: ",
                targetingObj["userprofile__gender"]?.length > 0
                  ? targetingObj["userprofile__gender"][0].label
                  : "Noooo"
              )}
              <Form.Select
                id="disabledSelect"
                value={
                  targetingObj["userprofile__gender"]?.length > 0
                    ? targetingObj["userprofile__gender"][0].value ||
                      targetingObj["userprofile__gender"][0]
                    : ""
                }
                disabled={targetingObj.type === "custom" || isDisable}
                onChange={(e) => {
                  console.log("e.target.value gender: ", e.target.value);
                  setTargetingObj((prevState) => ({
                    ...prevState,
                    userprofile__gender: [
                      { label: e.target.value, value: e.target.value },
                    ],
                  }));
                }}
              >
                <>
                  <option value="" selected disabled>
                    Select gender
                  </option>
                  {genderOpt.length > 0 &&
                    genderOpt.map((item, index) => (
                      <>
                        {console.log("itemmm: ", item)}
                        <option value={item.value} key={index.toString()}>
                          {item.value}
                        </option>
                      </>
                    ))}
                </>
              </Form.Select>
            </Form.Group>
          </>
        );

      case "governorate":
        return (
          <MultipleSelectionNotify
            optionsItem={options?.city_list}
            selectedValues={targetingObj["governorate"]}
            label={"city"}
            isDisable={targetingObj.type === "custom" || isDisable}
            finalSelectedList={(values) => {
              if (values && values.length > 0) {
                users_options(values);
              } else {
                users_options();
              }
              setTargetingObj((prevState) => ({
                ...prevState,
                governorate: values,
              }));
            }}
          />
        );

      case "area":
        return (
          <MultipleSelectionNotify
            optionsItem={options?.area_list}
            label={"area"}
            selectedValues={targetingObj["area"]}
            isDisable={targetingObj.type === "custom" || isDisable}
            finalSelectedList={(values) => {
              setTargetingObj((prevState) => ({
                ...prevState,
                area: values,
              }));
            }}
          />
        );

      case "preferences":
        return (
          <MultipleSelectionNotify
            optionsItem={preferencesOpt}
            selectedValues={targetingObj["preferences"]}
            label={"Preferences"}
            isDisable={targetingObj.type === "custom" || isDisable}
            finalSelectedList={(values) => {
              setTargetingObj((prevState) => ({
                ...prevState,
                preferences: values,
              }));
            }}
          />
        );

      case "corporate__in":
        return (
          <MultipleSelectionNotify
            optionsItem={corporatesOpt}
            selectedValues={targetingObj["corporate__in"]}
            label={"Corporates"}
            isDisable={targetingObj.type === "custom" || isDisable}
            finalSelectedList={(values) => {
              setTargetingObj((prevState) => ({
                ...prevState,
                corporate__in: values,
              }));
            }}
          />
        );

      default:
        break;
    }
  };

  useEffect(() => {
    users_options();
  }, [options?.city_list?.length]);

  useEffect(() => {
    setTargetFinal(targetingObj);
    let params = prepareParams();
    setPrepareParams(params);
    // console.log('targetingObj: ',targetingObj)
  }, [targetingObj]);
  useEffect(() => {
    if (defaultSelected?.criteria) {
      let criteria = defaultSelected?.criteria;
      let arr_state = criteria?.p_states ? criteria?.p_states.split(",") : [];
      let arr_gender = criteria?.userprofile__gender
        ? criteria?.userprofile__gender.split(",")
        : [];
      let arr_area = criteria?.area ? criteria?.area.split(",") : [];
      let arr_city = criteria?.governorate
        ? criteria?.governorate.split(",")
        : [];
      let arr_preference = criteria?.preferences
        ? criteria?.preferences.split(",")
        : [];
      let custom_pref = arr_preference.map((id) =>
        preferencesOpt.find((el) => el.value == Number(id))
      );

      let arr_corporate = criteria?.corporate__in
        ? criteria?.corporate__in.split(",")
        : [];
      let custom_corp = arr_corporate.map((id) =>
        corporatesOpt.find((el) => el.value == Number(id))
      );

      setAudienceNum(defaultSelected?.count);
      let obj = {
        ...criteria,
        type: defaultSelected?.type,
        p_states: arr_state,
        userprofile__gender: arr_gender,
        area: arr_area,
        governorate: arr_city,
        preferences: custom_pref,
        corporate__in: custom_corp,
      };
      setTargetingObj(obj);
    }
  }, [defaultSelected, preferencesOpt]);

  return (
    <div className="section_content">
      <Form.Check
        label="Fill Manual"
        name="group1"
        type={"radio"}
        id={`inline - radio - 1`}
        value={"criteria"}
        className="radio_notify"
        checked={targetingObj["type"] === "criteria"}
        onChange={(e) => {
          handleChangeObj("type", e.target.value);
        }}
        disabled={isDisable ? true : false}
      ></Form.Check>
      <div className="row row_notify_div">
        {textFieldsTargeting.map((field, index) => (
          <Form.Group
            className="mb-3 col-md-3"
            controlId="formBasic"
            key={index.toString()}
          >
            <Form.Label>{field.label}</Form.Label>

            {field.isDropDown ? (
              <>{switchSelectControl(field.name)}</>
            ) : (
              <>
                <Form.Control
                  type={field.type}
                  maxLength={field.type === "number" ? 11 : ""}
                  minLength={field.type === "number" ? 11 : ""}
                  name={field.name}
                  value={targetingObj[field.name]}
                  disabled={targetingObj.type === "custom" || isDisable}
                  onChange={(e) => handleChangeObj(field.name, e.target.value)}
                />
              </>
            )}
          </Form.Group>
        ))}

        {TextFieldsRangTargeting.map((field, index) => (
          <Form.Group
            className={`${
              field.attr_type === "date" ? "col-md-6" : "col-md-3"
            } mb-3`}
            controlId="formBasic"
            key={index.toString()}
          >
            <Form.Label>{field.attribute}</Form.Label>
            <div className="d-flex">
              {field.nestedArray.map((item, id) => (
                <InputGroup className="inputGroup_target">
                  <InputGroup.Text
                    id="basic-addon1"
                    className={`${
                      targetingObj.type === "custom" || isDisable
                        ? "label_disable"
                        : ""
                    }`}
                  >
                    {item.label}
                  </InputGroup.Text>
                  <Form.Control
                    type={item.type}
                    maxLength={item.type === "number" ? 11 : ""}
                    minLength={item.type === "number" ? 11 : ""}
                    name={item.name}
                    value={targetingObj[item.name]}
                    disabled={targetingObj.type === "custom" || isDisable}
                    onChange={(e) => handleChangeObj(item.name, e.target.value)}
                  />
                </InputGroup>
              ))}
            </div>
          </Form.Group>
        ))}
      </div>

      <div className="row row_notify_div">
        <Form.Group className="mb-3 col-md-3" controlId="formBasic">
          <Form.Label>target audience</Form.Label>
          <Form.Control
            type="number"
            maxLength={11}
            minLength={11}
            name={"target_audience"}
            value={audienceNum}
            disabled
          />
        </Form.Group>
        <div className="mb-3 col-md-3 align-self-end">
          <Button className="btn_small" onClick={() => calculateCount()}>
            {btnLoading ? (
              <CgSpinner className="spinner_loader_small" />
            ) : (
              " Check Audience"
            )}
          </Button>
        </div>
      </div>

      <Form.Check
        label="Custom"
        name="group1"
        type={"radio"}
        value={"custom"}
        id={`inline - radio - 1`}
        className="radio_notify"
        checked={targetingObj["type"] === "custom"}
        onChange={(e) => {
          handleChangeObj("type", e.target.value);
        }}
        disabled={isDisable ? true : false}
      ></Form.Check>

      <div className="row row_notify_div">
        <Form.Group controlId="formFile">
          <Form.Label>Upload Excel Sheet</Form.Label>

          <div className="upload_notify_class">
            <Form.Control
              type="file"
              className="upload_notify_input"
              disabled={targetingObj["type"] === "criteria" || isDisable}
              onChange={(e) => {
                setFile(e.target.files[0]);
                handleChangeObj("users_ids", e.target.files[0]);
              }}
            />

            <div className="upload_notify_btn" type="upload">
              <div className="child">
                <BsCloudUpload className="upload_icon" />
                <p>Drag & drop files or Browse</p>
              </div>
            </div>
          </div>
          {file && <p style={{ marginTop: 10 }}>File name: {file?.name}</p>}
        </Form.Group>
      </div>
    </div>
  );
};
