/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { Form } from "react-bootstrap";

import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const RequestHistory = ({ requestId }) => {
  const [pagesCount, setPagesCount] = useState(1);
  const [total, setTotal] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [actionHistory, setActionHistory] = useState([]);
  const actions_history = () => {
    UserUtility.actions_history(requestId, pagesNumber)
      .then((res) => {
        setTotal(res);
        setActionHistory(res.results);
        let pages = Math.ceil(res.count / 10);
        setPagesCount(pages);
      })
      .catch((err) => console.error("actions_history", err));
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  useEffect(() => {
    actions_history();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber]);

  const historyMap = actionHistory?.map((ele, index) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell> {ele.action}</StyledTableCell>
        <StyledTableCell>{ele.detail}</StyledTableCell>
        <StyledTableCell> {ele.performed}</StyledTableCell>
        <StyledTableCell> {ele.request_type}</StyledTableCell>
        <StyledTableCell
          style={{ width: ele.return_reason !== null ? "30%" : "" }}
        >
          <Form.Control
            rows={ele.return_reason !== null && 5}
            as="textarea"
            disabled
            value={ele.return_reason !== null ? ele.return_reason : ""}
          />
        </StyledTableCell>
        <StyledTableCell> {ele.status}</StyledTableCell>
        <StyledTableCell> {ele.department}</StyledTableCell>
        <StyledTableCell>
          {" "}
          {ele.employee === null ? "System" : ele.employee}
        </StyledTableCell>
      </StyledTableRow>
    );
  });
  return (
    <div>
      <TableContainer
        // component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 1300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Action</StyledTableCell>
              <StyledTableCell>Detail</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Request type</StyledTableCell>
              <StyledTableCell>Return reason</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
              <StyledTableCell>Employee name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{historyMap}</TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={total}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default RequestHistory;
