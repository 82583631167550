import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const ActionsHistory = ({ actionHistoryState }) => {
  const [userActions, setActions] = useState([]);
  const location = useLocation();
  const { userId } = location.state;
  const [pagesNumber, setPagesNumber] = useState(1);
  const [results, setResults] = useState();

  const action_history = () => {
    if (actionHistoryState) {
      UserUtility.action_history(userId, pagesNumber)
        .then((res) => {
          setResults(res);
          setActions(res);
        })
        .catch((err) => console.error("user history err", err));
    }
  };
  useEffect(() => {
    action_history();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionHistoryState, pagesNumber]);
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  return (
    <div className="mb-3">
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Action name</StyledTableCell>
              <StyledTableCell>Initiator</StyledTableCell>
              <StyledTableCell>Action details</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userActions?.results?.map((action, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{action.action_name}</StyledTableCell>
                <StyledTableCell>{action.initiator_phone}</StyledTableCell>
                <StyledTableCell>{action.action_details}</StyledTableCell>
                <StyledTableCell>{action.created}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ActionsHistory;
