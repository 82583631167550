import { useContext, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { textFieldsAudience } from "../../components/NotificationDashBoardComponent/NotificationData"
import { TargetingComponent } from "../../components/NotificationDashBoardComponent/TargetingComponent"
import UserContext from "../../hooks/userContext"
import { ENDPOINTS } from '../../apis/EndPoints';
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
export const AudienceDetail = () => {
    const [audienceObj, setAudienceObj] = useState({})
    const { handleLoader } = useContext(UserContext);
    const [filterParams, setFlterParams] = useState()
    const navigate = useNavigate()
    const handleChangeObj = (fieldName, value) => {
        setAudienceObj((prev) => ({ ...prev, [fieldName]: value }))
    }

    const createAudience = (isRedirect) => {
        let token = localStorage.getItem("tools_token");
        var myHeaders = new Headers();
        var formdata = new FormData();

        if (audienceObj?.title) {
            // body
            formdata.append('name', audienceObj?.title)
            formdata.append('description', audienceObj?.description)
            formdata.append('type', audienceObj?.type)
            if (audienceObj?.type === 'custom') {
                formdata.append('users_ids', audienceObj?.users_ids)
            }

            myHeaders.append("Authorization", `token ${token}`);
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };


            handleLoader(true);
            fetch(
                `${ENDPOINTS.url}accounts/audience/${(audienceObj?.type === 'criteria') ? `?${filterParams}` : ''}`,
                requestOptions
            )
                .then(async (res) => {
                    handleLoader(false);
                    if (!res.ok) {
                        const text = await res.json();
                        // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
                        toast.error(text.detail || text[0] || "something went wrong");
                        // throw new Error(text);
                    } else {

                        toast.success("Audience has been saved successfully");

                        res.json().then(res => {
                            console.log('will return res: ',res)
                            if (isRedirect) {
                                navigate('/notification/detail', {
                                    state: {
                                        audienceData: res
                                    }
                                });
                            } else {
                                navigate(-1)
                            }
                            // return res
                        })
                    }
                })
                .catch((err) => {
                    console.log('err of import: ', err)
                    toast.error(err || "Oops something went wrong of upload file");
                    handleLoader(false);
                });
        } else {
            toast.warning('Title field is required, Please fill it ')
        }

    }

    return (
        <main>
            {/* Audience info */}
            <section className="notify_section">
                <div className="head_banner">
                    <h4>Audience Target</h4>
                </div>
                <div className="section_content">
                    <div className="row row_notify_div">
                        {textFieldsAudience.map((field, index) => (
                            <Form.Group
                                className="mb-3 col-md-3"
                                controlId="formBasic"
                                key={index.toString()}
                            >
                                <Form.Label>{field.label}</Form.Label>
                                {field.type === 'textArea' ?

                                    <Form.Control
                                        as="textarea"
                                        rows={6}
                                        name={field.name}
                                        value={
                                            audienceObj[field.name]
                                        }
                                        onChange={(e) => handleChangeObj(field.name, e.target.value)}
                                    // isInvalid={audienceObj[field.name] ? false : true}
                                    />
                                    :
                                    <>
                                        <Form.Control
                                            type="text"
                                            name={field.name}
                                            value={
                                                audienceObj[field.name]
                                            }
                                            onChange={(e) => handleChangeObj(field.name, e.target.value)}
                                            isInvalid={audienceObj[field.name] ? false : true}

                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="error_message"
                                        >
                                            This field is required
                                        </Form.Control.Feedback>
                                    </>
                                }

                            </Form.Group>
                        ))}
                    </div>

                </div>
            </section>

            {/* target section */}
            <section className="notify_section">
                <div className="head_banner">
                    <h4>Targeting</h4>
                </div>
                <TargetingComponent

                    setTargetFinal={(values) => {
                        // console.log('setTargetFinal values: ', values)
                        setAudienceObj((prev) => ({ ...prev, ...values }))
                    }}
                    setPrepareParams={(values) => setFlterParams(values)}
                />
            </section>

            <div className="flex_div" style={{ marginTop: 100, justifyContent: 'flex-start' }}>

                <Button className="btn3" onClick={() => createAudience()}>Save</Button>
                <Button className="btn_outline" style={{ marginLeft: 20 }} onClick={() => createAudience(true)}>Create Notification</Button>


                {/* <Button className="btn_cancel">Cancel</Button> */}
            </div>
        </main>
    )
}