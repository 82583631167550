import React, { useRef, useState, useCallback } from "react";
import "./ContractReport.scss";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Colors } from "../../constants/Colors";
import { useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import moment from "moment";

const ContractReport = () => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const location = useLocation();
  const { details } = location.state;
  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 500);
    });
  }, [setLoading]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  console.log('tttttest: ',new Date('2023-01-18T16:22:12').toLocaleString())

  return (
    <main style={{width:'100%'}}>
      <div ref={componentRef} className="container contract_report">
        <article>
          <article className="head_sec">
            <section className="section_wrapper">
              <h4>Date:</h4>
              <h3>{new Date(`${details.detail.created}`).toLocaleString()}</h3>
            </section>
            <section className="section_wrapper">
              <h4>Created by:</h4>
              <h3>{details.detail.created_by}</h3>
            </section>
            <section className="section_wrapper">
              <h4>Assigned to:</h4>
              <h3>{details.detail.courier}</h3>
            </section>
            <section className="section_wrapper">
              <h4>Total maker:</h4>
              <h3>{details.detail.total_makers}</h3>
            </section>

            <section className="section_wrapper">
              <h4>Branch:</h4>
              <h3>{details.detail.branch_name}</h3>
            </section>
          </article>

          {/* maker data */}
          <article>
            <h2
              style={{
                marginBottom: 16,
                color: "#464646",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Contracts to collect
            </h2>
            {/* maker loop data */}
            {details.detail.contracts.map((item, index) => (
              <article key={index.toString()}>
                <section
                  className="section_wrapper d-flex"
                  style={{ alignSelf: "baseline", alignItems: "center" }}
                >
                  <h4>Maker name:</h4>
                  <h3 style={{ marginLeft: 6 }}>{item.maker}</h3>
                </section>

                {/* table */}
                <Table responsive hover className="Loans_table">
                  <thead>
                    <tr>
                      <th>Client Name</th>
                      <th>Date</th>
                      <th>National ID</th>
                    </tr>
                    <tr className="total_tr">
                      <th>Sum. Of contracts</th>
                      <th></th>
                      <th>{item.sum_of_contracts}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.contracts?.map((row, id) => (
                      <tr key={id}>
                        <td>{row.name}</td>
                        <td>{new Date(`${row.Date}`).toLocaleString()}</td>
                        <td>{row.national_id}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </article>
            ))}
          </article>
        </article>
      </div>
      <div className="submitBtn">
        <Button
          variant="contained"
          type="submit"
          style={{
            marginLeft: 0,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: loading ? Colors.secondary : Colors.primary,
          }}
          onClick={handlePrint}
          disabled={loading}
        >
          Print
        </Button>
      </div>
    </main>
  );
};

export default ContractReport;
