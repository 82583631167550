import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import UserContext from "./hooks/userContext";
import Login from "./pages/Auth/login/Login";
import Otp from "./components/otp/otp";
import Menu from "./components/menu/menu";
import Tools from "./pages/tools/tools";
import AddKiosk from "./components/kiosk/AddKiosk";
import KioskAdded from "./components/kioskAdded/KioskAdded";
import Brands from "./pages/brands/Brands";
import NewBrand from "./pages/newBrand/NewBrand";
import CategoryDetails from "./components/categoryDetails/CategoryDetails";
import OfferDetails from "./components/offerDetails/OfferDetails";
import Operation from "./pages/operation/Operation";
import ContractReport from "./pages/contractReport/ContractReport";
import Statistics from "./pages/statistics/Statistics";
import Daily from "./components/daily/Daily";
import MonthToDate from "./components/monthToDate/MonthToDate";
import YearToDate from "./components/yearToDate/YearToDate";
import SinceInception from "./components/sinceInception/SinceInception";
import LoaderComponent from "./components/loaderComponent/LoaderComponent";
import Makers from "./components/makers/Makers";
import Contracts from "./components/contracts/Contracts";
import GeneralInfo from "./components/generalInfo/GeneralInfo";
import FawryGenerate from "./pages/fawryGenerate/fawryGenerate";
import Categories from "./components/categories/Categories";
import Offers from "./components/offers/Offers";
import Branches from "./components/branches/Branches";
import Loans from "./pages/loans/Loans";
import BrandLoan from "./pages/brandLoan/BrandLoan";
import Users from "./pages/userProfile/Users";
import UserProfile from "./components/userProfile/UserProfile";
import UserDetails from "./components/userDetails/UserDetails";
import UserHistory from "./components/userHistory/UserHistory";
import BlockHistory from "./components/blockHistory/BlockHistory";
import HistoryDocuments from "./components/historyDocuments/HistoryDocuments";
import CashRegister from "./pages/CashRegister/CashRegister";
import ManualLimit from "./components/ManualLimit/ManualLimit";
import NewKioskManagement from "./pages/kioskManagement/NewKioskManagement";
import GlobalOfferDetails from "./components/offerDetails/GlobalOfferDetails";
import ManualStudy from "./components/manualStudy/ManualStudy";
import LimitIncrease from "./components/limitIncrease/LimitIncrease";
import UserRequset from "./pages/userRequest/UserRequest";
import ContractSummary from "./components/ContactSummary/ContactSummary";
import { OneTransactionRoute } from "./pages/OnTransactionScreen/OneTransactionRoute";
import Lawsuit from "./pages/legal/Lawsuit";
import Session from "./pages/legal/Session";
import Alert from "./pages/legal/Alert";
import Main from "./pages/legal/Main";
import Files from "./pages/legal/Files";
import FinanceReport from "./pages/FinanceReport/FinanceReport";
import { NotificationDashBoard } from "./pages/NotificationDashboard/NotificationDashboard";
import { NotifyDetail } from "./pages/NotificationDashboard/NotifyDetail";
import { AudienceDetail } from "./pages/NotificationDashboard/AudienceDetail";
import FinanceSafe from "./pages/Collection/FinanceSafe";
import FinanceContainer from "./pages/Collection/FinanceContainer";
import AdvancedPayments from "./pages/Collection/AdvancedPayments";
import { Receipts } from "./pages/Collection/Reciepts";
import StatmentHistory from "./pages/StatmentHistory/StatmentHistory";
import RequiredImages from "./components/requierdDocuments/RequiredImages";
import { OnBoardList } from "./pages/MerchantOnBoardTool/OnBoardList";
import OnBoardDetail from "./pages/MerchantOnBoardTool/OnBoardDetail";
import LegalHistory from "./components/LegalHistory/LegalHistory";
import Fees from "./pages/legal/Fees";
import { CertificateOfIndebtedness } from "./components/UserStatements/CertificateOfIndebtedness";
import RequestsFailure from "./pages/RequestsFailure/RequestsFailure";
import { RemainingInstallments } from "./components/UserStatements/RemainingInstallments/RemainingInstallments";
import { PrintingTemplates } from "./components/PrintingTemplates/PrintingTemplates";
import { IscoreToolScreen } from "./pages/IscoreScreens/IscoreToolScreen";
import { OfferOverViewTool } from "./pages/OffersOverViewTool/OffersOverView";
import ActivationPointTool from "./pages/ActivationPointTool/ActivationPointTool";

export default function RouteComponent() {
  const { isLogin, permissions } = useContext(UserContext);
  let isAdmin = permissions.includes("on_boarding_tool_admin");
  let isLegal = permissions.includes("can_approve_legal_user");
  let isRisk = permissions.includes("can_approve_risk_user");
  let isEntry = permissions.includes("data_entry_on_boarding");
  let isMarketing = permissions.includes("marketing__on_boarding");
  let isOwner = permissions.includes("owner__on_boarding");

  return (
    <>
      <div className="route_container">
        {!isLogin ? (
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        ) : (
          <div className="home_layoutContainer">
            <LoaderComponent />
            <Menu />

            <Routes>
              <Route path="/" element={<Tools />} />
              <Route path="/addKioskUser" element={<AddKiosk />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/KioskAdded" element={<KioskAdded />} />
              <Route path="/brands" element={<Brands />} />
              <Route path="/brands/:brand" element={<NewBrand />}>
                <Route path="GeneralInfo" element={<GeneralInfo />} />
                <Route path="Categories" element={<Categories />} />
                <Route path="Offers" element={<Offers />} />
                <Route path="Branches" element={<Branches />} />
              </Route>
              <Route path="/category/:brand" element={<CategoryDetails />} />
              <Route path="/offer/:brand" element={<OfferDetails />} />
              <Route path="/globalOffer" element={<GlobalOfferDetails />} />

              <Route path="/operationUser" element={<Operation />}>
                <Route path="makers" element={<Makers />} />
                <Route path="contracts" element={<Contracts />} />
                <Route path="summary" element={<ContractSummary />} />
              </Route>
              <Route path="/contractReport" element={<ContractReport />} />
              {/* <Route
                path="/operationUser/contracts/contractHistory"
                element={<ContractHistory />}
              /> */}
              <Route path="/statistics" element={<Statistics />}>
                <Route path="daily" element={<Daily />} />
                <Route path="monthToDate" element={<MonthToDate />} />
                <Route path="yearToDate" element={<YearToDate />} />
                <Route path="sinceInception" element={<SinceInception />} />
              </Route>
              <Route path="/KioskManagement" element={<NewKioskManagement />} />
              <Route
                path="/ActivationPointTool"
                element={<ActivationPointTool />}
              />
              <Route path="/fawryGenerate" element={<FawryGenerate />} />
              <Route path="/loans" element={<Loans />} />
              <Route path="/loans/brandLoan" element={<BrandLoan />} />
              <Route path="/users" element={<Users />} />
              <Route path="/manualStudy" element={<ManualStudy />} />
              <Route path="/limitIncrease" element={<LimitIncrease />} />
              <Route path="/Main" element={<Main />} />
              <Route path="/Lawsuit" element={<Lawsuit />} />
              <Route path="/Session" element={<Session />} />
              <Route path="/Alert" element={<Alert />} />
              <Route path="/Files" element={<Files />} />
              <Route path="/Main" element={<Main />} />
              <Route path="/Lawsuit" element={<Lawsuit />} />
              <Route path="/Session" element={<Session />} />
              <Route path="/Alert" element={<Alert />} />
              <Route path="/Fees" element={<Fees />} />
              <Route path="/Files" element={<Files />} />
              <Route path="/FinanceSafe" element={<FinanceSafe />} />
              <Route path="/AdvancedPayments" element={<AdvancedPayments />} />
              <Route path="/Receipt" element={<Receipts />} />
              <Route path="/FinanceSafe" element={<FinanceSafe />} />
              <Route path="/AdvancedPayments" element={<AdvancedPayments />} />
              <Route path="/Receipt" element={<Receipts />} />

              <Route path="/userRequest" element={<UserRequset />} />
              <Route path="/userDetails" element={<UserDetails />}>
                <Route
                  path="History/CertificateOfIndebtedness"
                  element={<CertificateOfIndebtedness />}
                />
                <Route
                  path="History/RemainingInstallments"
                  element={<RemainingInstallments />}
                />
                <Route
                  path="History/PrintingTemplates"
                  element={<PrintingTemplates />}
                />

                <Route path="FinanceDetails" element={<FinanceContainer />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="History" element={<UserHistory />} />
                <Route path="BlockHistory" element={<BlockHistory />} />
                <Route
                  path="History/documents"
                  element={<HistoryDocuments />}
                />
                <Route
                  path="History/requiredImages"
                  element={<RequiredImages />}
                />
                <Route path="History/Statment" element={<StatmentHistory />} />
                <Route
                  path="History/Statment/RemainingInstallments"
                  element={<RemainingInstallments />}
                />
                <Route path="ManualLimit" element={<ManualLimit />} />
                <Route path="LimitIncrease" element={<ManualLimit />} />
                <Route path="LegalHistory" element={<LegalHistory />} />
              </Route>
              <Route path="FinanceReport" element={<FinanceReport />} />
              <Route path="CashRegister" element={<CashRegister />}></Route>
              <Route path="notification" element={<NotificationDashBoard />} />
              <Route path="notification/detail" element={<NotifyDetail />} />
              <Route
                path="notification/audience/detail"
                element={<AudienceDetail />}
              />

              {/* one transaction screens */}
              {/* <Route  path="/oneTransaction" element={<OneTransactionRoute />} /> */}
              {/* <Route path="/oneTransaction" element={<OneTransaction />}>
                <Route path="/oneTransaction/dataEntry" element={<DataEntry />}></Route>
                <Route path="/oneTransaction/credit" element={<Credit />}></Route>
                <Route path="/oneTransaction/operation" element={<OperationOneTransaction />}></Route>
              </Route>
              <Route path="/oneTransaction/dataEntry/application" element={<DataEntryApplication />}></Route>
              <Route path="/oneTransaction/dataEntry/application/:user" element={<DataEntryApplication />}></Route>
              <Route path="/oneTransaction/credit/creditMemo" element={<CreditForm />}></Route> */}
              {/* one transaction */}

              <Route
                path="MerchantOnBoard"
                element={
                  isAdmin ||
                  isLegal ||
                  isRisk ||
                  isEntry ||
                  isMarketing ||
                  isOwner ? (
                    <OnBoardList />
                  ) : (
                    <p className="text-center fs-2 w-100 mt-5">
                      Sorry Not Have Permession For Access This Tool :
                    </p>
                  )
                }
              />
              <Route
                path="MerchantOnBoard/detail"
                element={
                  isAdmin ||
                  isLegal ||
                  isRisk ||
                  isEntry ||
                  isMarketing ||
                  isOwner ? (
                    <OnBoardDetail />
                  ) : (
                    <p className="text-center fs-2 w-100 mt-5">
                      Sorry Not Have Permession For Access This Tool :(
                    </p>
                  )
                }
              />

              <Route path="/requestsFailure" element={<RequestsFailure />} />
              <Route path="IscoreTool" element={<IscoreToolScreen />} />
              <Route path="offerOverView" element={<OfferOverViewTool />} />

            </Routes>

            <OneTransactionRoute />
          </div>
        )}
      </div>
    </>
  );
}
