import React, { useState, useContext, useEffect } from "react";
import "./Categories.scss";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Checkbox,
  Button,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import {
  Colors,
  StyledTableCell,
  StyledTableRow,
} from "../../constants/Colors";
import { Link, useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import { Box } from "@mui/system";
import PaginationComponent from "../pagination/PaginationComponent";
import moment from "moment";


const headerData = [
  {
    type: 'name',
    dataType: 'string',
    label: 'Category name',
  }, {
    type: 'created',
    dataType: 'date',
    label: 'Created date',
  }, {
    type: 'publish_date',
    dataType: 'date',
    label: 'Publish date',
  }, {
    type: 'expiry_date',
    dataType: 'date',
    label: 'Expiry date',
  }
]

const filtersArray = [
  {
    filterName: "Published",
    filterTag: "published",
    filterState: false,
  },
  {
    filterName: "Un published",
    filterTag: "is_un_published",
    filterState: false,
  },
  {
    filterName: "Is expired",
    filterTag: "is_expired",
    filterState: false,
  },

];

const Categories = () => {
  const location = useLocation();
  const { brandName, brandId } = location.state;
  const [brandCategories, setBrandCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    title: "",
    is_online: false,
    published: false,
  });

  const [addedCategory, setAddedCategory] = useState({
    title: "",
    is_online: false,
    published: false,
  });
  const { handleLoader, permissions } = useContext(UserContext);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = useState(1);

  // filter
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(filtersArray);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCategories = () => {
    const filtersStates = filters.map((filter) => filter.filterState);

    UserUtility.getBrandCategoris(brandId, searchValue, ...filtersStates)
      .then((res) => {
        setBrandCategories(res || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("getting special brand category error", error)
        setIsLoading(false);
      }
      );
  };

  const handleNewCategory = (e) => {
    const { name, value } = e.target;
    setNewCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleNewCheckBox = (e) => {
    setNewCategory((prevState) => ({
      ...prevState,
      is_online: e,
    }));
  };

  const handleAddCategory = () => {
    const { title } = newCategory;
    if (title !== "") {
      setBrandCategories((prevCategories) => [
        ...prevCategories,
        { ...newCategory },
      ]);
      setAddedCategory({ ...addedCategory, title: title })

      setNewCategory({
        title: "",
        is_online: false,
        published: false,
      });
    }
  };

  // to handle publish button to publish or unpublish
  const publishChange = (catId, publishState) => {
    handleLoader(true);
    UserUtility.publishCategory(catId, publishState)
      .then(() => {
        handleLoader(false);
        getCategories();
      })
      .catch((error) => {
        console.error("publish category error", error);
        handleLoader(false);
      });
  };

  const saveCategoriesUpdate = () => {
    handleLoader(true);
    UserUtility.updateBrandCategories(brandId, brandCategories)
      .then((res) => {
        handleLoader(false);
        getCategories();

        /*
        // in future
        if (page === 1) {
          getCategories();
        } else {
          setPage(1)
        }
        */

      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  };

  // to change is_online state
  const editCategoryState = (id, brandKey) => {
    const newCategories = brandCategories.map((brand, index) => {
      return {
        ...brand,
        [brandKey]: id === brand.id ? !brand[brandKey] : brand[brandKey],
      };
    });
    setBrandCategories(newCategories);
  };

  // sort
  const createSortHandler = (cellType) => () => {
    setOrderBy(cellType)
    setOrder(order === 'asc' ? 'desc' : 'asc')

    if (cellType === 'name') {
      if (order === 'asc') {
        // asc
        brandCategories.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? -1 : b.title.toLowerCase() > a.title.toLowerCase() ? 1 : 0
        )
      }
      else {
        // desc
        brandCategories.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : b.title.toLowerCase() > a.title.toLowerCase() ? -1 : 0
        )
      }
    }

    // created date
    if (cellType === 'created') {
      if (order === 'asc') {
        brandCategories.sort((a, b) => {
          return new Date(a.created) - new Date(b.created);
        })
      } else {
        brandCategories.sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        })
      }
    }

    // publish date
    if (cellType === 'publish_date') {
      if (order === 'asc') {
        brandCategories.sort((a, b) => {
          return new Date(a.publish_date) - new Date(b.publish_date);
        })
      } else {
        brandCategories.sort((a, b) => {
          return new Date(b.publish_date) - new Date(a.publish_date);
        })
      }
    }

    // expiry date
    if (cellType === 'expiry_date') {
      if (order === 'asc') {
        brandCategories.sort((a, b) => {
          return new Date(a.expiry_date) - new Date(b.expiry_date);
        })
      } else {
        brandCategories.sort((a, b) => {
          return new Date(b.expiry_date) - new Date(a.expiry_date);
        })
      }
    }


    // setBrandCategories
  };

  const publishSwitch = (isPublish, id, isOfferCreated) => {
    switch (true) {
      case isPublish && permissions.includes("Finance_merchant_creation_tool"):
      case isPublish && isOfferCreated:
      case isPublish &&

        permissions.includes("Marketing_merchant_creation_tool"):
        return "Published";

      case isPublish && permissions.includes("BD_merchant_creation_tool") && !isOfferCreated:
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.red,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, false)}
          >
            Unpublish
          </Button>
        );

      case !isPublish && permissions.includes("Finance_merchant_creation_tool") && !isOfferCreated:
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.primary,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, true)}
          >
            Publish
          </Button>
        );

      case !isPublish && permissions.includes("BD_merchant_creation_tool"):
      case !isPublish && isOfferCreated:
      case !isPublish &&
        permissions.includes("Marketing_merchant_creation_tool"):
        return "Not Published";

      default:
        break;
    }
  };

  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  //search Btn
  const brandSearch = (e) => {
    e.preventDefault();
    getCategories();
    setIsLoading(true);
  };

  const handleActivePlan = (index) => {
    //to add or remove checkbox as UI
    const newFilters = filters.map((filterInfo, id) => {
      return {
        ...filterInfo,
        filterState:
          index === id ? !filterInfo.filterState : filterInfo.filterState,
      };
    });
    setFilters(newFilters);
  };


  return (
    <article className="categories_container">

      {/* search component */}
      <article style={{ marginTop: 30 }}>
        <form className="searchBar_wrapper">
          <TextField
            size="small"
            placeholder="Search by category name"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="text-field"
            label="Category name"
          />

          <Button
            className="searchButton"
            type="submit"
            variant="contained"
            disabled={isLoading}
            onClick={(e) => brandSearch(e)}
          >
            {isLoading ? "Loading..." : "Search"}
          </Button>
        </form>
      </article>

      {/* filters container */}
      <article className="filters_containerr">
        {filters.map((filter, id) => {
          return (
            <section key={id}>
              <button onClick={() => handleActivePlan(id)} className="checkBtn">
                <Checkbox size="small" checked={filter.filterState} />
              </button>
              <span>{filter.filterName}</span>
            </section>
          );
        })}
      </article>

      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headerData.map((headCell, index) => (
                <StyledTableCell>
                  <TableSortLabel
                    // rowSpan={4}
                    className="table_sort_class"
                    active
                    direction={orderBy === headCell.type ? order : 'asc'}
                    onClick={createSortHandler(headCell.type)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" >
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell>Related to offer</StyledTableCell>
              <StyledTableCell>Offer name</StyledTableCell>

              <StyledTableCell>Is online</StyledTableCell>
              <StyledTableCell>Publish</StyledTableCell>
              {/* <StyledTableCell>Delete</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {brandCategories?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.id ? (
                    <Link
                      to={`/category/${row.id}`}
                      state={{
                        brandName: brandName,
                        categoryDetail: row,
                        brandId:brandId,
                        categoryName: row.title,
                        is_online: row.is_online,
                        published: row.published,
                        // max_percentage: maxPercent
                      }}
                      style={{
                        color: "#000",
                        textDecoration: "none",
                        fontWeight: 700,
                      }}
                    >
                      {row.title}
                    </Link>
                  ) : (
                    <h4>{row.title}</h4>
                  )}
                </StyledTableCell>
                {/* created date */}
                <StyledTableCell>{row.created ? moment(row.created).format('lll') : ' - '}</StyledTableCell>
                {/* publish date */}
                <StyledTableCell>{row.publish_date ? row.publish_date : ' - '}</StyledTableCell>
                {/* expiry date */}
                <StyledTableCell>{row.expiry_date ? row.expiry_date : ' - '}</StyledTableCell>
                {/* is related to offer */}
                <StyledTableCell>{row.offer_created === true ? 'Yes' : 'No'}</StyledTableCell>
                {/* offer name */}
                <StyledTableCell>{row.offer_name ? row.offer_name : ' - '}</StyledTableCell>

                <StyledTableCell>
                  <Checkbox
                    disabled={(row.offer_created === true || row.published === true) ? true : false}
                    checked={row.is_online}
                    size="small"
                    onClick={() => editCategoryState(row.id, "is_online")}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {publishSwitch(row.published, row.id, row.offer_created)}
                </StyledTableCell>

                {/* disabled delete cell */}
                {/* <StyledTableCell>
                  {permissions.includes("Finance_merchant_creation_tool") && (
                    <button
                      onClick={() => deleteCategory(id)}
                      className="deleteBtn"
                    >
                      <MdDelete className="deleteBtn__icon" />
                    </button>
                  )}
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
            {permissions.includes("BD_merchant_creation_tool") && (
              <>
                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={8}
                    style={{ padding: "5px 16px" }}
                  >
                    <button onClick={handleAddCategory} className="addBtnIcon">
                      <MdOutlineAddCircleOutline className="addIcon" />
                    </button>
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell>
                    <input
                      className="branchInput"
                      onChange={handleNewCategory}
                      value={newCategory.title}
                      name="title"
                      placeholder="new category"
                    />
                  </StyledTableCell>
                  {/* created date */}
                  <TableCell align="center"> - </TableCell>
                  {/* publish date */}
                  <TableCell align="center"> - </TableCell>
                  {/* expiry date */}
                  <TableCell align="center"> - </TableCell>
                  {/* is related to offer */}
                  <TableCell align="center"> - </TableCell>
                  {/* offer name */}
                  <TableCell align="center"> - </TableCell>


                  <StyledTableCell>
                    <Checkbox
                      name="is_online"
                      checked={newCategory.is_online}
                      size="small"
                      onChange={() => handleNewCheckBox(!newCategory.is_online)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center"> - </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {permissions.includes("BD_merchant_creation_tool") ? (
        <div className="submitBtn">
          <Button
            variant="contained"
            type="submit"
            style={{ marginLeft: 0 }}
            onClick={saveCategoriesUpdate}
          >
            Save section
          </Button>
        </div>
      ) : (
        ""
      )}

      {/* <PaginationComponent
        brands={categoriesDetail}
        page={page}
        handleChange={handleChange}
        total={false}
      /> */}
    </article>
  );
};

export default Categories;
