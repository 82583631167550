import Modal from 'react-bootstrap/Modal';
import TableCreditData from './TableCreditData';
import PaginationComponent from '../pagination/PaginationComponent';
import { Form } from 'react-bootstrap';


function PopupCreditLimit(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='w-100 h-100'
        >
            <Modal.Header closeButton style={{ backgroundColor: "#41b6a8", color: "white" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <p className='fs-3 mb-0'>Credit Limit</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TableCreditData creditData={props.creditData} />
            </Modal.Body>
            <Modal.Footer>
                <PaginationComponent
                    brands={props.creditData}
                    page={props.page}
                    handleChange={props.handleChange}
                    total={false}
                />
                <button className='btn btn-danger text-white ' onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}

export default PopupCreditLimit;