import "./fawryGenerate.scss";
import TextField from "@mui/material/TextField";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { FawryPay } from "../../apis/FawryPay";

const FawryGenerate = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [overdueUsers, setOverdueUsers] = useState({});
  const [page, setPage] = useState(1);
  const { handleLoader } = useContext(UserContext);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getUsers = () => {
    UserUtility.getOverdueUsers(page, searchValue)
      .then((res) => {
        setOverdueUsers(res);
      })
      .catch((err) => console.log(err));
    setIsLoading(false);
  };
  //search Btn
  const userSearch = (e) => {
    setIsLoading(true);
    e.preventDefault();
    getUsers();
  };
  const handleChange = (e, value) => {
    setPage(value);
  };

  const checkout = (chargeReq) => {
    const configuration = {
      locale: "en", //default en
      mode: "SIDE_PAGE", //required, allowed values [POPUP, INSIDE_PAGE, SIDE_PAGE]
      onSuccess: successCallBack, //optional and not supported in SEPARATED display mode
      onFailure: failureCallBack, //optional and not supported in SEPARATED display mode
    };
    FawryPay.checkout(chargeReq, configuration);
  };

  function successCallBack(data) {
    // console.log("handle successful callback as desired, data", data);
  }
  function failureCallBack(data) {
    // console.log("handle failure callback as desired, data", data);
  }
  const handleFawryPayment = (id) => {
    handleLoader(true);

    UserUtility.payMethod("fawry_reference_number", id)
      .then((res) => {
        // console.log(res);
        checkout(res.detail.object);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);

        // console.error("error fawry", err);
        toast.error(
          err.non_field_errors
            ? err.non_field_errors
            : "you can't pay right now, if you need help please contact us"
        );
      });
  };
  return (
    <div className="fawryGenerate_container background--white">
      <div className="page__title">Fawry Generate Ref</div>
      {/* search component */}
      <div className="search">
        <article>
          <form className="searchBar_wrapper">
            <TextField
              size="small"
              placeholder="Search by national ID"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              className="text-field"
              label="national ID"
            />
            <Button
              className="searchButton"
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => userSearch(e)}
            >
              {isLoading ? "Loading..." : "Search"}
            </Button>
          </form>
        </article>
      </div>

      {/* users list */}
      <div className="list">
        {overdueUsers.results &&
          overdueUsers.results.map((user) => (
            <div key={user.id} className="list__item">
              <div className="list__item__left">
                EGP{" "}
                {Number.isInteger(user.total_overdues)
                  ? user.total_overdues
                  : user.total_overdues.toFixed(2)}
              </div>
              <div className="list__item__right">
                <div className="list__item__text">{user.customer_name}</div>
                <div
                  className="list__item__button"
                  onClick={() => handleFawryPayment(user.id)}
                >
                  Pay
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* pagination */}
      <PaginationComponent
        brands={overdueUsers}
        page={page}
        handleChange={handleChange}
        total={false}
      />
    </div>
  );
};

export default FawryGenerate;
