import React, { useState, useContext, useEffect } from "react";
import { Accordion, Col, Container, Row, Button, Form } from "react-bootstrap";
import UserContext from "../../hooks/userContext";
import ActionsHistory from "../ActionsHistory/ActionsHistory";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import LoanHistory from "../LoanHistory/LoanHistory";
import ContractHistory from "../ContractHistory/ContractHistory";
import { Link, useLocation } from "react-router-dom";
import UserHistoryData from "../userHistoryData/UserHistoryData";
import UserLocation from "../userHistoryMap/UserLocation";
import BlackListHistory from "../BlackListHistory/BlackListHistory";
import CashbackHistory from "../CashbackHistory/CashbackHistory";
import { CertificateOfIndebtedness } from "../UserStatements/CertificateOfIndebtedness";
import Modal from "react-modal";
import { UserUtility } from "../../apis/UserUtility";

const UserHistory = () => {
  const reasonModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "35%",
      //   overflowY: "scroll",
      height: "40%",
      //   backgroundColor:"#41B6A8",
      borderRadius: 20,
    },
  };
  const { permissions } = useContext(UserContext);
  const [templatesName, setTemplatesName] = useState();
  console.log("templatesName", templatesName);
  const [userStatementsState, setUserStatementsState] = useState(false);
  const [actionHistoryState, setActionHistoryState] = useState(false);
  const [paymentHistoryState, setPaymentHistoryState] = useState(false);
  const [blacklistHistoryState, setBlacklistHistoryState] = useState(false);
  const [cashbackHistoryState, setCashbackHistoryState] = useState(false);
  const [LoanHistoryState, setLoanHistoryState] = useState(false);
  const [ContractHistoryState, setContractHistoryState] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [buttonName, setButtonName] = useState();
  const [templatetype, setTemplatetype] = useState();
  const location = useLocation();
  const { userId, profileOptions, blocked, type, manual_state, LimitIncrease } =
    location.state;

  const sections = [
    { name: "Loans" },
    { name: "Action History" },
    { name: "Contract History" },
    { name: "User Statements" },
  ];

  const handleSections = (section) => {
    if (section === "Loans") {
      setLoanHistoryState(true);
      setActionHistoryState(false);
      setContractHistoryState(false);
      setUserStatementsState(false);
    } else if (section === "Action History") {
      setActionHistoryState(true);
      setLoanHistoryState(false);
      setContractHistoryState(false);
      setUserStatementsState(false);
    } else if (section === "Contract History") {
      setContractHistoryState(true);
      setLoanHistoryState(false);
      setActionHistoryState(false);
      setUserStatementsState(false);
    } else {
      setContractHistoryState(false);
      setLoanHistoryState(false);
      setActionHistoryState(false);
      setUserStatementsState(true);
    }
  };

  const templates_id = () => {
    UserUtility.templates_id()
      .then((res) => {
        setTemplatesName(res);
      })
      .catch((err) => console.error("templates_id", err));
  };
  useEffect(() => {
    templates_id();
  }, []);
  return (
    <Container>
      <Row className="mt-4 flex-column-reverse flex-md-row justify-content-end">
        <Col
          className="justify-content-end text-decoration-underline"
          md={6}
          sm={12}
          style={{ width: "15%" }}
        >
          <Link
            state={{
              userId: userId,
              profileOptions: profileOptions,
              blocked: blocked,
              LimitIncrease: LimitIncrease,
              type: type,
              manual_state: manual_state,
            }}
            to="documents"
            className="text-success fw-bolder"
          ></Link>
        </Col>
      </Row>
      <Col className="d-flex justify-content-end " lg={12} md={12} sm={12}>
        <Button className="w-25 mt-3 mb-3 border border-success text-success ">
          <Link
            state={{
              userId: userId,
              profileOptions: profileOptions,
              blocked: blocked,
              LimitIncrease: LimitIncrease,
              type: type,
              manual_state: manual_state,
            }}
            to="requiredImages"
            className="text-success fw-bolder text-decoration-none"
          >
            Add Required Images
          </Link>
        </Button>
      </Col>
      <Col>
        {sections.map((button, index) => {
          return (
            <Button
              variant={button.name === buttonName ? "success" : ""}
              key={index}
              style={{
                padding: "1.2%",
                width: "25%",
                borderColor: "#D9D9D9",
              }}
              onClick={() => (
                handleSections(button.name), setButtonName(button.name)
              )}
              className=" mt-2 mb-2 border "
            >
              {button.name}
            </Button>
          );
        })}
      </Col>
      {LoanHistoryState && <LoanHistory LoanHistoryState={LoanHistoryState} />}
      {ContractHistoryState && (
        <ContractHistory ContractHistoryState={ContractHistoryState} />
      )}
      {actionHistoryState && (
        <ActionsHistory actionHistoryState={actionHistoryState} />
      )}
      {userStatementsState && (
        <Col
          className="d-flex justify-content-end  mb-3"
          lg={12}
          md={12}
          sm={12}
        >
          <Button
            className="w-25 mt-3 mb-3 border border-success text-success "
            onClick={() => {
              setTemplateModal(true);
            }}
          >
            <Link
              state={{
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
              }}
              // to="PrintingTemplates"
              className="text-success fw-bolder text-decoration-none"
            >
              Printing Templates
            </Link>
          </Button>
          <Button className="w-25 mt-3 mb-3 border border-success text-success ">
            <Link
              state={{
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
                fines: true,
                product:false
              }}
              to="RemainingInstallments"
              className="text-success fw-bolder text-decoration-none"
            >
              Fines
            </Link>
          </Button>

          <Button className="w-25 mt-3 mb-3 border border-success text-success ">
            <Link
              state={{
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
                fines: false,
                product:false
              }}
              to="RemainingInstallments"
              className="text-success fw-bolder text-decoration-none"
            >
              Remaining installments
            </Link>
          </Button>

          <Button className="w-25 mt-3 mb-3 border border-success text-success ">
            <Link
              state={{
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
              }}
              to="CertificateOfIndebtedness"
              className="text-success fw-bolder text-decoration-none"
            >
              Certificate Of Indebtedness
            </Link>
          </Button>
        </Col>
        // <CertificateOfIndebtedness actionHistoryState={actionHistoryState} />
      )}
      <Accordion alwaysOpen className="mb-5" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>General Info</Accordion.Header>
          <Accordion.Body>
            <Col
              className="d-flex justify-content-end  mb-3"
              lg={12}
              md={12}
              sm={12}
            >
              <Button className="w-25 mt-3 mb-3 border border-success text-success ">
                <Link
                  state={{
                    userId: userId,
                    profileOptions: profileOptions,
                    blocked: blocked,
                    LimitIncrease: LimitIncrease,
                    type: type,
                    manual_state: manual_state,
                  }}
                  to="documents"
                  className="text-success fw-bolder text-decoration-none"
                >
                  View Documents
                </Link>
              </Button>
            </Col>
            <UserHistoryData />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={() => setPaymentHistoryState(true)}>
            Payment history
          </Accordion.Header>
          <Accordion.Body>
            <PaymentHistory paymentHistoryState={paymentHistoryState} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Location</Accordion.Header>
          <Accordion.Body>
            <UserLocation />
          </Accordion.Body>
        </Accordion.Item>
        {permissions.includes("ud_can_view_user_blacklist_records") && (
          <Accordion.Item eventKey="3">
            <Accordion.Header onClick={() => setBlacklistHistoryState(true)}>
              Blacklist history
            </Accordion.Header>
            <Accordion.Body>
              <BlackListHistory blacklistHistoryState={blacklistHistoryState} />
            </Accordion.Body>
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="4">
          <Accordion.Header onClick={() => setCashbackHistoryState(true)}>
            Cashback history
          </Accordion.Header>
          <Accordion.Body>
            <CashbackHistory cashbackHistoryState={cashbackHistoryState} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Template Modal */}

      <Modal
        isOpen={templateModal}
        onRequestClose={() => setTemplateModal(false)}
        style={reasonModalStyle}
        shouldCloseOnOverlayClick="true"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop:"10%"
          }}
        >
          <Form.Select
            style={{
              width: "50%",
              // height: "120px",
              borderRadius: "8px",
            }}
            size="sm"
            // htmlSize={"120px"}
            onChange={(e) => {
              setTemplatetype(e.target.value);
            }}
            // onFocus={() => setSelectSize(4)}
            // onBlur={() => setSelectSize(1)}
          >
            <option value={""}>Select Template</option>
            {templatesName?.map((res, id) => (
              <option value={res.unique_name} name={res.unique_name} key={id}>
                {res.unique_name}
              </option>
            ))} 
          </Form.Select>
          <div style={{ marginTop: "50px" }}>
            {/* <Button variant="success" onClick={() => {}}>
              Submit
            </Button> */}
            <Link
              state={{
                userId: userId,
                profileOptions: profileOptions,
                blocked: blocked,
                LimitIncrease: LimitIncrease,
                type: type,
                manual_state: manual_state,
                templatetype:templatetype
              }}
              to="PrintingTemplates"
              className="text-success fw-bolder text-decoration-none"
            >
              Submit
            </Link>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default UserHistory;
