import React, { useEffect, useState } from 'react'
import style from './RequestsCardsFilters.module.scss'
import { toast } from 'react-toastify'

const RequestsCardsFilters = ({ title, item, isPermissions, setFilterTopics, CardTitle, handleActiveCard, activeCard }) => {
  const [ItemCount, setItemCount] = useState(0)

  useEffect(() => {
    if (item?.itemName === activeCard) {
      return setItemCount(item?.length)
    } else {
      return setItemCount(0)
    }
  }, [activeCard, item])

  return (
    <div
      className={`${isPermissions ? activeCard === title ? style.active : style.card_body : style.card_body_disabled}`}
      onClick={(e) => {
        if (isPermissions) {
          setFilterTopics(title);
          handleActiveCard(title)
        } else {
          toast.error("Not have a permission")
        }
      }}
    >
      <p
      >{CardTitle}</p>
      <span className={`${style.item_num}`}>{ItemCount !== 0 ? ItemCount : ''}</span>
    </div>
  )
}

export default RequestsCardsFilters