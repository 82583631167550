import React from 'react'
import { Accordion, Table } from 'react-bootstrap';
import PaginationComponent from '../../../components/pagination/PaginationComponent';

const AuditLogList = ({ auditLogList, page, handleChange }) => {
    return (
        <Accordion.Item eventKey="3">
            <Accordion.Header>
                History
            </Accordion.Header>
            <Accordion.Body>
                <div className="mb-3" controlid="exampleForm.ControlTextarea1">

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Action</th>
                                <th>Created</th>
                                <th>User Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                auditLogList.results?.map((audit, i) => (
                                    <tr key={i}>
                                        <td>{audit.user_phone}</td>
                                        <td>{audit.action}</td>
                                        <td>{audit.created}</td>
                                        <td>{audit.user_category}</td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td></td>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div>
                    <PaginationComponent
                        brands={auditLogList}
                        page={page}
                        handleChange={handleChange}
                        total={false}
                    />
                </div>

            </Accordion.Body>
        </Accordion.Item>
    )
}

export default AuditLogList
