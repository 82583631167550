import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./tools.scss";
import UserContext from "../../hooks/userContext";

export default function Tools(params) {
  //get permission from context api
  const { permissions = [] } = useContext(UserContext);

  //states
  const [filteredArr, setFilteredArr] = useState([]);

  //global check
  const checkIsFound = (arr, link) => {
    const found = arr.find((item) => item.link == link);
    return found ? true : false
  }


  useEffect(() => {
    let arr = []
    permissions && permissions?.forEach(permission => {
      switch (permission) {
        case "is_otp_tool_user":

          if (!checkIsFound(arr, "/otp")) {
            arr.push({
              title: "Otp tool",
              link: "/otp"
            })
          }
          break;

        case "can_create_kiosk_checkers":
        case "can_create_kiosk_makers":

          if (!checkIsFound(arr, "/addKioskUser")) {
            arr.push({
              title: "Create Kiosk User",
              link: "/addKioskUser"
            })
          }
          break;

        case "can_view_statistics_overview":

          if (!checkIsFound(arr, "/statistics/daily")) {
            arr.push({
              title: "Overview statistics",
              link: "/statistics/daily"
            })
          }

          break;
        case "BD_merchant_creation_tool":
        case "Finance_merchant_creation_tool":
        case "Marketing_merchant_creation_tool":

          if (!checkIsFound(arr, "/brands")) {
            arr.push({
              title: "Brands",
              link: "/brands"
            })
          }

          break;

        case "can_manage_kiosk_users":

          if (!checkIsFound(arr, "/KioskManagement")) {
            arr.push({
              title: "Kiosk Management",
              link: "/KioskManagement"
            })
          }

          break;
        case "can_view_overdue_users":

          if (!checkIsFound(arr, "/fawryGenerate")) {
            arr.push({
              title: "Fawry Generate Ref",
              link: "/fawryGenerate"
            })
          }

          break;
        default:
      }
    });
    setFilteredArr(arr)
  }, [permissions]);

  //to prevent duplicates for contract tool
  function contractPermission() {
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i] === "contract_operations") {
        return (
          <Link to="/operationUser/Makers" className="tool_card">
            <h3>Contract tool</h3>
          </Link>
        );
      } else if (
        permissions[i] === "contract_operations_risk" ||
        permissions[i] === "contracts_view_only" ||
        permissions[i] === "contract_operations_archive"
      ) {
        return (
          <Link to="/operationUser/contracts" className="tool_card">
            <h3>Contract tool</h3>
          </Link>
        );
      } else if (
        permissions[i] === "can_view_contract_tracking_summary" ||
        permissions[i] === "contracts_view_only"
      ) {
        return (
          <Link to="/operationUser/summary" className="tool_card">
            <h3>Contract tool</h3>
          </Link>
        );
      }
    }
  }

  return (
    <div className="tool_card_container">
      {
        filteredArr.map((obj, index) => {
          return (
            <Link to={obj.link} className="tool_card" key={index}>
              <h3>{obj.title}</h3>
            </Link>
          )
        })
      }
      {contractPermission()}
    </div>
  );
}
