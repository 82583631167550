import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { UserUtility } from "../../apis/UserUtility";
import { useLocation } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";
import "./Style.scss";
import { icon } from "./icon";
import { ENDPOINTS } from "../../apis/EndPoints";
import { toast } from "react-toastify";

const RequiredImages = () => {
  const [document, setdocument] = useState();
  console.log("document", document);
  const [file, setFile] = useState();

  Modal.setAppElement("#root");

  const location = useLocation();
  const { userId } = location.state;
  const get_extra_documents = () => {
    UserUtility.get_extra_documents(userId)
      .then((res) => {
        setdocument(res.results);
      })
      .catch((err) => console.log("get_extra_documents", err));
  };
  const upload_extra_documents = () => {
    var token = localStorage.getItem("tools_token");
    var formdata = new FormData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    formdata.append("name", file.name);
    formdata.append("file", file);
    formdata.append("profile", userId);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${ENDPOINTS.url}management_dashboard/user_dashboard/extra_documents/`,
      requestOptions
    )
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.json();
          // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
          for (const [key, value] of Object.entries(text?.errors)) {
            let final = (key + "-").concat(value);
            toast.error(final);
          }
          // throw new Error(text);
        } else {
          get_extra_documents();
          toast.success("File has been changed successfully");
          // after change image recall profile data
          return res.json();
        }
      })
      .catch((err) => {
        console.error("extra_documents", err);
      });
  };

  useEffect(() => {
    if (file) {
      upload_extra_documents();
    }
    get_extra_documents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <article className="mt-5">
      <Form.Group
        controlId="formFile"
        className="d-flex justify-content-center"
      >
        <div className="upload_notify_class ">
          <Form.Control
            type="file"
            className="upload_notify_input"
            // disabled={
            //   existUser|| isDisabled || isViewOnly || !userId ? true : false
            // }
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />

          <div className="upload_notify_btn" type="upload">
            <div className="child">
              {icon}
              <p className="mt-2">Drag & drop files or Browse</p>
              {/* <p style={{ fontWeight: "lighter" }}>Supported formates: PDF</p> */}
            </div>
          </div>
        </div>
      </Form.Group>

      {/* <h4 className="text-center">User documents</h4> */}

      <Row className="mt-4" md={2} sm={1} xs={1}>
        {document?.map((res, id) => (
          <Col className="d-flex flex-column align-items-center mt-5" key={id}>
            <a
              // style={{ textDecoration: "none", color: "white" }}
              target="_blank"
              rel="noreferrer"
              href={res.file}
            >
              {res.name}
            </a>
          </Col>
        ))}
      </Row>
    </article>
  );
};
export default RequiredImages;
