import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import {toast } from "react-toastify";

const BlockHistory = () => {
  const [blockHistoryData, setBlockHistoryData] = useState();
  const location = useLocation();
  const { userId, blocked } = location.state;
  let navigate = useNavigate();

  const user_blockHistory = () => {
    UserUtility.block_history(userId)
      .then((res) => {
        setBlockHistoryData(res.results);
      })
      .catch((err) => console.log("user block history err", err));
  };
  useEffect(() => {
    user_blockHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const unblock_user = () => {
    UserUtility.unblock_user(userId)
      .then((res) => {
        toast.success("Unblocked success");
        setTimeout(() => {
          navigate("/users");
        }, 1000);
      })
      .catch((err) => console.log("user block history err", err));
  };
  // console.log("blocke",blocked)
  return (
    <Container className="mt-4">
      <Alert variant={blocked ? "danger" : "success"} className="w-25 mx-auto">
        <Alert.Heading className="text-center">
          {blocked ? "Blocked" : "Unblocked"}
        </Alert.Heading>
      </Alert>
      {/* Blocked History */}
      <h3 className="mt-5">Blocked History</h3>
      <Table
        striped
        bordered
        responsive
        hover
        className="tools_table"
        size="sm"
      >
        <thead>
          <tr>
            <th>Block duration</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {blockHistoryData?.map((data, id) => (
            <tr key={id}>
              <td>{` ${data.block_duration} ${
                data.block_duration === 1 ? "Day" : "Days"
              } `}</td>
              <td>{data.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* <Form>
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Reason Code:</Form.Label>
              <Form.Select className="shadow">
                <option>test1</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Comment:</Form.Label>
              <Form.Control className="shadow" as="textarea" rows={1} />
            </Form.Group>
          </Col>
        </Row>
      </Form> */}

      {/* block or unblock user Button */}
      <section className="w-25 mx-auto">
        {/* <Button
          variant={blocked ? "success" : "danger"}
          onClick={() => console.log("Danger")}
          className="w-100 mt-5"
        >
          {blocked ? "Unblock User" : "Block User"}
        </Button> */}
        <Button
          variant={blocked ? "success" : "danger"}
          onClick={unblock_user}
          className="w-100 mt-5"
          disabled={blocked ? false : true}
        >
          {blocked ? "Unblock User" : "Block User"}
        </Button>
      </section>
    </Container>
  );
};

export default BlockHistory;
