import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import style from '../OnBoard.module.css'
import { Spinner } from 'react-bootstrap';
const PopupComments = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Comments List
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className={style.select_title}>
                    <p className='mb-0 mt-2'>All Comments {props.department}</p>
                </div>
                <div className='m-auto'>
                    {

                        props.comments?.results ? (
                            props.comments?.results?.map((comment, i) => (
                                <div key={i}>
                                    <div className="ms-5 my-1">
                                        {comment.comment}
                                    </div>
                                    <br /><hr />
                                </div>
                            ))
                        ) : (
                            <Spinner animation="grow" variant="info" />
                        )

                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PopupComments
