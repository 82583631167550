import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Stack, Button, Form, Pagination } from "react-bootstrap";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box } from "@mui/material";
import "./BrandLoan.scss";
import moment from "moment";
import { UserUtility } from "../../apis/UserUtility";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const BrandLoan = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [loansData, setLoansData] = useState([]);
  const [pagesCount, setPagesCount] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loanApproved, setLoanApproved] = useState();
  const [totalLoans, setTotalLoans] = useState();
  const [totalDueAmount, setTotalDueAmount] = useState();
  const location = useLocation();
  const { brandData, filterState } = location.state;
  // get branches by id
  useEffect(() => {
    UserUtility.getBrandBranches(brandData.id)
      .then((res) => {
        setBranches(res.results);
      })
      .catch((error) => {
        console.error("error get brand branches", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // get all loans
  useEffect(() => {
    setLoanApproved(filterState);
    getLoansDetails(filterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber, filterState]);

  const getLoansDetails = (Approved) => {
    const start_date = moment(dateFrom).format("YYYY-MM-DD");
    const end_date = moment(dateTo).format("YYYY-MM-DD");
    let loanAprrov = loanApproved !== undefined ? loanApproved : Approved;
    setIsLoading(true);
    UserUtility.brandLonasDetails(
      brandData.id,
      pagesNumber,
      start_date,
      end_date,
      selectedBranch,
      loanAprrov
    )
      .then((res) => {
        setLoansData(res.results);
        setTotalLoans(res.summary.sum_of_loan_amount);
        setTotalDueAmount(res.summary.sum_of_total_due_amount);
        setIsLoading(false);
        let pages = Math.ceil(res.count / 10);
        setPagesCount(pages);
      })
      .catch((error) => {
        console.error("error get loans details", error);
        setIsLoading(false);
      });
  };
  return (
    <main className="BrandLoans_container">
      <h2 className="text-success mt-5">{brandData?.title}</h2>
      <Stack direction="horizontal" className="mt-3" gap={2}>
        <Form.Select
          className="ms-auto"
          // style={{ width: "30%" }}
          size="lg"
          onChange={(e) => setSelectedBranch(e.target.value)}
        >
          <option value="">Choose a branch...</option>
          {branches?.map((branch, id) => (
            <option key={id} value={branch.id}>
              {branch.title}
            </option>
          ))}
        </Form.Select>
        {/* date picker from */}
        <span>From</span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="from"
            inputFormat="dd/MM/yyyy"
            value={dateFrom ? new Date(dateFrom) : null}
            style={{ width: "auto" }}
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  borderRadius: "3px",
                  paddingRight: "5px",
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  style={{ height: "48px", width: "110px", border: 0 }}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
        {/* date picker to */}
        <span>To</span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="To"
            inputFormat="dd/MM/yyyy"
            value={dateTo ? new Date(dateTo) : null}
            style={{ width: "auto" }}
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  borderRadius: "3px",
                  paddingRight: "5px",
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  style={{ height: "48px", width: "110px", border: 0 }}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>

        <div style={{ marginRight: 10, marginLeft: 10 }}>
          <Form.Select
            aria-label="Default select example"
            value={loanApproved}
            onChange={(event) => setLoanApproved(event.target.value)}
            style={{ width: "auto" }}
          >
            <option value={false}>Approved</option>
            <option value={true}>Refunded</option>
          </Form.Select>
        </div>

        <Button
          variant="success"
          className="px-5 py-2"
          disabled={isLoading}
          onClick={getLoansDetails}
        >
          Search
        </Button>
      </Stack>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30,marginBottom:30 }}
      >
        <Table sx={{ minWidth: 2000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Loan Date</StyledTableCell>
              <StyledTableCell>Loan Amount</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>No. Of Months</StyledTableCell>
              <StyledTableCell>Refund date</StyledTableCell>
              <StyledTableCell>Refund by</StyledTableCell>
              <StyledTableCell>Total Due Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>All Loans</StyledTableCell>
              <StyledTableCell>{totalLoans}</StyledTableCell>
              <StyledTableCell>-</StyledTableCell>
              <StyledTableCell>-</StyledTableCell>
              <StyledTableCell>-</StyledTableCell>
              <StyledTableCell>-</StyledTableCell>
              <StyledTableCell>-</StyledTableCell>
              <StyledTableCell>{totalDueAmount}</StyledTableCell>
            </TableRow>
            {loansData?.map((loan, id) => (
              <TableRow key={id}>
                <StyledTableCell>{loan.created}</StyledTableCell>
                <StyledTableCell>{loan.principal}</StyledTableCell>
                <StyledTableCell>{loan.category}</StyledTableCell>
                <StyledTableCell>{loan.full_name}</StyledTableCell>
                <StyledTableCell>{loan.plan_months}</StyledTableCell>
                <StyledTableCell>{loan.refunded_at}</StyledTableCell>
                <StyledTableCell>{loan.refunded_by}</StyledTableCell>
                <StyledTableCell>{loan.total_due_amount}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination className="d-flex justify-content-center flex-wrap">
        <Pagination.First onClick={() => setPagesNumber(1)} />
        {[...Array(pagesCount)].map((x, i) => (
          <Pagination.Item
            value={i + 1}
            // onClick={(e) => setPagesNumber(e.target.attributes.value.value)}
            onClick={(e) => setPagesNumber(Number(e.target.text))}
            active={i + 1 === pagesNumber}
            key={i}
            className={"pagination_item"}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Last onClick={() => setPagesNumber(pagesCount)} />
      </Pagination>
    </main>
  );
};

export default BrandLoan;
