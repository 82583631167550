import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { Button } from "react-bootstrap";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../pagination/PaginationComponent";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

export const ChequesFormComponent = ({ loanId }) => {
    const [cheques, setCheques] = useState([]);
    const [newCheque, setNewCheque] = useState({
        serial_number: 1,
        bank_name: "",
        amount: "",
        due_date: "",
    });

    const { handleLoader, permissions = [] } = useContext(UserContext);
    const isOperationMaker = permissions.includes("one_transaction_operation_maker");
    const [dataDetail, setDataDetail] = useState()
    const [page, setPage] = useState(1)
    const date = Date();
    const todayDate = moment(date)?.format("YYYY-MM-DD");
    const onBeforeGetContentResolve = useRef(null);
    const componentRef = useRef(null);
    const elementHideRef = useRef(null);

    const handleAddCheque = () => {
        const { bank_name, amount, due_date } = newCheque;
        if (bank_name !== "" && amount !== "" && due_date !== "") {
            if (cheques && cheques.length > 0) {
                setCheques((prev) => [...prev, { ...newCheque }]);
            } else {
                setCheques([{ ...newCheque }]);
            }


            setNewCheque({
                serial_number: 1,
                bank_name: "",
                amount: "",
                due_date: "",
            });
        }
    };

    const handleNewCheque = (e) => {
        const { name, value } = e.target;
        console.log('e.target: ', e.target)
        setNewCheque((prevState) => ({
            ...prevState,
            [name]:
                name === "amount"
                    ? value > -1
                        ? value
                        : 0
                    : value,
        }));
    };

    const addCheque = () => {
        let obj = {
            onetransactionloan: loanId,
            ...newCheque,
        };

        if (newCheque.due_date > todayDate) {
            handleLoader(true)

            UserUtility.oneTransCreateCheque(obj)
                .then((res) => {
                    toast.success("Cheque added successfully");
                    getCheques();
                    handleAddCheque();
                    handleLoader(false)

                })
                .catch((err) => {
                    console.error("oneTransCreateLiability: ", err);
                    handleLoader(false)

                });
        } else {
            toast.error("Amount due date should not be less than today")
        }
    };

    //edit cheque
    const handleEditCheque = (index, rowKey, e) => {
        const newItems = cheques?.map((row, id) => {
            return {
                ...row,
                [rowKey]: index === id ? e.target.value : row[rowKey],
            };
        });

        setCheques(newItems);
    };

    const editCheque = (data) => {
        let obj = {
            onetransactionloan: loanId,
            ...data,
        };


        if (data.due_date > todayDate) {

            handleLoader(true)
            UserUtility.oneTransEditCheque(data.id, obj)
                .then((res) => {
                    handleLoader(false)
                    toast.success("Cheques changed successfully");
                    handleAddCheque();
                })
                .catch((err) => {
                    handleLoader(false)
                    console.error("oneTransCheques edit err: ", err);
                    if (err?.detail) {
                        toast.error(err?.detail);
                    }
                });
        } else {
            toast.error("Amount due date should not be less than today")
        }
    };

    const getCheques = () => {
        console.log('paaage: ', page)
        UserUtility.oneTransCheques(loanId, page)
            .then((res) => {
                setDataDetail(res)
                setCheques(res.results);
            })
            .catch((err) => {
                console.error("onTransactionCheques err: ", err);
            });
    };

    //handle pagination page
    const handleChange = (e, value) => {
        setPage(value);
    };


    // start print cheques
    const handleOnBeforeGetContent = useCallback(() => {

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            console.log('onBeforeGetContentResolve.current: ', onBeforeGetContentResolve.current)
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }, []);

    const reactToPrintContent = useCallback(() => {

        const nodeList = document.querySelectorAll("#willHide");
        for (let i = 0; i < nodeList?.length; i++) {
            nodeList[i].style.display = "none";
        }
        componentRef.current.style.overflow = "initial"
        return componentRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);


    const handleOnAfterPrint = useCallback(() => {

        const nodeList = document.querySelectorAll("#willHide");
        for (let i = 0; i < nodeList?.length; i++) {
            nodeList[i].style.display = "table-row";
        }
        componentRef.current.style.overflowX = "auto"

    }, []);
    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint:handleOnAfterPrint,
        removeAfterPrint: true,
    });
    // end print AR


    useEffect(() => {
        getCheques();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    return (
        <article className="branches_container">
            <div className="row_div">
                <Button
                    variant="link"
                    className="link_btn_green "
                    style={{ color: "#41B6A8", fontSize: 20 }}
                    onClick={handlePrint}
                // disabled={loading}

                >
                    Print
                </Button>
            </div>

            <TableContainer
                ref={componentRef}
                component={Paper}
                style={{ height: "fit-content", marginRight: 20 }}
            >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>No.</StyledTableCell>
                            <StyledTableCell>cheque number</StyledTableCell>
                            <StyledTableCell>Bank name</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Amount due date</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(cheques && cheques.length > 0) && cheques?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>
                                    <input
                                        className="branchInput"
                                        value={index + 1}
                                        disabled
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <input
                                        className="branchInput"
                                        value={row.serial_number}
                                        onChange={(e) => handleEditCheque(index, "serial_number", e)}
                                        name="serial_number"
                                    />
                                </StyledTableCell>

                                <StyledTableCell>
                                    <input
                                        className="branchInput"
                                        value={row.bank_name}
                                        onChange={(e) => handleEditCheque(index, "bank_name", e)}
                                        name="bank_name"
                                    />
                                </StyledTableCell>

                                <StyledTableCell>
                                    <input
                                        type="number"
                                        min="0"
                                        className="branchInput"
                                        value={row.amount}
                                        onChange={(e) => handleEditCheque(index, "amount", e)}
                                        name="amount"
                                    />
                                </StyledTableCell>

                                <StyledTableCell>
                                    <input
                                        type={'date'}
                                        className="branchInput"
                                        value={row.due_date}
                                        onChange={(e) => handleEditCheque(index, "due_date", e)}
                                        name="due_date"
                                    />
                                </StyledTableCell>

                                <StyledTableCell>
                                    {isOperationMaker
                                        && (
                                            <Button
                                                variant="primary"
                                                type="button"
                                                className="small_btn"
                                                onClick={() => editCheque(row)}
                                                id="willHide"
                                            >
                                                Save
                                            </Button>
                                        )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {/* row of add icon */}

                        {permissions.includes("add_userliabilities") && isOperationMaker && (
                            <StyledTableRow id="willHide">
                                <TableCell
                                    align="center"
                                    colSpan={6}
                                    style={{ padding: "5px 16px" }}
                                ></TableCell>
                            </StyledTableRow>
                        )}

                        {/* row of inital if not have a data */}
                        <StyledTableRow id="willHide">
                            <StyledTableCell>
                                <input
                                    className="branchInput"
                                    // value={index}
                                    disabled
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <input
                                    className="branchInput"
                                    value={newCheque.serial_number}
                                    onChange={handleNewCheque}
                                    name="serial_number"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <input
                                    className="branchInput"
                                    value={newCheque.bank_name}
                                    onChange={handleNewCheque}
                                    name="bank_name"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <input
                                    type="number"
                                    min="0"
                                    className="branchInput"
                                    value={newCheque.amount}
                                    onChange={handleNewCheque}
                                    name="amount"
                                />
                            </StyledTableCell>

                            <StyledTableCell>
                                <input
                                    type={'date'}
                                    className="branchInput"
                                    value={newCheque.due_date}
                                    onChange={handleNewCheque}
                                    name="due_date"
                                />
                            </StyledTableCell>

                            <StyledTableCell>
                                {isOperationMaker && (
                                    <Button
                                        variant="primary"
                                        type="button"
                                        className="small_btn"
                                        onClick={() => addCheque()}
                                        disabled={
                                            !newCheque.serial_number ||
                                            !newCheque.bank_name ||
                                            !newCheque.amount ||
                                            !newCheque.due_date
                                        }
                                    >
                                        Add
                                    </Button>
                                )}
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent
                brands={dataDetail}
                page={page}
                handleChange={handleChange}
            />
        </article>
    );
};
