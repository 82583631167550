export const lawsuitFields = [
  {
    label: "عدد أيام التأخير",
    name: "total_overdues",
    disabled: true,
  },
  {
    label: "إضافة اسم المكتب",
    name: "office_name",
  },
  {type:"space"},
  {
    label: "اسم العميل",
    name: "customer_name",
    disabled: true,
  },
  {
    label: "رقم البطاقة",
    name: "customer_national_id",
    disabled: true,
  },
  {
    label: "المحكمة",
    name: "court_name",
  },
  {
    label: "الدائرة المختصة",
    name: "department_name",
  },

  {
    label: "رقم الدعوي",
    name: "lawsuit_num",
  },
  {
    label: "الإنذارات",
    name: "Alerts",
  },
  {
    label: "التاريخ",
    name: "date",
    type:'date'
  },
  {
    label: "تاريخ اول جلسة",
    name: "first_session_date",
    type:'date'
  },
  // {type:"space"},
  {
    label: "أنشئت بواسطة",
    name: "initiator_email",
    disabled: true,
  },
  {
    label: "أخر تعديل بواسطة",
    name: "updated_email",
    disabled: true,
  },
];
export const alertFields = [
  {
    label: "تاريخ الإحالة",
    name: "referral_date",
    type:"date",
  },
  {
    label: "تاريخ الإستلام من الأرشيف",
    name: "received_date_from_archieve",
    type:"date",
  },
  {
    label: "عدد أيام التأخير",
    name: "total_overdues",
    disabled: true,
  },
  {
    label: "إضافة اسم المكتب",
    name: "office_name",
  },
  {
    label: "اسم العميل",
    name: "customer_name",
    disabled: true,
  },
  {
    label: "رقم البطاقة",
    name: "customer_national_id",
    disabled: true,
  },
  {
    label: "المحكمة",
    name: "court_name",
  },
  {
    label: "المحضر",
    name: "Court_Bailiff",
  },

  {
    label: "رقم الإنذار",
    name: "alert_num",
  },
  {
    label: "تاريخ التسليم",
    name: "delivered_date",
    type:"date"
  },
  {
    label: "تاريخ الإستلام",
    name: "recieved_date",
    type:"date"
  },
  {
    label: "القائم بالتسليم",
    name: "deliver_person",
  },
  {
    label: "القائم بالتسلم",
    name: "received_person",
  },
{type:'space'},
  {
    label: "أنشئت بواسطة",
    name: "initiator_email",
    disabled: true,
  },
  {
    label: "أخر تعديل بواسطة",
    name: "updated_email",
    disabled: true,
  },
];

export const sessionFileds = [
  {
    label: "الحالة",
    name: "status",
  },
  {
    label: "التاريخ",
    name: "date",
    type:"date"
    
  },
  {
    label: "تاريخ الجلسة الجديدة",
    name: "next_session_date",
    type:"date"
  },
  {
    label: "نص القرار",
    name: "resolution_text",
    type: "textarea",
  },
  {
    type: "space",
  },
  {
    label: "أنشئت بواسطة",
    name: "initiator_email",
    disabled: true,
  },
  {
    label: "أخر تعديل بواسطة",
    name: "updated_email",
    disabled: true,
  },
  
];
export const feesFileds = [
  {
    label: "أنشئت بتاريخ",
    name: "created",
    type:"date"
    
  },
  {
    label: " التعديل بتاريخ",
    name: "modified",
    type:"date"
    
  },
  {
    label: "التعليق",
    name: "comment",
    type: "textarea",
  },
  {
    type: "space",
  },
  {
    label: "القيمة",
    name: "legal_fees",
  },
  {
    label: "مدفوع",
    name: "paid",
    type: "checkbox",
  },
  {
    type: "space",
  },
  {
    type: "space",
  },
  {
    label: "أنشئت بواسطة",
    name: "initiator_email",
    disabled: true,
  },
  {
    label: "أخر تعديل بواسطة",
    name: "updated_email",
    disabled: true,
  },
  
];
