//each array considers as a row,
//Field label is the label text for each field
//Field name is to target the field name in the user data as we get it from the backend
//Field type return either a normal text input, select, text aria, check box, space(for making a space)

export const profileFields = [
  [
    {
      fieldLabel: "Important Dates",
      fieldType: "header",
    },
  ],
  [
    {
      fieldLabel: "Created",
      fieldName: "created",
      fieldType: "text",
      redOnly: true,
      ImportantDate: "true",
    },
    {
      fieldLabel: "Form Submit Date",
      fieldName: "form_submit_date",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "First Time Visited Kiosk",
      fieldName: "first_time_visited_kiosk",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Kiosk Name",
      fieldName: "kiosk__title",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Date Of Activation",
      fieldName: "date_of_activation",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Account Closing Date",
      fieldName: "account_closing_date",
      fieldType: "text",
      redOnly: true,
    },

    {
      fieldLabel: "Date Joined",
      fieldName: "user__date_joined",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Last Login Date",
      fieldName: "user__last_login",
      fieldType: "text",
      redOnly: true,
    },
  ],

  [
    {
      fieldLabel: "On Watch",
      fieldName: "user__on_watch",
      fieldType: "checkbox",
      redOnly: true,
    },
    {
      fieldLabel: "On Watch Date",
      fieldName: "user__on_watch_date",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "On Hold",
      fieldName: "user__on_hold",
      fieldType: "checkbox",
      redOnly: true,
    },
    {
      fieldLabel: "On Hold Date",
      fieldName: "user__on_hold_date",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Blacklisted",
      fieldName: "is_blacklisted",
      fieldType: "checkbox",
      redOnly: true,
    },
    {
      fieldLabel: "User Requests Hold",
      fieldName: "on_hold_request",
      fieldType: "checkbox",
      redOnly: true,
    },
  ],
  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Personal Information",
      fieldType: "header",
    },
  ],
  // personal information
  [
    {
      fieldLabel: "Id",
      fieldName: "id",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "P_state",
      fieldName: "p_state",
      fieldType: "options",
    },
    {
      fieldLabel: "User Type",
      fieldName: "user_type",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Picture",
      fieldName: "picture",
      fieldType: "text",
      redOnly: true,
      image: true,
    },

    {
      fieldLabel: "National ID Number",
      fieldName: "national_id",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Expiration Date",
      fieldName: "national_id_expiration_date",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Profile Name",
      fieldName: "profile_name",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "First Name",
      fieldName: "first_name",
      fieldType: "text",
    },
    {
      fieldLabel: "Second Name",
      fieldName: "second_name",
      fieldType: "text",
    },
    {
      fieldLabel: "Third Name",
      fieldName: "third_name",
      fieldType: "text",
    },
    {
      fieldLabel: "Last Name",
      fieldName: "last_name",
      fieldType: "text",
    },
  ],
  [
    {
      fieldLabel: "Age",
      fieldName: "age",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Gender",
      fieldName: "gender",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Marital Status",
      fieldName: "marital_status",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Education",
      fieldName: "education",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "No. Of independent",
      fieldName: "no_independent",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Mobile number",
      fieldName: "user__phone",
      fieldType: "text",
    },
    {
      fieldLabel: "Mobile invoice amount",
      fieldName: "mobile_invoice_amount",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Mobile invoice type",
      fieldName: "mobile_invoice_type",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Email",
      fieldName: "email",
      fieldType: "text",
    },
    {
      fieldLabel: "Emergency name",
      fieldName: "emergency_contact_name",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Emergency number",
      fieldName: "emergency_contact",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Residential Information",
      fieldType: "header",
    },
  ],
  // Residential Information
  [
    {
      fieldLabel: "Home Type",
      fieldName: "home_type",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Governorate",
      fieldName: "governorate",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Area",
      fieldName: "area",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Duration",
      fieldName: "home_address_duration",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Home Address",
      fieldName: "home_address",
      fieldType: "textaria",
      redOnly: true,
    },
    {
      fieldLabel: "Current Governorate",
      fieldName: "current_governorate",
      fieldType: "options",
    },
    {
      fieldLabel: "Current Area",
      fieldName: "current_area",
      fieldType: "options",
    },
  ],

  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Work Information",
      fieldType: "header",
    },
  ],
  //working info
  [
    {
      fieldLabel: "Occupation",
      fieldName: "occupation",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Occupation position",
      fieldName: "business_position",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Job title",
      fieldName: "job_title__title",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Years in business",
      fieldName: "years_in_business",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Hiring Date",
      fieldName: "hiring_date",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Company Name",
      fieldName: "company_name",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Company Type",
      fieldName: "company_type",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Company Contact",
      fieldName: "company_contact",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Monthly Income",
      fieldName: "net_monthly_income",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Net annual installments",
      fieldName: "net_annual_installments",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Annual Income",
      fieldName: "annual_income",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Company Address",
      fieldName: "company_address",
      fieldType: "textaria",
      redOnly: true,
    },
  ],
  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Financial Information",
      fieldType: "header",
    },
  ],
  // Financial Information
  [
    {
      fieldLabel: "Bank",
      fieldName: "bank",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Bank Limit",
      fieldName: "bank_limit",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "Has credit card",
      fieldName: "have_credit_card",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldLabel: "Took loans 5 years ago",
      fieldName: "took_loans_5_years_ago",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Other Info",
      fieldType: "header",
    },
  ],
  //Other info
  [
    {
      fieldLabel: "Car Brand",
      fieldName: "car_brand",
      fieldType: "car_brand_options",
    },
    {
      fieldLabel: "Car Model",
      fieldName: "car_model",
      fieldType: "car_model_options",
    },
    {
      fieldLabel: "Car year",
      fieldName: "car_year",
      fieldType: "options",
    },
    {
      fieldLabel: "Chases Number",
      fieldName: "chassis_no",
      fieldType: "text",
      redOnly: true,
    },
    {
      fieldLabel: "License Exp. Date",
      fieldName: "licence_expiration_date",
      fieldType: "date",
    },
  ],
  [
    {
      fieldLabel: "Club Name",
      fieldName: "club_name",
      fieldType: "options",
    },
    {
      fieldLabel: "Club ID Exp. Date",
      fieldName: "club_id_expiration_date",
      fieldType: "date",
    },
    {
      fieldLabel: "Club ID",
      fieldName: "club_id",
      fieldType: "text",
    },
  ],
  [
    {
      fieldLabel: "Sent to Drive",
      fieldName: "sent_to_drive",
      fieldType: "checkbox",
      redOnly: true,
    },
    {
      fieldLabel: "Date To Send",
      fieldName: "send_to_drive_last_attempt",
      fieldType: "text",
      redOnly: true,
    },
  ],
  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Documents",
      fieldType: "header",
    },
  ],
  // Documents
  [
    {
      fieldLabel: "National ID front photo",
      fieldName: "national_id_front_image",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "National ID back photo",
      fieldName: "national_id_back_image",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "Car license front photo",
      fieldName: "front_licence_image",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "Car license back photo",
      fieldName: "back_licence_image",
      fieldType: "text",
      image: true,
    },
  ],
  [
    {
      fieldLabel: "Club ID front photo",
      fieldName: "club_front_image",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "Club ID back photo",
      fieldName: "club_back_image",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "Contract",
      fieldName: "contract",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "Contract Back",
      fieldName: "contract_back",
      fieldType: "text",
      image: true,
    },
  ],
  [
    {
      fieldLabel: "Contract Extra",
      fieldName: "contract_extra",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "contract Image 4",
      fieldName: "contract_image_4",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "contract Image 5",
      fieldName: "contract_image_5",
      fieldType: "text",
      image: true,
    },
    {
      fieldLabel: "contract Image 6",
      fieldName: "contract_image_6",
      fieldType: "text",
      image: true,
    },
  ],
  [
    {
      fieldType: "space",
    },
  ],
  [
    {
      fieldLabel: "Login Info",
      fieldType: "header",
    },
  ],
  [
    {
      fieldLabel: "Device Name",
      fieldLabel1: "First Time Login",
      fieldLabel2: "Last Login",
      fieldLabel3: "Initial",
      fieldLabel4: "Status",
      fieldLabel5: "Action",
      fieldName: "",
      redOnly: true,
      table: true,
    },
    {
      fieldName: "",
      redOnly: true,
      // table:true
    },
    {
      fieldLabel: "Initiale",
      fieldName: "",
      redOnly: true,
      // table:true
    },
    {
      fieldLabel: "status",
      fieldName: "",
      redOnly: true,
      // table:true
    },
    {
      fieldLabel: "Action",
      fieldName: "",
      redOnly: true,
      // table:true
    },
  ],
  [
    {
      fieldLabel: "Credit Info",
      fieldType: "header",
      permissionField: true,
    },
  ],
  // Credit Info
  [
    {
      fieldType: "button",
      fieldName: "View Credit Limit",
      permissionField: true,
    },
  ],
  [
    {
      fieldType: "state",
      fieldName: "p_state",
      permissionField: true,
    },
  ],

  [
    {
      fieldLabel: "Checker",
      fieldName: "checker__email",
      fieldType: "text",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "Checker comments",
      fieldName: "checker_comments",
      // fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "Decline Reason",
      fieldName: "decline_reason",
      fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "Declined",
      fieldName: "declined",
      fieldType: "checkbox",
      redOnly: true,
      permissionField: true,
    },
  ],
  [
    {
      fieldLabel: "Maker",
      fieldName: "maker__email",
      fieldType: "text",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "iscore state",
      fieldName: "iscore_state",
      fieldType: "options",
      permissionField: true,
    },
    {
      fieldLabel: "passed iscore test",
      fieldName: "passed_iscore_test",
      fieldType: "checkbox",
      redOnly: true,
      permissionField: true,
    },
  ],
  [
    {
      fieldLabel: "Profile Finance Limit",
      fieldName: "profile_finance_limit",
      fieldType: "text",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "Estimated Forsa Limit",
      fieldName: "estimated_forsa_limit",
      fieldType: "text",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "Estimated Iscore Limit",
      fieldName: "estimated_iscore_limit",
      fieldType: "text",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "Estimated Limit Report",
      fieldName: "estimate_limit",
      fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
  ],
  // [
  //   {
  //     fieldType: "space",
  //   },
  // ],
  [
    {
      fieldLabel: "rejection reason",
      fieldName: "rejection_reason__reason",
      fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "cancel reason",
      fieldName: "cancel_reason",
      fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
  ],
  [
    {
      fieldLabel: "iscore report",
      fieldName: "iscore_report",
      fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
    {
      fieldLabel: "iscore parsed report",
      fieldName: "iscore_parsed_report",
      fieldType: "textaria",
      redOnly: true,
      permissionField: true,
    },
  ],
];
