/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import "./Brands.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Colors,
  StyledTableCell,
  StyledTableRow,
} from "../../constants/Colors";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

const filtersArray = [
  {
    filterName: "Newest",
    filterTag: "newest",
    filterState: false,
  },
  {
    filterName: "Best seller",
    filterTag: "best_seller",
    filterState: false,
  },

  {
    filterName: "Featured",
    filterTag: "featured",
    filterState: false,
  },

  {
    filterName: "Published",
    filterTag: "published",
    filterState: false,
  },
  {
    filterName: "Un published",
    filterTag: "un_published",
    filterState: false,
  },
];

const actionsList = [
  {
    actionName: "Generate POS Users",
    actionValue: "POS",
    permission: "action_generate_pos_users",
  },
  {
    actionName: "Generate Merchant Users",
    actionValue: "generate",
    permission: "action_generate_merchant_users",
  },
  {
    actionName: "Re Generate Merchant Users",
    actionValue: "re-generate",
    permission: "action_re_generate_merchant_users",
  },
];

const Brands = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState("");
  const [brands, setBrands] = useState([]);
  const [brandsDetail, setBrandsDetail] = useState();
  const [filters, setFilters] = useState(filtersArray);
  const { handleLoader, permissions } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [actionSelected, setActionSelected] = useState();
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [brandIds, setBrandIds] = useState([]);

  console.log(brandsDetail);
  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };
  const handleChangeSector = (event) => {
    setSelectedSectors(event.target.value);
  };

  //get brands from BE
  const getBrands = () => {
    const filtersStates = filters.map((filter) => filter.filterState);
    UserUtility.getBrands(page, searchValue, selectedSectors, ...filtersStates)
      .then((res) => {
        setBrandsDetail(res);
        setBrands(res.results);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    setIsLoading(false);
  };

  //search Btn
  const brandSearch = (e) => {
    e.preventDefault();
    getBrands();
    setIsLoading(true);
  };
  // to handle publish button to publish or unpublish
  const publishChange = (brandId, publishState) => {
    handleLoader(true);
    UserUtility.publishBrand(brandId, publishState)
      .then(() => {
        handleLoader(false);
        getBrands();
      })
      .catch((error) => {
        console.error("publish brand error", error);
        handleLoader(false);
      });
  };

  const handleActivePlan = (index) => {
    //to add or remove checkbox as UI
    const newFilters = filters.map((filterInfo, id) => {
      return {
        ...filterInfo,
        filterState:
          index === id ? !filterInfo.filterState : filterInfo.filterState,
      };
    });
    setFilters(newFilters);
  };

  const publishSwitch = (isPublish, id) => {
    switch (true) {
      case isPublish && permissions.includes("Finance_merchant_creation_tool"):
      case isPublish &&
        permissions.includes("Marketing_merchant_creation_tool"):
        return "Published";

      case isPublish && permissions.includes("BD_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.red,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, false)}
          >
            Unpublish
          </Button>
        );
        break;

      case !isPublish && permissions.includes("Finance_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.primary,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, true)}
          >
            Publish
          </Button>
        );
        break;

      case !isPublish && permissions.includes("BD_merchant_creation_tool"):
      case !isPublish &&
        permissions.includes("Marketing_merchant_creation_tool"):
        return "Not Published";
        break;

      default:
        break;
    }
  };

  // brand actions

  const handleActiveBrand = (index, brandId) => {
    //to add or remove checkbox as UI
    const newBands =
      brands &&
      brands.map((brand, id) => {
        return {
          ...brand,
          active: index === id ? !brand.active : brand.active,
        };
      });
    setBrands(newBands);
    //to add and remove makersId to send to BE
    const checkId = brandIds.filter((brand) => brand === brandId);
    if (checkId[0]) {
      const removeId = brandIds.filter((brand) => brand !== brandId);
      setBrandIds(removeId);
    } else {
      setBrandIds((oldBrands) => [...oldBrands, brandId]);
    }
  };

  const handleSelectAll = (event) => {
    setIsCheckedAll(event.target.checked);
    //to add or remove checkbox as UI
    const newBrands =
      brands &&
      brands.map((brand, id) => {
        return {
          ...brand,
          active: event.target.checked,
        };
      });

    setBrands(newBrands);
    if (event.target.checked) {
      const AllIds = newBrands && newBrands.map((item, index) => item.id);
      setBrandIds(AllIds);
    } else {
      setBrandIds([]);
    }
  };

  const handleAction = () => {
    if (actionSelected === "POS") {
      generatePOSHandle();
    } else if (actionSelected === "generate") {
      generateMerchantUsersHandle();
    } else if (actionSelected === "re-generate") {
      reGenerateMerchantUsersHandle();
    }
  };

  // generate pos function
  const generatePOSHandle = () => {
    handleLoader(true);
    UserUtility.generatePOS(brandIds)
      .then((res) => {
        handleLoader(false);
        if (res.status === "error") {
          res?.data
            ? toast.error(res.message + "to brands: " + res.data.toString())
            : toast.error(res.message);
        } else {
          toast.success(res.Success);
        }
      })
      .catch((err) => {
        handleLoader(false);
        toast.error(err.message);
      });
  };

  // generate merchant users function
  const generateMerchantUsersHandle = () => {
    handleLoader(true);
    UserUtility.generateMerchantUsers(brandIds)
      .then((res) => {
        handleLoader(false);
        if (res.status === "error") {
          res?.data
            ? toast.error(res.message + "to brands: " + res.data.toString())
            : toast.error(res.message);
        } else {
          toast.success(res.Success);
        }
      })
      .catch((err) => {
        handleLoader(false);
        toast.error(err.message);
      });
  };

  // re-generate merchant users function
  const reGenerateMerchantUsersHandle = () => {
    handleLoader(true);
    UserUtility.RegenerateMerchantUsers(brandIds)
      .then((res) => {
        handleLoader(false);
        if (res.status === "error") {
          res?.data
            ? toast.error(res.message + "to brands: " + res.data.toString())
            : toast.error(res.message);
        } else {
          toast.success(res.Success);
        }
      })
      .catch((err) => {
        handleLoader(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    handleLoader(true);
    UserUtility.getBrandSectors()
      .then((res) => {
        setSectors(res.results);
        handleLoader(false);
      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  }, []);
  useEffect(() => {
    getBrands();
  }, [page]);
  return (
    <div className="brands-container">
      <h2>Brands</h2>

      {/* search component */}
      <article>
        <form className="searchBar_wrapper">
          <TextField
            size="small"
            placeholder="Search by brand name"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="text-field"
            label="Brand name"
          />
          <FormControl className="select-field">
            <InputLabel id="select-label">Sector</InputLabel>
            <Select
              size="small"
              id="demo-simple-select"
              value={selectedSectors}
              label="Sector"
              onChange={handleChangeSector}
            >
              {sectors?.map((sector, id) => (
                <MenuItem value={sector.id} key={id}>
                  {sector.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className="searchButton"
            type="submit"
            variant="contained"
            disabled={isLoading}
            onClick={(e) => brandSearch(e)}
          >
            {isLoading ? "Loading..." : "Search"}
          </Button>
        </form>
      </article>

      {/* filters container */}
      <article className="filters_containerr">
        {filters.map((filter, id) => {
          return (
            <section key={id}>
              <button onClick={() => handleActivePlan(id)} className="checkBtn">
                <Checkbox size="small" checked={filter.filterState} />
              </button>
              <span>{filter.filterName}</span>
            </section>
          );
        })}
      </article>

      {/* add brand btn */}
      {permissions.includes("BD_merchant_creation_tool") && (
        <div>
          <Link to="/brands/newbrand/GeneralInfo" state={{ newBrand: true }}>
            <Button type="submit" variant="contained" className="addBrandBtn">
              Add Brand / Merchant
            </Button>
          </Link>

          <Link to="/globalOffer" state={{ newBrand: true }} >
            <Button type="submit" variant="contained" className="addBrandBtn" style={{ marginRight: 20 }}>
              Add global offer
            </Button>
          </Link>
        </div>
      )}

      {/* table container */}
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  size="small"
                  checked={brands && brands?.length > 0 ? isCheckedAll : false}
                  // disabled={brands?.length === 0}
                  onChange={(event) => handleSelectAll(event)}
                />
              </StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Brand name</StyledTableCell>
              <StyledTableCell>Sector</StyledTableCell>
              <StyledTableCell>Featured</StyledTableCell>
              <StyledTableCell>Best seller</StyledTableCell>
              <StyledTableCell>Newest</StyledTableCell>
              <StyledTableCell>Published</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Form.Check
                    type="checkbox"
                    onChange={() => handleActiveBrand(index, row.id)}
                    checked={row.active}
                  />
                </StyledTableCell>

                <StyledTableCell>{row.id}</StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Link
                    to={`/brands/${row.title}/GeneralInfo`}
                    state={{
                      newBrand: false,
                      brandName: row.title,
                      brandId: row.id,
                      publishedBrand: row.published,
                    }}
                    style={{
                      color: "#000",
                      textDecoration: "none",
                      fontWeight: 700,
                    }}
                  >
                    {row.title}
                  </Link>
                </StyledTableCell>
                <StyledTableCell>{row.sector}</StyledTableCell>
                <StyledTableCell>
                  <Checkbox checked={row.featured} size="small" />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox checked={row.best_seller} size="small" />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox checked={row.newest} size="small" />
                </StyledTableCell>
                <StyledTableCell>
                  {publishSwitch(row.published, row.id)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* new actions wit new permission and all new action must have (Finance_merchant_creation_tool) permission */}
      {(permissions.includes("Finance_merchant_creation_tool") || (permissions.includes("BD_merchant_creation_tool"))) && (
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            width: "30%",
            marginTop: 40,
          }}
        >
          <Form.Select
            style={{ marginRight: 10 }}
            size="md"
            onChange={(e) => {
              setActionSelected(e.target.value);
            }}
          >
            <option value={""}>-------</option>
            {actionsList.map((res, id) => (
              <>
                {(permissions.includes(res.permission) && (permissions.includes("Finance_merchant_creation_tool") && res.permission !== 'action_generate_pos_users')) ? (
                  <option
                    value={res.actionValue}
                    name={res.actionName}
                    key={id}
                  >
                    {res.actionName}
                  </option>
                )
                  :
                  <>
                    {(permissions.includes("BD_merchant_creation_tool") && res.permission === 'action_generate_pos_users') && (
                      <option
                        value={res.actionValue}
                        name={res.actionName}
                        key={id}
                      >
                        {res.actionName}
                      </option>
                    )}
                  </>
                }
              </>
            ))}
          </Form.Select>
          <Button variant="success" className="px-5 btn" onClick={handleAction}>
            Submit
          </Button>
        </div>
      )}
      <PaginationComponent
        brands={brandsDetail}
        page={page}
        handleChange={handleChange}
        total={false}
      />
    </div>
  );
};

export default Brands;
