import React, { useEffect, useState } from "react";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";

const Documents = ({ item }) => {
  const [DocumentsList, setDocumentsList] = useState([]);
  const [documentObj, setDocumentObj] = useState();
  const [page, setPage] = useState(1);

   //handle pagination page
   const handleChange = (e, value) => {
    setPage(value);
  };
  useEffect(() => {
    item?.id &&
      UserUtility.Documents(item?.id, page).then((res) => {
        setDocumentsList(res.results);
        setDocumentObj(res);
      });
  }, [item?.id, page]);

  // const handleDownload = (ExamplePdf) => {
  //     const link = document.createElement('a');
  //     link.download = 'Report-PDF-File';
  //     link.href = ExamplePdf;
  //     link.click();
  // };

  return (
    <div>
      {DocumentsList?.map((doc) => (
        <div key={doc.id}>
          <a href={doc.file_field} target="blank">
            {doc.file_field}
          </a>
        </div>
      ))}

      <PaginationComponent
        brands={documentObj}
        page={page}
        handleChange={handleChange}
        total={false}
      />
    </div>
  );
};

export default Documents;
