import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import GoogleMapReact from "google-map-react";
import { HiLocationMarker } from "react-icons/hi";

const UserLocation = () => {
  const [locationHistory, setLocationHistory] = useState();
  const location = useLocation();
  const { userId } = location.state;
  const user_location = () => {
    UserUtility.user_location(userId)
      .then((res) => {
        setLocationHistory(res.results);
      })
      .catch((err) => console.error("actions_list", err));
  };
  useEffect(() => {
    user_location();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderLocations = ({ time }) => {
    return (
      <div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              <strong>{time}</strong>
            </Tooltip>
          }
        >
          <div>
            <HiLocationMarker className="locationIcon" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyD1bAslmzWadp0qPL33DJqPs6Nmd67864s",
        }}
        defaultCenter={{ lat: 30.0444, lng: 31.235574 }}
        defaultZoom={8}
      >
        {locationHistory?.map((mapLocation, index) => {
          // const regExp = /\((.*?)\)/;
          // const [latitude, longitude] = mapLocation?.coordinates
          //   .match(regExp)[1]
          //   .split(" ");
          return (
            <RenderLocations
              key={index}
              lat={mapLocation.latitude}
              lng={mapLocation.longitude}
              time={mapLocation.created_at}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default UserLocation;
