import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { customStyles, StyledTableCell, StyledTableRow } from "../../constants/Colors";
import "./OneTransactionStyle.scss";
import moment from "moment";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import UserContext from "../../hooks/userContext";
import { ChequesFormComponent } from "../../components/OneTransactionComponent/ChequesFormComponent";
import { toast } from "react-toastify";



export const OperationOneTransaction = () => {
  let navigate = useNavigate();
  const { permissions } = useContext(UserContext);
  const [searcnNational, setSearchNational] = useState();
  const [totalList, setTotalList] = useState();
  const [page, setPage] = useState(1);
  const [operation_list, setOperation_list] = useState();
  const [loanId, setLoanId] = useState();
  const [creditSelected, setCreditSelected] = useState();
  const CreateContractStatus = { status: "studying_operation" };
  const [isOpenCheque, setIsOpenCheque] = useState(false);
  const isOperationMaker = permissions.includes(
    "one_transaction_operation_maker"
  )
    ? true
    : false;

  const isOperationChecker = permissions.includes(
    "one_transaction_operation_checker"
  )
    ? true
    : false;

  const operationList = (searcnNational) => {
    UserUtility.operationList(page, searcnNational, creditSelected)
      .then((res) => {
        setTotalList(res);
        setOperation_list(res.results);
      })
      .catch((err) => {
        console.error("operationList:", err);
      });
  };
  const handleChange = (e, value) => {
    setPage(value);
  };
  const contractsSearch = (e) => {
    e.preventDefault();
    operationList(searcnNational);
  };

  const creatContractFun = (profileData) => {
    if (profileData?.onetransactionloandata?.status === "approved_credit") {
      UserUtility.sendToOperationChekcer(profileData.id, CreateContractStatus)
        .then(() => { })
        .catch((err) => {
          console.error("creatContractFun:", err);
          toast.error(err)
        });
    }


    setLoanId(profileData.id);
    navigate(`/oneTransaction/credit/creditMemo/${profileData.id}/`, {
      state: {
        loanId: profileData.id,
        showHistory: true,
        isViewOnly: false,
        fromPage: 'operationTab'
      }
    })
  };

  useEffect(() => {
    operationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <main className="oneTransaction_container">
      <section>
        <form>
          <article className="searchBar_wrapper searchBar_wrapper_full">
            <section className="search_fields_wrapper">
              <TextField
                size="small"
                placeholder="National ID / Phone Number"
                value={searcnNational}
                onChange={(e) => {
                  setSearchNational(e.target.value);
                }}
                className="text-field"
                label="National ID / Phone Number"
              />
              <FormControl className="select-field">
                <InputLabel>Credit Status</InputLabel>
                <Select
                  className="select_onetrans_class"
                  size="small"
                  id="demo-simple-select"
                  // value={creditSelected}
                  label="Credit Status"
                  onChange={(e) => setCreditSelected(e.target.value)}
                >
                  <MenuItem value="All">All</MenuItem>

                  <MenuItem value="approved_credit">Approved Credit</MenuItem>
                  <MenuItem value="studying_operation">
                    Studying Operation
                  </MenuItem>
                  <MenuItem value="restudying_operation">
                    Restudying Operation
                  </MenuItem>
                  <MenuItem value="checking_operation">
                    Checking Operation
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                className="searchButton"
                type="submit"
                variant="contained"
                onClick={(e) => contractsSearch(e)}
              >
                Search
              </Button>
            </section>
          </article>
          {/* filters container */}
        </form>
      </section>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>National ID</StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Application Status</StyledTableCell>
              <StyledTableCell>IScore Date</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operation_list?.map((ele, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {ele.userprofile.onetransactionprofile.national_id}
                  </StyledTableCell>
                  <StyledTableCell>
                    {ele.userprofile.onetransactionprofile.first_name +
                      " " +
                      ele.userprofile.onetransactionprofile.last_name}
                  </StyledTableCell>
                  <StyledTableCell>{ele.created}</StyledTableCell>
                  <StyledTableCell>
                    {ele.onetransactionloandata.status}
                  </StyledTableCell>

                  <StyledTableCell>
                    {moment(ele.onetransactionloandata.iscore_date).format(
                      "DD-MM-YYYY"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {[
                      "approved_credit",
                      "studying_operation",
                      "restudying_operation",
                    ].some((i) =>
                      ele.onetransactionloandata.status.includes(i)
                    ) &&
                      isOperationMaker && (
                        <Button
                          className="small_btn"
                          type="click"
                          variant="contained"
                          onClick={() => creatContractFun(ele)}
                        >
                          Create Contract
                        </Button>
                      )}

                    {ele.onetransactionloandata.status ===
                      "checking_operation" &&
                      isOperationChecker && (
                        <Button
                          className="small_btn"
                          type="click"
                          variant="contained"
                          onClick={() => (
                            creatContractFun(ele)
                          )}
                        >
                          Check Contract
                        </Button>
                      )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={totalList}
        page={page}
        handleChange={handleChange}
      />

      {/* modal of create liability */}
      <Modal
        isOpen={isOpenCheque}
        onRequestClose={() => setIsOpenCheque(false)}
        style={customStyles}
        contentLabel="Liabilities"
        shouldCloseOnOverlayClick={true}
      >
        <ChequesFormComponent loanId={loanId} />
      </Modal>
    </main>
  );
};
