/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Table } from "react-bootstrap";
import Modal from "react-modal";
import { UserUtility } from "../../../../apis/UserUtility";
import { installments } from "./TablesHeader";
import { products } from "./TablesHeader";
import { penalties } from "./TablesHeader";
import PaginationComponent from "../../../../components/pagination/PaginationComponent";
import ModalTables from "./ModalTables";
import { toast } from "react-toastify";
import UserContext from "../../../../hooks/userContext";
import { ENDPOINTS } from "../../../../apis/EndPoints";
import axios from "axios";
import "./table.scss";
import { FaSort } from "react-icons/fa";

const CollectionTables = ({
  Section,
  list,
  user_id,
  totalProducts,
  totalPenaltiesRes,
  productsPage,
  setProductsPage,
  penaltiesPage,
  setPenaltiesPage,
  createdDateSorting,
  setCreatedDateSorting,
  installmentAmountSorting,
  setInstallmentAmountSorting,
  installmentDueDate,
  setInstallmentDueDate,
  setSortingColumn,
  sortingColumn,
  remainPenalties,
  setRemainPenalties,
  holdPenalties,
  setHoldPenalties,
}) => {
  Modal.setAppElement("#root");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "85%",
      overflowY: "scroll",
      height: "85%",
    },
  };
  const [settleOpen, setSettleOpen] = useState(false);
  const [object, setObject] = useState([]);
  const [elementSelected, setElementSelected] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [totalPenalties, setTotalPenalties] = useState();
  const [totalDues, setTotalDues] = useState();
  const [totalEarly, setTotalEarly] = useState();
  const [calculateEarly, setCalculatelEarly] = useState();
  const [buttonName, setButtonName] = useState();
  const [advancedBalance, setAdvancedBalance] = useState();
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [remainSorting, setRemainSorting] = useState(false);
  const [installmentDateSorting, setInstallmentDateSorting] = useState(false);
  const [paidSorting, setPaidSorting] = useState(false);

  // Sorting
  const installmentDate_sorting = () => {
    if (installmentDateSorting === true) {
      list?.installments?.sort((a, b) => {
        return new Date(a.due_date) - new Date(b.due_date);
      });
    } else {
      list?.installments?.sort((a, b) => {
        return new Date(b.due_date) - new Date(a.due_date);
      });
    }
  };
  const paidDate_sorting = () => {
    if (paidSorting === true) {
      list?.installments?.sort((a, b) => {
        return new Date(a.last_payment_date) - new Date(b.last_payment_date);
      });
    } else {
      list?.installments?.sort((a, b) => {
        return new Date(b.last_payment_date) - new Date(a.last_payment_date);
      });
    }
  };
  const remainingAmount_sorting = () => {
    if (remainSorting === true) {
      list?.installments?.sort((a, b) => {
        return (
          a.installments_remaining_principal -
          b.installments_remaining_principal
        );
      });
    } else {
      list?.installments?.sort((a, b) => {
        return (
          b.installments_remaining_principal -
          a.installments_remaining_principal
        );
      });
    }
  };

  // End of Sorting

  const isInstallmentsPerm = permissions.includes("can_view_user_one_txn_installments")
    ? true
    : false;
  const isProductsPerm = permissions.includes("can_view_user_one_txn_products")
    ? true
    : false;
  const isPenaltiesPerm = permissions.includes("can_view_user_one_txn_penalties")
    ? true
    : false;

  const today = new Date().toISOString().slice(0, 10);

  const selectall = list?.installments?.filter((e) =>
    e.settled === false && e.due_date <= today ? e : ""
  );
  const selectallPenalties =
    Section === "Penalties" && list?.filter((e) => e.paid === false && e);
  const amounts = elementSelected?.map((e) => {
    return e?.installments_due_amount;
  });
  const TotalPenalties = elementSelected?.map((e) => {
    return e?.penalties_due_amount;
  });
  const TotalEarly = elementSelected?.map((e) => {
    return e?.early_settlement_amount;
  });
  const selectallProduction =
    Section === "Products" && list?.filter((e) => e.settled === false && e);
  // Select All Validation
  const compare = (a, b) => {
    return a - b;
  };

  const selectFilter =
    Section === "Installments" &&
    list?.installments?.filter((e) =>
      !e.settled && e.due_date <= today ? e : null
    );

  const insatallmentsDatesCompare = (a, b) => {
    a = new Date(a);
    b = new Date(b);
    return a - b;
  };
  const selectAllInstallmentsValidation =
    Section === "Installments" &&
    selectall?.map((e) => {
      return e.due_date;
    });
  const selectAllPenaltiesValidation =
    Section === "Penalties" &&
    selectallPenalties?.map((e) => {
      return e.id;
    });
  const selectAllProductsValidation =
    Section === "Products" &&
    selectallProduction?.map((e) => {
      return e.id;
    });
  const elementSelectedValidation = elementSelected?.map((e) => {
    return Section === "Installments" ? e.due_date : e.id;
  });

  const selectamount = () => {
    let sum = 0;
    let sumPen = 0;
    let sumEarly = 0;

    for (let i = 0; i < amounts?.length; i += 1) {
      sum += amounts[i];
    }
    for (let i = 0; i < TotalPenalties?.length; i += 1) {
      sumPen += TotalPenalties[i];
    }
    for (let i = 0; i < TotalEarly?.length; i += 1) {
      sumEarly += TotalEarly[i];
    }
    setTotalAmount(sum);
    setTotalPenalties(sumPen);
    setTotalEarly(sumEarly);
    setTotalDues(sum + sumPen);
  };
  const checkFun = () => {
    let unique = [];
    object?.forEach((element) => {
      if (!unique.includes(element)) {
        unique.push(element);
        const compare = (a, b) => {
          return a - b;
        };
        unique?.sort(compare);
      } else {
        unique.splice(unique.indexOf(element), 1);
      }
    });

    setElementSelected(unique);
  };
  const toastMsg = () => {
    toast.error("Please select one record at least");
  };
  const handleChange = (e, value) => {
    if (Section === "Products") return setProductsPage(value);
    if (Section === "Penalties") return setPenaltiesPage(value);
  };
  const getAdvancedBalance = () => {
    UserUtility.advanced_payment_oneTxn(user_id)
      .then((res) => {
        setAdvancedBalance(res.one_txn_remaining_amount);
      })
      .catch((err) => {
        console.error("getAdvancedBalance", err);
      });
  };
  const calculation = () => {
    if (elementSelected?.length !== 0) {
      let sum = 0;
      for (let i = 0; i < TotalEarly?.length; i += 1) {
        sum += TotalEarly[i];
      }
      const calculationResult = sum?.toFixed(2);
      setCalculatelEarly(calculationResult);
    } else {
      toast.error("Please select one record at least");
    }
  };

  // hold penalties
  const handleHoldPenalties = () => {
    if (elementSelected?.length !== 0) {
      let selectedIds = elementSelected?.map((ele) => {
        return ele.id;
      });
      handleLoader(true);
      UserUtility.hold_penalties_oneTxn(user_id, selectedIds)
        .then((res) => {
          setPenaltiesPage(1);
          handleLoader(false);
          toast.success("Selected penalties hold successfully ");
          setTimeout(() => {
            window.location.reload();
          }, 400);
        })
        .catch((err) => {
          toast.error(err);
          console.error("err of holdPenalties: ", err);
          handleLoader(false);
        });
    } else {
      toast.error("Please select one record at least");
    }
  };

  const unhandleHoldPenalties = () => {
    if (elementSelected?.length !== 0) {
      let selectedIds = elementSelected?.map((ele) => {
        return ele.id;
      });
      handleLoader(true);
      UserUtility.unhold_penalties_oneTxn(user_id, selectedIds)
        .then(() => {
          setPenaltiesPage(1);
          handleLoader(false);
          toast.success("Selected penalties un-hold successfully ");
          setTimeout(() => {
            window.location.reload();
          }, 400);
        })
        .catch((err) => {
          console.error("err of unholdPenalties: ", err);
          toast.error(err);
          handleLoader(false);
        });
    } else {
      toast.error("Please select one record at least");
    }
  };
  const installmentExport = async () => {
    handleLoader(true);
    const token = localStorage.getItem("tools_token");
    const headers = {
      "Content-Type": "blob",
      Authorization: `token ${token}`,
    };
    const config = {
      method: "GET",
      url: `${ENDPOINTS.url}finance/cash_collection/one_txn/user_profile/${user_id}/installments/export/`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `installment-${today}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      throw Error(error);
    }
  };
  const exportPenalties = async () => {
    handleLoader(true);
    const token = localStorage.getItem("tools_token");
    const headers = {
      "Content-Type": "blob",
      Authorization: `token ${token}`,
    };
    const config = {
      method: "GET",
      url: `${ENDPOINTS.url}finance/cash_collection/one_txn/user_profile/${user_id}/penalties/export/`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Penalties-${today}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      throw Error(error);
    }
  };
  useEffect(() => {
    if (elementSelected?.length === 0) {
      setCalculatelEarly("");
    }
  }, [elementSelected]);

  useEffect(() => {
    selectamount();
  }, [amounts]);
  useEffect(() => {
    checkFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object]);
  useEffect(() => {
    getAdvancedBalance();
  }, []);

  useEffect(() => {
    setElementSelected([]);
    setObject([]);
  }, [productsPage, penaltiesPage]);
  return (
    <div>
      <div className="textStyle">
        <Form.Text className="fs-5" style={{ color: "white" }}>
          {Section}
        </Form.Text>
      </div>
      {Section === "Installments" && (
        <div className="d-flex justify-content-end me-3 mt-2">
          <Button
            style={{
              backgroundColor: "#418AB6",
              paddingInline: 40,
              color: "#FFFFFF",
              alignSelf: "center",
            }}
            onClick={() => installmentExport()}
          >
            Export
          </Button>
          <div className="advanced_payment ">
            <Form.Text className="fw-bold">Advanced Payment</Form.Text>
            <Form.Label className="fw-bolder">
              {advancedBalance}
            </Form.Label>
          </div>
        </div>
      )}
      {Section === "Penalties" && (
        <div className="d-flex justify-content-end me-3 mt-2">
          <Button
            style={{
              backgroundColor: "#418AB6",
              paddingInline: 40,
              color: "#FFFFFF",
              alignSelf: "center",
            }}
            onClick={() => exportPenalties()}
          >
            Export
          </Button>
        </div>
      )}
      <Table striped responsive hover className="mt-2 users_table ">
        <thead>
          <tr>
            <th>
              <Form.Check
                type="checkbox"
                onChange={() =>
                  elementSelected?.length === 0
                    ? Section === "Installments"
                      ? setObject(selectall)
                      : Section === "Penalties"
                      ? setObject(selectallPenalties)
                      : setObject(selectallProduction)
                    : setObject([])
                }
                checked={
                  Section === "Installments"
                    ? selectall?.length !== 0 &&
                      selectAllInstallmentsValidation
                        ?.sort(insatallmentsDatesCompare)
                        ?.join() ===
                        elementSelectedValidation
                          ?.sort(insatallmentsDatesCompare)
                          ?.join() &&
                      true
                    : Section === "Penalties"
                    ? selectallPenalties?.length !== 0 &&
                      selectAllPenaltiesValidation?.sort(compare)?.join() ===
                        elementSelectedValidation?.sort(compare)?.join() &&
                      true
                    : selectallProduction?.length !== 0 &&
                      selectAllProductsValidation?.sort(compare)?.join() ===
                        elementSelectedValidation?.sort(compare)?.join() &&
                      true
                }
              />
            </th>
            {(Section === "Installments"
              ? installments
              : Section === "Products"
              ? products
              : penalties
            )?.map((e, index) => (
              <th key={index}>
                {e.label}{" "}
                {e.sort && (
                  <span
                    style={{ cursor: "pointer", color: "white", fontSize: 20 }}
                    onClick={() =>
                      e.label === "Remaining Amount"
                        ? (setRemainSorting(!remainSorting),
                          remainingAmount_sorting())
                        : e.label === "Installment Date"
                        ? (setInstallmentDateSorting(!installmentDateSorting),
                          installmentDate_sorting())
                        : e.label === "Paid Date"
                        ? (setPaidSorting(!paidSorting), paidDate_sorting())
                        : e.label === "Date Created"
                        ? (setCreatedDateSorting(!createdDateSorting),
                          setSortingColumn("created"))
                        : e.label === "Installment Amount"
                        ? (setInstallmentAmountSorting(
                            !installmentAmountSorting
                          ),
                          setSortingColumn("amount"))
                        : e.label === "Installment Due Date"
                        ? (setInstallmentDueDate(!installmentDueDate),
                          setSortingColumn("due_date"))
                        : e.label === "Remain"
                        ? (setRemainPenalties(!remainPenalties),
                          setSortingColumn("penalty_remaining_amount"))
                        : e.label === "Hold"
                        ? (setHoldPenalties(!holdPenalties),
                          setSortingColumn("on_hold"))
                        : null
                    }
                  >
                    {" "}
                    <FaSort />
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(Section === "Installments" && isInstallmentsPerm) ||
          (Section === "Products" && isProductsPerm) ||
          (Section === "Penalties" && isPenaltiesPerm) ? (
            <>
              {(Section === "Installments" ? list?.installments : list)?.map(
                (e, index) => (
                  <tr key={index}>
                    <td>
                      {Section === "Installments"
                        ? !e.settled &&
                          e.due_date <= today && (
                            <Form.Check
                              type="checkbox"
                              // disabled={!e.opened}
                              // onChange={() => setAmount([...amount,e.installments_amount])}
                              onChange={() => setObject([...object, e])}
                              checked={
                                elementSelected?.includes(e) ? true : false
                              }
                            />
                          )
                        : Section === "Products"
                        ? !e.settled && (
                            <>
                              <Form.Check
                                type="checkbox"
                                // onChange={() => setAmount([...amount,e.installments_amount])}
                                onChange={() => setObject([...object, e])}
                                checked={
                                  elementSelected?.includes(e) ? true : false
                                }
                                // checked={handleCheckedFun(e)}
                              />
                            </>
                          )
                        : Section === "Penalties" &&
                          !e.paid && (
                            <Form.Check
                              type="checkbox"
                              // onChange={() => setAmount([...amount,e.installments_amount])}
                              onChange={() => setObject([...object, e])}
                              checked={
                                elementSelected?.includes(e) ? true : false
                              }
                            />
                          )}
                    </td>
                    <td>
                      {Section === "Installments"
                        ? e.due_date
                        : Section === "Products"
                        ? e.id
                        : e.loan__integration_reference}
                    </td>
                    <td>
                      {Section === "Installments"
                        ? e.installments_amount
                        : Section === "Products"
                        ? e.integration_reference
                        : e.created}
                    </td>
                    <td>
                      {Section === "Installments"
                        ? e.installments_paid_amount
                        : Section === "Products"
                        ? e.early_settlement_date
                        : e.amount}
                    </td>
                    <td>
                      {Section === "Installments" ? (
                        e.installments_due_amount
                      ) : Section === "Products" ? (
                        <Form.Check
                          type="checkbox"
                          checked={e.settled}
                          onChange={() => {}}
                          disabled
                        />
                      ) : (
                        <Form.Check
                          type="checkbox"
                          checked={e.paid}
                          onChange={() => {}}
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {Section === "Installments"
                        ? e.late_days
                        : Section === "Products"
                        ? e.penalties_due_amount
                        : e.penalty_remaining_amount}
                    </td>
                    <td>
                      {Section === "Installments"
                        ? e.dpd
                        : Section === "Products"
                        ? e.remaining_principal
                        : e.installment__due_date}
                    </td>
                    {Section === "Installments" && <td>{e.bkt}</td>}
                    <td>
                      {Section === "Installments"
                        ? e.last_payment_date
                        : Section === "Products"
                        ? e.early_settlement_fees_percentage
                        : e.installment__amount}
                    </td>
                    <td>
                      {Section === "Installments"
                        ? e.penalties_total_amount
                        : Section === "Products"
                        ? e.early_settlement_fees
                        : e.temporary_waived_amount}
                    </td>
                    <td>
                      {Section === "Installments" ? (
                        e.penalties_paid_amount
                      ) : Section === "Products" ? (
                        e.early_settlement_upcoming_installment?.amount
                      ) : (
                        <Form.Check
                          type="checkbox"
                          checked={e.is_totally_waived}
                          onChange={() => {}}
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {Section === "Installments" ? (
                        e.penalties_waived_amount
                      ) : Section === "Products" ? (
                        e.early_settlement_upcoming_installment?.due_date
                      ) : (
                        <Form.Check
                          type="checkbox"
                          checked={e.on_hold}
                          onChange={() => {}}
                          disabled
                        />
                      )}
                    </td>
                    {Section !== "Penalties" && (
                      <>
                        <td>
                          {Section === "Installments"
                            ? e.penalties_on_hold_amount
                            : Section === "Products"
                            ? e.early_settlement_upcoming_installment?.balance
                            : null}
                        </td>
                        <td>
                          {Section === "Installments" ? (
                            e.penalties_due_amount
                          ) : Section === "Products" ? (
                            <Form.Check
                              type="checkbox"
                              checked={e.refund_blocked}
                              onChange={() => {}}
                              disabled
                            />
                          ) : null}
                        </td>
                      </>
                    )}

                    {Section === "Installments" && (
                      <>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={e.penalties_settled}
                            onChange={() => {}}
                            disabled
                          />
                        </td>
                        <td>{e.installments_principal}</td>
                        <td>{e.installments_interest}</td>
                        <td>{e.installments_remaining_principal}</td>
                        <td>{e.installments_remaining_interest}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={e.settled}
                            onChange={() => {}}
                            disabled
                          />
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
            </>
          ) : (
            <td
              align="center"
              colSpan={
                Section === "Installments"
                  ? installments.length
                  : Section === "Products"
                  ? products.length
                  : penalties.length
              }
            >
              <div className="permission_div">
                <h6 className="permission_sm_style">
                  You don't have permission to see this data
                </h6>
              </div>
            </td>
          )}
        </tbody>
      </Table>

      {(Section === "Installments" && isInstallmentsPerm) ||
      (Section === "Products" && isProductsPerm) ||
      (Section === "Penalties" && isPenaltiesPerm) ? (
        <Col className="table_bottom my-4">
          {Section === "Installments" && (
            <div className="d-flex flex-column ">
              <Form.Text>
                Total Installment :{" "}
                {elementSelected?.length !== 0
                  ? totalAmount?.toFixed(2)
                  : list?.installments_dues_to_pay?.toFixed(2)}
              </Form.Text>
              <Form.Text>
                Penalties :{" "}
                {elementSelected?.length !== 0
                  ? totalPenalties?.toFixed(2)
                  : list?.penalties_dues_to_pay?.toFixed(2)}
              </Form.Text>
              <Form.Text>
                Total Dues :{" "}
                {totalDues
                  ? totalDues?.toFixed(2)
                  : (
                      list?.installments_dues_to_pay +
                      list?.penalties_dues_to_pay
                    ).toFixed(2)}
              </Form.Text>
            </div>
          )}
          {Section === "Products" && (
            <PaginationComponent
              brands={
                Section === "Products" ? totalProducts : totalPenaltiesRes
              }
              page={Section === "Products" ? productsPage : penaltiesPage}
              handleChange={handleChange}
              section={"collection"}
            />
          )}
          {Section === "Products" && (
            <div className="d-flex justify-content-between">
              <Button
                style={{
                  backgroundColor: "#418AB6",
                  // paddingInline: 40,
                  width: "50%",
                  color: "#FFFFFF",
                }}
                onClick={() => calculation()}
              >
                Calculate Early
              </Button>
              <Form.Control
                className="w-50"
                defaultValue={
                  elementSelected?.length === 0 ? "" : calculateEarly
                }
                disabled
                name="value"
                // onChange={(e) =>
                //   setData({ ...data, [field.name]: e.target.value })
                // }
                type="text"
              />
            </div>
          )}
          {((Section === "Installments" &&
            permissions?.includes("can_take_one_txn_cash_collect_action")) ||
            (Section === "Products" &&
              permissions?.includes("can_take_one_txn_early_settle_action"))) && (
            <Button
              style={{
                backgroundColor: "#41B66E",
                paddingInline: 40,
                color: "#FFFFFF",
              }}
              onClick={
                elementSelected?.length
                  ? () => setSettleOpen(true)
                  : () => toastMsg()
              }
            >
              Settle
            </Button>
          )}
          {Section === "Penalties" && (
            <>
              {permissions?.includes("can_waive_one_txn_penalty") && (
                <Button
                  style={{
                    backgroundColor: "#418AB6",
                    paddingInline: 40,
                    color: "#FFFFFF",
                  }}
                  onClick={() =>
                    elementSelected?.length
                      ? (setSettleOpen(true), setButtonName("waive"))
                      : toastMsg()
                  }
                >
                  Waive
                </Button>
              )}

              {permissions.includes("can_update_one_txn_penalty_waive_percentage") && (
                <Button
                  style={{
                    backgroundColor: "#41B66E",
                    paddingInline: 40,
                    color: "#FFFFFF",
                    // width:"30%"
                  }}
                  onClick={() =>
                    elementSelected?.length
                      ? (setSettleOpen(true), setButtonName("withdrow"))
                      : toastMsg()
                  }
                >
                  Temporary Waive
                </Button>
              )}

              {/* hold, unhold */}
              {permissions.includes("can_hold_and_un_hold_one_txn_penalty") && (
                <>
                  <Button
                    style={{
                      backgroundColor: "#418AB6",
                      paddingInline: 20,
                      color: "#FFFFFF",
                    }}
                    onClick={() => handleHoldPenalties()}
                  >
                    Hold
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "#41B66E",
                      paddingInline: 20,
                      color: "#FFFFFF",
                    }}
                    onClick={() => unhandleHoldPenalties()}
                  >
                    UnHold
                  </Button>
                </>
              )}
            </>
          )}
        </Col>
      ) : (
        <></>
      )}

      <Modal
        isOpen={settleOpen}
        onRequestClose={() => setSettleOpen(false)}
        style={customStyles}
        contentLabel="user history"
        shouldCloseOnOverlayClick={true}
      >
        <ModalTables
          Section={Section}
          elementSelected={elementSelected}
          advancedPayment={advancedBalance}
          totalDues={totalDues}
          user_id={user_id}
          buttonName={buttonName}
          totalEarly={totalEarly}
        />
      </Modal>
      {/* </Row> */}
    </div>
  );
};
export default CollectionTables;
