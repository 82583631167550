/* eslint-disable no-sequences */
import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Stack, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CgClose } from "react-icons/cg";
import { BsSearch } from "react-icons/bs";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import LoginHistory from "../loginHistory/LoginHistory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: 400, lg: 500 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Users = () => {
  const { handleLoader, permissions } = useContext(UserContext);
  const [total, setTotal] = useState();
  const [list, setList] = useState();
  const [national_id, setNational_id] = useState(null);
  const [pagesNumber, setPagesNumber] = useState(1);
  const [profileOptions, setProfileOptions] = useState();
  const [state, setState] = useState();
  const [hold, setHold] = useState();
  const [profileState, setProfileState] = useState([]);
  const [iscoreState, setIscoreState] = useState([]);
  const [iscore, setIscore] = useState();
  const [openDateModified, setOpenDateModified] = useState(false);
  const [openDateCreated, setOpenDateCreated] = useState(false);
  const [checkIds, setCheckIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [actionsList, setActionsList] = useState([]);
  const [actionValue, setActionValue] = useState();
  const [holdReasons, setHoldReasons] = React.useState([]);
  const [reasonId, setReasonId] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [deviceLogin, setDeviceLogin] = useState(false);
  const compare = (a, b) => {
    return a - b;
  };

  const DeviceReport = ["is_user_dashboard_user", "is_staff"].some((i) =>
    permissions.includes(i)
  );
  // test
  // const sortUni = newUni?.sort(compare);
  // const ids = sortUni?.join();
  // to make default date instead of the date of this day
  const defaultDate = new Date("2022-01-01");
  const defaultDateForm = format(defaultDate, "MM/dd/yyyy");
  const [dateModified, setDateModified] = useState([
    {
      startDate: defaultDate,
      endDate: defaultDate,
      key: "selection",
    },
  ]);
  const [dateCreated, setDateCreated] = useState([
    {
      startDate: defaultDate,
      endDate: defaultDate,
      key: "selection",
    },
  ]);

  const modifiedStartDate = format(dateModified[0].startDate, "MM/dd/yyyy");
  const modifiedEndDate = format(dateModified[0]?.endDate, "MM/dd/yyyy");
  const createdStartDate = format(dateCreated[0]?.startDate, "MM/dd/yyyy");
  const createdEndDate = format(dateCreated[0]?.endDate, "MM/dd/yyyy");

  const filterBlackAndHold = list?.filter((user) => {
    return user.active && user.is_blacklisted && user.user__on_hold;
  });

  console.log("filterBlackAndHold",filterBlackAndHold)
  toast.configure();
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  const users_options = () => {
    UserUtility.users_options()
      .then((res) => {
        setIscoreState(res.actions.PUT.iscore_state.choices);
        setProfileOptions(res.actions.PUT);
        setProfileState(res.actions.PUT.p_state.choices);
      })
      .catch((err) => console.error("user_options_err", err));
  };
  const actions_list = () => {
    UserUtility.actions_list()
      .then((res) => {
        setActionsList(res);
      })
      .catch((err) => console.error("actions_list", err));
  };

  useEffect(() => {
    actions_list();
    hold_reason_list();
  }, []);
  useEffect(() => {
    if (
      permissions.includes("can_view_user_list") &&
      !permissions.includes("user_management")
    ) {
      userList();
    }
    if (permissions.includes("can_view_user_details")) {
      users_options();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber, permissions]);
  const userList = () => {
    setList([]);
    setCheckIds([])
    handleLoader(true);
    UserUtility.user_list(
      pagesNumber,
      national_id,
      state,
      hold,
      iscore,
      modifiedStartDate === defaultDateForm ? "" : modifiedStartDate,
      modifiedEndDate,
      createdStartDate === defaultDateForm ? "" : createdStartDate,
      createdEndDate
    )
      .then((res) => {
        if (permissions.includes("user_management") && res.count === 1) {
          setTotal(res);
          setList(res.results);
          handleLoader(false);
        }
        if (permissions.includes("user_management") && res.count > 1) {
          handleLoader(false);
        } else if (!permissions.includes("user_management")) {
          setTotal(res);
          setList(res.results);
          handleLoader(false);
        }
        handleLoader(false);
      })
      .catch((err) => {
        console.error("user_list_err", err);
        handleLoader(false);
      });
  };

  const handleClick = (e) => {
    if (e === "Enter") {
      setOpenDateCreated(false);
      setOpenDateModified(false);
      userList();
      setPagesNumber(1);
    }
  };
  const closeDate = () => {
    setOpenDateCreated(false);
    setOpenDateModified(false);
  };

  const handleActiveUser = (index, id) => {
    //to add or remove checkbox as UI
    const newProfile =
      list &&
      list.map((user, id) => {
        return {
          ...user,
          active: index === id ? !user.active : user.active,
        };
      });
    setList(newProfile);
    //to add and remove makersId to send to BE
    const filterId = checkIds.filter((item) => item === id);
    if (filterId[0]) {
      const removeId = checkIds.filter((item) => item !== id);

      setCheckIds(removeId);
    } else {
      setCheckIds((oldUser) => [...oldUser, id]);
    }
  };

  /*
  const checkIDs = (id) => {
    if (checkIds.length) {
      const array2 = [];
      checkIds.map((childArray) => {
        const filterCheck = childArray.filter((e) => e !== id);
        filterCheck.length && array2.push(filterCheck);
      });
      const arryEquals = JSON.stringify(checkId) === JSON.stringify(array2);
      if (arryEquals) {
        setCheckId([...checkId, [id]]);
      } else {
        setCheckId(array2);
      }
    } else {
      setCheckId([...checkId, [id]]);
    }
  };
*/
  const nationalId = (e) => {
    setNational_id(e.target.value);
  };
  const send_to_manual = (userId) => {
    handleLoader(true);
    UserUtility.send_to_manual(userId)
      .then(() => {
        handleLoader(false);
        userList();
        // alert("User has been sent to manual study");
      })
      .catch((err) => {
        console.error("send to manual", err);
        handleLoader(false);
      });
  };
  const export_users_profile = () => {
    UserUtility.export_users_profile()
      .then((res) => {
        toast.success(res.detail, {
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => console.error("export_users_profile", err));
  };
  const handleAction = (actionValuee) => {
    if (actionValuee === "un_hold" && filterBlackAndHold?.length >= 1) {
      toast.error("This user is blacklisted. You should remove first from blacklist and try again to un-hold.");
    } else {
      const obj = { reason: Number(reasonId), comment: comment };
      console.log("actionValuee: ", actionValuee);
      const ids = checkIds.toString();
      console.log("ids ids: ", ids);

      if (actionValuee) {
        ["hold", "un_hold", "close_account"].includes(actionValuee)
          ? UserUtility.actions(actionValuee, ids, obj)
              .then(() => {
                window.location.reload();
              })
              .catch((err) => console.error("actions", err))
          : UserUtility.action_report(actionValuee, ids)
              .then((res) => {
                toast.success(res.Success, {
                  autoClose: false,
                  position: toast.POSITION.TOP_CENTER,
                });
              })
              .catch((err) => console.error("action_report", err));
      }
    }
  };
  const pageIds = list?.map((ele) => {
    return ele.id;
  });

  /*
  const selectAll = (event) => {
    if (event) {
      setCheckId([...checkId, pageIds]);
    } else {
      const final = checkId[0]?.concat(...checkId);
      const unChecked = final?.filter((e) => {
        return pageIds.includes(e) ? 0 : e;
      });
      setCheckId([unChecked]);
    }
  };
*/

  const handleSelectAll = (event) => {
    setIsCheckedAll(event.target.checked);
    //to add or remove checkbox as UI
    const newBrands =
      list &&
      list.map((brand, id) => {
        return {
          ...brand,
          active: event.target.checked,
        };
      });

    setList(newBrands);
    if (event.target.checked) {
      const AllIds = newBrands && newBrands.map((item, index) => item.id);
      setCheckIds(AllIds);
    } else {
      setCheckIds([]);
    }
  };

  const listMap = list?.map((ele, index) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell>
          <Form.Check
            type="checkbox"
            onChange={() => handleActiveUser(index, ele.id)}
            checked={ele.active}
          />
        </StyledTableCell>
        <StyledTableCell>{ele.id}</StyledTableCell>
        <StyledTableCell>
          {[
            "can_view_user_details",
            "can_view_user_block",
            "can_view_user_history",
          ].some((i) => permissions.includes(i)) ? (
            <Link
              to={
                (permissions.includes("can_view_user_details") &&
                  "/userDetails/profile") ||
                (permissions.includes("can_view_user_history") &&
                  "/userDetails/History") ||
                (permissions.includes("can_view_user_block") &&
                  "/userDetails/BlockHistory")
              }
              state={{
                // User Id
                id:ele.user_id,
                // user Profile Id
                userId: ele.id,
                profileOptions: profileOptions,
                blocked: ele.is_blocked,
                type: null,
              }}
              className="brand_name"
            >
              {ele.national_id}
            </Link>
          ) : (
            ele.national_id
          )}
        </StyledTableCell>
        <StyledTableCell>{ele.full_name}</StyledTableCell>
        <StyledTableCell>{ele.user__phone}</StyledTableCell>
        <StyledTableCell>{ele.p_state}</StyledTableCell>
        <StyledTableCell
          className="iscore"
          style={{
            color: ele.iscore_state === "PASSED" ? "#18B992" : "#B64141",
            backgroundColor:
              ele.iscore_state === "PASSED" ? "#D1FAEF" : "#B641411A",
          }}
        >
          {ele.iscore_state}
        </StyledTableCell>
        <StyledTableCell>{ele.kiosk__title}</StyledTableCell>
        <StyledTableCell>{ele.maker__email}</StyledTableCell>
        <StyledTableCell>{ele.checker__email}</StyledTableCell>
        <StyledTableCell>
          <Form.Check
            type="checkbox"
            defaultChecked={ele.sent_to_drive}
            disabled
          />
        </StyledTableCell>
        <StyledTableCell>{`${ele.date_of_activation}`}</StyledTableCell>
        <StyledTableCell>
          <Form.Check
            type="checkbox"
            defaultChecked={ele.user__on_hold}
            disabled
          />
        </StyledTableCell>
        <StyledTableCell>
          <Form.Check
            type="checkbox"
            defaultChecked={ele.is_blacklisted}
            disabled
          />
        </StyledTableCell>
        <StyledTableCell>
          {permissions.includes("is_customer_support") && ele.manual_status && (
          // {permissions?.includes("is_customer_support") && (
            <Button
              style={{
                backgroundColor: "#418AB6",
                color: "white",
                fontWeight: "bold",
              }}
              // variant="success"
              onClick={() => send_to_manual(ele.id)}
            >
              Send To Manually
            </Button>
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  });

  // popup of hold

  const user_hold = () => {
    const body = { reason: Number(reasonId), comment };
    UserUtility.user_hold(body)
      .then((res) => {})
      .catch((error) => console.error(error));
    setReasonId(0);
  };
  const hold_reason_list = () => {
    UserUtility.hold_reason_list()
      .then((res) => {
        console.log(res.results);
        setHoldReasons(res.results);
      })
      .catch((err) => console.error("hold_reason_list", err));
  };

  const handleCloseHoldModal = () => {
    setShowModal(false);
    setActionValue("");
  };
  return (
    <>
      <div className="UserProfile">
        <Stack direction="horizontal" className="mt-5" gap={2}>
          <div className="input_div" style={{ width: "60%", marginRight: 20 }}>
            <Form.Control
              type="number"
              onKeyPress={(e) => handleClick(e.key)}
              placeholder="Search by National ID  / Phone Number"
              aria-describedby="passwordHelpBlock"
              className="search_form"
              onChange={nationalId}
            />
            <BsSearch className="search_icon" />
          </div>
          <Button
            variant="success"
            className="px-5"
            onClick={() => handleClick("Enter")}
          >
            Search
          </Button>
        </Stack>
        {permissions.includes("can_view_user_list") &&
          !permissions.includes("user_management") && (
            <Row className="mt-3">
              <Col lg={8} md={12} sm={12} className="d-flex">
                <Form.Label
                  style={{ alignSelf: "center", margin: 0, width: "10%" }}
                >
                  Filter by:
                </Form.Label>
                <Form.Text
                  size="sm"
                  className=" mt-0"
                  style={{
                    width: "25%",
                    borderRadius: "20px",
                    textAlign: "center",
                    borderWidth: "thin",
                    borderStyle: "solid",
                    paddingTop: 5,
                    borderColor: "#41B6A8",
                    cursor: "pointer",
                    color: "black",
                  }}
                  // style={{ width: "25%", marginTop: 0, color: "black" }}
                  onClick={() => (
                    setOpenDateCreated(false),
                    setOpenDateModified(!openDateModified)
                  )}
                  // className="ms-3 border rounded p-2 "
                >
                  {defaultDateForm === modifiedStartDate
                    ? "Select by date modified"
                    : `From ${modifiedStartDate} To ${modifiedEndDate}`}
                </Form.Text>
                {openDateModified && (
                  <DateRange
                    className="position-absolute top-25 mt-5 "
                    editableDateInputs={true}
                    onChange={(item) => setDateModified([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateModified}
                    showDateDisplay={false}
                  />
                )}
                <Form.Text
                  size="sm"
                  className="ms-3 mt-0"
                  style={{
                    width: "25%",
                    borderRadius: "20px",
                    textAlign: "center",
                    borderWidth: "thin",
                    borderStyle: "solid",
                    paddingTop: 5,
                    borderColor: "#41B6A8",
                    cursor: "pointer",
                    color: "black",
                  }}
                  // style={{ width: "25%", marginTop: 0, color: "black" }}
                  onClick={() => (
                    setOpenDateCreated(!openDateCreated),
                    setOpenDateModified(false)
                  )}
                  // className="ms-3 border rounded p-2 "
                >
                  {defaultDateForm === createdStartDate
                    ? "Select by date created"
                    : `From ${createdStartDate} To ${createdEndDate}`}
                </Form.Text>
                {openDateCreated && (
                  <DateRange
                    className="position-absolute top-25 end-50 mt-5"
                    editableDateInputs={true}
                    onChange={(item) => setDateCreated([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateCreated}
                    showDateDisplay={false}
                  />
                )}
                <Form.Select
                  className=" ms-3 "
                  style={{
                    width: "15%",
                    borderRadius: "20px",
                    borderColor: "#41B6A8",
                    textAlign: "center",
                  }}
                  size="sm"
                  onClick={() => closeDate()}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                >
                  <option value={""}>p state</option>
                  {profileState.map((res, id) => (
                    <option value={res.value} name={res.display_name} key={id}>
                      {res.display_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  className=" ms-3 "
                  style={{
                    width: "18%",
                    borderRadius: "20px",
                    borderColor: "#41B6A8",
                    textAlign: "center",
                  }}
                  size="sm"
                  onClick={() => closeDate()}
                  onChange={(e) => {
                    setIscore(e.target.value);
                  }}
                >
                  <option value={""}>Iscore state</option>
                  {iscoreState.map((res, id) => (
                    <option value={res.value} name={res.display_name} key={id}>
                      {res.display_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  className=" ms-3 "
                  style={{
                    width: "18%",
                    borderRadius: "20px",
                    borderColor: "#41B6A8",
                    textAlign: "center",
                  }}
                  size="sm"
                  onClick={() => closeDate()}
                  onChange={(e) => {
                    setHold(e.target.value);
                  }}
                >
                  <option value={""}>hold state</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Form.Select>
              </Col>
            </Row>
          )}
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 2000 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {!list ||
                    list?.length < 2 ||
                    (!permissions.includes("user_management") && (
                      <Form.Check
                        type="checkbox"
                        checked={
                          list && list?.length > 0 ? isCheckedAll : false
                        }
                        // disabled={brands?.length === 0}
                        onChange={(event) => handleSelectAll(event)}
                      />
                    ))}
                </StyledTableCell>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>National ID</StyledTableCell>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell>State</StyledTableCell>
                <StyledTableCell>I.score State</StyledTableCell>
                <StyledTableCell>Kiosk</StyledTableCell>
                <StyledTableCell>Maker</StyledTableCell>
                <StyledTableCell>Checker</StyledTableCell>
                <StyledTableCell>Sent to drive</StyledTableCell>
                <StyledTableCell>Activition Date</StyledTableCell>
                <StyledTableCell>On_Hold</StyledTableCell>
                <StyledTableCell>Blacklisted</StyledTableCell>
                <StyledTableCell>Send To Manual</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.includes("can_view_user_list") ||
              permissions.includes("user_management") ||
              permissions.includes("is_customer_support")
                ? listMap
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {permissions.includes("action_generate_risk_report_xlc") && (
          <div className="d-flex justify-content-end">
            <h4
              className=" text-success fw-bolder  text-decoration-underline"
              style={{ cursor: "pointer", width: "10%" }}
              onClick={export_users_profile}
            >
              Export as excel
            </h4>
          </div>
        )}
        {actionsList?.length ? (
          <div
            style={{
              display: "flex",
              marginBottom: 20,
              marginTop: 20,
              width: "30%",
            }}
          >
            <Form.Select
              style={{ marginRight: 10 }}
              size="md"
              value={actionValue}
              onChange={(e) => {
                setActionValue(e.target.value);
                e.target.value === "hold"
                  ? setShowModal(true)
                  : setShowModal(false);
              }}
            >
              <option value={""}>-------</option>
              {actionsList.map((res, id) => (
                <option
                  value={res.display_name}
                  name={res.display_name}
                  key={id}
                >
                  {res.display_name}
                </option>
              ))}
            </Form.Select>
            {/* <PopUp show={showModal} ids={checkId} handleClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                setShowModal(false)
              }
            }} /> */}
            <Button
              variant="success"
              className="px-5"
              onClick={() => handleAction(actionValue)}
            >
              Submit
            </Button>
          </div>
        ) : null}
        {DeviceReport && (
          <Button
            variant="success"
            className="px-5"
            onClick={() => setDeviceLogin(true)}
          >
            Get device Login
          </Button>
        )}
        {!permissions.includes("user_management") && (
          <PaginationComponent
            brands={total}
            page={pagesNumber}
            handleChange={handleChange}
          />
        )}
      </div>
      <LoginHistory deviceLogin={deviceLogin} setDeviceLogin={setDeviceLogin} />
      <Modal
        open={showModal}
        onClose={() => handleCloseHoldModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="d-flex justify-content-end">
            <IconButton onClick={() => handleCloseHoldModal()}>
              <CgClose />
            </IconButton>
          </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Reason
          </Typography>
          <Form.Select
            style={{ marginRight: 10 }}
            size="md"
            className="my-2"
            onChange={(e) => {
              setReasonId(e.target.value);
            }}
          >
            <option value={""}>-------</option>
            {holdReasons.map((res, id) => (
              <option value={res.id} name={res.reason} key={id}>
                {res.reason}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            className="shadow rounded mt-4"
            as="textarea"
            rows={5}
            placeholder="Add Comment"
            type="text"
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Box className="d-flex justify-content-end">
            <Button
              variant="success"
              className="  mt-3"
              disabled={reasonId > 0 ? false : true}
              onClick={() => {
                handleCloseHoldModal();
                // user_hold();
                handleAction(actionValue);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Users;
