import "./kioskManagement.scss";
import { useEffect, useState } from "react";
import { UserUtility } from "../../apis/UserUtility";
import MultipleSelection from "../../components/dropdown/MultipleSelection";
import { toast } from "react-toastify";
import { Button, InputLabel, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { Form } from "react-bootstrap";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "85%",
    overflowY: "scroll",
    height: "85%",
  },
};

Modal.setAppElement("#root");
const NewKioskManagement = () => {
  const [unassignedMakers, setUnassignedMakers] = useState([]);
  const [unassignedCheckers, setUnassignedCheckers] = useState([]);
  const [userDetail, setUserDetail] = useState()

  const [kiosks, setKiosks] = useState([]);
  const [kioskNext, setKioskNext] = useState(null);
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState([]);

  // filter states
  const [userSearch, setUserSearch] = useState();
  const [selectUserCity, setSelectUserCity] = useState();
  const [selectedUserBranch, setSelectedUserBranch] = useState()
  const [selectedRole, setSelectedRole] = useState();
  const [selectedAssigned, setSelectedAssigned] = useState();
  const [cities, setCities] = useState([]);
  const [userBranches, setUserBranches] = useState([]);
  const [kioskBranches, setKioskBranches] = useState([]);

  const [kioskSearch, setKioskSearch] = useState();
  const [selectKioskCity, setSelectKioskCity] = useState();
  const [selectedKioskBranch, setSelectedKioskBranch] = useState()
  const [openBranchesModal, setOpenBranchesModal] = useState({ isOpen: false, branches: [] })
  const [kioskDetail, setKioskDetail] = useState()
  const [kioskPage, setKioskPage] = useState(1)

  useEffect(() => {
    getAllUnassignedUsers()
    getKioskCities()
    getKioskBranches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsersEP();
  }, [page]);


  useEffect(() => {
    getKiosks();
  }, [kioskPage]);


  // useEffect(() => {
  //   setUnassignedUsers([...unassignedMakers, ...unassignedCheckers]);
  // }, [unassignedMakers, unassignedCheckers]);


  const getAllUnassignedUsers = () => {
    getUnassignedUsersEP("MAKER", false, setUnassignedMakers);
    getUnassignedUsersEP("CHECKER", '', setUnassignedCheckers);
  };


  const getUnassignedUsersEP = (role, is_assigned, setState) => {
    UserUtility.getKioskUsers(role, is_assigned)
      .then((res) => {
        setState(res.results);
        if (res.next) {
          getPaginateData(res.next, setState);
        }
      })
      .catch((err) => console.log(err));
  };


  const getUsersEP = () => {
    UserUtility.getKioskUsers(selectedRole, selectedAssigned, page, userSearch, selectUserCity, selectedUserBranch)
      .then((res) => {
        setUserDetail(res)
        setUsers(res.results);
      })
      .catch((err) => console.log(err));
  };

  const getPaginateData = (url, setState) => {
    console.log('url: ', url)
    UserUtility.getNextPageData(url)
      .then((res) => {
        setState((old) => [...old, ...res.results]);
        if (res.next) {
          getPaginateData(res.next, setState);
        }
      })
      .catch((err) => console.log(err));
  };

  const getKiosks = () => {
    UserUtility.getKiosks(kioskSearch, selectKioskCity, selectedKioskBranch, kioskPage)
      .then((res) => {
        let arrStort = res.results.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        );
        setKiosks(arrStort);
        setKioskDetail(res)
        // if (res.next && (kioskNext !== res.next)) {
        //   setKioskNext(res.next);
        // } else {
        //   setKioskNext(null);
        // }
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateKiosk = (kiosk) => {
    let maker_arr = kiosk.assigned_makers.map((item) => item.kiosk_user_id);
    let checker_arr = kiosk.assigned_checkers.map((item) => item.kiosk_user_id);

    UserUtility.updateKiosk(kiosk.id, maker_arr, checker_arr)
      .then((res) => {
        toast.success("Saved");
        getAllUnassignedUsers();
      })
      .catch((err) => {
        toast.error("Oops,Some thing get error,please try again");
      });
  };

  const searchUserHandle = () => {
    if (page === 1) {
      getUsersEP();
    } else {
      setPage(1)
    }
  }

  const searchKioskHandle = () => {
    if (kioskPage === 1) {
      getKiosks()
    } else {
      setKioskPage(1)
    }
  }

  const getKioskCities = () => {
    UserUtility.getKioskCities().then(res => {
      setCities(res)
    }).catch(err => console.log('err of getKioskCities: ', err))
  }

  const getKioskBranches = (selectedCity, from) => {
    UserUtility.getKioskBranches(selectedCity).then(res => {
      if (from === 'user') {
        setUserBranches(res.results)
      } else if (from === 'kiosk') {
        setKioskBranches(res.results)
      } else {
        setUserBranches(res.results)
        setKioskBranches(res.results)
      }

      if (res.next) {
        paginateBranches(res.next, from)
      }
    }).catch(err => console.log('err of getKioskBranches: ', err))
  }

  const paginateBranches = (url, from) => {
    UserUtility.getNextPageData(url)
      .then((res) => {
        if (from === 'user') {
          setUserBranches((old) => [...old, ...res.results])
        } else if (from === 'kiosk') {
          setKioskBranches((old) => [...old, ...res.results])
        } else {
          setUserBranches((old) => [...old, ...res.results])
          setKioskBranches((old) => [...old, ...res.results])
        }

        if (res.next) {
          paginateBranches(res.next, from);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="kioskManagement_container background--white">
      <div className="top">


        {/* <div className="top__body">
          {unassignedUsers &&
            unassignedUsers.length > 0 &&
            unassignedUsers.map((user, index) => (
              <div key={index} className="top__item">
                {index + 1}- {user.email}
              </div>
            ))}
        </div> */}
      </div>

      {/* Assigned users */}
      <div className="top" style={{ marginTop: 30 }}>
        <div className="page__title">Users</div>
        {/* filter */}
        <article>
          <Form className="flex_filter" >
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control type="text" placeholder="Search" value={userSearch} onChange={(e) => setUserSearch(e.target.value)} />
            </Form.Group>

            <Form.Select aria-label="Default select example" value={selectUserCity} onChange={(e) => {
              setSelectUserCity(e.target.value)
              getKioskBranches(e.target.value, 'user')
            }}>
              <option value="">Select city</option>
              {cities.map((item, index) => (
                <option value={item.value} key={index.toString()}>{item.label}</option>
              ))}

            </Form.Select>

            <Form.Select aria-label="Default select example" value={selectedUserBranch} onChange={(e) => setSelectedUserBranch(e.target.value)}>
              <option value="">Select branch</option>
              {userBranches.map((item, index) => (
                <option value={item.id} key={index.toString()}>{item.title}</option>
              ))}
            </Form.Select>

            <Form.Select aria-label="Default select example" value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
              <option value="">Select user type</option>
              <option value="MAKER">Maker</option>
              <option value="CHECKER">Checker</option>
            </Form.Select>

            <Form.Select aria-label="Default select example" value={selectedAssigned} onChange={(e) => setSelectedAssigned(e.target.value)}>
              <option value="">Select user assigned</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Select>

            <Button
              className="searchButton"
              type="button"
              variant="contained"
              onClick={() => searchUserHandle()}
            >
              {/* {isLoading ? "Loading..." : "Search"} */}
              Search
            </Button>
          </Form>
        </article>

        {/* table container */}
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>City</StyledTableCell>
                <StyledTableCell>Branch</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.role}</StyledTableCell>
                  <StyledTableCell>{row?.kiosk && row?.kiosk.length > 0 ? row?.kiosk[0]?.city : ''}</StyledTableCell>
                  <StyledTableCell style={{ width: '50%' }} colSpan={5}>

                    <div className="flex_cell_div">
                      <p>
                        {row?.kiosk && row?.kiosk.length > 0 ? row?.kiosk[0]?.title : ''}
                      </p>
                      {row?.kiosk && row?.kiosk.length > 1 &&
                        <p style={{ cursor: 'pointer' }} onClick={() => setOpenBranchesModal({ isOpen: true, branches: row?.kiosk })}>
                          <BsFillArrowUpRightCircleFill style={{ color: '#41b6a8', fontSize: 16, marginLeft: 20 }} />
                        </p>
                      }
                    </div>
                  </StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


        <PaginationComponent
          brands={userDetail}
          page={page}
          handleChange={(e, value) => setPage(value)}
        />

      </div>



      <div className="bottom">
        <div className="page__title">Branches</div>
        {/* filter */}
        <article style={{ marginBottom: 30 }}>
          <Form className="flex_filter" >
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control type="text" placeholder="Search branch" value={kioskSearch} onChange={(e) => setKioskSearch(e.target.value)} />
            </Form.Group>

            <Form.Select aria-label="Default select example" value={selectKioskCity} onChange={(e) => {
              setSelectKioskCity(e.target.value)
              getKioskBranches(e.target.value, 'kiosk')
              }}>
              <option value="">Select city</option>
              {cities.map((item, index) => (
                <option value={item.value} key={index.toString()}>{item.label}</option>

              ))}

            </Form.Select>

            <Form.Select aria-label="Default select example" value={selectedKioskBranch} onChange={(e) => setSelectedKioskBranch(e.target.value)}>
              <option value="">Select branch</option>
              {kioskBranches.map((item, index) => (
                <option value={item.id} key={index.toString()}>{item.title}</option>
              ))}
            </Form.Select>
            <Button
              className="searchButton"
              type="button"
              variant="contained"
              onClick={() => searchKioskHandle()}
            >
              {/* {isLoading ? "Loading..." : "Search"} */}
              Search
            </Button>
          </Form>
        </article>
        {kiosks && kiosks.length > 0 ?
          <>
            {kiosks.map((kiosk, index) => (
              <>
                <div key={kiosk.id} className="card">
                  <div className="card__title">
                    <div className="card__title__text">{kiosk.title}</div>
                  </div>
                  <div className="card__body">
                    <div className="card__dropdown">
                      <InputLabel className="label">Select Makers</InputLabel>
                      <MultipleSelection
                        optionsItem={unassignedMakers}
                        type="maker"
                        label="Maker"
                        finalSelectedList={(values) => {
                          let uniqueArr = values.map((item) => {
                            return { kiosk_user_id: item.id, email: item.email };
                          });
                          let default_kiosk = kiosk;
                          default_kiosk.assigned_makers = uniqueArr;

                          let index = kiosks.indexOf(
                            (item) => item.id === default_kiosk.id
                          );
                          if (index > -1) {
                            let arr_kiosks = kiosks;
                            arr_kiosks[index] = default_kiosk;

                            setKiosks(...arr_kiosks);
                          }
                        }}
                        selectedValues={kiosk.assigned_makers}
                      />
                    </div>
                    <div>
                      <InputLabel className="label">Select Checkers</InputLabel>

                      <MultipleSelection
                        optionsItem={unassignedCheckers}
                        type="checker"
                        label="Checker"
                        finalSelectedList={(values) => {
                          let uniqueArr = values.map((item) => {
                            return { kiosk_user_id: item.id, email: item.email };
                          });
                          let default_kiosk = kiosk;
                          default_kiosk.assigned_checkers = uniqueArr;

                          let index = kiosks.indexOf(
                            (item) => item.id === default_kiosk.id
                          );
                          if (index > -1) {
                            let arr_kiosks = kiosks;
                            arr_kiosks[index] = default_kiosk;

                            setKiosks(...arr_kiosks);
                          }
                        }}
                        selectedValues={kiosk.assigned_checkers}
                      />
                    </div>

                    <div className="submit_btn">
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={() => handleUpdateKiosk(kiosk)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
          :
          <div>
            <p style={{ textAlign: 'center', marginTop: 50, fontWeight: 'bold' }}>No Kiosk Result</p>
          </div>
        }
        {kiosks.length > 0 &&
          <PaginationComponent
            brands={kioskDetail}
            page={kioskPage}
            handleChange={(e, value) => setKioskPage(value)}
          />
        }
      </div>

      <Modal
        isOpen={openBranchesModal.isOpen}
        onRequestClose={() => setOpenBranchesModal({ isOpen: false, branches: [] })}
        style={customStyles}
        contentLabel="More Branches"
        shouldCloseOnOverlayClick={true}
      >
        <div>
          {openBranchesModal.branches.map((item, index) => (
            <p key={index.toString()}>
              <span>{index + 1}- </span>
              {item.title}</p>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default NewKioskManagement;
