import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import style from '../OnBoard.module.css'
import UserContext from '../../../hooks/userContext';
import { UserUtility } from '../../../apis/UserUtility';
import { toast } from 'react-toastify';
const PopupRejecte = (props) => {

    const { handleLoader, permissions } = useContext(UserContext);
    let isAdmin = permissions.includes("on_boarding_tool_admin");

    const [resones, setResones] = useState([])
    const handleCheckboxChange = (event, responseId) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            props.setSelectedResoneForReject([...props.selectedResoneForReject, responseId]);
        } else {
            props.setSelectedResoneForReject(props.selectedResoneForReject.filter(id => id !== responseId));
        }
    };

    useEffect(() => {
        UserUtility.rejectResone()
            .then((res) => {
                handleLoader(false);
                setResones(res);
            })
            .catch((err) => console.error("Rejecte_List_Not_Avall", err));
    }, [handleLoader])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Reject Reason
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className={style.select_title}>
                    <p className='mb-0 mt-2'>Select One or More Reasons</p>
                </div>
                <div className='mt-2'>
                    {
                        resones.results?.map((resone, i) => (
                            <span key={i}>
                                <label className="ms-5 my-1">
                                    <input
                                        className='mx-3'
                                        type="checkbox"
                                        value={resone.id}
                                        checked={props.selectedResoneForReject.includes(resone.id)}
                                        onChange={(event) => handleCheckboxChange(event, resone.id)}
                                    />
                                    {resone.reject_reason}
                                </label>
                                <br /><hr />
                            </span>
                        ))
                    }

                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                <Button
                    className={`${style.reject_butt} my-5`}
                    onClick={() => {
                        props.selectedResoneForReject?.length !== 0 ? isAdmin ? props.handle_Approve_Rejecte_Restudy(props.item, "REJECTED", "") : props.handle_Approve_Rejecte_Restudy(props.item, 'REJECTED', "REJECTED") && props.onHide() : toast.error("Select Reason For Reject");
                    }}
                >Send</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PopupRejecte
