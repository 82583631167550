import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import { Form } from "react-bootstrap";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const CashbackHistory = ({ cashbackHistoryState }) => {
  const [cashbackHistory, setCashbackHistory] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalUsedAmount, setTotalUsedAmount] = useState();
  const [totalAvailableAmount, setTotalAvailableAmount] = useState();
  const [totalCurrentAmount, setTotalCurrentAmount] = useState();
  const location = useLocation();
  const { userId } = location.state;
  const unExpiredCashback = cashbackHistory?.filter((ele) => {
    if(!ele.activation){
      return !ele.is_expired && !ele.is_deleted &&ele.loan_approved;
    }else{
      return !ele.is_expired && !ele.is_deleted ;
    }
  });
  
  const totalCashback = unExpiredCashback?.map((e) => {
    return e?.cashback_balance;
  });
  const totalUsedCashback = unExpiredCashback?.map((e) => {
    return e?.cashback_used;
  });
  const totalAvailableCashback = unExpiredCashback?.map((e) => {
    return e?.cashback_available;
  });
  const totalCurrentCashback = unExpiredCashback?.map((e) => {
    return e?.current_balance;
  });
  const selectamount = () => {
    let sum = 0;
    let sumUsed = 0;
    let sumAvailable = 0;
    let sumCurrent = 0;

    for (let i = 0; i < totalCashback?.length; i += 1) {
      sum += totalCashback[i];
    }
    for (let i = 0; i < totalUsedCashback?.length; i += 1) {
      sumUsed += totalUsedCashback[i];
    }
    for (let i = 0; i < totalAvailableCashback?.length; i += 1) {
      sumAvailable += totalAvailableCashback[i];
    }
    for (let i = 0; i < totalCurrentCashback?.length; i += 1) {
      sumCurrent += totalCurrentCashback[i];
    }
    setTotalAmount(sum);
    setTotalUsedAmount(sumUsed);
    setTotalAvailableAmount(sumAvailable);
    setTotalCurrentAmount(sumCurrent);
  };
  useEffect(() => {
    selectamount();
  }, [totalCashback]);
  const cashback_history = () => {
    if (cashbackHistoryState) {
      UserUtility.cashback_history(userId)
        .then((res) => {
          setCashbackHistory(res);
        })
        .catch((err) => console.error("cashback_history err", err));
    }
  };
  useEffect(() => {
    cashback_history();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashbackHistoryState]);
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Total Cashback</StyledTableCell>
              <StyledTableCell>Used Cashback</StyledTableCell>
              <StyledTableCell>Current Cashback Balance</StyledTableCell>
              <StyledTableCell>Available Cashback To Use</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableCell>{totalAmount}</StyledTableCell>
            <StyledTableCell>{totalUsedAmount}</StyledTableCell>
            <StyledTableCell>{totalCurrentAmount}</StyledTableCell>
            <StyledTableCell>{totalAvailableAmount}</StyledTableCell>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Cashback amount</StyledTableCell>
              <StyledTableCell>Received Date</StyledTableCell>
              <StyledTableCell>Loan Application Number</StyledTableCell>
              <StyledTableCell>Activation</StyledTableCell>
              <StyledTableCell>Approved</StyledTableCell>
              <StyledTableCell>Expiry Date</StyledTableCell>
              <StyledTableCell>Expired</StyledTableCell>
              <StyledTableCell>Deleted</StyledTableCell>
              <StyledTableCell>Vaild To Use</StyledTableCell>
              <StyledTableCell>Cashback Used</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cashbackHistory?.map((field, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{field.cashback_balance}</StyledTableCell>
                  <StyledTableCell>{field.created}</StyledTableCell>
                  <StyledTableCell>
                    {field.loan_application_number}
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      <Form.Check
                        type="checkbox"
                        disabled
                        defaultChecked={field.activation}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      <Form.Check
                        type="checkbox"
                        disabled
                        defaultChecked={field.loan_approved}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>
                    {field.redemption_expiry_date}
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      <Form.Check
                        type="checkbox"
                        disabled
                        defaultChecked={field.is_expired}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      <Form.Check
                        type="checkbox"
                        disabled
                        defaultChecked={field.is_deleted}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      <Form.Check
                        type="checkbox"
                        disabled
                        defaultChecked={field.valid}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>{field.cashback_used}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CashbackHistory;
