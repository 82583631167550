import { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ForsaLogo from "../../../assets/forsaLogo3.png";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import { UserUtility } from "../../../apis/UserUtility";
import "./Login.scss";
import UserContext from "../../../hooks/userContext";
import { toast } from "react-toastify";
import Modal from "react-modal";
import SignUp from "../signUp/signUp";

export default function Login() {
  const [spinner, setSpinner] = useState(false);
  const { setIsLogin } = useContext(UserContext);
  const [showSignUp, setShowSignUp] = useState(false);
  const handleLogin = async (values) => {
    setSpinner(true);
    UserUtility.userLogin(values)
      .then((res) => {
        localStorage.setItem("tools_token", res.token);
        setSpinner(false);
        setIsLogin(true);
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
        toast.error(error);
      });
  };

  const closeModal = () => {
    setShowSignUp(false);
  };

  const validateYup = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  return (
    <div className="login_container">
      <div className="image_container">
        <div className="image_wrapper">
          <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
        </div>
        <h4>Forsa tools</h4>
      </div>
      <div className="form_container">
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validateYup}
          onSubmit={async (values) => {
            handleLogin(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="form">
              <article className="inputs_wrapper">
                <h4 className="login_header">Login</h4>
                <section className="inputs">
                  <div className="input-container">
                    <label className="form_label">Email</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="input"
                    />
                    {errors.email && touched.email ? (
                      <div className="error_message">{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="input-container">
                    <label className="form_label">Password</label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className="input"
                    />
                    {errors.password && touched.password ? (
                      <div className="error_message">{errors.password}</div>
                    ) : null}
                  </div>
                </section>
              </article>
              <article className="buttonsWrapper">
                <Button
                  className="login_button"
                  type="submit"
                  variant="contained"
                  disabled={
                    errors.password ||
                    errors.email ||
                    !values.email ||
                    !values.password
                      ? true
                      : false
                  }
                  endIcon={spinner && <CgSpinner className="spinner" />}
                >
                  Login
                </Button>
                {/* <div className="form__footer" onClick={() => setShowSignUp(true)}>Sign up as staff</div> */}
              </article>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        isOpen={showSignUp}
        onRequestClose={closeModal}
        contentLabel="Sign Up"
        shouldCloseOnOverlayClick={true}
        className="modal"
      >
        <SignUp />
      </Modal>
    </div>
  );
}
