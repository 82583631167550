// import styles from "./CertificateStyle.module.scss";
import logo from "../../assets/images/logo2.png";
import mail from "../../assets/images/mailIcon.svg";
import locationLogo from "../../assets/images/locationIcon.svg";
import call from "../../assets/images/callIcon.svg";
import { useCallback, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import { Colors } from "../../constants/Colors";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import moment from "moment";

export const PrintingTemplates = () => {
  const location = useLocation();
  const { userId } = location.state;
  const { templatetype } = location.state;
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 500);
    });
  }, [setLoading]);

  const user_template = () => {
    UserUtility.user_template(userId, templatetype)
      .then((res) => {
        setTemplate(res.html);
      })
      .catch((err) => console.error("user_template", err));
  };
  useEffect(() => {
    user_template();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatetype]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  return (
    <main className="container">
      <div style={{ marginTop: "50px" }}>
        <Button
          variant="contained"
          type="submit"
          style={{
            marginLeft: 0,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: loading ? Colors.secondary : Colors.primary,
            color: "#fff",
          }}
          onClick={handlePrint}
          disabled={loading}
        >
          Print
        </Button>
      </div>
      <section ref={componentRef}>
        <section
          style={{
            paddingTop: 50,
            paddingRight: 10,
            textAlign: "-webkit-right",
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: template }}
            style={{ marginBlock: "100px" }}
          />
        </section>
      </section>
    </main>
  );
};
