import React, { useContext, useEffect, useState } from "react";
import { Form, Stack, Button, Pagination } from "react-bootstrap";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box } from "@mui/material";
import "./Style.scss";
import { Link } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import moment from "moment";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const Loans = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loansData, setLoansData] = useState([]);
  const [loansSummary, setLoansSummary] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  // const [pagesCount, setPagesCount] = useState(1);
  // const [pagesNumber, setPagesNumber] = useState(1);
  const [loanRefunded, setLoanRefunded] = useState(false);
  const { permissions } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [loanDetail, setLoanDetail] = useState();
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  const getLoans = () => {
    const start_date = moment(dateFrom).format("YYYY-MM-DD");
    const end_date = moment(dateTo).format("YYYY-MM-DD");
    setIsLoading(true);
    UserUtility.brandLonasList(
      page,
      start_date,
      end_date,
      searchValue,
      loanRefunded
    )
      .then((res) => {
        setLoansData(res.results);
        setLoanDetail(res);
        setLoansSummary(res.summary);
        setIsLoading(false);
        // let pages = Math.ceil(res.count / 10);
        // setPagesCount(pages);
      })
      .catch((error) => {
        console.error("error get loans", error);
        setIsLoading(false);
      });
  };
  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };
  return (
    <main className="loans_container">
      <h2>Brands</h2>

      {/* search bar  */}
      <Stack direction="horizontal" gap={4}>
        <Form.Control
          type="text"
          placeholder="Merchant Name"
          className="me-auto"
          size="lg"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {/* date picker from */}
        <span>From</span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="from"
            inputFormat="dd/MM/yyyy"
            value={dateFrom ? new Date(dateFrom) : null}
            style={{ width: "auto" }}
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  borderRadius: "3px",
                  paddingRight: "5px",
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  style={{ height: "48px", width: "110px", border: 0 }}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
        {/* date picker to */}
        <span>To</span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="To"
            // openTo="year"
            // views={["month", "day"]}
            inputFormat="dd/MM/yyyy"
            value={dateTo ? new Date(dateTo) : null}
            style={{ width: "auto" }}
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  borderRadius: "3px",
                  paddingRight: "5px",
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  style={{ height: "48px", width: "110px", border: 0 }}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>

        <div>
          <Form.Select
            style={{ width: "auto" }}
            aria-label="Default select example"
            onChange={(event) => {
              setLoanRefunded(event.target.value);
              setPage(1);
            }}
          >
            <option value={false}>Approved</option>
            <option value={true}>Refunded</option>
          </Form.Select>
        </div>

        <Button
          variant="success"
          className="px-5"
          onClick={getLoans}
          disabled={isLoading}
        >
          Search
        </Button>
      </Stack>
      {/* table */}
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Merchant</StyledTableCell>
              <StyledTableCell>No. Of Loans</StyledTableCell>
              <StyledTableCell>Sum Of Principles</StyledTableCell>
              <StyledTableCell>Sum Of Due Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <StyledTableCell>All Loans</StyledTableCell>
              <StyledTableCell>
                {loansSummary?.num_of_loans || 0}
              </StyledTableCell>
              <StyledTableCell>
                {loansSummary?.sum_of_principals || 0}
              </StyledTableCell>
              <StyledTableCell>
                {loansSummary?.sum_of_due_amount || 0}
              </StyledTableCell>
            </TableRow>
            {loansData?.map((loan, id) => (
              <StyledTableRow key={id}>
                <StyledTableCell>
                  {permissions.includes("can_view_loan_dashboard_loans") ? (
                    <Link
                      to="/loans/brandLoan"
                      state={{
                        brandData: loan,
                        filterState: loanRefunded,
                      }}
                      className="brand_name"
                    >
                      {loan.title}
                    </Link>
                  ) : (
                    <>{loan.title}</>
                  )}
                </StyledTableCell>
                <StyledTableCell>{loan.loans_count}</StyledTableCell>
                <StyledTableCell>{loan.sum_of_principal}</StyledTableCell>
                <StyledTableCell>{loan.sum_of_due_amount}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Pagination className="d-flex justify-content-center flex-wrap">
        <Pagination.First onClick={() => setPagesNumber(1)} />
        {[...Array(pagesCount)].map((x, i) => (
          <Pagination.Item
            value={i + 1}
            // onClick={(e) => setPagesNumber(e.target.attributes.value.value)}
            onClick={(e) => setPagesNumber(Number(e.target.text))}
            active={i + 1 === pagesNumber}
            key={i}
            className={"pagination_item"}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Last onClick={() => setPagesNumber(pagesCount)} />
      </Pagination> */}

      <PaginationComponent
        brands={loanDetail}
        page={page}
        handleChange={handleChange}
      />
    </main>
  );
};

export default Loans;
