import { ENDPOINTS } from "./EndPoints";
import { Network } from "./Network";

export class UserUtility {
  static userLogin(values) {
    let userCredentials = {
      username: values.email,
      password: values.password,
    };
    return Network.fetch(
      ENDPOINTS.userLogin.url,
      {
        body: JSON.stringify(userCredentials),
        method: ENDPOINTS.userLogin.method,
      },
      false
    );
  }
  static createKiosk(values) {
    return Network.fetch(
      ENDPOINTS.createKiosk.url,
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.createKiosk.method,
      },
      true
    );
  }

  static searchOtp(phoneNumber) {
    return Network.fetch(
      ENDPOINTS.searchOtp.url(phoneNumber),
      {
        method: ENDPOINTS.searchOtp.method,
      },
      true
    );
  }

  static userData() {
    return Network.fetch(
      ENDPOINTS.userData.url,
      {
        method: ENDPOINTS.userData.method,
      },
      true
    );
  }

  static userPermissions() {
    return Network.fetch(
      ENDPOINTS.userPermissions.url,
      {
        method: ENDPOINTS.userPermissions.method,
      },
      true
    );
  }

  static makersList(page, query = null) {
    return Network.fetch(
      ENDPOINTS.makersList.url(page, query),
      {
        method: ENDPOINTS.makersList.method,
      },
      true
    );
  }

  static makersSelectList() {
    return Network.fetch(
      ENDPOINTS.makersSelectList.url,
      {
        method: ENDPOINTS.makersSelectList.method,
      },
      true
    );
  }

  static courierList() {
    return Network.fetch(
      ENDPOINTS.courierList.url,
      {
        method: ENDPOINTS.courierList.method,
      },
      true
    );
  }

  static contractList(
    page,
    searchValue,
    maker,
    status,
    created_after,
    created_before
  ) {
    let filterParams = `page=${page}`;

    filterParams = searchValue
      ? `${filterParams}&search=${searchValue}`
      : filterParams;

    filterParams = maker ? `${filterParams}&maker=${maker}` : filterParams;
    filterParams = status ? `${filterParams}&status=${status}` : filterParams;

    filterParams =
      created_after !== "Invalid date"
        ? `${filterParams}&created_after=${created_after}`
        : filterParams;

    filterParams =
      created_before !== "Invalid date"
        ? `${filterParams}&created_before=${created_before}`
        : filterParams;

    return Network.fetch(
      ENDPOINTS.contractList.url(filterParams),
      {
        method: ENDPOINTS.contractList.method,
      },
      true
    );
  }

  static sendContractAction(
    actionName,
    contractId,
    courierId,
    rejection_reason = "",
    rejection_comment = ""
  ) {
    let actionValues = {
      action_type: actionName,
      contract: contractId,
      courier: courierId,
      rejection_reason: rejection_reason,
      comment: rejection_comment,
    };

    return Network.fetch(
      ENDPOINTS.sendContractAction.url,
      {
        body: JSON.stringify(actionValues),
        method: ENDPOINTS.sendContractAction.method,
      },
      true
    );
  }

  static createCollectionOrder(makersIds, courierId, branchId) {
    let collectionValues = {
      makers: makersIds,
      courier: courierId,
      branch: branchId,
    };
    return Network.fetch(
      ENDPOINTS.createCollectionOrder.url,
      {
        body: JSON.stringify(collectionValues),
        method: ENDPOINTS.createCollectionOrder.method,
      },
      true
    );
  }

  static contractHistory(contractId) {
    return Network.fetch(
      ENDPOINTS.contractHistory.url(contractId),
      {
        method: ENDPOINTS.contractHistory.method,
      },
      true
    );
  }

  static contractNotCollectedBranches() {
    return Network.fetch(
      ENDPOINTS.contractNotCollectedBranches.url,
      {
        method: ENDPOINTS.contractNotCollectedBranches.method,
      },
      true
    );
  }
  static contractBranches() {
    return Network.fetch(
      ENDPOINTS.contractBranches.url,
      {
        method: ENDPOINTS.contractBranches.method,
      },
      true
    );
  }

  static contractSummary(page, selectedBranch, status, dateFrom, dateTo) {
    let filterParams = `page=${page}`;

    filterParams = selectedBranch
      ? `${filterParams}&branch_id=${selectedBranch}`
      : filterParams;

    filterParams = status ? `${filterParams}&status=${status}` : filterParams;

    filterParams =
      dateFrom !== "Invalid date"
        ? `${filterParams}&created_gte=${dateFrom}`
        : filterParams;

    filterParams =
      dateTo !== "Invalid date"
        ? `${filterParams}&created_lte=${dateTo}`
        : filterParams;

    return Network.fetch(
      ENDPOINTS.contractSummary.url(filterParams),
      {
        method: ENDPOINTS.contractSummary.method,
      },
      true
    );
  }
  //Management tool
  static getBrands(
    page,
    searchValue,
    selectedSectors,
    newest,
    best_seller,
    featured,
    published,
    un_published
  ) {
    return Network.fetch(
      ENDPOINTS.getBrands.url(
        page,
        searchValue,
        selectedSectors,
        newest,
        best_seller,
        featured,
        published,
        un_published
      ),
      {
        method: ENDPOINTS.getBrands.method,
      },
      true
    );
  }

  static getBrandSectors() {
    return Network.fetch(
      ENDPOINTS.getBrandSectors.url,
      {
        method: ENDPOINTS.getBrandSectors.method,
      },
      true
    );
  }

  static editBrand(brandId, brandDetails) {
    return Network.fetch(
      ENDPOINTS.editBrand.url(brandId),
      {
        body: JSON.stringify(brandDetails),
        method: ENDPOINTS.editBrand.method,
      },
      true
    );
  }

  // brand actions
  static generatePOS(ids) {
    return Network.fetch(
      ENDPOINTS.actionPOS.url(ids),
      {
        method: ENDPOINTS.actionPOS.method,
      },
      true
    );
  }

  static generateMerchantUsers(ids) {
    return Network.fetch(
      ENDPOINTS.actionGenerateUsers.url(ids),
      {
        method: ENDPOINTS.actionGenerateUsers.method,
      },
      true
    );
  }

  static RegenerateMerchantUsers(ids) {
    return Network.fetch(
      ENDPOINTS.actionReGenerateUsers.url(ids),
      {
        method: ENDPOINTS.actionReGenerateUsers.method,
      },
      true
    );
  }

  static getOffers(page, brandId, search, published, un_published, is_expired) {
    let filterParams = `?brand=${brandId}&page=${page}`;
    filterParams = search ? `${filterParams}&search=${search}` : filterParams;

    // filterParams =
    //   (published && un_published) ? filterParams : (published && !un_published) ?
    //     `${filterParams}&published=true` : (!published && un_published) ? `${filterParams}&published=false` : filterParams

    if ((published && !un_published) || (!published && un_published)) {
      filterParams = published
        ? `${filterParams}&published=${published}`
        : filterParams;

      filterParams = un_published
        ? `${filterParams}&is_un_published=${un_published}`
        : filterParams;
    }

    filterParams = is_expired
      ? `${filterParams}&is_expired=${is_expired}`
      : filterParams;

    return Network.fetch(
      ENDPOINTS.getOffers.url(filterParams),
      {
        method: ENDPOINTS.getOffers.method,
      },
      true
    );
  }
  static getOfferDetail(offerId) {
    return Network.fetch(
      ENDPOINTS.getOfferDetails.url(offerId),
      {
        method: ENDPOINTS.getOfferDetails.method,
      },
      true
    );
  }

  // endpoint to hide offer
  static hideOffer(offerId, is_hidden) {
    return Network.fetch(
      ENDPOINTS.hideOffer.url(offerId),
      {
        body: JSON.stringify({ is_hidden: is_hidden }),
        method: ENDPOINTS.hideOffer.method,
      },
      true
    );
  }

  static getStatistics(start_date, end_date) {
    return Network.fetch(
      ENDPOINTS.getStatistics.url(start_date, end_date),
      {
        method: ENDPOINTS.getStatistics.method,
      },
      true
    );
  }
  static createInstallmentPlans(categoryId, installmetPlans) {
    return Network.fetch(
      ENDPOINTS.createInstallmentPlans.url(categoryId),
      {
        body: JSON.stringify(installmetPlans),
        method: ENDPOINTS.createInstallmentPlans.method,
      },
      true
    );
  }
  static getInstallmentPlans(categoryId) {
    return Network.fetch(
      ENDPOINTS.getInstallmentPlans.url(categoryId),
      {
        method: ENDPOINTS.getInstallmentPlans.method,
      },
      true
    );
  }

  static publishInstallmentPlans(installId, publishState) {
    let publishBody = {
      published: publishState,
      un_editable: publishState,
    };
    return Network.fetch(
      ENDPOINTS.publishInstallmentPlans.url(installId),
      {
        body: JSON.stringify(publishBody),
        method: ENDPOINTS.publishInstallmentPlans.method,
      },
      true
    );
  }
  static getBrandBranches(brandId, page) {
    return Network.fetch(
      ENDPOINTS.getBrandBranches.url(brandId, page),
      {
        method: ENDPOINTS.getBrandBranches.method,
      },
      true
    );
  }
  static addBranches(branchDetails, brandId) {
    let newBranch = {
      ...branchDetails,
      title: branchDetails.title_en,
      brand: brandId,
      // phone_numbers:branchDetails.phone_numbers.split(',')
    };

    return Network.fetch(
      ENDPOINTS.addBranches.url,
      {
        method: ENDPOINTS.addBranches.method,
        body: JSON.stringify(newBranch),
      },
      true
    );
  }
  static editBranch(branchDetails, branchId) {
    let newBranch = {
      ...branchDetails,
      title: branchDetails.title_en,
      // phone_numbers:branchDetails.phone_numbers.split(',')
    };
    return Network.fetch(
      ENDPOINTS.editBranch.url(branchId),
      {
        method: ENDPOINTS.editBranch.method,
        body: JSON.stringify(newBranch),
      },
      true
    );
  }

  static activeBrandBranches(brandId) {
    return Network.fetch(
      ENDPOINTS.activeBrandBranches.url(brandId),
      {
        method: ENDPOINTS.activeBrandBranches.method,
      },
      true
    );
  }
  static editInstallments(installmentsDetails) {
    return Network.fetch(
      ENDPOINTS.editInstallments.url,
      {
        method: ENDPOINTS.editInstallments.method,
        body: JSON.stringify(installmentsDetails),
      },
      true
    );
  }
  static createOffer(offerDetail) {
    return Network.fetch(
      ENDPOINTS.createOffer.url,
      {
        method: ENDPOINTS.createOffer.method,
        body: JSON.stringify(offerDetail),
      },
      true
    );
  }
  static editOffer(offerId, offerDetail) {
    console.log("newOfferData test: ", offerDetail);

    return Network.fetch(
      ENDPOINTS.editOffer.url(offerId),
      {
        method: ENDPOINTS.editOffer.method,
        body: JSON.stringify(offerDetail),
      },
      true
    );
  }
  static recreatePOSBranch(branchId) {
    return Network.fetch(
      ENDPOINTS.reCreateBranch.url(branchId),
      {
        method: ENDPOINTS.reCreateBranch.method,
      },
      true
    );
  }

  static getMerchantProviders() {
    return Network.fetch(
      ENDPOINTS.merchantProviders.url,
      {
        method: ENDPOINTS.merchantProviders.method,
      },
      true
    );
  }

  //kiosk management
  static getKioskUsers(role, is_assigned, page = 1, search, city, branch) {
    // console.log("typeof city: ", typeof city);
    let filterParams = `page=${page}`;
    filterParams = role ? `${filterParams}&role=${role}` : filterParams;

    filterParams = city ? `${filterParams}&city=${city}` : filterParams;

    filterParams = branch ? `${filterParams}&kiosk=${branch}` : filterParams;

    filterParams = search ? `${filterParams}&search=${search}` : filterParams;

    console.log("is_assigned: ", typeof is_assigned);
    filterParams =
      is_assigned === "false" ||
      is_assigned === "true" ||
      is_assigned === false ||
      is_assigned === true
        ? `${filterParams}&is_assigned=${is_assigned}`
        : filterParams;
    return Network.fetch(
      ENDPOINTS.getKioskUsers.url(filterParams),
      {
        method: ENDPOINTS.getKioskUsers.method,
      },
      true
    );
  }

  static getActivationPoints(page = 1, search, city, area) {
    // console.log("typeof city: ", typeof city);
    let filterParams = `page=${page}`;
    filterParams = search ? `${filterParams}&search=${search}` : filterParams;
    filterParams = city ? `${filterParams}&city=${city}` : filterParams;
    filterParams = area ? `${filterParams}&area=${area}` : filterParams;

    return Network.fetch(
      ENDPOINTS.getActivationPoints.url(filterParams),
      {
        method: ENDPOINTS.getActivationPoints.method,
      },
      true
    );
  }

  static getKiosks(search, city, branch, page = 1) {
    let filterParams = `page=${page}`;

    filterParams = city ? `${filterParams}&city=${city}` : filterParams;

    filterParams = branch ? `${filterParams}&id=${branch}` : filterParams;

    filterParams = search ? `${filterParams}&search=${search}` : filterParams;
    return Network.fetch(
      ENDPOINTS.getKiosks.url(filterParams),
      {
        method: ENDPOINTS.getKiosks.method,
      },
      true
    );
  }

  static updateKiosk(kioskId, makers, checkers) {
    return Network.fetch(
      ENDPOINTS.updateKiosk.url(kioskId),
      {
        method: ENDPOINTS.updateKiosk.method,
        body: JSON.stringify({ makers: makers, checkers: checkers }),
      },
      true
    );
  }

  static getKioskCities() {
    return Network.fetch(
      ENDPOINTS.kioskCities.url,
      {
        method: ENDPOINTS.kioskCities.method,
      },
      true
    );
  }

  static getKioskCAreas(selectedCity) {
    return Network.fetch(
      ENDPOINTS.kioskAreas.url(selectedCity),
      {
        method: ENDPOINTS.kioskAreas.method,
      },
      true
    );
  }

  static getKioskBranches(selectedCity) {
    let params = selectedCity ? `?city=${selectedCity}` : "";
    return Network.fetch(
      ENDPOINTS.kioskBranches.url(params),
      {
        method: ENDPOINTS.kioskBranches.method,
      },
      true
    );
  }

  static getOverdueUsers(page, searchValue) {
    return Network.fetch(
      ENDPOINTS.getOverdueUsers.url(page, searchValue),
      {
        method: ENDPOINTS.getOverdueUsers.method,
      },
      true
    );
  }

  static publishBrand(brandId, publishState) {
    let publishBody = {
      published: publishState,
    };
    return Network.fetch(
      ENDPOINTS.publishBrand.url(brandId),
      {
        body: JSON.stringify(publishBody),
        method: ENDPOINTS.publishBrand.method,
      },
      true
    );
  }
  static publishOffer(offerId, publishState) {
    let publishBody = {
      published: publishState,
    };
    return Network.fetch(
      ENDPOINTS.publishOffer.url(offerId),
      {
        body: JSON.stringify(publishBody),
        method: ENDPOINTS.publishOffer.method,
      },
      true
    );
  }

  static publishCategory(catId, publishState) {
    let publishBody = {
      published: publishState,
    };
    return Network.fetch(
      ENDPOINTS.publishCategory.url(catId),
      {
        body: JSON.stringify(publishBody),
        method: ENDPOINTS.publishCategory.method,
      },
      true
    );
  }
  static payMethod(paymentMethod, id) {
    return Network.fetch(
      ENDPOINTS.payMethod.url,
      {
        body: JSON.stringify({
          id: id,
          payment_method: paymentMethod,
          // payment_entity: "website",
        }),
        method: ENDPOINTS.payMethod.method,
      },
      true
    );
  }

  static getSpecialBrand(brandId) {
    return Network.fetch(
      ENDPOINTS.getSpecialBrand.url(brandId),
      {
        method: ENDPOINTS.getSpecialBrand.method,
      },
      true
    );
  }

  // list all categories in brand
  static getBrandCategoris(
    brandId,
    search,
    published,
    un_published,
    is_expired
  ) {
    let filterParams = `?brand=${brandId}`;
    filterParams = search ? `${filterParams}&search=${search}` : filterParams;

    // filterParams =
    // (published && un_published) ? filterParams : (published && !un_published) ?
    //   `${filterParams}&published=true` : (!published && un_published) ? `${filterParams}&published=false` : filterParams

    if ((published && !un_published) || (!published && un_published)) {
      filterParams = published
        ? `${filterParams}&published=${published}`
        : filterParams;

      filterParams = un_published
        ? `${filterParams}&is_un_published=${un_published}`
        : filterParams;
    }
    filterParams = is_expired
      ? `${filterParams}&is_expired=${is_expired}`
      : filterParams;

    return Network.fetch(
      ENDPOINTS.getCategories.url(filterParams),
      {
        method: ENDPOINTS.getCategories.method,
      },
      true
    );
  }

  static createBrand(brandDetails) {
    return Network.fetch(
      ENDPOINTS.createBrand.url,
      {
        body: JSON.stringify(brandDetails),
        method: ENDPOINTS.createBrand.method,
      },
      true
    );
  }
  static updateBrandCategories(brandId, categories) {
    return Network.fetch(
      ENDPOINTS.updateBrandCategories.url(brandId),
      {
        body: JSON.stringify({ categories: [...categories] }),
        method: ENDPOINTS.updateBrandCategories.method,
      },
      true
    );
  }
  static brandLonasList(page, start_date, end_date, searchValue, loanRefunded) {
    let filterParams = `page=${page}`;
    filterParams =
      start_date !== "Invalid date"
        ? `${filterParams}&created_gte=${start_date}`
        : filterParams;

    filterParams =
      end_date !== "Invalid date"
        ? `${filterParams}&created_lte=${end_date}`
        : filterParams;

    filterParams = searchValue
      ? `${filterParams}&search=${searchValue}`
      : filterParams;

    filterParams =
      loanRefunded !== undefined
        ? `${filterParams}&refunded=${loanRefunded}`
        : filterParams;

    return Network.fetch(
      ENDPOINTS.brandLonasList.url(filterParams),
      {
        method: ENDPOINTS.brandLonasList.method,
      },
      true
    );
  }
  static brandLonasDetails(
    brandId,
    page,
    start_date,
    end_date,
    branch_id,
    refunded
  ) {
    let filterParams = `branch__brand__id=${brandId}&page=${page}`;
    filterParams =
      start_date !== "Invalid date"
        ? `${filterParams}&created__date__gte=${start_date}`
        : filterParams;

    filterParams =
      end_date !== "Invalid date"
        ? `${filterParams}&created__date__lte=${end_date}`
        : filterParams;

    filterParams = branch_id
      ? `${filterParams}&branch__id=${branch_id}`
      : filterParams;

    filterParams =
      refunded !== undefined
        ? `${filterParams}&refunded=${refunded}`
        : filterParams;

    return Network.fetch(
      ENDPOINTS.brandLonasDetails.url(filterParams),
      {
        method: ENDPOINTS.brandLonasDetails.method,
      },
      true
    );
  }
  static user_list(
    currentPage,
    national_id,
    state,
    hold,
    iscore,
    modifiedStartDate,
    modifiedEndDate,
    createdStartDate,
    createdEndDate
  ) {
    return Network.fetch(
      ENDPOINTS.user_list.url(
        currentPage,
        national_id,
        state,
        hold,
        iscore,
        modifiedStartDate,
        modifiedEndDate,
        createdStartDate,
        createdEndDate
      ),
      {
        method: ENDPOINTS.user_list.method,
      },
      true
    );
  }

  static users_profile(userId) {
    return Network.fetch(
      ENDPOINTS.users_profile.url(userId),
      {
        method: ENDPOINTS.users_profile.method,
      },
      true
    );
  }
  static product_statement(userId, loanId) {
    return Network.fetch(
      ENDPOINTS.product_statement.url(userId, loanId),
      {
        method: ENDPOINTS.product_statement.method,
      },
      true
    );
  }
  static get_extra_documents(userId) {
    return Network.fetch(
      ENDPOINTS.get_extra_documents.url(userId),
      {
        method: ENDPOINTS.get_extra_documents.method,
      },
      true
    );
  }
  static users_options() {
    return Network.fetch(
      ENDPOINTS.users_options.url,
      {
        method: ENDPOINTS.users_options.method,
      },
      true
    );
  }
  static car_model(brandName) {
    return Network.fetch(
      ENDPOINTS.car_model.url(brandName),
      {
        method: ENDPOINTS.car_model.method,
      },
      true
    );
  }
  static login_info(userId) {
    return Network.fetch(
      ENDPOINTS.login_info.url(userId),
      {
        method: ENDPOINTS.login_info.method,
      },
      true
    );
  }

  static login_history_info(userId) {
    return Network.fetch(
      ENDPOINTS.login_history_info.url(userId),
      {
        method: ENDPOINTS.login_history_info.method,
      },
      true
    );
  }

  static login_action(userId, loginId, loginAction) {
    return Network.fetch(
      ENDPOINTS.login_action.url(userId, loginId),
      {
        body: JSON.stringify({ is_approved_to_login_through: loginAction }),
        method: ENDPOINTS.login_action.method,
      },
      true
    );
  }
  static change_userProfile(finalData, userId) {
    return Network.fetch(
      ENDPOINTS.change_userProfile.url(userId),
      {
        body: JSON.stringify(finalData),
        method: ENDPOINTS.change_userProfile.method,
      },
      true
    );
  }
  static userProfile_history(userId) {
    return Network.fetch(
      ENDPOINTS.userProfile_history.url(userId),
      {
        method: ENDPOINTS.userProfile_history.method,
      },
      true
    );
  }
  static userProfile_penalty(userId, pageNumber) {
    return Network.fetch(
      ENDPOINTS.userProfile_penalty.url(userId, pageNumber),
      {
        method: ENDPOINTS.userProfile_penalty.method,
      },
      true
    );
  }
  static payment_history(userId, pageNumber) {
    return Network.fetch(
      ENDPOINTS.payment_history.url(userId, pageNumber),
      {
        method: ENDPOINTS.payment_history.method,
      },
      true
    );
  }
  static user_paymob_payments(userId, pageNumber) {
    return Network.fetch(
      ENDPOINTS.user_paymob_payments.url(userId, pageNumber),
      {
        method: ENDPOINTS.user_paymob_payments.method,
      },
      true
    );
  }
  static loan_history(userId, pageNumber) {
    return Network.fetch(
      ENDPOINTS.loan_history.url(userId, pageNumber),
      {
        method: ENDPOINTS.loan_history.method,
      },
      true
    );
  }
  static contract_history(userId, pageNumber) {
    return Network.fetch(
      ENDPOINTS.contract_history.url(userId, pageNumber),
      {
        method: ENDPOINTS.contract_history.method,
      },
      true
    );
  }
  static user_document(userId) {
    return Network.fetch(
      ENDPOINTS.user_document.url(userId),
      {
        method: ENDPOINTS.user_document.method,
      },
      true
    );
  }

  static block_history(userId) {
    return Network.fetch(
      ENDPOINTS.block_history.url(userId),
      {
        method: ENDPOINTS.block_history.method,
      },
      true
    );
  }
  static unblock_user(userId) {
    return Network.fetch(
      ENDPOINTS.unblock_user.url(userId),
      {
        method: ENDPOINTS.unblock_user.method,
      },
      true
    );
  }
  static send_to_manual(userId) {
    return Network.fetch(
      ENDPOINTS.send_to_manual.url(userId),
      {
        body: JSON.stringify({ p_state: "MANUAL_STUDY" }),
        method: ENDPOINTS.send_to_manual.method,
      },
      true
    );
  }

  static getNextPageData(url) {
    return Network.fetch(
      ENDPOINTS.nextPage.url(url),
      {
        method: ENDPOINTS.nextPage.method,
      },
      true
    );
  }
  static export_users_profile() {
    return Network.fetch(
      ENDPOINTS.export_users_profile.url,
      {
        method: ENDPOINTS.export_users_profile.method,
      },
      true
    );
  }
  static action_history(userId, pageNumber) {
    return Network.fetch(
      ENDPOINTS.action_history.url(userId, pageNumber),
      {
        method: ENDPOINTS.action_history.method,
      },
      true
    );
  }

  // global offer
  static getBrandsBySector(sectorId) {
    return Network.fetch(
      ENDPOINTS.BrandsBySector.url(sectorId),
      {
        method: ENDPOINTS.BrandsBySector.method,
      },
      true
    );
  }
  static manualStudy_maker(pagesNumber, phone, nationalId) {
    return Network.fetch(
      ENDPOINTS.manualStudy_maker.url(pagesNumber, phone, nationalId),
      {
        method: ENDPOINTS.manualStudy_maker.method,
      },
      true
    );
  }

  static createGlobalOffer(finalData, brandIds) {
    return Network.fetch(
      ENDPOINTS.globalOffer.url(brandIds),
      {
        body: JSON.stringify(finalData),
        method: ENDPOINTS.globalOffer.method,
      },
      true
    );
  }

  static getBtandSlider(brandId) {
    return Network.fetch(
      ENDPOINTS.brandSlider.url(brandId),
      {
        method: ENDPOINTS.brandSlider.method,
      },
      true
    );
  }

  static getTags() {
    return Network.fetch(
      ENDPOINTS.tags.url,
      {
        method: ENDPOINTS.tags.method,
      },
      true
    );
  }

  static getMailList() {
    return Network.fetch(
      ENDPOINTS.riskMails.url,
      {
        method: ENDPOINTS.riskMails.method,
      },
      true
    );
  }

  static sendMailToRisk(comment, mails) {
    return Network.fetch(
      ENDPOINTS.sendRiskMail.url,
      {
        body: JSON.stringify({ emails: mails, risk_comment: comment }),
        method: ENDPOINTS.sendRiskMail.method,
      },
      true
    );
  }

  static getHistory(type, ids, page = 1) {
    console.log("merch-history : ", { type, ids });
    return Network.fetch(
      ENDPOINTS.getHistory.url(type, ids, page),
      {
        method: ENDPOINTS.getHistory.method,
      },
      true
    );
  }
  static getHistoryById(id, type, installmentIds, page = 1) {
    console.log("history by ids : ", { type, id });
    return Network.fetch(
      ENDPOINTS.getHistoryById.url(id, type, installmentIds, page),
      {
        method: ENDPOINTS.getHistoryById.method,
      },
      true
    );
  }

  static getInstallmentDefault(brandId) {
    return Network.fetch(
      ENDPOINTS.installmentDefault.url(brandId),
      {
        method: ENDPOINTS.installmentDefault.method,
      },
      true
    );
  }

  static generateUnqMerchantIdentifier() {
    return Network.fetch(
      ENDPOINTS.generateUniqueMerchantIndent.url,
      {
        method: ENDPOINTS.generateUniqueMerchantIndent.method,
      },
      true
    );
  }
  /*
  static getBrandHistoy(brandId) {
    return Network.fetch(
      ENDPOINTS.brandHistory.url(brandId),
      {
        method: ENDPOINTS.brandHistory.method,
      },
      true
    );
  }

  static getOfferHistoy(offerId) {
    return Network.fetch(
      ENDPOINTS.offerHistory.url(offerId),
      {
        method: ENDPOINTS.offerHistory.method,
      },
      true
    );
  }

  static getCategoryHistoy(catId) {
    return Network.fetch(
      ENDPOINTS.categoryHistory.url(catId),
      {
        method: ENDPOINTS.categoryHistory.method,
      },
      true
    );
  }
*/
  // end merchant
  static manualStudy_checker(pageNumber) {
    return Network.fetch(
      ENDPOINTS.manualStudy_checker.url(pageNumber),
      {
        method: ENDPOINTS.manualStudy_checker.method,
      },
      true
    );
  }
  static maker_beginStudy(data, userId) {
    return Network.fetch(
      ENDPOINTS.maker_beginStudy.url(userId),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.maker_beginStudy.method,
      },
      true
    );
  }
  static checker_retrieve(userId) {
    return Network.fetch(
      ENDPOINTS.checker_retrieve.url(userId),
      {
        method: ENDPOINTS.checker_retrieve.method,
      },
      true
    );
  }
  static checker_return_to_maker(userId) {
    return Network.fetch(
      ENDPOINTS.checker_return_to_maker.url(userId),
      {
        method: ENDPOINTS.checker_return_to_maker.method,
      },
      true
    );
  }
  static checker_approve(userId) {
    return Network.fetch(
      ENDPOINTS.checker_approve.url(userId),
      {
        method: ENDPOINTS.checker_approve.method,
      },
      true
    );
  }
  static checker_comment(userId, comment) {
    return Network.fetch(
      ENDPOINTS.checker_comment.url(userId),
      {
        body: JSON.stringify(comment),
        method: ENDPOINTS.checker_comment.method,
      },
      true
    );
  }
  static maker_reject(userId) {
    return Network.fetch(
      ENDPOINTS.maker_reject.url(userId),
      {
        method: ENDPOINTS.maker_reject.method,
      },
      true
    );
  }
  static retrieve_checker_comment(userId) {
    return Network.fetch(
      ENDPOINTS.retrieve_checker_comment.url(userId),
      {
        method: ENDPOINTS.retrieve_checker_comment.method,
      },
      true
    );
  }
  static limitMaker_search(phone, national_id) {
    return Network.fetch(
      ENDPOINTS.limitMaker_search.url(phone, national_id),
      {
        method: ENDPOINTS.limitMaker_search.method,
      },
      true
    );
  }
  static limitMaker_beginStudy(data, userId) {
    return Network.fetch(
      ENDPOINTS.limitMaker_beginStudy.url(userId),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.limitMaker_beginStudy.method,
      },
      true
    );
  }
  static limitChecker_retrieve(userId) {
    return Network.fetch(
      ENDPOINTS.limitChecker_retrieve.url(userId),
      {
        method: ENDPOINTS.limitChecker_retrieve.method,
      },
      true
    );
  }
  static limitChecker_list(pageNumber) {
    return Network.fetch(
      ENDPOINTS.limitChecker_list.url(pageNumber),
      {
        method: ENDPOINTS.limitChecker_list.method,
      },
      true
    );
  }
  static limitChecker_comment(userId, comment) {
    return Network.fetch(
      ENDPOINTS.limitChecker_comment.url(userId),
      {
        body: JSON.stringify(comment),
        method: ENDPOINTS.limitChecker_comment.method,
      },
      true
    );
  }
  static limitChecker_returnToMaker(userId) {
    return Network.fetch(
      ENDPOINTS.limitChecker_returnToMaker.url(userId),
      {
        method: ENDPOINTS.limitChecker_returnToMaker.method,
      },
      true
    );
  }
  static limitMaker_list(pageNumber) {
    return Network.fetch(
      ENDPOINTS.limitMaker_list.url(pageNumber),
      {
        method: ENDPOINTS.limitMaker_list.method,
      },
      true
    );
  }
  static limitMaker_retrieveComment(userId) {
    return Network.fetch(
      ENDPOINTS.limitMaker_retrieveComment.url(userId),
      {
        method: ENDPOINTS.limitMaker_retrieveComment.method,
      },
      true
    );
  }
  static limitChecker_approve(userId) {
    return Network.fetch(
      ENDPOINTS.limitChecker_approve.url(userId),
      {
        method: ENDPOINTS.limitChecker_approve.method,
      },
      true
    );
  }
  static limitChecker_reject(userId) {
    return Network.fetch(
      ENDPOINTS.limitChecker_reject.url(userId),
      {
        method: ENDPOINTS.limitChecker_reject.method,
      },
      true
    );
  }
  static actions_list() {
    return Network.fetch(
      ENDPOINTS.actions_list.url,
      {
        method: ENDPOINTS.actions_list.method,
      },
      true
    );
  }
  static actions(actionValue, ids, obj) {
    if (!obj?.reason) {
      obj = {};
    }

    return Network.fetch(
      ENDPOINTS.actions.url(actionValue, ids),
      {
        body: JSON.stringify(obj),
        method: ENDPOINTS.actions.method,
      },
      true
    );
  }
  static action_report(actionValue, ids) {
    return Network.fetch(
      ENDPOINTS.action_report.url(actionValue, ids),
      {
        method: ENDPOINTS.action_report.method,
      },
      true
    );
  }
  static user_managment(national_id) {
    return Network.fetch(
      ENDPOINTS.user_managment.url(national_id),
      {
        method: ENDPOINTS.user_managment.method,
      },
      true
    );
  }
  // userRequest
  static user_request_list(
    page,
    state,
    requestValue,
    id,
    department,
    createdStartDate,
    createdEndDate,
    payingStatus
  ) {
    return Network.fetch(
      ENDPOINTS.user_request_list.url(
        page,
        state,
        requestValue,
        id,
        department,
        createdStartDate,
        createdEndDate,
        payingStatus
      ),
      {
        method: ENDPOINTS.user_request_list.method,
      },
      true
    );
  }
  static status_options() {
    return Network.fetch(
      ENDPOINTS.status_options.url,
      {
        method: ENDPOINTS.status_options.method,
      },
      true
    );
  }
  static request_types() {
    return Network.fetch(
      ENDPOINTS.request_types.url,
      {
        method: ENDPOINTS.request_types.method,
      },
      true
    );
  }
  static request_tool_action(actions, id, reason) {
    let type = {
      action: actions,
      return_reason: reason,
    };
    return Network.fetch(
      ENDPOINTS.request_tool_action.url(id),
      {
        body: JSON.stringify(type),
        method: ENDPOINTS.request_tool_action.method,
      },
      true
    );
  }
  static department_options() {
    return Network.fetch(
      ENDPOINTS.department_options.url,
      {
        method: ENDPOINTS.department_options.method,
      },
      true
    );
  }
  static actions_history(id, pagesNumber) {
    return Network.fetch(
      ENDPOINTS.actions_history.url(id, pagesNumber),
      {
        method: ENDPOINTS.actions_history.method,
      },
      true
    );
  }
  static branches_options() {
    return Network.fetch(
      ENDPOINTS.branches_options.url,
      {
        method: ENDPOINTS.branches_options.method,
      },
      true
    );
  }
  static changeBranch(id, newBranch) {
    return Network.fetch(
      ENDPOINTS.changeBranch.url(id),
      {
        body: JSON.stringify({ forsa_branch: newBranch }),
        method: ENDPOINTS.changeBranch.method,
      },
      true
    );
  }
  // Legal Tool
  static search_list(nationalId) {
    return Network.fetch(
      ENDPOINTS.search_list.url(nationalId),
      {
        method: ENDPOINTS.search_list.method,
      },
      true
    );
  }

  static lawsuit_list(id, pageNumber) {
    return Network.fetch(
      ENDPOINTS.lawsuit_list.url(id, pageNumber),
      {
        method: ENDPOINTS.lawsuit_list.method,
      },
      true
    );
  }
  static alert_list(id, pageNumber) {
    return Network.fetch(
      ENDPOINTS.alert_list.url(id, pageNumber),
      {
        method: ENDPOINTS.alert_list.method,
      },
      true
    );
  }
  static fees_list(id, pageNumber) {
    return Network.fetch(
      ENDPOINTS.fees_list.url(id, pageNumber),
      {
        method: ENDPOINTS.fees_list.method,
      },
      true
    );
  }
  static get_alert(alert_id, id) {
    return Network.fetch(
      ENDPOINTS.get_alert.url(alert_id, id),
      {
        method: ENDPOINTS.get_alert.method,
      },
      true
    );
  }
  static get_session(session_id, id) {
    return Network.fetch(
      ENDPOINTS.get_session.url(session_id, id),
      {
        method: ENDPOINTS.get_session.method,
      },
      true
    );
  }
  static get_lawsuit(lawsuit_id, id) {
    return Network.fetch(
      ENDPOINTS.get_lawsuit.url(lawsuit_id, id),
      {
        method: ENDPOINTS.get_lawsuit.method,
      },
      true
    );
  }
  static get_fees(fees_id, id) {
    return Network.fetch(
      ENDPOINTS.get_fees.url(fees_id, id),
      {
        method: ENDPOINTS.get_fees.method,
      },
      true
    );
  }
  static create_alert(id, data) {
    return Network.fetch(
      ENDPOINTS.create_alert.url(id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.create_alert.method,
      },
      true
    );
  }
  static create_lawsuit(id, data) {
    return Network.fetch(
      ENDPOINTS.create_lawsuit.url(id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.create_lawsuit.method,
      },
      true
    );
  }
  static create_fees(id, data) {
    return Network.fetch(
      ENDPOINTS.create_fees.url(id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.create_fees.method,
      },
      true
    );
  }
  static update_alert(alert_id, id, data) {
    return Network.fetch(
      ENDPOINTS.update_alert.url(alert_id, id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.update_alert.method,
      },
      true
    );
  }
  static update_lawsuit(lawsuit_id, id, data) {
    return Network.fetch(
      ENDPOINTS.update_lawsuit.url(lawsuit_id, id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.update_lawsuit.method,
      },
      true
    );
  }
  static update_session(session_id, id, data) {
    return Network.fetch(
      ENDPOINTS.update_session.url(session_id, id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.update_session.method,
      },
      true
    );
  }
  static update_fees(fees_id, id, data) {
    return Network.fetch(
      ENDPOINTS.update_fees.url(fees_id, id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.update_fees.method,
      },
      true
    );
  }
  static legal_report(nationa_id) {
    const data = {
      type: "legal",
      national_id: nationa_id,
      name: "legalsummary",
    };
    return Network.fetch(
      ENDPOINTS.legal_report.url,
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.legal_report.method,
      },
      true
    );
  }
  static user_reconciliation(id) {
    return Network.fetch(
      ENDPOINTS.user_reconciliation.url,
      {
        body: JSON.stringify({ user: id }),
        method: ENDPOINTS.user_reconciliation.method,
      },
      true
    );
  }

  // one transaction tool
  // to get user profile that already exist in forsa users
  static oneTransGetProfile(nationa_id) {
    return Network.fetch(
      ENDPOINTS.profileInfo.url(nationa_id),
      {
        method: ENDPOINTS.profileInfo.method,
      },
      true
    );
  }

  // to get profile options that contain all dropdown arr
  static oneTransProfileOptions() {
    return Network.fetch(
      ENDPOINTS.ProfileOptions.url,
      {
        method: ENDPOINTS.ProfileOptions.method,
      },
      true
    );
  }

  // to get all applications profile
  static oneTransAllApplications(
    page,
    searchValue,
    numberOfApplications,
    dateFrom,
    dateTo,
    status
  ) {
    let filterParams = `page=${page}`;

    filterParams = searchValue
      ? `${filterParams}&search=${searchValue}`
      : filterParams;

    filterParams = numberOfApplications
      ? `${filterParams}&loan_count=${numberOfApplications}`
      : filterParams;

    filterParams =
      dateFrom !== "Invalid date"
        ? `${filterParams}&from=${dateFrom}`
        : filterParams;

    filterParams =
      dateTo !== "Invalid date" ? `${filterParams}&to=${dateTo}` : filterParams;

    filterParams = status
      ? `${filterParams}&returned_status=${status}`
      : filterParams;

    console.log("filterParams: ", filterParams);
    return Network.fetch(
      ENDPOINTS.AllApplications.url(filterParams),
      {
        method: ENDPOINTS.AllApplications.method,
      },
      true
    );
  }
  // To get user profile loans
  static userProfile_loansHistory(page, user_id) {
    return Network.fetch(
      ENDPOINTS.userProfile_loansHistory.url(page, user_id),
      {
        method: ENDPOINTS.userProfile_loansHistory.method,
      },
      true
    );
  }
  static loanHistory(page, loan_id) {
    return Network.fetch(
      ENDPOINTS.loanHistory.url(page, loan_id),
      {
        method: ENDPOINTS.loanHistory.method,
      },
      true
    );
  }
  // to get specific application profile by id
  static oneTransApplication(id) {
    return Network.fetch(
      ENDPOINTS.Application.url(id),
      {
        method: ENDPOINTS.Application.method,
      },
      true
    );
  }

  // to create new profile Or Edit profile
  static oneTransCreateOrEditProfile(values, method, profileId) {
    return Network.fetch(
      ENDPOINTS.newProfile.url(profileId),
      {
        body: JSON.stringify(values),
        method: method,
      },
      true
    );
  }

  // to get cities in onetransaction
  static oneTransCities() {
    return Network.fetch(
      ENDPOINTS.onTransactionCities.url,
      {
        method: ENDPOINTS.onTransactionCities.method,
      },
      true
    );
  }

  // to get Areas in onetransaction
  static oneTransAreas(city) {
    return Network.fetch(
      ENDPOINTS.onTransactionArea.url(city),
      {
        method: ENDPOINTS.onTransactionArea.method,
      },
      true
    );
  }

  //onetransaction credit
  static oneTransPrograms() {
    return Network.fetch(
      ENDPOINTS.onTransactionProgram.url,
      {
        method: ENDPOINTS.onTransactionProgram.method,
      },
      true
    );
  }

  static oneTransTenors(programId) {
    return Network.fetch(
      ENDPOINTS.onTransactionTenors.url(programId),
      {
        method: ENDPOINTS.onTransactionTenors.method,
      },
      true
    );
  }

  static session_list(id, pageNumber) {
    return Network.fetch(
      ENDPOINTS.session_list.url(id, pageNumber),
      {
        method: ENDPOINTS.session_list.method,
      },
      true
    );
  }

  static oneTransCreateLiability(values) {
    return Network.fetch(
      ENDPOINTS.onTransactionCreateLiablity.url,
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionCreateLiablity.method,
      },
      true
    );
  }

  static oneTransLiabilities(user) {
    return Network.fetch(
      ENDPOINTS.onTransactionLiablity.url(user),
      {
        method: ENDPOINTS.onTransactionLiablity.method,
      },
      true
    );
  }

  static oneTransEditLiability(liablitiyId, values) {
    return Network.fetch(
      ENDPOINTS.onTransactionEditLiablity.url(liablitiyId),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionEditLiablity.method,
      },
      true
    );
  }

  static oneTransCreateCredit(values) {
    return Network.fetch(
      ENDPOINTS.onTransactionCreateCredit.url,
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionCreateCredit.method,
      },
      true
    );
  }

  static oneTransEditCredit(creditId, values) {
    return Network.fetch(
      ENDPOINTS.onTransactionEditCredit.url(creditId),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionEditCredit.method,
      },
      true
    );
  }

  static oneTransCredits(
    page,
    searchValue,
    status,
    dateFrom,
    dateTo,
    programSelected,
    deviation
  ) {
    let filterParams = `page=${page}`;
    filterParams = searchValue
      ? `${filterParams}&search=${searchValue}`
      : filterParams;

    filterParams =
      status && status !== "All"
        ? `${filterParams}&status=${status}`
        : filterParams;
    filterParams =
      dateFrom !== "Invalid date" && dateFrom !== null
        ? `${filterParams}&from=${dateFrom}`
        : filterParams;

    filterParams =
      dateTo !== "Invalid date" && dateTo !== null
        ? `${filterParams}&to=${dateTo}`
        : filterParams;

    filterParams =
      programSelected && programSelected !== "All"
        ? `${filterParams}&program=${programSelected}`
        : filterParams;

    filterParams = deviation
      ? `${filterParams}&deviation_view=${deviation}`
      : filterParams;
    return Network.fetch(
      ENDPOINTS.onTransactionCredits.url(filterParams),
      {
        method: ENDPOINTS.onTransactionCredits.method,
      },
      true
    );
  }

  static create_session(id, data) {
    return Network.fetch(
      ENDPOINTS.create_session.url(id),
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.create_session.method,
      },
      true
    );
  }
  static getLoanData(loanId) {
    return Network.fetch(
      ENDPOINTS.getLoanData.url(loanId),
      {
        method: ENDPOINTS.getLoanData.method,
      },
      true
    );
  }

  static oneTransCreditOptions() {
    return Network.fetch(
      ENDPOINTS.onTransactionCreditsOptions.url,
      {
        method: ENDPOINTS.onTransactionCreditsOptions.method,
      },
      true
    );
  }

  static oneTransIscore(loanId) {
    return Network.fetch(
      ENDPOINTS.onTransactionIscore.url(loanId),
      {
        method: ENDPOINTS.onTransactionIscore.method,
      },
      true
    );
  }

  static oneTransAddComment(values) {
    return Network.fetch(
      ENDPOINTS.onTransactionAddComment.url,
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionAddComment.method,
      },
      true
    );
  }

  static oneTransEditComment(commentId, values) {
    return Network.fetch(
      ENDPOINTS.onTransactionEditComment.url(commentId),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionEditComment.method,
      },
      true
    );
  }

  // legal functions

  static oneTransGetComments(oneTrasactId, type) {
    let filterParams = `onetransactionloan=${oneTrasactId}`;
    filterParams = type ? `${filterParams}&type=${type}` : filterParams;

    return Network.fetch(
      ENDPOINTS.onTransactionGetComments.url(filterParams),
      {
        method: ENDPOINTS.onTransactionGetComments.method,
      },
      true
    );
  }
  // Deviation
  static deviationList() {
    return Network.fetch(
      ENDPOINTS.deviationList.url,
      {
        method: ENDPOINTS.deviationList.method,
      },
      true
    );
  }

  static deviationApproval(data) {
    return Network.fetch(
      ENDPOINTS.deviationApprove.url,
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.deviationApprove.method,
      },
      true
    );
  }
  // cheques

  static oneTransCreateCheque(values) {
    return Network.fetch(
      ENDPOINTS.onTransactionCreateCheque.url,
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionCreateCheque.method,
      },
      true
    );
  }

  static oneTransCheques(loanId, page) {
    console.log("endpoint: ", ENDPOINTS.onTransactionCheques.url(loanId, page));
    return Network.fetch(
      ENDPOINTS.onTransactionCheques.url(loanId, page),
      {
        method: ENDPOINTS.onTransactionCheques.method,
      },
      true
    );
  }

  static oneTransEditCheque(chequeId, values) {
    return Network.fetch(
      ENDPOINTS.onTransactionEditCheque.url(chequeId),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.onTransactionEditCheque.method,
      },
      true
    );
  }

  // loan documents
  static oneTransactionLoanDocuments(loanId, department) {
    return Network.fetch(
      ENDPOINTS.onTransactionLoanDocuments.url(loanId, department),
      {
        method: ENDPOINTS.onTransactionLoanDocuments.method,
      },
      true
    );
  }

  static oneTransactionReturnedComments(profileId) {
    return Network.fetch(
      ENDPOINTS.onTransactionReturnedComments.url(profileId),
      {
        method: ENDPOINTS.onTransactionReturnedComments.method,
      },
      true
    );
  }
  // one transaction operation

  // static operationSearch(search) {
  //   return Network.fetch(
  //     ENDPOINTS.operationSearch.url(search),
  //     {
  //       method: ENDPOINTS.operationSearch.method,
  //     },
  //     true
  //   );
  // }
  static operationList(page, search, creditSelected) {
    return Network.fetch(
      ENDPOINTS.operationList.url(page, search, creditSelected),
      {
        method: ENDPOINTS.operationList.method,
      },
      true
    );
  }
  static image_list(id, pagesNumber) {
    return Network.fetch(
      ENDPOINTS.image_list.url(id, pagesNumber),
      {
        method: ENDPOINTS.image_list.method,
      },
      true
    );
  }

  static sendToOperationChekcer(loanId, values) {
    return Network.fetch(
      ENDPOINTS.sendToOperationChekcer.url(loanId),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.sendToOperationChekcer.method,
      },
      true
    );
  }
  // Finance
  static financeList(page, search, creditSelected) {
    return Network.fetch(
      ENDPOINTS.financeList.url(page, search, creditSelected),
      {
        method: ENDPOINTS.financeList.method,
      },
      true
    );
  }

  static delete_image(image_id, id) {
    return Network.fetch(
      ENDPOINTS.delete_image.url(image_id, id),
      {
        method: ENDPOINTS.delete_image.method,
      },
      true
    );
  }

  static create_image(id, image) {
    return Network.fetch(
      ENDPOINTS.create_image.url(id),
      {
        body: JSON.stringify(image),
        method: ENDPOINTS.create_image.method,
      },
      true
    );
  }

  static ReturnToDepartment(loanId, values) {
    return Network.fetch(
      ENDPOINTS.ReturnToDepartment.url(loanId),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.ReturnToDepartment.method,
      },
      true
    );
  }

  static user_hold(ids, userHoldBody) {
    return Network.fetch(
      ENDPOINTS.user_hold.url(ids),
      {
        body: JSON.stringify(userHoldBody),
        method: ENDPOINTS.user_hold.method,
      },
      true
    );
  }

  // Collection Tool
  static advanced_users(pageNumber, national_id) {
    return Network.fetch(
      ENDPOINTS.advanced_users.url(pageNumber, national_id),
      {
        method: ENDPOINTS.advanced_users.method,
      },
      true
    );
  }

  static finance_list(pagesNumber, nationa_id, date) {
    return Network.fetch(
      ENDPOINTS.finance_list.url(pagesNumber, nationa_id, date),
      {
        method: ENDPOINTS.finance_list.method,
      },
      true
    );
  }

  // financeOptions
  static financeOptions() {
    return Network.fetch(
      ENDPOINTS.financeOptions.url,
      {
        method: ENDPOINTS.financeOptions.method,
      },
      true
    );
  }
  static user_installments(user_id) {
    return Network.fetch(
      ENDPOINTS.user_installments.url(user_id),
      {
        method: ENDPOINTS.user_installments.method,
      },
      true
    );
  }

  static user_products(user_id) {
    return Network.fetch(
      ENDPOINTS.user_products.url(user_id),
      {
        method: ENDPOINTS.user_products.method,
      },
      true
    );
  }

  static user_penalties(
    user_id,
    created,
    amount,
    due_date,
    remaining_dues,
    on_hold
  ) {
    return Network.fetch(
      ENDPOINTS.user_penalties.url(
        user_id,
        created,
        amount,
        due_date,
        remaining_dues,
        on_hold
      ),
      {
        method: ENDPOINTS.user_penalties.method,
      },
      true
    );
  }

  static advanced_payment(user_id) {
    return Network.fetch(
      ENDPOINTS.advanced_payment.url(user_id),
      {
        method: ENDPOINTS.advanced_payment.method,
      },
      true
    );
  }
  static cash_collect(user_id, dates, paymentValue) {
    let values = {
      selected_due_dates: dates,
      settlement_amount: paymentValue,
    };
    return Network.fetch(
      ENDPOINTS.cash_collect.url(user_id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.cash_collect.method,
      },
      true
    );
  }
  // ReportS

  static finance_report(from, to) {
    return Network.fetch(
      ENDPOINTS.finance_report.url(from, to),
      {
        method: ENDPOINTS.finance_report.method,
      },
      true
    );
  }
  static device_report(modifiedFrom, modifiedTo, createdFrom, createdTo) {
    return Network.fetch(
      ENDPOINTS.device_report.url(
        modifiedFrom,
        modifiedTo,
        createdFrom,
        createdTo
      ),
      {
        method: ENDPOINTS.device_report.method,
      },
      true
    );
  }

  static waive_penalties(user_id, waiveIds, waivePercentage) {
    let values = {
      penalties: waiveIds,
      waive_percentage: waivePercentage,
    };
    return Network.fetch(
      ENDPOINTS.waive_penalties.url(user_id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.waive_penalties.method,
      },
      true
    );
  }

  static update_penalties(user_id, waiveIds, waivePercentage, waiveExpiry) {
    let values = {
      penalties: waiveIds,
      waive_percentage: waivePercentage,
      waive_percentage_expiry: waiveExpiry,
    };
    return Network.fetch(
      ENDPOINTS.update_penalties.url(user_id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.update_penalties.method,
      },
      true
    );
  }

  // User Profile
  static hold_reason_list() {
    return Network.fetch(
      ENDPOINTS.hold_reason_list.url,
      {
        method: ENDPOINTS.hold_reason_list.method,
      },
      true
    );
  }

  static holdPenalties(user_id, ids) {
    return Network.fetch(
      ENDPOINTS.hold_penalties.url(user_id),
      {
        body: JSON.stringify({ penalties: ids }),
        method: ENDPOINTS.hold_penalties.method,
      },
      true
    );
  }

  static unholdPenalties(user_id, ids) {
    return Network.fetch(
      ENDPOINTS.unhold_penalties.url(user_id),
      {
        body: JSON.stringify({ penalties: ids }),
        method: ENDPOINTS.unhold_penalties.method,
      },
      true
    );
  }

  // onetransaction payment
  static oneTransactionReport(data) {
    return Network.fetch(
      ENDPOINTS.oneTransactionReport.url,
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.oneTransactionReport.method,
      },
      true
    );
  }

  // Notification
  static notification_list(
    pageNumber,
    searchValue,
    status,
    created_from_date,
    created_to_date
  ) {
    let filterParams = `?page=${pageNumber}`;
    filterParams = searchValue
      ? `${filterParams}&search=${searchValue}`
      : filterParams;
    filterParams =
      status && status !== "All"
        ? `${filterParams}&status=${status}`
        : filterParams;

    filterParams = created_from_date
      ? `${filterParams}&created_gte=${created_from_date}`
      : filterParams;
    filterParams = created_to_date
      ? `${filterParams}&created_lte=${created_to_date}`
      : filterParams;

    return Network.fetch(
      ENDPOINTS.notification_list.url(filterParams),
      {
        method: ENDPOINTS.notification_list.method,
      },
      true
    );
  }
  static delete_notification(notification_id) {
    return Network.fetch(
      ENDPOINTS.delete_notification.url(notification_id),
      {
        method: ENDPOINTS.delete_notification.method,
      },
      true
    );
  }

  static getUserOptions(cities = "") {
    return Network.fetch(
      ENDPOINTS.userOptions.url(cities),
      {
        method: ENDPOINTS.userOptions.method,
      },
      true
    );
  }

  static getTargetCount(filterParams) {
    return Network.fetch(
      ENDPOINTS.tergetCount.url(filterParams),
      {
        method: ENDPOINTS.tergetCount.method,
      },
      true
    );
  }

  static getAudiences(searchValue, page) {
    let filter = `?page=${page}`;
    filter = searchValue ? `${filter}&search=${searchValue}` : filter;
    return Network.fetch(
      ENDPOINTS.audiences.url(filter),
      {
        method: ENDPOINTS.audiences.method,
      },
      true
    );
  }

  // User location history
  static user_location(user_id) {
    return Network.fetch(
      ENDPOINTS.user_location.url(user_id),
      {
        method: ENDPOINTS.user_location.method,
      },
      true
    );
  }
  static CreditLimit(param) {
    return Network.fetch(
      ENDPOINTS.credit_limit.url(param),
      {
        method: ENDPOINTS.credit_limit.method,
      },
      true
    );
  }
  // Blacklist history
  static blackList_history(user_id, page) {
    return Network.fetch(
      ENDPOINTS.blackList_history.url(user_id, page),
      {
        method: ENDPOINTS.blackList_history.method,
      },
      true
    );
  }
  // One Transaction Collection

  static collection_list(pageNumber, state, searchValue) {
    return Network.fetch(
      ENDPOINTS.collection_list.url(pageNumber, state, searchValue),
      {
        method: ENDPOINTS.collection_list.method,
      },
      true
    );
  }
  // Cashback history
  static cashback_history(user_id) {
    return Network.fetch(
      ENDPOINTS.cashback_history.url(user_id),
      {
        method: ENDPOINTS.cashback_history.method,
      },
      true
    );
  }

  static blackList_activate(user_id, id) {
    return Network.fetch(
      ENDPOINTS.blackList_activate.url(user_id),
      {
        body: JSON.stringify({ ids: [id] }),
        method: ENDPOINTS.blackList_activate.method,
      },
      true
    );
  }

  static collection_options() {
    return Network.fetch(
      ENDPOINTS.collection_options.url,
      {
        method: ENDPOINTS.collection_options.method,
      },
      true
    );
  }

  static blackList_deactivate(user_id, id) {
    return Network.fetch(
      ENDPOINTS.blackList_deactivate.url(user_id),
      {
        body: JSON.stringify({ ids: [id] }),
        method: ENDPOINTS.blackList_deactivate.method,
      },
      true
    );
  }

  static monthly_installments(user_id) {
    return Network.fetch(
      ENDPOINTS.monthly_installments.url(user_id),
      {
        method: ENDPOINTS.monthly_installments.method,
      },
      true
    );
  }

  static OnBoard_All_List(searchParams) {
    return Network.fetch(
      ENDPOINTS.OnBoard_list.url(searchParams),
      {
        method: ENDPOINTS.OnBoard_list.method,
      },
      true
    );
  }
  static getBrandClass(page) {
    return Network.fetch(
      ENDPOINTS.Brand_Class.url(page),
      {
        method: ENDPOINTS.Brand_Class.method,
      },
      true
    );
  }
  static getLocation(page) {
    return Network.fetch(
      ENDPOINTS.Location.url(page),
      {
        method: ENDPOINTS.Location.method,
      },
      true
    );
  }
  static rejectResone() {
    return Network.fetch(
      ENDPOINTS.reject_resone.url,
      {
        method: ENDPOINTS.reject_resone.method,
      },
      true
    );
  }
  static Comments(DraftID) {
    return Network.fetch(
      ENDPOINTS.comments.url(DraftID),
      {
        method: ENDPOINTS.comments.method,
      },
      true
    );
  }
  static CommentsViewListbyCategory(DraftID, category) {
    return Network.fetch(
      ENDPOINTS.commentsbByCategory.url(DraftID, category),
      {
        method: ENDPOINTS.commentsbByCategory.method,
      },
      true
    );
  }

  static Documents(DraftID, page) {
    return Network.fetch(
      ENDPOINTS.documents.url(DraftID, page),
      {
        method: ENDPOINTS.documents.method,
      },
      true
    );
  }

  static collection_products(user_id) {
    return Network.fetch(
      ENDPOINTS.collection_products.url(user_id),
      {
        method: ENDPOINTS.collection_products.method,
      },
      true
    );
  }
  static AuditLogList(DraftID) {
    return Network.fetch(
      ENDPOINTS.auditLogList.url(DraftID),
      {
        method: ENDPOINTS.auditLogList.method,
      },
      true
    );
  }
  static Failure_Requests(req, RequestParams) {
    return Network.fetch(
      ENDPOINTS.failure_requests.url(req, RequestParams),
      {
        method: ENDPOINTS.failure_requests.method,
      },
      true
    );
  }

  static checker_confirm(filterIds) {
    return Network.fetch(
      ENDPOINTS.checker_confirm.url,
      {
        body: JSON.stringify({ advanced_payments: filterIds }),
        method: ENDPOINTS.checker_confirm.method,
      },
      true
    );
  }

  // onboarding merchant
  static ownerList(page) {
    return Network.fetch(
      ENDPOINTS.onwerList.url(page),
      {
        method: ENDPOINTS.onwerList.method,
      },
      true
    );
  }

  // onetransaction payment
  static ExportProfiles() {
    return Network.fetch(
      ENDPOINTS.exportProfiles.url,
      {
        method: ENDPOINTS.exportProfiles.method,
      },
      true
    );
  }

  static printContract(loanId, contentType) {
    return Network.fetch(
      ENDPOINTS.printContract.url(loanId),
      {
        method: ENDPOINTS.printContract.method,
      },
      true,
      contentType
    );
  }
  // User statment
  static CertificationData(user_id) {
    return Network.fetch(
      ENDPOINTS.CertificationData.url(user_id),
      {
        method: ENDPOINTS.CertificationData.method,
      },
      true
    );
  }

  static collection_penalties(
    user_id,
    created,
    amount,
    due_date,
    remaining_dues,
    on_hold
  ) {
    return Network.fetch(
      ENDPOINTS.collection_penalties.url(
        user_id,
        created,
        amount,
        due_date,
        remaining_dues,
        on_hold
      ),
      {
        method: ENDPOINTS.collection_penalties.method,
      },
      true
    );
  }
  // Printing Templates

  static templates_id() {
    return Network.fetch(
      ENDPOINTS.templates_id.url,
      {
        method: ENDPOINTS.templates_id.method,
      },
      true
    );
  }
  static statement(user_id) {
    return Network.fetch(
      ENDPOINTS.statement.url(user_id),
      {
        method: ENDPOINTS.statement.method,
      },
      true
    );
  }
  static cash_collection_oneTxn(user_id, dates, paymentValue) {
    let values = {
      selected_due_dates: dates,
      settlement_amount: paymentValue,
    };
    return Network.fetch(
      ENDPOINTS.cash_collection_oneTxn.url(user_id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.cash_collection_oneTxn.method,
      },
      true
    );
  }
  static update_penalties_oneTxn(
    user_id,
    waiveIds,
    waivePercentage,
    waiveExpiry
  ) {
    let values = {
      penalties: waiveIds,
      waive_percentage: waivePercentage,
      waive_percentage_expiry: waiveExpiry,
    };
    return Network.fetch(
      ENDPOINTS.update_penalties_oneTxn.url(user_id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.update_penalties_oneTxn.method,
      },
      true
    );
  }
  static waive_penalties_oneTxn(user_id, waiveIds, waivePercentage) {
    let values = {
      penalties: waiveIds,
      waive_percentage: waivePercentage,
    };
    return Network.fetch(
      ENDPOINTS.waive_penalties_oneTxn.url(user_id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.waive_penalties_oneTxn.method,
      },
      true
    );
  }
  static hold_penalties_oneTxn(user_id, ids) {
    return Network.fetch(
      ENDPOINTS.hold_penalties_oneTxn.url(user_id),
      {
        body: JSON.stringify({ penalties: ids }),
        method: ENDPOINTS.hold_penalties_oneTxn.method,
      },
      true
    );
  }
  static unhold_penalties_oneTxn(user_id, ids) {
    return Network.fetch(
      ENDPOINTS.unhold_penalties_oneTxn.url(user_id),
      {
        body: JSON.stringify({ penalties: ids }),
        method: ENDPOINTS.unhold_penalties_oneTxn.method,
      },
      true
    );
  }
  static advanced_payment_oneTxn(user_id) {
    return Network.fetch(
      ENDPOINTS.advanced_payment_oneTxn.url(user_id),
      {
        method: ENDPOINTS.advanced_payment_oneTxn.method,
      },
      true
    );
  }
  static statement_details(user_id) {
    return Network.fetch(
      ENDPOINTS.statement_details.url(user_id),
      {
        method: ENDPOINTS.statement_details.method,
      },
      true
    );
  }
  static loan_statement(user_id, loan_id) {
    return Network.fetch(
      ENDPOINTS.loan_statement.url(user_id, loan_id),
      {
        method: ENDPOINTS.loan_statement.method,
      },
      true
    );
  }
  static loan_statement_fines(user_id, loan_id) {
    return Network.fetch(
      ENDPOINTS.loan_statement_fines.url(user_id, loan_id),
      {
        method: ENDPOINTS.loan_statement_fines.method,
      },
      true
    );
  }
  static user_template(user_id, unique_name) {
    return Network.fetch(
      ENDPOINTS.user_template.url(user_id, unique_name),
      {
        method: ENDPOINTS.user_template.method,
      },
      true
    );
  }
  // onboarding merchant
  static updateBrandsOwners(data) {
    return Network.fetch(
      ENDPOINTS.updateBrandsOwners.url,
      {
        body: JSON.stringify(data),
        method: ENDPOINTS.updateBrandsOwners.method,
      },
      true
    );
  }

  //start .. Iscore tool
  static getIscoreSearch(
    pageNumber,
    nationa_id,
    iscoreState,
    from,
    to,
    initiatorSelected,
    reportLanguage,
    param
  ) {
    return Network.fetch(
      ENDPOINTS.searchIscore.url(
        pageNumber,
        nationa_id,
        iscoreState,
        from,
        to,
        initiatorSelected,
        reportLanguage,
        param
      ),
      {
        method: ENDPOINTS.searchIscore.method,
      },
      true
    );
  }
  static iscoreInitiators() {
    return Network.fetch(
      ENDPOINTS.iscoreInitiators.url,
      {
        method: ENDPOINTS.iscoreInitiators.method,
      },
      true
    );
  }
  static importIscore() {
    return Network.fetch(
      ENDPOINTS.importIscores.url,
      {
        method: ENDPOINTS.importIscores.method,
      },
      true
    );
  }
  static download_report(ids) {
    return Network.fetch(
      ENDPOINTS.download_report.url,
      {
        method: ENDPOINTS.download_report.method,
        body: JSON.stringify({ ids }),
      },
      true
    );
  }
  //end .. Iscore tool

  static getOfferOverView(page, search) {
    let params = search ? `page=${page}&search=${search}` : `page=${page}`;

    return Network.fetch(
      ENDPOINTS.offerOverView.url(params),
      {
        method: ENDPOINTS.offerOverView.method,
      },
      true
    );
  }

  static oneTransactionManSalese() {
    return Network.fetch(
      ENDPOINTS.onTransactionSalesAgent.url,
      {
        method: ENDPOINTS.onTransactionSalesAgent.method,
      },
      true
    );
  }
}
