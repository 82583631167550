import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import Modal from "react-modal";
import "./Contracts.scss";
import { customStyles, StyledTableCell, StyledTableRow } from "../../constants/Colors";
import PaginationComponent from "../pagination/PaginationComponent";
import ContractHistory from "../../pages/contractHistory/ContractHistory";
import { UserUtility } from "../../apis/UserUtility";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from "moment";
import UserContext from "../../hooks/userContext";
const filtersArray = [
  {
    filterName: "Not collected",
    filterTag: "not_collected",
    filterState: false,
  },
  {
    filterName: "Scheduled for pickup",
    filterTag: "scheduled_for_pickup",
    filterState: false,
  },
  {
    filterName: "Received by operation",
    filterTag: "received",
    filterState: false,
  },
  {
    filterName: "Delivered to Risk",
    filterTag: "delivered_to_risk",
    filterState: false,
  },
  {
    filterName: "Received by Risk",
    filterTag: "received_by_risk",
    filterState: false,
  },

  {
    filterName: "Delivered to Archived",
    filterTag: "delivered_to_archive",
    filterState: false,
  },
  {
    filterName: "Received by Archived",
    filterTag: "received_by_archive",
    filterState: false,
  },
  {
    filterName: "Archived",
    filterTag: "archived",
    filterState: false,
  },
  {
    filterName: "Rejected",
    filterTag: "rejected",
    filterState: false,
  },
];

const Contracts = () => {
  const [searchValue, setSearchValue] = useState("");
  const [makersList, setMakersList] = useState([]);
  const [maker, setMaker] = useState("");
  const [contracts, setContracts] = useState();
  const [filters, setFilters] = useState(filtersArray);
  const [page, setPage] = useState(1);
  const [statusChanged, setStatusChanged] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [contractId, setContractId] = useState();
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [isRejectState, setIsReject] = useState({ isReject: false, obj: null });
  const [questionReject, setQuestionReject] = useState();
  const [questionError, setQuestionError] = useState("");
  const [rejectComment, setRejectComment] = useState()


  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    getMakersSelectList();
  }, []);
  useEffect(() => {
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  //to generate a string to send it to the BE to filter
  const statusArr = statusChanged
    ? filters.map((res) =>
      res.filterState ? `${res.filterTag}=true` : `${res.filterTag}=false`
    )
    : [];
  const status = statusArr.join("%26");
  const getContracts = () => {
    const created_after = moment(dateFrom).format("YYYY-MM-DD");
    const created_before = moment(dateTo).format("YYYY-MM-DD");
    handleLoader(true);
    UserUtility.contractList(
      page,
      searchValue,
      maker,
      status,
      created_after,
      created_before
    )
      .then((res) => {
        setContracts(res);
        handleLoader(false);
      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  };
  const getMakersSelectList = () => {
    UserUtility.makersSelectList()
      .then((res) => {
        setMakersList(res.results);
      })
      .catch((error) => console.error(error));
  };

  const contractsSearch = (e) => {
    e.preventDefault();
    if (page === 1) {
      getContracts()
    } else {
      setPage(1)
    }
  };

  const handleActivePlan = (index) => {
    //to add or remove checkbox as UI
    const newFilters = filters.map((filterInfo, id) => {
      return {
        ...filterInfo,
        filterState:
          index === id ? !filterInfo.filterState : filterInfo.filterState,
      };
    });
    setFilters(newFilters);
    setStatusChanged(true);
    //to check if all filters not selected
    setStatusChanged(
      !newFilters.every((filter) => filter.filterState === false)
    );
  };

  //handle contract status
  const handleContractStauts = (contractStauts) => {
    const statusToLowerCase = contractStauts.toLowerCase();
    switch (statusToLowerCase) {
      case "rejected":
        return <span className="status_rejected">Rejected</span>;
      case "delivered_to_risk":
        return <span className="status_delivered">Delivered to risk</span>;
      case "received":
        return <span className="status_delivered">Received by operation</span>;
      default:
        return (
          <span style={{ textTransform: "capitalize" }}>
            {contractStauts.split("_").join(" ")}
          </span>
        );
    }
  };

  //send action to BE
  const sendContractAction = (actionName, contractId, courierId) => {
    UserUtility.sendContractAction(
      actionName,
      contractId,
      courierId,
      questionReject,
      rejectComment
    )
      .then((res) => {
        getContracts();
        if (actionName === "reject") {
          setIsReject({ isReject: false, obj: null });
          setQuestionReject();
          setRejectComment()
        }
      })
      .catch((error) => console.error(error));
  };

  //handle action button
  const actionButton = (actionName, status, contractId, courierId, id) => {
    switch (actionName) {
      // in case action deliver_to_risk and status is rejected then change button name to Send back to risk
      case "deliver_to_risk":
        return (
          <>
            {status === "rejected" ? (
              <div className="action_btn_div">
                <Button
                  key={id}
                  className="action_Btn"
                  type="submit"
                  variant="contained"
                  onClick={() =>
                    sendContractAction(actionName, contractId, courierId)
                  }
                >
                  Send back to risk
                </Button>
              </div>
            ) : (
              <div className="action_btn_div">
                <Button
                  key={id}
                  className="action_Btn"
                  type="submit"
                  variant="contained"
                  onClick={() =>
                    sendContractAction(actionName, contractId, courierId)
                  }
                >
                  {actionName.split("_").join(" ")}
                </Button>
              </div>
            )}
          </>
        );
      default:
        return (
          <Button
            key={id}
            className={actionName === "reject" ? "reject_btn" : "action_Btn"}
            type="submit"
            variant={actionName === "reject" ? "outlined" : "contained"}
            onClick={() => {
              if (actionName === "reject") {
                let obj = {
                  actionName: actionName,
                  contractId: contractId,
                  courierId: courierId,
                };
                setQuestionReject();
                setIsReject({ isReject: true, obj: obj });
              } else {
                sendContractAction(actionName, contractId, courierId);
              }
            }}
          >
            {actionName.split("_").join(" ")}
          </Button>
        );
    }
  };

  //handle contract history modal
  const handleReport = (id) => {
    // navigate("contractHistory", {
    //   state: {
    //     contractId: id,
    //   },
    // });

    setContractId(id);
  };

  const closeModal = () => {
    setContractId("");
  };

  // reject handle
  const handleRadioChange = (event) => {
    setQuestionReject(event.target.value);
    setQuestionError("");
  };
  const handleSubmitRejectForm = (event) => {
    event.preventDefault();
    if (!questionReject) {
      setQuestionError("Please select an option.");
    } else {
      sendContractAction(
        isRejectState.obj.actionName,
        isRejectState.obj.contractId,
        isRejectState.obj.courierId
      );
    }
  };
  return (
    <main className="contract_container">
      {(permissions.includes('contract_operations_risk') || permissions.includes('contract_operations_archive') || permissions.includes('contract_operations')) ?
        <>
          {/* search component */}
          <article>
            <form>
              <article className="searchBar_wrapper">
                <section className="search_fields_wrapper">
                  <TextField
                    size="small"
                    placeholder="Search by client's National ID"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    className="text-field"
                    label="National ID"
                  />
                  <span className="or_span"> Or </span>
                  <FormControl className="select-field">
                    <InputLabel>Maker</InputLabel>
                    <Select
                      size="small"
                      id="demo-simple-select"
                      value={maker}
                      label="Maker"
                      onChange={(e) => setMaker(e.target.value)}
                    >
                      {makersList?.map((eachMaker, id) => {
                        return (
                          <MenuItem value={eachMaker.id} key={id}>
                            {eachMaker.email + "|" + eachMaker.phone}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <span className="or_span"> Or </span>
                  <article className="date_container">
                    <section className="date_wrapper">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="from"
                          // openTo="year"
                          // views={["month", "day"]}
                          inputFormat="dd/MM/yyyy"
                          value={dateFrom ? new Date(dateFrom) : null}
                          onChange={(newValue) => {
                            setDateFrom(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </section>
                    <section className="date_wrapper">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="to"
                          value={dateTo ? new Date(dateTo) : null}
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            setDateTo(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </section>
                  </article>
                </section>
                <Button
                  className="searchButton"
                  type="submit"
                  variant="contained"
                  onClick={(e) => contractsSearch(e)}
                >
                  Search
                </Button>
              </article>
              {/* filters container */}
              <section className="filters_container">
                {filters.map((filter, id) => {
                  return (
                    <section key={id}>
                      <button
                        onClick={() => handleActivePlan(id)}
                        className="checkBtn"
                      >
                        <Checkbox size="small" checked={filter.active} />
                      </button>
                      <span className="filer_name">{filter.filterName}</span>
                    </section>
                  );
                })}
              </section>
            </form>
          </article>
          {/* table container */}
          <TableContainer
            component={Paper}
            style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>ID Number</StyledTableCell>
                  <StyledTableCell>Activation Point</StyledTableCell>
                  {/* <StyledTableCell>Operation</StyledTableCell> */}
                  <StyledTableCell>Courier</StyledTableCell>
                  <StyledTableCell>Maker</StyledTableCell>
                  <StyledTableCell>Created Date</StyledTableCell>
                  <StyledTableCell>Contract Status</StyledTableCell>
                  <StyledTableCell>Finance Limit</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts?.results?.map((row, id) => (
                  <StyledTableRow key={id}>
                    <StyledTableCell component="th" scope="row">
                      {!permissions.includes("contracts_view_only") ? (
                        <button
                          className="generateReportBtn"
                          onClick={() => handleReport(row.id)}
                        >
                          {row.name}
                        </button>
                      ) : (
                        <> {row.name} </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{row.id_number}</StyledTableCell>
                    <StyledTableCell>{row.kiosk_title}</StyledTableCell>
                    {/* <StyledTableCell>{row.operation}</StyledTableCell> */}
                    <StyledTableCell>{row.courier_name}</StyledTableCell>
                    <StyledTableCell>{row.maker}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.created).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {handleContractStauts(row.status)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* {handleContractStauts(row.finance_limit)} */}
                      {row.finance_limit}
                    </StyledTableCell>
                    <StyledTableCell style={{ paddingRight: 0, paddingLeft: 0 }}>
                      <div className="d-flex justify-content-center">
                        {row.status !== "not_collected" &&
                          row.status !== "memo_delivered"
                          ? row.action?.map((action, id) =>
                            actionButton(
                              action,
                              row.status,
                              row.id,
                              row.courier,
                              id
                            )
                          )
                          : null}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationComponent
            brands={contracts}
            page={page}
            handleChange={handleChange}
            total={true}
          />
          {contractId && (
            <div>
              <Modal
                isOpen={contractId}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="user history"
                shouldCloseOnOverlayClick={true}
              >
                <ContractHistory contractId={contractId} />
              </Modal>
            </div>
          )}

          {/* reject modal questions */}
          {isRejectState.isReject && (
            <div>
              <Modal
                isOpen={isRejectState.isReject}
                onRequestClose={() => setIsReject({ obj: null, isReject: false })}
                style={{
                  ...customStyles,
                  content: {
                    ...customStyles.content,
                    height: "auto",
                    width: "40%",
                  },
                }}
                contentLabel="Rejection reason"
                shouldCloseOnOverlayClick={true}
              >
                <div className="model_qs_reject">
                  <form onSubmit={handleSubmitRejectForm}>
                    <FormControl error={questionError}>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Rejection reason
                      </FormLabel>
                      <div className="flex_div">
                        <div>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="wrong in customer data in contract or system"
                              control={<Radio />}
                              label="wrong in customer data in contract or system"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="wrong in car license or club id"
                              control={<Radio />}
                              label="wrong in car license or club id"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Invalid national ID"
                              control={<Radio />}
                              label="Invalid national ID"
                              onChange={handleRadioChange}
                            />

                            <FormControlLabel
                              value="Wrong in customer signature"
                              control={<Radio />}
                              label="Wrong in customer signature"
                              onChange={handleRadioChange}
                            />

                            <FormControlLabel
                              value="Missing docs."
                              control={<Radio />}
                              label="Missing docs."
                              onChange={handleRadioChange}
                            />
                          </RadioGroup>
                          <FormHelperText>{questionError}</FormHelperText>
                        </div>
                        <div>
                          <TextField
                            className="text-field"
                            multiline
                            minRows={6}
                            label="Add Comment"
                            value={rejectComment}
                            onChange={(e) => setRejectComment(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="btn_modal_div">
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() => {
                            setQuestionReject();
                            setIsReject({ obj: null, isReject: false });
                          }}
                        >
                          Cancel
                        </Button>

                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </div>
                    </FormControl>
                  </form>
                </div>
              </Modal>
            </div>
          )}
        </>
        :
        <div>
          <h6 className="permission_style">You don't have permission to see this page</h6>
        </div>
      }
    </main>
  );
};

export default Contracts;
