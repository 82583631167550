import React, { useContext, useEffect, useState } from "react";
import "./requestsFailure.scss";
import RequestsCardsFilters from "../../components/RequestsFailure/RequestsCardsFilters/RequestsCardsFilters";
// import FilterButtonList from '../../components/RequestsFailure/FilterButtonList/FilterButtonList'
import { Button, Form } from "react-bootstrap";
import TableData from "../../components/RequestsFailure/TableData/TableData";
// import PaginationComponent from '../../components/pagination/PaginationComponent'
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import {
  PrimaryHeader,
  LoansHeaders,
  CustomersHeaders,
  VendorsHeaders,
  InstallmentPaymentHeaders,
  CreateInstallmentsHeaders,
  EarlyHeaders,
  PenaltyHeaders,
} from "./HeadersData";
import { ENDPOINTS } from "../../apis/EndPoints";
import { toast } from "react-toastify";

const RequestsFailure = () => {
  const { handleLoader } = useContext(UserContext);
  const permissions = {
    loans: "loanss",
    customers: "customers",
    vendor: "vendor",
    payment: "payment",
    installment: "installment",
    Early: "Early",
    Penalty: "Penalty",
  };

  const [checkedLoan, setCheckedLoan] = useState(new Set());
  const values = [...checkedLoan];
  const requestID = values?.map((item) => item.payment_subscriber?.id);
  const integrationIds = values?.map((item) => item?.integrationrequestlog);

  const [activeCard, setActiveCard] = useState("");
  const [AllDataFromDB, setAllDataFromDB] = useState([]);
  const [filterTopics, setFilterTopics] = useState("");
  const [contentType, setContentType] = useState();
  const [startDate, setStartDateByFilter] = useState("");
  const [endDate, setEndDateByFilter] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rerender, setRerender] = useState("");
  const validPhone = phoneNumber.match(/^\d+$/);

  // const [page, setPage] = useState(1);
  // const [Refund, setRefund] = useState("")
  // const [RefundBlock, setRefundBlock] = useState("")
  // const [reRunEffect, setReRunEffect] = useState(0);
  const Itemcount = AllDataFromDB ? AllDataFromDB?.count : 0;

  let token = localStorage.getItem("tools_token");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `token ${token}`);

  const CardsFilterTopics = [
    {
      CardTitle: "Loans",
      title: "loans",
      itemCount: { itemName: "loans", length: Itemcount },
      isPermissions: permissions.loans === "loanss" ? true : false,
    },
    {
      CardTitle: "Customers",
      title: "customers",
      itemCount: { itemName: "customers", length: Itemcount },
      isPermissions: true,
    },
    {
      CardTitle: "Vendor liability",
      title: "Vendor_liability_requests",
      itemCount: { itemName: "Vendor_liability_requests", length: Itemcount },
      isPermissions: true,
    },
    {
      CardTitle: "Installment Payment",
      title: "installment_payment",
      itemCount: { itemName: "installment_payment", length: Itemcount },
      isPermissions: true,
    },
    {
      CardTitle: "Create Installments",
      title: "create_installments",
      itemCount: { itemName: "create_installments", length: Itemcount },
      isPermissions: true,
    },
    {
      CardTitle: "Early Settlement",
      title: "Early",
      itemCount: { itemName: "Early", length: Itemcount },
      isPermissions: permissions.Early === "Early" ? true : true,
    },
    {
      CardTitle: "Penalty  payment",
      title: "Penalty",
      itemCount: { itemName: "Penalty", length: Itemcount },
      isPermissions: permissions.Penalty === "Penalty" ? true : true,
    },
  ];
  const t = CardsFilterTopics.map((it) => it.CardTitle);
  console.log(t);

  //handle title table
  const handleTitles = (isTopic) => {
    switch (isTopic) {
      case "loans":
        return "Loans";
        break;
      case "customers":
        return "Customers";
        break;
      case "Vendor_liability_requests":
        return "Vendor liability";
        break;
      case "installment_payment":
        return "Installment Payment";
        break;
      case "create_installments":
        return "Create Installments";
        break;
      case "Early":
        return "Early Settlement";
        break;
      case "Penalty":
        return "Penalty  payment";
        break;
      default:
        return "Loans";
        break;
    }
  };
  const handleHeaders = (isTopic) => {
    switch (isTopic) {
      case "loans":
        return LoansHeaders;
        break;
      case "customers":
        return CustomersHeaders;
        break;
      case "Vendor_liability_requests":
        return VendorsHeaders;
        break;
      case "installment_payment":
        return InstallmentPaymentHeaders;
        break;
      case "create_installments":
        return CreateInstallmentsHeaders;
        break;
      case "Early":
        return EarlyHeaders;
        break;
      case "Penalty":
        return PenaltyHeaders;
        break;
      default:
        return LoansHeaders;
        break;
    }
  };

  //handle pagination page
  // const handleChange = (e, value) => {
  //     setPage(value);
  // };

  function handleStartDateChange(event) {
    setStartDateByFilter(event.target.value);
  }
  function handleEndDateChange(event) {
    setEndDateByFilter(event.target.value);
  }

  const handleActiveCard = (title) => {
    setActiveCard(title);
    setStartDateByFilter("");
    setEndDateByFilter("");
    setPhoneNumber("");
  };

  console.log(filterTopics);
  console.log(handleHeaders(filterTopics));
  console.log(activeCard);
  console.log(checkedLoan);
  console.log(contentType);
  console.log(`${phoneNumber},${contentType}`);
  console.log(requestID);
  console.log(integrationIds);

  const handleSearch = () => {
    const searchParams = new URLSearchParams();
    if (startDate) {
      searchParams.append("created__gte", startDate);
    }
    if (endDate) {
      searchParams.append("created__lte", endDate);
    }
    if (phoneNumber !== "") {
      if (validPhone) {
        searchParams.append(`phone`, `${phoneNumber},${contentType}`);
      } else {
        toast.error(
          "We will not enter the mobile number in the search process (contains text)"
        );
      }
    }

    // if (page) {
    //     formdata.append("page", page);
    // }
    // formdata.append("refund", Refund);
    // formdata.append("refundBlock", RefundBlock);
    // formdata.append("brand", Brand);

    handleLoader(true);
    UserUtility.Failure_Requests(
      filterTopics.toString(),
      `${filterTopics.toString() ? `?${searchParams.toString()}` : ``}`
    )
      .then((res) => {
        handleLoader(false);
        setAllDataFromDB(res);
      })
      .catch((err) => {
        handleLoader(false);
        console.error("Failure_List_Not_Avall test", err);
        toast.error(err);
      });
  };

  // const handleSearch = () => {
  //     if (page === 1) {
  //         setReRunEffect(reRunEffect + 1)
  //     } else {
  //         setPage(1)
  //     }
  // }

  // useEffect(() => {
  //     if (filterTopics) {
  //         setPage(1)
  //     }
  // }, [filterTopics])
  useEffect(() => {
    setCheckedLoan(new Set());
    const searchParams = new URLSearchParams();
    // if (page && page !== 1) {
    //     searchParams.set("page", page)
    // }

    if (activeCard !== "") {
      handleLoader(true);
      UserUtility.Failure_Requests(
        filterTopics.toString(),
        `${searchParams.toString() ? `?${searchParams.toString()}` : ``}`
      )
        .then((res) => {
          handleLoader(false);
          setAllDataFromDB(res);
          const content_type =
            res && res.results.map((ele) => ele.content_type);
            console.log('res.results: ',res.results)
            if(content_type){
                setContentType(content_type[0]);
            }
         
        })
        .catch((err) => {
          handleLoader(false);
          console.error("Failure_List_Not_Avall test2", err);
          toast.error(err);
        });
    }
  }, [filterTopics, rerender]);

  const handleResend = (ids, integrationIds) => {
    var formdata = new FormData();
    formdata.append(`ids`, ids);
    formdata.append(`integration_ids`, integrationIds);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    handleLoader(true);

    fetch(
      ENDPOINTS.failure_requests.url_post(`${filterTopics}`),
      requestOptions
    )
      .then(async (res) => {
        handleLoader(false);
        if (!res.ok) {
          const responseJson = await res.json();
          const errorMessage = responseJson.errors;
          toast.error(`Error: ${errorMessage}`);
          throw new Error(errorMessage);
        } else {
          handleLoader(false);
          toast.success(
            `Request for ${handleTitles(
              filterTopics
            )} resending to Oracle is processed successfully.`
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("edit Draft error:: ", error);
        toast.error(error);
        handleLoader(false);
      });
  };

  return (
    <div className="failure_container my-5 mx-3 container">
      <div className="header">
        <p>Requests Failure</p>
        <span>Select View</span>
      </div>

      <div className="card_types">
        {CardsFilterTopics?.map((topic) => {
          return (
            <RequestsCardsFilters
              title={topic.title}
              CardTitle={topic.CardTitle}
              isPermissions={topic.isPermissions}
              item={topic.itemCount}
              setFilterTopics={setFilterTopics}
              handleActiveCard={handleActiveCard}
              activeCard={activeCard}
            />
          );
        })}
      </div>

      <div className="filter_bottons">
        {/* <span style={{ width: "100px" }}>Filter By :</span> */}
        {/* <FilterButtonList title={"Refund"} options={5} setFilterVal={setRefund} />
                <FilterButtonList title={"Refund Block"} options={5} setFilterVal={setRefundBlock} />
                <FilterButtonList title={"Brand"} options={5} setFilterVal={setBrand} /> */}
        <Form.Control
          className="select_onBoard me-2 w-25"
          type="text"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          placeholder="Phone number"
        />
        <label style={{ width: "100px", textAlign: "center" }}>
          Start date
        </label>
        <Form.Control
          className="select_onBoard me-2 w-25"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <label style={{ width: "100px", textAlign: "center" }}>End date</label>
        <Form.Control
          className="select_onBoard me-2 w-25"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
        />
        <Button
          className="small_btn"
          type="button"
          variant="contained"
          disabled={AllDataFromDB?.length !== 0 ? false : true}
          onClick={() => {
            if ((endDate !== "" && startDate !== "") || validPhone !== null) {
              handleSearch();
            } else if (phoneNumber !== "" && validPhone === null) {
              toast.error(
                "We will not enter the mobile number in the search process (contains text)"
              );
            } else {
              setRerender(rerender + 1);
            }
          }}
        >
          Search
        </Button>
      </div>

      <div className="table_data">
        <div className="table m-auto  mt-4">
          {AllDataFromDB?.length !== 0 ? (
            <TableData
              AllDataFromDB={AllDataFromDB}
              PrimaryHeader={PrimaryHeader}
              header={handleHeaders(filterTopics)}
              title={filterTopics}
              checkedLoan={checkedLoan}
              setCheckedLoan={setCheckedLoan}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#41b6a8",
                color: "#fff",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Please select your view first{" "}
            </div>
          )}

          {filterTopics !== "" && (
            <div>
              <Button
                className="small_btn my-4"
                type="button"
                variant="contained"
                onClick={() => {
                  values?.length !== 0
                    ? handleResend(requestID, integrationIds)
                    : toast.error("Please select one record at least.");
                }}
              >
                Send {handleTitles(filterTopics)}
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* <div className='pagination'>
                <PaginationComponent
                    brands={AllDataFromDB}
                    page={page}
                    handleChange={handleChange}
                    total={false}
                />
            </div> */}
    </div>
  );
};

export default RequestsFailure;
