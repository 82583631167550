import React from "react";
import Button from "@material-ui/core/Button";
// import { ToastContainer, toast } from "react-toastify";
import "./KioskAdded.scss";
import { FaCheckCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

export default function KioskAdded() {
  let location = useLocation();
  const {
    kioskType: kioskRole = "Maker/Checker",
    // phone = "Maker/Checker",
    username = "Maker/Checker",
    email = "Maker/Checker",
  } = location?.state;

  const direction = `/addKioskUser`;
  return (
    <div className="kiosk_container">
      <div className="search_result_container">
        <div className="transaction_cancelled_container">
          <div className="checkIcon">
            <FaCheckCircle style={{ width: "56px", height: "56px" }} />
          </div>
          <h4 className="search_header transaction_cancelled">
            {kioskRole} has been added successfully
          </h4>
          <div className="checkIcon">
            <Button className="searchButton" type="submit" variant="contained">
              <Link
                to={direction}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                Add another {kioskRole}
              </Link>
            </Button>
          </div>
          <div className="userDataContainer">
            <h4
              className="search_header userDataHeader"
              style={{ marginBottom: 0 }}
            >
              {kioskRole} Data:
            </h4>
            {/* <h5 className="userData">Phone: {phone}</h5> */}
            <h5 className="userData">Username: {username}</h5>
            <h5 className="userData">Email: {email}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

