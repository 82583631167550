import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import { StyledTableCell, StyledTableRow } from "../../../constants/Colors";

const OfferTable = ({
  list,
  onBtnClick,
  tableData,
  readOnly,
  inputClassName,
  btnChecked,
  inputType,
  months,
  handlePlanNumbers2,
  handlePlanNumbers,
  publishSwitch,
  isDisabled,
  handleBranchesSelectAll,
  isCheckedAll,
  // min_percentage
}) => {

  useEffect(() => {
  }, [isCheckedAll])
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {btnChecked === "active" &&
                <>
                  <StyledTableCell>
                    {/* <Checkbox
                      size="small"
                      value={isCheckedAll || false}
                      checked={list && list?.length > 0 ? isCheckedAll : false}
                      // disabled={brands?.length === 0}
                      disabled={isDisabled}
                      onChange={handleBranchesSelectAll}
                    /> */}

                    <input type={'checkbox'} value={isCheckedAll || false} onChange={handleBranchesSelectAll}
                      checked={list && list?.length > 0 ? isCheckedAll : false}
                      disabled={isDisabled}
                       />
                  </StyledTableCell>
                </>
              }
              {months && <StyledTableCell>No of months</StyledTableCell>}

              {(tableData && tableData?.length > 0) && tableData?.map((item, index) => (
                <StyledTableCell key={index}>{item?.title}</StyledTableCell>
              ))}

              {btnChecked === "published" && (
                <StyledTableCell>Publish</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(list && list?.length > 0) && list?.map((row, id) => (
              <>
                <StyledTableRow key={id}>
                  {btnChecked === "active" && (
                    <>
                      <StyledTableCell
                        style={{ borderRight: "1px dashed #707070" }}
                      >
                        {/* <button onClick={() => onBtnClick(row?.id)} className="checkBtn"> */}
                        <Checkbox size="small" checked={row[btnChecked]} disabled={isDisabled} onChange={() => onBtnClick(row?.id)} />
                        {/* </button> */}
                      </StyledTableCell>
                    </>
                  )}
                  {months && <StyledTableCell>{row?.months}</StyledTableCell>}
                  {(tableData && tableData?.length > 0) && tableData?.map((item, index) => (
                    <>
                      <StyledTableCell key={index}>
                        {item?.defaultValue1 === 'total' ?
                          <span className="total_flag" style={{ backgroundColor: (row?.total >= row?.min_percentage) ? "#f7a46b" : "#e74c3c" }}>{row[item?.defaultValue1]}</span>
                          :
                          <input
                            title={
                              item?.defaultValue2
                                ? row[item?.defaultValue1][item?.defaultValue2]
                                : item?.defaultValue1 ? row[item?.defaultValue1] : ""
                            }
                            step="any"
                            type={item?.inputType}
                            key={index}
                            className={`${inputClassName} ${item?.defaultValue1 === "email" ||
                              item?.defaultValue1 === "location_link"
                              ? "tableShowInput--lg"
                              : ""
                              }`}
                            defaultValue={
                              item?.defaultValue2
                                ? row[item?.defaultValue1][item?.defaultValue2]
                                : row[item?.defaultValue1]
                            }
                            readOnly={readOnly}
                            onChange={
                              readOnly
                                ? null
                                : item?.defaultValue2
                                  ? (e) =>
                                    handlePlanNumbers2(
                                      id,
                                      item?.defaultValue1,
                                      item?.defaultValue2,
                                      e
                                    )
                                  : (e) => handlePlanNumbers(id, item?.defaultValue1, e)
                            }
                            disabled={
                              isDisabled || row.published || item.defaultValue1 === 'grace_period'
                            }
                          />
                        }
                      </StyledTableCell>
                    </>
                  ))}
                  {btnChecked === "published" && (
                    <StyledTableCell>
                      {publishSwitch(row.published, row)}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OfferTable;
