export const PrimaryHeader = [
    { header_names: "Select All" , isSelect:true},
    { header_names: "Failure reason" },
    { header_names: "Failure Date/Time"},

]

export const LoansHeaders = [
    // { header_names: "Loan application number", fieldName: "" },
    { header_names: "Loan application number"},
    { header_names: "Installment Plan"},
    { header_names: "Created date"},
    { header_names: "Modified date"},
    { header_names: "User phone number"},
    { header_names: "User national id" },
    { header_names: "Brand name"},
    { header_names: "Branch name"},
    { header_names: "Product label"},
    { header_names: "Principal amount"},
    { header_names: "Total due amount"},
    { header_names: "Interest rate"},
    { header_names: "Approved"},
    { header_names: "Refunded"},
];

export const CustomersHeaders = [
    { header_names: "User full name" },
    { header_names: "User phone number" },
    { header_names: "User national ID" },
    { header_names: "Governorate" },
    { header_names: "Area" },
]

export const VendorsHeaders = [
    { header_names: "Branch name" },
    { header_names: "Brand name" },
    { header_names: "Loan Application Number" },
    { header_names: "Created date" },
]

export const InstallmentPaymentHeaders = [
    
    { header_names: "Loan" },
    { header_names: "Due Date" },
    { header_names: "Loan Application Number" },
    { header_names: "Created" },
    { header_names: "Amount" },
    { header_names: "Interest" },
    { header_names: "Principal" },
    { header_names: "Paid Amount" },
]

export const CreateInstallmentsHeaders = [

    // { header_names: "Loan application number" },
    // { header_names: "Installment due date" },
    // { header_names: "Created" },
    // { header_names: "Payment Method" },
    // { header_names: "Amount" },
    // { header_names: "Interest Amount" },
    // { header_names: "Principal Amount" },
    // { header_names: "Paid Amount" },
    { header_names: "Loan application number"},
    { header_names: "Installment Plan"},
    { header_names: "Created date"},
    { header_names: "Modified date"},
    { header_names: "User phone number"},
    { header_names: "User national id" },
    { header_names: "Brand name"},
    { header_names: "Branch name"},
    { header_names: "Product label"},
    { header_names: "Principal amount"},
    { header_names: "Total due amount"},
    { header_names: "Interest rate"},
    { header_names: "Approved"},
    { header_names: "Refunded"},
    

]

export const EarlyHeaders = [

    { header_names: "Payment Method" },
    { header_names: "Paid date time" },
    { header_names: "Transaction id" },
    { header_names: "Amount" },
    { header_names: "Total interest" },
    { header_names: "National id" },
    { header_names: "User oracle identifier" },
    { header_names: "Sent conv fees" },
    { header_names: "Paid" },
]
export const PenaltyHeaders = [

    { header_names: "Loan application number" },
    { header_names: "Customer Name" },
    { header_names: "National id" },
    { header_names: "Phone Number" },
    { header_names: "Penalty Amount" },
    { header_names: "Installment due date" },
    { header_names: "Installment Amount" },
    { header_names: "Created" },
    { header_names: "Installment number" },
    { header_names: "Paid" },
    
]
