import { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { toast } from "react-toastify"
import { OneTransaction } from "."
import { UserUtility } from "../../apis/UserUtility"
import { ApplicationFormComponent } from "../../components/OneTransactionComponent/ApplicationFormComponent"
import OneTransactionContext from "../../hooks/oneTransactionContext"
import { Credit } from "./Credit"
import { CreditForm } from "./CreditForm"
import { DataEntry } from "./DataEntry"
import { FinanceOneTransaction } from "./FinanceOneTransaction"
import { OperationOneTransaction } from "./OperationOneTransaction"
import CollectionList from "./Collection/CollectionList"
import CollectionContainer from "./Collection/CollectionContainer"


export const OneTransactionRoute = () => {
  const [oneTransactionOptionResult, setOneTransactionOptionResult] =
    useState();
  const [oneTransactionOtherOptionResult, setOneTransactionOtherOptionResult] =
    useState();
  const [programs, setPrograms] = useState([]);

  //to verify if the user able to see makers component or not
  const getAllPrograms = () => {
    UserUtility.oneTransPrograms()
      .then((res) => {
        setPrograms(res);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((err) => {
        if (err.detail) {
          toast.error("Programs " + err.detail);
        }
      });
  };
  const paginationFun = (nextUrl) => {
    UserUtility.getNextPageData(nextUrl, "")
      .then((res) => {
        setPrograms((old) => [...old, ...res.results]);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((err) => {
        if (err.detail) {
          toast.error("Programs " + err.detail);
        }
        console.log(err);
      });
  };

  // to get alll options in data entry to filter or used in dropdown application form
  const profileOptions = () => {
    UserUtility.oneTransProfileOptions().then((res) => {
      const result = res.actions.OPTIONS;
      setOneTransactionOptionResult(result);
    });
  };

  // to get alll options in (credit,operation,finance) to filter or used in dropdown application form
  const getAnotherOptions = () => {
    UserUtility.oneTransCreditOptions()
      .then((res) => {
        let options = res.actions.OPTIONS;
        setOneTransactionOtherOptionResult(options);
      })
      .catch((err) => {
        console.error("error of getAllApplications: ", err);
      });
  };

    useEffect(() => {
        getAllPrograms()
        profileOptions()
        getAnotherOptions()
    }, [])

    return (
        <OneTransactionContext.Provider
            value={{ setOneTransactionOptionResult, oneTransactionOptionResult, setPrograms, programs, setOneTransactionOtherOptionResult, oneTransactionOtherOptionResult }}
        >
            <Routes>
                <Route path="/oneTransaction" element={<OneTransaction />}>
                    <Route path="/oneTransaction/dataEntry" element={<DataEntry />}></Route>
                    <Route path="/oneTransaction/loans" element={<Credit />}></Route>
                    <Route path="/oneTransaction/operation" element={<OperationOneTransaction />}></Route>
                    <Route path="/oneTransaction/finance" element={<FinanceOneTransaction />}></Route>
                    <Route path="/oneTransaction/collection" element={<CollectionList />}></Route>
                </Route>
                <Route path="/oneTransaction/dataEntry/application" element={<ApplicationFormComponent />}></Route>
                <Route path="/oneTransaction/dataEntry/application/:user" element={<ApplicationFormComponent />}></Route>
                <Route path="/oneTransaction/credit/creditMemo" element={<CreditForm />}></Route>
                <Route path="/oneTransaction/credit/creditMemo/:transactionId" element={<CreditForm />}></Route>
                <Route path="/oneTransaction/collection/:user" element={<CollectionContainer />}></Route>

            </Routes>
        </OneTransactionContext.Provider>
    )

}

