import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const Colors = {
  // base colors
  primary: "#41B6A8", // green
  secondary: "#A7A7A7", // gray

  //colors
  darkGray: "#EDEDED",
  ligtGray: "#F5F5F5",
  red: "#B64141",
};

//table properties
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.primary,
    color: theme.palette.common.white,
    fontSize: 15,
    textAlign: "center",
    fontFamily: "Tajawal",
    fontWeight: 700,
    innerWidth:'20%'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: 7,
    paddingBottom: 7,
    textAlign: "center",
    fontFamily: "Tajawal",
    fontWeight: 500,
    innerWidth:'20%'
  },

}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: Colors.darkGray,
  },
  "&:nth-of-type(even)": {
    backgroundColor: Colors.ligtGray,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


// modal style 
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "95%",
    overflowY: "scroll",
    height: "85%",
  },
};