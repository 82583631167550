import { Button, Form, Modal } from "react-bootstrap";
import {
  AiOutlineHistory,
  AiOutlinePlus,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import "./NotificationStyle.scss";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { UserUtility } from "../../apis/UserUtility";

import { StyledTableCell, StyledTableRow, customStyles } from "../../constants/Colors";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../hooks/userContext";
// import Modal from 'react-modal'
import { toast } from "react-toastify";
import moment from "moment";

export const NotificationDashBoard = () => {
  let navigate = useNavigate();
  const [notificationList, setNotificationList] = useState();
  const [searchValue, SetSearchValue] = useState();
  const [total, setTotal] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [createdFrom, setCreatedFrom] = useState();
  const [createdTo, setCreatedTo] = useState();
  const [status, setStatus] = useState();
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState({ isOpen: false, id: null })


  const notification_list = () => {
    handleLoader(true)
    UserUtility.notification_list(pagesNumber, searchValue, status, createdFrom, createdTo)
      .then((res) => {
        handleLoader(false)
        setTotal(res);
        setNotificationList(res.results)
        // searchValue
        //   ? setNotificationList([res])
        //   : setNotificationList(res.results);
      })
      .catch((err) => {
        handleLoader(false)
        console.error("notification_list", err)
        toast.error(err?.detail || err)
      });
  };


  const delete_notification = (notificationId) => {
    setIsModalOpen({ isOpen: false, id: null })

    UserUtility.delete_notification(notificationId)
      .then(() => {
        notification_list();
      })
      .catch((err) => console.error("delete_notification", err));

  };
  const handleClick = (e) => {
    if (e === "Enter") {
      notification_list();
      setPagesNumber(1);
    }
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };

  const handleToEdit = (element) => {
    navigate('/notification/detail', {
      state: {
        notifyData: element
      }

    }
    )
  }
  useEffect(() => {
    notification_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber]);
  return (
    <main className="main_div">
      {/* head */}
      <div>
        <div className="flex_div">
          <div className="flex_div" style={{ width: "70%" }}>
            <div
              className="input_div"
              style={{ width: "75%", marginRight: 20 }}
            >
              <Form.Control
                onKeyPress={(e) => handleClick(e.key)}
                placeholder="Search by audience title"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="searchInput form_control_style"
                onChange={(e) => SetSearchValue(e.target.value)}
              />
              <BsSearch className="search_icon" />
            </div>

          </div>

          <div className="flex_div">
            <Button onClick={() => handleClick("Enter")} className="btn1">
              Search
            </Button>
          </div>
        </div>

        <div className="flex_div  mt-5">

          <div className="flex_div">
            <Form.Group
              controlId="formBasic"
            >
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form_control_style"
                onChange={(event) => setStatus(event.target.value)}
              >
                <option disabled selected>Status</option>
                <option value="All">All</option>
                <option value="pending">Pending</option>
                <option value="sent">Sent</option>
              </Form.Select>
            </Form.Group>

            {/* from */}
            <Form.Group controlId="duedate">
              <Form.Label>Created From</Form.Label>
              <Form.Control type="date" name="duedate" placeholder="Due date" className="form_control_style ml-3"
                onChange={(event) => setCreatedFrom(event.target.value)} />
            </Form.Group>

            {/* to */}
            <Form.Group controlId="duedate">
              <Form.Label>Created To</Form.Label>

              <Form.Control type="date" name="duedate" placeholder="Due date" className="form_control_style ml-3"
                onChange={(event) => setCreatedTo(event.target.value)} />
            </Form.Group>
          </div>
          {permissions.includes('notification_audience_create_edit') &&
            <div>
              {/* <Button className="btn2" style={{ marginRight: 16 }}>
              <AiOutlineHistory style={{ marginRight: 10 }} />
              History
            </Button> */}

              <Button
                className="btn3"
                onClick={() => navigate("/notification/detail")}
              >
                <AiOutlinePlus style={{ marginRight: 10 }} />
                Create Notification
              </Button>

            </div>
          }
        </div>
        {permissions.includes('notification_audience_create_edit') &&
          <div style={{ textAlign: 'end' }}>
            <Button
              className="btn3"
              onClick={() => navigate("/notification/audience/detail")}
            >
              <AiOutlinePlus style={{ marginRight: 10 }} />
              Create Audience
            </Button>
          </div>
        }
      </div>

      {/* table */}

      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table striped responsive hover sx={{ minWidth: 700 }} className="notify_table" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Notification ID</StyledTableCell>
              <StyledTableCell>Audience Title</StyledTableCell>
              <StyledTableCell>Audience ID</StyledTableCell>
              <StyledTableCell>Targeted audience No</StyledTableCell>

              <StyledTableCell>Schedule Time</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Create Date</StyledTableCell>
              <StyledTableCell>Broadcast</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {notificationList?.map((ele, index) => {
              return (
                <StyledTableRow key={index.toString()}>
                  <StyledTableCell>
                    {/* <Link
                      to={`/notification/detail`}
                      className="linkStyle"
                      state={{
                        notifyData: ele,
                      }}
                    > */}
                    {ele?.created_by}
                    {/* </Link> */}
                  </StyledTableCell>

                  <StyledTableCell>{ele.title} </StyledTableCell>
                  <StyledTableCell>{ele?.id || ' - '} </StyledTableCell>
                  <StyledTableCell>{ele?.audience?.name || ' - '} </StyledTableCell>
                  <StyledTableCell>{ele?.audience?.id || ' - '} </StyledTableCell>
                  <StyledTableCell>{ele?.count || ' - '} </StyledTableCell>
                  <StyledTableCell key={index.toString()}>
                    {ele?.scheduled_dates.map((item, index) => (
                      <p> {moment(item?.date).format('lll')}</p>
                    ))}
                  </StyledTableCell>

                  <StyledTableCell>
                    <span className="spn_flag">{ele?.status}</span>
                  </StyledTableCell>
                  <StyledTableCell>{ele?.created}</StyledTableCell>
                  
                  <StyledTableCell>
                    <Form.Check
                      type="checkbox"
                      checked={ele.broadcast}
                      disabled
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {(permissions.includes('notification_audience_create_edit') && !ele.broadcast) &&

                      <div className="flex_div">
                        <AiOutlineEdit style={{ cursor: "pointer" }}

                          onClick={() => handleToEdit(ele)}
                        />
                        <AiOutlineDelete
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsModalOpen({ isOpen: true, id: ele.id })}
                        />
                      </div>
                    }
                  </StyledTableCell>

                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={total}
        page={pagesNumber}
        handleChange={handleChange}
      />


      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel="user history"
        shouldCloseOnOverlayClick={true}
      >
        <p>Are you sure to delete this notification ?</p>
        <div className="flex_dev">
          <Button className="btn1">Yes</Button>
          <Button className="btn_outline">No</Button>
        </div>
      </Modal> */}




      {/* <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
      > */}
        <Modal
          show={isModalOpen.isOpen}
          onHide={() => setIsModalOpen({ isOpen: false, id: null })}
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal title</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure to delete this notification ?</p>
            <div className="flex2_div">
              <Button className="btn1 me-3" onClick={() => delete_notification(isModalOpen.id)}>Yes</Button>
              <Button className="btn_outline" onClick={() => setIsModalOpen({ isOpen: false, id: null })}>No</Button>
            </div>
          </Modal.Body>

        </Modal>
      {/* </div> */}
    </main>
  );
};
