import React, { useState } from "react";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import "./AddKiosk.scss";
import { UserUtility } from "../../apis/UserUtility";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const AddKiosk = ({ kioskHeader = "Maker/Checker" }) => {
  const [spinner, setSpinner] = useState(false);
  const [rolle, setRolle] = useState();
  let navigate = useNavigate();
  const createKiosk = (values) => {
    values["role"] = rolle;
    setSpinner(true);
    UserUtility.createKiosk(values)
      .then((res) => {
        setSpinner(false);
        navigate("/KioskAdded", {
          state: {
            kioskType: res.role,
            // phone: res.user.phone,
            username: res.username,
            email: res.email,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
        toast.error(error[0]);
      });
  };
  const validateYup = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
    first_name: Yup.string().min(4),
    last_name: Yup.string().min(4),
    // role: Yup.string().min(1),
  });
  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    // role: rolle,
  };
  const handlleChange = (event) => {
    setRolle(event.target.value);
  };
  return (
    <article className="createMaker_container">
      <article className="createMaker_wrapper">
        <h2 className="makerHeader">Create Kiosk User</h2>
        <section className="Form_container">
          <Formik
            initialValues={initialValues}
            validationSchema={validateYup}
            onSubmit={(values) => {
              createKiosk(values);
            }}
          >
            {({ values, errors, handleChange, handleSubmit, isValid }) => (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  required
                  name="email"
                  onChange={handleChange}
                  error={errors.email ? true : false}
                  label="Email"
                  defaultValue={values.email}
                  placeholder="example@mail.com"
                  className="textField"
                  focused
                />
                <TextField
                  required
                  name="first_name"
                  onChange={handleChange}
                  error={errors.first_name ? true : false}
                  label="First Name"
                  defaultValue={values.first_name}
                  placeholder="First Name"
                  className="textField"
                />

                <TextField
                  required
                  name="last_name"
                  onChange={handleChange}
                  error={errors.last_name ? true : false}
                  label="Last Name"
                  defaultValue={values.last_name}
                  placeholder="Last Name"
                  className="textField"
                />

                <FormControl className="formColtrol_select">
                  <InputLabel id="demo-simple-select-required-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={rolle || ""}
                    onChange={handlleChange}
                    // defaultValue={values.role || ""}
                    label={"Role *"}
                  >
                    <MenuItem value={"MAKER"}>Maker</MenuItem>
                    <MenuItem value={"CHECKER"}>Checker</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  className="submitBtn"
                  variant="contained"
                  disabled={
                    !isValid ||
                      !values.email ||
                      !values.first_name ||
                      !values.last_name ||
                      !rolle ||
                      spinner
                      ? true
                      : false
                  }
                  endIcon={spinner && <CgSpinner className="spinner" />}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </section>
      </article>
    </article>
  );
};
export default AddKiosk;

