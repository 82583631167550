import React, { useCallback, useEffect, useRef, useState } from "react";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import {
  Colors,
  StyledTableCell,
  StyledTableRow,
} from "../../constants/Colors";
import "./Daily.scss";
import { UserUtility } from "../../apis/UserUtility";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
const statistics_fields = {
  no_of_registered_user: 0,
  no_of_credit_limit_forms_filled: 0,
  credit_limit_forms_filled_per_signup: 0,
  no_of_customers_submit_docs: 0,
  percentage_of_customers_submit_docs: 0,
  pending_rejected_zerolimit: 0,
  activated_customers: 0,
  activated_customer_per_customers_submit_docs: 0,
  activated_customer_per_credit_limit_form_filled: 0,
  activated_limits: 0,
  avg_limit_per_customer: 0,
  no_of_transactions: 0,
  total_transactions: 0,
  avg_ticket_size_per_transaction: 0,
  no_of_merchants_on_board: 0,
  existing_areas: 0,
  current_o_s_portfolio: 0,
  unearned_or_deferred_interest_rev: 0,
  Portfolio_of_total_limits: 0,
  manual_study: 0,
  rejected: 0,
  no_of_refunded_transactions: 0,
  refunded_amount: 0,
};

const Daily = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dailyData, setDailyData] = useState(statistics_fields);
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(false)
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const commaNumber = require("comma-number");

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 500);
    });
  }, [setLoading]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  const getStatistics = () => {
    setIsLoading(true)
    const start_date = moment(dateFrom).format("YYYY-MM-DD");
    const end_date = moment(dateTo).format("YYYY-MM-DD");
    setIsLoading(true);
    UserUtility.getStatistics(start_date, end_date)
      .then((res) => {
        setDailyData(res);
        setIsLoading(false);
        setFetchData(true)
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setFetchData(true)
      });
  };

  const searchFunc = (e) => {
    e.preventDefault();
    getStatistics();
  };
  const dailyArr = [
    { cellName: "no_of_registered_user", cellTag: "no_of_registered_user" },
    {
      cellName: "no_of_credit_limit_forms_filled",
      cellTag: "no_of_credit_limit_forms_filled",
    },
    {
      cellName: "credit_limit_forms_filled_per_signup_(%)",
      cellTag: "credit_limit_forms_filled_per_signup",
    },
    {
      cellName: "no_of_customers_submit_docs",
      cellTag: "no_of_customers_submit_docs",
    },
    {
      cellName: "percentage_of_customers_submit_docs_(%)",
      cellTag: "percentage_of_customers_submit_docs",
    },
  ];
  const dailyArr2 = [
    { cellName: "activated_customers", cellTag: "activated_customers" },
    {
      cellName: "activated_customer_per_customers_submit_docs_(%)",
      cellTag: "activated_customer_per_customers_submit_docs",
    },
    {
      cellName: "activated_customer_per_credit_limit_form_filled_(%)",
      cellTag: "activated_customer_per_credit_limit_form_filled",
    },
    { cellName: "activated_limits", cellTag: "activated_limits" },
    { cellName: "avg_limit_per_customer", cellTag: "avg_limit_per_customer" },
    { cellName: "no_of_transactions", cellTag: "no_of_transactions" },
    { cellName: "total_transactions", cellTag: "total_transactions" },
    {
      cellName: "avg_ticket_size_per_transaction",
      cellTag: "avg_ticket_size_per_transaction",
    },
    {
      cellName: "no_of_merchants_on_board",
      cellTag: "no_of_merchants_on_board",
    },
    { cellName: "existing_areas", cellTag: "existing_areas" },
    { cellName: "current_o_s_portfolio", cellTag: "current_o_s_portfolio" },
    {
      cellName: "unearned_or_deferred_interest_rev",
      cellTag: "unearned_or_deferred_interest_rev",
    },
    {
      cellName: "Portfolio_of_total_limits_(%)",
      cellTag: "Portfolio_of_total_limits",
    },
    {
      cellName: "manual_study",
      cellTag: "manual_study",
    },
    {
      cellName: "rejected",
      cellTag: "rejected",
    },
    {
      cellName: "no_of_refunded_transactions",
      cellTag: "no_of_refunded_transactions",
    },
    {
      cellName: "refunded_amount",
      cellTag: "refunded_amount",
    },
  ];

  function generate_graph_data_from_response(response) {
    var no_of_registered_user = response['no_of_registered_user'];
    var no_of_credit_limit_forms_filled = response['no_of_credit_limit_forms_filled'];
    var no_of_customers_submit_docs = response['no_of_customers_submit_docs'];
    var activated_customers = response['activated_customers'];
    var bar_percentage_signup = 100;
    var bar_percentage_forms_filled = (no_of_credit_limit_forms_filled / no_of_registered_user) * 100
    var bar_percentage_docs_submit = (no_of_customers_submit_docs / no_of_registered_user) * 100
    var bar_percentage_activated_customers = (activated_customers / no_of_registered_user) * 100
    return [
      bar_percentage_signup,
      bar_percentage_forms_filled,
      bar_percentage_docs_submit,
      bar_percentage_activated_customers
    ]
  }
  function change_bar_width(bar_data) {
    document.getElementById("bar_percentage_activated_customers").style.width = bar_data[3] + "%";
    document.getElementById("bar_percentage_docs_submit").style.width = bar_data[2] - bar_data[3] + "%";
    document.getElementById("bar_percentage_forms_filled").style.width = bar_data[1] - bar_data[2] + "%";
    document.getElementById("bar_percentage_signup").style.width = bar_data[0] - bar_data[1] + "%";
  }

  useEffect(() => {
    fetchData && change_bar_width(generate_graph_data_from_response(dailyData));
  }, [dailyData, fetchData])

  return (
    <main className="daily_container">

      <form>
        <article className="seachBar_wrapper">
          <h3>or select date</h3>
          <section className="date_wrapper">
            <h3>From</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture
                // label="from"
                // openTo="year"
                // views={["month", "day"]}
                inputFormat="dd/MM/yyyy"
                value={dateFrom ? new Date(dateFrom) : null}
                onChange={(newValue) => {
                  setDateFrom(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </section>
          <section className="date_wrapper">
            <h3>To</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={dateTo ? new Date(dateTo) : null}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setDateTo(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </section>
          <Button
            type="submit"
            variant="contained"
            className="searchBtn"
            onClick={(e) => searchFunc(e)}
            disabled={isLoading}
          >
            Search
          </Button>
        </article>
      </form>

      {
        fetchData &&
        <div className="new_design mt-3">
          <div id="bar-chart-container">
            <div class="bar first" id="bar_percentage_activated_customers"></div>
            <div class="bar" id="bar_percentage_docs_submit"></div>
            <div class="bar" id="bar_percentage_forms_filled"></div>
            <div class="bar last" id="bar_percentage_signup"></div>
          </div>
          <div id="bar-chart-legend">
            <div class="legend-container">
              <div class="legend-point bar_percentage_activated_customers"></div>
              <div class="legend-text">Activated</div>
            </div>
            <div class="legend-container">
              <div class="legend-point bar_percentage_docs_submit"></div>
              <div class="legend-text">Documents Submitted</div>
            </div>
            <div class="legend-container">
              <div class="legend-point bar_percentage_forms_filled"></div>
              <div class="legend-text">Forms Filled</div>
            </div>
            <div class="legend-container">
              <div class="legend-point bar_percentage_signup"></div>
              <div class="legend-text">Signups</div>
            </div>
          </div>
        </div>
      }


      {/* beginning of the table */}
      <TableContainer
        component={Paper}
        style={{
          height: "fit-content",
          marginRight: 20,
          marginTop: 30,
        }}
        ref={componentRef}
      >
        <Table sx={{ minWidth: 700 }} aria-label="daily statistics">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {dateFrom || dateTo ? "date:-" : ""}
              </StyledTableCell>
              <StyledTableCell>
                <span>
                  {dateFrom
                    ? `From: ${moment(dateFrom).format("DD-MM-YYYY")}`
                    : ""}
                </span>
                <span>
                  {dateTo ? ` To: ${moment(dateTo).format("DD-MM-YYYY")}` : ""}
                </span>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* the rows upside the gray row */}
            {dailyArr.map((cell, id) => {
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell
                    style={{ borderRight: "1px dashed #707070", width: "30%" }}
                  >
                    <h3 className="table_content">
                      {cell.cellName.split("_").join(" ")}
                    </h3>
                  </StyledTableCell>
                  <StyledTableCell>
                    <h3 className="table_content">
                      {dailyData[cell.cellTag] &&
                        typeof dailyData[cell.cellTag] === "number"
                        ? commaNumber(dailyData[cell.cellTag])
                        : dailyData[cell.cellTag]}
                      <span>{cell.cellName.includes("%") ? " %" : ""}</span>
                    </h3>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            {/* the gray row */}
            <StyledTableRow style={{ backgroundColor: Colors.secondary }}>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell className="outOfWhich">
                <h3>Out of which</h3>
              </StyledTableCell>
            </StyledTableRow>
            {/* the rows below the gray row */}
            {dailyArr2.map((cell, id) => {
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell
                    style={{ borderRight: "1px dashed #707070", width: "30%" }}
                  >
                    <h3 className="table_content">
                      {cell.cellName.split("_").join(" ")}
                    </h3>
                  </StyledTableCell>
                  <StyledTableCell>
                    <h3 className="table_content">
                      {dailyData[cell.cellTag] &&
                        typeof dailyData[cell.cellTag] === "number"
                        ? commaNumber(dailyData[cell.cellTag])
                        : dailyData[cell.cellTag]}
                      <span>{cell.cellName.includes("%") ? " %" : null}</span>
                    </h3>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Button
          variant="contained"
          type="submit"
          style={{
            marginLeft: 0,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: loading ? Colors.secondary : Colors.primary,
          }}
          onClick={handlePrint}
          disabled={loading}
        >
          Print
        </Button>
      </div>
    </main>
  );
};

export default Daily;
