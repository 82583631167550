/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import "./Style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { UserUtility } from "../../apis/UserUtility";
import { ENDPOINTS } from "../../apis/EndPoints";
import { useLocation } from "react-router-dom";
import PaginationComponent from "../../components/pagination/PaginationComponent";

const Files = () => {
  const location = useLocation();
  const { id } = location.state;
  const [data, setData] = useState([]);
  const [pagesNumber,setPagesNumber]=useState(1)
  const [total,setTotal]=useState()
  const [image, setImage] = useState();
  useEffect(() => {
    if (image !== undefined) return create_image();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);
  useEffect(() => {
    image_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber]);
  const image_list = () => {
    UserUtility.image_list(id,pagesNumber)
      .then((res) => {
        setData(res.results);
        setTotal(res)
        
    })
    .catch((err) => {
        console.error("image_list", err);
    });
  };
  const create_image = () => {
      UserUtility.create_image(id, image)
      .then(() => {
          image_list();
    })
      .catch((err) => {
        console.error("create_image", err);
      });
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  const handelUploadPhoto = async (e) => {
    const pics = e.target.files[0];
    const token = localStorage.getItem("tools_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    const formdata = new FormData();
    formdata.append("image", pics);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    const fet = await fetch(
      `${ENDPOINTS.url}/management_dashboard/user_dashboard/upload_media/`,
      requestOptions
    );
    const file = await fet.json();
    setImage({ customer_media: file?.image });
  };
  const deleteImage = (image_id) => {
    UserUtility.delete_image(image_id, id)
      .then(() => {
        image_list();
      })
      .catch((err) => {
        console.error("delete_image", err);
      });
  };
  return (
    <div className="Legal" style={{ textAlign: "right" }} dir="rtl">
      <Row>
        <Col lg={7}>
          <Form.Label>إضافة مستند</Form.Label>
          <label
            htmlFor="contained-button-file"
            className="d-flex align-content-center mb-3"
          >
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              style={{ display: "none" }}
              name="thumbnail"
              onChange={(e) => handelUploadPhoto(e)}
            />
            <Form.Control
              value={data?.file?.name}
              name="value"
              type="text"
              className="shadow rounded"
              style={{ width: "50%" }}
              disabled
              // required
            />
            <span
              className="me-5 py-1 px-5  bg-success text-white"
              style={{ cursor: "pointer" }}
            >
              Upload
            </span>
          </label>
          {data?.map((e, index) => {
            return (
              <Form className="d-flex " key={index}>
                <a href={e.customer_media}>{e.customer_media}</a>
                <Button
                  style={{}}
                  variant="success"
                  onClick={() => {
                    deleteImage(e.id);
                  }}
                  className="py-1 px-5 me-5 mb-5"
                >
                  Delete
                </Button>
              </Form>
            );
          })
          }
        </Col>
        <section className="mb-5">

        <PaginationComponent
      brands={total}
      page={pagesNumber}
      handleChange={handleChange}
      // eslint-disable-next-line react/style-prop-object
      style="ltr"
      /> 
      </section>
      </Row>
    </div>
  );
};

export default Files;
