import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  ButtonToolbar,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserUtility } from "../../apis/UserUtility";
import { ENDPOINTS } from "../../apis/EndPoints";
import { ToastContainer, toast } from "react-toastify";
import UserContext from "../../hooks/userContext";

const ManualLimit = () => {
  let navigate = useNavigate();
  const { handleLoader, permissions } = useContext(UserContext);
  // Button color mui
  const theme = createTheme({
    palette: {
      main: {
        main: "#41b6a8",
        contrastText: "#fff",
      },
    },
  });

  const [disable, setDisable] = useState(false);
  const [retrieveCheckerComment, setRetrieveCheckerComment] = useState();
  const [data, setData] = useState({
    strict_balance: true,
    bypass_iscore: true,
    additional_document_1:'',
    additional_document_2:'',
    additional_document_3:'',
    additional_document_4:'',
    balance:'',
    comment:'',
    // active:true
  });
  const [comment, setComment] = useState({});
  const [userChecker, setUserChecker] = useState({});
  const [formRow, setFormRow] = useState([{}, {}, {}, {}]);
  const [number, setNumber] = useState();
  const [balancevalidated, setBalanceValidated] = useState(false);
  const [commentvalidated, setCommentValidated] = useState(false);
  useEffect(() => {
    dataCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...formRow]);
  const initial = [
    {
      fieldLabel: "Additional doc.1",
      fieldType: "text",
      Name: "additional_document_1",
      filedName: "",
    },
    {
      fieldLabel: "Additional doc.2",
      fieldType: "text",
      Name: "additional_document_2",
      filedName: "",
    },

    {
      fieldLabel: "Additional doc.3",
      fieldType: "text",
      Name: "additional_document_3",
      filedName: "",
    },
    {
      fieldLabel: "Additional doc.4",
      fieldType: "text",
      Name: "additional_document_4",
      filedName: "",
    },
  ];
  const dataObject = Object.assign({}, ...formRow);
  const location = useLocation();
  const { LimitIncrease, userId, manual_state, type } = location.state;
  const userType = () => {
    type === "riskMaker" || type === "limitMaker"
      ? setDisable(false)
      : setDisable(true);
  };
  // upload photo function
  const handelUploadPhoto = async (e) => {
    const pics = e.target.files[0];
    const token = localStorage.getItem("tools_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    const formdata = new FormData();
    formdata.append("image", pics);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    const fet = await fetch(
      `${ENDPOINTS.url}/management_dashboard/user_dashboard/upload_media/`,
      requestOptions
    );
    const file = await fet.json();
    const documentName = "additional_document_" + (number + 1);
    const result = formRow.map((ele, index) => {
      if (index === number) {
        return { [documentName]: file.image };
      }
      return ele;
    });
    setFormRow(result);
  };

  // collect data from object dataObject to data
  const dataCollection = () => {
    setData({ ...data, ...dataObject });
  };
  const checker_data = () => {
    type === "riskChecker"
      ? UserUtility.checker_retrieve(userId)
        .then((res) => {
          setUserChecker(res);
        })
        .catch((err) => console.error("checker_retrieve", err))
      : UserUtility.limitChecker_retrieve(userId)
        .then((res) => {
          setUserChecker(res);
        })
        .catch((err) => console.error("limitChecker_retrieve", err));
  };
  const send_to_checker = () => {
    // requierd fields
    const balance = data?.balance;
    const value = data?.value;
    const comment = data?.comment;
    comment ? setCommentValidated(false) : setCommentValidated(true);
    type === "riskMaker"
      ? balance
        ? setBalanceValidated(false)
        : setBalanceValidated(true)
      : value
        ? setBalanceValidated(false)
        : setBalanceValidated(true);
    type === "limitMaker"
      ? UserUtility.limitMaker_beginStudy(data, userId)
        .then(() => {
          toast.success("Sent to checker");
          setTimeout(() => {
            navigate("/limitIncrease");
          }, 1500);
        })
        .catch((err) => console.error("send_to_checker", err))
      :
      UserUtility.maker_beginStudy(data, userId)
        .then((res) => {
          toast.success("Sent to checker");
          setTimeout(() => {
            navigate("/manualStudy");
          }, 1500);
        })
        .catch((err) => console.error("send_to_checker", err));
  };
  const checker_return_to_maker = () => {
    type === "limitChecker"
      ? UserUtility.limitChecker_returnToMaker(userId)
        .then(() => {
          toast.success("Returned to maker");
          setTimeout(() => {
            navigate("/limitIncrease");
          }, 1500);
        })
        .catch((err) => console.error("checker_return_to_maker", err))
      : UserUtility.checker_return_to_maker(userId)
        .then(() => {
          toast.success("Returned to maker");
          setTimeout(() => {
            navigate("/manualStudy");
          }, 1500);
        })
        .catch((err) => console.error("checker_return_to_maker", err));
  };
  const checker_approve = () => {
    handleLoader(true)
    if (type === "limitChecker") {
      UserUtility.limitChecker_approve(userId)
        .then(() => {
          handleLoader(false)
          toast.success(
            "Mail has been sent to: customersupport@forsaegypt.com"
          );
          setTimeout(() => {
            navigate("/limitIncrease");
          }, 2000);
        })
        .catch((err) => {
          console.error("limitChecker_approve", err)
          handleLoader(false)
        }
        );
    } else if (type === "riskChecker") {
      UserUtility.checker_approve(userId)
        .then(() => {
          handleLoader(false)
          toast.success(
            "Mail has been sent to: customersupport@forsaegypt.com"
          );
          setTimeout(() => {
            navigate("/manualStudy");
          }, 2000);
        })
        .catch((err) => {
          console.error("checker_approve", err)
          handleLoader(false)
        }
        );
    }
  };
  const checker_comment = () => {
    type === "limitChecker"
      ? UserUtility.limitChecker_comment(userId, comment)
        .then((res) => { })
        .catch((err) => console.error("limitChecker_comment", err))
      : UserUtility.checker_comment(userId, comment)
        .then((res) => { })
        .catch((err) => console.error("checker_comment", err));
  };
  const maker_reject = () => {
    handleLoader(true)
    UserUtility.maker_reject(userId)
      .then(() => {
        handleLoader(false)
        toast.success("Mail has been sent to: customersupport@forsaegypt.com");
        setTimeout(() => {
          navigate("/manualStudy");
        }, 2000);
      })
      .catch((err) => {
        console.error("maker_reject", err)
        handleLoader(false)
      }
      );
  };
  const retrieve_checker_comment = () => {
    if (manual_state === "limitReturn_To_Maker") {
      UserUtility.limitMaker_retrieveComment(userId)
        .then((res) => {
          setRetrieveCheckerComment(res);
        })
        .catch((err) => console.error("limitMaker_retrieveComment", err));
    } else if (manual_state === "Return_To_Maker") {
      UserUtility.retrieve_checker_comment(userId)
        .then((res) => {
          setRetrieveCheckerComment(res);
        })
        .catch((err) => console.error("retrieve_checker_comment", err));
    }
  };
  const maker_retrieve = () => {
    if (manual_state === "limitReturn_To_Maker") {
      UserUtility.limitChecker_retrieve(userId)
        .then((res) => {
          setData(res);
        })
        .catch((err) => console.error("limitChecker_retrieve", err));
    } else if (manual_state === "Return_To_Maker") {
      UserUtility.checker_retrieve(userId)
        .then((res) => {
          setData(res);
        })
        .catch((err) => console.error("checker_retrieve", err));
    }
  };
  const limitCheckerReject = () => {
    handleLoader(true)
    UserUtility.limitChecker_reject(userId)
      .then(() => {
        handleLoader(false)
        toast.success("Mail has been sent to: customersupport@forsaegypt.com");
        setTimeout(() => {
          navigate("/limitIncrease");
        }, 2000);
      })
      .catch((err) => {
        console.error("maker_reject", err)
        handleLoader(false)
      }
      );
  };

  useEffect(() => {
    userType();
    if (permissions?.includes("is_risk_checker")) {
      checker_data();
    } else if (permissions?.includes("is_limit_checker")) {
      checker_data();
    }

    retrieve_checker_comment();
    maker_retrieve();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <Container className="mt-5">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Form className="d-flex  flex-column">
        <Row>
          <Col lg={3} md={6} sm={12}>
            <Form.Group className="mb-3">
              {LimitIncrease ? (
                <Form.Label>Limit increase/decrease</Form.Label>
              ) : (
                <Form.Label>Manual limit</Form.Label>
              )}
              {disable ? (
                <Form.Control
                  type="number"
                  readOnly
                  defaultValue={
                    LimitIncrease ? userChecker?.value : userChecker?.balance
                  }
                />
              ) : (
                <InputGroup>
                  {type === "limitMaker" ? (
                    <Form.Control
                      value={data?.value}
                      name="value"
                      onChange={(e) =>
                        setData({ ...data, value: e.target.value })
                      }
                      type="number"
                      className="shadow rounded"
                      required
                      isInvalid={balancevalidated}
                    />
                  ) : (
                    <Form.Control
                      value={data?.balance}
                      name="balance"
                      onChange={(e) =>
                        setData({ ...data, balance: e.target.value })
                      }
                      type="number"
                      className="shadow rounded"
                      required
                      isInvalid={balancevalidated}
                    />
                  )}

                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </InputGroup>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>Comment:</Form.Label>
              {disable ? (
                <Form.Control
                  as="textarea"
                  rows={3}
                  readOnly
                  defaultValue={userChecker?.comment}
                />
              ) : (
                <InputGroup>
                  <Form.Control
                    onChange={(e) =>
                      setData({ ...data, comment: e.target.value })
                    }
                    as="textarea"
                    rows={3}
                    className="shadow rounded"
                    isInvalid={commentvalidated}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </InputGroup>
              )}
            </Form.Group>
          </Col>
        </Row>
        {(manual_state === "Return_To_Maker" ||
          manual_state === "limitReturn_To_Maker") && (
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>checker_comment:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    readOnly
                    defaultValue={
                      retrieveCheckerComment?.comment_checker ||
                      retrieveCheckerComment?.approved_by_comment
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        {(type === "limitChecker" || type === "limitMaker") ? null : (
          <Form.Group className="mb-3">
            <Form.Check
              checked={type === "riskChecker" ? userChecker?.strict_balance : data?.strict_balance}
              onChange={(e) =>
                setData({
                  ...data,
                  strict_balance: e.target.checked,
                  // bypass_iscore: false,
                })
              }
              type="checkbox"
              label="Strict limit"
              disabled={type === "riskChecker" ? true : false}
            />
          </Form.Group>
        )}
        {type === "limitChecker" || type === "limitMaker" ? null : (
          <Form.Group className="mb-3">
            <Form.Check
              checked={type === "riskChecker" ? userChecker?.bypass_iscore : data?.bypass_iscore}
              onChange={(e) =>
                setData({
                  ...data,
                  bypass_iscore: e.target.checked,
                  // strict_balance: false,
                })
              }
              type="checkbox"
              label="Iscore down"
              disabled={type === "riskChecker" ? true : false}
            />
          </Form.Group>
        )}
        <Row className=" align-items-end ">
          {initial.map((ele, index) => (
            <>
              {disable ? (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="d-flex justify-content-between"
                >
                  <Form.Group className="mt-3" style={{ width: 300 }}>
                    <Form.Label>{ele.fieldLabel}</Form.Label>
                    <Form.Control
                      type={ele.fieldType}
                      readOnly
                      value={userChecker[ele.Name]}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <>
                  <Col
                    lg={6}
                    md={8}
                    sm={12}
                    className="d-flex flex-column justify-content-between align-content-center"
                  >
                    <Form.Label className="mt-3">{ele.fieldLabel}</Form.Label>
                    <label
                      htmlFor="contained-button-file"
                      className="d-flex align-content-center"
                    >
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        style={{ display: "none" }}
                        name="thumbnail"
                        onChange={(e) => handelUploadPhoto(e)}
                      />
                      <TextField
                        className="mt-1"
                        variant="outlined"
                        size="small"
                        style={{ width: 300 }}
                        value={data ? data[ele.Name] : null}
                        InputProps={{
                          readOnly: true,
                        }}
                        required
                      />

                      <ThemeProvider theme={theme}>
                        <Button
                          style={{ width: 150, marginLeft: 80 }}
                          variant="contained"
                          component="span"
                          color="main"
                          onClick={() => setNumber(index)}
                        >
                          Upload
                        </Button>
                      </ThemeProvider>
                    </label>
                  </Col>
                </>
              )}
            </>
          ))}
          {disable && (
            <Row>
              <Col lg={10} md={10} sm={12}>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>Comment:</Form.Label>
                  {type === "limitChecker" ? (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="shadow rounded"
                      onChange={(e) =>
                        setComment({
                          ...comment,
                          approved_by_comment: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="shadow rounded"
                      onChange={(e) =>
                        setComment({
                          ...comment,
                          comment_checker: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
        </Row>
        {disable ? (
          <Row className="m-5 ">
            <ButtonToolbar
              className="d-flex justify-content-center"
              aria-label="Toolbar with button groups"
            >
              <ButtonGroup className="me-2" aria-label="First group">
                <ThemeProvider theme={theme}>
                  <Button
                    style={{ width: 200 }}
                    className=" justify-content-md-center"
                    variant="contained"
                    color="main"
                    onClick={() => (checker_comment(), checker_approve())}
                  >
                    Approve
                  </Button>
                  {type === "limitChecker" && (
                    <ThemeProvider theme={theme}>
                      <Button
                        style={{ width: 200 }}
                        className="ms-2 justify-content-md-center"
                        variant="contained"
                        color="error"
                        onClick={() => limitCheckerReject()}
                      >
                        Reject
                      </Button>
                    </ThemeProvider>
                  )}
                </ThemeProvider>
              </ButtonGroup>
              <ThemeProvider theme={theme}>
                <Button
                  onClick={() => (checker_comment(), checker_return_to_maker())}
                  style={{ width: 200 }}
                  className=" justify-content-md-center"
                  variant="outlined"
                  color="main"
                >
                  Return to maker
                </Button>
              </ThemeProvider>
            </ButtonToolbar>
          </Row>
        ) : (
          <Row className="mb-5" style={{ marginTop: 70 }}>
            <ButtonToolbar
              className="d-flex justify-content-center"
              aria-label="Toolbar with button groups"
            >
              <ButtonGroup className="me-2" aria-label="First group">
                <ThemeProvider theme={theme}>
                  <Button
                    style={{ width: 200 }}
                    className=" justify-content-md-center"
                    variant="contained"
                    color="main"
                    onClick={send_to_checker}
                  >
                    Send to checker
                  </Button>
                </ThemeProvider>
              </ButtonGroup>
              {LimitIncrease ? null : (
                <ThemeProvider theme={theme}>
                  <Button
                    style={{ width: 200 }}
                    className=" justify-content-md-center"
                    variant="contained"
                    color="error"
                    onClick={() => maker_reject()}
                  >
                    Reject
                  </Button>
                </ThemeProvider>
              )}
            </ButtonToolbar>
          </Row>
        )}
      </Form>
    </Container>
  );
};
export default ManualLimit;
