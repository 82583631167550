import styles from "./RecieptStyle.module.scss";
import logo from "../../assets/images/logo2.png";
import forsaLogo from "../../assets/images/forsaLogo.jpeg";
import mail from "../../assets/images/mailIcon.svg";
import locationLogo from "../../assets/images/locationIcon.svg";
import call from "../../assets/images/callIcon.svg";
import { useCallback, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import { Colors } from "../../constants/Colors";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const Receipts = () => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 5];
  const location = useLocation();
  console.log("location: ", location);
  const users = location?.state?.users;
  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 500);
    });
  }, [setLoading]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  return (
    <main className="container">
      <div className={styles.btnn}>
        <Button
          variant="contained"
          type="submit"
          style={{
            marginLeft: 0,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: loading ? Colors.secondary : Colors.primary,
            color: "#fff",
          }}
          onClick={handlePrint}
          disabled={loading}
        >
          Print
        </Button>
      </div>
      <section ref={componentRef}>
        {users.map((item, index) => {
          return (
            <section key={index.toString()} style={{ paddingTop: 100 }}>
              {/* section1 */}
              <div className={styles.flex_div}>
                <div className={styles.logo}>
                  <img src={logo} alt="logo" />
                  <img src={forsaLogo} alt="logo" />
                </div>
                

                <div>
                  <p>درايف للتمويل والخدمات المالية غير المصرفية ش.م.م</p>
                  <p>Drive Finance And Non Banking Service S . A . E</p>
                </div>
              </div>

              {/* section2 */}
              <div className={styles.section2}>
                <h6 className={styles.title}>
                  ايصال سداد رقم <span>{item.id}</span>
                </h6>

                <div className={styles.flex_div}>
                  <div className={styles.table}>
                    <p>
                      اسم العميل:
                      <span>{item.user_profile_name}</span>
                    </p>

                    <p>
                      كود العميل:
                      <span>{item.user_code}</span>
                    </p>

                    <p>
                      المبلغ بالارقام:
                      <span>{item.advanced_amount}</span>
                    </p>

                    <p>
                      العملة:
                      <span>جنيه مصري </span>
                    </p>
                  </div>

                  <div className={styles.sec2_col1}>
                    <p>
                      نوع السداد:
                      <span>نقدي / فيزا</span>
                    </p>

                    <p>
                      التاريخ:
                      <span>
                        {moment(item.created_at).format("YYYY-MM-DD")}
                      </span>
                    </p>

                    <p>
                      : الفرع
                      <span className={styles.seprt_span}></span>
                    </p>

                    <p>
                      المبلغ بالحروف:
                      <span>{item.amount_in_arabic_words}</span>
                    </p>

                    <p>
                      الغرض من التوريد:
                      <span>{item.comment}</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* section3 */}
              <div className={styles.section3}>
                <h4>ملاحظات هامه</h4>
                <ul>
                  <li>
                    لا يعتمد هذا الايصال مالم يكن مختوما بختمه الخزينة وموقع من
                    امين الخزينة
                  </li>
                  <li>
                    يرجي مراجعه بيانات الايصال قبل مغادره الخزينة حيث ان مغادرتك
                    اقرار منك علي صحة البيانات
                  </li>
                </ul>
              </div>

              {/* section4 */}
              <div className={`${styles.flex_div} ${styles.section4}`}>
                <div className={styles.div1}>
                  <h6>امين الخزينة</h6>
                  <p></p>
                </div>

                <div className={styles.table}>
                  <p>
                    : القائم بالسداد
                    <span className={styles.seprt_span}></span>
                  </p>

                  <p>
                    : رقم الهاتف
                    <span className={styles.seprt_span}></span>
                  </p>

                  <p>
                    : الرقم القومي
                    <span className={styles.seprt_span}></span>
                  </p>

                  <p>
                    : التوقيع
                    <span className={styles.seprt_span}></span>
                  </p>
                </div>
              </div>

              <div className={styles.section5}>
                <div className={styles.footer}>
                  <div className={styles.flex_div}>
                    {/* col1 */}
                    <div className={`${styles.flex_div2} ${styles.img_div}`}>
                      <img src={logo} alt="logo" />
                    </div>

                    {/* col2  */}
                    <div className={styles.flex_div2}>
                      <p>drive.info@drive-finance.com</p>
                      <img src={mail} alt="mail" />
                    </div>

                    {/* col3  */}
                    <div className={styles.flex_div2}>
                      <p>المعرض السابع-الطريق الدائري-القاهره الجديده-مصر</p>
                      <img src={locationLogo} alt="location" />
                    </div>

                    {/* col4  */}
                    <div className={styles.flex_div2}>
                      <p>16845</p>
                      <img src={call} alt="call" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </section>
    </main>
  );
};
