import styles from "./TableData/TableData.module.scss";
import { useCallback, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import { Colors } from "../../../constants/Colors";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../../apis/UserUtility";
import TableData from "./TableData/TableData";
import TableFines from "../Fines/TableFines";

export const RemainingInstallments = () => {
  const location = useLocation();
  const { userId } = location.state;
  const { fines } = location.state;
  const { product } = location.state;
  const { loanId } = location.state;
  const [statement, setStatement] = useState([]);

  const [statementDetails, setStatementDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const upcoming_installments = statementDetails?.upcoming_installments;
  const user_installments = statementDetails?.due_installments;
  const payment_requests = statementDetails?.payment_requests;

  //   Fines

  let today = new Date().toISOString().slice(0, 10);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 500);
    });
  }, [setLoading]);

  const getStatement = () => {
    UserUtility.statement(userId)
      .then((res) => {
        setStatement(res);
      })
      .catch((err) => console.error("statement", err));
  };

  const statement_details = () => {
    UserUtility.statement_details(userId)
      .then((res) => {
        setStatementDetails(res);
      })
      .catch((err) => console.error("statement_details", err));
  };

  const loan_statement = () => {
    UserUtility.loan_statement(userId, loanId)
      .then((res) => {
        setStatementDetails(res);
      })
      .catch((err) => console.error("loan_statement", err));
  };
  const loan_statement_fines = () => {
    UserUtility.loan_statement_fines(userId, loanId)
      .then((res) => {
        setStatement(res);
      })
      .catch((err) => console.error("loan_statement_fines", err));
  };

  useEffect(() => {
    if (product) {
      if (fines) {
        loan_statement_fines();
      } else {
        loan_statement();
      }
    } else {
      if (fines) {
        getStatement();
      } else {
        statement_details();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, fines]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  // RemainingInstallments

  const upcomingNewData = upcoming_installments?.map((ele, index) => {
    return { ...ele, index: index + 1 };
  });
  const remainingChunk = 20; // items per chunk

  const upcomingNewDataDivide = upcomingNewData?.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / remainingChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );
  const dueDataDivide = user_installments?.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / remainingChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  const paymentRequestDataDivide = payment_requests?.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / remainingChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  // Fines
  const finesNewData = statement?.installments?.map((ele, index) => {
    return { ...ele, index: index + 1 };
  });
  const perChunk = 27; // items per chunk

  const finesDivide = finesNewData?.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return (
    <main className="container" style={{ fontSize: "1px" }}>
      <div className={`${styles.btnn} mt-3`}>
        <Button
          variant="contained"
          type="submit"
          style={{
            marginLeft: 0,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: loading ? Colors.secondary : Colors.primary,
            color: "#fff",
          }}
          onClick={handlePrint}
          disabled={loading}
        >
          Print
        </Button>
      </div>
      <section ref={componentRef}>
        <section
          // key={index.toString()}
          style={{
            paddingTop: 160,
            textAlign: "-webkit-right",
          }}
        >
          <div
            className={styles.header_data}
            style={{
              marginBottom:
                (
                  upcomingNewDataDivide ||
                  dueDataDivide ||
                  paymentRequestDataDivide ||
                  finesDivide
                )?.length > 1
                  ? "-450px"
                  : "",
            }}
          >
            <p>
              <span className="ms-2">بناء علي طلب العميل :</span>
              <span>
                {statementDetails?.customer_details?.user_name ||
                  statement?.customer_details?.user_name}
              </span>
            </p>
            <p>
              <span className="ms-2">بطاقة الرقم القومي :</span>
              <span>
                {statementDetails?.customer_details?.national_id ||
                  statement?.customer_details?.national_id}
              </span>
            </p>
            <p>
              <span className="ms-2">موجه إلي :</span>
              <span>إلي من يهمه الامر</span>
              <span
                className="fw-bold text-decoration-underline"
                style={{ marginRight: "35%", fontSize: 13 }}
              >
                كشف حساب
              </span>
            </p>
            <p>
              <span className="ms-2">تاريخ الإصدار :</span>
              <span>{today}</span>
            </p>
          </div>
          {!fines && (
            <div className="d-flex  flex-row-reverse px-1 flex-wrap">
              {upcomingNewDataDivide?.map((ele, index) => {
                return (
                  <div
                    key={index}
                    className="ms-2"
                    style={{
                      textAlign: "right",
                      height: "600px",
                      marginTop:
                        (
                          upcomingNewDataDivide ||
                          dueDataDivide ||
                          paymentRequestDataDivide
                        )?.length > 1
                          ? "450px"
                          : "",

                      // marginBottom:
                    }}
                  >
                    <div className={`${styles.RemainingInstallments_tabel}`}>
                      <p>جدول الاقساط المتبقيه</p>
                    </div>
                    <TableData data={ele} />
                  </div>
                );
              })}

              {dueDataDivide?.map((ele, index) => {
                return (
                  <div
                    key={index}
                    className="ms-2"
                    style={{
                      textAlign: "right",
                      height: "600px",
                      marginTop:
                        (
                          upcomingNewDataDivide ||
                          dueDataDivide ||
                          paymentRequestDataDivide
                        )?.length > 1
                          ? "450px"
                          : "",

                      // marginBottom:
                    }}
                  >
                    <div className={`${styles.RemainingInstallments_tabel}`}>
                      <p>جدول الاقساط المستحقة حتي تاريخه</p>
                    </div>
                    <TableData data={ele} />
                  </div>
                );
              })}
              {paymentRequestDataDivide?.map((ele, index) => {
                return (
                  <div
                    key={index}
                    className="ms-2"
                    style={{
                      textAlign: "right",
                      height: "600px",
                      marginTop:
                        (
                          upcomingNewDataDivide ||
                          dueDataDivide ||
                          paymentRequestDataDivide
                        )?.length > 1
                          ? "450px"
                          : "",

                      // marginBottom:
                    }}
                  >
                    <div className={`${styles.RemainingInstallments_tabel}`}>
                      <p>جدول السداد</p>
                    </div>
                    <TableData
                      paymentRequestdata={ele}
                      paid={
                        paymentRequestDataDivide?.length === index + 1
                          ? statementDetails?.total_paid_amount
                          : null
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className="px-1 d-flex flex-wrap" style={{ direction: "rtl" }}>
            {fines &&
              finesDivide?.map((ele, index) => {
                return (
                  <div
                    key={index}
                    className="ms-2"
                    style={{
                      textAlign: "right",
                      marginTop:
                        statement?.installments?.length > 27 ? "450px" : "",
                      // marginBottom: "10%",
                      width: "100%",
                      height: "600px",
                    }}
                  >
                    {<TableFines allData={ele} />}
                  </div>
                );
              })}
          </div>
          {!fines && (
            <>
              {/* section4 */}
              <div className={`${styles.Late_commission}`}>
                <div>
                  <p style={{ fontSize: "8px" }}>
                    إجمالي عمولة التاخير حتي تاريخه
                  </p>
                  <span style={{ fontSize: "8px" }}>
                    {statementDetails?.remaining_penalties_amount}
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: "8px" }}>
                    إجمالي الاقساط المستحقة غير المدفوعة حتي تاريخه
                  </p>
                  <span style={{ fontSize: "8px" }}>
                    {statementDetails?.total_remaining_installments_amount}
                  </span>
                </div>
              </div>
              <div>
                <div className={`${styles.text_footer}`}>
                  <p>
                    <p>
                      سداد العميل هو مسوْليته الشخصيه بموجب التعاقد و الشركة غير
                      مسئوله في ذلك
                    </p>
                    <p>
                      التاريخ الموضح بالكشف هو آخر تاريخ تم به اكتمال كامل لقيمة
                      القسط
                    </p>
                    <p>وقد أصدر هذا الخطاب دون أدني مستولية علي الشركة</p>
                  </p>
                </div>
              </div>
            </>
          )}
        </section>
      </section>
    </main>
  );
};
