//staging
const Url = "https://forsa-staging.bit68.com/api/v1/";

// production
// const Url = "https://backend.forsaegypt.com/api/v1/";

// local
// const Url = "https://9427-196-132-98-131.eu.ngrok.io/api/v1/"
//
// preproduction

// const Url = "https://forsa-preproduction.agilepatch.com/api/v1/"

// local
// const Url = "https://64b4-156-215-52-84.ngrok-free.app/api/v1/";

// local
// const Url = "https://d978-41-235-34-139.ngrok-free.app/api/v1/";

// pre Production
// const Url = "https://forsa-preproduction.agilepatch.com/api/v1/"

export const ENDPOINTS = {
  url: Url,
  userLogin: {
    url: `${Url}automation_tool/auth/`,
    method: "POST",
  },
  createKiosk: {
    url: `${Url}automation_tool/kiosk_management/kiosk_users/`,
    method: "POST",
  },
  searchOtp: {
    url: (phoneNumber) =>
      `${Url}automation_tool/customer_otps/?phone=${phoneNumber}`,
    method: "GET",
  },
  userData: {
    url: `${Url}automation_tool/get_profile/`,
    method: "GET",
  },
  userPermissions: {
    url: `${Url}automation_tool/get_permissions/`,
    method: "GET",
  },
  makersList: {
    url: (page, query) =>
      `${Url}contract_tracker/contract/makers/?page=${page}${query}`,
    method: "GET",
  },
  makersSelectList: {
    url: `${Url}contract_tracker/makers/list/`,
    method: "GET",
  },
  courierList: {
    url: `${Url}contract_tracker/courier/list/`,
    method: "GET",
  },
  contractList: {
    url: (filterParams) =>
      `${Url}contract_tracker/contract/list/?${filterParams}`,
    method: "GET",
  },
  sendContractAction: {
    url: `${Url}contract_tracker/contract/action/create/`,
    method: "POST",
  },
  createCollectionOrder: {
    url: `${Url}contract_tracker/contract/makers/`,
    method: "POST",
  },
  contractHistory: {
    url: (contractId) =>
      `${Url}contract_tracker/contract/action/list/?pk=${contractId}`,
    method: "GET",
  },
  contractNotCollectedBranches: {
    url: `${Url}contract_tracker/contract/not_collected/branches/`,
    method: "GET",
  },
  contractBranches: {
    url: `${Url}contract_tracker/contract/branches/`,
    method: "GET",
  },

  contractSummary: {
    url: (filterParams) =>
      `${Url}contract_tracker/contract/summary/?${filterParams}`,
    method: "GET",
  },
  //Management tool
  getBrands: {
    url: (
      page,
      searchValue,
      selectedSectors,
      newest,
      best_seller,
      featured,
      published,
      un_published
    ) =>
      `${Url}management_dashboard/brands/list/?page=${page}${
        searchValue && `&search=${searchValue}`
      }${selectedSectors && `&sector=${selectedSectors}`}${
        newest ? `&newest=true` : ""
      }${best_seller ? `&best_seller=true` : ""}${
        featured ? `&featured=true` : ""
      }${published ? `&published=true` : ""}${
        un_published ? `&un_published=true` : ""
      }`,
    method: "GET",
  },

  getBrandSectors: {
    url: `${Url}management_dashboard/sector/list/`,
    method: "GET",
  },
  editBrand: {
    url: (brandId) => `${Url}management_dashboard/brands/edit/${brandId}/`,
    method: "PATCH",
  },
  actionPOS: {
    url: (ids) => `${Url}management_dashboard/generate_pos_user/?ids=${ids}`,
    method: "GET",
  },
  actionGenerateUsers: {
    url: (ids) =>
      `${Url}management_dashboard/generate_merchant_user/?ids=${ids}`,
    method: "GET",
  },
  actionReGenerateUsers: {
    url: (ids) =>
      `${Url}management_dashboard/re_generate_merchant_users/?ids=${ids}`,
    method: "GET",
  },
  getOffers: {
    url: (filterParams) =>
      `${Url}management_dashboard/offer/list/${filterParams}`,
    method: "GET",
  },
  getOfferDetails: {
    url: (offerId) => `${Url}management_dashboard/offers/?id=${offerId}`,
    method: "GET",
  },

  hideOffer: {
    url: (offerId) => `${Url}management_dashboard/offers/hidden/${offerId}/`,
    method: "PATCH",
  },
  getStatistics: {
    url: (start_date, end_date) =>
      `${Url}automation_tool/statistics-overview/?${
        start_date !== "Invalid date" ? `start_date=${start_date}` : ""
      }${end_date !== "Invalid date" ? `&end_date=${end_date}` : ""}`,
    method: "GET",
  },

  publishBrand: {
    url: (brandId) => `${Url}management_dashboard/brands/publish/${brandId}/`,
    method: "PUT",
  },
  publishOffer: {
    url: (offerId) => `${Url}management_dashboard/offers/publish/${offerId}/`,
    method: "PUT",
  },

  publishCategory: {
    url: (catId) => `${Url}management_dashboard/categories/publish/${catId}/`,
    method: "PUT",
  },
  // new endpoint
  getCategories: {
    url: (filterParams) =>
      `${Url}management_dashboard/category/list/${filterParams}`,
    method: "GET",
  },
  getSpecialBrand: {
    url: (brandId) => `${Url}management_dashboard/brands/edit/${brandId}/`,
    method: "GET",
  },
  createBrand: {
    url: `${Url}management_dashboard/brands/create/`,
    method: "POST",
  },
  updateBrandCategories: {
    url: (brandId) =>
      `${Url}management_dashboard/brands/${brandId}/create/category/`,
    method: "PUT",
  },
  createInstallmentPlans: {
    url: (categoryId) =>
      `${Url}management_dashboard/categories/${categoryId}/create/installment_plans/`,
    method: "PUT",
  },
  getInstallmentPlans: {
    url: (categoryId) =>
      `${Url}management_dashboard/categories/installment_plans/list/?category=${categoryId}`,
    method: "GET",
  },
  publishInstallmentPlans: {
    url: (installId) =>
      `${Url}management_dashboard/intsallment/plan/publish/${installId}/`,
    method: "PUT",
  },
  getBrandBranches: {
    url: (brandId, page) =>
      page
        ? `${Url}management_dashboard/brands/list_branches/?brand=${brandId}&page=${page}`
        : `${Url}management_dashboard/brands/list_branches/?brand=${brandId}`,

    method: "GET",
  },
  addBranches: {
    url: `${Url}management_dashboard/branches/create/`,
    method: "POST",
  },
  editBranch: {
    url: (branchId) => `${Url}management_dashboard/branches/edit/${branchId}/`,
    method: "PUT",
  },

  activeBrandBranches: {
    url: (brandId) =>
      `${Url}management_dashboard/brand_branches/?brand=${brandId}`,
    method: "GET",
  },
  editInstallments: {
    url: `${Url}management_dashboard/categories/installment_plans/edit/`,
    method: "PUT",
  },
  createOffer: {
    url: `${Url}management_dashboard/offers/`,
    method: "POST",
  },
  editOffer: {
    url: (offerId) => `${Url}management_dashboard/offers/${offerId}/`,
    method: "PUT",
  },
  reCreateBranch: {
    url: (branchId) =>
      `${Url}management_dashboard/re_create_pos_user/?ids=${branchId}`,
    method: "GET",
  },

  merchantProviders: {
    url: `${Url}stores/ProvidersView/`,
    method: "GET",
  },

  // global offers
  BrandsBySector: {
    url: (sectorId) => `${Url}management_dashboard/brand/sector/${sectorId}/`,
    method: "GET",
  },

  globalOffer: {
    url: (brandIds) =>
      `${Url}management_dashboard/global_offer/?ids=${brandIds}`,
    method: "POST",
  },

  brandSlider: {
    url: (brandId) => `${Url}management_dashboard/slider/${brandId}/`,
    method: "GET",
  },

  tags: {
    url: `${Url}management_dashboard/tag/list/`,
    method: "GET",
  },

  riskMails: {
    url: `${Url}management_dashboard/email/list/`,
    method: "GET",
  },
  sendRiskMail: {
    url: `${Url}management_dashboard/send_email/`,
    method: "POST",
  },

  getHistory: {
    url: (type, ids, page = 1) => {
      const serilizedIds = ids.join(",");
      const searchParams = new URLSearchParams();
      searchParams.append("page", page);
      if (ids.length) {
        searchParams.set("ids", serilizedIds);
      }
      return `${Url}management_dashboard/${type}/history/?${searchParams.toString()}`;
    },
    method: "GET",
  },
  getHistoryById: {
    url: (id, type, installmentIds, page = 1) => {
      const serilizedInstallmentIds = installmentIds.join(",");
      const searchParams = new URLSearchParams();
      searchParams.append("page", page);
      console.log("installmentIds.length endpoint: ", installmentIds);
      if (installmentIds.length) {
        searchParams.append("installment_ids", serilizedInstallmentIds);
      }

      return `${Url}management_dashboard/${type}/history/${id}/?${searchParams.toString()}`;
    },
    method: "GET",
  },
  merchantHistory: {
    url: (type, id, page) =>
      `${Url}management_dashboard/${type}/history/${id}/?page=${page}`,
    method: "GET",
  },
  historyByIds: {
    url: (ids, type, page) => {
      const deserilizedIds = ids.join(",");
      const searchParams = new URLSearchParams();
      searchParams.append("ids", deserilizedIds);
      searchParams.append("page", page);
      return `${Url}management_dashboard/${type}/history/?${searchParams.toString()}`;
    },
    method: "GET",
  },

  installmentDefault: {
    url: (brandId) =>
      `${Url}management_dashboard/brands/${brandId}/default-installments/`,
    method: "GET",
  },

  generateUniqueMerchantIndent: {
    url: `${Url}management_dashboard/generate_unique_mid/`,
    method: "GET",
  },
  /*
  brandHistory: {
    url: (brandId) => `${Url}management_dashboard/brand/history/${brandId}/`,
    method: "GET"
  },

  offerHistory: {
    url: (offerId) => `${Url}management_dashboard/offer/history/${offerId}/`,
    method: "GET"
  },

  categoryHistory: {
    url: (categoryId) => `${Url}management_dashboard/category/history/${categoryId}/`,
    method: "GET"
  },
*/
  //kiosk management
  getKioskUsers: {
    url: (queryy) =>
      `${Url}automation_tool/kiosk_management/kiosk_users/?${queryy}`,
    method: "GET",
  },
  getActivationPoints: {
    url: (queryy) =>
      `${Url}automation_tool/kiosk_management/kiosk_tool_view/?${queryy}`,
    method: "GET",
  },
  getKiosks: {
    url: (filter) => `${Url}automation_tool/kiosk_management/kiosks/?${filter}`,
    method: "GET",
  },
  updateKiosk: {
    url: (kiosk_id) =>
      `${Url}automation_tool/kiosk_management/kiosks/${kiosk_id}/`,
    method: "PATCH",
  },

  kioskCities: {
    url: `${Url}kiosk/cities/`,
    method: "GET",
  },

  kioskAreas: {
    url: (selectedCity) => `${Url}kiosk/areas/?city=${selectedCity}`,
    method: "GET",
  },

  kioskBranches: {
    url: (params) =>
      `${Url}automation_tool/kiosk_management/simple_kiosks/${params}`,
    method: "GET",
  },
  getOverdueUsers: {
    url: (page, searchValue) =>
      `${Url}automation_tool/overdue_users/?page=${page}&nationa_id=${searchValue}`,
    method: "GET",
  },

  payMethod: {
    url: `${Url}automation_tool/generate_fawry_payment/`,
    method: "POST",
  },
  brandLonasList: {
    url: (filterParams) =>
      `${Url}management_dashboard/brands/transactions/?${filterParams}`,
    method: "GET",
  },
  brandLonasDetails: {
    url: (filterParams) =>
      `${Url}management_dashboard/brands/transactions/detail/?${filterParams}`,
    method: "GET",
  },

  user_list: {
    url: (
      currentPage,
      national_id,
      state,
      hold,
      iscore,
      modifiedStartDate,
      modifiedEndDate,
      createdStartDate,
      createdEndDate
    ) =>
      `${Url}management_dashboard/user_dashboard/users_profile/list/?page=${currentPage}${
        state ? `&p_state=${state}` : ""
      }${national_id ? `&search=${national_id}` : ""}${
        hold ? `&user__on_hold=${hold}` : ""
      }${iscore ? `&iscore_state=${iscore}` : ""}${
        createdStartDate
          ? `&created_after=${createdStartDate}&created_before=${createdEndDate}`
          : ""
      }${
        modifiedStartDate
          ? `&modified_after=${modifiedStartDate}&modified_before=${modifiedEndDate}`
          : ""
      }
      
  `,
    method: "GET",
  },
  user_managment: {
    url: (national_id) =>
      `${Url}management_dashboard/user_dashboard/users_profile/list/?search=${national_id}`,
    method: "GET",
  },

  product_statement: {
    url: (userId, loanId) =>
      `${Url}finance/cash_collection/user_profile/${userId}/loan/${loanId}/statement/`,
    method: "GET",
  },

  get_extra_documents: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/user_profile/${userId}/extra_documents/`,
    method: "GET",
  },

  users_profile: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${userId}/`,
    method: "GET",
  },
  users_options: {
    url: `${Url}management_dashboard/user_dashboard/users_profile/`,
    method: "OPTIONS",
  },
  car_model: {
    url: (brandName) =>
      `${Url}management_dashboard/user_dashboard/car_model/?brand__name=${brandName} `,
    method: "GET",
  },
  login_info: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${userId}/login_devices/`,
    method: "GET",
  },
  login_history_info: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${userId}/login_attempts/`,
    method: "GET",
  },
  login_action: {
    url: (userId, loginId) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${userId}/login_devices/${loginId}/`,
    method: "PATCH",
  },

  change_userProfile: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${userId}/`,
    method: "PATCH",
  },
  userProfile_history: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/user_history/${userId}/`,
    method: "GET",
  },
  userProfile_penalty: {
    url: (userId, pageNumber) =>
      `${Url}management_dashboard/user_dashboard/get_user_penalty/${userId}/?page=${pageNumber}`,
    method: "GET",
  },
  payment_history: {
    url: (userId, pageNumber) =>
      `${Url}management_dashboard/user_dashboard/payment_history/${userId}/?page=${pageNumber}`,
    method: "GET",
  },
  user_paymob_payments: {
    url: (userId, pageNumber) =>
      `${Url}management_dashboard/user_dashboard/user_paymob_payments/${userId}/?page=${pageNumber}`,
    method: "GET",
  },
  loan_history: {
    url: (userId, pageNumber) =>
      `${Url}management_dashboard/user_dashboard/loan_history/${userId}/?page=${pageNumber}`,
    method: "GET",
  },
  contract_history: {
    url: (userId, pageNumber) =>
      `${Url}management_dashboard/user_dashboard/contract_history/${userId}/?page=${pageNumber}`,
    method: "GET",
  },
  user_document: {
    url: (userId) =>
      `${Url}/management_dashboard/user_dashboard/user_document/${userId}/`,
    method: "GET",
  },
  block_history: {
    url: (userId) =>
      `${Url}/management_dashboard/user_dashboard/users_profile/block_history/${userId}/`,
    method: "GET",
  },
  unblock_user: {
    url: (userId) =>
      `${Url}/management_dashboard/user_dashboard/users_profile/unblock/${userId}/`,
    method: "PATCH",
  },
  send_to_manual: {
    url: (userId) =>
      `${Url}/management_dashboard/user_dashboard/users_profile/manual_study/${userId}/`,
    method: "PATCH",
  },
  nextPage: {
    url: (urlQuery) => `${urlQuery}`,
    method: "GET",
  },
  export_users_profile: {
    url: `${Url}management_dashboard/user_dashboard/export_user_profile/`,
    method: "GET",
  },
  action_history: {
    url: (userId, pageNumber) =>
      `${Url}management_dashboard/user_dashboard/user_action_log/${userId}/?page=${pageNumber}`,
    method: "GET",
  },
  manualStudy_maker: {
    url: (pagesNumber, phone, nationalId) =>
      `${Url}management_dashboard/user_dashboard/manual/mkr/list/?page=${pagesNumber}${
        phone
          ? `&user__phone=${phone}`
          : nationalId
          ? `&national_id=${nationalId}`
          : ""
      }`,
    method: "GET",
  },
  manualStudy_checker: {
    url: (pageNumber) =>
      `${Url}management_dashboard/user_dashboard/manual/chkr/list/?page=${pageNumber}`,
    method: "GET",
  },
  maker_beginStudy: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/mkr/begin_study/${userId}/`,
    method: "POST",
  },
  checker_retrieve: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/chkr/retrieve/${userId}/`,
    method: "GET",
  },
  checker_return_to_maker: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/chkr/return_to_maker/${userId}/`,
    method: "PATCH",
  },
  checker_approve: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/chkr/approve/${userId}/`,
    method: "PATCH",
  },
  checker_comment: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/chkr/add_comment/${userId}/`,
    method: "PATCH",
  },
  maker_reject: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/mkr/reject/${userId}/`,
    method: "PATCH",
  },
  retrieve_checker_comment: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/manual/mkr/retrieve_comment/${userId}/`,
    method: "GET",
  },
  // Limit increase EPS
  limitMaker_search: {
    url: (phone, national_id) =>
      `${Url}management_dashboard/user_dashboard/limit/mkr/search?${
        phone ? `phone=${phone}` : `national_id=${national_id}`
      }`,
    method: "GET",
  },
  limitMaker_beginStudy: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/mkr/begin_processing/${userId}/`,
    method: "POST",
  },
  limitChecker_list: {
    url: (pageNumber) =>
      `${Url}management_dashboard/user_dashboard/limit/chkr/list/?page=${pageNumber}`,
    method: "GET",
  },
  limitChecker_retrieve: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/chkr/retrieve/${userId}/`,
    method: "PATCH",
  },
  limitChecker_comment: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/chkr/add_comment/${userId}/`,
    method: "PATCH",
  },
  limitChecker_returnToMaker: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/chkr/return_to_maker/${userId}/`,
    method: "PATCH",
  },
  limitMaker_list: {
    url: (pageNumber) =>
      `${Url}management_dashboard/user_dashboard/limit/mkr/list/?page=${pageNumber}`,
    method: "GET",
  },
  limitMaker_retrieveComment: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/mkr/retrieve_comment/${userId}/`,
    method: "GET",
  },
  limitChecker_approve: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/chkr/approve/${userId}/`,
    method: "PATCH",
  },
  limitChecker_reject: {
    url: (userId) =>
      `${Url}management_dashboard/user_dashboard/limit/chkr/reject/${userId}/`,
    method: "PATCH",
  },
  actions_list: {
    url: `${Url}management_dashboard/user_dashboard/user_actions/`,
    method: "GET",
  },
  actions: {
    url: (actionValue, ids) =>
      `${Url}management_dashboard/user_dashboard/action/${actionValue}/?ids=${ids}`,
    method: "PUT",
  },
  action_report: {
    url: (actionValue, ids) =>
      `${Url}management_dashboard/user_dashboard/action/${actionValue}/?ids=${ids}`,
    method: "GET",
  },

  // userRequest EP
  user_request_list: {
    url: (
      page,
      state,
      requestValue,
      id,
      department,
      createdStartDate,
      createdEndDate,
      payingStatus
    ) =>
      `${Url}accounts/request_tool/?page=${page}${
        state ? `&status=${state}` : ""
      }${requestValue?.length ? `&request_type__in=${requestValue}` : ""}${
        id ? `&search=${id}` : ""
      }${department?.length ? `&department=${department}` : ""}${
        createdStartDate
          ? `&created__date__range=${createdStartDate},${createdEndDate}`
          : ""
      }${payingStatus ? `&paid=${payingStatus}` : ""}`,
    method: "GET",
  },
  status_options: {
    url: `${Url}accounts/request_tool/status_options/`,
    method: "GET",
  },
  request_types: {
    url: `${Url}accounts/request_tool/request_types/`,
    method: "GET",
  },
  request_tool_action: {
    url: (id) => `${Url}accounts/request_tool/perform_action/${id}/`,
    method: "PATCH",
  },
  department_options: {
    url: `${Url}accounts/request_tool/department_options/`,
    method: "GET",
  },
  actions_history: {
    url: (id, pagesNumber) =>
      `${Url}accounts/request_tool/actions_history/${id}/?page=${pagesNumber}`,
    method: "GET",
  },
  branches_options: {
    url: `${Url}accounts/forsa_branch_options/`,
    method: "GET",
  },
  changeBranch: {
    url: (id) => `${Url}accounts/request_tool/change_forsa_branch/${id}/`,
    method: "PATCH",
  },

  // upload media
  uploadMultipleFile: {
    url: `${Url}stores/Upload_multiple_media/`,
    method: "POST",
  },

  // oneTransaction

  // get profile info
  profileInfo: {
    url: (nationa_id) =>
      `${Url}onetransaction/userprofile/?national_id=${nationa_id}`,
    method: "GET",
  },

  ProfileOptions: {
    url: `${Url}onetransaction/`,
    method: "OPTIONS",
  },

  newProfile: {
    url: (profileId) =>
      `${Url}onetransaction${profileId ? `/${profileId}` : ""}/`,
    // method: "POST",
  },

  AllApplications: {
    url: (params) => `${Url}onetransaction/?${params}`,
    method: "GET",
  },

  Application: {
    url: (id) => `${Url}onetransaction/${id}/`,
    method: "GET",
  },

  onTransactionCities: {
    url: `${Url}onetransaction/cities/`,
    method: "GET",
  },

  onTransactionArea: {
    url: (city) => `${Url}onetransaction/areas/?governorate=${city}`,
    method: "GET",
  },

  onTransactionSalesAgent: {
    url: `${Url}onetransaction/salesman_and_agency/`,
    method: "GET",
  },

  // Legal Tool
  search_list: {
    url: (nationalId) =>
      `${Url}legal/get_user/?userprofile__national_id=${nationalId}`,
    method: "GET",
  },
  alert_list: {
    url: (id, pageNumber) =>
      `${Url}legal/alert_list/?id=${id}&page=${pageNumber}`,
    method: "GET",
  },
  lawsuit_list: {
    url: (id, pageNumber) =>
      `${Url}legal/lawsuit_list/?id=${id}&page=${pageNumber}`,
    method: "GET",
  },
  session_list: {
    url: (id, pageNumber) =>
      `${Url}legal/session_list/?id=${id}&page=${pageNumber}`,
    method: "GET",
  },
  fees_list: {
    url: (id, pageNumber) =>
      `${Url}legal/legal_fees/?id=${id}&page=${pageNumber}`,
    method: "GET",
  },
  get_alert: {
    url: (alert_id, id) =>
      `${Url}legal/alert/details/${alert_id}/?user_id=${id}`,
    method: "GET",
  },
  get_session: {
    url: (session_id, id) =>
      `${Url}legal/session/details/${session_id}/?id=${id}`,
    method: "GET",
  },
  get_lawsuit: {
    url: (lawsuit_id, id) =>
      `${Url}legal/lawsuit/details/${lawsuit_id}/?id=${id}`,
    method: "GET",
  },
  get_fees: {
    url: (fees_id) => `${Url}legal/legal_fees/${fees_id}/`,
    method: "GET",
  },
  create_alert: {
    url: (id) => `${Url}legal/create_alert/?id=${id}`,
    method: "POST",
  },
  create_session: {
    url: (id) => `${Url}legal/session/create/?id=${id}`,
    method: "POST",
  },
  create_lawsuit: {
    url: (id) => `${Url}legal/lawsuit/create/?id=${id}`,
    method: "POST",
  },
  create_fees: {
    url: (id) => `${Url}legal/legal_fees/?id=${id}`,
    method: "POST",
  },
  update_alert: {
    url: (alert_id, id) =>
      `${Url}legal/update_alert/${alert_id}/?user_id=${id}`,
    method: "PATCH",
  },
  update_session: {
    url: (session_id, id) =>
      `${Url}legal/session/update/${session_id}/?id=${id}`,
    method: "PATCH",
  },
  update_lawsuit: {
    url: (lawsuit_id, id) =>
      `${Url}legal/lawsuit/update/${lawsuit_id}/?id=${id}`,
    method: "PATCH",
  },
  update_fees: {
    url: (fees_id, id) => `${Url}legal/legal_fees/${fees_id}/?id=${id}`,
    method: "PATCH",
  },
  create_image: {
    url: (id) => `${Url}legal/legal/create/?id=${id}`,
    method: "POST",
  },
  image_list: {
    url: (id, pagesNumber) =>
      `${Url}legal/legal/list/?id=${id}&page=${pagesNumber}`,
    method: "GET",
  },
  delete_image: {
    url: (image_id, id) => `${Url}legal/legal/update/${image_id}/?id=${id}`,
    method: "DELETE",
  },
  user_reconciliation: {
    url: `${Url}legal/reconciliation/`,
    method: "POST",
  },

  // Reports
  finance_report: {
    url: (from, to) =>
      `${Url}finance/payment_report/?date_gte=${from}&date_lte=${to}`,
    method: "GET",
  },
  device_report: {
    url: (modifiedFrom, modifiedTo, createdFrom, createdTo) =>
      `${Url}management_dashboard/user_dashboard/export_user_login_devices/?${
        modifiedFrom
          ? `updated_at__date__gte=${modifiedFrom}&updated_at__date__lte=${modifiedTo}`
          : ""
      }${
        createdFrom
          ? `created_at__date__gte=${createdFrom}&created_at__date__lte=${createdTo}`
          : ""
      } `,
    method: "GET",
  },
  // data entry history
  userProfile_loansHistory: {
    url: (page, user_id) =>
      `${Url}onetransaction/onetransactionloan/?page=${page}&user=${user_id}`,
    method: "GET",
  },
  loanHistory: {
    url: (page, loan_id) =>
      `${Url}onetransaction/onetransactionhistory/?page=${page}&onetransactionloan=${loan_id}`,
    method: "GET",
  },

  // one transaction credit

  onTransactionProgram: {
    url: `${Url}onetransaction/program/`,
    method: "GET",
  },
  onTransactionTenors: {
    url: (programId) => `${Url}onetransaction/tenure/?program=${programId}`,
    method: "GET",
  },

  onTransactionCreateLiablity: {
    url: `${Url}onetransaction/userliabilities/`,
    method: "POST",
  },

  onTransactionLiablity: {
    url: (user) => `${Url}onetransaction/userliabilities/?user=${user}`,
    method: "GET",
  },

  onTransactionEditLiablity: {
    url: (liablitiyId) =>
      `${Url}onetransaction/userliabilities/${liablitiyId}/`,
    method: "PATCH",
  },

  onTransactionCreateCredit: {
    url: `${Url}onetransaction/onetransactionloan/`,
    method: "POST",
  },

  onTransactionEditCredit: {
    url: (creditId) => `${Url}onetransaction/onetransactionloan/${creditId}/`,
    method: "PATCH",
  },
  getLoanData: {
    url: (loanId) => `${Url}onetransaction/onetransactionloan/${loanId}/`,
    method: "GET",
  },

  onTransactionCredits: {
    url: (params) => `${Url}onetransaction/onetransactionloan/?${params}`,
    method: "GET",
  },

  onTransactionCreditsOptions: {
    url: `${Url}onetransaction/onetransactionloan/`,
    method: "OPTIONS",
  },

  onTransactionIscore: {
    url: (loanId) => `${Url}onetransaction/user/pdfreport/?loan=${loanId}`,
    method: "GET",
  },

  onTransactionAddComment: {
    url: `${Url}onetransaction/onetransactioncomments/`,
    method: "POST",
  },

  onTransactionEditComment: {
    url: (commentId) =>
      `${Url}onetransaction/onetransactioncomments/${commentId}/`,
    method: "PATCH",
  },

  credit_limit: {
    url: (param) =>
      `${Url}management_dashboard/user_dashboard/userlimitdetail/?${param}`,
    method: "GET",
  },

  // one transaction operation

  operationList: {
    url: (page, search, creditSelected) =>
      `${Url}onetransaction/onetransactionloan/?page=${page}${
        search
          ? `&search=${search}`
          : creditSelected && creditSelected !== "All"
          ? `&status=${creditSelected}`
          : `&status=approved_credit,studying_operation,restudying_operation,checking_operation`
      }`,
    method: "GET",
  },

  sendToOperationChekcer: {
    url: (loanId) => `${Url}onetransaction/onetransactionloan/${loanId}/`,
    method: "PATCH",
  },
  // one transaction finance
  financeList: {
    url: (page, search, creditSelected) =>
      `${Url}onetransaction/onetransactionloan/?page=${page}${
        search
          ? `&search=${search}`
          : creditSelected && creditSelected !== "All"
          ? `&status=${creditSelected}`
          : `&status=approved_operation,studying_finance,restudying_finance,checking_finance`
      }`,
    method: "GET",
  },
  ReturnToDepartment: {
    url: (loanId) => `${Url}onetransaction/onetransactionloan/${loanId}/`,
    method: "PATCH",
  },

  // Deviation
  deviationList: {
    url: `${Url}onetransaction/onetransactiondeviation/`,
    method: "GET",
  },
  // financeOptions
  financeOptions: {
    url: `${Url}onetransaction/finance/loanstatusoptions/`,
    method: "OPTIONS",
  },

  onTransactionGetComments: {
    url: (params) => `${Url}onetransaction/onetransactioncomments/?${params}`,
    method: "GET",
  },
  // one transaction operation

  deviationApprove: {
    url: `${Url}onetransaction/onetransactiondeviationapprovals/`,
    method: "POST",
  },

  // cheques
  onTransactionCreateCheque: {
    url: `${Url}onetransaction/chequesdetails/`,
    method: "POST",
  },

  onTransactionCheques: {
    url: (loanId, page) =>
      `${Url}onetransaction/chequesdetails/?onetransactionloan=${loanId}&page=${page}`,
    method: "GET",
  },

  onTransactionEditCheque: {
    url: (chequeId) => `${Url}onetransaction/chequesdetails/${chequeId}/`,
    method: "PATCH",
  },

  // document in loans
  onTransactionLoanDocuments: {
    url: (loanId, department) =>
      `${Url}onetransaction/loandocuments/?onetransactionloan=${loanId}&department=${department}`,
    method: "GET",
  },

  // returned comment history
  onTransactionReturnedComments: {
    url: (profileId) =>
      `${Url}onetransaction/comment/?onetransactionprofile=${profileId}`,
    method: "GET",
  },

  // User Profile
  hold_reason_list: {
    url: `${Url}management_dashboard/user_dashboard/hold_reason_list/`,
    method: "GET",
  },

  user_hold: {
    url: (ids) =>
      `${Url}management_dashboard/user_dashboard/action/hold/?ids=${ids}`,
    method: "PUT",
  },
  // Notification
  notification_list: {
    url: (params) => `${Url}accounts/triggers/${params}`,
    method: "GET",
  },
  delete_notification: {
    url: (notification_id) => `${Url}accounts/triggers/${notification_id}/`,
    method: "DELETE",
  },

  userOptions: {
    url: (cities) =>
      `${Url}accounts/user_filter_options/${
        cities ? `?cities= ${cities}` : ""
      }`,
    method: "OPTIONS",
  },
  tergetCount: {
    url: (params) => `${Url}accounts/user-count/${params ? `?${params}` : ""}`,
    method: "GET",
  },
  audiences: {
    url: (params) => `${Url}accounts/audience/${params}`,
    method: "GET",
  },
  // Collection Tool

  advanced_users: {
    url: (pageNumber, national_id) =>
      `${Url}finance/cash_collection/advanced_payments/advanced_payment_balance/?page=${pageNumber}${
        national_id ? `&national_id=${national_id}` : ""
      }`,
    method: "GET",
  },
  finance_list: {
    url: (pagesNumber, nationa_id, date) =>
      `${Url}finance/cash_collection/advanced_payments/?page=${pagesNumber}${
        nationa_id ? `&national_id=${nationa_id}` : ""
      }${date?.from?.length ? `&created_at__date__gte=${date?.from}` : ""}${
        date?.to?.length ? `&created_at__date__lte=${date?.to}` : ""
      }`,
    method: "GET",
  },
  cash_collect: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/cash_collect/`,
    method: "POST",
  },
  user_installments: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/installments/`,
    method: "GET",
  },
  user_products: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/products/`,
    method: "GET",
  },
  user_penalties: {
    url: (user_id, created, amount, due_date, remaining_dues, on_hold) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/penalties/${
        created || due_date || amount || remaining_dues || on_hold
          ? `?order_by=${
              created || due_date || amount || remaining_dues || on_hold
            }`
          : ""
      }`,
    method: "GET",
  },
  waive_penalties: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/waive_penalties/`,
    method: "POST",
  },
  update_penalties: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/update_penalties_waive_percentage/`,
    method: "POST",
  },
  advanced_payment: {
    url: (user_id) =>
      `${Url}finance/cash_collection/advanced_payments/advanced_payment_balance/for_profile/${user_id}/`,
    method: "GET",
  },

  // onetransaction payment
  oneTransactionReport: {
    url: `${Url}onetransaction/installment_export/`,
    method: "POST",
  },

  hold_penalties: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/hold_penalties/`,
    method: "POST",
  },
  unhold_penalties: {
    url: (user_id) =>
      `${Url}finance/cash_collection/user_profile/${user_id}/un_hold_penalties/`,
    method: "POST",
  },
  checker_confirm: {
    url: `${Url}finance/cash_collection/advanced_payments/confirm_advanced_payments/`,
    method: "PATCH",
  },

  // User location history
  user_location: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/user_history_locations/${user_id}/`,
    method: "GET",
  },

  // Blacklist history
  blackList_history: {
    url: (user_id, page) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${user_id}/blacklist_records/?page=${page}`,
    method: "GET",
  },
  blackList_activate: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${user_id}/blacklist_records/activate/`,
    method: "POST",
  },
  blackList_deactivate: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/users_profile/${user_id}/blacklist_records/deactivate/`,
    method: "POST",
  },
  OnBoard_list: {
    url: (searchParams) =>
      `${Url}merchant_on_boarding/DraftViewList/?${searchParams}`,
    url_updated: (itemId) =>
      `${Url}merchant_on_boarding/DraftViewUpdate/${itemId}/`,
    url_created: () => `${Url}merchant_on_boarding/CreateNewDraft/`,
    method: "GET",
  },
  Brand_Class: {
    url: (page) =>
      `${Url}merchant_on_boarding/BrandClassViewList/?page=${page}`,
    method: "GET",
  },
  Location: {
    url: (page) => `${Url}merchant_on_boarding/LocationViewList/?page=${page}`,
    method: "GET",
  },
  reject_resone: {
    url: `${Url}merchant_on_boarding/RejectedReasonViewList/`,
    method: "GET",
  },
  comments: {
    url: (DraftID) => `${Url}merchant_on_boarding/CommentsViewList/${DraftID}/`,
    method: "GET",
  },
  commentsbByCategory: {
    url: (DraftID, category) =>
      `${Url}merchant_on_boarding/CommentsViewListbyCategory/${DraftID}/?created_by=${category}`,
    method: "GET",
  },
  documents: {
    url: (DraftID, page) =>
      `${Url}merchant_on_boarding/DocumentsViewList/${DraftID}/?page=${page}`,
    method: "GET",
  },
  exportDraft: {
    url: `${Url}merchant_on_boarding/ExportDraft/`,
    method: "POST",
  },
  auditLogList: {
    url: (DraftID) => `${Url}merchant_on_boarding/AuditLogList/${DraftID}/`,
    method: "GET",
  },

  failure_requests: {
    url: (req, RequestParams) =>
      `${Url}management_dashboard/requests_failed/${req}/${RequestParams}`,
    url_post: (topic) =>
      `${Url}management_dashboard/requests_failed/resend/${topic}/`,
    method: "GET",
  },
  importProfiles: {
    url: `${Url}onetransaction/profile/import/`,
    method: "POST",
  },

  exportProfiles: {
    url: `${Url}onetransaction/profile/export/`,
    method: "POST",
  },

  importLoans: {
    url: `${Url}onetransaction/loan/import/`,
    method: "POST",
  },

  exportLoans: {
    url: `${Url}onetransaction/loan/export/`,
    method: "POST",
  },

  printContract: {
    url: (loanId) => `${Url}onetransaction/contract/${loanId}/`,
    method: "GET",
  },

  // One Transaction Collection
  collection_list: {
    url: (pageNumber, state, searchValue) =>
      `${Url}management_dashboard/user_dashboard/one_txn_profiles/?page=${pageNumber}${
        state ? `&state=${state}` : ""
      }${searchValue ? `&search=${searchValue}` : ""}`,
    method: "GET",
  },
  collection_options: {
    url: `${Url}management_dashboard/user_dashboard/one_txn_profiles/`,
    method: "OPTIONS",
  },
  monthly_installments: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/installments/`,
    method: "GET",
  },
  collection_products: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/products/`,
    method: "GET",
  },
  collection_penalties: {
    url: (user_id, created, amount, due_date, remaining_dues, on_hold) =>
      `${Url}/finance/cash_collection/one_txn/user_profile/${user_id}/penalties/${
        created || due_date || amount || remaining_dues || on_hold
          ? `?order_by=${
              created || due_date || amount || remaining_dues || on_hold
            }`
          : ""
      }`,
    method: "GET",
  },
  cash_collection_oneTxn: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/cash_collect/`,
    method: "POST",
  },
  update_penalties_oneTxn: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/update_penalties_waive_percentage/`,
    method: "POST",
  },
  waive_penalties_oneTxn: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/waive_penalties/`,
    method: "POST",
  },
  hold_penalties_oneTxn: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/hold_penalties/`,
    method: "POST",
  },
  unhold_penalties_oneTxn: {
    url: (user_id) =>
      `${Url}finance/cash_collection/one_txn/user_profile/${user_id}/un_hold_penalties/`,
    method: "POST",
  },
  advanced_payment_oneTxn: {
    url: (user_id) =>
      `${Url}finance/cash_collection/advanced_payments/advanced_payment_balance/for_one_txn_profile/${user_id}/`,
    method: "GET",
  },
  // Cashback history
  cashback_history: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/cashback-history/${user_id}/`,
    method: "GET",
  },
  // User statment
  CertificationData: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/user_invoice_history/${user_id}/`,
    method: "GET",
  },
  statement: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/account_statement/profile/${user_id}/statement/`,
    method: "GET",
  },
  statement_details: {
    url: (user_id) =>
      `${Url}management_dashboard/user_dashboard/account_statement/profile/${user_id}/statement_details/`,
    method: "GET",
  },
  loan_statement: {
    url: (user_id, loanId) =>
      `${Url}management_dashboard/user_dashboard/account_statement/profile/${user_id}/statement_details/loan/${loanId}/`,
    method: "GET",
  },
  loan_statement_fines: {
    url: (user_id, loanId) =>
      `${Url}management_dashboard/user_dashboard/account_statement/profile/${user_id}/statement/loan/${loanId}/`,
  },
  // Printing Templates

  templates_id: {
    url: `${Url}accounts/printing_templates/`,
    method: "GET",
  },
  user_template: {
    url: (user_id, unique_name) =>
      `${Url}accounts/printing_templates/${unique_name}/?profile_id=${user_id}`,
    method: "GET",
  },
  onwerList: {
    url: (page) => `${Url}merchant_on_boarding/OwnersViewList/?page=${page}`,
    method: "GET",
  },

  updateBrandsOwners: {
    url: `${Url}merchant_on_boarding/UpdateBrandssWithOwner/`,
    method: "PUT",
  },

  //start .. Iscore tool
  searchIscore: {
    url: (
      pageNumber,
      nationalId,
      iscoreState,
      from,
      to,
      initiatorSelected,
      reportLanguage,
      param
    ) =>
      `${Url}accounts/iscore_tool/?page=${pageNumber}${
        nationalId ? `&national_id=${nationalId}` : ""
      }${iscoreState ? `&get_iscore=${iscoreState}` : ""}${
        from !== "Invalid date" ? `&from_date=${from}` : ""
      }${
        from !== "Invalid date" && to !== "Invalid date" ? `&to_date=${to}` : ""
      }${initiatorSelected ? `&initiator=${initiatorSelected}` : ""}${
        reportLanguage ? `&report_language=${reportLanguage}` : ""
      }${param ? `&page_size=${param}` : ""}`,
    method: "GET",
  },
  iscoreInitiators: {
    url: `${Url}accounts/iscore_tool_initiator/`,
    method: "GET",
  },
  importIscores: {
    url: `${Url}accounts/bulk_request_iscore/`,
    method: "POST",
  },
  download_report: {
    url: `${Url}accounts/download-iscore-files/`,
    method: "POST",
  },
  //end .. Iscore tool

  offerOverView: {
    url: (params) => `${Url}automation_tool/offers/?${params}`,
    method: "GET",
  },
};
