/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from "react";
import "../generalInfo/GeneralInfo.scss"; //90% of style are the same as general info
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox, InputLabel, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import * as plans from "../categoryDetails/Plans.json";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import { toast } from "react-toastify";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment";
import * as Yup from "yup";
import OfferTable from "./offerTable/offerTable";
import { Table1Data, Table2Data, textFields, checkboxes } from "./Data";
import ImageCropper from "../imageCropper/imageCropper";
import { Colors } from "../../constants/Colors";
import { ENDPOINTS } from "../../apis/EndPoints";
import PaginationComponent from "../pagination/PaginationComponent";
import { TagComponent } from "../Tags/TagComponent";
import { Accordion } from "react-bootstrap";
import MultipleSelection from "../dropdown/MultipleSelection";
import MerchantToolHistory from "../MerchantToolHistory/MerchantToolHistory";
import { PaginateSelectedComponent } from "../CustomSelectedComponent/PaginateSelectedComponent";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { addHours, format } from "date-fns";

const validateYup = Yup.object({
  slug: Yup.string()
    .matches(
      /^[a-z0-9]+(?:[._-][a-z0-9]+)*$/g,
      "No capital or special character or space"
    )
    .required("Required*"),
  title_en: Yup.string().required("Required*"),
  title_ar: Yup.string().required("Required*"),
  description_en: Yup.string().required("Required*"),
  description_ar: Yup.string().required("Required*"),
  product_label: Yup.string(),
  price: Yup.string().matches(/^[0-9]+$/, "invalid number "),

  category: Yup.string().required("Required*"),
  order: Yup.number().required("Required*"),

  board_description_ar: Yup.string().when("is_offer_board", {
    is: (is_offer_board) =>
      typeof club_name === "boolean" && is_offer_board === true,
    then: Yup.string().required("*This field is required.").nullable(),
    otherwise: Yup.string().nullable(),
  }),

  board_description_en: Yup.string().when("is_offer_board", {
    is: (is_offer_board) =>
      typeof club_name === "boolean" && is_offer_board === true,
    then: Yup.string().required("*This field is required.").nullable(),
    otherwise: Yup.string().nullable(),
  }),

  board_background: Yup.string().when("is_offer_board", {
    is: (is_offer_board) =>
      typeof club_name === "boolean" && is_offer_board === true,
    then: Yup.string().required("*This field is required.").nullable(),
    otherwise: Yup.string().nullable(),
  }),
});

const OfferDetails = () => {
  let navigate = useNavigate();
  const { brand } = useParams();
  const location = useLocation();
  // const { brandName, brandId, newOffer, offerId, min_percentage } = location?.state;
  const { brandName, brandId, offerData, newOffer } = location?.state;
  const [brandBranches, setBrandBranches] = useState([]);
  const [branchesId, setBranchesId] = useState([]);
  const [installmentPlans, setInstallmentPlans] = useState();

  const [expirationDate, setExpirationDate] = useState();
  const { handleLoader, permissions } = useContext(UserContext);
  // new params
  const [imageToCropThumbnail, setImageToCropThumbnail] = useState("");
  const [imageToCropBackground, setImageToCropBackground] = useState("");
  const [thumbnailCropped, setThumbnailCropped] = useState();
  const [backgroundCropped, setBackgroundCropped] = useState();
  // web
  const [imageToCropThumbnailWeb, setImageToCropThumbnailWeb] = useState("");
  const [imageToCropBackgroundWeb, setImageToCropBackgroundWeb] = useState("");
  const [thumbnailCroppedWeb, setThumbnailCroppedWeb] = useState();
  const [backgroundCroppedWeb, setBackgroundCroppedWeb] = useState();
  const [boardBackground, setBoardBackground] = useState();

  // branshes
  const [page, setPage] = useState(1);
  const [branchesDetail, setBranchesDetail] = useState();
  const [offerDetail, setOfferDetail] = useState();
  // const formRef = useRef();
  const today = new Date();
  const isMarketing = permissions.includes("Marketing_merchant_creation_tool")
    ? true
    : false;
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isBranchesCheckedAll, setIsBranchesCheckedAll] = useState();
  // const [isPlanChanged, setIsPlanChanged] = useState(false)
  // const dummyIds = [1607, 2024, 2030, 2620, 2622, 2623, 2625, 2631, 1633, 3507, 3508, 3509]
  const [isRiskOpen, setIsRiskOpen] = useState(false);
  const [mailList, setMailList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isSendRisk, setIsSendRisk] = useState(false);
  const [riskSendData, setRiskSendData] = useState({
    comment: "",
    selectedList: [],
  });
  const isReadOnly =
    permissions.includes("BD_merchant_creation_tool") &&
    !offerDetail?.start_date
      ? true
      : false;
  const [selectedAudience, setSelectedAudience] = useState();
  const [value, setValue] = React.useState();

  useEffect(() => {
    if (brandId) {
      if (
        (isBranchesCheckedAll === true || isBranchesCheckedAll === false) &&
        branchesId?.length !== branchesDetail?.count
      ) {
        getBranches(branchesId);
      } else {
        getBranches(branchesId);
      }
    }
  }, [page]);

  useEffect(() => {
    if (newOffer) {
      getSpecialBrand();
    } else {
      // setMinPercent(min_percentage)
      getDetail();
    }
  }, []);

  useEffect(() => {
    isRiskOpen && getMails();
  }, [isRiskOpen]);

  // to handle checkbox of all branches in case all branches already selected when open this offer
  useEffect(() => {
    if (branchesId?.length === branchesDetail?.count) {
      setIsBranchesCheckedAll(true);
    }
  }, [branchesDetail]);

  // check expiry date
  /*
  const expiryDate = (date_string) => {
    var expiration = moment(date_string).format("YYYY-MM-DD");
    var current_date = moment().format("YYYY-MM-DD");
    var isBefore = moment(expiration).isBefore(current_date, "days");
    return isBefore;
  };
*/
  // to get min_percentage of brand if create new offer
  const getSpecialBrand = async () => {
    await UserUtility.getSpecialBrand(brandId)
      .then((res) => {
        // setMinPercent(res?.min_percentage);

        let arrOfMinPercent = [
          {
            months: 6,
            value: res?.min_percentage_6_months,
          },
          {
            months: 9,
            value: res?.min_percentage_9_months,
          },
          {
            months: 12,
            value: res?.min_percentage_12_months,
          },
          {
            months: 18,
            value: res?.min_percentage_18_months,
          },
          {
            months: 24,
            value: res?.min_percentage_24_months,
          },
          {
            months: 30,
            value: res?.min_percentage_30_months,
          },
          {
            months: 36,
            value: res?.min_percentage_36_months,
          },
          {
            months: 42,
            value: res?.min_percentage_42_months,
          },
          {
            months: 48,
            value: res?.min_percentage_48_months,
          },
          {
            months: 54,
            value: res?.min_percentage_54_months,
          },
          {
            months: 60,
            value: res?.min_percentage_60_months,
          },
          {
            months: 61,
            value: res?.min_percentage_61_months,
          },
        ];

        getDefaultInstall(arrOfMinPercent);
      })
      .catch((error) => console.error("getting special brand error", error));
  };

  const getDetail = () => {
    handleLoader(true);
    UserUtility.getOfferDetail(offerData?.id)
      .then((res) => {
        handleLoader(false);
        setOfferDetail(res.results[0]);
        setBranchesId(res.results[0]?.branches);
        setThumbnailCropped(res.results[0]?.thumbnail);
        setBackgroundCropped(res.results[0]?.background);
        setThumbnailCroppedWeb(res.results[0]?.thumbnail_web);
        setBackgroundCroppedWeb(res.results[0]?.background_web);
        setExpirationDate(res.results[0]?.expiry_date);
        setBoardBackground(res.results[0]?.board_background);
        // to change active branches
        getBranches(res.results[0]?.branches);
        setBranchesId(res.results[0]?.branches);
        setSelectedAudience(res.results[0]?.audience_details);
        let arrOfMinPercent = [
          {
            months: 6,
            value: offerData?.min_percentage_6_months,
          },
          {
            months: 9,
            value: offerData?.min_percentage_9_months,
          },
          {
            months: 12,
            value: offerData?.min_percentage_12_months,
          },
          {
            months: 18,
            value: offerData?.min_percentage_18_months,
          },
          {
            months: 24,
            value: offerData?.min_percentage_24_months,
          },
          {
            months: 30,
            value: offerData?.min_percentage_30_months,
          },
          {
            months: 36,
            value: offerData?.min_percentage_36_months,
          },
          {
            months: 42,
            value: offerData?.min_percentage_42_months,
          },
          {
            months: 48,
            value: offerData?.min_percentage_48_months,
          },
          {
            months: 54,
            value: offerData?.min_percentage_54_months,
          },
          {
            months: 60,
            value: offerData?.min_percentage_60_months,
          },
          {
            months: 61,
            value: offerData?.min_percentage_61_months,
          },
        ];

        const newCat =
          res.results[0]?.installment_plans.length &&
          res.results[0]?.installment_plans.map((category, index) => {
            return {
              ...category,
              min_percentage:
                arrOfMinPercent[index].months ===
                res.results[0]?.installment_plans[index]?.months
                  ? arrOfMinPercent[index].value
                  : null,
              total: calculateMax(
                category.interest_rate,
                category.admin_fees.value,
                category.admin_fees.kick_back,
                category.admin_fees.forsa_processing_fees,
                category.admin_fees.paymob_processing_fees
              ),
            };
          });

        if (res.results[0]?.installment_plans.length > 0) {
          setInstallmentPlans(newCat);
        } else {
          getDefaultInstall(arrOfMinPercent);
        }
      })
      .catch((err) => {
        handleLoader(false);
        console.log("error of offer detail: ", err);
      });
  };
  //to get Branches from BE
  const getBranches = (ids = []) => {
    setBrandBranches([]);
    UserUtility.getBrandBranches(brandId, page)
      .then((res) => {
        setBranchesDetail(res);
        //add active key to each object to change it later to send active ids to BE
        let addActiveToBranches = res?.results?.map((branch) => ({
          ...branch,
          active: ids.length && ids.includes(branch.id) ? true : false,
        }));

        setBrandBranches(addActiveToBranches);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //to handle active branches check
  const handleActiveBranch = (checkId) => {
    const newBranches = brandBranches?.map((category, id) => {
      return {
        ...category,
        active: checkId === category.id ? !category.active : category.active,
      };
    });

    let arr = [...branchesId];
    let ind = arr.indexOf(checkId);
    if (ind > -1) {
      arr.splice(ind, 1);
    } else {
      arr.push(checkId);
    }

    if (arr?.length !== branchesDetail?.count) {
      setIsBranchesCheckedAll(false);
    } else {
      setIsBranchesCheckedAll(true);
    }
    setBranchesId(arr);
    setBrandBranches(newBranches);
  };

  // handle check all branches
  const handleBranchesSelectAll = (event) => {
    setIsBranchesCheckedAll(event.target.checked);

    if (event.target.checked) {
      UserUtility.activeBrandBranches(brandId)
        .then((res) => {
          let allIds = res.map((item, _) => {
            return item.id;
          });
          //add active key to each object to change it later to send active ids to BE
          let addActiveToBranches = brandBranches?.map((branch) => ({
            ...branch,
            active: allIds.length && allIds.includes(branch.id) ? true : false,
          }));
          setBrandBranches(addActiveToBranches);
          setBranchesId(allIds);
        })
        .catch((err) => {
          console.log("activeBrandBranches err: ", err);
        });
    } else {
      setBranchesId([]);
      let addActiveToBranches = brandBranches?.map((branch) => ({
        ...branch,
        active: false,
      }));
      setBrandBranches(addActiveToBranches);
    }
  };

  //to handle installment plans values
  const handlePlanNumbers = (index, planKey, e) => {
    // setIsPlanChanged(true)
    const newInstallmentPlans = installmentPlans.map((plan, id) => {
      return {
        ...plan,
        [planKey]:
          index === id
            ? planKey === "grace_period"
              ? e.target.value
              : parseFloat(e.target.value)
            : parseFloat(plan[planKey]),

        total: calculateMax(
          index === id && planKey === "interest_rate"
            ? parseFloat(e.target.value)
            : plan.interest_rate,
          plan.admin_fees.value,
          plan.admin_fees.kick_back,
          plan.admin_fees.forsa_processing_fees,
          plan.admin_fees.paymob_processing_fees
        ),
      };
    });
    setInstallmentPlans(newInstallmentPlans);
  };

  //to handle object of object
  const handlePlanNumbers2 = (index, planKey, keyOfPlanKey, e) => {
    // setIsPlanChanged(true)
    const newInstallments = installmentPlans.map((eachPlan, id) => {
      return {
        ...eachPlan,
        [planKey]: {
          ...eachPlan[planKey],
          [keyOfPlanKey]:
            index === id
              ? parseFloat(e.target.value)
              : parseFloat(eachPlan[planKey][keyOfPlanKey]),
        },
        total: calculateMax(
          eachPlan.interest_rate,
          index === id && keyOfPlanKey === "value"
            ? parseFloat(e.target.value)
            : eachPlan.admin_fees.value,
          index === id && keyOfPlanKey === "kick_back"
            ? parseFloat(e.target.value)
            : eachPlan.admin_fees.kick_back,
          index === id && keyOfPlanKey === "forsa_processing_fees"
            ? parseFloat(e.target.value)
            : eachPlan.admin_fees.forsa_processing_fees,
          index === id && keyOfPlanKey === "paymob_processing_fees"
            ? parseFloat(e.target.value)
            : eachPlan.admin_fees.paymob_processing_fees
        ),
      };
    });

    setInstallmentPlans(newInstallments);
  };

  // to calculate sum of attributes value in installments
  const calculateMax = (interest, fees, kick, forsa_processing, paymob) => {
    return (
      (interest ? interest : 0) +
      (fees ? fees : 0) +
      (kick ? kick : 0) +
      (forsa_processing ? forsa_processing : 0) +
      (paymob ? paymob : 0)
    );
  };

  const editAndPostOffer = (values) => {
    if (dayjs(expirationDate) === today) {
      toast.error("Please don't select today as expiry date ");
    } else if (dayjs(expirationDate) < today) {
      toast.error("Expiry date should be after today ");
    } else {
      //this block of code is to upload pics and it is seperated from rest of data
      //to remove background, thumbnail and category from the object
      const { category: valuesCategory, ...newValues } = values;

      let convertCategory = {
        id: offerDetail?.category?.id,
        title: valuesCategory,
        title_en: valuesCategory,
        title_ar: valuesCategory,
        published: offerDetail?.category
          ? offerDetail?.category?.published
          : null,
        is_online: offerDetail?.category
          ? offerDetail?.category?.is_online
          : null,
      };
      let newOfferData = {
        ...newValues,
        installment_plans: installmentPlans,
        category: convertCategory,
        description: newValues.description_en,
        title: newValues.title_en,
        brand: brandId,
        branches: branchesId,
        expiry_date: expirationDate
          ? dayjs(expirationDate).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        start_date: values.start_date
          ? dayjs(values.start_date).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        price: newValues.price ? newValues.price : 0,
        board_description_ar: newValues.is_offer_board
          ? newValues.board_description_ar
          : "",
        board_description_en: newValues.is_offer_board
          ? newValues.board_description_en
          : "",
      };
      if (selectedAudience?.id) {
        newOfferData["audience"] = selectedAudience?.id;
      }
      delete newOfferData.thumbnail;
      delete newOfferData.background;
      delete newOfferData.thumbnail_web;
      delete newOfferData.background_web;
      if (newValues.is_offer_board) {
        delete newOfferData.board_background;
      } else {
        newOfferData.board_background = null;
      }
      if (!newOffer) {
        if (selectedAudience?.id && newOfferData?.is_online) {
          toast.error("Note: Offers can’t be used online with audience.");
        } else {
          handleLoader(true);
          UserUtility.editOffer(offerData?.id, newOfferData)
            .then((res) => {
              handleLoader(false);
              //to check if the url is new or not
              sendImagesToBE(res);
            })
            .catch((error) => {
              console.error(error);
              handleLoader(false);
            });
        }
      } else {
        //if the offer is new
        const convertInstallments = installmentPlans.map((eachPlan) => ({
          ...eachPlan,
          admin_fees: parseFloat(eachPlan.admin_fees.value),
          forsa_processing_fees: parseFloat(
            eachPlan.admin_fees.forsa_processing_fees
          ),
          paymob_processing_fees: parseFloat(
            eachPlan.admin_fees.paymob_processing_fees
          ),
          kick_back: parseFloat(eachPlan.admin_fees.kick_back),
          grace_period: `${eachPlan.grace_period.toString()}`,
        }));

        newOfferData = {
          ...newOfferData,
          // installment_plans: [...convertInstallments],
          installment_plans: convertInstallments,
          published: null,
        };

        if (selectedAudience?.id && newOfferData?.is_online) {
          toast.error("Note: Offers can’t be used online with audience.");
        } else {
          handleLoader(true);
          UserUtility.createOffer(newOfferData)
            .then((res) => {
              handleLoader(false);
              //to check if the url is new or not
              sendImagesToBE(res);
            })
            .catch((error) => {
              console.error("error posting", error);
              handleLoader(false);
              toast.error(error[0] || "something went wrong");
            });
        }
      }
    }
  };

  const sendImagesToBE = (res) => {
    var token = localStorage.getItem("tools_token");
    var formdata = new FormData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);

    // for two cases new offer or create one
    if (backgroundCropped && typeof backgroundCropped === "object") {
      formdata.append("background", backgroundCropped);
    }
    if (thumbnailCropped && typeof thumbnailCropped === "object") {
      formdata.append("thumbnail", thumbnailCropped);
    }

    if (backgroundCroppedWeb && typeof backgroundCroppedWeb === "object") {
      formdata.append("background_web", backgroundCroppedWeb);
    }
    if (thumbnailCroppedWeb && typeof thumbnailCroppedWeb === "object") {
      formdata.append("thumbnail_web", thumbnailCroppedWeb);
    }

    if (boardBackground && typeof boardBackground === "object") {
      formdata.append("board_background", boardBackground);
    }
    formdata.append("title", res.title);
    formdata.append("description", res.description);
    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    if (
      (backgroundCropped && typeof backgroundCropped === "object") ||
      (thumbnailCropped && typeof thumbnailCropped === "object") ||
      (backgroundCroppedWeb && typeof backgroundCroppedWeb === "object") ||
      (thumbnailCroppedWeb && typeof thumbnailCroppedWeb === "object") ||
      (boardBackground && typeof boardBackground === "object")
    ) {
      fetch(
        `${ENDPOINTS.url}management_dashboard/offers/${res.id}/`,
        requestOptions
      )
        .then(async (res) => {
          if (!res.ok) {
            handleLoader(false);
            const text = await res.text();
            throw new Error(text);
          } else {
            handleLoader(false);
            navigate(-1);
            return res.json();
          }
        })
        .catch((err) => {
          console.error("error editing on images", err);
          handleLoader(false);
          toast.error(err[0] || "something went wrong");
        });
    } else {
      navigate(-1);
    }
  };

  const onUploadFile = (event, setState) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setState(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // to handle publish button to publish or unpublish
  const publishChange = (planId, publishState) => {
    const newPlans = installmentPlans.map((eachPlan, id) => {
      return {
        ...eachPlan,
        published: planId === eachPlan.id ? publishState : eachPlan.published,
      };
    });
    handleLoader(true);
    UserUtility.publishInstallmentPlans(planId, publishState)
      .then(() => {
        handleLoader(false);
        setInstallmentPlans(newPlans);
      })
      .catch((error) => {
        console.error("publish installment plan error: ", error);
        handleLoader(false);
      });
  };

  const publishSwitch = (isPublish, item) => {
    let id = item.id;
    /*
    let grace_period =
      item?.grace_period === 1
        ? item?.grace_period
        : (typeof item?.grace_period == 'string') ? parseInt(item?.grace_period.split(" ", 1)) : '';
*/
    switch (true) {
      case isPublish && !permissions.includes("BD_merchant_creation_tool"):
        return "Published";

      case isPublish && permissions.includes("BD_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.red,
            }}
            variant="contained"
            onClick={() => publishChange(id, false)}
          >
            Unpublish
          </Button>
        );

      case !isPublish && permissions.includes("Finance_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.primary,
            }}
            // type="submit"
            disabled={
              item.total < item?.min_percentage ||
              (item?.admin_fees?.forsa_processing_fees === 0 &&
                item?.admin_fees?.paymob_processing_fees === 0)
            }
            variant="contained"
            onClick={() => publishChange(id, true)}
          >
            Publish
          </Button>
        );

      case !isPublish &&
        !permissions.includes("Finance_merchant_creation_tool"):
        return "Not Published";

      default:
        break;
    }
  };

  //handle pagination page
  const handleChangePaginate = (e, value) => {
    setPage(value);
  };

  // calculate number of dayes between expiry date and created date
  const datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  };

  /**
   * new Date("dateString") is browser-dependent and discouraged, so we'll write
   * a simple parse function for U.S. date format (which does no error checking)
   */
  const parseDate = (str) => {
    var mdy = str.split("-");
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  };

  const getMails = () => {
    UserUtility.getMailList()
      .then((res) => {
        setMailList(res.results);
        if (res.next) {
          getPaginationMails(res.next);
        }
      })
      .catch((err) => console.log("err of getMails: ", err));
  };

  const getPaginationMails = (url) => {
    UserUtility.getNextPageData(url)
      .then((res) => {
        // state of assigned orunussigned maker or checker
        if (res.next) {
          getPaginationMails(res.next);
        }
        setMailList((old) => [...old, ...res.results]);
      })
      .catch((err) => console.log(err));
  };

  const sendMail = (comment) => {
    let arr = selectedEmails.map((item, _) => {
      return item.email;
    });
    setIsSendRisk(true);
    UserUtility.sendMailToRisk(comment, arr)
      .then((res) => {
        setRiskSendData({ comment: comment, selectedList: arr });
        toast.success(res.message);
        // setIsSendRisk(false)
      })
      .catch((err) => {
        // setIsSendRisk(false)
        toast.error(err);
        console.log("err of sendMail: ", err);
      });
  };

  // to get default installment values releated to sectors , will call this function in case category return []
  const getDefaultInstall = (defualtMinArr) => {
    UserUtility.getInstallmentDefault(brandId)
      .then((res) => {
        const newArr = res?.results.map((category, index) => {
          return {
            ...category,
            min_percentage:
              defualtMinArr[index].months === plans.rows[index]?.months
                ? defualtMinArr[index].value
                : null,
            total: calculateMax(
              category.interest_rate,
              category.admin_fees.value,
              category.admin_fees.kick_back,
              category.admin_fees.forsa_processing_fees,
              category.admin_fees.paymob_processing_fees
            ),
          };
        });
        setInstallmentPlans(newArr);
        if (res.next) {
          getPaginateData(res.next, defualtMinArr);
        }
      })
      .catch((err) => {
        console.log("err of getDefaultInstall: ", err);
      });
  };

  const getPaginateData = (url, defualtMinArr) => {
    UserUtility.getNextPageData(url)
      .then((res) => {
        const newArr = res?.results.map((category, index) => {
          return {
            ...category,
            min_percentage:
              defualtMinArr[index].months === plans.rows[index]?.months
                ? defualtMinArr[index].value
                : null,
            total: calculateMax(
              category.interest_rate,
              category.admin_fees.value,
              category.admin_fees.kick_back,
              category.admin_fees.forsa_processing_fees,
              category.admin_fees.paymob_processing_fees
            ),
          };
        });
        setInstallmentPlans((old) => [...old, ...newArr]);
        if (res.next) {
          getPaginateData(res.next);
        }
      })
      .catch((err) => console.log(err));
  };

  // audiences get options with page and search
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    let response = await UserUtility.getAudiences(searchQuery, page);
    return {
      options: response?.results,
      hasMore: response?.results.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const validateYup = Yup.object({
    slug: Yup.string()
      .matches(
        /^[a-z0-9]+(?:[._-][a-z0-9]+)*$/g,
        "No capital or special character or space"
      )
      .required("Required*"),
    title_en: Yup.string().required("Required*"),
    title_ar: Yup.string().required("Required*"),
    description_en: Yup.string().required("Required*"),
    description_ar: Yup.string().required("Required*"),
    product_label: Yup.string(),
    price: Yup.string().matches(/^[0-9]+$/, "invalid number "),

    category: Yup.string().required("Required*"),
    order: Yup.number().required("Required*"),

    board_description_ar: Yup.string().when("is_offer_board", {
      is: (is_offer_board) =>
        typeof club_name === "boolean" && is_offer_board === true,
      then: Yup.string().required("*This field is required.").nullable(),
      otherwise: Yup.string().nullable(),
    }),

    board_description_en: Yup.string().when("is_offer_board", {
      is: (is_offer_board) =>
        typeof club_name === "boolean" && is_offer_board === true,
      then: Yup.string().required("*This field is required.").nullable(),
      otherwise: Yup.string().nullable(),
    }),

    board_background: Yup.string().when("is_offer_board", {
      is: (is_offer_board) =>
        typeof club_name === "boolean" && is_offer_board === true,
      then: Yup.string().required("*This field is required.").nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });

  return (
    <article className="generalInfo_container">
      <h2 className="brand_title">
        {`Brands / ${brandName} / Offers / `}
        <span>{brand}</span>
      </h2>
      <Formik
        enableReinitialize
        // innerRef={formRef}
        initialValues={{
          slug: offerDetail?.slug || "",
          title_en: offerDetail?.title_en || "",
          title_ar: offerDetail?.title_ar || "",
          description_en: offerDetail?.description_en || "",
          description_ar: offerDetail?.description_ar || "",
          is_retail: offerDetail?.is_retail || false,
          is_online: offerDetail?.is_online || false,
          featured: offerDetail?.featured || false,
          newest: offerDetail?.newest || false,
          best_seller: offerDetail?.best_seller || false,
          otc: offerDetail?.otc || false,
          product_label: offerDetail?.product_label || "",
          price: offerDetail?.price || 0,
          category: offerDetail?.category?.title_en || "",
          order: offerDetail?.order || 1,
          thumbnail: offerDetail?.thumbnail || "",
          background: offerDetail?.background || "",
          thumbnail_web: offerDetail?.thumbnail_web || "",
          background_web: offerDetail?.background_web || "",
          is_seasonal: offerDetail?.is_seasonal || false,
          approved_by_marketing: offerDetail?.approved_by_marketing || false,
          created: offerDetail?.created || "",
          is_offer_board: offerDetail?.is_offer_board || false,
          board_description_ar: offerDetail?.board_description_ar || "",
          board_description_en: offerDetail?.board_description_en || "",
          board_background: offerDetail?.board_background || null,
          tags: offerDetail?.tags || [],
          risk_comment: offerDetail?.risk_comment || "",
          modified: offerDetail?.modified || null,
          start_date: offerDetail?.start_date || null,
        }}
        onSubmit={(values) => editAndPostOffer(values)}
        validationSchema={validateYup}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {textFields.map((field, index) => (
              <div key={index}>
                <section>
                  <h3>{field.title}</h3>
                  <TextField
                    placeholder={field.placeholder}
                    variant="filled"
                    onBlur={handleBlur}
                    name={field.name}
                    onChange={handleChange}
                    style={field.styles ? field.styles : null}
                    multiline={field.multiline ? true : false}
                    rows={field.rows ? field.rows : null}
                    className={field.className ? field.className : null}
                    value={values[field.name]}
                    disabled={
                      field.name === "price" ||
                      field.name === "category" ||
                      field.name === "product_label"
                        ? !isReadOnly
                        : !isReadOnly && !isMarketing
                    }
                  />
                </section>
                {field.error && errors[field.name] && touched[field.name] && (
                  <div className="error_message">{errors[field.name]}</div>
                )}
              </div>
            ))}

            <PaginateSelectedComponent
              value={selectedAudience}
              loadOptions={loadOptions}
              onChange={(value) => setSelectedAudience(value)}
              placeholder="Select Audience"
              customStyle={{ width: "30%" }}
            />

            {/* dates */}
            {values.created && (
              <section>
                <h3>Created date:</h3>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values.created ? new Date(values.created) : null}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    disabled
                  />
                </LocalizationProvider>
              </section>
            )}

            <section className="flex_date_sec">
              <section>
                <h3>Expiry date:</h3>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    value={expirationDate ? new Date(expirationDate) : null}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setExpirationDate(moment(newValue).format("YYYY-MM-DD"));
                    }}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => e.preventDefault()}
                        {...params}
                      />
                    )}
                    disabled={!isReadOnly}
                    // miDate={moment(today).format("dd/MM/yyyy")}
                    // minDate={moment().toDate()}
                    // maxDate={today.setDate(today.getDate()) -1}
                  />
                </LocalizationProvider> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2} sx={{ minWidth: 305 }}>
                    <DateTimePicker
                      value={expirationDate ? dayjs(expirationDate) : null}
                      onChange={setExpirationDate}
                      referenceDate={dayjs(today)}
                      disabled={!isReadOnly}
                    />
                  </Stack>
                </LocalizationProvider>
              </section>

              <section style={{ marginLeft: 10 }}>
                <h3>Start date:</h3>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    value={values.start_date ? new Date(values.start_date) : null}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(value) => {
                      let start_date = moment(value).format("YYYY-MM-DD");
                      setFieldValue('start_date', start_date);
                    }}

                    disabled={!permissions.includes('Finance_merchant_creation_tool')}
                  />
                </LocalizationProvider> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2} sx={{ minWidth: 305 }}>
                    <DateTimePicker
                      value={
                        values.start_date ? dayjs(values.start_date) : null
                      }
                      onChange={(value) => {
                        setFieldValue("start_date", value);
                      }}
                      referenceDate={dayjs(today)}
                      disabled={
                        !permissions.includes("Finance_merchant_creation_tool")
                      }
                    />
                  </Stack>
                </LocalizationProvider>
              </section>
            </section>

            {expirationDate && values.start_date && (
              <section className="txt_style">
                <h6 style={{ fontWeight: "bold" }}>
                  Number of days between (Start and Expiry date):{" "}
                </h6>
                <p>
                  {datediff(
                    parseDate(moment(values.start_date).format("YYYY-MM-DD")),
                    parseDate(moment(expirationDate).format("YYYY-MM-DD"))
                  )}
                </p>
              </section>
            )}

            <section>
              <h3>Modified date:</h3>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={values.modified ? new Date(values.modified) : null}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                />
              </LocalizationProvider>
            </section>
            {/* Thumbnail section */}
            <section>
              <h3>Thumbnail</h3>
              <ImageCropper
                imageToCrop={imageToCropThumbnail}
                onImageCropped={(croppedImage) =>
                  setThumbnailCropped(croppedImage)
                }
                maxCrop={{ x: 0, y: 0, width: 33, height: 32, unit: "px" }}
                name="thumbnail.jpeg"
              />
              <div>
                <label htmlFor="contained-button-file">
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                    name="thumbnail"
                    onChange={(e) => onUploadFile(e, setImageToCropThumbnail)}
                    disabled={!isReadOnly && !isMarketing}
                  />

                  <TextField
                    placeholder="image url"
                    variant="filled"
                    onBlur={handleBlur}
                    value={thumbnailCropped?.name || thumbnailCropped || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    disabled={!isReadOnly && !isMarketing}
                  >
                    Upload
                  </Button>
                  <p className="image_hint">*Hint: Dimension is: 155 * 116</p>
                </label>
              </div>
              {thumbnailCropped &&
                thumbnailCropped.size &&
                thumbnailCropped.size >= 400000 && (
                  <div className="error_message">
                    File size exceeds the maximum limit.
                  </div>
                )}
            </section>

            {/* background section */}
            <section>
              <h3>Background</h3>
              <ImageCropper
                imageToCrop={imageToCropBackground}
                onImageCropped={(croppedImage) =>
                  setBackgroundCropped(croppedImage)
                }
                maxCrop={{ x: 0, y: 0, width: 155, height: 116, unit: "px" }}
                name="background.jpeg"
              />
              <div>
                <label htmlFor="background-file">
                  <input
                    accept="image/*"
                    id="background-file"
                    type="file"
                    style={{ display: "none" }}
                    name="background"
                    onChange={(e) => onUploadFile(e, setImageToCropBackground)}
                    disabled={!isReadOnly && !isMarketing}
                  />
                  <TextField
                    placeholder="image url"
                    variant="filled"
                    onBlur={handleBlur}
                    value={backgroundCropped?.name || backgroundCropped || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    disabled={!isReadOnly && !isMarketing}
                  >
                    Upload
                  </Button>
                  <p className="image_hint">*Hint: Dimension is: 325 * 154</p>
                </label>
              </div>

              {backgroundCropped &&
                backgroundCropped.size &&
                backgroundCropped.size > 400000 && (
                  <div className="error_message">
                    File size exceeds the maximum limit.
                  </div>
                )}
            </section>

            {/*thumbnail web */}
            <section>
              <h3>Thumbnail Web</h3>
              <ImageCropper
                imageToCrop={imageToCropThumbnailWeb}
                onImageCropped={(croppedImage) =>
                  setThumbnailCroppedWeb(croppedImage)
                }
                maxCrop={{ x: 0, y: 0, width: 33, height: 32, unit: "px" }}
                name="thumbnail.jpeg"
              />
              <div>
                <label htmlFor="contained-button-file-web">
                  <input
                    accept="image/*"
                    id="contained-button-file-web"
                    type="file"
                    style={{ display: "none" }}
                    name="thumbnail_web"
                    onChange={(e) =>
                      onUploadFile(e, setImageToCropThumbnailWeb)
                    }
                    disabled={!isReadOnly && !isMarketing}
                  />
                  <TextField
                    placeholder="image url"
                    variant="filled"
                    onBlur={handleBlur}
                    value={
                      thumbnailCroppedWeb?.name || thumbnailCroppedWeb || ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    disabled={!isReadOnly && !isMarketing}
                  >
                    Upload
                  </Button>
                  <p className="image_hint">*Hint: Dimension is: 400 * 400</p>
                </label>
              </div>
              {thumbnailCroppedWeb &&
                thumbnailCroppedWeb.size &&
                thumbnailCroppedWeb.size >= 400000 && (
                  <div className="error_message">
                    File size exceeds the maximum limit.
                  </div>
                )}
            </section>

            {/*background web */}
            <section>
              <h3>Background Web</h3>
              <ImageCropper
                imageToCrop={imageToCropBackgroundWeb}
                onImageCropped={(croppedImage) => {
                  setBackgroundCroppedWeb(croppedImage);
                }}
                maxCrop={{ x: 0, y: 0, width: 155, height: 116, unit: "px" }}
                name="background.jpeg"
              />
              <div>
                <label htmlFor="background-file-web">
                  <input
                    accept="image/*"
                    id="background-file-web"
                    type="file"
                    style={{ display: "none" }}
                    name="background_web"
                    onChange={(e) => {
                      onUploadFile(e, setImageToCropBackgroundWeb);
                    }}
                    disabled={!isReadOnly && !isMarketing}
                  />
                  <TextField
                    placeholder="image url"
                    variant="filled"
                    onBlur={handleBlur}
                    value={
                      backgroundCroppedWeb?.name || backgroundCroppedWeb || ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    disabled={!isReadOnly && !isMarketing}
                  >
                    Upload
                  </Button>
                  <p className="image_hint">*Hint: Dimension is: 720 * 273</p>
                </label>
              </div>
            </section>
            {backgroundCroppedWeb &&
              backgroundCroppedWeb.size &&
              backgroundCroppedWeb.size > 400000 && (
                <div className="error_message">
                  File size exceeds the maximum limit.
                </div>
              )}

            {checkboxes.map((item, index) => (
              <section
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  size="small"
                  name={item.name}
                  value={values[item.name]}
                  onChange={handleChange}
                  checked={values[item.name]}
                  disabled={!isReadOnly}
                />
                <h3 style={{ marginLeft: 6 }}>{item.title}</h3>
              </section>
            ))}
            <section>
              <h3>Order</h3>
              <TextField
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                placeholder="order"
                variant="filled"
                name="order"
                value={values.order}
                onChange={handleChange}
                style={{ direction: "rtl" }}
                onBlur={handleBlur}
                disabled={!isReadOnly}
              />
            </section>
            {brandBranches && brandBranches.length > 0 && (
              <>
                <h2 className="branches_head">Selected branches </h2>
                {/* branches table */}

                <OfferTable
                  list={brandBranches}
                  onBtnClick={handleActiveBranch}
                  tableData={Table1Data}
                  readOnly={true}
                  inputClassName="tableShowInput"
                  btnChecked="active"
                  inputType="text"
                  months={false}
                  isDisabled={!isReadOnly}
                  handleBranchesSelectAll={handleBranchesSelectAll}
                  isCheckedAll={isBranchesCheckedAll}
                />
                <PaginationComponent
                  brands={branchesDetail}
                  page={page}
                  handleChange={handleChangePaginate}
                  total={false}
                />
              </>
            )}
            {installmentPlans && installmentPlans?.length > 0 && (
              <OfferTable
                list={installmentPlans}
                // onBtnClick={handleActivePlan}
                tableData={Table2Data}
                readOnly={false}
                inputClassName="input"
                btnChecked="published"
                inputType="number"
                months={true}
                handlePlanNumbers2={handlePlanNumbers2}
                handlePlanNumbers={handlePlanNumbers}
                publishSwitch={publishSwitch}
                isDisabled={!isReadOnly}
                // min_percentage={minPercent}
              />
            )}

            {/* approved by marketing */}
            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                // style={{ opacity: isMarketing ? 1 : .5 }}
                size="small"
                name="approved_by_marketing"
                onChange={handleChange}
                checked={values.approved_by_marketing}
                disabled={!isMarketing}
              />
              <h3 style={{ marginLeft: 6 }}>Approved by marketing</h3>
            </section>

            {/* isOfferBoard */}
            <section style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                // style={{ opacity: isMarketing ? 1 : .5 }}
                size="small"
                name="is_offer_board"
                onChange={handleChange}
                checked={values?.is_offer_board}
                disabled={!isReadOnly}
              />
              <h3 style={{ marginLeft: 6 }}>Is offer board</h3>
            </section>

            {/* offer board and tags */}
            <Accordion alwaysOpen defaultActiveKey="0" className="accordClass">
              {values?.is_offer_board && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Offer board</Accordion.Header>

                  <Accordion.Body>
                    <section>
                      <section>
                        <h3>Board description (en):</h3>
                        <TextField
                          multiline
                          rows={4}
                          variant="filled"
                          value={values?.board_description_en}
                          name="board_description_en"
                          placeholder="Enter the description here ..."
                          onChange={handleChange}
                          className="description"
                          onBlur={handleBlur}
                          disabled={!isReadOnly && !isMarketing}
                        />
                      </section>
                      {errors.board_description_en &&
                        touched.board_description_en && (
                          <div className="error_message">
                            {errors.board_description_en}
                          </div>
                        )}

                      <section>
                        <h3>Board description (ar):</h3>
                        <TextField
                          multiline
                          rows={4}
                          variant="filled"
                          value={values.board_description_ar}
                          name="board_description_ar"
                          placeholder="Enter the description here ..."
                          onChange={handleChange}
                          className="description"
                          onBlur={handleBlur}
                          disabled={!isReadOnly && !isMarketing}
                        />
                      </section>
                      {errors.board_description_ar &&
                        touched.board_description_ar && (
                          <div className="error_message">
                            {errors.board_description_ar}
                          </div>
                        )}

                      <section>
                        <h3> Board background</h3>
                        <div>
                          <label
                            className="image"
                            htmlFor="board_background-file"
                          >
                            <input
                              accept="image/*"
                              id="board_background-file"
                              type="file"
                              style={{ display: "none" }}
                              name="board_background"
                              onChange={(e) =>
                                setBoardBackground(e.target.files[0])
                              }
                            />
                            <div>
                              <TextField
                                id="outlined-basic"
                                placeholder="image url"
                                variant="filled"
                                value={boardBackground?.name || boardBackground}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              {(isReadOnly || isMarketing) && (
                                <Button variant="contained" component="span">
                                  Upload
                                </Button>
                              )}
                              <p className="image_hint">
                                *Hint: Dimension is: 325 * 154
                              </p>
                            </div>
                          </label>
                        </div>
                        {boardBackground &&
                          boardBackground.size &&
                          boardBackground.size > 400000 && (
                            <div className="error_message">
                              File size exceeds the maximum limit (400kb).
                            </div>
                          )}
                      </section>
                    </section>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              <Accordion.Item eventKey="1">
                <Accordion.Header
                  onClick={() => {
                    setIsTagsOpen(true);
                  }}
                >
                  Tags
                </Accordion.Header>
                <Accordion.Body
                  onExiting={() => {
                    setIsTagsOpen(false);
                  }}
                >
                  <TagComponent
                    isDisabled={!isReadOnly}
                    brandId={offerDetail?.id}
                    tagState={isTagsOpen}
                    tagIds={values.tags}
                    selectedTagIds={(items) => {
                      let arr =
                        items && items.length > 0
                          ? items.map((element, _) => {
                              return element.id;
                            })
                          : [];
                      values.tags = arr;
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header
                  onClick={() => {
                    setIsRiskOpen(true);
                  }}
                >
                  Risk
                </Accordion.Header>

                <Accordion.Body
                  onExiting={() => {
                    setIsRiskOpen(false);
                  }}
                >
                  <section>
                    <section>
                      <h3>Risk comment:</h3>
                      <TextField
                        multiline
                        rows={4}
                        variant="filled"
                        value={values.risk_comment}
                        name="risk_comment"
                        placeholder="Enter risk comment ..."
                        onChange={(event) => {
                          if (
                            event.target.value.trim() === riskSendData.comment
                          ) {
                            setIsSendRisk(true);
                            setFieldValue("risk_comment", event.target.value);
                          } else {
                            setFieldValue("risk_comment", event.target.value);
                            setIsSendRisk(false);
                          }
                        }}
                        className="description"
                        onBlur={handleBlur}
                        disabled={!isReadOnly && !isMarketing}
                      />
                    </section>
                    {errors.risk_comment && touched.risk_comment && (
                      <div className="error_message">{errors.risk_comment}</div>
                    )}

                    {/* risk comment */}
                    <div className="card_tag_dropdown">
                      <InputLabel className="label">Select Mails</InputLabel>
                      <MultipleSelection
                        // disabled={isDisabled}
                        optionsItem={mailList}
                        type="mails"
                        label="Mails"
                        finalSelectedList={(values) => {
                          // to filter array that will return array of string not object to be compared
                          let filterArr = values.map((item, _) => {
                            return item.email;
                          });

                          if (
                            JSON.stringify(filterArr) ===
                            JSON.stringify(riskSendData.selectedList)
                          ) {
                            setIsSendRisk(true);
                          } else {
                            setIsSendRisk(false);
                          }
                          setSelectedEmails(values);
                        }}
                        selectedValues={selectedEmails}
                      />
                    </div>
                    {permissions.includes("can_add_risk_comment") && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          disabled={
                            selectedEmails.length === 0 ||
                            !values.risk_comment ||
                            isSendRisk
                          }
                          onClick={() => sendMail(values.risk_comment)}
                        >
                          Send Mails
                        </Button>
                      </div>
                    )}
                  </section>
                </Accordion.Body>
              </Accordion.Item>

              {/* history */}
              {offerDetail?.id && (
                <Accordion.Item eventKey="3">
                  <Accordion.Header
                    onClick={() => {
                      setIsHistoryOpen(!isHistoryOpen);
                    }}
                  >
                    History
                  </Accordion.Header>
                  <Accordion.Body>
                    <MerchantToolHistory
                      installmentIds={installmentPlans?.map(({ id }) => id)}
                      type="offer"
                      id={offerDetail?.id}
                      isState={isHistoryOpen}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>

            {(permissions.includes("Finance_merchant_creation_tool") &&
              !offerDetail?.published) ||
            (permissions.includes("BD_merchant_creation_tool") &&
              !offerDetail?.published &&
              !offerDetail?.start_date) ||
            (!offerDetail?.published &&
              permissions.includes("Marketing_merchant_creation_tool")) ? (
              <div className="submitBtn">
                <Button variant="contained" type="submit">
                  Save section
                </Button>
              </div>
            ) : (
              <></>
            )}
          </form>
        )}
      </Formik>
    </article>
  );
};

export default OfferDetails;
