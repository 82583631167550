export let Table1Data = [
  { title: "Title (en)", defaultValue1: "title_en" },
  { title: "Title (ar)", defaultValue1: "title_ar" },
  { title: "Email", defaultValue1: "email" },
  { title: "LAT", defaultValue1: "lat" },
  { title: "LNG", defaultValue1: "lng" },
  { title: "Location link", defaultValue1: "location_link" },
];
export let Table2Data = [
  { title: "Interest rate (%)", defaultValue1: "interest_rate" },
  {
    title: "Admin fees (%)",
    defaultValue1: "admin_fees",
    defaultValue2: "value",
    inputType: "number",
  },
  {
    title: "Kick back (%)",
    defaultValue1: "admin_fees",
    defaultValue2: "kick_back",
    inputType: "number",
  },
  {
    title: "Forsa proccessing fees (%)",
    defaultValue1: "admin_fees",
    defaultValue2: "forsa_processing_fees",
    inputType: "number",
  },
  {
    title: "Paymob processing fees (%)",
    defaultValue1: "admin_fees",
    defaultValue2: "paymob_processing_fees",
    inputType: "number",
  },
  {
    title: "Min percentage (%)",
    defaultValue1: "total",
    inputType: "number",
  },
  { title: "grace period", defaultValue1: "grace_period", inputType: "text" },
];
export let textFields = [
  {
    title: "Offer slug (en):",
    placeholder: "car_services",
    name: "slug",
    error: true,
  },
  {
    title: "Offer name (en):",
    placeholder: "car services",
    name: "title_en",
    error: true,
  },
  {
    title: "Offer name (ar):",
    placeholder: "خدمات السيارات",
    name: "title_ar",
    styles: { direction: "rtl" },
    error: true,
  },
  {
    title: "Description (en):",
    placeholder: "Enter the description here ...",
    name: "description_en",
    multiline: true,
    rows: 4,
    className: "description",
    error: true,
  },
  {
    title: "Description (ar):",
    placeholder: "ادخل الوصف هنا ....",
    name: "description_ar",
    multiline: true,
    rows: 4,
    className: "description",
    styles: { direction: "rtl" },
    error: true,
  },
  {
    title: "Product label:",
    placeholder: "iphone",
    name: "product_label",
    error: true,
  },
  { title: "price (EGP):", placeholder: "15000", name: "price", error: true },
  {
    title: "category:",
    placeholder: "Big Zero",
    name: "category",
    error: true,
  },
];
export let checkboxes = [
  { title: "Is online", name: "is_online" },
  { title: "Is retail", name: "is_retail" },
  { title: "Newest", name: "newest" },
  { title: "Featured", name: "featured" },
  { title: "Best seller", name: "best_seller" },
  { title: "OTC", name: "otc" },
  { title: "Is Seasonal", name: "is_seasonal" }
];

export let globalOfferTableData = [
  { title: 'ID' },
  { title: 'Brand name' },
  // {title:'Sector'},
  { title: 'Remove' }
]