import { Button, Form, Modal, Table } from "react-bootstrap";
import "./OnBoardStyle.scss";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { UserUtility } from "../../apis/UserUtility";
import ExportDraft from "./Exports/ExportDraft";
import { PaginateSelectedComponent } from "./PaginateSelectedComponent/PaginateSelectedComponent";
import { toast } from "react-toastify";

export const OnBoardList = () => {
  const [BrandName, setBrandName] = useState("");
  const [commercialRegister, setCommercialRegister] = useState("");
  const [statusParam, setStatusParam] = useState("");
  const [approved_by_legal, setApproved_by_legal] = useState("");
  const [approved_by_risk, setApproved_by_risk] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [AllDataFromDB, setAllDataFromDB] = useState();
  const { handleLoader, permissions, toolProfile } = useContext(UserContext);
  const [reRunEffect, setReRunEffect] = useState(0);
  const [checkIds, setCheckIds] = useState([]);
  const [page, setPage] = useState(1);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState();

  const isFinalCheck =
    AllDataFromDB && AllDataFromDB.results.length == checkIds.length
      ? true
      : false;

  let isMarketing = permissions.includes("marketing__on_boarding");
  let isOwner = permissions.includes("owner__on_boarding");

  //   console.log(approved_by_legal);
  //   console.log(approved_by_risk);
  //   console.log(startDate);
  //   console.log(endDate);

  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  function handleStartDateChange(event) {
    setStartDate(event.target.value);
  }

  function handleEndDateChange(event) {
    setEndDate(event.target.value);
  }
  /*this function for handle async search with all params and swaping to first page */
  const handleSearch = () => {
    if (commercialRegister?.length) {
      setBrandName("");
    }
    if (page === 1) {
      setReRunEffect(reRunEffect + 1);
    } else {
      setPage(1);
    }
  };

  // handle select all for every page
  const handleActiveUser = (index, id) => {
    //to add or remove checkbox as UI
    const newList =
      AllDataFromDB &&
      AllDataFromDB.results.map((user, ind) => {
        console.log("index === ind: ", index === ind);
        return {
          ...user,
          active: index === ind ? !user.active : user.active,
        };
      });
    setAllDataFromDB({ ...AllDataFromDB, results: newList });
    //to add and remove makersId to send to BE
    const filterId = checkIds.filter((item) => item === id);
    console.log(`checkIds: ${checkIds}, filterId: ${filterId}`);
    if (filterId[0]) {
      const removeId = checkIds.filter((item) => item !== id);
      setCheckIds(removeId);
    } else {
      setCheckIds((oldUser) => [...oldUser, id]);
    }
  };

  //   check all merchants in 1 page
  const handleActiveAll = (isChecked) => {
    let list =
      AllDataFromDB &&
      AllDataFromDB?.results.map((item, _) => {
        return {
          ...item,
          active: item.status == "APPROVED" ? isChecked : false,
        };
      });
    setAllDataFromDB({ ...AllDataFromDB, results: list });

    if (isChecked) {
      const AllIds = list && list.map((item, index) => item.id);
      setCheckIds(AllIds);
    } else {
      setCheckIds([]);
    }
  };

  // audiences get options with page and search
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    console.log("page load: ", page);
    let response = await UserUtility.ownerList(page);

    return {
      options: response?.results,
      hasMore: response.next ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleAssignUsers = () => {
    console.log("selectedOwner: ", selectedOwner);
    let obj = {
      ids: checkIds,
      owner: selectedOwner.id,
    };
    handleLoader(true);
    UserUtility.updateBrandsOwners(obj)
      .then((res) => {
        handleLoader(false);
        toast.success("Assigned Successfully");
      })
      .catch((err) => {
        handleLoader(false);
        console.log("err of updateBrandsOwners: ", err);
        toast.error(err);
      })
      .finally(() => handleHideAssignModal());
  };

  const handleHideAssignModal = () => {
    setIsAssignModal(false);
    setSelectedOwner();
  };
  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (statusParam) {
      searchParams.append("status", statusParam);
    }
    if (BrandName) {
      searchParams.append("brand_name", BrandName);
    }
    if (approved_by_legal) {
      searchParams.append("approved_by_legal", approved_by_legal);
    }
    if (approved_by_risk) {
      searchParams.append("approved_by_risk", approved_by_risk);
    }
    if (startDate && endDate) {
      searchParams.append("created_after", startDate);
    }
    if (endDate && startDate) {
      searchParams.append("created_before", endDate);
    }
    if (commercialRegister) {
      searchParams.append("commercial_register", commercialRegister);
    }

    if (!statusParam || !BrandName) {
      if (page) {
        searchParams.append("page", page);
      }
    }

    // if (isOwner) {
    //   searchParams.append("owner", toolProfile.id);
    // }
    handleLoader(true);
    UserUtility.OnBoard_All_List(searchParams.toString())
      .then((res) => {
        handleLoader(false);
        let list = res.results.map((item, _) => {
          return { ...item, active: false };
        });
        setAllDataFromDB({ ...res, results: list });
        setCheckIds([]);
      })
      .catch((err) => {
        handleLoader(false);
        console.error("OnBoard_List_Not_Avall", err);
      });
  }, [page, reRunEffect]);

  useEffect(() => {
    console.log("!isNaN(BrandName): ", !isNaN(BrandName));
    if (!isNaN(BrandName)) {
      setCommercialRegister(BrandName);
    } else {
      setCommercialRegister("");
    }
  }, [BrandName]);

  console.log("checkIds.length: ", checkIds.length);
  console.log("toolProfile: ", toolProfile);

  return (
    <main className="onBoard_main mb-5">
      {/* filter and search */}
      <section>
        <div className="row justify-content-between">
          <Form.Group className="mb-3 col-md-5" controlId="formBasic">
            <Form.Control
              className="input_onBoard w-100"
              type="text"
              placeholder="Search by Brand Name or Commercial register"
              name="national_id"
              // value={BrandName}
              onChange={(e) => {
                setBrandName(e.target.value);
              }}
            />
          </Form.Group>

          <div className="mb-3 col-md-3">
            <Button
              className="small_btn"
              type="button"
              variant="contained"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
          </div>

          <div className="mb-3 col-md-4 text-end ">
            {permissions.includes("data_entry_on_boarding") && (
              <Button className="small_btn" type="button" variant="contained">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="detail"
                >
                  Create New Application
                </Link>
              </Button>
            )}
          </div>
        </div>

        <div className="flex_div filter_row">
          <h6>Filter by :</h6>

          <div className="flex_div">
            <Form.Group className="me-3">
              <Form.Select
                className="select_onBoard"
                id="disabledSelect"
                // value={ }
                onChange={(e) => {
                  setStatusParam(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Status
                </option>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="RESTUDY">Restudy</option>
              </Form.Select>
            </Form.Group>
            <h4 className="me-2">Legal : </h4>
            <Form.Group className="me-3">
              <Form.Select
                className="select_onBoard"
                id="disabledSelect"
                // value={ }
                onChange={(e) => {
                  setApproved_by_legal(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Status
                </option>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="RESTUDY">Restudy</option>
              </Form.Select>
            </Form.Group>

            <h4 className="me-2">Risk : </h4>
            <Form.Group className="me-3">
              <Form.Select
                className="select_onBoard"
                id="disabledSelect"
                // value={ }
                onChange={(e) => {
                  setApproved_by_risk(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Status
                </option>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="RESTUDY">Restudy</option>
              </Form.Select>
            </Form.Group>
          </div>
          <Form.Group className="d-flex ">
            <div className="d-flex justify-content-around align-items-center">
              <h4 className="me-1" style={{ width: "80px" }}>
                Start Date :
              </h4>
              <Form.Control
                className="select_onBoard me-2 w-75"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <h4 className="me-1" style={{ width: "75px" }}>
                End Date :
              </h4>
              <Form.Control
                className="select_onBoard me-2 w-75"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </Form.Group>
        </div>
        <div className="row"></div>
      </section>
      {!isOwner && (
        <div className="d-flex justify-content-end mb-0">
          <ExportDraft
            status={statusParam}
            approved_by_legal={approved_by_legal}
            approved_by_risk={approved_by_risk}
            startDate={startDate}
            endDate={endDate}
            handleSearch={handleSearch}
          />
        </div>
      )}
      {/* table */}
      <section className="mt-1">
        <Table bordered responsive className="mt-2 users_table mb-0">
          <thead>
            <tr>
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={(e) => {
                    handleActiveAll(e.target.checked);
                  }}
                  checked={isFinalCheck}
                />
              </th>
              <th style={{ backgroundColor: "#41b6a8", color: "white" }}>
                Brand
              </th>
              <th style={{ backgroundColor: "#41b6a8", color: "white" }}>
                Create Date
              </th>
              <th style={{ backgroundColor: "#41b6a8", color: "white" }}>
                Approved Risk
              </th>
              <th style={{ backgroundColor: "#41b6a8", color: "white" }}>
                Approved Legal
              </th>
              <th style={{ backgroundColor: "#41b6a8", color: "white" }}>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {AllDataFromDB &&
              AllDataFromDB.results.map((item, i) => (
                <tr key={item.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      onChange={() => handleActiveUser(i, item.id)}
                      checked={item?.active}
                      disabled={item.status == "APPROVED" ? false : true}
                    />
                  </td>
                  <td className="date_title">
                    <Link className="text-black" to="detail" state={{ item }}>
                      {item.brand_name}
                    </Link>
                  </td>
                  <td className="date_title">
                    <Link className="text-black" to="detail" state={{ item }}>
                      {item.created}
                    </Link>
                  </td>

                  <td className="date_title">
                    <span
                      className={`status_flag ${
                        item.approved_by_risk === "APPROVED"
                          ? "approved_status"
                          : item.approved_by_risk === "REJECTED"
                          ? "rejected_status"
                          : item.approved_by_risk === "PENDING"
                          ? "pending_status"
                          : "restudy_status"
                      }`}
                    >
                      {item.approved_by_risk}
                    </span>
                  </td>

                  <td className="date_title">
                    <span
                      className={`status_flag ${
                        item.approved_by_legal === "APPROVED"
                          ? "approved_status"
                          : item.approved_by_legal === "REJECTED"
                          ? "rejected_status"
                          : item.approved_by_legal === "PENDING"
                          ? "pending_status"
                          : "restudy_status"
                      }`}
                    >
                      {item.approved_by_legal}
                    </span>
                  </td>

                  <td className="date_title">
                    <span
                      className={`status_flag ${
                        item.status === "PENDING"
                          ? "pending_status"
                          : item.status === "REJECTED"
                          ? "rejected_status"
                          : item.status === "RESTUDY"
                          ? "restudy_status"
                          : item.status === "APPROVED" && "approved_status"
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </section>
      <PaginationComponent
        brands={AllDataFromDB}
        page={page}
        handleChange={handleChange}
        total={false}
      />
      {isMarketing && (
        <div>
          <Button
            className="small_btn"
            type="button"
            variant="contained"
            disabled={checkIds.length < 1 ? true : false}
            onClick={() => {
              setIsAssignModal(true);
            }}
          >
            Assign to owners
          </Button>
        </div>
      )}

      <Modal
        show={isAssignModal}
        onHide={() => handleHideAssignModal()}
        size="lg"
        centered
        className="modal_style custom_boarding_modal"
      >
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Select Owners</Form.Label>
            <PaginateSelectedComponent
              value={selectedOwner}
              loadOptions={loadOptions}
              onChange={(value) => setSelectedOwner(value)}
              placeholder="Select Owner"
              // customStyle={{ width: '30%' }}
              //   loading={selectedLocation ? false : true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleHideAssignModal()}>
            Close
          </Button>
          <Button
            className="small_btn"
            variant="contained"
            disabled={!selectedOwner ? true : false}
            onClick={() => handleAssignUsers()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};
