import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { Button } from "react-bootstrap";
import { UserUtility } from "../../apis/UserUtility";
import { toast } from "react-toastify";
import UserContext from "../../hooks/userContext";

export const LiabilityFormComponent = ({ user, applicationStatus }) => {
  const [banks, setBanks] = useState([]);
  const [newBank, setNewBank] = useState({
    number: 1,
    bank: "",
    limit: "",
    monthly_payment: "",
  });

  const { permissions = [] } = useContext(UserContext);
  const [totalPayment, setTotalPayment] = useState(0);
  const isMaker = permissions.includes("one_transaction_credit_maker");
  const isAcceptableStatus =
    applicationStatus === "created" ||
    applicationStatus === "studying_credit" ||
    applicationStatus === "restudying_credit";
  const handleAddBank = () => {
    const { bank, limit, monthly_payment } = newBank;
    if (bank !== "" && limit !== "" && monthly_payment !== "") {
      setBanks((prev) => [...prev, { ...newBank }]);

      setNewBank({
        number: "",
        bank: "",
        limit: "",
        monthly_payment: "",
      });
    }
  };

  const handleNewBank = (e) => {
    const { name, value } = e.target;
    setNewBank((prevState) => ({
      ...prevState,
      [name]:
        name === "limit" || name === "monthly_payment"
          ? value > -1
            ? value
            : 0
          : value,
    }));
  };

  const addLiability = () => {
    let obj = {
      user: user,
      ...newBank,
    };
    UserUtility.oneTransCreateLiability(obj)
      .then((res) => {
        toast.success("Liability added successfully");
        getLiabilities();
        handleAddBank();
      })
      .catch((err) => {
        console.error("oneTransCreateLiability: ", err);
      });
  };

  //edit branches
  const handleEditLiability = (index, rowKey, e) => {
    const newBranches = banks?.map((row, id) => {
      return {
        ...row,
        [rowKey]: index === id ? e.target.value : row[rowKey],
      };
    });
    setBanks(newBranches);
  };

  const editLiability = (data) => {
    let obj = {
      user: user,
      ...data,
    };
    UserUtility.oneTransEditLiability(data.id, obj)
      .then((res) => {
        toast.success("Liability changed successfully");
        getLiabilities();
        handleAddBank();
      })
      .catch((err) => {
        console.error("oneTransEditLiability: ", err);
        if (err?.detail) {
          toast.error(err?.detail);
        }
      });
  };

  const getLiabilities = () => {
    UserUtility.oneTransLiabilities(user)
      .then((res) => {
        setBanks(res);
        let total = 0;
        for (const { monthly_payment } of res) {
          total = total + parseFloat(monthly_payment);
        }

        setTotalPayment(total);
      })
      .catch((err) => {
        console.error("getLiabilities: ", err);
      });
  };

  useEffect(() => {
    getLiabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <article className="branches_container">
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No.</StyledTableCell>
              <StyledTableCell>Bank</StyledTableCell>
              <StyledTableCell>Limit</StyledTableCell>
              <StyledTableCell>Monthly Payment</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banks?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <input
                    className="branchInput"
                    value={row.number}
                    onChange={(e) => handleEditLiability(index, "number", e)}
                    name="bank"
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <input
                    className="branchInput"
                    value={row.bank}
                    onChange={(e) => handleEditLiability(index, "bank", e)}
                    name="bank"
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <input
                    type="number"
                    min="0"
                    className="branchInput"
                    value={row.limit}
                    onChange={(e) => handleEditLiability(index, "limit", e)}
                    name="limit"
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <input
                    type="number"
                    min="0"
                    className="branchInput"
                    value={row.monthly_payment}
                    onChange={(e) =>
                      handleEditLiability(index, "monthly_payment", e)
                    }
                    name="monthly_payment"
                  />
                </StyledTableCell>

                <StyledTableCell>
                  {permissions.includes("change_userliabilities") &&
                    isMaker &&
                    isAcceptableStatus && (
                      <Button
                        variant="primary"
                        type="button"
                        className="small_btn"
                        onClick={() => editLiability(row)}
                      >
                        Save
                      </Button>
                    )}
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {/* row of add icon */}

            {permissions.includes("add_userliabilities") && isMaker && (
              <StyledTableRow>
                <TableCell
                  align="center"
                  colSpan={5}
                  style={{ padding: "5px 16px" }}
                ></TableCell>
              </StyledTableRow>
            )}

            {/* row of inital if not have a data */}
            <StyledTableRow>
              <StyledTableCell>
                <input
                  className="branchInput"
                  value={newBank.number}
                  onChange={handleNewBank}
                  name="number"
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  className="branchInput"
                  value={newBank.bank}
                  onChange={handleNewBank}
                  name="bank"
                />
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  min="0"
                  className="branchInput"
                  value={newBank.limit}
                  onChange={handleNewBank}
                  name="limit"
                />
              </StyledTableCell>

              <StyledTableCell>
                <input
                  type="number"
                  min="0"
                  className="branchInput"
                  value={newBank.monthly_payment}
                  onChange={handleNewBank}
                  name="monthly_payment"
                />
              </StyledTableCell>
              {console.log(
                'permissions.includes("add_userliabilities") && isMaker && isAcceptableStatus: ',
                permissions.includes("add_userliabilities") &&
                  isMaker &&
                  isAcceptableStatus
              )}
              <StyledTableCell>
                {permissions.includes("add_userliabilities") &&
                  isMaker &&
                  isAcceptableStatus && (
                    <Button
                      variant="primary"
                      type="button"
                      className="small_btn"
                      onClick={() => addLiability()}
                      disabled={
                        !newBank.bank ||
                        !newBank.limit ||
                        !newBank.monthly_payment ||
                        !newBank.number
                      }
                    >
                      Add
                    </Button>
                  )}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="total_liab_div">
        <p>
          Total monthly payment is <span>{totalPayment}</span>
        </p>
      </div>
    </article>
  );
};
