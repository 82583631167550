import styles from "./CertificateStyle.module.scss";
import logo from "../../assets/images/logo2.png";
import mail from "../../assets/images/mailIcon.svg";
import locationLogo from "../../assets/images/locationIcon.svg";
import call from "../../assets/images/callIcon.svg";
import { useCallback, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import { Colors } from "../../constants/Colors";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import moment from "moment";

export const CertificateOfIndebtedness = () => {
  const location = useLocation();
  const { userId } = location.state;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const date = data?.date_of_activation?.substring(0, 10);
  const transactions = data?.principal_and_created_date;
  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 500);
    });
  }, [setLoading]);

  const CertificationData = () => {
    UserUtility.CertificationData(userId)
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.error("CertificationData", err));
  };
  useEffect(() => {
    CertificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  return (
    <main className="container">
      <div className={styles.btnn}>
        <Button
          variant="contained"
          type="submit"
          style={{
            marginLeft: 0,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: loading ? Colors.secondary : Colors.primary,
            color: "#fff",
          }}
          onClick={handlePrint}
          disabled={loading}
        >
          Print
        </Button>
      </div>
      <section ref={componentRef}>
        <section
          // key={index.toString()}
          style={{
            paddingTop: 50,
            paddingRight: 10,
            textAlign: "-webkit-right",
          }}
        >
          {/* section1 */}
          <div style={{ textAlign: "right" }}>
            <p>شركة درايف للتمويل و الخدمات المالية غير المصرفية ش.م.م</p>
            <p>سجل تجاري رقم : 164123</p>
            <p>رقم التسجيل الضريبي : 457-786-407</p>
            <p>ترخيص الهيئة العامة للرقابه الماليه رقم : 26</p>
          </div>

          {/* section2 */}
          <div className={styles.section2}>
            <p className={styles.title}>شهادة مديونيه</p>

            <div className={styles.sec2_col1}>
              <p>{` السيد / ${data?.customer_full_name} `}</p>

              <p>{`  بطاقة الرقم القومي : ${data?.national_id} `}</p>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <p>{data?.id} / الخاصه بعقد تمويل الإستهلاكي رقم</p>
                <p
                  style={{ marginRight: " 20%" }}
                >{`${data?.phone_number} / رقم الهاتف  `}</p>
              </div>

              <p>
                {`بتاريخ ${date ? date : ""} تم منح تمويل دوار مبلغ وقدرة ${
                  data ? data?.profile_finance_limit : ""
                } ( فقط ${data ? data?.amount_in_arabic_words : ""} )`}
              </p>
              <p> : و قد قام بالمعاملات الاتيه</p>
            </div>
            <div style={{ textAlign: "-webkit-right" }}>
              <table className={styles.Certificate_table}>
                <tr>
                  <th style={{ paddingInline: "140px" }}>قيمة المعاملة</th>
                  <th style={{ paddingInline: "140px" }}>التاريخ</th>
                </tr>
                {transactions?.map((ele, index) => {
                  return (
                    <tr key={index}>
                      <td>{ele.principal}</td>
                      <td>{ele.created.substring(0, 10)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>

          {/* section3 */}
          <div className={styles.section3}>
            <p>{`وبتاريخ ${data?.unsettled_loan_date} تم الامتناع عن السداد`}</p>
            <p>{`ليصبح إجمالي المطلوب سداده من العميل مضاف إليه الرسوم و العوائد و الغرمات و عمولات التاخير   
حتى تاريخ إصدار هذا المستند مبلغ وقدره ( ${data?.total_dues} ) 
`}</p>
            <p>شركة درايف للتمويل والخدمات المالية غير المصرفية</p>
          </div>

          {/* section4 */}
          <div className={styles.flex_div}>
            <div className={styles.table}>
              <p>
                : الاسم
                <span className={styles.seprt_span}></span>
              </p>

              <p>
                : التوقيع
                <span className={styles.seprt_span}></span>
              </p>

              <p>
                : التاريخ
                <span className={styles.seprt_span}></span>
              </p>
            </div>
            <div className={styles.table} style={{ marginRight: "2%" }}>
              <p>
                : الاسم
                <span className={styles.seprt_span}></span>
              </p>

              <p>
                : التوقيع
                <span className={styles.seprt_span}></span>
              </p>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};
