import { useContext } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import UserContext from "../../../hooks/userContext";

const PaginateSelectedComponent = (props) => {
    const { permissions } = useContext(UserContext);

    const onChange = (option) => {
        if (typeof props.onChange === "function") {
            props.onChange(option);
        }
    };

    return (
        <section style={props?.customStyle}>
            <AsyncPaginate
                value={props.value || []}
                loadOptions={props.loadOptions}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>  option.class_name ? option.class_name : option.location ? option.location : option.email}
                onChange={onChange}
                isDisabled={props.disabled}
                isSearchable
                isLoading={props.loading}
                isMulti={props?.isMulti ? true : false}
                placeholder={props?.placeholder}
                additional={{
                    page: 1,
                }}
                className="AsyncPaginateStyle m-2"
            />
        </section>
    )
}

export { PaginateSelectedComponent };