import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import { Form } from "react-bootstrap";
import { Col, Button } from "react-bootstrap";

const StatmentHistory = () => {
  const [statment, setStatment] = useState();
  const location = useLocation();

  const {
    userId,
    loanId,
    profileOptions,
    blocked,
    type,
    manual_state,
    LimitIncrease,
  } = location?.state;

  const product_statement = () => {
    UserUtility.product_statement(userId, loanId)
      .then((res) => {
        setStatment(res);
      })
      .catch((err) => console.error("product_statement", err));
  };
  useEffect(() => {
    product_statement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mb-3">
      <Col className="d-flex justify-content-end  mb-3" lg={12} md={12} sm={12}>
        <Button className="w-25 mt-3 mb-3 border border-success text-success ">
          <Link
            state={{
              userId: userId,
              profileOptions: profileOptions,
              blocked: blocked,
              LimitIncrease: LimitIncrease,
              type: type,
              manual_state: manual_state,
              fines: true,
              product: true,
              loanId: loanId,
            }}
            to="RemainingInstallments"
            className="text-success fw-bolder text-decoration-none"
          >
            Fines
          </Link>
        </Button>

        <Button className="w-25 mt-3 mb-3 border border-success text-success ">
          <Link
            state={{
              userId: userId,
              profileOptions: profileOptions,
              blocked: blocked,
              LimitIncrease: LimitIncrease,
              type: type,
              manual_state: manual_state,
              fines: false,
              product: true,
              loanId: loanId,
            }}
            to="RemainingInstallments"
            className="text-success fw-bolder text-decoration-none"
          >
            Remaining installments
          </Link>
        </Button>
      </Col>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 2000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Installment Date</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Paid Amount</StyledTableCell>
              <StyledTableCell>Remaining Amount</StyledTableCell>
              <StyledTableCell>Late Days</StyledTableCell>
              <StyledTableCell>DPD</StyledTableCell>
              <StyledTableCell>Paid Date</StyledTableCell>
              <StyledTableCell>Penalties</StyledTableCell>
              <StyledTableCell>Paid Penalties</StyledTableCell>
              <StyledTableCell>Remain Penalties</StyledTableCell>
              <StyledTableCell>Principal</StyledTableCell>
              <StyledTableCell>Waived Penalties</StyledTableCell>
              <StyledTableCell>Remain Principal</StyledTableCell>
              <StyledTableCell>Penalties OnHold Amount</StyledTableCell>
              <StyledTableCell>settled</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statment?.map((element, id) => (
              <StyledTableRow key={id}>
                <StyledTableCell>{element?.due_date}</StyledTableCell>
                <StyledTableCell>{element.installments_amount}</StyledTableCell>
                <StyledTableCell>
                  {element.installments_paid_amount}
                </StyledTableCell>
                <StyledTableCell>
                  {element.installments_due_amount}
                </StyledTableCell>
                <StyledTableCell>{element.late_days}</StyledTableCell>
                <StyledTableCell>{element.dpd}</StyledTableCell>
                <StyledTableCell>{element.last_payment_date}</StyledTableCell>
                <StyledTableCell>
                  {element.penalties_total_amount}
                </StyledTableCell>
                <StyledTableCell>
                  {element.penalties_paid_amount}
                </StyledTableCell>
                <StyledTableCell>
                  {element.penalties_due_amount}
                </StyledTableCell>
                <StyledTableCell>
                  {element.installments_principal}
                </StyledTableCell>
                <StyledTableCell>
                  {element.penalties_waived_amount}
                </StyledTableCell>
                <StyledTableCell>
                  {element.installments_remaining_principal}
                </StyledTableCell>
                <StyledTableCell>
                  {element.penalties_on_hold_amount}
                </StyledTableCell>
                <StyledTableCell>
                  <Form.Check
                    type="checkbox"
                    checked={element.settled}
                    onChange={() => {}}
                    disabled
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StatmentHistory;
