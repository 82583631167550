import { useContext, useEffect, useState } from "react"
import { UserUtility } from "../../apis/UserUtility"
import UserContext from "../../hooks/userContext"
import { toast } from "react-toastify";
import { ENDPOINTS } from '../../apis/EndPoints'
export const DocumentsComponent = ({ id}) => {
    const [applicationData, setApplicationData] = useState()
    const { handleLoader, permissions = [] } = useContext(UserContext)
    const getApplicationById = () => {
        handleLoader(true)
        UserUtility.oneTransApplication(id).then(res => {
            handleLoader(false)
            setApplicationData(res)
        }).catch(err => {
            handleLoader(false)
            console.log('error of getApplicationById: ', err)
        })
    }

    const uploadDocuments = (file, docId) => {
        var token = localStorage.getItem("tools_token");
        var formdata = new FormData();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `token ${token}`);
        formdata.append("user", applicationData.user);
        formdata.append("name", file.name);
        formdata.append("file", file);
        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            `${ENDPOINTS.url}onetransaction/userdocuments/${docId}/`,
            requestOptions
        ).then(async (res) => {
            if (!res.ok) {
                const text = await res.json();
                // call error here becouse when throw error in catch read as text and did not convert it to json to read error from backend
                toast.error(text.detail || text[0] || "something went wrong");
                // throw new Error(text);
            } else {
                toast.success("File has been changed successfully");
                // after change image recall profile data 
                getApplicationById()
                return res.json();


            }
        })
            .catch((err) => {
                console.error("error editing on imagessss", err);
            });
    }

    useEffect(() => {
        getApplicationById()
    }, [id])
    return (
        <main>
            <div >
                {applicationData?.user_documents && applicationData?.user_documents.length > 0 ?
                    <>
                        {applicationData?.user_documents.map((item, index) => (
                            <div className="document_flex" key={index}>
                                <div className="doc_div" key={index.toString()}>
                                    <p>File name: <span>{item.name}</span></p>
                                    <a href={item.file} target="_blank" rel="noreferrer">{item.file}</a>
                                </div>

                                {permissions.includes('change_userdocuments') && 
                                    <div style={{ position: 'relative', alignSelf: 'flex-end', marginBottom: -6 }}>
                                        <input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            onChange={(e) =>
                                                uploadDocuments(e.target.files[0], item.id)}
                                        />

                                        <div type="upload" className="edit_txt">Edit</div>

                                    </div>
                                }
                            </div>
                        ))}
                    </>
                    :
                    <p style={{textAlign:'center',marginTop:20,fontSize:20}}>No documents found</p>
                }
            </div>
        </main>
    )
}