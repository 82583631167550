import React, { useEffect, useState, useContext } from "react";
import { Button,  Stack, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Style.scss";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const ManualStudy = () => {
  const { handleLoader, permissions } = useContext(UserContext);
  const [makerList, setMakerList] = useState();
  const [checkerList, setCheckerList] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [phone, setPhone] = useState();
  const [nationalId, setNationalId] = useState();
  const [results, setResults] = useState();

  const riskMaker = permissions?.includes("is_risk_maker");
  const riskChecker = permissions?.includes("is_risk_checker");
  const searchValue = (value) => {
    // eslint-disable-next-line no-unused-expressions
    value.length > 11
      ? (setNationalId(value), setPhone(null))
      : (setPhone(value), setNationalId(null));
  };
  const manualStudy_maker = () => {
    handleLoader(true);
    UserUtility.manualStudy_maker(pagesNumber, phone, nationalId)
      .then((res) => {
        setResults(res);
        setMakerList(res.results);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("manualStudy_maker", err);
        handleLoader(false);
      });
  };
  const manualStudy_checker = () => {
    handleLoader(true);
    UserUtility.manualStudy_checker(pagesNumber)
      .then((res) => {
        setResults(res);
        setCheckerList(res.results);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("manualStudy_checker", err);
        handleLoader(false);
      });
  };
  const handleClick = (e) => {
    if (e === "Enter") {
      manualStudy_maker();
    }
  };

  useEffect(() => {
    if (riskMaker) {
      manualStudy_maker();
    }
    if (riskChecker) {
      manualStudy_checker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber, riskMaker, riskChecker]);
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  return (
    <div className="UserProfile">
      <Stack direction="horizontal" className="mt-5" gap={4}>
        <Form.Control
          onKeyPress={(e) => handleClick(e.key)}
          type="text"
          placeholder="Client's national ID or phone number"
          className="me-auto"
          size="lg"
          onChange={(e) => searchValue(e.target.value)}
        />
        <Button
          variant="success"
          className="px-5"
          onClick={() => handleClick("Enter")}
        >
          Search
        </Button>
      </Stack>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>ID Number</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Manual state</StyledTableCell>
              <StyledTableCell>Sent to drive</StyledTableCell>
              <StyledTableCell>Passed Iscore</StyledTableCell>
              <StyledTableCell>Maker</StyledTableCell>
              <StyledTableCell>Checker</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(makerList ? makerList : checkerList)?.map((ele, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{ele.id}</StyledTableCell>
                  <StyledTableCell>{ele.national_id}</StyledTableCell>
                  <StyledTableCell>{ele.p_state}</StyledTableCell>
                  <StyledTableCell>
                    {ele.manual_study_data.manual_state}
                  </StyledTableCell>
                  <StyledTableCell>{`${ele.sent_to_drive}`}</StyledTableCell>
                  <StyledTableCell>{`${ele.passed_iscore_test}`}</StyledTableCell>
                  {permissions?.includes("is_risk_maker") ? (
                    <StyledTableCell>
                      {ele.manual_study_data?.risk_maker_phone ? (
                        ele.manual_study_data.manual_state ===
                        "Return_To_Maker" ? (
                          <Link
                            to="/userDetails/ManualLimit"
                            state={{
                              userId: ele.id,
                              type: "riskMaker",
                              manual_state: "Return_To_Maker",
                              LimitIncrease: false,
                            }}
                          >
                            {ele.manual_study_data.risk_maker_phone}
                          </Link>
                        ) : (
                          ele.manual_study_data.risk_maker_phone
                        )
                      ) : (
                        <Link
                          to="/userDetails/ManualLimit"
                          state={{
                            userId: ele.id,
                            type: "riskMaker",
                            LimitIncrease: false,
                          }}
                        >
                          <Button variant="success" style={{ width: 100 }}>
                            Study
                          </Button>
                        </Link>
                      )}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell>{`${ele.manual_study_data.risk_maker_phone}`}</StyledTableCell>
                  )}
                  {permissions?.includes("is_risk_maker") ? (
                    <StyledTableCell>{`${
                      ele.manual_study_data?.risk_checker_phone
                        ? ele.manual_study_data?.risk_checker_phone
                        : "null"
                    }`}</StyledTableCell>
                  ) : (
                    <StyledTableCell>
                      <Link
                        to="/userDetails/ManualLimit"
                        state={{
                          userId: ele.id,
                          type: "riskChecker",
                          LimitIncrease: false,
                        }}
                      >
                        <Button variant="success">
                          {ele.manual_study_data.risk_checker_phone === null
                            ? "Check"
                            : ele.manual_study_data.risk_checker_phone}
                        </Button>
                      </Link>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default ManualStudy;
