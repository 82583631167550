import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import styles from "./CashRegisterStyle.module.scss";

const dummyData = [
  { id: 1, title: "Feb installment 500", checked: false },
  { id: 2, title: "Feb installment 600", checked: false },
  { id: 3, title: "Mar installment 500", checked: false },
  { id: 4, title: "Apr installment 500", checked: false },
];
const CashRegister = () => {
  const [data, setData] = useState([...dummyData]);
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (event, item) => {
    setIsChanged(!isChanged);
    let arr = data;
    console.log("event: ", event);
    let index = arr.findIndex((element) => element.id === item.id);
    if (index > -1) {
      arr[index].checked = event.target.checked;
    }
    console.log("arrr: ", arr);
    setData(arr);
  };

  useEffect(() => {
    console.log("daate: ", data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChanged]);
  return (
    <setion className="container">
      <div style={{ marginTop: 40 }}></div>
      <Form className={`row`} style={{ marginLeft: 0, marginRight: 0 }}>
        <Form.Group className="col-8" controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="National ID"
            style={{ height: 40 }}
          />
        </Form.Group>

        <Button className={`col-4 ${styles.btn}`} type="submit">
          Search
        </Button>
      </Form>

      <hr />
      {/* Result of search  */}

      <Form>
        {dummyData.map((item, index) => (
          <div key={`default-checkbox${index}`} className="mb-3">
            {console.log("iiiitem: ", item)}
            <Form.Check
              label={item.title}
              name="group1"
              type="checkbox"
              id={`default-checkbox-${index}`}
              disabled={index > 0 ? !data[index - 1].checked : false}
              onChange={(event) => handleChange(event, item)}
            />
          </div>
        ))}
      </Form>
    </setion>
  );
};

export default CashRegister;
