import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Table from "react-bootstrap/Table";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import moment from "moment/moment";

const TableLoginHistoryData = ({ historyData }) => {
  const formatDate = (date) => {
    return moment(date).format("MMM DD, YYYY HH:mm");
  };

  console.log("historyData", historyData);
  return (
    <TableContainer
      component={Paper}
      style={{ height: "fit-content", marginRight: 20 }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Created at</StyledTableCell>
            <StyledTableCell>Updated date</StyledTableCell>
            <StyledTableCell>Device Name</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {historyData?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{formatDate(row.created_at)}</StyledTableCell>
              <StyledTableCell>{row.timestamp}</StyledTableCell>
              <StyledTableCell>{row.device_name}</StyledTableCell>
              <StyledTableCell>
                {row.is_successful_login ? "APPROVED" : "BLOCK"}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableLoginHistoryData;
