import React, { useEffect, useState } from "react";
import ReactCrop from 'react-image-crop';
// import demoImage from "./demo-image.jpg";

function ImageCropper(props) {
    const { imageToCrop, onImageCropped, maxCrop, name } = props;
    const [cropConfig, setCropConfig] = useState(
        // default crop config
        maxCrop
    );
    const [imageRef, setImageRef] = useState();

    useEffect(() => {
        if (imageRef) {
            cropImage(maxCrop)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageRef])


    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                name // destination filename
            );

            // calling the props function to expose
            // croppedImage to the parent component
            onImageCropped(croppedImage);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        // creating the cropped image from the source image
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    // returning an error
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }

                    blob.name = fileName;
                    // creating a Object URL representing the Blob object given
                    // const croppedImageUrl = window.URL.createObjectURL(blob);

                    //creating file object 
                    const file = new File([blob], blob.name, { type: 'image/jpeg' })
                    resolve(file);
                }, 'image/jpeg'
            );
        });
    }

    return (
        imageToCrop ? <ReactCrop
            src={imageToCrop || ''}
            crop={cropConfig}
            ruleOfThirds
            onImageLoaded={(imageRef) => setImageRef(imageRef)}
            onComplete={(cropConfig) => cropImage(cropConfig)}
            onChange={(cropConfig) => setCropConfig(cropConfig)}
            crossorigin="anonymous" // to avoid CORS-related problems
        /> : ''
    );
}

ImageCropper.defaultProps = {
    onImageCropped: () => { }
}

export default ImageCropper;
