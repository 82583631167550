import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { Button } from "react-bootstrap";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
const RecentCardPayments = () => {
  const [userPayment, setUserPayment] = useState();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [results, setResults] = useState();
  const location = useLocation();
  const { userId } = location.state;
  const user_paymob_payments = () => {
    UserUtility.user_paymob_payments(userId, pagesNumber)
      .then((res) => {
        setResults(res);
        setUserPayment(res.results);
      })
      .catch((err) => console.log("user_paymob_payments", err));
  };
  const handleChange = (e, value) => {
    setPagesNumber(value);
  };
  useEffect(() => {
    user_paymob_payments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumber]);
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Bank Name</StyledTableCell>
              <StyledTableCell>Success</StyledTableCell>
              <StyledTableCell>Time stamp</StyledTableCell>
              <StyledTableCell>Cause of failure</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userPayment?.map((field, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{field.payment__amount}</StyledTableCell>
                  <StyledTableCell>{field.issuer_bank}</StyledTableCell>
                  <StyledTableCell>{`${field.payment__paid}`}</StyledTableCell>
                  <StyledTableCell>{field.date_issued}</StyledTableCell>
                  <StyledTableCell>{field.failure_reason}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        brands={results}
        page={pagesNumber}
        handleChange={handleChange}
      />
    </div>
  );
};

export default RecentCardPayments;
