import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import "../contracts/Contracts.scss";
import { StyledTableCell, StyledTableRow } from "../../constants/Colors";
import PaginationComponent from "../pagination/PaginationComponent";
import { UserUtility } from "../../apis/UserUtility";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment";
import UserContext from "../../hooks/userContext";
import { Form } from "react-bootstrap";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
const filtersArray = [
  {
    filterName: "Not collected",
    filterTag: "not_collected",
    filterState: false,
  },
  {
    filterName: "Scheduled for pickup",
    filterTag: "scheduled_for_pickup",
    filterState: false,
  },
  {
    filterName: "Received by operation",
    filterTag: "received",
    filterState: false,
  },
  {
    filterName: "Delivered to Risk",
    filterTag: "delivered_to_risk",
    filterState: false,
  },
  {
    filterName: "Received by Risk",
    filterTag: "received_by_risk",
    filterState: false,
  },

  {
    filterName: "Delivered to Archived",
    filterTag: "delivered_to_archive",
    filterState: false,
  },
  {
    filterName: "Received by Archived",
    filterTag: "received_by_archive",
    filterState: false,
  },
  {
    filterName: "Archived",
    filterTag: "archived",
    filterState: false,
  },
  {
    filterName: "Rejected",
    filterTag: "rejected",
    filterState: false,
  },
];
const ContractSummary = () => {
  //   const [maker, setMaker] = useState("");
  const [contracts, setContracts] = useState();
  const [filters, setFilters] = useState(filtersArray);
  const [page, setPage] = useState(1);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [statusSelected, setStatusSelected] = useState();
  const [contractId, setContractId] = useState();
  const { handleLoader, permissions = [] } = useContext(UserContext);
  const [branchesList, setBranchesList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  // const [isRejectState, setIsReject] = useState({ isReject: false, obj: null });
  // const [questionReject, setQuestionReject] = useState();

  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };

  // get branches and handle pagination
  const getBranches = () => {
    UserUtility.contractBranches()
      .then((res) => {
        setBranchesList(res.results);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((error) => {
        console.error("get getBranches error", error);
      });
  };

  const paginationFun = (nextUrl) => {
    UserUtility.getNextPageData(nextUrl, "")
      .then((res) => {
        setBranchesList((old) => [...old, ...res.results]);
        if (res.next) {
          paginationFun(res.next);
        }
      })
      .catch((err) => console.log(err));
  };

  const getSummaryContracts = () => {
    const date_from = moment(dateFrom).format("YYYY-MM-DD");
    const date_to = moment(dateTo).format("YYYY-MM-DD");

    // if not select any status will select all
    /*
    let statusAll;
    if (!statusSelected) {
      const statusArr = filters.map((res) => { return (res.filterTag) }
      );
      statusAll = statusArr.join(",")
    }
    */
    handleLoader(true);
    UserUtility.contractSummary(
      page,
      selectedBranch,
      statusSelected,
      date_from,
      date_to
    )
      .then((res) => {
        setContracts(res);
        handleLoader(false);
      })
      .catch((error) => {
        console.error(error);
        handleLoader(false);
      });
  };

  const contractsSearch = (e) => {
    e.preventDefault();
    getSummaryContracts()
  };

  //handle contract history modal
  const handleReport = (id) => {
    setContractId(id);
  };

  useEffect(() => {
    getSummaryContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="contract_container">

      {(permissions.includes('can_view_contract_tracking_summary')) ?
        <>
          {/* branches component */}
          <article>
            <form>
              <article className="searchBar_wrapper">
                <section className="search_fields_wrapper">
                  {/* <TextField
                size="small"
                placeholder="Branch Name"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="text-field"
                label="Branch Name"
              /> */}

                  {/* branches */}
                  <FormControl className="select-field" style={{ width: '40%' }}>
                    <InputLabel>Choose a branch...</InputLabel>
                    <Select
                      className="select_class"
                      size="small"
                      id="demo-simple-select"
                      value={selectedBranch}
                      label="Choose a branch..."
                      onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                      {branchesList?.map((branch, id) => {
                        return (
                          <MenuItem key={id} value={branch.id}>
                            {branch.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl className="select-field">
                    <InputLabel>Contract Status</InputLabel>
                    <Select
                      className="select_class"
                      size="small"
                      id="demo-simple-select"
                      value={statusSelected}
                      label="Contract Status"
                      onChange={(e) => setStatusSelected(e.target.value)}
                    >
                      {filters?.map((filter, id) => {
                        return (
                          <MenuItem value={filter.filterTag} key={id}>
                            {filter.filterName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <article className="date_container">
                    {/* from */}
                    <section className="date_wrapper">
                      <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <DatePicker
                          // PopperProps={{
                          //   className: "picker_class",
                          // }}
                          className="picker_class"
                          disableFuture
                          label="Date From"
                          inputFormat="yyyy-MM-dd"
                          value={dateFrom ? new Date(dateFrom) : null}
                          onChange={(newValue) => {
                            setDateFrom(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </section>

                    {/* to */}
                    <section className="date_wrapper">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="Date To"
                          inputFormat="yyyy-MM-dd"
                          value={dateTo ? new Date(dateTo) : null}
                          onChange={(newValue) => {
                            setDateTo(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </section>
                  </article>
                </section>


                <Button
                  className="searchButton"
                  type="submit"
                  variant="contained"
                  onClick={(e) => contractsSearch(e)}
                >
                  Search
                </Button>
              </article>
              {/* filters container */}
            </form>
          </article>
          {/* table container */}
          <TableContainer
            component={Paper}
            style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Branch Name</StyledTableCell>
                  <StyledTableCell>Sum.Of.Contracts</StyledTableCell>
                  <StyledTableCell>Contract Status</StyledTableCell>
                  {/* <StyledTableCell>Operation</StyledTableCell> */}
                  <StyledTableCell>Sum.Of.Limit</StyledTableCell>
                  <StyledTableCell>Oldest Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts?.results?.map((row, id) => (
                  <StyledTableRow key={id}>
                    <StyledTableCell component="th" scope="row">
                      {!permissions.includes("contracts_view_only") ? (
                        <button
                          className="generateReportBtn"
                          onClick={() => handleReport(row.branch.id)}
                        >
                          {row.branch.kiosk_v2__title}
                        </button>
                      ) : (
                        <> {row.branch.kiosk_v2__title} </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{row.branch.contracts_count}</StyledTableCell>
                    <StyledTableCell>{row.branch.status}</StyledTableCell>

                    <StyledTableCell>{row.branch.sum_of_finance_limit}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.branch.last_date).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationComponent
            brands={contracts}
            page={page}
            handleChange={handleChange}
            total={true}
          />
        </>
        :
        <div>
          <h6 className="permission_style">You don't have permission to see this page</h6>
        </div>
      }
    </main>
  );
};

export default ContractSummary;
