import { ApplicationFormComponent } from "../../components/OneTransactionComponent/ApplicationFormComponent";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import {
  customStyles,
  StyledTableCell,
  StyledTableRow,
} from "../../constants/Colors";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { UserUtility } from "../../apis/UserUtility";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import Modal from "react-modal";
import moment from "moment";

export const DataEntryApplication = (props) => {
  const {profileId,
    history,
    user_id,
    creditFormData,
    loan,
    showHistory}=props
    console.log("creditFormData",creditFormData?.userprofile?.onetransactionprofile?.id)
  const [loanId, setLoanId] = useState();
  const [userLoans, setuserLoans] = useState();
  const [loanDetails, setLoanDetails] = useState();
  const [data, setData] = useState();
  const [loanData, setLoanData] = useState();
  const [page, setPage] = useState(1);
  const [pageLoan, setPageLoan] = useState(1);
  const [isHistoryOpen, setIsHistoryOpen] = useState();
  const [commentsHistory, setCommentsHistory] = useState(false);
  const [returnedHistory, setReturnedHistory] = useState(false);
  const [returnedData, setReturnedData] = useState();

  const [isLoanOpen, setIsLoanOpen] = useState();
  useEffect(() => {
    loanHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoan, loanId, loan]);
  useEffect(() => {
    userProfile_loansHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  const userProfile_loansHistory = () => {
    UserUtility.userProfile_loansHistory(page, user_id)
      .then((res) => {
        setData(res);
        setuserLoans(res.results);
      })
      .catch((err) => {
        console.log("userProfile_loansHistory:", err);
      });
  };
  const loanHistory = () => {
    (loanId || loan) &&
      UserUtility.loanHistory(pageLoan, loanId || loan?.id)
        .then((res) => {
          setLoanData(res);
          setLoanDetails(res.results);
        })
        .catch((err) => {
          console.log("loanHistory:", err);
        });
  };
  const handleChange = (e, value) => {
    setPage(value);
  };
  const handleLoanChange = (e, value) => {
    setPageLoan(value);
  };
  const handleGetRetrunedHistory = () => {
    setReturnedHistory(true);
    setIsLoanOpen(true);
    UserUtility.oneTransactionReturnedComments(profileId||loan?.userprofile?.onetransactionprofile?.id||creditFormData?.userprofile?.onetransactionprofile?.id)
      .then((res) => {
        console.log("res of oneTransactionReturnedComments: ", res);
        setReturnedData(res);
        setIsLoanOpen(false);
      })
      .catch((err) => {
        console.log("err of oneTransactionReturnedComments: ", err);
        setIsLoanOpen(false);
      });
  };

  return (
    <main className="d-flex justify-content-evenly">
      {/* <ApplicationFormComponent  /> */}
      {!user_id && !history && (
        <div style={{ width: "30%" }}>
          <Button
            variant="primary"
            className="outlineBtn"
            style={{ width: "100%" }}
            onClick={() => setCommentsHistory(true)}
          >
            Comments history
          </Button>
        </div>
      )}

      {!creditFormData && (
        <div style={{ width: "25%" }}>
          <Button
            variant="primary"
            className="outlineBtn"
            style={{ width: "100%" }}
            onClick={() => (
              // eslint-disable-next-line no-sequences
              loan && setIsLoanOpen(true) && loanHistory(),
              setIsHistoryOpen(true),
              userProfile_loansHistory()
            )}
          >
            History
          </Button>
        </div>
      )}
      {showHistory && (
        <div style={{ width: "25%" }}>
          <Button
            variant="primary"
            className="outlineBtn"
            style={{ width: "100%" }}
            onClick={() => (
              loan && setIsLoanOpen(true) && loanHistory(),
              setIsHistoryOpen(true),
              userProfile_loansHistory()
            )}
          >
            History
          </Button>
        </div>
      )}

      <div style={{ width: "25%" }}>
        <Button
          variant="primary"
          className="outlineBtn"
          style={{ width: "100%" }}
          onClick={() => handleGetRetrunedHistory()}
        >
          Return History
        </Button>
      </div>
      {/* modal of credit credit memo */}
      <Modal
        isOpen={isHistoryOpen}
        onRequestClose={() => setIsHistoryOpen(false)}
        style={customStyles}
        contentLabel="user history"
        shouldCloseOnOverlayClick={true}
      >
        <h6 className="subTitle">Application History</h6>
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Program Name</StyledTableCell>
                <StyledTableCell>Membership Amount</StyledTableCell>
                <StyledTableCell>Intrest Rate</StyledTableCell>
                <StyledTableCell>Application State</StyledTableCell>
                <StyledTableCell>I.Score Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userLoans?.map((ele, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      onClick={() => (
                        setIsLoanOpen(true), setLoanId(ele?.id), loanHistory()
                      )}
                      style={{ cursor: "pointer" }}
                    >
                      {ele?.onetransactionloandata?.program_name}
                    </StyledTableCell>
                    <StyledTableCell>{ele?.membership_amount}</StyledTableCell>
                    <StyledTableCell>
                      {ele?.onetransactionloandata?.program_interest_rate}
                    </StyledTableCell>
                    <StyledTableCell>
                      {ele?.onetransactionloandata?.status}
                    </StyledTableCell>
                    <StyledTableCell>
                      {ele?.onetransactionloandata?.iscore_date}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationComponent
          brands={data}
          page={page}
          handleChange={handleChange}
        />
      </Modal>

      {/* loans history */}
      <Modal
        isOpen={isLoanOpen}
        onRequestClose={() => (
          loan && setIsHistoryOpen(false), setIsLoanOpen(false)
        )}
        style={customStyles}
        contentLabel="Loan history"
        shouldCloseOnOverlayClick={true}
      >
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Action</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Department</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loanDetails?.map((ele, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{ele?.action}</StyledTableCell>
                    <StyledTableCell>{ele?.created}</StyledTableCell>
                    <StyledTableCell>{ele?.department}</StyledTableCell>
                    <StyledTableCell>{ele?.email}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationComponent
          brands={loanData}
          page={pageLoan}
          handleChange={handleLoanChange}
        />
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Forsa Available Amount</StyledTableCell>
                <StyledTableCell>Forsa Late Days</StyledTableCell>
                <StyledTableCell>Forsa Limit</StyledTableCell>
                <StyledTableCell>Forsa Used Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loanDetails && (
                <StyledTableRow>
                  <StyledTableCell>
                    {loanDetails[0]?.forsa_available_amount}
                  </StyledTableCell>
                  <StyledTableCell>
                    {loanDetails[0]?.forsa_late_days}
                  </StyledTableCell>
                  <StyledTableCell>
                    {loanDetails[0]?.forsa_limit}
                  </StyledTableCell>
                  <StyledTableCell>
                    {loanDetails[0]?.forsa_used_amount}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>

      {/* comments history */}
      <Modal
        isOpen={commentsHistory}
        onRequestClose={() => setCommentsHistory(false)}
        style={customStyles}
        contentLabel="user history"
        shouldCloseOnOverlayClick={true}
      >
        <h6 className="subTitle">Comments History</h6>
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>user</StyledTableCell>
                <StyledTableCell>type</StyledTableCell>
                <StyledTableCell>created</StyledTableCell>
                <StyledTableCell>modified</StyledTableCell>
                <StyledTableCell>comment</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loan?.comments?.map((ele, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{ele?.user}</StyledTableCell>
                    <StyledTableCell>
                      {ele?.type.replaceAll("_", " ")}
                    </StyledTableCell>
                    <StyledTableCell>{ele?.created}</StyledTableCell>
                    <StyledTableCell>{ele?.modified}</StyledTableCell>
                    <StyledTableCell>{ele?.comment}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>

      {/* return history */}
      <Modal
        isOpen={returnedHistory}
        onRequestClose={() => setReturnedHistory(false)}
        style={customStyles}
        contentLabel="returned history"
        shouldCloseOnOverlayClick={true}
      >
        <h6 className="subTitle">Returned History</h6>
        <TableContainer
          component={Paper}
          style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Program Name</StyledTableCell>
                <StyledTableCell>Return Date</StyledTableCell>
                <StyledTableCell>Return Reason</StyledTableCell>
                <StyledTableCell>Action User Email</StyledTableCell>
                <StyledTableCell>Comment Type</StyledTableCell>
                <StyledTableCell>Resolved</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                returnedData?.results?.map((ele, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{ele?.program_name}</StyledTableCell>
                      <StyledTableCell>
                        {moment(ele?.return_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell>{ele?.comment}</StyledTableCell>
                      <StyledTableCell>{ele?.action_user_email}</StyledTableCell>
                      <StyledTableCell>{ele?.comment_type}</StyledTableCell>
                      <StyledTableCell>{`${ele?.resolved}`}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

      </Modal>
    </main>
  );
};
