import React, { useContext, useEffect, useRef, useState } from "react";
import "./Offers.scss";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Checkbox,
  Button,
  TableSortLabel,
  Box,
  TextField,
} from "@mui/material";
import {
  Colors,
  StyledTableCell,
  StyledTableRow,
} from "../../constants/Colors";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { UserUtility } from "../../apis/UserUtility";
import UserContext from "../../hooks/userContext";
import PaginationComponent from "../pagination/PaginationComponent";
import moment from "moment";
import { toast } from "react-toastify";


const headerData = [
  {
    type: 'name',
    dataType: 'string',
    label: 'Offer name',
  }, {
    type: 'created',
    dataType: 'date',
    label: 'Created date',
  }, {
    type: 'publish_date',
    dataType: 'date',
    label: 'Publish date',
  }, {
    type: 'expiry_date',
    dataType: 'date',
    label: 'Expiry date',
  }
]

const filtersArray = [
  {
    filterName: "Published",
    filterTag: "published",
    filterState: false,
  },
  {
    filterName: "Un published",
    filterTag: "is_un_published",
    filterState: false,
  },
  {
    filterName: "Is expired",
    filterTag: "is_expired",
    filterState: false,
  },

];
const Offers = () => {
  const [brandOffers, setBrandOffers] = useState([]);
  const { handleLoader, permissions } = useContext(UserContext);
  const location = useLocation();
  const { brandName, brandId } = location.state;
  const [page, setPage] = useState(1);
  const [offersDetail, setOffersDetail] = useState();
  const mountedRef = useRef()
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');

  // filter
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(filtersArray);

  const getOffers = () => {
    const filtersStates = filters.map((filter) => filter.filterState);

    UserUtility.getOffers(page, brandId, searchValue, ...filtersStates)
      .then((res) => {
        setOffersDetail(res)
        setBrandOffers(res.results)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)

      });
  };
  //handle pagination page
  const handleChange = (e, value) => {
    setPage(value);
  };


  const publishChange = (offerId, publishState) => {
    handleLoader(true);
    UserUtility.publishOffer(offerId, publishState)
      .then(() => {
        handleLoader(false);
        getOffers();
      })
      .catch((error) => {
        console.error("publish offer error", error);
        handleLoader(false);
      });
  };

  // sort
  const createSortHandler = (cellType) => () => {
    setOrderBy(cellType)
    setOrder(order === 'asc' ? 'desc' : 'asc')

    if (cellType === 'name') {
      if (order === 'asc') {
        // asc
        brandOffers.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? -1 : b.title.toLowerCase() > a.title.toLowerCase() ? 1 : 0
        )
      }
      else {
        // desc
        brandOffers.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : b.title.toLowerCase() > a.title.toLowerCase() ? -1 : 0
        )
      }
    }

    // created date
    if (cellType === 'created') {
      if (order === 'asc') {
        brandOffers.sort((a, b) => {
          return new Date(a.created) - new Date(b.created);
        })
      } else {
        brandOffers.sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        })
      }
    }

    // publish date
    if (cellType === 'publish_date') {
      if (order === 'asc') {
        brandOffers.sort((a, b) => {
          return new Date(a.publish_date) - new Date(b.publish_date);
        })
      } else {
        brandOffers.sort((a, b) => {
          return new Date(b.publish_date) - new Date(a.publish_date);
        })
      }
    }

    // expiry date
    if (cellType === 'expiry_date') {
      if (order === 'asc') {
        brandOffers.sort((a, b) => {
          return new Date(a.expiry_date) - new Date(b.expiry_date);
        })
      } else {
        brandOffers.sort((a, b) => {
          return new Date(b.expiry_date) - new Date(a.expiry_date);
        })
      }
    }


    // setBrandCategories
  };

  const publishSwitch = (isPublish, id, expiry_date) => {
    console.log('expiry_date: ', expiry_date)
    switch (true) {
      case isPublish && permissions.includes("Finance_merchant_creation_tool"):
      case isPublish &&
        permissions.includes("Marketing_merchant_creation_tool"):
        return "Published";
        break;

      case isPublish && permissions.includes("BD_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.red,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, false)}
          >
            Unpublish
          </Button>
        );
        break;

      case !isPublish && permissions.includes("Finance_merchant_creation_tool"):
        return (
          <Button
            style={{
              minWidth: 100,
              backgroundColor: Colors.primary,
            }}
            type="submit"
            variant="contained"
            onClick={() => publishChange(id, true)}
            disabled={expiry_date < moment(new Date()).format("YYYY-MM-DD")}
          >
            Publish
          </Button>
        );
        break;

      case !isPublish && permissions.includes("BD_merchant_creation_tool"):
      case !isPublish &&
        permissions.includes("Marketing_merchant_creation_tool"):
        return "Not Published";
        break;

      default:
        break;
    }
  };

  //search Btn
  const brandSearch = (e) => {
    e.preventDefault();
    if (page === 1) {
      getOffers();
    } else {
      setPage(1)
    }
    setIsLoading(true);
  };

  const handleActivePlan = (index) => {
    //to add or remove checkbox as UI
    const newFilters = filters.map((filterInfo, id) => {
      return {
        ...filterInfo,
        filterState:
          index === id ? !filterInfo.filterState : filterInfo.filterState,
      };
    });
    setFilters(newFilters);
  };

  // to hide offer from any endpoint
  const hideOfferFun = (offerId) => {
    UserUtility.hideOffer(offerId, true).then(res => {
      console.log('res of hide: ', res)
      getOffers()
    }).catch(err => {
      console.log('error of hide offer: ', err)
      toast.error(err)
    })
  }
  /*
    useEffect(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useCallback(() => {
        getOffers();
        return () => {
          mountedRef.current = false;
        };
      }, [])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
  */

  useEffect(() => {
    getOffers();
  }, [page])
  return (
    <article className="offers_container">
      {/* search component */}
      <article style={{ marginTop: 30 }}>
        <form className="searchBar_wrapper">
          <TextField
            size="small"
            placeholder="Search by offer name"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="text-field"
            label="Offer name"
          />

          <Button
            className="searchButton"
            type="submit"
            variant="contained"
            disabled={isLoading}
            onClick={(e) => brandSearch(e)}
          >
            {isLoading ? "Loading..." : "Search"}
          </Button>
        </form>
      </article>

      {/* filters container */}
      <article className="filters_containerr">
        {filters.map((filter, id) => {
          return (
            <section key={id}>
              <button onClick={() => handleActivePlan(id)} className="checkBtn">
                <Checkbox size="small" checked={filter.filterState} />
              </button>
              <span>{filter.filterName}</span>
            </section>
          );
        })}
      </article>


      <TableContainer
        component={Paper}
        style={{ height: "fit-content", marginRight: 20, marginTop: 30 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headerData.map((headCell, index) => (
                <StyledTableCell>
                  <TableSortLabel
                    className="table_sort_class"
                    active
                    direction={orderBy === headCell.type ? order : 'asc'}
                    onClick={createSortHandler(headCell.type)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" >
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell>Featured</StyledTableCell>
              <StyledTableCell>Best seller</StyledTableCell>
              <StyledTableCell>Newest</StyledTableCell>
              <StyledTableCell>Published</StyledTableCell>
              <StyledTableCell>Hide offer</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brandOffers?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.id ? (
                    <Link
                      to={`/offer/${row.id}`}
                      state={{
                        brandName: brandName,
                        offerData: row,
                        newOffer: false,
                        brandId: row.brand,
                        /*
                        brandId: row.brand,
                       
                        offerId: row.id,
                        min_percentage: row.min_percentage
                        */
                      }}
                      style={{
                        color: "#000",
                        textDecoration: "none",
                        fontWeight: 700,
                      }}
                    >
                      {row.title_en}
                    </Link>
                  ) : (
                    <h4>{row.title_en}</h4>
                  )}
                </StyledTableCell>
                {/* created date */}
                <StyledTableCell>{row.created ? moment(row.created).format('lll') : ' - '}</StyledTableCell>
                {/* publish date */}
                <StyledTableCell>{row.publish_date ? row.publish_date : ' - '}</StyledTableCell>
                {/* expiry date */}
                <StyledTableCell>{row.expiry_date ? row.expiry_date : ' - '}</StyledTableCell>
                <StyledTableCell>
                  <Checkbox checked={row.featured} size="small" />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox checked={row.best_seller} size="small" />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox checked={row.newest} size="small" />
                </StyledTableCell>
                <StyledTableCell>
                  {/* {permissions.includes("Finance_merchant_creation_tool") ? (
                    <Button
                      style={{
                        minWidth: 100,
                        backgroundColor: row.published
                          ? Colors.red
                          : Colors.primary,
                      }}
                      type="submit"
                      variant="contained"
                      onClick={() => publishChange(row.id, !row.published)}
                    >
                      {row.published ? "Unpublish" : "Publish"}
                    </Button>
                  ) : row.published ? (
                    "Published"
                  ) : (
                    "Not Published"
                  )} */}

                  {publishSwitch(row.published, row.id, row.expiry_date)}
                </StyledTableCell>

                {/* hide offer */}
                <StyledTableCell>
                  {(permissions.includes("BD_merchant_creation_tool") && !row.published) ?
                    <Button
                      style={{
                        minWidth: 100,
                        backgroundColor: row.published
                          ? Colors.red
                          : Colors.primary,
                      }}
                      type="submit"
                      variant="contained"
                      disabled={row.publish_date}
                      onClick={() => hideOfferFun(row.id)}
                    >
                      Hide
                    </Button>
                    :
                    ' '
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {permissions.includes("BD_merchant_creation_tool") && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={9}
                  style={{ padding: "5px 47px 5px 0px" }}
                >
                  <Link
                    to="/offer/New offer"
                    state={{
                      brandName: brandName,
                      brandId: brandId,
                      newOffer: true,

                    }}
                  >
                    <MdOutlineAddCircleOutline className="addIcon" />
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {permissions.includes("BD_merchant_creation_tool") ? (
        <div className="submitBtn">
          <Button
            variant="contained"
            type="submit"
            style={{ marginLeft: 0, marginRight: 40 }}
          >
            Save section
          </Button>
        </div>
      ) : (
        ""
      )}

      <PaginationComponent
        brands={offersDetail}
        page={page}
        handleChange={handleChange}
        total={false}
      />
    </article>
  );
};

export default Offers;
