export let textFieldsPerson = [
  {
    label: "First name",
    name: "first_name",
    error: true,
    type: "text",
  },
  {
    label: "Second name",
    name: "second_name",
    error: true,
    type: "text",
  },
  {
    label: "Third name",
    name: "third_name",
    error: true,
    type: "text",
  },
  {
    label: "Last name",
    name: "last_name",
    error: true,
    type: "text",
  },

  {
    label: "Phone number",
    name: "phone",
    error: true,
    type: "number",
  },

  {
    label: "Additional Phone number",
    name: "additional_phone",
    error: true,
    type: "number",
  },

  {
    label: "Marital status",
    name: "marital_status",
    error: true,
    isDropDown: true,
  },

  {
    label: "Gender",
    name: "gender",
    error: true,
    isDropDown: true,
  },

  {
    label: "Age",
    name: "age",
    error: true,
  },
  {
    label: "Emergency contact",
    name: "emergency_contact",
    error: true,
    type: "number",
  },
  {
    label: "Emergency contact name",
    name: "emergency_contact_name",
    error: true,
    type: "text",
  },
  {
    label: "Number of independent",
    name: "no_independent",
    error: true,
  },
  {
    label: "Email",
    name: "email",
    error: true,
    type: "text",
  },
];

export let textFieldsAddress = [
  {
    label: "City",
    name: "governorate",
    error: true,
    isDropDown: true,
  },
  {
    label: "Area",
    name: "area",
    error: true,
    isDropDown: true,
  },
  {
    label: "Home type",
    name: "home_type",
    error: true,
    isDropDown: true,
  },
  {
    label: "Home address duration",
    name: "home_address_duration",
    error: true,
    isDropDown: true,
  },
  {
    label: "Home Address 1",
    name: "home_address",
    error: true,
    type: "text",
  },
  {
    label: "Home Address 2",
    name: "home_address_2",
    error: true,
    type: "text",
  },
];

export let textFieldsFinancial = [
  {
    label: "Occupation",
    name: "occupation",
    error: true,
    isDropDown: true,
  },
  {
    label: "Company name",
    name: "company_name",
    error: true,
    type: "text",
  },
  {
    label: "Company contact",
    name: "company_contact",
    error: true,
    type: "number",
  },
  {
    label: "Company type",
    name: "company_type",
    error: true,
    isDropDown: true,
  },
  {
    label: "Company address",
    name: "company_address",
    error: true,
    isDropDown: false,
  },
  {
    label: "Annual income",
    name: "annual_income",
    error: true,
  },
  {
    label: "Net monthly income",
    name: "net_monthly_income",
    error: true,
  },

  // {
  //   label: "Service provider",
  //   name: "service_provider",
  //   type: "text",
  //   error: true,
  // },
  // {
  //   label: "Service info",
  //   name: "service_info",
  //   type: "text",
  //   error: true,
  // },
];
